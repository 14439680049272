import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { black, carbon600, primary500 } from '@styles/palette'
import { CommonIcon } from '@common/components'

export const CompanyDetails = ({
  companyName = '',
  companyIdentifier = '',
  style = {},
  verifiedCompany = false,
}) => {
  return (
    <View style={[styles.container, style]}>
      {Boolean(companyName) && (
        <View style={styles.row}>
          <Text
            style={styles.companyName}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {companyName}
          </Text>
          {verifiedCompany && (
            <View style={styles.iconContainer}>
              <CommonIcon
                name={'VERIFIED-FILLED'}
                size={18}
                color={primary500}
              />
            </View>
          )}
        </View>
      )}

      {Boolean(companyName) && (
        <View style={styles.row}>
          <Text style={styles.cuitTitle}>TAX ID </Text>
          <Text style={styles.companyIdentifier} numberOfLines={1}>
            {companyIdentifier}
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
  },
  companyName: {
    fontSize: 16,
    fontWeight: '600',
    color: black,
    lineHeight: 24,
    textTransform: 'uppercase',
  },
  companyIdentifier: {
    fontSize: 12,
    fontWeight: '400',
    color: carbon600,
    lineHeight: 18,
  },
  cuitTitle: {
    fontSize: 12,
    fontWeight: '500',
    color: carbon600,
    lineHeight: 18,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginStart: 5,
  },
})

CompanyDetails.propTypes = {
  companyName: PropTypes.string,
  companyIdentifier: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  verifiedCompany: PropTypes.bool,
}
