import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'

import { ActivityProgressBar, ActivityCardRealization } from '../'

import ACTIVITY_TYPES from '@constants/activityTypes'
import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

export const DirectFertilizationActivity = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  signed,
  signedIf,
  supplies,
  areaUnit,
  dateOrder,
  surface,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_FERTILIZATION.key) return null

  const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(farms)

  return (
    <>
      <ActivityProgressBar percent={percent} />

      <ActivityCardRealization
        activityType={activityType}
        supplies={supplies}
        farms={farmAndLotsWithSurfaceRealized}
        subTypeActivity={subTypeActivity}
        areaUnit={areaUnit}
        signed={signed}
        signedIf={signedIf}
        date={dateOrder}
        contentStyle={styles.realization}
        farmRealized={true}
        surface={surface}
      />
    </>
  )
}

const styles = StyleSheet.create({
  realization: {
    marginTop: 15,
  },
})

DirectFertilizationActivity.prototype = {
  activityType: PropTypes.oneOf([ACTIVITY_TYPES.ACT_TILLAGE.key]),
  percent: PropTypes.number,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  dateOrder: PropTypes.string,
}
