import React, { useCallback, useEffect, useState } from 'react'
import FormAdd from '@components/companies/FormAdd'
import useNetwork from '@utils/network'

function CropCompanyScreen({ route, navigation }) {
  const [crop, setCrop] = useState({})
  const { doRequest } = useNetwork()

  const fetchCrop = useCallback(async () => {
    const id = route.params.id
    try {
      const response = await doRequest({ method: 'GET', url: `crops/${id}` })
      setCrop(response.data)
    } catch (err) {
      console.warn(err)
    }
  }, [route])

  useEffect(() => {
    fetchCrop()
  }, [])

  return <FormAdd data={crop} navigation={navigation} />
}

export default CropCompanyScreen
