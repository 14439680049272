import React, { useEffect, useContext } from 'react'
import { StyleSheet, ScrollView, ActivityIndicator } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Text, Colors } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CropContext } from '@contextState/crop'
import { LicenseContext } from '@contextState/license'
import LicensesEmpty from '@components/licenses/v1/LicensesEmpty'
import LicensesList from '@components/licenses/v1/LicensesList'

const Licenses = ({ navigation, route }) => {
  const {
    fetchCrop,
    selectCrop,
    currentCrop,
    isLoading: isCropLoading,
  } = useContext(CropContext)
  const {
    fetchLicenses,
    licenses,
    isLoading: isLicenseLoading,
  } = useContext(LicenseContext)

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      doFetchLicenses()
      doFetchCrop()
    })

    return unsubscribe
  }, [])

  /**
   * FETCH CROP
   */
  const doFetchCrop = async () => {
    const response = await fetchCrop(route.params.cropId)

    selectCrop(response)
  }

  /**
   * FETCH LICENSES
   */
  const doFetchLicenses = async () => {
    const params = {
      cropId: route.params.cropId,
    }

    fetchLicenses(params)
  }

  /**
   * GO TO CROPS LIST
   */
  const goToCrops = () => {
    navigation.navigate('CropList')
  }

  /**
   * GO TO LICENSE DETAILS
   */
  const goToLicenseDetails = (license) => {
    if (license.disabled) {
      return
    }

    navigation.navigate('LicenseDetails', {
      cropId: route.params.cropId,
      licenseId: license._id,
    })
  }

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      <ScrollView style={styles.container}>
        {currentCrop && (
          <Text
            style={styles.cropName}
          >{`${currentCrop.name} - ${currentCrop.company.name}`}</Text>
        )}

        {licenses.length ? (
          <LicensesList
            goToLicenseDetails={goToLicenseDetails}
            licenses={licenses}
            route={route}
          />
        ) : (
          !licenses.length &&
          !isCropLoading &&
          !isLicenseLoading && <LicensesEmpty goToCrops={goToCrops} />
        )}

        {isCropLoading ||
          (isLicenseLoading && (
            <ActivityIndicator
              size='large'
              color={Colors.green500}
              style={styles.loader}
            />
          ))}
      </ScrollView>
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  safeAreaProvider: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    flex: 1,
    paddingBottom: 16,
  },
  cropName: {
    fontSize: 16,
    fontWeight: '500',
    marginHorizontal: 32,
    marginVertical: 24,
  },
  loader: {
    marginTop: 100,
  },
})

Licenses.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default Licenses
