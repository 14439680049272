import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View } from 'react-native'

import { LanguageContext } from '@contextState/language'

import { blackMediumEmphasis } from '@styles/palette'
import { formatDateMoment } from '@utils/date'

export const PlannedActivityDate = ({ dateStart, dateEnd }) => {
  const { t, i18n } = useContext(LanguageContext)

  const date = `${formatDateMoment(
    dateStart,
    'DD/MMM/YYYY',
    i18n.locale,
    true
  )} - ${formatDateMoment(dateEnd, 'DD/MMM/YYYY', i18n.locale, true)}`

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {t(
          `VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.PLANNED_ACTIVITY_DATE.PLANNED`
        )}
      </Text>
      <Text style={styles.date}>{date}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    letterSpacing: 0.4,
    color: blackMediumEmphasis,
  },
  date: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    letterSpacing: 0.4,
    color: blackMediumEmphasis,
  },
})

PlannedActivityDate.prototype = {
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
}
