import { Platform, Linking } from 'react-native'
import * as FileSystem from 'expo-file-system'
import axios from 'axios'

/**
 * GET FILE TYPE
 *
 * @param {*} path
 *
 * @returns Boolean
 */
export const getFileType = (path) => {
  let fileType

  try {
    const result = /\.(\w+)$/.exec(path)

    fileType = result?.length ? result[1] : undefined
  } catch (error) {
    console.error(error)
  }

  return fileType
}

/**
 * TRANSFORM FILE OF FILE TYPE TO BASE64
 *
 * @param {*} file
 *
 * @returns String
 */
export const fileToBase64 = async (file) => {
  try {
    if (Platform.OS === 'web') {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    } else {
      const fileBase64 = await FileSystem.readAsStringAsync(file.uri, {
        encoding: FileSystem.EncodingType.Base64,
      })

      return `data:${file.type};base64,${fileBase64}`
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * TRANSFORM URL FILE TO BLOB TYPE
 *
 * @param {*} param0
 *
 * @returns Blob
 */
export const urlToBlob = async ({ url }) => {
  const response = await fetch(url)

  return await response.blob()
}

/**
 * TRANSFORM BLOB TYPE TO FILE
 *
 * @param {*} param0
 *
 * @returns File
 */
export const blobToFile = ({ blobFile, fileName, fileType }) => {
  return new File([blobFile], fileName, {
    type: fileType,
  })
}

/**
 * DOWNLOAD FILE
 *
 * @param {*} path
 *
 * @returns
 */
export const downloadFile = (path) => {
  if (Platform.OS === 'web') {
    window.open(path)
  } else {
    Linking.openURL(path)
  }
}

export const downloadFileWeb = async (path, nameFile) => {
  try {
    const response = await axios({
      url: path,
      method: 'GET',
      responseType: 'blob', // important
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', nameFile)
    document.body.appendChild(link)
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
    return true
  } catch (error) {
    return error
  }
}

export const allowedFormatsFiles = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
  '.doc',
  '.docx',
  '.txt',
  '.zip',
]
