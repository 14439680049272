import React, { useContext } from 'react'
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { SustainabilityIcon } from '@modules/common/components'
import CardDefault from '../../cards/CardDefault'
import TextWithOverlap from '../../text/TextWithOverlap'
import ViewAnimatedBackground from './ViewAnimatedBackground'
import { LanguageContext } from '../../../contextState/language'
import { blackMediumEmphasis, lime50, lime600 } from '@styles/palette'
import statusEiq from '@constants/statusEiq'
import { roundSurface } from '@utils/common'

const ItemLot = ({
  item: {
    _id,
    image,
    nameLot,
    surface,
    countAchievement,
    eiq,
    overlappingRange,
    landscapeSustainability,
    areaUnit,
  },
  onGoToLandscapeSustainability,
}) => {
  const { t } = useContext(LanguageContext)
  const colorEiq = { color: statusEiq[eiq.range].color }
  const backgroundEiq = { backgroundColor: statusEiq[eiq.range].background }
  return (
    <CardDefault>
      <TouchableOpacity
        onPress={() =>
          onGoToLandscapeSustainability({ _id, landscapeSustainability })
        }
      >
        <ViewAnimatedBackground extraStyle={{ ...styles.contentAnimated }}>
          <Image source={{ uri: image }} style={styles.image} />
          <View style={styles.containerLot}>
            <Text style={styles.title}>{nameLot}</Text>
            <Text style={styles.text} numberOfLines={1}>
              {`${roundSurface(surface)} ${areaUnit ?? ''}`}
            </Text>
            {overlappingRange && (
              <TextWithOverlap
                percent={parseInt(overlappingRange.percent)}
                styleOverlapping={overlappingRange.style}
              />
            )}
            <Text style={styles.text} numberOfLines={1}>
              {`${countAchievement} ${
                t('COMPONENTS').COMMON.VERTICAL_CARD_LIST.TEXT_1
              }`}
            </Text>
            <View style={[styles.contentEiq, backgroundEiq]}>
              <SustainabilityIcon name='EIQ' size={16} />

              <Text style={[styles.textEiq, colorEiq]}>EIQ {eiq.quantity}</Text>
            </View>
          </View>
        </ViewAnimatedBackground>
      </TouchableOpacity>
    </CardDefault>
  )
}

const styles = StyleSheet.create({
  separator: {
    marginHorizontal: 4,
  },
  image: {
    width: 168,
    height: 168,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  contentAnimated: {
    height: 370,
    width: 168,
  },
  containerLot: {
    padding: 10,
  },
  title: {
    fontSize: 17,
    width: 150,
  },
  text: {
    color: blackMediumEmphasis,
    marginVertical: 7,
  },
  contentEiq: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: lime50,
    borderRadius: 100,
    width: 100,
    padding: 6,
  },
  textEiq: {
    fontWeight: 'bold',
    color: lime600,
  },
})

ItemLot.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    image: PropTypes.string,
    nameLot: PropTypes.string,
    surface: PropTypes.number,
    countAchievement: PropTypes.number,
    eiq: PropTypes.object,
  }),
  onGoToLandscapeSustainability: PropTypes.func.isRequired,
}

export default ItemLot
