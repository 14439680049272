import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { useDetailsFarm } from './hooks'

import { EditableTitle } from '@modules/farms/screens/FarmDetails/v1/components'

import ButtonCustom from '@components/common/ButtonCustom'
import { primary500 } from '@styles/palette'
import { LotList } from './components'
import { CompanyDetails } from '@modules/common/components'
import { userPermissions } from '@utils/permissions'

const FarmLotList = ({ route, navigation }) => {
  const { config } = useContext(AuthContext)
  const { t } = useContext(LanguageContext)
  const isFocused = useIsFocused()

  const { identifier, name: companyName } = config.companySelected

  const { params } = route
  const farmId = params?.farmId
  const name = params?.name

  const { roleSelected } = config
  const hasPermissionCreate = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.EDIT_FIELD
    )
  )

  const { lots, isLoading, nextPage } = useDetailsFarm(
    identifier,
    farmId,
    isFocused,
    navigation
  )

  const goToDetailsLotFarm = (lotId) => {
    navigation.navigate('FarmLotDetails', {
      farmId,
      lotId,
    })
  }

  /**
   * ON GO TO FARM LOTS ADD
   */
  const onGoFarmLotsAdd = () => {
    navigation.navigate('FarmLotsAdd', {
      farmName: name,
      farmUuid: farmId,
      showSuccess: false,
      onReturn: () => {
        navigation.goBack()
      },
    })
  }

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />

      <EditableTitle title={name} />

      <LotList
        lots={lots}
        goToDetailsLotFarm={goToDetailsLotFarm}
        nextPage={nextPage}
        isLoading={isLoading}
      />

      {hasPermissionCreate && (
        <View style={styles.footer}>
          <ButtonCustom
            onPress={onGoFarmLotsAdd}
            styles={styles.button}
            labelStyle={styles.textButton}
          >
            {t('VIEWS.FARM_DETAILS.BUTTON_EDIT_LOTS_TO_FARM')}
          </ButtonCustom>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    paddingHorizontal: 16,
    marginTop: 10,
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: primary500,
    marginVertical: 5,
  },
})

FarmLotList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default FarmLotList
