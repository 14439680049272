import { useNavigation } from '@react-navigation/native'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '@contextState/auth'
import { userPermissions } from '@utils/permissions'
import RouterNames from '@constants/routerNames'
import useFetchPagination from './useFetchPagination'
import { useFocusEffect } from '@react-navigation/core'
import { Platform } from 'react-native'

export const useFarmList = () => {
  const { config } = useContext(AuthContext)
  const [isFocusedScreen, setIsFocusedScreen] = useState(false)

  const navigation = useNavigation()

  const identifier = config.companySelected?.identifier
  const companyName = config.companySelected?.name

  const {
    response: { data },
    isLoading,
    nextPage,
    refreshPage,
    firstPaginate,
    handleFarmSearch,
    hasSearchedFarm,
  } = useFetchPagination({
    url: `farms/companies/${identifier}`,
    version: 'v1',
    method: 'GET',
    params: {
      limit: 15,
    },
  })

  const { roleSelected } = config
  const hasPermissionAdd = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.CREATE_FARM
    )
  )

  const showEmptyState = () => !data?.length

  useFocusEffect(
    useCallback(() => {
      setIsFocusedScreen(true)
      return () => {
        setIsFocusedScreen(false)
      }
    }, [])
  )

  useEffect(() => {
    if (isFocusedScreen) {
      refreshPage()
    }
  }, [identifier, isFocusedScreen])

  /**
   * ON GO TO CREATE FARM
   * @return {void}
   */
  const goToCreateFarm = () => {
    if (Platform.OS === 'web') {
      navigation.navigate(RouterNames.FARM_MAP)
    } else {
      navigation.navigate(RouterNames.FARM_CREATE)
    }
  }

  /**
   * ON GO TO CREATE FARM FROM DRAFT FARM
   * @param {object} farm
   * @param {number} farm.id
   * @param {string} farm.uuid
   * @param {string} farm.name
   * @param {surface} farm.surface
   * @return {void}
   */
  const goToFarmDraft = (farm) => {
    if (Platform.OS === 'web') {
      navigation.navigate(RouterNames.FARM_MAP, {
        farmUuid: farm.uuid,
      })
    } else {
      navigation.navigate(RouterNames.FARM_CREATE, {
        farmUuid: farm.uuid,
      })
    }
  }

  /**
   *
   * @param {object} farm
   * @param {number} farm.id
   * @param {string} farm.uuid
   * @param {string} farm.name
   * @param {surface} farm.surface
   * @return {void}
   */
  const goToFarmAvailable = (farm) => {
    navigation.navigate('FarmDetails', {
      farmId: farm.uuid,
    })
  }

  return {
    isLoading,
    showEmptyState,
    companyName,
    identifier,
    goToFarmDraft,
    goToFarmAvailable,
    goToCreateFarm,
    hasPermissionAdd,
    config,
    nextPage,
    refreshPage,
    data,
    handleFarmSearch,
    firstPaginate,
    hasSearchedFarm,
    isFocusedScreen,
  }
}
