import React, { useState, useEffect, useRef } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Animated,
  FlatList,
  Platform,
} from 'react-native'
import { Input } from 'react-native-elements'
import { Menu, TouchableRipple } from 'react-native-paper'
import PropTypes from 'prop-types'

import {
  blackHighEmphasis,
  primary500,
  blackMediumEmphasis,
  onPressedColor,
  redError,
  buttonDisabled,
  borderInput,
  white,
  placeholderDisabled,
} from '@styles/palette'
import useFadeAnimation from '@hooks/useFadeAnimation'
import useModal from '@hooks/useModal'
import { isEmptyValue } from '@utils/common'
import { MAGIC_SPACE_DROPDOWN, MAGIC_RESULT_MOBILE } from '@constants/magic'

const InputSelectDropdown = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  leftIcon = false,
  rightIcon = false,
  onChangeText,
  value = {},
  inputRef,
  editable = true,
  disabled = false,
  placeholder = '',
  label = '',
  options = [],
  touched,
  error,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [containerLayout, setContainerLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const placeholderFadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(placeholderFadeParams)
  const { isModalVisible, toggleModal, closeModal } = useModal()
  useEffect(() => {
    if (isEmptyValue(value.label)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [value.label])

  const onPress = () => {
    if (!editable || disabled) {
      return
    }

    if (!isModalVisible) {
      onShowModal()
    } else {
      onHideModal()
    }
  }

  const selectOption = (option) => {
    onChangeText(option)

    onHideModal()
  }

  const onShowModal = () => {
    toggleModal()

    setIsFocused(true)
  }

  const onHideModal = () => {
    closeModal()

    setIsFocused(false)

    if (onBlur) {
      onBlur()
    }
  }

  const onLayout = (event) => {
    setContainerLayout(event.nativeEvent.layout)
  }

  const renderLabel = (label) => {
    if (containerLayout?.width <= 0) return label

    const result = containerLayout?.width / MAGIC_SPACE_DROPDOWN

    return result > label?.length
      ? label
      : `${label?.substring(
          0,
          Platform.OS !== 'web' ? result - MAGIC_RESULT_MOBILE : result
        )}...`
  }

  return (
    <Menu
      visible={isModalVisible}
      onDismiss={onHideModal}
      anchor={
        <Pressable onPress={onPress} style={containerStyle} onLayout={onLayout}>
          <Animated.View
            style={[
              styles.placeholderContainer,
              {
                opacity: placeholderOpacity,
              },
            ]}
          >
            <Text
              style={[
                styles.placeholder,
                touched && error ? styles.placeholderError : {},
                isFocused ? styles.focusedPlaceholder : {},
                disabled ? styles.placeholderDisabled : {},
              ]}
            >
              {label ?? placeholder}
            </Text>

            <View style={styles.placeholderBackground}></View>
          </Animated.View>

          <Input
            id={id}
            name={name}
            inputContainerStyle={[
              styles.inputContainer,
              touched && error ? styles.borderError : {},
              isFocused ? styles.focusedInput : {},
            ]}
            inputStyle={[styles.input, inputStyle]}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            value={renderLabel(value.label)}
            ref={inputRef}
            editable={false}
            disabled={disabled}
            placeholder={placeholder}
            placeholderTextColor={blackMediumEmphasis}
            errorStyle={styles.textError}
            errorMessage={touched && error}
            rightIconContainerStyle={styles.rightIconStyle}
          />
        </Pressable>
      }
      style={{
        maxWidth: containerLayout.width,
        width: containerLayout.width,
        marginTop:
          containerLayout.height -
          (Platform.OS === 'web' ? (touched && error ? 22 : 7) : 25),
      }}
    >
      <FlatList
        data={options}
        style={styles.modalListContainer}
        renderItem={({ item }) => (
          <TouchableRipple
            onPress={() => {
              if (!item.disabled) {
                selectOption(item)
              }
            }}
            style={styles.modalListOption}
            rippleColor={onPressedColor}
            underlayColor={onPressedColor}
          >
            <Text
              style={
                item.disabled ? styles.textDisabled : styles.modalListOptionText
              }
              numberOfLines={1}
            >
              {item.label}
            </Text>
          </TouchableRipple>
        )}
        keyExtractor={(item, index) => `SELECT_OPTION_${index}`}
      />
    </Menu>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: white,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: borderInput,
    borderRadius: 2,
    height: 62,
    paddingHorizontal: 16,
    marginHorizontal: -10,
    zIndex: 1,
  },
  borderError: {
    borderColor: redError,
  },
  textError: {
    color: redError,
    fontSize: 12,
    marginLeft: 9,
  },
  input: {
    fontSize: 15,
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: placeholderDisabled,
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: white,
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
  modalListContainer: {
    maxHeight: 300,
  },
  modalListOption: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    paddingHorizontal: 16,
  },
  modalListOptionText: {
    fontSize: 15,
    color: blackHighEmphasis,
  },
  textDisabled: {
    fontSize: 15,
    color: buttonDisabled,
  },
  modalBackScreen: {
    backgroundColor: redError,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  rightIconStyle: {
    position: 'absolute',
    right: 15,
    backgroundColor: white,
  },
})

InputSelectDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    label: PropTypes.string,
  }),
  inputRef: PropTypes.object,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default InputSelectDropdown
