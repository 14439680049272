import { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useConfig } from './useConfig'
import { useCompanyInfo } from './useCompanyInfo'
import { useNavigation } from '@react-navigation/native'

const STORIES_URL = process.env.FIELD_STORY_URL

export enum MESSAGE_KEY {
  GO_TO_FIELD_STORY = 'GO_TO_FIELD_STORY',
}

interface QueryParams {
  cropId: string
  lotId?: string
}

export const useStoriesWebView = (url: string, query: QueryParams) => {
  const navigation = useNavigation()
  const { identifier: taxID } = useCompanyInfo()
  const config = useConfig()

  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')

      setAccessToken(token)
    })()
  }, [])

  const makeWebViewUrl = () => {
    if (!accessToken) return ''

    const params = {
      ...query,
      taxID,
      accessToken,
      isApp: 'true',
      date: `${new Date().getTime()}`,
    }

    const searchParams = new URLSearchParams(params)
    const queryParams = searchParams.toString()

    return `${STORIES_URL}/${url}?${queryParams}`
  }

  const handleIFrameMessages = (event: any) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data

    switch (data.key) {
      case MESSAGE_KEY.GO_TO_FIELD_STORY:
        // @ts-ignore
        navigation?.push('FieldStory', {
          id: query.cropId,
          fieldStory: true,
          lotId: data?.data?.queryParams?.lotId,
        })
        break

      default:
        break
    }

    return data
  }

  return {
    accessToken,
    url: makeWebViewUrl(),
    handleIFrameMessages,
  }
}
