import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { carbon500, blackHighEmphasis } from '@styles/palette'

const ICON_COLLABORATORS = 'COLLABORATORS'

export const CollaboratorCard = ({ collaboratorsQuantity }) => {
  const { t } = useContext(LanguageContext)

  const collaboratorLabel =
    collaboratorsQuantity !== 1
      ? t(
          'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.COLLABORATOR_CARD.COLLABORATORS_SELECTED',
          { collaboratorsQuantity }
        )
      : t(
          'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.COLLABORATOR_CARD.COLLABORATOR_SELECTED',
          { collaboratorsQuantity }
        )

  return (
    <Card>
      <View style={styles.infoContainer}>
        <View style={styles.collaboratorContainer}>
          <CommonIcon
            name={ICON_COLLABORATORS}
            size={45}
            style={styles.collaboratorIcon}
          />

          <View>
            <Text style={styles.textBold}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.COLLABORATOR_CARD.COLLABORATOR_TITLE'
              )}
            </Text>

            <Text style={styles.text}>{collaboratorLabel}</Text>
          </View>
        </View>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  collaboratorContainer: {
    flexDirection: 'row',
  },
  collaboratorIcon: {
    marginRight: 20,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon500,
    marginBottom: 2,
  },
})

CollaboratorCard.propTypes = {
  collaboratorsQuantity: PropTypes.number.isRequired,
}
