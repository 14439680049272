// DECRAPED: please use src/modules/common/styles
export const primary50 = '#E8F5E9'
export const primary100 = '#DBEFDC'
export const primary200 = '#B7DFB9'
export const primary500 = '#4CAF50'
export const primary700 = '#388E3C'
export const primary800 = '#2E7D32'
export const primary900 = '#1B5E20'
export const primaryEmphasis = '#4CAF5055'
export const lightGreen900 = '#33691E'
export const lightGreen100 = '#DCEDC8'
export const greenOpacity50 = 'rgba(76, 175, 80, 0.5)'
export const lime900 = '#827717'
export const lime50 = '#F9FBE7'
export const lime600 = '#C0CA33'
export const amber900 = '#FF6F00'
export const amber50 = '#FFF8E1'
export const secondary900 = '#BF360C'
export const secondary700 = '#E64A19'
export const secondary600 = '#F4511E'
export const secondary500 = '#FF5722'
export const secondary300 = '#FF8A65'
export const secondary100 = '#FFCCBC'
export const redError = '#FF0C3E'
export const secondary50 = '#FBE9E7'
export const buttonDisabled = 'rgba(0, 0, 0, 0.12)'
export const blackMediumEmphasis = 'rgba(51, 51, 51, 0.7)'
export const grayInput = 'rgba(0, 0, 0, 0.54)'
export const gray12 = 'rgba(0, 0, 0, 0.12)'
export const gray1 = 'rgba(0, 0, 0, 0.1)'
export const gray7 = 'rgba(0, 0, 0, 0.7)'
export const grayDA = '#DADADA'
export const gray = '#7E7E7E'
export const blackHighEmphasis = '#333333'
export const grayBackground = '#F8F8F8'
export const primary500Disabled = '#81C784'
export const white = '#FFF'
export const black = '#000'
export const red = '#FF0C3E'
export const errorLight = '#FFCCBC'
export const placeholderColor = 'rgba(0, 0, 0, .6)'
export const onPressedColor = 'rgba(0, 0, 0, .1)'
export const grayBlueLight = '#90A4AE'
export const LightSalmon = '#FF8A65'
export const teal600 = '#00897B'
export const blackDisabled = 'rgba(51, 51, 51, 0.38)'
export const primaryGradient = 'rgba(232, 192, 39, 0.75)'
export const backgroundDefault = '#F2F2F2'
export const teal300 = '#4DB6AC'
export const blueGrey700 = '#455A64'
export const blueGrey50 = '#ECEFF1'
export const orange50 = '#FFF3E0'
export const orange100 = '#FFE0B2'
export const Orange500 = '#FF9800'
export const colorWarningMessage = 'rgba(255, 204, 188, 1)'
export const lightGreen500 = '#8BC34A'
export const lightGreen50 = '#F1F8E9'
export const blackText50 = '#696D6A'
export const darkGreen50 = '#66BB6A'
export const greenLight = '#79C37C'
export const grey300 = '#0000001f'
export const grey500 = '#33333361'
export const transparent = 'transparent'
export const grey700 = '#9d9d9d'
export const yellow500 = '#FFD100'
export const yellow600 = '#FDD835'
export const blackHighEmphasisBorder = 'rgba(51, 51, 51, 0.12)'
export const whiteTransparent = 'rgba(255, 255, 255, 0.3)'
export const blueGray100 = '#CFD8DC'
export const whiteLight = '#FAFAFA'
export const white200 = '#f2f2f2'
export const grayLight = '#E6E6E6'
export const green = 'green'
export const gray999 = '#999999'
export const borderInput = 'rgba(0, 0, 0, .16)'
export const placeholderDisabled = 'rgba(0, 0, 0, .4)'
export const blackInactive = 'rgba(51, 51, 51, 0.54)'
export const yellow200 = '#FCF1CB'
export const brown400 = '#A84E20'
export const carbon600 = '#495365'
export const carbon500 = '#687083'
export const carbon400 = '#9AA1B2'
export const carbon800 = '#1F2938'
export const surfaceSnackbar = 'rgba(32, 32, 32, 0.12)'
export const carbon300 = '#D1D5DD'
export const carbon200 = '#EAECF0'
export const carbon100 = '#F3F4F7'
export const carbon50 = '#FAFAFB'
export const cherry400 = '#F97066'
export const alertText = '#F04438'
export const cherry50 = '#FEF3F2'
export const cherry200 = '#FECDCA'
export const cherry300 = '#FDA29B'
export const cherry600 = '#D92D20'
export const lemon100 = '#FEFAEC'
export const lemon500 = '#FDB022'
export const lemon300 = '#F9E094'
export const lemon400 = '#FAE6AE'
export const grayDDD = '#DDDDDD'
export const redV2 = '#EB5757'
export const green1 = '#219653'
export const green700 = '#2E6930'
export const green300 = '#94CF96'
export const gray4 = '#BDBDBD'
export const graySnackBar = '#4F4F4F'
export const red200 = '#FFAA90'
export const red300 = '#FF7043'
export const red400 = 'rgba(255, 87, 34, .5)'
export const backgroundModal = 'rgba(51, 51, 51, 1)'

export const stateLicenseGreen = 'rgba(76, 175, 80, .7)'
export const stateLicenseYellow = 'rgba(255, 193, 7, .7)'
export const stateLicenseGreenLight = 'rgba(139, 195, 74, .7)'
// DECRAPED: please use src/modules/common/styles
