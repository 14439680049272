import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Snackbar, ActivityIndicator } from 'react-native-paper'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Linking from 'expo-linking'
import { useSelector } from 'react-redux'

import activityTypes from '@constants/activityTypes'
import { AuthContext } from '@contextState/auth'
import { ConnectionContext } from '@contextState/connection'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { signInAnonymously } from '@services/firebase'
import Header from '@components/common/Header'
import { HeaderOnlyBack } from '@common/components'
import RegisterScreen from '../screens/RegisterScreen'
import CompanyOnboardingAdd from '../screens/CompanyOnboardingAdd'
import VerifyCodeScreen from '@modules/authentication/screens/VerifyCode'
import DrawerNavigator from './DrawerNavigator'
import LotsSelectorScreen from '../screens/LotsSelectorScreen'
import CollaboratorsSelectorScreen from '../screens/CollaboratorsSelectorScreen'
import SupplySelector from '../screens/v1/supplySelector'
import SupplyLoaderScreen from '@modules/activities/screens/SupplyLoader/v1/SupplyLoaderScreen'
import CommonLoader from '@modules/loaders/screens/CommonLoader/v1'
import {
  white,
  graySnackBar,
  carbon300,
  carbon100,
  Orange500,
  secondary600,
} from '@styles/palette'

/******************************
 *        NOTIFICATIONS       *
 *****************************/
import NotificationList from '@modules/notifications/screens/NotificationList/v1'
import NotificationFilters from '../modules/notifications/screens/Filters/v1'

/******************************
 *         ACTIVITIES         *
 *****************************/
import ActivityCreate from '@modules/activities/screens/activityCreate/v1'
import CommonActivitiesDetail from '../screens/CommonActivitiesDetail'
import ActivityMinimumUnitLot from '../screens/v1/ActivityMinimumUnitLot'
import SupplyDetail from '../screens/v1/supplyDetail/index'

import SignInV2 from '@modules/authentication/screens/SignIn/v2'

import CreatePin from '@modules/pin/screens/CreatePin'
import SignUp from '@modules/authentication/screens/SignUp/v1'

/******************************
 *         COMPANIES         *
 *****************************/
import WelcomeCompany from '@modules/companies/screens/WelcomeCompany/v1'
import ValidateTaxId from '@modules/companies/screens/ValidateTaxId/v1'
import AddCompanyScreen from '@modules/companies/screens/AddCompanyScreen/v1'
import CollaboratorsListNewCompany from '../modules/collaborators/screens/CollaboratorsListNewCompany/v1'
import SuccessAddCompany from '../modules/companies/screens/SuccessAddCompany'
import { ResponsiveLayout } from '@common/components'
import FarmMap from '@modules/farms/screens/FarmMap/v1'
import RouterNames from '@constants/routerNames'
import { LayoutWithOutResponsive } from '@common/components/index'

const Stack = createStackNavigator()

const linking = {
  prefixes: [
    Linking.makeUrl('/'),
    'https://developer-crop-story.ucrop.it',
    'https://app.ucrop.it',
    'https://developer-sn-deeplinking.ucrop.it',
  ],
  config: {
    screens: {
      Main: {
        screens: {
          Tab: {
            path: '',
            screens: {
              LandscapeSustainability:
                '/crop/:cropId/lot/:lotId/landscape-sustainability',
              CropsCollaborators: '/crop-collaborators-add/:id',
              CropCompany: '/crop/:id/company',
              CropCollaborators: '/crop/:id/collaborators/:status?',
              CollaboratorsDelegateSignatures:
                '/crop/:cropId/collaborators/delegate-signatures/:collaboratorsIds',
              UnlinkCollaborator:
                '/crop/:cropId/collaborators/unlink/:collaboratorsIds',
              CropCollaboratorsAdd: '/crop/:id/collaborators-add',
              ServiceIntegrations: '/crop/:id/services-integrations',
              IntegrationQueue:
                '/crop/:id/services-integrations/queue/:id/:service?',
              SyncDetail: '/crop/:id/services-integrations/detail/:service?',
              CompanyServiceIntegration: '/company/:id/company-integrations',
              CompanyServiceIntegrationAdd:
                '/company/:id/company-integrations-add/:mode?/:service?',
              CompanyServiceIntegrationUnlink:
                '/company/:id/company-integrations-unlink/:service',
              CompanyIntegrationAppList:
                '/company/:id/company-integrations-list',
              CompanyIntegrationAgreement:
                '/company/:id/company-integrations-agreement/:service',
              CompanyServiceIntegrationLink:
                '/company/:id/company-integration-link/:service',
              CompanyServiceIntegrationsSelector:
                '/company/:id/company-integrations-select',
              CompanyServiceIntegrationAccountSelector:
                '/company/:id/company-integrations-account-select/:service',
              CompanyProfile: '/company-profile',
              CropSelectResponsible: '/crops/create/select-responsible',
              LotsAdd: '/crops/create/lots-add',
              KmzAdd: '/crops/create/lots-add/kmz-add',
              CropCreateConfirm: '/crops/create/confirm',
              LicenseDetails: '/crop/:cropId/licenses/:licenseId',
              LicenseSign: '/crop/:cropId/licenses/:licenseId/sign',
              LicenseLotsAdd: '/crop/:cropId/licenses/:licenseId/lots-add',
              SummaryLicenseLots:
                '/crop/:cropId/licenses/:licenseId/summary-lots',
              SummaryLandscapeSustainability:
                '/crop/:cropId/licenses/:licenseId/summary',
              AchievementValidation: '/achievement-validation',
              LotsSelectorModal: '/selectors/:id/lots/:type?/:status?',
              CollaboratorsSelectorModal:
                '/selectors/:id/collaborators/:status?',
              SupplySelectorModal: '/selectors/:id/supply/:tag?/:activity?',
              SupplyLoaderScreen:
                '/selectors/:id/supply/:tag?/:activity?/selectedSupply',
              CollaboratorCreate: '/crops/create/collaborators/create',
              CropList: {
                path: 'crops',
                screens: {
                  CropList: '/list',
                  CropCreate: '/create',
                  AchievementsActivityList: '/achievements-activity-list',
                  CompanyAdd: '/company-add',
                  CropDetail: '/:id',
                  Licenses: '/crop/:cropId/licenses',
                  Activities: '/activities/:id',
                  ActivityList: '/:cropId/activities',
                  FilterActivities: '/:cropId/activities/filter',
                  Agreement: '/activities/:id/agreement',
                  AgreementEdit: '/activities/:id/agreement/:agreement',
                  Monitoring: '/activities/:id/monitoring',
                  MonitoringEdit: '/activities/:id/monitoring/:monitoring',
                  Harvest: '/activities/:id/harvest',
                  HarvestEdit: '/activities/:id/harvest/:harvest',
                  CommonActivitiesDetail:
                    'activities/:id/:mode/common/detail/:achievement/:activity/:canWrite?',
                  CommonActivities:
                    '/activities/:id/:mode/:tag/common/:activity?',
                  CommonActivitiesEdit:
                    '/activities/:id/:mode/common/edit/:activity',
                  CommonOptionalPlanned: {
                    path: '/activities/:id/:mode/:tag/common/optionalPlanned/:activity?',
                  },
                  AchievementsScreen:
                    '/activities/:id/common/achievement/:activity/:tag?',
                  ActivityCreate: '/crops/:cropId?/activity/create',
                  ActivityUpdate: '/crops/:cropId?/activity/update',
                  ActivityFinished:
                    '/crops/:cropId?/activity/:activityId/finished',
                  ActivityLotsAdd:
                    '/crop/:cropId/activity/:activityId/achievement/lots-add',
                  ActivityMinimumUnitLot:
                    '/crop/:cropId/activity/:activityId/achievement/lots-add/edit-Surface',
                  activityValidation: '/activity-validation',
                  activityCreateConfirm:
                    '/crop/:cropId/activity/create/confirm',
                  ActivityApplicationDetails:
                    '/crop/:cropId/activities/application/:activityId',
                  DetailsPlanning:
                    '/crop/:cropId/activity/:activityId/detailsPlanning',
                  DetailsRealization:
                    '/crop/:cropId/activity/:activityId/achievement/detailsRealization',
                  CropFarmList: {
                    path: '/:cropId/farms',
                  },
                  CropFarmFieldDetails: {
                    path: '/:cropId/farms/:farmId/fields/:fieldId',
                  },
                  FarmLotEditName: {
                    path: '/:cropId/farms/:farmUuid/lots/:lotUuid/edit',
                  },
                },
              },
              FarmList: {
                path: 'farms',
                screens: {
                  FarmList: '/list',
                  FarmCreate: '/create',
                  FarmLotsAdd: '/create/lots-add',
                  FarmDetails: '/:farmId',
                  FarmEditName: '/:farmUuid/edit',
                  FarmLotList: '/:farmId/lots/:name',
                  FarmLotDetails: '/:farmId/lots/:lotId',
                  FarmLotEditName: '/:farmUuid/lots/:lotUuid/edit',
                },
              },
              LicenseList: {
                path: 'licenses',
                screens: {
                  LicenseList: '/list',
                  LicenseDetail: '/:licenseId',
                },
              },
            },
          },
        },
      },
      NotificationList: {
        path: 'notifications/list',
      },
      NotificationFilters: {
        path: 'notifications/filters',
      },
      Pin: {
        path: 'pin',
        screens: {
          PinWizard: '/wizard',
          EnterPin: '/enter',
        },
      },
      SignUp: { path: 'signUp' },
      Company: {
        path: 'company',
        screens: {
          WelcomeCompany: '/welcome',
        },
      },
    },
  },
}

const RootStack = createStackNavigator()

export default function Navigator() {
  const { t } = useContext(LanguageContext)
  const {
    isNotificationModalVisible,
    closeModalNotification,
    notification,
    isFetchingCommonSuccess,
  } = useContext(CommonContext)
  const {
    isAuth,
    setAuth,
    config,
    fetchUser,
    user,
    fetchCompany,
    addCompanyConfigUser,
  } = useContext(AuthContext)

  const {
    isConnected,
    lowConnection,
    isDraftsSynchronizing,
    setIsDraftsSynchronizing,
  } = useContext(ConnectionContext)

  const [isReadyAuth, setIsReadyAuth] = useState(false)

  const welcomeCompany = useSelector((state) =>
    (state?.auth?.welcomeCompanyList ?? []).find(
      (item) => item?.email === user?.email
    )
  )

  const fullScreen = useSelector((state) => Boolean(state?.farm?.fullScreen))

  const fetchAuth = async function (token) {
    try {
      await fetchUser()

      if (user && user.companies.length && !config.companySelected) {
        const company = await fetchCompany(user.companies[0].identifier)

        if (company) {
          await addCompanyConfigUser(
            user.config._id,
            company._id,
            company.identifier
          )
          await fetchUser()
        }
      }
      setAuth(token)
    } catch (err) {
      console.warn('ERR', err)
      if (err?.status === 401) {
        await AsyncStorage.removeItem('USER_TOKEN')
        await AsyncStorage.removeItem('USER_DATA')
        setAuth(null)
      }
    }
    setIsReadyAuth(true)
  }

  // prettier-ignore
  useEffect(() => {
    (async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      if (!token) {
        setIsReadyAuth(true)
        return
      }
      await fetchAuth(token)
    })()
  }, [isAuth])

  useEffect(() => {
    if (!isAuth || !user) {
      return
    }

    signInAnonymously(user)
  }, [isAuth, user])

  const AuthStack = function () {
    return (
      <Stack.Navigator animationEnabled>
        <Stack.Screen
          name='SignIn'
          component={SignInV2}
          options={{
            title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_1,
            header: () => null,
          }}
        />
        <Stack.Screen
          name='Register'
          component={RegisterScreen}
          options={{
            title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_2,
            header: (props) => <Header {...props} goBack='SignIn' />,
          }}
        />
        <Stack.Screen
          name='SignUp'
          component={SignUp}
          options={{
            header: (props) => <HeaderOnlyBack {...props} title={''} />,
          }}
        />
        <Stack.Screen
          name='VerifyCode'
          component={VerifyCodeScreen}
          options={{
            title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_3,
            header: (props) => <HeaderOnlyBack {...props} title={''} />,
          }}
        />
      </Stack.Navigator>
    )
  }

  const CompanyOnboardingAddStack = function () {
    return (
      <Stack.Navigator animationEnabled initialRouteName={'WelcomeCompany'}>
        <Stack.Screen
          name='WelcomeCompany'
          component={WelcomeCompany}
          options={{
            title: '',
            header: () => null,
          }}
        />
        <Stack.Screen
          name='CompanyOnboardingAdd'
          component={CompanyOnboardingAdd}
          options={{
            title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_5,
            header: (props) => (
              <Header
                {...props}
                title={t('NAVIGATION').INDEX_PRINCIPAL.TEXT_6}
                goBack
              />
            ),
          }}
        />
        <Stack.Screen
          name='ValidateTaxId'
          component={ValidateTaxId}
          options={{
            title: '',
            // eslint-disable-next-line react/display-name
            header: (props) => <HeaderOnlyBack {...props} title={''} />,
          }}
        />
        <Stack.Screen
          name='CompanyAddOnboarding'
          initialParams={{}}
          component={AddCompanyScreen}
          options={{
            title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_9,
            // eslint-disable-next-line react/display-name
            header: (props) => {
              const { isAdviser } = props?.scene?.route?.params
              const title = isAdviser
                ? t('NAVIGATION.STACK_NAVIGATOR.ADVISE_COMPANY')
                : t('NAVIGATION.STACK_NAVIGATOR.MANAGE_COMPANY')
              return <Header {...props} title={title} goBack />
            },
          }}
        />
        <Stack.Screen
          name='SuccessAddCompany'
          component={SuccessAddCompany}
          options={{
            title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_9,
            header: () => null,
          }}
        />

        <Stack.Screen
          name='CollaboratorsListNewCompany'
          component={CollaboratorsListNewCompany}
          initialParams={{}}
          options={{
            // eslint-disable-next-line react/display-name
            header: (props) => {
              return (
                <Header
                  {...props}
                  title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
                  goBack
                />
              )
            },
          }}
        />
      </Stack.Navigator>
    )
  }

  if (!isReadyAuth) return null
  if (!isFetchingCommonSuccess && isAuth)
    return (
      <ResponsiveLayout>
        <CommonLoader />
      </ResponsiveLayout>
    )

  return (
    <LayoutWithOutResponsive>
      <NavigationContainer linking={linking}>
        {!isAuth ? (
          <ResponsiveLayout>
            <AuthStack />
          </ResponsiveLayout>
        ) : !welcomeCompany?.hide && !config?.companySelected ? (
          <ResponsiveLayout fullScreen={fullScreen}>
            <CompanyOnboardingAddStack />
          </ResponsiveLayout>
        ) : (
          <RootStack.Navigator mode='Modal'>
            <RootStack.Screen name='Main' options={{ headerShown: false }}>
              {(props) => (
                <DrawerNavigator
                  {...props}
                  companyTypes={config?.companySelected?.types.map(
                    (element) => element.name
                  )}
                />
              )}
            </RootStack.Screen>

            <RootStack.Screen
              name={RouterNames.FARM_MAP}
              component={FarmMap}
              options={{
                title: '',
                header: () => null,
              }}
            />

            <RootStack.Screen
              name='SignInV2'
              component={SignInV2}
              options={{
                title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_1,
                header: () => null,
              }}
            />

            <Stack.Screen
              name='NotificationList'
              component={NotificationList}
              options={{
                title: t('VIEWS.NOTIFICATIONS.NAVIGATION.TITLE'),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('VIEWS.NOTIFICATIONS.NAVIGATION.TITLE')}
                    filtersScreen='NotificationFilters'
                    goBack
                  />
                ),
              }}
            />

            <Stack.Screen
              name='NotificationFilters'
              component={NotificationFilters}
              options={{
                title: t('VIEWS.NOTIFICATION_FILTER.NAVIGATION.TITLE'),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('VIEWS.NOTIFICATION_FILTER.NAVIGATION.TITLE')}
                    close
                    showResetNotificationFilters
                  />
                ),
              }}
            />

            <Stack.Screen
              name='CommonActivitiesDetail'
              component={CommonActivitiesDetail}
              options={{
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_41')}
                    goBack
                  />
                ),
              }}
            />

            <Stack.Screen
              name='ActivityUpdate'
              component={ActivityCreate}
              options={({ route }) => ({
                title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
                  activityType: activityTypes[route.params.tag].name(t),
                }),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
                      activityType: activityTypes[route.params.tag].name(t),
                    })}
                    goBack
                  />
                ),
              })}
            />

            <Stack.Screen
              name='ActivityMinimumUnitLot'
              component={ActivityMinimumUnitLot}
              options={{
                title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_42'),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_42')}
                    goBack
                  />
                ),
              }}
            />

            <Stack.Screen
              name='SupplyDetail'
              component={SupplyDetail}
              options={{
                title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_44'),
                header: (props) => {
                  return (
                    <Header
                      {...props}
                      title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_44')}
                      goBack
                    />
                  )
                },
              }}
            />

            <RootStack.Screen
              name='LotsSelectorModal'
              options={{
                title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_7,
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION').INDEX_PRINCIPAL.TEXT_7}
                    goBack
                  />
                ),
              }}
              component={LotsSelectorScreen}
            />

            <RootStack.Screen
              name='CollaboratorsSelectorModal'
              options={{
                title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_8,
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION').INDEX_PRINCIPAL.TEXT_8}
                    goBack
                  />
                ),
              }}
              component={CollaboratorsSelectorScreen}
            />
            <RootStack.Screen
              name='SupplySelectorModal'
              options={{
                title: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_9,
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION').INDEX_PRINCIPAL.TEXT_9}
                    goBack
                  />
                ),
              }}
              component={SupplySelector}
            />
            <RootStack.Screen
              name='CreatePin'
              options={{
                title: '',
                header: () => null,
              }}
              component={CreatePin}
            />

            <RootStack.Screen
              name='SupplyLoaderScreen'
              options={{
                title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_43,
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_43}
                    goBack
                  />
                ),
              }}
              component={SupplyLoaderScreen}
            />
          </RootStack.Navigator>
        )}
      </NavigationContainer>

      {!isConnected && (
        <View style={styles.contentNotConnection}>
          <Text style={styles.textInfoConnection}>
            {t('NAVIGATION').INDEX_PRINCIPAL.TEXT_10}
          </Text>
        </View>
      )}

      {lowConnection && (
        <View style={styles.lowConnection}>
          <Text style={styles.textInfoConnection}>
            {t('NAVIGATION').INDEX_PRINCIPAL.TEXT_11}
          </Text>
        </View>
      )}

      <Snackbar
        visible={isDraftsSynchronizing}
        style={styles.snackbar}
        onDismiss={() => {}}
        action={{
          label: t('NAVIGATION').INDEX_PRINCIPAL.TEXT_12,
          onPress: () => {
            setIsDraftsSynchronizing(false)
          },
        }}
      >
        <View style={styles.syncSnackbarContainer}>
          <ActivityIndicator
            size='small'
            color={white}
            style={styles.syncSnackbarContainerLoader}
          />

          <Text style={styles.syncSnackbarContainerText}>
            {t('NAVIGATION').INDEX_PRINCIPAL.TEXT_13}
          </Text>
        </View>
      </Snackbar>

      <Snackbar
        visible={isNotificationModalVisible && !isDraftsSynchronizing}
        style={[styles.snackbar, notification.extraStyle]}
        onDismiss={closeModalNotification}
        duration={notification.duration}
      >
        <Text style={styles.notificationSnackbarContainerText}>
          {notification.text}
        </Text>
      </Snackbar>
    </LayoutWithOutResponsive>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: carbon100,
  },
  contentNavigator: {
    flex: 1,
    width: '100%',
  },
  contentPermanent: {
    width: 960,
    borderLeftColor: carbon300,
    borderLeftWidth: 1,
    borderRightColor: carbon300,
    borderRightWidth: 1,
  },
  snackbar: {
    backgroundColor: graySnackBar,
  },
  syncSnackbarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  syncSnackbarContainerLoader: {
    marginRight: 10,
  },
  syncSnackbarContainerText: {
    color: white,
    fontSize: 14,
    fontWeight: '400',
  },
  notificationSnackbarContainerText: {
    color: white,
    fontSize: 14,
    fontWeight: '400',
  },
  headerOnlyBack: {
    flex: 1,
  },
  lowConnection: {
    padding: 4,
    justifyContent: 'center',
    backgroundColor: Orange500,
  },
  textInfoConnection: {
    textAlign: 'center',
    color: white,

    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  contentNotConnection: {
    justifyContent: 'center',
    backgroundColor: secondary600,
    height: 40,
  },
})
