import { Platform } from 'react-native'
import ImageWizard1 from '../Images/img_wizard-s1.svg'
import ImageWizard2 from '../Images/img_wizard-s2.svg'
import ImageWizard3 from '../Images/img_wizard-s3.svg'
export const IMAGE_WIZZARD_1 =
  Platform.OS === 'web'
    ? ImageWizard1
    : `<svg width="272" height="272" viewBox="0 0 272 272" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="136" cy="136" r="136" fill="#EDF7ED"/>
    <path d="M123.95 66H69.7251C53.3084 66 40 79.2806 40 95.7251V228.275C40 244.719 53.3084 258 69.7251 258H123.95C140.395 258 153.675 244.689 153.675 228.275V95.7251C153.675 79.3106 140.394 66 123.95 66Z" fill="#B7DFB9"/>
    <path d="M153.675 228.275V95.7251C153.675 79.3106 140.394 66 123.95 66H96.8376V258H123.95C140.394 258 153.675 244.689 153.675 228.275Z" fill="#DBEFDC"/>
    <path d="M123.95 77.25H69.7251C59.521 77.25 51.25 85.5041 51.25 95.7251V228.275C51.25 238.496 59.521 246.75 69.7251 246.75H123.95C134.19 246.75 142.425 238.459 142.425 228.275V95.7251C142.425 85.5412 134.189 77.25 123.95 77.25Z" fill="#66BB6A"/>
    <path d="M142.425 228.275V95.7251C142.425 85.5412 134.189 77.25 123.95 77.25H96.8376V246.75H123.95C134.189 246.75 142.425 238.459 142.425 228.275Z" fill="#81C784"/>
    <path d="M105.875 101.35H87.8002C84.6937 101.35 82.1752 98.8314 82.1752 95.7249C82.1752 92.6184 84.6937 90.0999 87.8002 90.0999H105.875C108.982 90.0999 111.5 92.6184 111.5 95.7249C111.5 98.8314 108.982 101.35 105.875 101.35Z" fill="#94CF96"/>
    <path d="M214.325 233.9H69.7247C67.4496 233.9 65.3983 232.53 64.5279 230.428C63.6572 228.326 64.1383 225.906 65.7471 224.298L80.2914 209.753C81.5068 208.54 82.1743 206.926 82.1743 205.208V146.938C82.1743 137.09 90.1641 129.263 99.8492 129.263H214.324C224.001 129.263 231.999 137.083 231.999 146.938V216.225C232 225.971 224.071 233.9 214.325 233.9Z" fill="#FCFDFD"/>
    <path d="M202.275 153.363C182.618 153.363 165.535 164.582 157.087 180.953C148.64 164.582 131.556 153.363 111.9 153.363C108.793 153.363 106.275 155.881 106.275 158.988C106.275 187.006 129.069 209.8 157.087 209.8C185.105 209.8 207.9 187.006 207.9 158.988C207.9 155.881 205.382 153.363 202.275 153.363Z" fill="#66BB6A"/>
    <path d="M157.087 209.8C185.105 209.8 207.9 187.006 207.9 158.988C207.9 155.881 205.381 153.363 202.275 153.363C182.618 153.363 165.535 164.582 157.087 180.953V209.8Z" fill="#2E7D32"/>
    <path d="M111.5 95.7249C111.5 92.6184 108.982 90.0999 105.875 90.0999H96.8376V101.35H105.875C108.982 101.35 111.5 98.8314 111.5 95.7249Z" fill="#94CF96"/>
    </svg>
    `

export const IMAGE_WIZZARD_2 =
  Platform.OS === 'web'
    ? ImageWizard2
    : `<svg width="272" height="272" viewBox="0 0 272 272" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="136" cy="136" r="136" fill="#EDF7ED"/>
    <path d="M188.711 158.268L159.66 143.7C157.726 142.731 155.39 143.11 153.86 144.644L124.132 174.46L95.0813 159.893C93.1469 158.923 90.811 159.303 89.2816 160.837L56 194.216C56 194.216 56 196.443 56 197.779V213.961C56 216.744 56.7781 219 59.5529 219H221V138.049C221 133.56 215.588 131.312 212.423 134.486L188.711 158.268Z" fill="#66BB6A"/>
    <path d="M92.2927 218.656H124.548V174.294L95.5282 159.785C94.5024 159.272 93.3641 159.142 92.2927 159.358V218.656Z" fill="#81C784"/>
    <path d="M156.802 218.656H189.057V158.167L160.038 143.658C159.012 143.145 157.873 143.015 156.802 143.23V218.656Z" fill="#81C784"/>
    <path d="M215.982 214H92V224H215.982C218.753 224 221 221.761 221 219C221 216.239 218.753 214 215.982 214Z" fill="#333333" fill-opacity="0.38"/>
    <path d="M216 222C213.239 222 211 219.753 211 216.981L211 79.0189C211 76.2471 213.239 74 216 74C218.761 74 221 76.2471 221 79.0189V216.981C221 219.753 218.761 222 216 222Z" fill="#1B5E20"/>
    <path d="M256.656 31C239.118 31 223.874 41.0107 216.337 55.6174C208.8 41.0104 193.557 31 176.019 31C173.247 31 171 33.2471 171 36.0189C171 61.0181 191.338 81.3563 216.337 81.3563C241.337 81.3563 261.675 61.0177 261.675 36.0189C261.675 33.2468 259.428 31 256.656 31Z" fill="#66BB6A"/>
    <path d="M216.337 81.3563C241.337 81.3563 261.675 61.0181 261.675 36.0189C261.675 33.2471 259.428 31 256.656 31C239.118 31 223.874 41.0107 216.337 55.6174V81.3563Z" fill="#1B5E20"/>
    <path d="M177.293 224.172H32.0189C28.5 221.5 29.5 221.5 27 219.153C27 216.381 26 219.153 24.0189 214.134H177.293C180.065 214.134 182.312 216.381 182.312 219.153C182.312 221.924 180.065 224.172 177.293 224.172Z" fill="#66BB6A"/>
    <path d="M55.514 214L221 214V224H55.514C52.7449 224 50.5 221.761 50.5 219C50.5 216.239 52.7449 214 55.514 214Z" fill="#1B5E20"/>
    </svg>
    
  `
export const IMAGE_WIZZARD_3 =
  Platform.OS === 'web'
    ? ImageWizard3
    : `<svg width="272" height="272" viewBox="0 0 272 272" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="136" cy="136" r="136" fill="#EDF7ED"/>
  <g clip-path="url(#clip0_830_12517)">
  <path d="M102.889 115.713C102.889 113.071 105.022 110.938 107.664 110.938H123.582C133.26 110.938 141.092 118.769 141.092 128.447C141.092 131.09 138.959 133.223 136.316 133.223H120.398C110.72 133.223 102.889 125.391 102.889 115.713Z" fill="#66BB6A"/>
  <path d="M176.111 115.713C176.111 125.391 168.28 133.223 158.602 133.223H142.684C140.041 133.223 137.908 131.09 137.908 128.447C137.908 118.769 145.74 110.938 155.418 110.938H171.336C173.978 110.938 176.111 113.071 176.111 115.713Z" fill="#1B5E20"/>
  <path d="M102.889 77.5098C102.889 74.8674 105.022 72.7344 107.664 72.7344H123.582C133.26 72.7344 141.092 80.566 141.092 90.2441C141.092 92.8865 138.959 95.0195 136.316 95.0195H120.398C110.72 95.0195 102.889 87.1879 102.889 77.5098Z" fill="#66BB6A"/>
  <path d="M176.111 77.5098C176.111 87.1879 168.28 95.0195 158.602 95.0195H142.684C140.041 95.0195 137.908 92.8865 137.908 90.2441C137.908 80.566 145.74 72.7344 155.418 72.7344H171.336C173.978 72.7344 176.111 74.8674 176.111 77.5098Z" fill="#1B5E20"/>
  <path d="M139.5 155.508C136.858 155.508 134.725 153.375 134.725 150.732V64.7754C134.725 62.133 136.858 60 139.5 60C142.142 60 144.275 62.133 144.275 64.7754V150.732C144.275 153.375 142.142 155.508 139.5 155.508Z" fill="#81C784"/>
  <path d="M144.275 150.732V64.7754C144.275 62.133 142.142 60 139.5 60V155.508C142.142 155.508 144.275 153.375 144.275 150.732Z" fill="#388E3C"/>
  <path d="M183.625 200.078H100.978C99.705 200.078 98.4634 199.569 97.572 198.646C96.6806 197.722 96.1712 196.512 96.2031 195.239L96.5214 169.77C96.5214 168.752 96.8716 167.765 97.4765 166.969C102.284 160.602 108.555 155.285 115.623 151.688C123.073 147.899 131.095 145.957 139.5 145.957C160.83 145.957 179.9 158.691 188.05 178.398C188.304 178.971 188.4 179.608 188.4 180.213V195.303C188.4 197.945 186.267 200.078 183.625 200.078Z" fill="#D09875"/>
  <path d="M183.625 200.078C186.267 200.078 188.4 197.945 188.4 195.303V180.244C188.4 179.608 188.273 179.003 188.05 178.43C179.9 158.691 160.83 145.957 139.5 145.957V200.078H183.625Z" fill="#9D7552"/>
  <path d="M119.316 195.939H158.124C164.714 195.939 170.062 190.591 170.062 184.001C170.062 177.411 164.714 172.063 158.124 172.063L128.771 172.094C113.872 162.225 94.3566 162.544 79.7439 172.954L77.7383 174.387V211.062L79.6484 212.494C88.7535 219.307 99.8006 223 111.166 223H162.645C173.151 223 183.147 218.479 190.087 210.616L218.167 178.812C222.464 173.941 221.732 166.459 216.575 162.512C212.372 159.296 206.451 159.455 202.44 162.926L170.54 190.527C166.497 194.029 161.308 195.971 155.959 195.971" fill="#FBE9E7"/>
  <path d="M190.119 210.616L218.198 178.812C222.496 173.941 221.764 166.459 216.607 162.512C212.404 159.296 206.483 159.455 202.471 162.926L170.572 190.527C168.503 192.31 166.147 193.679 163.632 194.602C167.452 192.597 170.031 188.617 170.031 184.033C170.031 177.443 164.682 172.094 158.092 172.094H139.5V223.032H162.645C173.151 223 183.147 218.479 190.119 210.616Z" fill="#FFCCBC"/>
  <path d="M179.454 182.823L170.572 190.496C168.502 192.278 166.147 193.647 163.632 194.571C163.663 194.571 163.695 194.539 163.727 194.539C162.04 195.43 160.161 195.939 158.124 195.939H119.316C116.674 195.939 114.541 198.072 114.541 200.715C114.541 203.357 116.674 205.49 119.316 205.49H158.124C169.967 205.49 179.613 195.844 179.613 184.001C179.581 183.587 179.55 183.205 179.454 182.823Z" fill="#FFBEAA"/>
  <path d="M170.572 190.496C168.503 192.278 166.147 193.647 163.632 194.571C163.663 194.571 163.695 194.539 163.727 194.539C162.04 195.43 160.162 195.939 158.124 195.939H139.5V205.49H158.092C169.935 205.49 179.581 195.844 179.581 184.001C179.581 183.587 179.518 183.205 179.422 182.823L170.572 190.496Z" fill="#FAA68F"/>
  <path d="M73.1465 222.488H59.7754C57.133 222.488 55 220.355 55 217.713V166.775C55 164.133 57.133 162 59.7754 162H73.1465C75.7889 162 77.9219 164.133 77.9219 166.775V217.713C77.9219 220.355 75.7889 222.488 73.1465 222.488Z" fill="#333333" fill-opacity="0.38"/>
  </g>
  <defs>
  <clipPath id="clip0_830_12517">
  <rect width="169" height="163" fill="white" transform="translate(52 60)"/>
  </clipPath>
  </defs>
  </svg>
  `
