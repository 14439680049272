import cropEmpty from '@images/crop_empty.png'
import kmzEmpty from '@images/kmz_empty.png'
import licenseEmpty from '@images/license_empty.png'
import lotDefault from '@images/lot_default_image.png'
import cropsEmpty from '@images/crops_empty.png'
import offlineEmptyImage from '@images/offline_empty.png'
import notificationEmpty from '@images/notification_empty.png'
import notificationFilterEmpty from '@images/notification_filter_empty.png'

export {
  cropEmpty,
  kmzEmpty,
  licenseEmpty,
  lotDefault,
  cropsEmpty,
  offlineEmptyImage,
  notificationEmpty,
  notificationFilterEmpty,
}
