import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Dimensions,
  StyleSheet,
  View,
  ImageBackground,
  Platform,
} from 'react-native'
import { blackHighEmphasis, white } from '@styles/palette'

const Animated =
  Platform.OS === 'web'
    ? require('react-native').Animated
    : require('react-native-reanimated').default

const HEADER_EXPANDED_HEIGHT = 300
const ZERO = 0
const HEADER_COLLAPSED_HEIGHT = ZERO

const { width: SCREEN_WIDTH } = Dimensions.get('screen')

export const AnimatedScreen = ({ imageBackground, children }) => {
  const scrollY = useRef(new Animated.Value(0)).current

  const HEADER_COLLAPSED_HEIGHT_WITH_SAFEAREA = HEADER_COLLAPSED_HEIGHT

  const headerHeight = scrollY.interpolate({
    inputRange: [
      ZERO,
      HEADER_EXPANDED_HEIGHT - HEADER_COLLAPSED_HEIGHT_WITH_SAFEAREA,
    ],
    outputRange: [
      HEADER_EXPANDED_HEIGHT,
      HEADER_COLLAPSED_HEIGHT_WITH_SAFEAREA,
    ],
    extrapolate: 'clamp',
  })

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.imageBackgroundContainer,
          { height: headerHeight },
          styles.fullWidth,
        ]}
      >
        <ImageBackground
          source={{ uri: imageBackground }}
          style={[styles.imageBackground]}
        >
          <Animated.View
            style={[
              styles.headerContainer,
              {
                height: headerHeight,
              },
            ]}
          ></Animated.View>
        </ImageBackground>
      </Animated.View>

      <Animated.ScrollView
        contentContainerStyle={[styles.childrenContainer]}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: scrollY,
                },
              },
            },
          ],
          {
            useNativeDriver: false,
          }
        )}
        scrollEventThrottle={16}
      >
        {children}
      </Animated.ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
  imageBackgroundContainer: {
    position: 'absolute',
    width: SCREEN_WIDTH,
    top: 0,
    left: 0,
    zIndex: 1,
  },
  imageBackground: {
    resizeMode: 'cover',
    height: '100%',
  },
  headerContainer: {
    position: 'absolute',
    width: SCREEN_WIDTH,
    top: 0,
    left: 0,
    zIndex: 2,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: HEADER_COLLAPSED_HEIGHT,
  },
  headerIcon: {
    color: blackHighEmphasis,
    marginLeft: 20,
    marginRight: 25,
    fontSize: 22,
  },
  headerTitle: {
    fontSize: 18,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  childrenContainer: {
    paddingTop: HEADER_EXPANDED_HEIGHT,
  },
  fullWidth: {
    width: '100%',
  },
})

AnimatedScreen.propTypes = {
  imageBackground: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
