import React, { useState, useEffect, useCallback } from 'react'
import { View, Image, ImageBackground } from 'react-native'
import * as FileSystem from 'expo-file-system'
import * as Crypto from 'expo-crypto'
import PropTypes from 'prop-types'

const CachedImage = ({ source, isBackground, children, ...otherProps }) => {
  const [state, setState] = useState({
    imgURI: '',
  })

  useEffect(() => {
    onInit()

    return () => {
      onEnd()
    }
  }, [])

  const onInit = useCallback(async () => {
    if (!source.uri) {
      return
    }

    const filesystemURI = await getImageFilesystemKey(source.uri)

    await loadImage(filesystemURI, source.uri)
  }, [])

  const onEnd = useCallback(async () => {
    if (!source.uri) {
      return
    }

    const filesystemURI = await getImageFilesystemKey(source.uri)

    if (source.uri === state.imgURI || filesystemURI === state.imgURI) {
      return null
    }

    await loadImage(filesystemURI, source.uri)
  })

  const getImageFilesystemKey = async (remoteURI) => {
    const hashed = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      remoteURI
    )

    return `${FileSystem.cacheDirectory}${hashed}`
  }

  const loadImage = async (filesystemURI, remoteURI) => {
    try {
      // Use the cached image if it exists
      const metadata = await FileSystem.getInfoAsync(filesystemURI)

      if (metadata.exists) {
        setState({
          imgURI: filesystemURI,
        })
        return
      }

      // otherwise download to cache
      const imageObject = await FileSystem.downloadAsync(
        remoteURI,
        filesystemURI
      )

      setState({
        imgURI: imageObject.uri,
      })
    } catch (error) {
      console.warn('Image loading error:', error)
      setState({ imgURI: remoteURI })
    }
  }

  return (
    <View>
      {isBackground ? (
        <ImageBackground
          {...otherProps}
          source={state.imgURI ? { uri: state.imgURI } : null}
        >
          {children}
        </ImageBackground>
      ) : (
        <Image
          {...otherProps}
          source={state.imgURI ? { uri: state.imgURI } : null}
        />
      )}
    </View>
  )
}

CachedImage.propTypes = {
  source: PropTypes.shape({
    uri: PropTypes.string,
  }),
  isBackground: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default CachedImage
