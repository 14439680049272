import React from 'react'
import PropTypes from 'prop-types'
import {
  PlannedApplicationActivity,
  PlannedTillageActivity,
  PlannedSowingActivity,
  PlannedFertilizationActivity,
} from '../'

export const ActivityCardPlanned = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  dateHarvest,
  dateObservation,
  storages,
  areaUnitVolume,
  achievements,
  pay,
  unitType,
  unitTypeKey,
  volume,
  areaUnit,
  dateOrder,
  supplies,
  suppliesList,
  envImpactIndex,
  dateStart,
  dateEnd,
}) => {
  return (
    <>
      <PlannedApplicationActivity
        activityType={activityType}
        storages={storages}
        areaUnitVolume={areaUnitVolume}
        dateHarvest={dateHarvest}
        dateObservation={dateObservation}
        percent={percent}
        farms={farms}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        achievements={achievements}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        supplies={supplies}
        envImpactIndex={envImpactIndex}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />

      <PlannedTillageActivity
        activityType={activityType}
        subTypeActivity={subTypeActivity}
        percent={percent}
        farms={farms}
        achievements={achievements}
        supplies={supplies}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />

      <PlannedSowingActivity
        activityType={activityType}
        subTypeActivity={subTypeActivity}
        percent={percent}
        farms={farms}
        achievements={achievements}
        supplies={supplies}
        suppliesList={suppliesList}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />

      <PlannedFertilizationActivity
        activityType={activityType}
        subTypeActivity={subTypeActivity}
        percent={percent}
        farms={farms}
        achievements={achievements}
        supplies={supplies}
        suppliesList={suppliesList}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </>
  )
}

ActivityCardPlanned.prototype = {
  activityType: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  dateHarvest: PropTypes.string,
  dateObservation: PropTypes.string,
  storages: PropTypes.array,
  areaUnitVolume: PropTypes.string,
  achievements: PropTypes.array,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  areaUnit: PropTypes.string,
  dateOrder: PropTypes.string,
  supplies: PropTypes.array,
  suppliesList: PropTypes.array,
  envImpactIndex: PropTypes.object,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
}
