import React, { useState, useEffect, useContext, useCallback } from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  ActivityIndicator,
  Pressable,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Colors, Chip, Button } from 'react-native-paper'
import moment from 'moment'
import { round } from 'lodash'
import PropTypes from 'prop-types'
import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import { CropContext } from '@contextState/crop'
import { SelectorContext } from '@contextState/selectors'
import useOfflineCommon from '@offline/queries/common'
import useOfflineDrafts from '@offline/queries/drafts'
import EiqCard from '@components/common/v1/EiqCard'
import useNetwork from '@utils/network'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import { capitalizeFirstLetter } from '@utils/strings'
import { getCropNameFormatted } from '@utils/crop'
import { LanguageContext } from '@contextState/language'
import { getDraftsByActivityId, onSnapDelete } from '@services/firebase'
import ChipsComponent from '@components/common/v2/ChipsComponent'
import { secondary600, white, grayBlueLight } from '@styles/palette'
import { roundNumber } from '@utils/number'

let auxDrafts = []

const ActivityDetails = ({ navigation, route }) => {
  const { isConnected } = useContext(ConnectionContext)
  const { user } = useContext(AuthContext)
  const { data, isVerifier, currentCrop, setCurrentCropId } =
    useContext(CropContext)
  const { t, i18n } = useContext(LanguageContext)
  const { clearSelectors } = useContext(SelectorContext)

  const { doRequest, loading } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const { findDraftsByActivityIdOffline } = useOfflineDrafts()

  const [collaboratorType, setCollaboratorType] = useState()
  const [activity, setActivity] = useState()
  const [achievementsPercent, setAchievementsPercent] = useState(0)
  const [hasWritePermission, setHasWritePermission] = useState(false)
  const [drafts, setDrafts] = useState([])

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      fetchActivityDetails()

      if (!isConnected) {
        return
      }

      const unsubscribeOnSnapDelete = onSnapDelete(
        route.params.activityId,
        removeDrafts
      )

      return () => isConnected && unsubscribeOnSnapDelete()
    }, [isConnected, route?.params?.activityId])
  )

  useFocusEffect(
    useCallback(() => {
      setCurrentCropId(route?.params?.cropId)
      getCollaboratorType()
    }, [isConnected, route?.params?.cropId])
  )

  useFocusEffect(
    useCallback(() => {
      if (currentCrop) {
        getCollaboratorType()
      }
    }, [isConnected, currentCrop, route?.params?.cropId])
  )

  const removeDrafts = (idDrafts) => {
    const resultDrafts = auxDrafts.filter((draft) => {
      return idDrafts.indexOf(draft.id) === -1
    })
    auxDrafts = resultDrafts
    setDrafts([...resultDrafts])
  }

  /**
   * CALCULATE AND SET ACHIEVEMENTS PERCENT
   */
  useEffect(() => {
    if (activity?.achievements) {
      setAchievementsPercent(
        activity.achievements.reduce((previous, currentElement) => {
          return previous + currentElement.percent
        }, 0)
      )
    }
  }, [activity])

  /**
   * SET PERMISSIONS
   */
  useEffect(() => {
    if (!collaboratorType) {
      return
    }

    setHasWritePermission(
      collaboratorType !== 'MARKETER' &&
        collaboratorType !== 'PROVIDER' &&
        !isVerifier()
    )
  }, [collaboratorType])

  /**
   * FETCH ACTIVITY DETAILS
   */
  const fetchActivityDetails = async () => {
    let data
    let draftsByActivity

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `activities/${route.params.activityId}`,
      })

      data = response.data
      draftsByActivity = await getDraftsByActivityId(data._id)
      auxDrafts = draftsByActivity
    } else {
      data = await showIndividuals('activities', route.params.activityId)

      const [errorFindDraftsOffline, responseDrafts] =
        await findDraftsByActivityIdOffline(data._id)

      if (errorFindDraftsOffline) {
        alert(errorFindDraftsOffline)

        return
      }

      draftsByActivity = responseDrafts
    }

    setActivity(data)
    setDrafts(draftsByActivity)
  }

  /**
   * DEFINES THE CURRENT USER RESPONSABILITY IN THE CROP
   */
  const getCollaboratorType = async () => {
    const currentCrop = data.find(
      (element) => element._id === route.params.cropId
    )

    if (!currentCrop) {
      return
    }

    const currentUser = currentCrop.members.find(
      (element) => element.user?._id === user._id
    )

    if (!currentUser) {
      return
    }

    setCollaboratorType(currentUser.type)
  }

  /**
   * GO TO ADD ACHIEVEMENT
   */
  const goToAddAchievement = () => {
    //CLEAN SELECTOR WHEN GO TO ACHIEVEMENT SCREEN
    clearSelectors()
    navigation.navigate('AchievementsScreen', {
      id: route.params.cropId,
      activity: route.params.activityId,
      tag: route.params.tag,
      crop: currentCrop,
    })
  }

  /**
   * GO TO ACHIEVEMENT
   */
  const goToAchievement = (achievement) => {
    navigation.navigate('AchievementValidation', {
      achievement,
      crop: currentCrop,
      canWrite: hasWritePermission,
      tag: route.params.tag,
      activity: route.params.activityId,
      mode: 'sowing',
    })
  }

  const goToDraftAchievementDetails = (id) => {
    navigation.navigate('AchievementsScreen', {
      id: route.params.cropId,
      activity: route.params.activityId,
      tag: route.params.tag,
      draftId: id,
    })
  }

  /**
   * GO TO ActivityDetails
   */
  const goToActivityDetails = () => {
    navigation.navigate('ActivityValidation', {
      activity: activity,
      crop: currentCrop,
    })
  }

  const ItemCard = ({
    draft = false,
    date,
    lots,
    surface,
    areaUnit = 'ha',
    signers,
    supplies,
    onPress,
    versionDraft,
    hasMoreVersion,
    isRejected,
  }) => (
    <Pressable
      style={[
        styles.achievementContainer,
        draft && hasMoreVersion
          ? styles.achievementContainerDraft
          : styles.achievementContainerDefault,
      ]}
      onPress={onPress}
    >
      <View style={styles.achievementSubContainer}>
        <View style={styles.contentLotsDate}>
          <Text style={styles.achievementDateStart}>{date}</Text>
          {isRejected && (
            <ChipsComponent
              text={t('VIEWS.ACTIVITIES.TEXT_61')}
              widthChips={72}
              heightChips={20}
              backgroundColor={secondary600}
              textColor={white}
              textTransform='uppercase'
            />
          )}
          {draft && !hasMoreVersion && !isRejected && (
            <ChipsComponent
              text={t('VIEWS.ACTIVITY_APPLICATION_DETAILS.TEXT_16')}
              widthChips={72}
              heightChips={20}
              backgroundColor={grayBlueLight}
              textColor={white}
              textTransform='uppercase'
            />
          )}
          {hasMoreVersion === true && (
            <Chip
              selectedColor='white'
              style={styles.backgroundColorChipDraft}
              textStyle={styles.colorWhite}
            >
              <Text>
                {`${t('VIEWS.ACTIVITY_APPLICATION_DETAILS.TEXT_16')}: ${
                  t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_17
                }`}{' '}
                <Text style={styles.bold}>{versionDraft}</Text>
              </Text>
            </Chip>
          )}
        </View>

        <Text style={styles.achievementLotsSelected}>
          {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_6} : ${lots}`}
        </Text>

        <Text style={styles.achievementSurface}>
          {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_18} : ${roundNumber(
            surface
          )} ${areaUnit}`}
        </Text>

        <Text style={styles.achievementValidations}>
          {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_14} : ${signers}`}
        </Text>

        {isConnected && (
          <EiqCard
            title={`${
              t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_15
            } ${calculateEiq(supplies, surface)}`}
            subTitle={`${supplies.length} ${
              t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_9
            }`}
            eiqRange={getEIQRange(calculateEiq(supplies, surface))}
          />
        )}
      </View>
    </Pressable>
  )

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      {activity && (
        <ScrollView style={styles.container}>
          <Text style={styles.cropNameText}>
            {capitalizeFirstLetter(
              getCropNameFormatted(currentCrop, i18n.locale)
            )}
          </Text>

          <Text style={styles.surfaceText}>{`${round(activity.surface, 2)} ${
            currentCrop?.areaUnit
          } - ${currentCrop?.company?.name}`}</Text>

          <Text style={styles.activityTitle}>
            {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_3}
          </Text>

          <Pressable
            style={styles.activityContainer}
            onPress={goToActivityDetails}
          >
            <View style={styles.activityDatesContainer}>
              <View style={styles.activityDateStartContainer}>
                <Text style={styles.activityDateStartText}>
                  {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_4}
                </Text>

                <Text style={styles.activityDateStart}>
                  {moment(activity.dateStart).format('DD/MM/YYYY')}
                </Text>
              </View>

              <View style={styles.activityDateEndContainer}>
                <Text style={styles.activityDateEndText}>
                  {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_5}
                </Text>

                <Text style={styles.activityDateEnd}>
                  {moment(activity.dateEnd).format('DD/MM/YYYY')}
                </Text>
              </View>
            </View>

            <Text style={styles.activityLotsSelected}>
              {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_6} : ${
                activity.lots.length
              }`}
            </Text>

            <Text style={styles.activitySurface}>
              {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_7} : ${round(
                activity.surface,
                2
              )} ${activity.areaUnit}`}
            </Text>

            {isConnected && (
              <EiqCard
                title={`${
                  t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_8
                } ${calculateEiq(activity.supplies, activity.surface)}`}
                subTitle={`${activity.supplies.length} ${
                  t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_9
                }`}
                eiqRange={getEIQRange(
                  calculateEiq(activity.supplies, activity.surface)
                )}
              />
            )}
          </Pressable>

          <View style={styles.achievementsPercentContainer}>
            <View style={styles.achievementsPercentContent}>
              <Text style={styles.achievementsPercentText1} numberOfLines={2}>
                {`${t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_10} - ${
                  isConnected ? `${achievementsPercent}%` : ''
                }`}
              </Text>

              <Text style={styles.achievementsPercentText2} numberOfLines={2}>
                {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_11}
              </Text>
            </View>

            {achievementsPercent >= 100 && (
              <Text style={styles.achievementsPercentStatus}>
                {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_12}
              </Text>
            )}
          </View>

          <Button
            mode='text'
            disabled={achievementsPercent >= 100 || !hasWritePermission}
            style={styles.addAchievementButton}
            onPress={() => goToAddAchievement()}
          >
            {t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_13}
          </Button>

          {drafts &&
            drafts.map((element, key) => (
              <ItemCard
                key={key}
                onPress={() =>
                  goToDraftAchievementDetails(element.id ?? element._id)
                }
                date={moment(element.dateStart).format('DD/MM/YYYY')}
                lots={element.lots.length}
                surface={element.surface}
                areaUnit={activity.areaUnit}
                signers={element.signers.length}
                supplies={element.supplies}
                draft
                versionDraft={element.version}
                hasMoreVersion={element.hasMoreVersion}
                isRejected={element.isRejected}
              />
            ))}

          {activity.achievements &&
            activity.achievements.map((element, key) => (
              <ItemCard
                key={key}
                onPress={() => goToAchievement(element)}
                date={moment(element.dateStart).format('DD/MM/YYYY')}
                lots={element.lots.length}
                surface={element.surface}
                areaUnit={activity.areaUnit}
                signers={element.signers.length}
                supplies={element.supplies}
              />
            ))}
        </ScrollView>
      )}

      {!activity && loading && (
        <ActivityIndicator
          size='large'
          color={Colors.green500}
          style={styles.loader}
        />
      )}
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  colorWhite: {
    color: 'white',
  },
  contentChips: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: 10,
  },
  lastChip: {
    marginLeft: 10,
    justifyContent: 'flex-end',
  },
  backgroundColorChipDraft: {
    backgroundColor: grayBlueLight,
  },
  bold: {
    fontWeight: 'bold',
  },
  safeAreaProvider: {
    marginBottom: 20,
  },
  container: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 12.5,
    backgroundColor: '#F2F2F2',
  },
  cropNameText: {
    fontSize: 16,
    fontWeight: '500',
    paddingHorizontal: 16,
    marginTop: 20,
    color: 'rgba(51, 51, 51, 0.7)',
  },
  surfaceText: {
    fontSize: 14,
    fontWeight: '400',
    marginTop: 4,
    marginBottom: 31,
    paddingHorizontal: 16,
    color: 'rgba(51, 51, 51, 0.7)',
  },
  activityTitle: {
    fontSize: 16,
    fontWeight: '400',
    marginBottom: 19,
    paddingHorizontal: 16,
    color: 'rgba(51, 51, 51, 0.7)',
  },
  activityContainer: {
    backgroundColor: '#FAFAFA',
    paddingVertical: 12,
    paddingHorizontal: 11,
    borderRadius: 8,
  },
  activityDatesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  activityDateStartContainer: {
    marginRight: 'auto',
  },
  activityDateStartText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  activityDateStart: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  contentLotsDate: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activityDateEndContainer: {
    marginLeft: 'auto',
  },
  activityDateEndText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  activityDateEnd: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  activityLotsSelected: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  activitySurface: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
    marginBottom: 6,
  },
  achievementsPercentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 23,
  },
  achievementsPercentContent: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
  },
  achievementsPercentText1: {
    fontSize: 16,
    fontWeight: '500',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  achievementsPercentText2: {
    fontSize: 10,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
    marginLeft: 5,
  },
  achievementsPercentStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 4,
    backgroundColor: Colors.green500,
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '700',
    overflow: 'hidden',
    borderRadius: 8,
    marginLeft: 'auto',
  },
  addAchievementButton: {
    marginVertical: 24,
  },
  achievementContainer: {
    paddingTop: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  achievementSubContainer: {
    paddingTop: 4,
    paddingBottom: 8,
    borderTopWidth: 1,
    borderTopColor: 'rgba(0, 0, 0, .1)',
  },
  achievementContainerDefault: {
    backgroundColor: '#FFFFFF',
  },
  achievementContainerDraft: {
    backgroundColor: '#FBE9E7',
  },
  achievementDateStart: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  achievementLotsSelected: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  achievementSurface: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  achievementValidations: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
    marginBottom: 6,
  },
  loader: {
    marginTop: 100,
  },
})

ActivityDetails.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default ActivityDetails
