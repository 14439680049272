import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Platform,
  Pressable,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { ActivityIndicator, Colors, List, Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { ConnectionContext } from '@contextState/connection'
import { SelectorContext } from '@contextState/selectors'
import { LanguageContext } from '@contextState/language'
import useOfflineCommon from '../../offline/queries/common'
import ButtonCustom from '@components/common/ButtonCustom'
import useNetwork from '@utils/network'
import CachedImage from '@components/common/CachedImage'
import activityTypes from '@constants/activityTypes'
import { lotsActions } from '@store/actions'
import { colorWarningMessage, blackHighEmphasis } from '@styles/palette'
import { formatSurface } from '@common/utils'
import { truncateSurface } from '@common/utils/truncateSurface'

const ActivityLotsAdd = ({ route, navigation }) => {
  const { tag, cropId, draftId } = useRef(route.params).current

  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const {
    setLotSelector,
    lotSelector,
    setSelectorTotalSurface,
    setLotSelectorData,
    setEstablishmentsSelector,
    setJsonWkt,
  } = useContext(SelectorContext)

  const { doRequest, loading: isLoading } = useNetwork()
  const { verificationSelected } = useSelector(
    (state) => state.verificationTypes
  )
  const { lotsUnsynchronized } = useSelector((state) => state.lots)
  const dispatch = useDispatch()

  const [establishments, setEstablishments] = useState([])
  const [establishmentsSelected, setEstablishmentsSelected] = useState([])
  const [lotSelected, setLotSelected] = useState([])
  const [selected, setSelected] = useState([])
  const [totalSumSurface, setTotalSumSurface] = useState(0)
  const { showIndividuals } = useOfflineCommon()
  const [lotsUnsynchronizedMessage, setLotsUnsynchronizedMessage] = useState()
  const [allEstablishmentSelected, setAllEstablishmentSelected] =
    useState(false)

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      setSelected(lotSelector)
      fetchActivity()
      searchLotsUnsynchronized()

      return () => true
    }, [])
  )

  useEffect(() => {
    if (!establishments.length) {
      return
    }

    setAllEstablishmentSelected(
      establishments.filter((establishment) => !establishment.disabled)
        .length ===
        establishments.filter(
          (establishment) => !establishment.disabled && establishment.selected
        ).length
    )

    let totalSurface = 0
    let lotSelected = []
    const idLotsSelected = []
    const establishmentsSelected = []
    const jsonWktFormat = {}
    establishments.forEach((element) => {
      const arrayLotsSelected = element.lots
        .filter((subElement) => subElement.selected)
        .map((subElement) => {
          return {
            ...subElement,
            surfaceRealized: subElement.surface,
          }
        })

      arrayLotsSelected.forEach((subElement) => {
        jsonWktFormat[subElement._id] = {
          wktFormat: subElement.wktFormat,
          area: subElement.area,
        }
        idLotsSelected.push(subElement._id)
      })

      lotSelected = lotSelected.concat(arrayLotsSelected)

      totalSurface += arrayLotsSelected.reduce(
        (acumulator, { surface }) => acumulator + surface,
        0
      )
      if (arrayLotsSelected.length) {
        establishmentsSelected.push({
          tag: element.tag,
          lots: arrayLotsSelected,
        })
      }
    })

    setEstablishmentsSelected(establishmentsSelected)
    setSelected(idLotsSelected)
    setLotSelected(lotSelected)
    setTotalSumSurface(truncateSurface(totalSurface))
    setJsonWkt(jsonWktFormat)
  }, [establishments])

  const fetchActivity = useCallback(async () => {
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${cropId}/establishments`,
        params: {
          cropId: route.params.cropId,
          activityId: route.params.activityId,
        },
      })

      buildEstablishments(response.data, lotSelector)
    } else {
      const cropResponse = await showIndividuals('crops', cropId)

      let establishments
      if (route.params.activityId) {
        const offlineActivityData = await showIndividuals(
          'activities',
          route.params.activityId
        )
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots.filter((lot) =>
            offlineActivityData.lots.some(
              (lotActivity) => lotActivity._id === lot._id
            )
          ),
        }))
      } else {
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots,
        }))
      }

      buildEstablishments(establishments, lotSelector)
    }
  }, [])

  const buildEstablishmentsVerification = (lots) =>
    lots.filter((element) =>
      verificationSelected.lots.find(
        (lotVerification) => lotVerification._id === element._id
      )
    )

  const searchLotsUnsynchronized = () => {
    if (draftId && lotsUnsynchronized.length) {
      const currentLotsUnsynchronized = lotsUnsynchronized.find(
        (element) => element.draft === draftId
      )

      if (currentLotsUnsynchronized) {
        setLotsUnsynchronizedMessage(
          t('VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_11', {
            lotsVerifiedQuantity: currentLotsUnsynchronized.lotsVerified.length,
            lotsQuantity: currentLotsUnsynchronized.lotsSelectedQuantity,
          })
        )

        dispatch(lotsActions.clearLotsUnsynchronizedByDraftId({ draftId }))
      }
    }
  }

  const disabledVerification = (newLots) =>
    newLots.filter((newLot) => newLot.isVerified).length === newLots.length

  const buildEstablishments = (establishments, selectedIds) => {
    const newEstablishments = establishments
      .map((element) => {
        const allLotSelected = []

        const elementsLots =
          tag === activityTypes.ACT_VERIFICATION.key && verificationSelected
            ? buildEstablishmentsVerification(element.lots)
            : element.lots

        const newLots = elementsLots.map((lotElement) => {
          if (tag === activityTypes.ACT_VERIFICATION.key) {
            const verificationItem = verificationSelected.lots.find(
              (lotVerification) => lotVerification._id === lotElement._id
            )
            if (verificationItem.isVerified) {
              allLotSelected.push(lotElement)
            }

            return {
              ...lotElement,
              ...lotElement.lot,
              isVerified: verificationItem.isVerified,
            }
          }

          if (
            selectedIds.indexOf(
              lotElement._id ? lotElement._id : lotElement.lot.id
            ) !== -1
          ) {
            allLotSelected.push(lotElement)

            return {
              ...lotElement,
              ...lotElement.lot,
              selected: !lotElement.selected,
            }
          }

          if (lotElement.selected) {
            allLotSelected.push(lotElement)
          }

          return {
            ...lotElement,
            ...lotElement.lot,
          }
        })

        if (newLots.length) {
          return {
            ...element,
            selected:
              allLotSelected.length ===
              newLots.filter((lot) => !lot.disabled).length,
            lots: newLots,
            disabled:
              tag === activityTypes.ACT_VERIFICATION.key
                ? disabledVerification(newLots)
                : element.disabled,
          }
        }
      })
      .filter((element) => element)

    setEstablishments(newEstablishments)
  }

  const selectAllEstablishmentsToggle = () => {
    const establishmentsSelected = []

    const newEstablishments = establishments.map((element) => {
      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? lotElement.isVerified
              ? false
              : !allEstablishmentSelected
            : lotElement.selected,
        }
      })

      if (element.disabled) {
        return element
      }

      establishmentsSelected.push(element)

      return {
        ...element,
        selected: !allEstablishmentSelected,
        lots: newLots,
      }
    })

    if (!establishmentsSelected.length) {
      return
    }

    setEstablishments(newEstablishments)
  }

  const selectEstablishmentToggle = (establishment) => {
    if (establishment.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element.tag !== establishment.tag) {
        return element
      }

      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? lotElement.isVerified
              ? false
              : !element.selected
            : lotElement.selected,
        }
      })

      return {
        ...element,
        selected: !element.selected,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  const displayEstablishmentToggle = (establishment) => {
    const newEstablishments = establishments.map((element) => ({
      ...element,
      display:
        element.tag === establishment.tag ? !element.display : element.display,
    }))

    setEstablishments(newEstablishments)
  }

  const selectLotToggle = (establishment, lot) => {
    if (lot.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      const allLotSelected = []

      const newLots = element.lots.map((lotElement) => {
        if (lotElement._id !== lot._id) {
          if (lotElement.selected) {
            allLotSelected.push(lotElement)
          }

          return lotElement
        }

        if (!lotElement.selected) {
          allLotSelected.push(lotElement)
        }

        return {
          ...lotElement,
          selected: !lotElement.selected,
        }
      })

      return {
        ...element,
        selected:
          allLotSelected.length ===
          newLots.filter((lot) => !lot.disabled).length,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  const ItemCheck = ({
    checked,
    disabled = false,
    onPress,
    title,
    subtitle,
    accordion = false,
    display,
    toggleDisplay,
  }) => {
    return (
      <View
        style={
          !disabled
            ? styles.containerAccordion
            : styles.containerDisabledAccordion
        }
      >
        <CommonIcon
          name={checked ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'}
          size={24}
          color={checked && !disabled ? '#4CAF50' : '#333333'}
          style={styles.accordionIcon}
          onPress={onPress}
        />

        {accordion ? (
          <View style={styles.subContainerAccordion}>
            <List.Accordion
              title={title}
              style={styles.accordion}
              titleStyle={styles.accordionTitle}
              expanded={display}
              onPress={toggleDisplay}
            />
          </View>
        ) : (
          <View style={styles.accordionsHeaderTextContainer}>
            <Text style={styles.accordionsHeaderText}>{title}</Text>
            {subtitle && <Text style={styles.lotSurface}>{subtitle}</Text>}
          </View>
        )}
      </View>
    )
  }

  const ItemLots = ({ checked, lot, onPress }) => {
    const isVerified = lot.isVerified
    const contentStyle = isVerified ? styles.styleOpacity : {}
    return (
      <Pressable
        style={[styles.containerLot, contentStyle]}
        onPress={!isVerified && onPress ? onPress : () => {}}
      >
        <View style={styles.subContainerLot}>
          <CommonIcon
            name={
              checked || isVerified
                ? 'CHECKBOX-SELECTED'
                : 'CHECKBOX-UNSELECTED'
            }
            size={24}
            color={checked ? '#4CAF50' : '#333333'}
            style={styles.lotIcon}
            onPress={!isVerified && onPress ? onPress : () => {}}
          />

          <View style={styles.lotTexts}>
            <Text style={styles.lotName}>{`${lot.name}`}</Text>

            <Text style={styles.lotLocation}>
              {lot.cityName && `${lot.cityName}`}
              {lot.provinceName && `, ${lot.provinceName}`}
            </Text>

            <Text style={styles.lotSurface}>
              {`${t(
                tag === activityTypes.ACT_VERIFICATION.key
                  ? isVerified
                    ? 'VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_9'
                    : 'VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_8'
                  : 'VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_5'
              )} ${formatSurface(lot.surface, lot.areaUnit)}`}
            </Text>
          </View>

          <View style={styles.lotImageContainer}>
            {Platform.OS === 'web' ? (
              <Image
                source={{
                  uri: lot.imageUrl,
                }}
                style={styles.lotImage}
              />
            ) : lot.imageUrl ? (
              <CachedImage
                source={{
                  uri: lot.imageUrl,
                }}
                style={styles.lotImage}
              />
            ) : (
              false
            )}
          </View>
        </View>
        {isVerified && (
          <View style={styles.contentTextApplied}>
            <Text style={styles.lotSurface}>
              {t('VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_10')}
            </Text>
          </View>
        )}
      </Pressable>
    )
  }

  const renderLotsInCrops = (establishments) =>
    establishments &&
    establishments.map((establishment, key) => (
      <View key={key}>
        <ItemCheck
          checked={establishment.selected}
          onPress={() => selectEstablishmentToggle(establishment)}
          title={establishment.tag}
          accordion
          display={establishment.display}
          toggleDisplay={() => displayEstablishmentToggle(establishment)}
          disabled={establishment.disabled}
        />

        {establishment.display && (
          <View style={styles.containerLots}>
            {establishment.lots.map((lot, key) => (
              <View key={key}>
                <ItemLots
                  lot={lot}
                  onPress={() => selectLotToggle(establishment, lot)}
                  checked={lot.selected}
                  index={key}
                />
              </View>
            ))}
          </View>
        )}
      </View>
    ))

  const handleSelect = () => {
    setEstablishmentsSelector(establishmentsSelected)
    setLotSelector(selected)
    setLotSelectorData(lotSelected)
    setSelectorTotalSurface(truncateSurface(totalSumSurface))

    if (tag === activityTypes.ACT_VERIFICATION.key && route.params.callback) {
      const dataToReturn = establishmentsSelected
        .flatMap((element) =>
          element.lots.map((subElement) => ({
            ...subElement,
            tag: element.tag,
          }))
        )
        .map((element) => ({
          _id: element._id,
          surface: element.surface,
          tag: element.tag,
          wktFormat: element.wktFormat,
          area: element.area ?? undefined,
        }))
      route.params.callback(dataToReturn, 1)

      return
    }

    navigation.navigate('ActivityMinimumUnitLot', {
      ...route.params,
    })
  }

  if (isLoading) {
    return (
      <ActivityIndicator
        size='large'
        color={Colors.green500}
        style={styles.loader}
      />
    )
  }

  return (
    <View style={styles.container}>
      {Boolean(lotsUnsynchronizedMessage) && (
        <View style={styles.lotsUnsynchronizedMessageContainer}>
          <Text style={styles.lotsUnsynchronizedMessageText}>
            {lotsUnsynchronizedMessage}
          </Text>
        </View>
      )}

      {Boolean(lotSelected.length) && (
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>{`${lotSelected.length} ${
            t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_2
          }`}</Text>

          <Text style={styles.headerText}>
            {`${totalSumSurface} ${lotSelected[0].areaUnit}`}
          </Text>
        </View>
      )}

      <ScrollView style={{ paddingHorizontal: 0 }}>
        <ItemCheck
          checked={allEstablishmentSelected}
          onPress={selectAllEstablishmentsToggle}
          title={t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_4}
        />

        {renderLotsInCrops(establishments)}
      </ScrollView>

      <View style={styles.footer}>
        <ButtonCustom
          disabled={!selected.length}
          onPress={handleSelect}
          styles={!selected.length ? styles.buttonDisabled : styles.button}
          labelStyle={
            !selected.length ? styles.textButtonDisabled : styles.textButton
          }
        >
          {t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_6}
        </ButtonCustom>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  lotsUnsynchronizedMessageContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colorWarningMessage,
    padding: 14,
    borderRadius: 4,
    margin: 7.5,
  },
  lotsUnsynchronizedMessageText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  headerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E8F5E9',
    padding: 14,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
    marginVertical: 2,
  },
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  accordionsHeader: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  accordionsHeaderIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  accordionsHeaderTextContainer: {
    flex: 1,
    paddingTop: 18,
    paddingBottom: 18,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  accordionsHeaderText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333333',
  },
  containerLot: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 5,
    paddingRight: 5,
  },
  subContainerLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  lotIcon: {
    marginLeft: 30,
    marginRight: 30,
  },
  lotTexts: {
    flex: 1,
    marginRight: 7.5,
  },
  lotName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: '#333333',
    marginBottom: 5,
  },
  lotLocation: {
    fontSize: 14,
    color: '#333333',
  },
  lotImageContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  lotImage: {
    height: 60,
    width: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  containerAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  containerDisabledAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    opacity: 0.5,
  },
  accordionIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  subContainerAccordion: {
    flex: 1,
  },
  accordion: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 16,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  accordionTitle: {
    fontWeight: 'bold',
    color: '#333333',
    marginLeft: -8,
  },
  accordionTitleSelected: {
    color: '#4CAF50',
    fontWeight: '500',
    marginLeft: -8,
  },
  button: {
    backgroundColor: '#4CAF50',
  },
  buttonDisabled: {
    backgroundColor: '#81C784',
  },
  textButtonDisabled: {
    color: 'rgba(255, 255, 255, .5)',
  },
  textButton: {
    color: 'white',
  },
  footer: {
    padding: 16,
  },
  styleOpacity: {
    opacity: 0.5,
  },
  contentTextApplied: {
    paddingLeft: 35,
  },
})

ActivityLotsAdd.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default ActivityLotsAdd
