import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useFocusEffect } from '@react-navigation/native'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import { CommonContext } from '@contextState/common'
import { SelectorContext } from '@contextState/selectors'
import { AuthContext } from '@contextState/auth'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  getFormByActivityType,
  preventLeaveViewListener,
  parseDataToSaveDraft,
  validateByTypeActivity,
  onPressEvidenceFile,
  parseActivityDataToFormik,
  parseDraftDataToFormik,
  getFilesDeleted,
  parseEvidencesToFiles,
  parseAndSetEvidencesToStorage,
  validateLocationInLot,
  recalculateIsInLot,
  parseDataToValidate,
  parseDataToValidateAchievementUnplanned,
} from './utils'
import useNetwork from '@utils/network'
import useModal from '@hooks/useModal'
import useActionSheetManagement from '@hooks/useActionSheetManagement'
import useOfflineCommon from '@offline/queries/common'
import useOfflineDrafts from '@offline/queries/drafts'
import {
  insertDraft,
  updateDraft,
  getDraftById,
  deleteDraft,
  getUserActive,
  deleteUserActive,
  deleteFilesInStorage,
  getDraftsVerificationByCropIdAndIdentifier,
} from '@services/firebase'
import activityTypesConstants from '@constants/activityTypes'
import verificationStatus from '@constants/verificationStatus'
import { verificationTypesActions } from '@store/actions/verificationTypes'
import Screen from './screen'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { MODAL_ERRORS } from '@utils/constants'
import {
  EActiveAgreementTypes,
  searchErrorInAcVerification,
} from '@modules/common/utils'
import { activityNotValidate } from '../../../../../services/analytics'
import useValidatePin from '@hooks/useValidatePin'
import { useModalAnimated } from '../../../../common/hooks/useModalAnimated'
import { useStandardDeviation, useChangeOutliersHarvest } from '@common/hooks'
import { truncateSurface } from '@common/utils/truncateSurface'

const ActivityCreate = ({ navigation, route }) => {
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const {
    activities: activityTypes,
    verificationTypes,
    unitTypes,
    agreementTypes,
    setShowDeleteIcon,
    onEnableDeleteIcon,
    onDisableDeleteIcon,
    showDeleteModal: isModalDeleteDraftVisible,
    closeDeleteModal: closeModalDeleteDraft,
    displayToggleModalNotification,
    subTypeActivities,
    unitTypesSupplies,
    fetchVerificationTypes,
    fetchTypeActivities,
    saveTemporalData,
    unitTypesVolume,
    errorDeepLink,
  } = useContext(CommonContext)
  const {
    clearSelectors,
    setCollaboratorSelector,
    setEstablishmentsSelector,
    setLotSelector,
    jsonWkt,
    setJsonWkt,
  } = useContext(SelectorContext)
  const { user } = useContext(AuthContext)
  const { config } = user
  const {
    cropId,
    activityId,
    draftId,
    tag,
    activityToDelete,
    notDeepLink,
    isNewActivity,
  } = useRef(route.params).current

  const canSign = useRef(
    route.params.canSign === 'true' || route.params.canSign === true
  ).current
  const isRealized = useRef(
    route.params.isRealized === 'true' || route.params.isRealized === true
  ).current
  const isFinished = useRef(
    route.params.isFinished === 'true' || route.params.isFinished === true
  ).current
  const isDirectAchievement = useRef(
    route.params.isDirectAchievement === 'true' ||
      route.params.isDirectAchievement === true
  ).current
  const canPlanning = useRef(
    route.params.canPlanning === 'true' || route.params.canPlanning === true
  ).current

  const activityType = activityTypes.find((element) => element.tag === tag)
  const dispatch = useDispatch()
  const [crop, setCrop] = useState({})
  const [activity, setActivity] = useState()
  const [draft, setDraft] = useState()
  const [isViewReady, setIsViewReady] = useState(false)
  const [isViewReadOnly, setIsViewReadOnly] = useState(isRealized || isFinished)
  const [isOtherUserOnDraft, setIsOtherUserOnDraft] = useState(false)
  const [isSaveDraftButtonAvailable, setIsSaveDraftButtonAvailable] =
    useState(false)
  const [isSaveDraftButtonLoading, setIsSaveDraftButtonLoading] =
    useState(false)
  const [isValidateButtonAvailable, setIsValidateButtonAvailable] =
    useState(false)
  const [isValidateButtonVisible, setValidateButtonVisible] = useState(true)
  const [isDeclineButtonVisible, setDeclineButtonVisible] = useState(false)
  const [isDeclineButtonAvailable, setDeclineButtonAvailable] = useState(true)

  const [isValidateButtonLoading, setIsValidateButtonLoading] = useState(false)
  const [canShowOutlier, setCanShowOutlier] = useState(false)
  const [
    isAddVerificationFileButtonDisabled,
    setIsAddVerificationFileButtonDisabled,
  ] = useState(false)
  const [goBackWithoutSaveChangesEvent, setGoBackWithoutSaveChangesEvent] =
    useState(null)
  const [formikInitialValues, setFormikInitialValues] = useState(
    getFormikInitialValues({
      ...route.params,
      activityType,
      company: user.config.companySelected,
      locale: i18n.locale,
      isDirectAchievement,
      signers:
        tag === activityTypesConstants.ACT_VERIFICATION.key
          ? [
              {
                userId: user._id,
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
                type: user.type,
                identifier: user.identifier,
                selected: true,
              },
            ]
          : [],
    })
  )

  const [newValueVerification, setNewValueVerification] = useState(null)

  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const { findDraftByIdOffline, insertDraftOffline, updateDraftOffLine } =
    useOfflineDrafts()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const {
    isModalVisible: isModalConfirmGoBackVisible,
    toggleModal: displayToggleModalConfirmGoBack,
    closeModal: closeModalConfirmGoBack,
  } = useModal()
  const {
    isModalVisible: isModalValidateVisible,
    toggleModal: displayToggleModalValidate,
    closeModal: closeModalValidate,
  } = useModal()
  const {
    isModalVisible: isModalVerifyPinVisible,
    toggleModal: toggleModalVerifyPin,
    setModalIsVisible: setModalVerifyPinVisible,
    closeModal: closeModalVerifyPin,
  } = useModal()
  const {
    isModalVisible: isModalEvidenceVisible,
    toggleModal: toggleModalEvidences,
    closeModal: closeModalEvidences,
  } = useModal()
  const {
    isModalVisible: isModalDestinationVisible,
    toggleModal: toggleModalDestination,
    closeModal: closeModalDestination,
  } = useModal()
  const {
    isModalVisible: isModalConfirmChangeVerification,
    toggleModal: toggleModalConfirmChangeVerification,
    closeModal: closeModalConfirmChangeVerification,
  } = useModal()
  const {
    isModalVisible: isModalValidateVerificationVisible,
    toggleModal: toggleModalValidateVerification,
    closeModal: closeModalValidateVerification,
  } = useModal()
  const {
    isModalVisible: isModalConfirmValidateVerificationVisible,
    toggleModal: toggleModalConfirmValidateVerification,
    closeModal: closeModalConfirmValidateVerification,
  } = useModal()

  const { isOpenModal, actionSheetRef, openActionSheet, closeActionSheet } =
    useActionSheetManagement()

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t, tag, isDirectAchievement),
    enableReinitialize: false,
  })

  const {
    dirty,
    setFieldValue,
    values,
    resetForm,
    isValid,
    validateForm,
    setSubmitting,
    isSubmitting,
  } = formik

  const [isActivityNotValidate, setIsActivityNotValidate] = useState(true)

  const {
    isModalVisible: isModalLoadingVisible,
    closeModal: closeModalLoading,
    title: titleModalLoading,
    subtitle: subtitleModalLoading,
    setModalIsVisible: setModalLoadingIsVisible,
  } = useModalAnimated({
    title: t('VIEWS.ACTIVITY_CREATE.MODAL_LOADING.COMPLETING_THE_APPLICATION'),
    subtitle: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MOMENTS'
    ),
    subtitleSecondary: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MORE_MOMENTS'
    ),
  })
  const { openBackdropStandardDeviation, ComponentStandarDeviation } =
    useStandardDeviation({ t })

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    if (isDirectAchievement && activityTypes?.length <= 0) {
      return fetchTypeActivities()
    } else if (activityTypes?.length > 0 && activityType) {
      const newFormikInitialValues = getFormikInitialValues(formikInitialValues)

      setFormikInitialValues(newFormikInitialValues)

      resetForm({
        values: newFormikInitialValues,
      })
    }

    fetchInitData()

    return () => {
      clearSelectors()
    }
  }, [activityTypes, agreementTypes, verificationTypes])

  useFocusEffect(
    useCallback(() => {
      if (errorDeepLink) {
        navigation.replace('ModalErrorDeepLinks', {
          error: errorDeepLink,
        })
      }
    }, [])
  )

  /** Decline functions */

  const canShowDeclineButton = (defaultValue) => {
    setDeclineButtonVisible(
      tag !== activityTypesConstants.ACT_VERIFICATION.key && defaultValue
    )
  }

  const onSuccessDecline = () => {
    navigation.goBack()
  }
  // Recalculate isInLot
  useEffect(() => {
    if (values?.lots?.value?.length) {
      recalculateEvidences()
    }
  }, [values?.lots])

  /**
   * DISABLE VALIDATE BUTTON IF SAVE DRAFT BUTTON IS LOADING
   */
  useEffect(() => {
    setDeclineButtonAvailable(isConnected)
    if (!isConnected) {
      return
    }

    if (isRealized && canSign) {
      setCanShowOutlier(tag !== activityTypesConstants.ACT_VERIFICATION.key)
      setIsValidateButtonAvailable(true)
    } else if (!isRealized) {
      if (isSaveDraftButtonLoading) {
        setIsValidateButtonAvailable(false)
      } else {
        if (isValid && !isOtherUserOnDraft) {
          setIsValidateButtonAvailable(true)
        } else {
          setIsValidateButtonAvailable(false)
        }
      }
    }
  }, [isConnected, isSaveDraftButtonLoading, isValid, isOtherUserOnDraft])

  /**
   * DISABLE SAVE DRAFT BUTTON IF VALIDATE BUTTON IS LOADING
   */
  useEffect(() => {
    if (isValidateButtonLoading || isOtherUserOnDraft) {
      setIsSaveDraftButtonAvailable(false)
    } else {
      setIsSaveDraftButtonAvailable(true)
    }
  }, [isValidateButtonLoading, isOtherUserOnDraft])

  /**
   * ADD LISTENERS FOR PREVENT USER LEAVE SCREEN WITHOUT SAVE
   *
   * ABLE/DISABLE SAVE DRAFT BUTTON IF HAVE ANY CHANGE
   */
  useEffect(() => {
    if (!canSign) {
      return
    }

    preventLeaveViewListener(
      navigation,
      beforeRemoveListener,
      beforeunloadListener
    )

    if (dirty) {
      setIsSaveDraftButtonAvailable(true)
    } else {
      setIsSaveDraftButtonAvailable(false)
    }
  }, [dirty, isActivityNotValidate])

  /**
   * HIDE VALIDATE BUTTON IF USER IS NOT SIGNER
   * HIDE VALIDATE BUTTON IF ACTIVITY IS FINISHED
   * SHOW VALIDATE BUTTON IF ACTIVITY IS REALIZED OR IS DRAFT
   * SHOW/HIDE VALIDATE BUTTON IF IS OTHER USER ON DRAFT
   * SHOW/HIDE DELETE ICON IF IS OTHER USER ON DRAFT
   */
  useEffect(() => {
    const isOwnerUser = user?._id === draft?.createdByUserId
    if (!isViewReady) {
      return
    }

    if (isFinished || !canSign) {
      setValidateButtonVisible(false)
      return
    }

    if (isRealized) {
      setValidateButtonVisible(true)
      canShowDeclineButton(!isOtherUserOnDraft && !isOwnerUser && canSign)
      return
    }

    if (draftId) {
      setIsViewReadOnly(isOtherUserOnDraft || isRealized || isFinished)
      setShowDeleteIcon(!isOtherUserOnDraft)
      setValidateButtonVisible(!isOtherUserOnDraft)
    }
  }, [isViewReady, isOtherUserOnDraft])

  /**
   * HIDE DELETE DRAFT ICON IF USER LEAVE THE VIEW
   * ENABLE/DISABLE DELETE DRAFT ICON IF USER IS NOT CONNECTED OR LEAVE THE VIEW
   * TRIGGER EVENT FOR VERIFY IS ANY USER IS ALREADY ON DRAFT
   */
  useFocusEffect(
    useCallback(() => {
      validateForm()

      if (draftId) {
        if (isConnected) {
          const userFirebase = {
            _id: user._id,
            name: `${user.firstName} ${user.lastName}`,
          }

          getUserActive(draftId, userFirebase, disableScreen, enableScreen)
        } else {
          onDisableDeleteIcon()
        }
      }

      return () => {
        if (draftId) {
          setShowDeleteIcon(false)
          onEnableDeleteIcon()
        }
      }
    }, [isConnected])
  )

  useEffect(() => {
    return () => {
      if (isConnected && draftId) {
        deleteUserActive(draftId, user._id)
      }
    }
  }, [isConnected, draftId])

  useEffect(() => {
    if (
      verificationTypes.length &&
      tag === activityTypesConstants.ACT_VERIFICATION.key &&
      crop?.verificationTypes?.length
    ) {
      dispatch(
        verificationTypesActions.loadVerificationTypes({
          id: cropId,
          identifier: user.config.companySelected?.identifier,
          verificationTypes,
          verificationTypesCrop: crop?.verificationTypes,
        })
      )
    } else if (
      !verificationTypes.length &&
      tag === activityTypesConstants.ACT_VERIFICATION.key
    ) {
      fetchVerificationTypes()
    }
  }, [verificationTypes, crop])

  useEffect(() => {
    if (isSubmitting) {
      setModalLoadingIsVisible(true)
    } else {
      setModalLoadingIsVisible(false)
    }

    return () => {
      closeModalLoading()
    }
  }, [isSubmitting])

  const searchDraftInVerification = async (currentCrop) => {
    const draftsByCrop = await getDraftsVerificationByCropIdAndIdentifier(
      cropId,
      user.config.companySelected?.identifier
    )
    const isError = searchErrorInAcVerification(currentCrop, draftsByCrop)
    if (isError === MODAL_ERRORS.EXIST_VERIFICATION_DRAFT) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.EXIST_VERIFICATION_DRAFT,
      })
      throw new Error(MODAL_ERRORS.EXIST_VERIFICATION_DRAFT)
    } else if (isError === MODAL_ERRORS.NO_LOTS_AVAILABLE) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.NO_LOTS_AVAILABLE,
      })
      throw new Error(MODAL_ERRORS.NO_LOTS_AVAILABLE)
    }
    return false
  }

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    try {
      setIsViewReady(false)
      const [crop, activity, draft] = await Promise.all([
        fetchCrop(),
        fetchActivity(),
        fetchDraft(),
      ])
      //si notDeepLink es falso quiere decir que entra de un deeplink y hay que hacer la validacion del fav button de verificaciones
      if (
        !notDeepLink &&
        tag === activityTypesConstants.ACT_VERIFICATION.key &&
        !activityId &&
        !draftId
      ) {
        await searchDraftInVerification(crop)
      }
      let newFormikInitialValues
      if (draftId) {
        if (!draft) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.NOT_AVAILABLE,
          })
          return
        }
        newFormikInitialValues = parseDraftDataToFormik({
          t,
          verificationTypes,
          unitTypes,
          agreementTypes,
          formikInitialValues,
          draft,
          subTypeActivities,
          tag,
          locale: i18n.locale,
          unitTypesVolume,
        })

        setSelectors(crop, draft.lots, draft.signers)
      } else if (activityId) {
        if (!activity) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.NOT_AVAILABLE,
          })
          return
        }
        newFormikInitialValues = parseActivityDataToFormik({
          t,
          verificationTypes,
          unitTypes,
          agreementTypes,
          formikInitialValues,
          activity,
          subTypeActivities,
          tag,
          locale: i18n.locale,
          unitTypesSupplies,
          unitTypesVolume,
        })

        setSelectors(
          crop,
          activity.lotsWithSurface?.length
            ? activity.lotsWithSurface
            : activity.lots,
          activity.signers
        )
      }

      if (newFormikInitialValues) {
        setFormikInitialValues(newFormikInitialValues)

        resetForm({
          values: newFormikInitialValues,
        })

        setTimeout(validateForm)
      }

      setIsViewReady(true)
    } catch (error) {
      console.error('error en initialData', error)
    }
  }, [activityTypes, agreementTypes, verificationTypes])
  /**
   * FETCH CROP
   */
  const fetchCrop = useCallback(async () => {
    try {
      let crop

      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          version: 'v2',
          url: `crops/${cropId}`,
          params: {
            companyId: user.config.companySelected?._id,
          },
          displayAlert: false,
        })

        crop = response.data
      } else {
        const offlineData = await showIndividuals('crops', cropId)

        crop = offlineData
      }
      const me = crop.members.find((el) => el.user._id === user._id)
      if (!crop) {
        navigation.navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.ERROR_UNEXPECTED,
        })
        throw new Error(MODAL_ERRORS.ERROR_UNEXPECTED)
      }
      if (!me) {
        navigation.navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.UNAUTHORIZED,
        })
        throw new Error(MODAL_ERRORS.UNAUTHORIZED)
      }
      setCrop(crop)

      return crop
    } catch (error) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.ERROR_UNEXPECTED,
      })
      throw new Error(MODAL_ERRORS.ERROR_UNEXPECTED)
    }
  }, [isConnected])

  /**
   * FETCH ACTIVITY
   */
  const fetchActivity = useCallback(async () => {
    if (!activityId) {
      return false
    }

    let activity

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `activities/${activityId}`,
      })

      activity = response.data
    } else {
      const offlineData = await showIndividuals('activities', activityId)

      activity = offlineData
    }

    setActivity(activity)

    return activity
  }, [isConnected])

  /**
   * FETCH DRAFT
   */
  const fetchDraft = useCallback(async () => {
    if (!draftId) {
      return false
    }

    let draft

    if (isConnected) {
      draft = await getDraftById(draftId)
    } else {
      const [errorFindDraft, responseDraft] = await findDraftByIdOffline(
        draftId
      )

      if (errorFindDraft) {
        alert(errorFindDraft)

        return
      }

      draft = responseDraft
    }

    setDraft(draft)

    return draft
  }, [isConnected])

  /**
   * SET DATA TO SELECTORS
   *
   * @param {*} signers
   */
  const setSelectors = useCallback(({ establishments }, lots, signers) => {
    const auxJsonWkt = {}
    setCollaboratorSelector(signers)
    const newEstablishments = establishments
      .map((element) => {
        const newLots = []

        element.lots.map((subElement) => {
          const lot = lots?.find(
            (subSubElement) =>
              subSubElement.lot?._id === subElement._id ||
              subSubElement._id === subElement._id
          )

          if (lot) {
            newLots.push({
              ...lot,
              ...lot.lot,
            })
            auxJsonWkt[subElement._id] = {
              wktFormat: subElement?.wktFormat,
              area: subElement?.area,
            }
          }
        })
        return {
          tag: element.tag,
          lots: newLots,
        }
      })
      .filter((element) => element.lots.length)
    setJsonWkt(auxJsonWkt)
    setEstablishmentsSelector(newEstablishments)
    setLotSelector(lots.map((element) => element._id))
  }, [])

  /**
   * DISABLE SCREEN
   *
   * @param {*} name
   */
  const disableScreen = useCallback((name) => {
    setIsOtherUserOnDraft(name)
  }, [])

  /**
   * ENABLE SCREEN
   */
  const enableScreen = useCallback((isOtherUserLeaveDraft) => {
    if (isOtherUserLeaveDraft) {
      fetchInitData()
      setIsViewReadOnly(false)
      setIsOtherUserOnDraft(false)
    } else {
      setShowDeleteIcon(true)
    }
  }, [])

  /**
   * VALIDATE IF USER ATTEMPT GO BACK WITHOUT SAVE USING NAVIGATOR IN THE APP
   *
   * @param {*} event
   * @returns
   */
  const beforeRemoveListener = useCallback(
    (event) => {
      if (canSign && isConnected && isActivityNotValidate) {
        activityNotValidate({
          activityType: tag,
          date: new Date(),
          userId: user._id,
          email: user.email,
        })
      }

      if (!dirty) {
        return
      }

      event.preventDefault()

      displayToggleModalConfirmGoBack()

      setGoBackWithoutSaveChangesEvent(event)
    },
    [dirty, isActivityNotValidate]
  )

  /**
   * VALIDATE IF USER ATTEMPT LEAVE OR REFRESH WITHOUT SAVE ON WEB
   *
   * @param {*} event
   * @returns
   */
  const beforeunloadListener = useCallback(
    (event) => {
      if (canSign && isConnected && isActivityNotValidate) {
        activityNotValidate({
          activityType: tag,
          date: new Date(),
          userId: user._id,
          email: user.email,
        })
      }
      if (!dirty) {
        return
      }

      event.preventDefault()

      return (event.returnValue =
        t(
          'VIEWS'
        ).ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES.TEXT_2)
    },
    [isActivityNotValidate]
  )

  /**
   * GO TO SELECT LOTS AND SURFACE
   */
  const onPressLotsInput = useCallback(() => {
    if (tag === activityTypesConstants.ACT_AGREEMENTS.key) {
      if (!values?.typeAgreement?.key) {
        return
      }
      if (
        values?.typeAgreement?.key === EActiveAgreementTypes.CALCULATION_2BSVS
      ) {
        navigation.navigate('FarmsListSelectByCrop', {
          ...route.params,
          farmId: values?.farm?.farmId,
          isViewReadOnly,
          callback: (farm) => {
            const { lots, tag } = farm
            const resultLots = lots.map((element) => ({
              _id: element._id,
              surface: element.surface,
              tag: element.tag,
              wktFormat: element.wktFormat,
              area: element.area ?? undefined,
            }))
            setFieldValue('lots', {
              value: resultLots,
              label: tag,
            })
            const totalSurface = truncateSurface(
              resultLots.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.surface,
                0
              )
            )
            setFieldValue('surface', String(totalSurface))
            setFieldValue('farm', {
              farmId: farm.farm,
              tag: farm.tag,
              surfaceTotal: farm.surfaceTotal,
            })
            navigation.pop(1)
          },
        })
        return
      }
    }
    if (isViewReadOnly) {
      if (draftId) {
        return
      }

      if (activity.lotsWithSurface?.length === activity.lots.length) {
        navigation.navigate('ActivityMinimumUnitLot', {
          cropId,
          activityId,
          readOnly: isViewReadOnly,
        })
      } else {
        navigation.navigate('LotsSelectorModal', {
          id: activityId,
          type: 'activities',
          status: isViewReadOnly ? 'readOnly' : '',
        })
      }

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key) {
      dispatch(
        verificationTypesActions.setVerificationSelected(
          values.verificationType
        )
      )
    }

    if (tag === activityTypesConstants.ACT_AGREEMENTS.key) {
      if (!values?.typeAgreement?.key) {
        return
      }
      if (
        values?.typeAgreement?.key === EActiveAgreementTypes.CALCULATION_2BSVS
      ) {
        navigation.navigate('FarmsListSelectByCrop', {
          ...route.params,
          farmId: values?.farm?._id,
          callback: (farm) => {
            const { lots, tag } = farm
            setFieldValue('lots', {
              value: lots,
              label: tag,
            })
            const totalSurface = truncateSurface(
              lots.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.surface,
                0
              )
            )
            setFieldValue('surface', String(totalSurface))
            setFieldValue('farm', farm)
            navigation.pop(1)
          },
        })
        return
      }
    }

    navigation.navigate('ActivityLotsAdd', {
      ...route.params,
      hideSurfacePlanned: true,
      draftId,
      tag,
      isDirectAchievement,
      callback: (lots, numPop = 2) => {
        const newLabel =
          lots.length > 1
            ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
            : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

        setFieldValue('lots', {
          value: lots,
          label: `${lots.length} ${newLabel}`,
        })

        const totalSurface = truncateSurface(
          lots.reduce(
            (acumulator, currentValue) => acumulator + currentValue.surface,
            0
          )
        )

        setFieldValue('surface', String(totalSurface))

        navigation.pop(numPop)
      },
    })
  }, [
    isViewReadOnly,
    activity,
    values.verificationType,
    values.typeAgreement,
    values?.farm?.farmId,
  ])

  const navigationBack = () => {
    if (isNewActivity) {
      navigation.pop(2)
    } else {
      navigation.goBack()
    }
  }

  /**
   * SAVE DRAFT
   */
  const onPressSaveDraftButton = async () => {
    if (isSaveDraftButtonLoading) {
      return
    }

    setIsSaveDraftButtonLoading(true)

    const evidences = await parseAndSetEvidencesToStorage(
      isConnected,
      values.evidences
    )

    const dataToInsert = parseDataToSaveDraft({
      canPlanning,
      ...values,
      evidences,
      companyIdentifier: user.config.companySelected?.identifier,
      areaUnit: crop.areaUnit,
      type: activityType,
      isDirectAchievement,
    })

    const firebaseUser = {
      _id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }

    /**
     * BEFORE SAVE OFFLINE DATA IN ONLINE MODE, WE NEED VALIDATE IF USER ALREADY HAVE THIS CROP IN OFFLINE MODE
     * BECAUSE IF NOT, THEN MAKE SOME ERRORS.
     *
     * VALIDATE IF USER IS IN MOBILE FOR THAT, BECAUSE IN WEB OFFLINE IS NOT WORKING AND NO NEED.
     */
    const hasCropOffline = crop.members?.find(
      (element) => element.user?._id === user._id && element.isOfflineEnabled
    )

    if (!draftId) {
      if (isConnected) {
        await insertDraft(dataToInsert, firebaseUser)
      }

      if (Platform.OS !== 'web' && (hasCropOffline || !isConnected)) {
        const [errorInsertDraftOffline] = await insertDraftOffline(
          dataToInsert,
          firebaseUser
        )

        if (errorInsertDraftOffline && !isConnected) {
          alert(errorInsertDraftOffline)

          return
        }
      }
    } else {
      if (isConnected) {
        deleteFilesInStorage(
          draft.draftGroupId,
          draftId,
          getFilesDeleted(formikInitialValues.evidences, values.evidences)
        )

        dataToInsert.isRejected = false
        dataToInsert.rejectedAt = undefined
        dataToInsert.rejectObservation = undefined
        await updateDraft(dataToInsert, firebaseUser)
      }

      if (Platform.OS !== 'web' && (hasCropOffline || !isConnected)) {
        const [errorUpdateDraftOffline] = await updateDraftOffLine(
          draftId,
          dataToInsert,
          firebaseUser
        )

        if (errorUpdateDraftOffline && !isConnected) {
          alert(errorUpdateDraftOffline)

          return
        }
      }
    }

    if (activityToDelete && isConnected) {
      await doRequest({
        method: 'DELETE',
        url: `activities/${activityToDelete}`,
      })
    }

    resetForm({
      values,
    })

    setIsSaveDraftButtonLoading(false)

    displayToggleModalNotification({
      text: t('VIEWS.ACTIVITY_CREATE.TEXT_7'),
      duration: 5000,
    })

    navigationBack()
  }

  /**
   *  Change Outlier UnitType EventChange.
   *
   * @param String value
   */
  const changeOutlierUnitType = (value) => {
    const {
      cropType: { key },
    } = crop
    const unit = value?.key
    const pay = values?.pay
    const cropType = key

    if (pay && unit && cropType) {
      changeOutLierHarvest(pay, unit, cropType)
    }
  }

  /**
   *  Change Outlier Pay Event Change.
   *
   * @param String value
   */
  const changePayAndRequestOutlier = (value) => {
    const {
      cropType: { key },
    } = crop
    const unit = values?.unitType?.key
    const cropType = key

    if (value && unit && cropType) {
      changeOutLierHarvest(value, unit, cropType)
    }
  }

  /**
   * SUBMIT VALIDATION
   */
  const onPressValidateButton = async () => {
    if (tag !== activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      const isPlanification = !isDirectAchievement && canPlanning

      const dataToValidate = isDirectAchievement
        ? parseDataToValidateAchievementUnplanned(values, user._id)
        : parseDataToValidate(values, user._id)
      let screensToReturn = 2

      if (!draft && !activityToDelete && isDirectAchievement) {
        screensToReturn = 3
      }

      saveTemporalData({
        dataToValidate,
        isPlanification,
        cropName: crop.name,
        cropType: crop?.cropType?.key,
        evidences: values.evidences,
        displayDraftVersionModal: draft?.hasMoreVersion,
        activityToDelete,
        isDirectAchievement,
        screensToReturn,
      })

      resetForm({
        values,
      })

      navigation.navigate('ActivityCreateConfirm', {
        isPlanification,
        activityTypeTag: tag,
      })

      return
    }

    if (isValidateButtonLoading || isModalVerifyPinVisible) {
      return
    }

    if (draftId && draft?.hasMoreVersion) {
      displayToggleModalValidate()

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      toggleModalValidateVerification()

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && isRealized) {
      toggleModalConfirmValidateVerification()

      return
    }

    if (canSign) {
      if (config.hasPin) {
        if (verifyExpirationPin()) {
          toggleModalVerifyPin(true)
        } else {
          const { validate, tokenPin } = await geTokenAuthPin()
          if (!validate) {
            toggleModalVerifyPin(true)
            return
          }
          onSuccessVerifyPin(tokenPin)
        }
      } else {
        navigation.navigate('CreatePin', {
          callbackPin,
        })
      }
      return
    }

    onValidate()
  }

  const callbackPin = async () => {
    const { tokenPin } = await geTokenAuthPin()
    onSuccessVerifyPin(tokenPin)
  }

  /**
   * CONFIRM VALIDATE DRAFT
   */
  const onPressConfirmValidate = async () => {
    closeModalValidate()

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      setTimeout(toggleModalValidateVerification, 100)

      return
    }

    if (canSign) {
      if (verifyExpirationPin()) {
        setTimeout(toggleModalVerifyPin, 100)
      } else {
        const { validate, tokenPin } = await geTokenAuthPin()
        if (!validate) {
          setTimeout(toggleModalVerifyPin, 100)
          return
        }
        onSuccessVerifyPin(tokenPin)
      }
      return
    }

    onValidate()
  }

  /**
   * CALLBACK AFTER VERIFY PIN
   *
   * @param {*} pinToken
   */
  const onSuccessVerifyPin = async (pinToken) => {
    closeModalVerifyPin()
    onValidate(pinToken)
  }

  /**
   * ON PRESS CANCEL BUTTON MODAL VERIFY PIN
   */
  const onPressCancelModalVerifyPin = () => {
    setModalVerifyPinVisible(false)
    setIsValidateButtonLoading(false)
  }

  /**
   * DO VALIDATION
   */
  const onValidate = async (pinToken) => {
    try {
      setIsActivityNotValidate(false)
      setIsValidateButtonLoading(true)
      setSubmitting(true)

      const files = await parseEvidencesToFiles(values.evidences)

      const newValues = {
        ...values,
        files,
      }

      await validateByTypeActivity({
        doRequest,
        tag,
        activityToDelete,
        isRealized,
        values: newValues,
        pinToken,
        isDirectAchievement,
        userId: user._id,
      })

      setIsValidateButtonLoading(false)

      resetForm()
      navigationBack()
    } catch (error) {
      alert(error.error)
    } finally {
      setIsValidateButtonLoading(false)
      closeModalVerifyPin()
      setSubmitting(false)
    }
  }

  /**
   * CONFIRM GO BACK WITHOUT SAVING CHANGES
   */
  const onPressConfirmGoBackWithoutSaveChanges = useCallback(() => {
    closeModalConfirmGoBack()

    navigation.dispatch(goBackWithoutSaveChangesEvent.data.action)
  }, [goBackWithoutSaveChangesEvent])

  /**
   * CONFIRM DELETE DRAFT
   */
  const onPressConfirmDeleteDraft = async () => {
    closeModalDeleteDraft()

    setIsViewReady(false)

    setShowDeleteIcon(false)

    deleteFilesInStorage(
      draft.draftGroupId,
      draftId,
      values.evidences
        .filter((element) => element.persisted)
        .map((element) => element.fileNamePath || element.name)
    )

    await deleteDraft(draftId, draft.draftGroupId)

    resetForm()

    setTimeout(navigation.goBack)
  }

  /**
   * GO TO ADD COLLABORATORS
   */
  const onPressAddCollaborator = useCallback(() => {
    navigation.navigate('CollaboratorsSelectorModal', {
      id: cropId,
      status: isViewReadOnly ? 'readOnly' : '',
      tag,
      callback: (signers) => {
        setFieldValue('signers', signers)
      },
    })
  }, [isViewReadOnly])

  const recalculateEvidences = () => {
    if (!values?.evidences.length) {
      return null
    }
    const auxEvidences = recalculateIsInLot(values, jsonWkt)
    setFieldValue('evidences', auxEvidences)
  }

  /**
   * ON PRESS ADD EVIDENCE
   *
   * @param {*} param0
   */
  const onPressAddEvidence = ({ values: valuesEvidences, location }) => {
    let isInLot

    if (values.lots.value.length && Object.keys(location).length) {
      isInLot = validateLocationInLot({
        location,
        lots: values.lots.value,
        jsonWkt,
      })
    }

    const fileExtension = valuesEvidences.file.name.substring(
      valuesEvidences.file.name.lastIndexOf('.'),
      valuesEvidences.file.name.length
    )

    const evidenceFileName = uuidv4() + fileExtension

    const resultEvidences = [
      ...values.evidences,
      {
        ...valuesEvidences,
        meta: {
          location,
        },
        settings: {
          isInLot: Boolean(isInLot),
          isValidateLot: valuesEvidences.isValidateLot,
        },
        type: valuesEvidences.file.type,
        name: valuesEvidences.file.name,
        fileNamePath: evidenceFileName,
        originalName: valuesEvidences.file.name,
        date: moment.isMoment(valuesEvidences.date)
          ? valuesEvidences.date.toDate()
          : valuesEvidences.date,
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
    ]

    setFieldValue('evidences', resultEvidences)
  }

  /**
   * ON PRESS REMOVE EVIDENCE
   *
   * @param {*} index
   */
  const onPressRemoveEvidence = useCallback(
    (index) => {
      const resultEvidences = values.evidences.filter(
        (element, key) => key !== index
      )

      setFieldValue('evidences', resultEvidences)
    },
    [values?.evidences]
  )

  /**
   * ON PRESS ADD DESTINATION
   *
   * @param {*} param0
   */
  const onPressAddDestination = useCallback(
    ({ values: valuesDestination }) => {
      const resultDestination = [...values.storages, valuesDestination]

      setFieldValue('storages', resultDestination)
    },
    [values?.storages]
  )

  /**
   * ON PRESS REMOVE DESTINATION
   *
   * @param {*} index
   */
  const onPressRemoveDestination = useCallback(
    (index) => {
      const resultDestination = values.storages.filter(
        (el, key) => key !== index
      )

      setFieldValue('storages', resultDestination)
    },
    [values?.storages]
  )
  /**
   * ON PRESS CANCEL VALIDATE VERSION
   */
  const onPressCancelValidateVersion = useCallback(() => {
    closeModalValidate()

    setIsValidateButtonLoading(false)
  }, [])

  const copyObservation = (value) => {
    setFieldValue('observation', value)
  }

  const deleteObservation = (index) => {
    const arrayAuxObservation = values.observations
    arrayAuxObservation.splice(index, 1)
    setFieldValue('observations', arrayAuxObservation)
  }

  const onSelectVerificationFile = (file) => {
    const fileExtension = file.name.substring(
      file.name.lastIndexOf('.'),
      file.name.length
    )

    const evidenceFileName = uuidv4() + fileExtension

    const verificationFile = {
      file,
      name: evidenceFileName,
      originalName: file.name,
      description: file.description,
      uploadedBy: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      uploadedAt: new Date(),
    }

    setFieldValue('verificationFile', verificationFile)

    setIsAddVerificationFileButtonDisabled(true)
  }

  const onPressRemoveVerificationFile = () => {
    setIsAddVerificationFileButtonDisabled(false)
    setFieldValue('verificationFile', undefined)
  }

  const onPressVerificationInvalidButton = () => {
    closeModalValidateVerification()

    setFieldValue('verificationStatus', verificationStatus.INVALID.key)

    setTimeout(toggleModalConfirmValidateVerification, 100)
  }

  const onPressVerificationValidButton = () => {
    closeModalValidateVerification()

    setFieldValue('verificationStatus', verificationStatus.VALID.key)

    setTimeout(toggleModalConfirmValidateVerification, 100)
  }

  const onPressConfirmConfirmValidateVerification = async () => {
    toggleModalConfirmValidateVerification()

    if (verifyExpirationPin()) {
      setTimeout(toggleModalVerifyPin, 100)
    } else {
      const { validate, tokenPin } = await geTokenAuthPin()
      if (!validate) {
        setTimeout(toggleModalVerifyPin, 100)
        return
      }
      onSuccessVerifyPin(tokenPin)
    }
  }

  const openConfirmationChangeVerification = (value) => {
    toggleModalConfirmChangeVerification()
    setNewValueVerification(value)
  }

  const confirmVerification = () => {
    clearSelectors()
    closeModalConfirmChangeVerification()

    const newFormikInitialValues = getFormikInitialValues({
      ...route.params,
      activityType,
      company: user.config.companySelected,
      locale: i18n.locale,
      isDirectAchievement,
    })

    setFormikInitialValues(newFormikInitialValues)

    resetForm({
      values: newFormikInitialValues,
    })

    setTimeout(validateForm)

    setTimeout(() => setFieldValue('verificationType', newValueVerification))
  }

  const setFieldEiq = (resultSupplies) => {
    setFieldValue('supplies', resultSupplies)
    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(resultSupplies, values.surface)
      const range = getEIQRange(totalEiqAllSupplies)
      setFieldValue('eiqSupplies', { totalEiqAllSupplies, range })
      setFieldValue('eiqRealized', totalEiqAllSupplies)
    }
  }

  const pressAddSupply = () => {
    navigation.navigate('SupplySelectorModal', {
      id: crop._id,
      tag: route.params.tag,
      activity: route.params.activity,
      surface: values.surface,
      alphaCode: crop?.company?.country?.alphaCode,
      cropType: crop?.cropType?.key,
      activityType: route.params.tag,
      suppliesSelected: values?.supplies,
      callBack: (supply) => {
        let result = []

        if (values?.supplies.find(({ _id }) => _id === supply._id)) {
          result = values?.supplies.map((element) =>
            element._id !== supply._id ? element : supply
          )
        } else {
          result = [...values?.supplies, supply]
        }

        setFieldEiq(result)
      },
    })
  }

  const navigateToSupplyDetail = (supply, key) => {
    navigation.navigate(isRealized ? 'SupplyDetail' : 'SupplyLoaderScreen', {
      id: supply._id,
      item: supply,
      key: key,
      surface: values.surface,
      readOnly: isRealized,
      callBack: (key) => {
        const resultSupplies = values?.supplies.filter(
          (el, index) => key !== index
        )
        setFieldEiq(resultSupplies)
      },
    })
  }

  return (
    <Screen
      isViewReady={isViewReady}
      isViewReadOnly={isViewReadOnly}
      tag={tag}
      activity={activity}
      isRealized={isRealized}
      isFinished={isFinished}
      formik={formik}
      form={getFormByActivityType({
        tag,
        formik,
        isViewReadOnly,
        onPressLotsInput,
        openConfirmationChangeField: openConfirmationChangeVerification,
        crop,
        isDirectAchievement,
        activityType,
        isConnected,
        outlierHarvestLevel: activity?.outlierHarvestLevel,
        canShowOutlier,
        changeOutlierUnitType,
        changePayAndRequestOutlier,
        isValueOutsideMeanHarvest,
        canShowOutlierHarvest,
        statusOutlier,
        openBackdropStandardDeviation,
        isNewActivity: !activityId,
        formikInitialValues,
      })}
      crop={crop}
      draft={draft}
      isOtherUserOnDraft={isOtherUserOnDraft}
      isValidateButtonAvailable={
        draft?.isRejected !== true && isValidateButtonAvailable
      }
      isValidateButtonLoading={isValidateButtonLoading}
      onPressSaveDraftButton={onPressSaveDraftButton}
      isSaveDraftButtonAvailable={isSaveDraftButtonAvailable}
      isSaveDraftButtonLoading={isSaveDraftButtonLoading}
      onPressValidateButton={onPressValidateButton}
      isValidateButtonVisible={isValidateButtonVisible}
      isModalConfirmGoBackVisible={isModalConfirmGoBackVisible}
      closeModalConfirmGoBack={closeModalConfirmGoBack}
      onPressConfirmGoBackWithoutSaveChanges={
        onPressConfirmGoBackWithoutSaveChanges
      }
      isModalValidateVisible={isModalValidateVisible}
      onPressCancelValidateVersion={onPressCancelValidateVersion}
      onPressConfirmValidate={onPressConfirmValidate}
      isModalDeleteDraftVisible={isModalDeleteDraftVisible}
      closeModalDeleteDraft={closeModalDeleteDraft}
      onPressConfirmDeleteDraft={onPressConfirmDeleteDraft}
      isModalVerifyPinVisible={isModalVerifyPinVisible}
      toggleModalVerifyPin={toggleModalVerifyPin}
      onPressCancelModalVerifyPin={onPressCancelModalVerifyPin}
      onSuccessVerifyPin={onSuccessVerifyPin}
      onPressAddCollaborator={onPressAddCollaborator}
      signerQuantity={values?.signers.length}
      isModalEvidenceVisible={isModalEvidenceVisible}
      toggleModalEvidences={toggleModalEvidences}
      closeModalEvidences={closeModalEvidences}
      onPressAddEvidence={onPressAddEvidence}
      evidences={values?.evidences}
      onPressRemoveEvidence={onPressRemoveEvidence}
      onPressEvidenceFile={onPressEvidenceFile}
      lots={values?.lots}
      isModalDestinationVisible={isModalDestinationVisible}
      toggleModalDestination={toggleModalDestination}
      closeModalDestination={closeModalDestination}
      onPressAddDestination={onPressAddDestination}
      storages={values?.storages}
      onPressRemoveDestination={onPressRemoveDestination}
      observations={values?.observations}
      deleteObservation={deleteObservation}
      copyObservation={copyObservation}
      isModalConfirmChangeVerification={isModalConfirmChangeVerification}
      closeModalConfirmChangeVerification={closeModalConfirmChangeVerification}
      confirmVerification={confirmVerification}
      isModalValidateVerificationVisible={isModalValidateVerificationVisible}
      closeModalValidateVerification={closeModalValidateVerification}
      isAddVerificationFileButtonDisabled={isAddVerificationFileButtonDisabled}
      onSelectVerificationFile={onSelectVerificationFile}
      verificationType={values?.verificationType}
      verificationFile={values?.verificationFile}
      onPressRemoveVerificationFile={onPressRemoveVerificationFile}
      onPressVerificationInvalidButton={onPressVerificationInvalidButton}
      onPressVerificationValidButton={onPressVerificationValidButton}
      isModalConfirmValidateVerificationVisible={
        isModalConfirmValidateVerificationVisible
      }
      closeModalConfirmValidateVerification={
        closeModalConfirmValidateVerification
      }
      onPressConfirmConfirmValidateVerification={
        onPressConfirmConfirmValidateVerification
      }
      modalConfirmDecline={{
        isOpenModal,
        actionSheetRef,
        openActionSheet,
        closeActionSheet,
        onSuccess: onSuccessDecline,
        paramsRequest: {
          method: 'PUT',
          url: `activities/${activityId}/reject`,
          version: 'v2',
          data: {
            cropId,
          },
        },
      }}
      isDeclineButtonVisible={isDeclineButtonVisible}
      isDeclineButtonAvailable={isDeclineButtonAvailable}
      isDirectAchievement={isDirectAchievement}
      supplies={values?.supplies}
      navigateToSupplyDetail={navigateToSupplyDetail}
      areaUnit={crop.areaUnit}
      pressAddSupply={pressAddSupply}
      totalEiqAllSupplies={values?.eiqSupplies?.totalEiqAllSupplies}
      eiqRange={values?.eiqSupplies?.range}
      isModalLoadingVisible={isModalLoadingVisible}
      titleModalLoading={titleModalLoading}
      subtitleModalLoading={subtitleModalLoading}
      canShowOutlier={canShowOutlier}
      openBackdropStandardDeviation={openBackdropStandardDeviation}
      ComponentStandarDeviation={ComponentStandarDeviation}
    />
  )
}

ActivityCreate.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default ActivityCreate
