import { Card } from 'react-native-paper'
import moment from 'moment'
import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { round } from 'lodash'
import PropTypes from 'prop-types'

import { CropIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import {
  lightGreen500,
  lightGreen50,
  blackText50,
  darkGreen50,
  primary50,
  white,
  black,
} from '@styles/palette'
import { roundSurface } from '@utils'

function CardCropDetail({ crop }) {
  const { t, i18n } = useContext(LanguageContext)

  function renderIcon() {
    const cropType = crop.cropType.key.toUpperCase()

    return <CropIcon name={cropType} size={30} color={white} />
  }

  return (
    <Card style={styles.container}>
      <Card.Title
        style={styles.cardTitleStyle}
        title={`${
          t('CALENDAR').MONTHS[moment(crop?.dateCrop).format('M')]
        } ${moment(crop?.dateCrop).format('YYYY')} - ${
          t('CALENDAR').MONTHS[moment(crop?.dateHarvest).format('M')]
        } ${moment(crop?.dateHarvest).format('YYYY')}`}
        titleStyle={styles.titleStyle}
        subtitle={`${crop?.cropType?.keyLabel} -  ${crop?.company?.name}`}
        subtitleStyle={styles.subtitle}
        left={renderIcon}
        leftStyle={styles.leftStyle}
      />

      <Card.Content style={styles.content}>
        {crop?.cropStatusVerified && (
          <View style={styles.containerFlagVerified}>
            <Text style={styles.textVerified}>
              {t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_6}
            </Text>
          </View>
        )}
        <Text style={styles.text}>
          {`${t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_1} ${roundSurface(
            crop.surface
          )} ${crop?.areaUnit}`}
        </Text>
        <Text style={styles.textPay}>
          {`${t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_2} ${crop.pay} ${
            crop?.keyUnitType
          }`}
          {`\n(${
            crop?.unitType?.codeLabel ?? crop?.unitType?.name[i18n.locale]
          })`}
        </Text>
        <Text style={styles.text}>
          {`${t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_3} ${round(
            crop?.volume ?? 0,
            2
          )} ${crop?.areaUnitVolume}`}
        </Text>
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
    paddingRight: 16,
    backgroundColor: primary50,
    borderRadius: 4,
  },
  cardTitleStyle: { justifyContent: 'flex-start' },
  titleStyle: {
    color: blackText50,
    textTransform: 'uppercase',
    fontSize: 11,
    marginTop: 0,
    fontWeight: '500',
  },
  subtitle: {
    fontSize: 16,
    color: black,
    fontWeight: '500',
    marginTop: 0,
  },
  content: {
    marginTop: -5,
    paddingLeft: 72,
  },
  leftStyle: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: darkGreen50,
  },
  text: {
    height: 22,
  },
  textPay: {
    height: 38,
    marginBottom: 12,
  },
  containerFlagVerified: {
    backgroundColor: lightGreen50,
    alignSelf: 'flex-start',
    paddingHorizontal: 5,
    borderRadius: 5,
    marginVertical: 5,
  },
  textVerified: {
    color: lightGreen500,
    fontSize: 10,
    lineHeight: 16,
    letterSpacing: 1.5,
    fontWeight: '500',
  },
})

CardCropDetail.propTypes = {
  crop: PropTypes.object.isRequired,
}

export default CardCropDetail
