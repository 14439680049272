import * as Yup from 'yup'

const MAX_CARACTERES = 50

export const farmEditNameValidationSchema = (t) =>
  Yup.object().shape({
    farmName: Yup.string()
      .trim()
      .min(1, t('VIEWS').FARM_EDIT_NAME.TEXT_2)
      .max(
        50,
        t('VIEWS.FARM_EDIT_NAME.TEXT_3', {
          quantity: MAX_CARACTERES,
        })
      )
      .required(t('VIEWS').FARM_EDIT_NAME.TEXT_2),
  })
