export const harvestFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  dateHarvest: {
    value: '',
    label: '',
  },
  unitType: {
    value: '',
    label: '',
  },
  volume: '',
  unitVolume: {
    value: '',
    label: '',
  },
  pay: '',
  signers: [],
  evidences: [],
  files: [],
  storages: [],
})

export const dataHarvest = {
  name: 'Cosecha',
  dateHarvest: '2021-10-28T20:05:20.242Z',
  type: '5fa01d56d109e6791d233c66',
  unitType: '5fa01d56d109e6791d233c5a',
  surface: 14.88,
  pay: 10,
  crop: '613bb932c53c358eaa773f76',
  lots: ['613bb932c53c358eaa773f57'],
  signers: [
    {
      userId: '61244f9329c5bb4ec1157d9a',
      fullName: 'Alfonso Tortorici',
      email: 'alfonso@ucrop.it',
      type: 'PRODUCER',
      signed: false,
    },
    {
      userId: '5fb43018e353c20878e0b42f',
      fullName: 'franco correa',
      email: 'anibalfranciscocorrea@gmail.com',
      type: 'PRODUCER',
    },
  ],
  storages: [
    {
      unitType: '5fa01d56d109e6791d233c5b',
      tonsHarvest: 10,
      storageType: '6064aa6be1edf16aa0bb3aac',
      icon: 'archive',
      label: 'Toneladas/ha',
    },
  ],
}
