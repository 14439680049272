import React, { useContext, useRef } from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Image,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { SUPPLY } from '@constants/iconGuide'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { Layout } from '@modules/common/components'
import { useStandardDeviation, useFetchOutliers } from '@common/hooks'

const Screen = ({
  supply,
  route,
  handleDeleteSupply,
  showDeleteModal,
  closeModalDelete,
  getSurface,
  getEiq,
  getComposition,
  getClassToxic,
  getQuantity,
  unitSurface,
  code,
}) => {
  const { t } = useContext(LanguageContext)
  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })
  const { surface, surfaceDetail, item, id } = useRef(route.params).current
  const itemToSend = item ? item : id

  const { ComponentOutlierBox } = useFetchOutliers({
    item: itemToSend,
    surface: surface
      ? surface
      : surfaceDetail
      ? surfaceDetail
      : itemToSend?.surfaceDetail,
    openBackdropStandardDeviation,
  })
  const getTitle = () => {
    if (supply.brand) {
      return supply.brand
    }

    if (typeof supply.supply === 'object') {
      if (supply?.supply?.brand) {
        return supply.supply.brand
      }
    }

    return supply?.name
  }

  const getUnitTypeSupply = (supply) => supply?.unitName ?? supply?.unit ?? ''

  return (
    <Layout style={styles.layoutContainer}>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        {supply && (
          <View>
            <View style={styles.supplyDescription}>
              <Image
                style={styles.image}
                source={SUPPLY[code] || SUPPLY['default']}
              />
              <View style={styles.textDescription}>
                <Text style={styles.title}>{getTitle()}</Text>
                {supply.brand && (
                  <Text style={styles.subTitleSupply}>{supply.name}</Text>
                )}
                <Text style={styles.subTitleSupply}>
                  {supply?.typeId?.codeLabel ?? supply?.typeId?.name}
                </Text>
                <Text style={styles.subTitleSupply}>{supply?.company}</Text>
              </View>
            </View>
            <ComponentOutlierBox />
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_1}
              </Text>
              <Text style={styles.dataValue}>{getComposition(supply)}</Text>
            </View>
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_2}
              </Text>
              <Text style={styles.dataValue}>{getEiq(supply)}</Text>
            </View>
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_3}
              </Text>
              <Text style={styles.dataValue}>{getClassToxic(supply)}</Text>
            </View>
            <View style={styles.separator} />
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS.SUPPLY_DETAIL.TEXT_4', {
                  areaUnit: unitSurface,
                })}
              </Text>

              <Text style={styles.dataValue}>{getSurface(supply)}</Text>
            </View>
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_5}
              </Text>
              <Text style={styles.dataValue}>{getUnitTypeSupply(supply)}</Text>
            </View>

            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_6} {getUnitTypeSupply(supply)}/
                {unitSurface}
              </Text>
              <Text style={styles.dataValue}>{getQuantity(supply)}</Text>
            </View>
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_7}
              </Text>
              <Text style={styles.dataValue}>
                {`${supply?.total} ${getUnitTypeSupply(supply)}`}
              </Text>
            </View>
          </View>
        )}
      </ScrollView>
      <ComponentStandarDeviation />
      <ModalConfirm
        visible={showDeleteModal}
        onClose={closeModalDelete}
        onConfirm={() => handleDeleteSupply(route.params.key)}
        title={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_1}
        contentText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_2}
        cancelText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_3}
        confirmText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />
    </Layout>
  )
}

const styles = StyleSheet.create({
  layoutContainer: {
    backgroundColor: 'white',
  },
  container: {
    padding: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    color: '#333333',
  },
  subTitleSupply: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: '#333333B2',
  },
  subTitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: '#333333B2',
  },
  dataValue: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    color: '#333333B2',
  },
  separator: {
    borderBottomColor: '#0000001F',
    borderBottomWidth: 1,
    marginHorizontal: 32,
    paddingTop: 8,
  },
  image: {
    height: 40,
    width: 40,
  },
  supplyDescription: {
    flexDirection: 'row',
  },
  textDescription: {
    marginLeft: 16,
    width: 300,
  },
  dataWrapper: {
    marginBottom: 16,
    marginTop: 16,
  },
  deleteModalCancelButtonStyle: {
    borderColor: '#FF7043',
  },
  deleteModalCancelButtonTextStyle: {
    color: '#FF7043',
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  outlierContainer: {
    marginHorizontal: 33,
    marginBottom: 45,
  },
})
Screen.propTypes = {
  route: PropTypes.object.isRequired,
}

Screen.propTypes = {
  supply: PropTypes.object,
  route: PropTypes.object.isRequired,
  handleDeleteSupply: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.bool.isRequired,
  closeModalDelete: PropTypes.func.isRequired,
  getSurface: PropTypes.func.isRequired,
  getEiq: PropTypes.func.isRequired,
  getComposition: PropTypes.func.isRequired,
  getClassToxic: PropTypes.func.isRequired,
}

export default Screen
