import React from 'react'
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import BoldText from '@components/text/BoldText'
import { SUPPLY_DEFAULT, keysSupplies } from '@constants'
import { SUPPLY } from '@constants/iconGuide'
import { blackMediumEmphasis, gray12 } from '@styles/palette'

const ItemSupply = ({ item, search, onPress, type }) => {
  const isTypeSupply = (tags, supplyKey) => {
    return tags.length && tags.some((tag) => tag === supplyKey)
  }

  return (
    <TouchableOpacity onPress={() => onPress(item)} style={styles.contentItem}>
      <View style={styles.contentItem}>
        <View style={styles.contentIcon}>
          <Image
            style={styles.iconSupply}
            source={SUPPLY[item?.typeId?.code] || SUPPLY[SUPPLY_DEFAULT]}
          />
        </View>
        <View style={styles.contentValueItem}>
          {!isTypeSupply(item.typeId?.tags, keysSupplies.seeds) && (
            <BoldText
              text={item?.brand}
              style={styles.textTitle}
              searchWord={search}
            />
          )}
          <BoldText
            text={item?.name}
            style={
              isTypeSupply(item.typeId?.tags, keysSupplies.seeds)
                ? styles.textTitle
                : styles.textDescription
            }
            searchWord={search}
          />
          {type === 'insumos' && (
            <BoldText
              text={item?.typeId?.codeLabel ?? item?.typeId?.name}
              style={styles.textDescription}
              searchWord={search}
            />
          )}
          <BoldText
            text={item?.company}
            style={styles.textDescription}
            searchWord={search}
          />
          {item.eiqTotal !== null && item.eiqTotal !== undefined && (
            <BoldText
              text={`EIQ ${Number(item.eiqTotal).toFixed(2)}`}
              style={styles.textDescription}
              searchWord={search}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  contentItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 10,
  },
  iconSupply: {
    width: 50,
    height: 50,
    marginTop: 8,
  },
  contentValueItem: {
    marginLeft: 20,
    marginTop: 6,
    paddingBottom: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: gray12,
    flex: 1,
  },
  textTitle: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: '500',
  },
  textDescription: {
    fontSize: 14,
    color: blackMediumEmphasis,
  },
})

export default ItemSupply
