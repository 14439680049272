import React from 'react'
import PropTypes from 'prop-types'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

export const PressableInfo = ({
  label,
  content,
  showIcon = true,
  onPress = null,
  containerStyle = {},
  labelStyle = {},
  contentStyle = {},
  iconStyle = {},
  showBorder = false,
  componentIcon = null,
}) => {
  const renderIcon = () => {
    if (componentIcon) return componentIcon

    if (showIcon)
      return (
        <CommonIcon
          name='CHEVRON-RIGHT'
          size={25}
          color={blackHighEmphasis}
          style={[iconStyle]}
        />
      )
  }

  return (
    <TouchableOpacity activeOpacity={onPress ? 0.7 : 1} onPress={onPress}>
      <View
        style={[
          showBorder ? styles.borderBottom : {},
          styles.container,
          containerStyle,
        ]}
      >
        <View>
          <Text style={[styles.label, labelStyle]} numberOfLines={1}>
            {label}
          </Text>
          <Text style={[styles.textInfo, contentStyle]} numberOfLines={1}>
            {content}
          </Text>
        </View>

        {renderIcon()}
      </View>
    </TouchableOpacity>
  )
}

PressableInfo.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  onPress: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  containerStyle: PropTypes.any,
  labelStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  showBorder: PropTypes.bool,
  componentIcon: PropTypes.elementType,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 18.5,
  },
  label: {
    height: 16,
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.4,
    color: blackHighEmphasis,
    marginVertical: 2,
  },
  textInfo: {
    height: 24,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackHighEmphasis,
    marginVertical: 2,
  },
  borderBottom: {
    paddingBottom: 9,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
})
