import React, { useContext, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'

import { useFormik } from 'formik'
import useNetwork from '@utils/network'
import { authActions } from '@store/actions'
import { badRequestCompanies } from '@utils/constants'
import { AuthContext } from '@contextState/auth'
import { LanguageContext } from '@contextState/language'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { white, blackMediumEmphasis, black } from '@styles/palette'
import { TaxIdForm } from './components/TaxIdForm'
import { getFormikInitialValues } from './utils/getFormikInitialValues'
import { getFormikValidationSchema } from './utils/getFormikValidationShema'

const ValidateTaxId = ({ navigation }) => {
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()
  const { t } = useContext(LanguageContext)
  const { user, fetchUser } = useContext(AuthContext)
  const [formikInitialValues] = useState(getFormikInitialValues())
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      submitForm(values, setSubmitting)
    },
  })
  const { handleSubmit, dirty, isValid, isSubmitting } = formik
  const submitForm = async (valuesForm, setSubmitting) => {
    try {
      await doRequest({
        method: 'PATCH',
        url: `companies/join`,
        version: 'v2',
        data: {
          identifier: valuesForm.taxid,
        },
        displayAlert: false,
      })
      await fetchUser()
      dispatch(authActions.removeEmailOfWelcomeCompanyList(user?.email))
    } catch (error) {
      if (error.cod === badRequestCompanies.INVALID_COMPANY) {
        navigation.navigate('CompanyAddOnboarding', {
          isAdviser: false,
          onBoardingCompany: true,
        })
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <View style={styles.content}>
      <Text style={styles.textTitle}>
        {t('VIEWS.VALIDATE_TAXID.ENTER_TAXID')}
      </Text>
      <TaxIdForm formik={formik} t={t} />
      <Text style={styles.subText}>{t('VIEWS.VALIDATE_TAXID.NO_ENTER')}</Text>
      <View style={styles.contentBottom}>
        <ButtonDefault
          typeButton={
            dirty && isValid && !isSubmitting && isValid && !isSubmitting
              ? 'primary'
              : 'disabled'
          }
          loading={isSubmitting}
          text={t('VIEWS.VALIDATE_TAXID.CONTINUE')}
          onPress={handleSubmit}
          disabled={!(dirty && isValid)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingTop: 30,
    paddingBottom: 44,
    backgroundColor: white,
    flex: 1,
  },
  textTitle: {
    fontWeight: '700',
    fontSize: 24,
    marginBottom: 40,
    lineHeight: 28,
    color: black,
  },
  contentBottom: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  subText: {
    color: blackMediumEmphasis,
    fontWeight: '400',
    marginTop: 21,
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0.25,
  },
})
export default ValidateTaxId
