import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Card, Chip, Menu } from 'react-native-paper'
import { ActivityIndicator } from 'react-native'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import { green500 } from '@common/styles'

function ListItem({
  title,
  subtitle,
  status,
  menuItems,
  onPress,
  open,
  handleOpenMenu,
  disabled,
  statusColor,
  extraStatus,
  extraStatusColor,
  downloaded,
  loading,
  ...props
}) {
  const { t } = useContext(LanguageContext)

  return (
    <Card
      onPress={disabled ? null : onPress ? () => onPress() : null}
      style={{ marginBottom: 8, justifyContent: 'center' }}
      disabled={disabled}
    >
      <Card.Title
        title={title}
        right={(props) => (
          <>
            {menuItems && (
              <Menu
                {...props}
                onDismiss={handleOpenMenu}
                visible={open}
                anchor={
                  <CommonIcon
                    name={'DOTS-VERTICAL'}
                    size={20}
                    onPress={handleOpenMenu}
                    disabled={disabled}
                  />
                }
              >
                {menuItems}
              </Menu>
            )}
          </>
        )}
        titleStyle={[
          { opacity: disabled ? 0.5 : 1 },
          { fontSize: 16 },
          props.titleStyle,
        ]}
        subtitleStyle={{ fontSize: 14 }}
        subtitleNumberOfLines={3}
        subtitle={subtitle}
      />
      <Card.Content>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: 2,
          }}
        >
          <View style={styles.chipContainer}>
            {status && (
              <Chip
                textStyle={styles.chipText}
                style={[
                  { backgroundColor: statusColor || '#FF8A65' },
                  styles.chipStyle,
                ]}
              >
                {(status === 'DRAFT' &&
                  t('COMPONENTS').COMMON.LIST_ITEM.TEXT_1) ||
                  status}
              </Chip>
            )}
            {extraStatus && (
              <Chip
                textStyle={styles.chipText}
                style={{
                  backgroundColor: extraStatusColor || '#FF8A65',
                  margin: 0,
                  color: 'white',
                  fontSize: 14,
                }}
              >
                {extraStatus}
              </Chip>
            )}
          </View>
          {downloaded && !loading && (
            <View style={styles.syncStatus}>
              <CommonIcon name={'FILE-DOWNLOAD'} size={14} color={green500} />
            </View>
          )}
          {loading && <ActivityIndicator color='green' />}
        </View>
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  chipText: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 14,
    margin: 0,
  },
  cardStyles: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  chipStyle: {
    margin: 0,
    color: 'white',
    fontSize: 14,
    marginRight: 4,
  },
  chipContainer: {
    flex: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  syncStatus: {
    position: 'relative',
    right: -4,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1.5,
    borderRadius: 10,
    borderColor: 'green',
    paddingLeft: 1,
    paddingTop: 1,
  },
})

export default ListItem
