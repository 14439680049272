import React, { useEffect, useState, useContext } from 'react'
import { StyleSheet, View, Text, Linking, ScrollView } from 'react-native'

import { Title, Paragraph, Checkbox, Card } from 'react-native-paper'
import Input from '@components/common/Input'
import ButtonCustom from '@components/common/ButtonCustom'
import { Formik, Field } from 'formik'
import { validateEmail } from '@utils/validation'
import Constants from 'expo-constants'
import useNetwork from '@utils/network.js'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Layout from '@components/common/Layout'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'

const {
  platform: { web },
} = Constants
const isWeb = web || false

function RegisterScreen({ navigation }) {
  const [check, setCheck] = useState('unchecked')
  const [initialValues, setInitialValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  })
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()

  useEffect(() => {
    async function getEmail() {
      const email = await AsyncStorage.getItem('REGISTER_EMAIL')
      if (!email) {
        navigation.replace('sign-in')
      } else {
        setInitialValues({
          ...initialValues,
          email,
        })
      }
    }

    getEmail()
  }, [])

  function handleCheck() {
    setCheck(check === 'unchecked' ? 'checked' : 'unchecked')
  }

  async function handleSubmit(values) {
    await doRequest({
      needAuthorizationHeaders: false,
      method: 'POST',
      url: 'auth/register',
      data: values,
    })

    navigation.replace('VerifyCode')
  }

  function validate(values) {
    const errors = {}

    if (!values.email) {
      errors.email = t('VIEWS').REGISTER.TEXT_1
    } else if (!validateEmail(values.email)) {
      errors.email = t('VIEWS').REGISTER.TEXT_2
    }

    if (!values.firstName) {
      errors.firstName = t('VIEWS').REGISTER.TEXT_1
    }

    if (!values.lastName) {
      errors.lastName = t('VIEWS').REGISTER.TEXT_1
    }

    if (!values.phone) {
      errors.phone = t('VIEWS').REGISTER.TEXT_1
    }

    return errors
  }

  function navToAggreement() {
    Linking.openURL(
      `https://ucrop.it/${t('VIEWS').REGISTER.PRIVACY_POLICY_URL}`
    )
  }

  return (
    <ScrollView style={styles.content}>
      <Layout>
        <View style={styles.content}>
          <Card elevation={3} style={styles.cardHeader}>
            <Card.Content>
              <View style={styles.header}>
                <Paragraph style={styles.text}>
                  {t('VIEWS').REGISTER.TEXT_3}
                </Paragraph>
                <Title style={styles.title}>{t('VIEWS').REGISTER.TEXT_4}</Title>
              </View>
            </Card.Content>
          </Card>

          <Formik
            validate={validate}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ isValid, handleSubmit, isSubmitting }) => (
              <View style={styles.form}>
                <Field
                  name='email'
                  component={Input}
                  icon={(props) => (
                    <CommonIcon {...props} name={'MAIL'} size={20} />
                  )}
                  helpText={t('VIEWS').REGISTER.FIELD_1.HELP_TEXT}
                  disabled={true}
                  inputProps={{
                    label: t('VIEWS').REGISTER.FIELD_1.LABEL,
                    disabled: true,
                    placeholder: t('VIEWS').REGISTER.FIELD_1.PLACEHOLDER,
                    [isWeb ? 'autocompletetype' : 'autoCompleteType']: 'email',
                    textContentType: 'emailAddress',
                    keyboardType: 'email-address',
                  }}
                />
                <Field
                  name='firstName'
                  component={Input}
                  inputProps={{
                    label: t('VIEWS').REGISTER.FIELD_2.LABEL,
                    placeholder: t('VIEWS').REGISTER.FIELD_2.PLACEHOLDER,
                  }}
                />
                <Field
                  name='lastName'
                  component={Input}
                  inputProps={{
                    label: t('VIEWS').REGISTER.FIELD_3.LABEL,
                    placeholder: t('VIEWS').REGISTER.FIELD_3.PLACEHOLDER,
                  }}
                />
                <Field
                  name='phone'
                  icon={(props) => (
                    <CommonIcon {...props} name={'PHONE'} size={20} />
                  )}
                  component={Input}
                  inputProps={{
                    label: t('VIEWS').REGISTER.FIELD_4.LABEL,
                    placeholder: t('VIEWS').REGISTER.FIELD_4.PLACEHOLDER,
                    textContentType: 'telephoneNumber',
                    keyboardType: 'phone-pad',
                  }}
                />
                <View style={styles.checkbox}>
                  <Checkbox.Android
                    status={check}
                    onPress={() => handleCheck()}
                  />
                  <Text onPress={navToAggreement} style={styles.private}>
                    {t('VIEWS').REGISTER.TEXT_7}{' '}
                    <Text style={styles.privateLink} onPress={navToAggreement}>
                      {t('VIEWS').REGISTER.TEXT_8}
                    </Text>
                  </Text>
                </View>
                <ButtonCustom
                  disabled={!isValid || check === 'unchecked'}
                  isLoading={isSubmitting}
                  onPress={handleSubmit}
                >
                  {t('VIEWS').REGISTER.TEXT_6}
                </ButtonCustom>
              </View>
            )}
          </Formik>
        </View>
      </Layout>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  subheading: {
    fontWeight: '500',
    marginBottom: 8,
  },
  content: {
    flex: 1,
    backgroundColor: '#FAFAFA',
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 8,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 48,
    marginTop: 4,
  },
  header: {
    height: 97,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
  form: {
    padding: 16,
    marginTop: 22,
  },
  logo: {
    width: 186,
    height: 53,
    resizeMode: 'contain',
  },
  names: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameInput: {
    flexBasis: '49%',
  },
  private: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 22,
    flexWrap: 'wrap',
    flex: 1,
  },
  privateLink: {
    fontWeight: '700',
    color: '#31879f',
    fontSize: 16,
    textDecorationLine: 'underline',
    lineHeight: 22,
  },
  checkbox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  cardHeader: {
    borderRadius: 0,
  },
})

export default RegisterScreen
