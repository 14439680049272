import React, { useState, useContext, useCallback } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { Title, Subheading, Text } from 'react-native-paper'
import ActionSheet from 'react-native-actions-sheet'

import { CommonIcon } from '@modules/common/components'
import { CropContext } from '@contextState/crop'
import { ConnectionContext } from '@contextState/connection'
import { SelectorContext } from '@contextState/selectors'
import { AuthContext } from '@contextState/auth'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import EiqCardSimple from '@components/common/v1/EiqCardSimple'
import ButtonCustom from '@components/common/ButtonCustom'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import useNetwork from '@utils/network'
import useValidateAchievement from '@hooks/useValidateAchievement'
import useModal from '@hooks/useModal'
import VerifyPinModal from '@components/modals/VerifyPinModal'
import { LanguageContext } from '@contextState/language'
import CommonList from '@components/common/CommonList'
import { SUPPLY } from '@constants/iconGuide'
import { getDescriptionSupplySelect } from '@utils/supply'
import useActionSheetManagement from '@hooks/useActionSheetManagement'
import { ModalConfirmDecline } from '@modules/activities/screens/activityCreate/v1/components'
import { secondary500, gray12 } from '@styles/palette'
import { roundSurface } from '@utils'
import EvidencesList from '@components/common/v1/EvidencesList'
import { onPressEvidenceFile } from '../../modules/activities/screens/activityCreate/v1/utils'
import { formatDateMoment } from '@utils/date'
import { CommonContext } from '@contextState/common'
import useValidatePin from '@hooks/useValidatePin'
import { truncateSurface } from '@common/utils'
import { ModalDefault } from '@common/components'

const AchievementValidation = ({ navigation, route }) => {
  const { loading } = useNetwork()

  const { currentCrop } = useContext(CropContext)
  const {
    setEstablishmentsSelector,
    setLotSelector,
    setSelectorTotalSurface,
    collaboratorSelector,
    setCollaboratorSelector,
    setSupplySelector,
  } = useContext(SelectorContext)
  const { isConnected } = useContext(ConnectionContext)
  const { user } = useContext(AuthContext)
  const { t, i18n } = useContext(LanguageContext)
  const { unitTypesSupplies } = useContext(CommonContext)
  const { isOpenModal, actionSheetRef, openActionSheet, closeActionSheet } =
    useActionSheetManagement()
  const [achievements] = useState(route.params.achievement)
  const [crop] = useState(route.params.crop)
  const [canWrites, setCanWrites] = useState(false)
  const [signer, setSigner] = useState(null)
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const {
    loading: loadingValidate,
    setParams,
    validateAchievement,
  } = useValidateAchievement()

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      getAchievementData()

      return () => true
    }, [])
  )

  /**
   * GET DATA ACHIEVEMENT
   */
  const getAchievementData = async () => {
    const { achievement, canWrite } = route.params

    setCanWrites(canWrite)
    setCollaboratorSelector(
      achievement.signers.map((el) => ({
        userId: el.userId,
        fullName: el.fullName,
        email: el.email,
        type: el.type || 'Colaborador',
        signed: el.signed,
      }))
    )
    setSigner(achievement.signers.find((el) => el.userId === user._id))

    setLotSelector(achievement.lots.map((el) => el._id))
    setSupplySelector(achievement.supplies)
    const newEstablishments = []
    if (achievement?.lotsWithSurface) {
      achievement?.lotsWithSurface.forEach((element) => {
        let establishmentName = ''

        const establishments = currentCrop.establishments ?? currentCrop.lots

        const lotsInCrop = establishments.find((subElement) => {
          const lots = subElement.lots ?? subElement.data

          return lots.find(
            (subSubElement) => subSubElement._id === element.lot._id
          )
        })

        if (lotsInCrop) {
          establishmentName = lotsInCrop.tag
        }

        if (!newEstablishments.length) {
          newEstablishments.push({
            tag: establishmentName,
            lots: [
              {
                ...element,
                ...element.lot,
                surfaceRealized: element.surfaceAchievement,
              },
            ],
          })
        } else {
          let exist = false

          newEstablishments.map((subElement) => {
            if (subElement.tag === establishmentName) {
              subElement.lots.push({
                ...element,
                ...element.lot,
                surfaceRealized: element.surfaceAchievement,
              })

              exist = true
            }
          })

          if (!exist) {
            newEstablishments.push({
              tag: establishmentName,
              lots: [
                {
                  ...element,
                  ...element.lot,
                  surfaceRealized: element.surfaceAchievement,
                },
              ],
            })
          }
        }
      })
    } else {
      achievement?.lots.forEach((element) => {
        let establishmentName = ''

        const establishments = currentCrop.establishments ?? currentCrop.lots

        const lotsInCrop = establishments.find((subElement) => {
          const lots = subElement.lots ?? subElement.data

          return lots.find(
            (subSubElement) => subSubElement._id === element.lot._id
          )
        })

        if (lotsInCrop) {
          establishmentName = lotsInCrop.tag
        }

        if (!newEstablishments.length) {
          newEstablishments.push({
            tag: establishmentName,
            lots: [
              {
                ...element,
                surfaceRealized: element.surface,
              },
            ],
          })
        } else {
          let exist = false

          newEstablishments.map((subElement) => {
            if (subElement.tag === establishmentName) {
              subElement.lots.push({
                ...element,
                surfaceRealized: element.surface,
              })

              exist = true
            }
          })

          if (!exist) {
            newEstablishments.push({
              tag: establishmentName,
              lots: [
                {
                  ...element,
                  surfaceRealized: element.surface,
                },
              ],
            })
          }
        }
      })
    }

    setEstablishmentsSelector(newEstablishments)

    setSelectorTotalSurface(truncateSurface(achievement.surface ?? 0))
  }

  /**
   * HANDLE PRESS COLLABORATOR
   */
  const handleCollaboratorsNav = () => {
    navigation.navigate('CollaboratorsSelectorModal', {
      id: crop._id,
      status: 'readOnly',
    })
  }

  /**
   * SIGN ACHIEVEMENT
   * @param {*} id
   */
  async function sign() {
    const params = {
      crop: crop._id,
      activity: route.params.activity,
      mode: route.params.mode,
      tag: route.params.tag,
      achievements: achievements._id,
      cropData: crop,
    }
    setParams(params)
    if (verifyExpirationPin()) {
      toggleModal(true)
    } else {
      const { validate } = await geTokenAuthPin()
      if (!validate) {
        toggleModal(true)
        return
      }
      successPin(params)
    }
  }

  const successPin = async (data = null) => {
    try {
      await validateAchievement(typeof data === 'object' ? data : null)

      closeModal()

      navigation.goBack()
    } catch (error) {
      console.error('error', error.message)
      closeModal()
    }
  }

  function navigateToSupplyDetail(supplyId, key) {
    navigation.navigate('SupplyDetail', {
      id: supplyId,
      key: key,
      surface: roundSurface(achievements.surface ?? 0),
      readOnly: true,
    })
  }

  const goToDetailsPlanning = () => {
    navigation.navigate('DetailsRealization', {
      cropId: crop._id,
      activityId: route.params.activity,
      routeRedirect: 'AchievementValidation',
      readOnly: true,
    })
  }

  const getAchievementEiq = ({ supplies, lotsWithSurface, surface }) => {
    const surfaceRealized = lotsWithSurface
      ? lotsWithSurface.reduce(
          (subAccumulator, { surfaceAchievement }) =>
            subAccumulator + surfaceAchievement,
          0
        )
      : surface

    return calculateEiq(supplies, surfaceRealized)
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.containerTitle}>
          <View style={styles.containerHeadTitle}>
            <Title style={styles.title}>
              {`${crop.cropType?.codeLabel} ${formatDateMoment(
                crop.dateCrop,
                'MMM/YYYY',
                i18n.locale,
                true
              )} ${formatDateMoment(
                crop.dateHarvest,
                'MMM/YYYY',
                i18n.locale,
                true
              )}`}
            </Title>
            <Subheading style={styles.subTitle}>
              {roundSurface(crop?.surface ?? 0)} {crop?.areaUnit ?? ''} -{' '}
              {crop.company?.name}
            </Subheading>
          </View>

          <View>
            <Text style={styles.subTitles}>
              {formatDateMoment(
                achievements.dateAchievement,
                'DD/MMM/YYYY',
                i18n.locale,
                true
              )}
            </Text>
            <TouchableOpacity
              onPress={goToDetailsPlanning}
              style={styles.containerDetailsLots}
            >
              <Text style={styles.subTitles}>{`${
                achievements.lots ? achievements.lots.length : 0
              } ${t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_6} - ${roundSurface(
                achievements.surface ?? 0
              )} ${crop?.areaUnit ?? ''}`}</Text>
              <CommonIcon name='CHEVRON-RIGHT' size={20} />
            </TouchableOpacity>
          </View>
        </View>

        <EiqCardSimple
          title={`${
            t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_7
          }  ${getAchievementEiq(achievements)}`}
          range={getEIQRange(getAchievementEiq(achievements))}
          tag='ACT_APPLICATION'
        />

        {achievements.supplies &&
          achievements.supplies.map((element, key) => {
            const unitName = unitTypesSupplies.find(
              (unit) => unit.key === element.unit
            )
            element.unitName = unitName?.keyLabel
            return (
              <CommonList
                key={key}
                index={key}
                img={
                  SUPPLY[
                    element?.typeId?.code ? element?.typeId?.code : 'default'
                  ]
                }
                onPress={() => navigateToSupplyDetail(element._id, key)}
                data={getDescriptionSupplySelect(
                  element,
                  currentCrop.areaUnit,
                  element.surface
                )}
              />
            )
          })}

        <View style={styles.containerCollaborator}>
          <CollaboratorsButton
            title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
            quantity={collaboratorSelector.length}
            onPress={handleCollaboratorsNav}
          />
        </View>

        <EvidencesList
          evidences={achievements.files}
          disabled
          onPress={onPressEvidenceFile}
        />

        {signer && canWrites && (
          <View>
            <ButtonCustom
              isLoading={loading}
              disabled={signer.signed || !isConnected}
              onPress={sign}
            >
              <Text style={styles.titleSign}>
                {signer.signed
                  ? t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_4
                  : t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_5}
              </Text>
            </ButtonCustom>
            <ButtonCustom
              isLoading={loading}
              disabled={signer.signed || !isConnected}
              onPress={openActionSheet}
              styles={
                !isConnected || signer.signed
                  ? styles.rejectButtonDisabled
                  : styles.rejectButton
              }
              labelStyle={
                !isConnected || signer.signed
                  ? styles.rejectTextButtonDisabled
                  : styles.rejectTextButton
              }
            >
              {t('VIEWS').COMMON_ACTIVITIES_DETAILS.TEXT_4}
            </ButtonCustom>
          </View>
        )}
      </View>
      <VerifyPinModal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={successPin}
        externalLoading={loadingValidate}
      />
      {Platform.OS !== 'web' ? (
        <ActionSheet ref={actionSheetRef} containerStyle={styles.actionStyles}>
          <View style={styles.containerActionSheet}>
            <ModalConfirmDecline
              closeActionSheet={closeActionSheet}
              onSuccess={() => navigation.goBack()}
              paramsRequest={{
                method: 'PATCH',
                url: `achievements/${route.params?.achievement?._id}/reject`,
                version: 'v2',
                data: {
                  cropId: route?.params?.crop?._id,
                  activityId: route?.params?.activity,
                },
              }}
            />
          </View>
        </ActionSheet>
      ) : (
        <ModalDefault
          isModalVisible={isOpenModal}
          closeModal={closeActionSheet}
          hasIconCloseModal={false}
        >
          <ModalConfirmDecline
            modal
            closeActionSheet={closeActionSheet}
            onSuccess={() => navigation.goBack()}
            paramsRequest={{
              method: 'PATCH',
              url: `achievements/${route.params?.achievement?._id}/reject`,
              version: 'v2',
              data: {
                cropId: route?.params?.crop?._id,
                activityId: route?.params?.activity,
              },
            }}
          />
        </ModalDefault>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  containerTitle: {
    flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
    lineHeight: 16,
    maxWidth: 260,
  },
  titleSign: {
    fontSize: 16,
    fontWeight: '500',
    color: '#fff',
    lineHeight: 16,
    maxWidth: 260,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333',
  },
  iconSupply: {
    color: 'white',
  },
  containerHeadTitle: {
    marginBottom: 8,
  },
  subTitles: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  card: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  cardItem: {
    flexBasis: '20%',
  },
  supplies: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16,
    marginBottom: 16,
  },
  leftContainerSupplies: {
    alignItems: 'center',
    width: 40,
    height: '100%',
    marginHorizontal: 20,
  },
  rightContainerSupplies: {
    width: '70%',
  },
  icoContainer: {
    height: 41,
    width: 41,
    borderRadius: 64,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4CAF50',
  },
  iconContainerSupplies: {
    borderRadius: 64,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9E9E9E',
    width: 40,
    height: 40,
  },
  spaceCard: {
    marginBottom: 10,
  },
  containerCollaborator: {
    marginTop: 16,
    marginBottom: 16,
  },
  Image: {
    height: 50,
    width: 50,
  },
  containerDetailsLots: {
    flex: 1,
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rejectButton: {
    marginTop: 16,
    borderColor: secondary500,
    backgroundColor: 'transparent',
    borderWidth: 1,
    elevation: 0,
  },
  rejectTextButton: {
    color: secondary500,
    textTransform: 'uppercase',
  },
  rejectButtonDisabled: {
    marginTop: 16,
    borderColor: gray12,
    backgroundColor: 'transparent',
    borderWidth: 1,
    elevation: 0,
  },
  rejectTextButtonDisabled: {
    color: gray12,
    textTransform: 'uppercase',
  },
})

export default AchievementValidation
