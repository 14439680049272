import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'

export const FormEditFarmName = ({ formik }) => {
  const { t } = useContext(LanguageContext)
  const label = useCallback(
    t('VIEWS').FARM_EDIT_NAME.COMPONENTS.FORM_EDIT_FARM_NAME.TEXT_1,
    [t]
  )

  const { setFieldValue, values, setFieldTouched, touched, errors } = formik

  useEffect(() => {
    setFieldTouched('farmName')
  }, [])

  return (
    <Field
      component={InputText}
      name='farmName'
      id='farmName'
      label={label}
      placeholder={label}
      value={values?.farmName}
      onChangeText={(value) => setFieldValue('farmName', value?.trimStart())}
      onBlur={() => setFieldTouched('farmName')}
      touched={touched.farmName}
      error={errors.farmName}
    />
  )
}

FormEditFarmName.propTypes = {
  formik: PropTypes.object.isRequired,
}
