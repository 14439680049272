import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { blackHighEmphasis } from '@styles/palette'
import { formatDateMoment } from '@utils/date'
import { roundSurface } from '@utils/common'

export const CropDescription = ({
  crop: {
    cropType: { _id: cropTypeId },
    dateCrop,
    dateHarvest,
    surface,
    company: { name: companyName },
    areaUnit,
  },
}) => {
  const { i18n } = useContext(LanguageContext)
  const { cropTypes } = useContext(CommonContext)

  const cropTypeName = cropTypes.find(
    (element) => element.value === cropTypeId
  ).label

  return (
    <View style={styles.container}>
      <Text
        style={styles.cropName}
        numberOfLines={1}
        ellipsizeMode='tail'
      >{`${cropTypeName} ${formatDateMoment(
        dateCrop,
        'MMM/YYYY',
        i18n.locale,
        true
      )} - ${formatDateMoment(
        dateHarvest,
        'MMM/YYYY',
        i18n.locale,
        true
      )}`}</Text>

      <Text
        style={styles.surfaceAndCompany}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {`${roundSurface(surface)} ${areaUnit} - ${companyName}`}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
  },
  cropName: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginVertical: 1,
  },
  surfaceAndCompany: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
})

CropDescription.propTypes = {
  crop: PropTypes.object,
}
