import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { round } from 'lodash'

import { LanguageContext } from '../../contextState/language'
import Accordion from '../common/v1/Accordion'
import ListHorizontalLots from '../common/v1/ListHorizontalLots'
import { blackHighEmphasis } from '@styles/palette'
import TitleIcon from '../../screens/v1/CropStory/components/TitleIcon'
import { CommonIcon } from '@modules/common/components'

const LotDetailCard = ({
  establishments,
  onGoToLandscapeSustainability,
  areaUnit,
}) => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.container}>
      <TitleIcon
        text={t('COMPONENTS').BINNACLE.LOT_DETAIL_CARD.TEXT_1}
        Icon={() => <CommonIcon name={'FIELDS'} size={24} />}
      />

      {establishments.map((establishment, index) => {
        const title = `${establishment.tag} - ${establishment.lotsQuantity} ${
          t('COMPONENTS').BINNACLE.LOT_DETAIL_CARD.TEXT_3
        }`
        const subtitle = `${round(establishment.surface, 2)} ${areaUnit}`
        return (
          <Accordion
            title={title}
            subtitle={subtitle}
            style={styles.contentAccordion}
            key={`establishment-${index}`}
          >
            <ListHorizontalLots
              lots={establishment.lots}
              onGoToLandscapeSustainability={onGoToLandscapeSustainability}
            />
          </Accordion>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  contentAccordion: {
    padding: 15,
  },
  contentTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25,
    marginTop: 5,
  },
  textTitle: {
    fontSize: 16,
    color: blackHighEmphasis,
    marginLeft: 14,
    fontWeight: '700',
  },
})

LotDetailCard.propTypes = {
  establishments: PropTypes.arrayOf(
    PropTypes.shape({
      lotsQuantity: PropTypes.number.isRequired,
      surface: PropTypes.number.isRequired,
      tag: PropTypes.string.isRequired,
      lots: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          nameLot: PropTypes.string.isRequired,
          surface: PropTypes.number.isRequired,
          countAchievement: PropTypes.number.isRequired,
          eiq: PropTypes.shape({
            quantity: PropTypes.number.isRequired,
            range: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
  onGoToLandscapeSustainability: PropTypes.func.isRequired,
}

export default LotDetailCard
