import getDatabase from './getDataBase'

export const QUEUE_TYPES = {
  CREATE_COLLABORATOR: 'create_collaborator',
  COMMON_UPDATE: 'common_update',
  COMMON_CREATE: 'common_create',
}

const CREATE_QUERY = `CREATE TABLE IF NOT EXISTS offline_queue (
      id integer primary key not null,
      data text,
      params text,
      time text,
      synced integer,
      type string
    );`

function useOfflineQueue() {
  const db = getDatabase('db.offlinedata')
  function initOfflineQueue() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          CREATE_QUERY,
          [],
          () => {
            resolve()
          },
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  function storeQueueItem(data, params, type) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          'INSERT INTO offline_queue (data, params, type, time, synced) values (?,?,?,?,0)',
          [JSON.stringify(data), JSON.stringify(params), type, Date.now()],
          () => resolve(),
          (_, err) => console.warn(`STORE QUEUE ERR: ${err}`)
        )
      })
    })

    return promise
  }

  function showQueue() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }
      db.transaction((tx) => {
        tx.executeSql(
          'SELECT * FROM offline_queue where synced = 0',
          [],
          (_, { rows }) => {
            resolve(
              rows._array.map((el) => {
                return {
                  ...el,
                  type: el.type,
                  time: Date.now(),
                  data: JSON.parse(el.data),
                  params: JSON.parse(el.params),
                }
              })
            )
          }
        )
      })
    })

    return promise
  }

  async function changeSyncedFlag(id) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          `UPDATE offline_queue SET synced = 1 where id = '${id}'`,
          [],
          () => resolve(),
          (_, err) => console.warn('ERR: at changeSyncedFlag', err)
        )
      })
    })

    return promise
  }

  async function dropAllQueue() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }

      db.transaction(
        (tx) => {
          tx.executeSql(
            'DROP TABLE IF EXISTS offline_queue;',
            [],
            () => {
              initOfflineQueue()

              tx.executeSql('DELETE FROM crops', [], null, (_, err) => {
                console.error(err)
              })

              tx.executeSql(
                'DELETE FROM individuals_crops',
                [],
                null,
                (_, err) => console.error(err)
              )
            },
            (t, error) => {
              console.error(error)
            }
          )
        },
        null,
        () => resolve()
      )
    })

    return promise
  }

  return {
    initOfflineQueue,
    storeQueueItem,
    showQueue,
    dropAllQueue,
    changeSyncedFlag,
  }
}

export default useOfflineQueue
