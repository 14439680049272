import React, { useContext } from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Image,
  Platform,
  Animated,
  TouchableOpacity,
} from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import AccordionItem from '@components/common/v2/AccordionItem'
import {
  red,
  black,
  secondary500,
  blackHighEmphasis,
  onPressedColor,
  primary500,
  grayBackground,
  blackDisabled,
  amber50,
  buttonDisabled,
} from '@styles/palette'
import { formatSurface } from '@modules/common/utils'

export const LotsList = ({
  farmName,
  kmzLots = [],
  selectKmzToggle,
  selectLotToggle,
  removeKmz,
  displayErrorDisabled,
}) => {
  const { t } = useContext(LanguageContext)
  const ContentHeader = ({
    arrowAngle,
    onPress,
    otherParams: { key, kmz },
    isOpened,
  }) => {
    return (
      <View
        style={[
          styles.headerAccordionContainer,
          kmz.disable && styles.headerAccordionContainerDisabled,
        ]}
      >
        <CommonIcon
          name={'X'}
          size={26}
          style={styles.headerAccordionLeftIcon}
          onPress={() => removeKmz(key)}
        />

        <TouchableRipple
          onPress={onPress}
          rippleColor={onPressedColor}
          underlayColor={onPressedColor}
          style={styles.headerTextContainer}
        >
          <>
            <Text
              style={[styles.headerText, isOpened && styles.headerTextSelected]}
              numberOfLines={1}
            >
              {kmz.kmzName}
            </Text>

            <Animated.View
              style={[
                styles.headerAccordionRightIcon,
                { transform: [{ rotateZ: arrowAngle }] },
              ]}
            >
              <CommonIcon
                name='CHEVRON-DOWN'
                size={25}
                color={blackHighEmphasis}
              />
            </Animated.View>
          </>
        </TouchableRipple>
      </View>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
    >
      <View style={styles.farmDescription}>
        <View style={styles.farmDescriptionTextContainer}>
          <Text style={styles.farmName}>{farmName}</Text>
        </View>
      </View>

      {kmzLots.map((kmz, key) => (
        <AccordionItem
          key={key}
          otherParams={{
            key,
            kmz,
          }}
          ContentHeader={ContentHeader}
          extraBodyStyle={styles.extraBodyAccordion}
          openByDefault
        >
          <>
            <View style={styles.lotsHeader}>
              <CommonIcon
                name={
                  kmz.selected ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'
                }
                size={24}
                color={
                  kmz.isAllDisabled
                    ? buttonDisabled
                    : kmz.selected
                    ? primary500
                    : blackHighEmphasis
                }
                style={styles.lotsHeaderIcon}
                onPress={() => selectKmzToggle(kmz)}
              />

              <View style={styles.subContainerLotsHeader}>
                <View style={styles.lotTexts}>
                  <Text style={styles.lotName}>
                    {t('VIEWS.FARM_LOTS_ADD.COMPONENTS.LOTS_LIST.TEXT_1')}
                  </Text>
                </View>
              </View>
            </View>

            {kmz.lots.map((lot, lotKey) => (
              <TouchableOpacity
                key={lotKey}
                style={styles.containerLot}
                onPress={() => displayErrorDisabled(lot)}
              >
                <View style={styles.subContainerLot}>
                  {lot?.disable ? (
                    !lot?.isValid ? (
                      <CommonIcon
                        activeOpacity={lot.disable ? 0.2 : 1}
                        name='INFO'
                        size={24}
                        color={secondary500}
                        style={styles.lotIcon}
                      />
                    ) : (
                      <View
                        activeOpacity={lot.disable ? 0.2 : 1}
                        style={styles.lotWithoutIcon}
                      />
                    )
                  ) : (
                    <CommonIcon
                      name={
                        lot.selected
                          ? 'CHECKBOX-SELECTED'
                          : 'CHECKBOX-UNSELECTED'
                      }
                      size={24}
                      color={lot.selected ? primary500 : blackHighEmphasis}
                      onPress={() => selectLotToggle(kmz, lot)}
                      style={styles.lotIcon}
                    />
                  )}

                  <View style={styles.lotTexts}>
                    <Text
                      activeOpacity={lot.disable ? 0.2 : 1}
                      style={[
                        styles.lotName,
                        lot.disable && styles.textDisabled,
                      ]}
                    >
                      {lot.name}
                    </Text>

                    <Text
                      style={[
                        styles.lotSurface,
                        lot.disable && styles.textDisabled,
                      ]}
                    >
                      {formatSurface(lot.surface, lot.areaUnit)}
                    </Text>

                    {Boolean(lot.cityName || lot.provinceName) && (
                      <Text
                        activeOpacity={lot.disable ? 0.2 : 1}
                        style={styles.lotLocation}
                      >
                        {(lot.cityName ? lot.cityName : '') +
                          ' ' +
                          (lot.provinceName ? lot.provinceName : '')}
                      </Text>
                    )}

                    {lot.disable && Boolean(lot?.disabledReasons?.length) && (
                      <View style={styles.lotDisabledReasonsContainer}>
                        <View style={styles.lotDisabledReason}>
                          <Text style={styles.lotDisabledReasonText}>
                            {lot?.disabledReasons[0].toUpperCase()}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>

                  <View style={styles.lotImageContainer}>
                    <Image
                      source={{
                        uri: lot.image,
                      }}
                      style={styles.lotImage}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </>
        </AccordionItem>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    marginBottom: 16,
  },
  farmDescription: {
    flexDirection: 'row',
    marginLeft: 75,
  },
  farmDescriptionTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    width: '100%',
  },
  farmName: {
    fontSize: 16,
    fontWeight: '500',
  },
  extraBodyAccordion: {
    margin: 0,
    padding: 0,
  },
  headerAccordionContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  headerAccordionContainerDisabled: {
    opacity: 0.5,
  },
  headerAccordionLeftIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: red,
  },
  headerTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 16,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '500',
    color: black,
    paddingRight: 46,
  },
  headerTextSelected: {
    color: primary500,
  },
  headerAccordionRightIcon: {
    marginLeft: 'auto',
    marginRight: 25,
  },
  lotsHeader: {
    flexDirection: 'row',
    backgroundColor: grayBackground,
    paddingLeft: 10,
    paddingRight: 10,
  },
  lotsHeaderIcon: {
    marginLeft: 15,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  subContainerLotsHeader: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    width: '100%',
  },
  containerLot: {
    backgroundColor: grayBackground,
    paddingLeft: 5,
    paddingRight: 5,
  },
  subContainerLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  lotIcon: {
    marginLeft: 30,
    marginRight: 30,
  },
  lotWithoutIcon: {
    width: 85,
  },
  lotTexts: {
    flex: 1,
  },
  lotName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: blackHighEmphasis,
    marginBottom: 5,
  },
  lotLocation: {
    fontSize: 14,
    color: blackHighEmphasis,
  },
  lotDisabledReasonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  lotDisabledReason: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: secondary500,
    borderRadius: 4,
    paddingTop: 2,
    paddingBottom: 4,
    paddingHorizontal: 4,
    marginTop: 4,
    marginRight: 4,
  },
  lotDisabledReasonText: {
    fontSize: 10,
    fontWeight: '500',
    color: amber50,
    textTransform: 'uppercase',
  },
  lotImageContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  lotImage: {
    height: 60,
    width: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  subContainerDisabledLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    opacity: 0.5,
  },
  textDisabled: {
    color: blackDisabled,
  },
  contentErrorLot: {
    marginTop: 5,
    flex: 1,
    flexDirection: 'row',
  },
})

LotsList.propTypes = {
  farmName: PropTypes.string.isRequired,
  kmzLots: PropTypes.array.isRequired,
  displayKmzToggle: PropTypes.func.isRequired,
  selectKmzToggle: PropTypes.func.isRequired,
  selectLotToggle: PropTypes.func.isRequired,
  removeKmz: PropTypes.func.isRequired,
}
