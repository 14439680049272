import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet } from 'react-native'
import { List } from 'react-native-paper'
import { blackDisabled } from '@styles/palette'

const DrawerItem = ({
  disable,
  title,
  titleStyle = {},
  description,
  onPress,
  style = {},
  left,
  right,
}) => {
  const handleNotConnectionOrIsPendingRequest = () => {
    console.warn('Not connection or pending for authorization')
  }

  return (
    <List.Item
      onPress={!disable ? onPress : handleNotConnectionOrIsPendingRequest}
      title={title}
      description={description}
      left={left}
      right={right}
      titleStyle={[titleStyle, disable ? styles.disabled : {}]}
      style={style}
    />
  )
}

const styles = StyleSheet.create({
  disabled: {
    color: blackDisabled,
  },
})

DrawerItem.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      cropId: PropTypes.string.isRequired,
    }),
  }),
  disable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    ),
  ]),
  description: PropTypes.string,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    ),
  ]),
  left: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

export default DrawerItem
