import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Paragraph } from 'react-native-paper'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import useModal from '@hooks/useModal'
import {
  secondary600,
  blackHighEmphasis,
  white,
  gray12,
  transparent,
  primary500,
} from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import ModalSuccess from '@components/common/v1/ModalSuccess'
import { ChildrenModalSuccess } from '@common/components/modals/ChildrenModalSuccess'
import useNetwork from '@utils/network'
import useOfflineUser from '@offline/queries/user'
import useOfflineQueue from '@offline/queries/queue'
import { signOut } from '@services/firebase'
import { AuthContext } from '@contextState/auth'

const DeleteAccount = ({ navigation }) => {
  let finish = false
  const { t } = useContext(LanguageContext)
  const { deleteUser } = useOfflineUser()
  const { dropAllQueue } = useOfflineQueue()
  const { doRequest } = useNetwork()
  const { isConnected } = useContext(ConnectionContext)
  const { setAuth } = useContext(AuthContext)
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const { isModalVisible: isModalSuccess, toggleModal: toggleModalSuccess } =
    useModal()

  useEffect(() => {
    if (isModalSuccess) {
      setTimeout(() => {
        if (!finish) {
          onSignOut()
        }
      }, 10000)
    }
  }, [isModalSuccess])

  const onSignOut = async () => {
    try {
      finish = true
      if (Platform.OS !== 'web') {
        await deleteUser()
        await dropAllQueue()
      }
      signOut()
      await AsyncStorage.clear()
      setAuth(false)
      navigation.goBack()
    } catch (err) {
      console.error(err)
    }
  }

  const goBackScreen = () => {
    navigation.goBack()
  }
  const deleteAccount = async () => {
    try {
      await doRequest({
        method: 'delete',
        url: 'auth/delete-account',
        version: 'v2',
      })
      toggleModalSuccess()
    } catch (error) {
      console.error('error', error)
      closeModal()
    }
  }
  return (
    <>
      <View style={styles.container}>
        <View>
          <Paragraph style={[styles.paragraph, styles.marginBottom]}>
            {t('VIEWS.DELETE_ACCOUNT.PARAGRAPH_BY_DELETING_ACCOUNT')}
          </Paragraph>
          <Paragraph style={styles.paragraph}>
            {t('VIEWS.DELETE_ACCOUNT.PARAGRAPH_ACTION_IRREVERSIBLE')}
          </Paragraph>
        </View>
        <View>
          <ButtonPrimary
            text={t(`VIEWS.DELETE_ACCOUNT.DELETE_ACCOUNT`).toUpperCase()}
            backgroundColor={isConnected ? secondary600 : gray12}
            colorText={white}
            borderRadius={8}
            fontSize={14}
            fontWeight={'700'}
            disabled={!isConnected}
            onPress={toggleModal}
          />
          <ButtonPrimary
            text={t(`VIEWS.DELETE_ACCOUNT.CANCEL`).toUpperCase()}
            backgroundColor={transparent}
            colorText={primary500}
            borderColor={primary500}
            borderRadius={8}
            fontSize={14}
            fontWeight={'700'}
            marginTop={16}
            onPress={goBackScreen}
          />
        </View>
      </View>

      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onCancel={goBackScreen}
        onConfirm={deleteAccount}
        title={t(`VIEWS.DELETE_ACCOUNT.DELETE_ACCOUNT`)}
        contentText={t(
          `VIEWS.DELETE_ACCOUNT.MODAL_CONFIRM_DELETE_ACCOUNT.PARAGRAPH_1`
        )}
        contentTextStyle={styles.contentTextStyle}
        confirmText={t(
          'VIEWS.DELETE_ACCOUNT.MODAL_CONFIRM_DELETE_ACCOUNT.DELETE'
        )}
        cancelText={t('VIEWS.DELETE_ACCOUNT.CANCEL')}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />

      <ModalSuccess
        visible={isModalSuccess}
        title={''}
        description={''}
        style={styles.buttonContainer}
      >
        <ChildrenModalSuccess
          onPress={onSignOut}
          title={t(
            'VIEWS.DELETE_ACCOUNT.MODAL_SUCCESS_DELETED_ACCOUNT.DELETED_ACCOUNT'
          )}
          textSuccess={t(
            'VIEWS.DELETE_ACCOUNT.MODAL_SUCCESS_DELETED_ACCOUNT.PARAGRAPH_HAVE_DELETED_ACCOUNT'
          )}
          textButton={t(
            'VIEWS.DELETE_ACCOUNT.MODAL_SUCCESS_DELETED_ACCOUNT.CLOSE'
          )}
          titleStyle={styles.titleStyleModalSuccess}
          textSuccessStyle={styles.textSuccessStyleModalSuccess}
        />
      </ModalSuccess>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: white,
  },
  paragraph: {
    color: blackHighEmphasis,
    fontWeight: '400',
    fontSize: 16,
  },
  marginBottom: {
    marginBottom: 16,
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: secondary600,
    borderColor: secondary600,
    borderWidth: 1,
  },
  contentTextStyle: {
    fontWeight: '500',
    fontSize: 16,
    color: blackHighEmphasis,
    marginTop: 0,
  },
  titleStyleModalSuccess: {
    fontSize: 34,
    fontWeight: '500',
  },
  textSuccessStyleModalSuccess: {
    fontSize: 16,
    fontWeight: '400',
  },
})

DeleteAccount.propTypes = {
  navigation: PropTypes.object,
}
export default DeleteAccount
