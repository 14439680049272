export const monitoringFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  dateObservation: {
    value: '',
    label: '',
  },
  unitType: {
    value: '',
    label: '',
  },
  pay: '',
  observation: '',
  dateEstimatedHarvest: {
    value: '',
    label: '',
  },
  signers: [],
  evidences: [],
  files: [],
})

export const dataMonitoring = {
  name: 'Monitoreo',
  dateObservation: '2021-11-03T18:26:49.987Z',
  type: '5fdc934f21fee9f1df89b6cd',
  unitType: '5fa01d56d109e6791d233c5a',
  surface: 1000,
  dateEstimatedHarvest: '2022-09-10T04:00:00.000Z',
  pay: 500,
  observation: 'ninguna observación',
  crop: '613bb932c53c358eaa773f76',
  lots: ['613bb932c53c358eaa773f57'],
  signers: [
    {
      userId: '61244f9329c5bb4ec1157d9a',
      fullName: 'Alfonso Tortorici',
      email: 'alfonso@ucrop.it',
      type: 'PRODUCER',
      signed: false,
    },
    {
      userId: '6064c1dcbd342c6b61050ca7',
      fullName: 'M Falcon',
      email: 'marcos.falcon@ucrop.it',
      type: 'KAM',
    },
  ],
  evidences: [
    {
      description: 'Carta de porte',
      date: '2021-11-03T18:48:11.226Z',
      file: { path: 'Dieta - Guia basica del protocolo autoinmune paleo.pdf' },
      location: {},
      meta: { location: {} },
      settings: { isInLot: false },
    },
  ],
}
