import React, { useContext } from 'react'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { primary500, blackDisabled } from '@styles/palette'

const AddSupply = ({ pressAddSupply, disabled }) => {
  const { t } = useContext(LanguageContext)
  return (
    <ButtonPrimary
      text={t('VIEWS.ACHIEVEMENT_UNPLANNED.TEXT_1')}
      onPress={pressAddSupply}
      backgroundColor={'transparent'}
      disabled={disabled}
      colorText={disabled ? blackDisabled : primary500}
    />
  )
}

export default AddSupply
