import React from 'react'
import PropTypes from 'prop-types'

import {
  GeneralInfoCard,
  ActivityCard,
  SupplySeedCard,
  SupplyFertilizerCard,
  SupplyPhytotherapicCard,
  EvidenceCard,
  CollaboratorCard,
} from '../'

export const Realization = ({
  cropName,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  activityTypeName,
  subActivityTypeName,
  dateAchievement,
  sowingOutlier,
  suppliesSeeds,
  fertilizerOutlier,
  suppliesFertilizers,
  phytotherapicOutlier,
  suppliesPhytotherapics,
  evidences,
  collaboratorsQuantity,
}) => {
  return (
    <>
      <GeneralInfoCard cropName={cropName} companyName={companyName} />

      <ActivityCard
        fieldsQuantity={fieldsQuantity}
        surface={surface}
        unitArea={unitArea}
        activityTypeName={activityTypeName}
        subActivityTypeName={subActivityTypeName}
        dateAchievement={dateAchievement}
      />

      <SupplySeedCard
        outlier={sowingOutlier}
        unitArea={unitArea}
        supplyTypeName={suppliesSeeds.supplyTypeName}
        totalDensity={suppliesSeeds.totalDensity}
        unitType={suppliesSeeds.unitType}
        supplies={suppliesSeeds.supplies}
      />

      <SupplyFertilizerCard
        outlier={fertilizerOutlier}
        unitArea={unitArea}
        supplyTypeName={suppliesFertilizers.supplyTypeName}
        supplies={suppliesFertilizers.supplies}
      />

      <SupplyPhytotherapicCard
        outlier={phytotherapicOutlier}
        unitArea={unitArea}
        supplyTypeName={suppliesPhytotherapics.supplyTypeName}
        supplies={suppliesPhytotherapics.supplies}
      />

      <EvidenceCard evidences={evidences} />

      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

Realization.propTypes = {
  cropName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  activityTypeName: PropTypes.string.isRequired,
  subActivityTypeName: PropTypes.string.isRequired,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  dateAchievement: PropTypes.string,
  sowingOutlier: PropTypes.string,
  suppliesSeeds: PropTypes.shape({
    supplyTypeName: PropTypes.string,
    totalDensity: PropTypes.number,
    unitType: PropTypes.string,
    supplies: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        eiqTotal: PropTypes.number,
      })
    ),
  }),
  fertilizerOutlier: PropTypes.string,
  suppliesFertilizers: PropTypes.shape({
    supplyTypeName: PropTypes.string,
    supplies: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        eiqTotal: PropTypes.number,
      })
    ),
  }),
  phytotherapicOutlier: PropTypes.string,
  suppliesPhytotherapics: PropTypes.shape({
    supplyTypeName: PropTypes.string,
    supplies: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        unitType: PropTypes.string.isRequired,
        eiqTotal: PropTypes.number,
      })
    ),
  }),
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
  collaboratorsQuantity: PropTypes.number.isRequired,
}
