import React, { useContext, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import moment from 'moment'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { ConnectionContext } from '@contextState/connection'
import ActivityVerificationValid from '@components/common/v1/ActivityVerificationValid'
import ActivityVerificationInvalid from '@components/common/v1/ActivityVerificationInvalid'
import activityTypes from '@constants/activityTypes'
import verificationStatus from '@constants/verificationStatus'
import { downloadFile } from '@utils/files'
import { CommonIcon } from '@modules/common/components'

export const VerificationStatus = ({
  tag,
  activity,
  isRealized,
  isFinished,
}) => {
  if (
    tag !== activityTypes.ACT_VERIFICATION.key ||
    (!isRealized && !isFinished)
  ) {
    return false
  }

  const [isDownloadingVerificationFile, setIsDownloadingVerificationFile] =
    useState(false)

  const { t } = useContext(LanguageContext)
  const { verificationTypes } = useContext(CommonContext)
  const { isConnected } = useContext(ConnectionContext)

  const downloadVerificationFile = async (isConnected, path) => {
    if (!isConnected) {
      return
    }

    setIsDownloadingVerificationFile(true)

    try {
      downloadFile(path)
    } catch (error) {
      alert(t('COMPONENTS').COMMON.HEADER.TEXT_1)
    }

    setIsDownloadingVerificationFile(false)
  }

  const verificationTypeLabel = verificationTypes.find(
    (element) => element.value === activity.verificationType._id
  )?.label

  return (
    <View style={styles.container}>
      {activity.verificationStatus === verificationStatus.VALID.key ? (
        <ActivityVerificationValid
          verificationName={t(
            'VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_4',
            {
              verificationType: verificationTypeLabel,
            }
          )}
          fileName={activity.verificationFile.name}
          uploadedBy={`${activity.verificationFile.user.firstName} ${activity.verificationFile.user.lastName}`}
          uploadedAt={moment(activity.createdAt).format('DD/MM/YY')}
          right={() => {
            return (
              <View style={styles.rightIcon}>
                <CommonIcon
                  name={'FILE-DOWNLOAD'}
                  onPress={() =>
                    downloadVerificationFile(
                      isConnected,
                      activity.verificationFile.path
                    )
                  }
                  disabled={isDownloadingVerificationFile}
                />
              </View>
            )
          }}
        />
      ) : (
        <ActivityVerificationInvalid
          uploadedBy={activity.signers
            .filter((element) => element.signed)
            .map((element) => element.fullName)
            .join(', ')}
          uploadedAt={moment(activity.createdAt).format('DD/MM/YY')}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  rightIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 8,
  },
})

VerificationStatus.propTypes = {
  tag: PropTypes.string.isRequired,
  activity: PropTypes.object,
  isRealized: PropTypes.bool,
  isFinished: PropTypes.bool,
}
