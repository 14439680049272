import React from 'react'
import { StyleSheet } from 'react-native'
import { Button } from 'react-native-paper'

function ButtonCustom({
  children,
  isLoading = false,
  disabled = false,
  ...props
}) {
  return (
    <Button
      {...props}
      disabled={disabled || isLoading}
      style={[styles.button, props.styles]}
      contentStyle={[styles.button, props.contentStyle]}
      labelStyle={[styles.label, props.labelStyle]}
      loading={isLoading}
      uppercase={false}
    >
      {!isLoading && children}
    </Button>
  )
}

ButtonCustom.defaultProps = {
  mode: 'contained',
}

const styles = StyleSheet.create({
  button: { height: 50 },
  label: { color: 'white' },
})

export default ButtonCustom
