import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

export const EditableTitle = ({
  title,
  subTitle,
  styleSubTitle = {},
  icon = 'EDIT',
  onPress,
  showIcon = false,
}) => {
  return (
    <>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>

      <View style={[styles.container, styles.flexDirectionRow]}>
        <View style={styles.containerField}>
          <Text style={[styles.subTitle, styleSubTitle]} numberOfLines={1}>
            {subTitle}
          </Text>
        </View>

        {showIcon && (
          <CommonIcon
            name={icon}
            size={20}
            color={blackHighEmphasis}
            style={styles.icon}
            onPress={onPress}
          />
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 6,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  containerField: {
    flex: 1,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    color: blackHighEmphasis,
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 28.13,
    letterSpacing: 0.25,
    color: blackHighEmphasis,
  },
  icon: {
    marginRight: 18.5,
  },
})

EditableTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  styleSubTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onPress: PropTypes.func,
  showIcon: PropTypes.bool,
}
