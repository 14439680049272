import React, { useContext } from 'react'
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  ImageBackground,
  Linking,
} from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import PropTypes from 'prop-types'

import { SustainabilityIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import Accordion from '@components/common/v1/Accordion'
import TitleSection from '@components/landscapeSustainability/TitleSection'
import PercentageOverlap from '@components/landscapeSustainability/PercentageOverlap'
import ItemLandscapeSustainability from '@components/landscapeSustainability/ItemLandscapeSustainability'
import ItemOverlappingSurface from '@components/landscapeSustainability/ItemOverlappingSurface'
import statusEiq from '@constants/statusEiq'
import { getEIQRange } from '@utils/eiq'
import { primary500 } from '@styles/palette'
import { MapCard } from '@modules/cropStory/screens/CropStoryLotDetail/v1/components/MapCard'
import { getLotLocationUrl } from '@modules/cropStory/screens/CropStoryLotDetail/v1/utils'
import { roundSurface } from '@utils/common'

const Screen = ({
  isFetchingLot,
  image,
  name,
  cityName,
  provinceName,
  surface,
  applicationQuantity,
  eiq,
  percentageWithoutOverlapping,
  surfaceWithoutOverlapping,
  overlappingPercentage,
  overlappingSurface,
  normatives,
  details,
  cropAreaUnit,
  centerBound,
  widthStyle,
}) => {
  const { t } = useContext(LanguageContext)

  if (isFetchingLot) {
    return (
      <ActivityIndicator
        size='large'
        color={primary500}
        style={styles.loader}
      />
    )
  }

  const colorEiq = statusEiq[getEIQRange(eiq.quantity)]

  const goToMap = () => {
    const params = {
      name: name,
      centerBound,
    }

    const url = getLotLocationUrl(params)

    if (Linking.canOpenURL(url)) Linking.openURL(url)
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={[styles.contentHeader, widthStyle]}>
        <ImageBackground
          source={{ uri: image }}
          style={[styles.imageContainer, widthStyle]}
        >
          {centerBound && <MapCard onPress={goToMap} />}
        </ImageBackground>
      </View>

      <View style={styles.description}>
        <Text style={styles.name}>{name}</Text>

        <Text style={styles.location}>{`${cityName}, ${provinceName}`}</Text>

        <Text style={styles.surface}>
          {t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_2', {
            surface: roundSurface(surface),
            areaUnit: cropAreaUnit ?? '',
          })}
        </Text>

        <View style={styles.applicationsContainer}>
          <Text style={styles.applicationQuantity}>
            {t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_3', {
              applicationQuantity,
            })}
          </Text>

          {colorEiq && (
            <View
              style={[
                styles.eiqContainer,
                { backgroundColor: colorEiq.background },
              ]}
            >
              <SustainabilityIcon
                name='EIQ'
                size={14}
                style={[styles.eiqIcon, { color: colorEiq.color }]}
              />

              <Text style={[styles.eiqText, { color: colorEiq.color }]}>
                {t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_4', {
                  eiq: eiq.quantity,
                })}
              </Text>
            </View>
          )}
        </View>
      </View>

      <TitleSection
        iconName='LANDSCAPE-SUSTAINABILITY'
        text={t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_5')}
      />

      {percentageWithoutOverlapping !== undefined && (
        <View style={styles.generalOverlappingPercent}>
          <PercentageOverlap
            percentage={`${percentageWithoutOverlapping}%`}
            text={t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_6', {
              value: roundSurface(surfaceWithoutOverlapping),
              areaUnit: cropAreaUnit ?? '',
            })}
            overlap
          />
          <PercentageOverlap
            percentage={`${overlappingPercentage}%`}
            text={t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_7', {
              value: roundSurface(overlappingSurface),
              areaUnit: cropAreaUnit ?? '',
            })}
          />
        </View>
      )}

      <Accordion
        style={styles.accordion}
        title={t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_8')}
      >
        {normatives.map((element, key) => (
          <ItemLandscapeSustainability
            key={key}
            name={element.name}
            value={`${element.percent}% ${roundSurface(
              element.surface
            )} ${cropAreaUnit}`}
            horizontal
          />
        ))}
      </Accordion>

      <Accordion
        style={styles.accordion}
        title={t('VIEWS.LANDSCAPE_SUSTAINABILITY.TEXT_10')}
      >
        {details.map((element, key) => (
          <ItemLandscapeSustainability
            key={key}
            name={element.name}
            value={t(
              'COMPONENTS.BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_9',
              {
                value: element.overlappingPercentage,
              }
            )}
          >
            {element.categories.length ? (
              element.categories.map((subElement, key) => (
                <ItemOverlappingSurface
                  key={key}
                  name={subElement.name}
                  value={roundSurface(subElement.overlappingSurface)}
                  color={subElement.color}
                  areaUnit={cropAreaUnit}
                />
              ))
            ) : (
              <ItemOverlappingSurface
                value={roundSurface(element.overlappingSurface)}
                areaUnit={cropAreaUnit}
              />
            )}
          </ItemLandscapeSustainability>
        ))}

        <View style={styles.textClarificationContainer}>
          <Text style={styles.textClarification}>
            {t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_18}
          </Text>
        </View>
      </Accordion>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    backgroundColor: 'white',
    paddingBottom: 40,
  },
  accordion: {
    padding: 16,
    backgroundColor: '#F8F8F8',
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    borderBottomWidth: 1,
  },
  contentHeader: {
    height: 375,
    width: '100%',
  },
  imageContainer: {
    height: '100%',
    resizeMode: 'contain',
    justifyContent: 'flex-end',
  },
  description: {
    padding: 16,
  },
  name: {
    fontSize: 20,
    fontWeight: '500',
    color: '#333333',
  },
  location: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
  },
  surface: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  applicationsContainer: {
    flexDirection: 'row',
  },
  applicationQuantity: {
    flex: 1,
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  eiqContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 110,
    height: 32,
    paddingHorizontal: 5,
    borderRadius: 100,
  },
  eiqIcon: {
    marginLeft: 10,
    marginRight: 15,
  },
  eiqText: {
    fontSize: 12,
    fontWeight: '700',
  },
  generalOverlappingPercent: {
    backgroundColor: '#F8F8F8',
    paddingTop: 16,
  },
  textClarificationContainer: {
    backgroundColor: '#F8F8F8',
    padding: 16,
    marginBottom: 4,
  },
  textClarification: {
    fontSize: 14,
    color: '#6E6E6E',
  },
})

Screen.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  provinceName: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  surface: PropTypes.number.isRequired,
  applicationQuantity: PropTypes.number.isRequired,
  eiq: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    range: PropTypes.string.isRequired,
  }),
  overlappingPercentage: PropTypes.number,
  overlappingSurface: PropTypes.number,
  percentageWithoutOverlapping: PropTypes.number,
  surfaceWithoutOverlapping: PropTypes.number,
  normatives: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      surface: PropTypes.number.isRequired,
    })
  ),
  details: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      overlappingPercentage: PropTypes.number.isRequired,
      overlappingSurface: PropTypes.number,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          overlappingSurface: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  centerBound: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
}

export default Screen
