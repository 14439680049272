import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import PropTypes from 'prop-types'

import { onPressedColor } from '@styles/palette'

export const IconButton = ({ disabled = false, onPress, children }) => {
  return (
    <View style={styles.container}>
      <TouchableRipple
        disabled={disabled}
        style={[styles.iconContainer, disabled ? styles.iconDisabled : {}]}
        onPress={onPress}
        rippleColor={onPressedColor}
        underlayColor={onPressedColor}
      >
        {children}
      </TouchableRipple>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    borderRadius: 100,
    marginHorizontal: 5,
    overflow: 'hidden',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    borderRadius: 100,
  },
  iconDisabled: {
    opacity: 0.5,
  },
})

IconButton.propTypes = {
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
