import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import TextTitle from './components/TextTitle'
import SelectAll from './components/SelectAll'
import InputCompany from './components/InputCompany'
import { FlatList } from 'react-native-gesture-handler'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import {
  primary500,
  blackMediumEmphasis,
  blackHighEmphasis,
  white,
} from '@styles/palette'
import SuccessApplySubLicensesModal from './components/SuccessApplySubLicensesModal'
import ErrorApplySubLicensesModal from './components/ErrorApplySubLicensesModal'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import ModalConfirm from '@components/common/v1/ModalConfirm'

const Screen = ({
  textLots,
  subLicenses,
  isSelected,
  selectAndUnselectSubLicense,
  selectedAll,
  selectAndUnselectAll,
  t,
  applyLicense,
  subLicensesSelected,
  isModalVisible,
  toggleModal,
  goToLicenses,
  goToHome,
  loading,
  has,
  applyLicenseLoading,
  lotsLength,
  isModalConfirmApplyLicenseVisible,
  displayToggleModalConfirmApplyLicense,
  closeModalConfirmApplyLicense,
  isModalErrorVisible,
  cropNameText,
  disabledAll,
  lotsSelected,
  areaUnit,
}) => {
  return (
    <View style={styles.content}>
      <TextTitle text={cropNameText} />
      <TextTitle
        text={`${textLots} ${t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_7')}`}
        color={blackMediumEmphasis}
        extraStyle={{ marginTop: 0 }}
      />
      {loading ? (
        <LoaderCenterContainer />
      ) : (
        <View style={styles.content}>
          <SelectAll
            selectedAll={selectedAll}
            selectAndUnselectAll={selectAndUnselectAll}
            disabledAll={disabledAll}
            t={t}
          />
          <FlatList
            data={subLicenses}
            renderItem={({ item }) => {
              const checked = isSelected(item)
              return (
                <InputCompany
                  checked={checked}
                  textLots={textLots}
                  subLicense={item}
                  selectAndUnselectSubLicense={selectAndUnselectSubLicense}
                  t={t}
                  has={has}
                  lotsLength={lotsLength}
                  lotsSelected={lotsSelected}
                  areaUnit={areaUnit}
                />
              )
            }}
            keyExtractor={(item) => item._id}
            ListFooterComponentStyle={styles.contentButton}
            ListFooterComponent={() => (
              <ButtonPrimary
                disabled={
                  subLicensesSelected.length <= 0 || applyLicenseLoading
                }
                margin
                text={t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_1')}
                backgroundColor={
                  subLicensesSelected.length <= 0
                    ? blackMediumEmphasis
                    : primary500
                }
                colorText={
                  subLicensesSelected.length <= 0 ? blackHighEmphasis : white
                }
                fontSize={14}
                onPress={displayToggleModalConfirmApplyLicense}
              />
            )}
          />
        </View>
      )}
      <SuccessApplySubLicensesModal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        goToLicenses={goToLicenses}
        goToHome={goToHome}
        t={t}
      />
      <ErrorApplySubLicensesModal
        isModalErrorVisible={isModalErrorVisible}
        goToLicenses={goToLicenses}
        t={t}
        goToHome={goToHome}
      />

      <ModalConfirm
        visible={isModalConfirmApplyLicenseVisible}
        onClose={closeModalConfirmApplyLicense}
        onConfirm={() => {
          closeModalConfirmApplyLicense()
          applyLicense()
        }}
        title={t(
          'VIEWS.SUBLICENSES_SELECT_COMPANIES.MODAL_CONFIRM_COMPANIES.TEXT_1'
        )}
        contentText={t(
          'VIEWS.SUBLICENSES_SELECT_COMPANIES.MODAL_CONFIRM_COMPANIES.TEXT_2'
        )}
        cancelText={t(
          'VIEWS.SUBLICENSES_SELECT_COMPANIES.MODAL_CONFIRM_COMPANIES.TEXT_3'
        )}
        confirmText={t(
          'VIEWS.SUBLICENSES_SELECT_COMPANIES.MODAL_CONFIRM_COMPANIES.TEXT_4'
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: white,
    flex: 1,
  },
  contentTitle: {
    marginTop: 10,
    marginLeft: 17,
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
  },
  contentButton: { padding: 10 },
})

Screen.propTypes = {
  textLots: PropTypes.string,
  subLicenses: PropTypes.array,
  isSelected: PropTypes.func,
  selectAndUnselectSubLicense: PropTypes.func,
  selectedAll: PropTypes.bool.isRequired,
  selectAndUnselectAll: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isModalConfirmApplyLicenseVisible: PropTypes.bool.isRequired,
  displayToggleModalConfirmApplyLicense: PropTypes.func.isRequired,
  closeModalConfirmApplyLicense: PropTypes.func.isRequired,
}

export default Screen
