import React, { useState, useContext } from 'react'
import { StyleSheet } from 'react-native'

import { white } from '@styles/palette'

import EnterPin from './components/EnterPin'
import Confirm from './components/ConfirmPin'
import WizardPin from './components/WizardPin'

import useNetwork from '@utils/network'
import { AuthContext } from '@contextState/auth'
import useValidatePin from '@hooks/useValidatePin'
import { HeaderOnlyBack } from '@common/components'
import { ResponsiveLayout } from '@common/components/index'

const CreatePin = ({ route, navigation }) => {
  const { callbackPin } = route.params
  const { doRequest } = useNetwork()
  const { user, fetchUser } = useContext(AuthContext)
  const { persitPinAuth } = useValidatePin()
  const [pinToConfirm, setPinToConfirm] = useState('')
  const [step, setStep] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const onBack = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1)
    } else {
      navigation.goBack()
    }
  }

  const submitPin = async (codePin) => {
    try {
      setIsSubmit(true)
      const data = { pin: codePin }
      await doRequest({
        method: 'PUT',
        url: `users/${user._id}`,
        data,
      })
      persitPinAuth(codePin)
      await fetchUser()
      navigation.goBack()
      callbackPin()
      setIsSubmit(false)
    } catch (error) {
      console.error('error pin', error)
      setIsSubmit(false)
    }
  }

  const steps = {
    1: <WizardPin handleStep={handleStep} />,
    2: (
      <EnterPin
        value={pinToConfirm}
        setValue={setPinToConfirm}
        handleStep={handleStep}
      />
    ),
    3: (
      <Confirm
        pinToConfirm={pinToConfirm}
        submitPin={submitPin}
        isSubmit={isSubmit}
      />
    ),
  }

  return (
    <ResponsiveLayout style={styles.root}>
      <HeaderOnlyBack onPress={onBack} />
      {steps[step]}
    </ResponsiveLayout>
  )
}

export default CreatePin

const styles = StyleSheet.create({
  root: {
    backgroundColor: white,
    flex: 1,
  },
})
