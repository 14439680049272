import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'

export const OtherUserOnDraft = ({ isOtherUserOnDraft }) => {
  if (!isOtherUserOnDraft) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Text style={styles.otherUserOnScreenText}>
        {t('VIEWS.ACTIVITY_CREATE.TEXT_8', {
          userFullname: isOtherUserOnDraft,
        })}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#4F4F4F',
    padding: 16,
    marginVertical: 4,
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  otherUserOnScreenText: {
    fontSize: 14,
    fontWeight: '400',
    color: '#FFFFFF',
  },
})

OtherUserOnDraft.propTypes = {
  isOtherUserOnDraft: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
