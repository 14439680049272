import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { blackMediumEmphasis, blackHighEmphasis } from '@styles/palette'
import { round } from 'lodash'
import ButtonPrimary from '@components/buttons/ButtonPrimary'

const ListCardEstablishment = ({
  establishments,
  status,
  t,
  onPress,
  activity,
}) => {
  const getTextLots = (lots) =>
    lots.map(
      (lot) => `${lot.name} (${round(Number(lot.surface), 2)} ${lot.areaUnit})`
    )

  const statuses = {
    pending: t('VIEWS.ACTIVITIES.TEXT_54'),
    toMake: t('VIEWS.ACTIVITIES.TEXT_55'),
    finished: t('VIEWS.ACTIVITIES.TEXT_55'),
    done: t('VIEWS.ACTIVITIES.TEXT_55'),
    draft: t('VIEWS.ACTIVITIES.TEXT_56'),
  }

  return (
    <View style={styles.content}>
      <View>
        {establishments.map((establishment) => {
          return (
            <View
              key={`establishment-${establishment._id}`}
              style={styles.marginTop}
            >
              <Text style={styles.title}>{t('VIEWS.ACTIVITIES.TEXT_52')}</Text>
              <Text style={styles.subText}>
                {establishment.tag} (
                {round(
                  establishment.lots.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.surface,
                    0
                  ),
                  2
                ) || 0}{' '}
                {establishment.areaUnit})
              </Text>
              <View style={styles.marginTop}>
                <Text style={styles.title}>
                  {t('VIEWS.ACTIVITIES.TEXT_53')}
                </Text>
                <Text style={styles.subText}>
                  {getTextLots(establishment.lots).join(', ')}
                </Text>
              </View>
            </View>
          )
        })}
      </View>
      <View style={styles.contentButton}>
        <ButtonPrimary
          text={statuses[status]}
          justifyContent={'flex-start'}
          fontSize={14}
          onPress={() => onPress(activity, status)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    marginHorizontal: 5,
  },
  title: {
    color: blackMediumEmphasis,
    fontSize: 10,
    fontWeight: 'bold',
  },
  subText: {
    color: blackHighEmphasis,
  },
  marginTop: {
    marginTop: 10,
  },
  contentButton: {
    paddingVertical: 5,
  },
})

ListCardEstablishment.propTypes = {
  establishments: PropTypes.array,
  status: PropTypes.string,
}

export default ListCardEstablishment
