/**
 * @param {Array.<object>} activityTypes
 * @param {string} activityTypes.value
 * @param {string} activityTypes.label
 * @param {string} activityTypes.tag
 */
export const activityTypesNotification = (activityTypes) => {
  return activityTypes
    .map((activityType) => ({
      _id: activityType.value,
      name: activityType.label,
      key: activityType.tag,
    }))
    .sort((activityTypeA, activityTypeB) =>
      activityTypeA.name.localeCompare(activityTypeB.name)
    )
}
