import PropTypes from 'prop-types'
import React from 'react'
import { View, Text, StyleSheet, Image, Platform } from 'react-native'
import {
  grayInput,
  blackHighEmphasis,
  blackMediumEmphasis,
  black,
} from '@styles/palette'
import { CommonIcon } from '@modules/common/components'

const ItemDownload = ({
  title,
  subtitle,
  description,
  onPress,
  icon,
  iconStyle = {},
  iconProps = {
    size: 14,
    color: black,
  },
  Icon,
  titleStyle = {},
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {!!icon &&
          (typeof icon === 'object' ||
          (Platform.OS === 'web' && icon?.includes('/static')) ||
          typeof icon === 'number' ? (
            <Image
              source={icon}
              style={[
                { width: iconProps.size, height: iconProps.size },
                iconStyle,
              ]}
            />
          ) : (
            <Icon />
          ))}
        <View style={styles.containerText}>
          <Text style={[styles.title, titleStyle]}>{title}</Text>
          {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
          {description && <Text style={styles.subtitle}>{description}</Text>}
        </View>
      </View>
      <CommonIcon
        name={'FILE-DOWNLOAD'}
        size={20}
        color={grayInput}
        onPress={onPress}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 5,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  subtitle: {
    fontSize: 14,
    color: blackMediumEmphasis,
  },
  containerText: { flex: 1 },
})

ItemDownload.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  onPress: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  iconStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  iconProps: PropTypes.shape({
    size: PropTypes.number,
    color: PropTypes.string,
  }),
  titleStyle: PropTypes.object,
}

export default ItemDownload
