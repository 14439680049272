import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import {
  amber50,
  amber900,
  lime50,
  lime900,
  primary50,
  primary700,
  secondary50,
  secondary900,
} from '@styles/palette'
import { LanguageContext } from '../../contextState/language'

const TextWithOverlap = ({ percent, styleOverlapping }) => {
  const { t } = React.useContext(LanguageContext)
  const colorPercent = () => {
    if (percent > 80) {
      return {
        content: { backgroundColor: secondary50 },
        text: { color: secondary900 },
      }
    } else if (percent <= 80 && percent > 41) {
      return {
        content: { backgroundColor: amber50 },
        text: { color: amber900 },
      }
    } else if (percent <= 40 && percent > 0) {
      return { content: { backgroundColor: lime50 }, text: { color: lime900 } }
    } else {
      return {
        content: { backgroundColor: primary50 },
        text: { color: primary700 },
      }
    }
  }

  const colorStyle = ({ backGroundColor, textColor }) => {
    return {
      content: {
        backgroundColor: backGroundColor,
      },
      text: {
        color: textColor,
      },
    }
  }

  const resultStyles = styleOverlapping
    ? colorStyle(styleOverlapping)
    : colorPercent()
  return (
    <View style={[styles.content, resultStyles.content]}>
      <Text
        style={[styles.textWithBackground, resultStyles.text]}
      >{`${percent}% ${t('COMPONENTS').TEXT.TEXT_WITH_OVERLAP.TEXT_1}`}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    padding: 2,
    width: 110,
    height: 16,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWithBackground: {
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 16,
  },
})

TextWithOverlap.propTypes = {
  percent: PropTypes.number.isRequired,
}

export default TextWithOverlap
