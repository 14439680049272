import DESCRIPTION from '../../components/Description/translate/en'
import ERROR_MESSAGE from '../../components/DeleteModal/translate/en'

export default {
  TEXT_1: 'Crops',
  TEXT_2: 'Season',
  TEXT_3: 'Digital map',
  TEXT_4: 'Delete lot',
  TEXT_5: 'No crop',
  TEXT_6: 'No Season',
  TO_DATE: 'to',
  COMPONENTS: {
    DESCRIPTION,
    ERROR_MESSAGE,
  },
}
