import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, KeyboardAvoidingView } from 'react-native'
import { FormikProvider, useFormik } from 'formik'

import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { Button, FormEditFarmName } from './components'
import { farmEditNameValidationSchema } from './utils'
import { ActivityIndicator } from '@modules/common/components'
import { primary500 } from '@styles/palette'

const FarmEditName = ({ navigation, route }) => {
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const { config } = useContext(AuthContext)
  const { params } = route
  const farmUuid = params?.farmUuid
  const { identifier } = config.companySelected

  const [formikInitialValues] = useState({
    farmName: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: farmEditNameValidationSchema(t),
    enableReinitialize: false,
    initialTouched: {
      farmName: true,
    },
    onSubmit: async (values) => await handleSubmit(values),
  })

  const { isValid, setFieldError, setFieldValue, isSubmitting } = formik

  useEffect(() => {
    fetchFarm()
  }, [])

  const fetchFarm = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await doRequest({
        method: 'GET',
        url: `/farms/${farmUuid}/companies/${identifier}`,
        displayAlert: false,
      })

      const { data: farm } = response

      setFieldValue('farmName', farm.name)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsLoading(false)
    }
  }, [farmUuid])

  const handleSubmit = async (values) => {
    try {
      const url = `/farms/${farmUuid}/name`
      const data = {
        name: values.farmName,
        identifier,
      }

      await doRequest({
        method: 'PUT',
        url,
        data,
        displayAlert: false,
      })
      navigation.goBack()
    } catch (err) {
      setFieldError('farmName', err.message)
    }
  }

  if (isLoading) return <ActivityIndicator size='large' color={primary500} />

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior='height'
      keyboardVerticalOffset={100}
    >
      <View style={styles.containerInput}>
        <FormikProvider value={formik}>
          <FormEditFarmName formik={formik} />
        </FormikProvider>
      </View>

      <Button
        title={t('VIEWS').FARM_EDIT_NAME.TEXT_1}
        onPress={formik.submitForm}
        styles={styles.button}
        disabled={!isValid}
        isLoading={isSubmitting}
      />
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerInput: {
    flex: 1,
    paddingTop: 54,
    marginHorizontal: 16,
  },
  button: {
    marginHorizontal: 23,
    marginBottom: 10,
  },
})

FarmEditName.prototype = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default FarmEditName
