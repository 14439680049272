import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'

export const SubmitButton = ({
  isSubmitButtonAvailable,
  onPressSubmitButton,
  isSubmitButtonLoading,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      disabled={!isSubmitButtonAvailable}
      onPress={onPressSubmitButton}
      styles={[
        styles.button,
        !isSubmitButtonAvailable ? styles.buttonDisabled : {},
      ]}
      labelStyle={[
        styles.textButton,
        !isSubmitButtonAvailable ? styles.textButtonDisabled : {},
      ]}
      isLoading={isSubmitButtonLoading}
    >
      {t('VIEWS.COLLABORATOR_CREATE.TEXT_2')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    margin: 16,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
})

SubmitButton.propTypes = {
  isSubmitButtonAvailable: PropTypes.bool.isRequired,
  onPressSubmitButton: PropTypes.func.isRequired,
  isSubmitButtonLoading: PropTypes.bool.isRequired,
}
