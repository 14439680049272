export default {
  TEXT_1: '¿Deseas salir de UCROP.IT?',
  TEXT_2: 'Realizando esta acción eliminaras la cola de sincronización',
  TEXT_3: 'Sin empresa asignada ',
  TEXT_4: 'Alta de Empresa',
  TEXT_5: 'EDITAR PERFIL',
  TEXT_6: 'Empresa',
  TEXT_7: 'Integraciones',
  TEXT_8: 'Ajustes',
  TEXT_9: 'Cerrar sesión',
  TEXT_10: 'Versión %{version} Build %{build}',
  TEXT_11: 'Perfil',
  TEXT_12: 'Idioma',
  TEXT_13: 'Detalle empresa',
  TEXT_14: 'Sincronizar offline',
  TEXT_15: 'Conectar aplicaciones',
  TEXT_16: 'Asesorar empresa',
  SETTINGS: 'Configuración',
  PRODUCTION_BOARD: 'Tablero de producción',
  ADD_COMPANY: 'Agregar empresa',
}
