import React, { useContext } from 'react'

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { MainStackNavigator } from './StackNavigator'
import CustomTabBar from './CustomTabBar'
import { canSeeFarms } from '../utils'
import { AuthContext } from '@contextState/auth'

const Tab = createBottomTabNavigator()

const getCurrentRouteName = ({ currentRoute }) => {
  const routesLength = currentRoute.state?.routes?.length - 1
  const lastRoute = currentRoute.state?.routes[routesLength]
  const routeName = lastRoute
    ? lastRoute?.name
    : !currentRoute.state || currentRoute.state?.routes?.length === 1
    ? currentRoute.name
    : ''
  return routeName
}

const getIsTabBarVisible = ({ currentRouteName }) => {
  switch (currentRouteName) {
    case 'FarmList':
      return true
    case 'CropList':
      return true
    case 'LicenseList':
      return true
    default:
      return false
  }
}

const BottomTabNavigator = () => {
  const { config, isPendingAuthorization } = useContext(AuthContext)
  const { roleSelected, companySelected } = config

  const hasCompany = Boolean(companySelected)

  const initialRoute =
    canSeeFarms({ roleSelected }) && !isPendingAuthorization
      ? 'FarmList'
      : 'CropList'

  return (
    <Tab.Navigator
      tabBar={(props) => {
        let currentRoute = {}

        props.state.routes.forEach((route, index) => {
          if (props.state.index === index) {
            currentRoute = route
          }
        })
        //Validate if a main route exists - Deeplink workaround to back button redirecting to home
        if (
          props.state?.routes[1]?.name === 'CropList' &&
          props.state?.routes[1]?.state &&
          props.state?.routes[1]?.state?.routes[0]?.name !== 'CropList'
        ) {
          props.state.routes[1].state.routes.unshift({
            name: 'CropList',
          })
        }
        const currentRouteName = getCurrentRouteName({ currentRoute })
        return getIsTabBarVisible({
          currentRouteName,
        }) ? (
          <CustomTabBar
            {...props}
            isPendingAuthorization={isPendingAuthorization}
            hasCompany={hasCompany}
            roleSelected={roleSelected}
            currentRouteName={currentRouteName}
            companySelected={companySelected}
          />
        ) : null
      }}
      initialRouteName={initialRoute}
    >
      <Tab.Screen name='FarmList' component={MainStackNavigator} />
      <Tab.Screen name='CropList' options={{ unmountOnBlur: true }}>
        {(props) => (
          <MainStackNavigator {...props} initialRouteName='CropList' />
        )}
      </Tab.Screen>
      <Tab.Screen name='LicenseList' options={{ unmountOnBlur: true }}>
        {(props) => (
          <MainStackNavigator {...props} initialRouteName='LicenseList' />
        )}
      </Tab.Screen>
    </Tab.Navigator>
  )
}

export default BottomTabNavigator
