export default {
  TEXT_1: 'New crop',
  TEXT_2:
    'From the company responsible for production, whether physical or legal.',
  TEXT_3: 'Next',
  TEXT_4: 'Tax Id has already been registered, request permissions',
  TEXT_5: 'The planting date must be later than or equal to the current date',
  TEXT_6: 'The planting date should not be later than the harvest date',
  TEXT_7: 'The harvest date must be after the planting date',
  TEXT_8: 'Required field',
  TEXT_9: 'You can edit the crop name later if needed.',
  FIELD_1: {
    NAME: 'Type of crop',
    OPTION_1: 'Soy',
    OPTION_2: 'Sunflower',
    OPTION_3: 'Corn',
    OPTION_4: 'Cotton',
    OPTION_5: 'Wheat',
    OPTION_6: 'Carinata',
  },
  FIELD_2: {
    NAME: 'Estimated planting date',
  },
  FIELD_3: {
    NAME: 'Estimated harvest date',
  },
  FIELD_4: {
    NAME: 'Yield unit',
    OPTION_1: 'Kg/Ha',
    OPTION_2: 'Ton/Ha',
    OPTION_3: 'Bags/Ha',
    OPTION_4: 'Bales/Ha',
    OPTION_5: 'Quintales(1qq = 100kgs)/Ha',
  },
  FIELD_5: {
    NAME: 'Estimated yield',
  },
  FIELD_6: {
    NAME: 'Tax Id',
  },
  FIELD_7: {
    NAME: 'Crop name',
  },
  FIELD_8: {
    NAME: 'Country',
  },
  MODAL_1: {
    TEXT_1: 'Do you want to resume your previous crop?',
    TEXT_2:
      'You have an unfinished crop. Do you want to continue it or start a new one?',
    TEXT_3: 'Restore',
    TEXT_4: 'Start a new one',
  },
  STAGE_FIELD: {
    NAME: 'Crop timing',
    FIRST: 'First crop',
    SECOND: 'Secondary crop',
  },
}
