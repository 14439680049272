import React, { useState, useContext, useEffect } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  TouchableHighlight,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import CachedImage from '@components/common/CachedImage'
import { formatSurface, truncateSurface } from '@common/utils'

const EditLotsSurface = ({
  id,
  name,
  cityName,
  provinceName,
  imageUrl,
  surface,
  surfacePlanned,
  surfaceRealized,
  onPressAdd,
  onPressSubtract,
  editSurfaceLot,
  readOnly = false,
  hideSurfacePlanned,
  areaUnit,
}) => {
  const { t } = useContext(LanguageContext)

  const [editSurfaces, onChangeEditSurface] = useState(surfaceRealized)

  useEffect(() => {
    editSurfaceInput(id, editSurfaces)
  }, [editSurfaces])

  useEffect(() => {
    editSurface(surfaceRealized)
  }, [surfaceRealized])

  const editSurface = (surfaceRealized) => {
    onChangeEditSurface(surfaceRealized)
  }

  const editSurfaceInput = (id, editSurfaces) => {
    editSurfaceLot(id, truncateSurface(Number(editSurfaces)))
  }

  const limitDecimal = (value) => {
    value = value.replace(',', '.')
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      onChangeEditSurface(value)
    }
  }

  return (
    <>
      <View key={id}>
        <View style={styles.locationContainer}>
          <CommonIcon name={'CHECK'} size={24} color={'#4CAF50'} />

          <View style={styles.locationTextContainer}>
            <Text style={styles.descriptionTitle}>{name}</Text>
            {(Boolean(cityName) || Boolean(provinceName)) && (
              <Text style={styles.descriptionSubTitle}>{`${cityName}${
                cityName && provinceName ? ', ' : ''
              }${provinceName}`}</Text>
            )}
          </View>

          <View style={styles.imageContainer}>
            {Platform.OS === 'web' ? (
              <Image
                source={{
                  uri: imageUrl,
                }}
                style={styles.image}
              />
            ) : imageUrl ? (
              <CachedImage
                source={{
                  uri: imageUrl,
                }}
                style={styles.image}
              />
            ) : (
              false
            )}
          </View>
        </View>

        <View
          style={[styles.contentDescription, styles.contentDescriptionSurface]}
        >
          <View style={styles.contentDescriptionTitle}>
            <View>
              <Text style={styles.descriptionTitleSurface}>
                {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_2}
              </Text>

              {!hideSurfacePlanned && (
                <Text style={styles.descriptionTitleSurface}>
                  {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_3}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.contentDescriptionSubTitle}>
            <View>
              <Text style={styles.descriptionTitleSurface}>
                {formatSurface(surface, areaUnit)}
              </Text>

              {!hideSurfacePlanned && (
                <Text style={styles.descriptionTitleSurface}>
                  {formatSurface(surfacePlanned, areaUnit)}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={styles.contentSurfaceRealize}>
          <View>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_4}
            </Text>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_5}
            </Text>
          </View>
          <View style={styles.contentDescriptionImage}>
            {!readOnly && (
              <TouchableHighlight
                onPress={() => onPressSubtract(id, editSurfaces)}
              >
                <View style={styles.button}>
                  <Text>
                    <CommonIcon name={'MINUS'} size={24} color={'#333333'} />
                  </Text>
                </View>
              </TouchableHighlight>
            )}

            <TextInput
              style={styles.input}
              onChangeText={(value) => limitDecimal(value)}
              value={editSurfaces.toString()}
              keyboardType='numeric'
              editable={!readOnly}
            />

            {!readOnly && (
              <TouchableHighlight onPress={() => onPressAdd(id, editSurfaces)}>
                <View style={styles.button}>
                  <Text>
                    <CommonIcon name={'PLUS'} size={24} color={'#333333'} />
                  </Text>
                </View>
              </TouchableHighlight>
            )}
          </View>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  locationContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    width: '100%',
  },
  locationTextContainer: {
    flex: 1,
  },
  imageContainer: {
    height: 56,
    width: 100,
    marginLeft: 15,
  },
  image: {
    height: 56,
    width: 100,
  },
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
  },
  contentDescription: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    marginTop: 10,
  },
  contentDescriptionSurface: {
    marginLeft: 20,
  },
  contentDescriptionTitle: {
    flexDirection: 'row',
  },
  descriptionContainer: {
    flex: 1,
    marginBottom: 15,
  },
  descriptionTitle: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 10,
  },
  descriptionSubTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
  },
  contentDescriptionImage: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  contentDescriptionSubTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  descriptionTitleSurface: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
    marginBottom: 10,
  },
  contentSurfaceRealize: {
    flexDirection: 'row',
    marginLeft: 20,
    marginBottom: 10,
  },
  surfaceRealizeTitle: {
    fontSize: 16,
    fontWeight: '400',
  },
  input: {
    width: 105,
    padding: 8,
    backgroundColor: '#EEEEEE',
    fontSize: 16,
    fontWeight: '700',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    padding: 10,
    marginRight: 4,
    marginLeft: 4,
  },
  icon: {
    marginRight: 16,
  },
})

EditLotsSurface.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  provinceName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  surface: PropTypes.number.isRequired,
  surfacePlanned: PropTypes.number,
  surfaceRealized: PropTypes.number.isRequired,
  onPressAdd: PropTypes.func.isRequired,
  onPressSubtract: PropTypes.func.isRequired,
  editSurfaceLot: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  hideSurfacePlanned: PropTypes.bool,
  areaUnit: PropTypes.string,
}

export default EditLotsSurface
