import FARM_FORM from '../../components/FarmForm/translate/pt'
import LOST_ADD_BUTTON from '../../components/LotsAddButton/translate/pt'
import SAVE_DRAFT_BUTTON from '../../components/SaveDraftButton/translate/pt'
import UPLOAD_AREA from '../../components/UploadArea/translate/pt'

export default {
  TEXT_1: 'Tente carregar outro arquivo',
  TEXT_2: 'Fechar',
  TEXT_3: 'Superfície não pode ser 0',
  COMPONENTS: {
    FARM_FORM,
    LOST_ADD_BUTTON,
    SAVE_DRAFT_BUTTON,
    UPLOAD_AREA,
  },
}
