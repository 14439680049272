import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'

import { CommonIcon } from '@modules/common/components'
import InputSelectDropdown from '@components/inputs/InputSelectDropdown'
import InputText from '@components/inputs/InputText'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { ConnectionContext } from '@contextState/connection'

import { identifierName } from '@utils/countries'

import {
  white,
  primary500,
  blackHighEmphasis,
  gray12,
  blackDisabled,
} from '@styles/palette'

const FormCollaborator = ({
  formik,
  countriesData,
  allCountryData,
  userTypeOptions,
  isAdviser,
  nextButton,
  isNewCompany,
  companySelected,
}) => {
  const {
    setFieldValue,
    values,
    setFieldTouched,
    touched,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
    dirty,
  } = formik

  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const [isSaveButtonDisabled, setIsSaveInButtonDisabled] = useState(false)
  useEffect(() => {
    setIsSaveInButtonDisabled(
      !dirty || !isValid || !isEmpty(errors) || !isConnected || isSubmitting
    )
  }, [isValid, dirty, errors, isSubmitting, isConnected])

  return (
    <View style={styles.container}>
      <View>
        <InputSelectDropdown
          id='country'
          options={countriesData}
          label={t(
            `VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.COUNTRY.PLACEHOLDER`
          )}
          placeholder={t(
            `VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.COUNTRY.LABEL`
          )}
          value={values.country}
          rightIcon={<CommonIcon name={'CHEVRON-DOWN'} size={20} />}
          onChangeText={(value) => {
            setFieldValue('country', value)
          }}
          onBlur={() => setTimeout(() => setFieldTouched('country'))}
          editable={!isNewCompany && !isAdviser}
          disabled={isNewCompany && isAdviser}
          touched={touched.country}
          error={errors.country?.value}
        />
        <InputText
          id='identifier'
          value={values.identifier}
          label={identifierName(
            allCountryData(companySelected?.country?._id)?.alpha3Code,
            t
          )}
          placeholder={identifierName(
            allCountryData(companySelected?.country?._id)?.alpha3Code,
            t
          )}
          touched={touched.identifier}
          error={errors.identifier}
          onChangeText={(value) => {
            setFieldValue('identifier', value)
          }}
          onBlur={() => setFieldTouched('identifier')}
          editable={!isNewCompany && !isAdviser}
          disabled={isNewCompany && isAdviser}
        />

        <InputText
          id='email'
          containerStyle={styles.containerInput}
          onChangeText={(value) => setFieldValue('email', value)}
          value={values.email}
          placeholder={`${t(
            'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.EMAIL.PLACEHOLDER'
          )}`}
          label={`${t(
            'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.EMAIL.LABEL'
          )}`}
          onBlur={() => setFieldTouched('email')}
          touched={touched.email}
          error={errors.email}
          autocomplete='off'
        />

        <InputText
          id='confirmEmail'
          containerStyle={styles.containerInput}
          onChangeText={(value) => setFieldValue('confirmEmail', value)}
          value={values.confirmEmail}
          placeholder={`${t(
            'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.CONFIRM_EMAIL.PLACEHOLDER'
          )}`}
          label={`${t(
            'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.CONFIRM_EMAIL.LABEL'
          )}`}
          onBlur={() => setFieldTouched('confirmEmail')}
          touched={touched.confirmEmail}
          error={errors.confirmEmail}
          autocomplete='off'
        />

        {!isAdviser && (
          <InputSelectDropdown
            id='userType'
            containerStyle={styles.containerInput}
            rightIcon={<CommonIcon name={'CHEVRON-DOWN'} size={20} />}
            onChangeText={(value) => {
              setFieldValue('userType', value)
            }}
            onBlur={() => setTimeout(() => setFieldTouched('userType'))}
            value={values.userType}
            placeholder={`${t(
              'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.COLLABORATOR_TYPE.PLACEHOLDER'
            )}`}
            label={`${t(
              'VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.COLLABORATOR_TYPE.LABEL'
            )}`}
            options={userTypeOptions}
            touched={touched.userType}
            error={errors.userType?.value}
          />
        )}
      </View>
      <ButtonPrimary
        text={t(
          nextButton
            ? `VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.NEXT`
            : `VIEWS.ADD_COLLABORATOR.COMPONENTS.FORM_COLLABORATOR.SAVE`
        ).toUpperCase()}
        fontSize={14}
        backgroundColor={!isSaveButtonDisabled ? primary500 : gray12}
        colorText={!isSaveButtonDisabled ? white : blackDisabled}
        borderRadius={8}
        disabled={isSaveButtonDisabled}
        onPress={handleSubmit}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
})

FormCollaborator.propTypes = {
  formik: PropTypes.object.isRequired,
  countriesData: PropTypes.array.isRequired,
  allCountryData: PropTypes.func.isRequired,
  userTypeOptions: PropTypes.array.isRequired,
}

export default FormCollaborator
