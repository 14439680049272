export const parseDataToValidateIsDirectAchievement = (values, userId, tag) => {
  const dataToSend = {
    draftId: values.draftId,
    cropId: values?.cropId ?? values.crop,
    name: values.name,
    type: values.type,
    status: values.status,
    lots: values.lots?.value?.map((element) => element._id) ?? values.lots,
    lotsWithSurface:
      values.lots?.value?.map((element) => ({
        lot: element._id,
        surfacePlanned: element.surface,
        tag: element.tag,
      })) ?? values.lotsWithSurface,
    surface: values.surface,
    dateHarvest: values.dateHarvest?.value ?? values.dateHarvest,
    dateObservation: values.dateObservation?.value ?? values.dateObservation,
    dateEstimatedHarvest:
      values.dateEstimatedHarvest?.value ?? values.dateEstimatedHarvest,
    unitType: values.unitType?.value ?? values.unitType,
    typeAgreement: values.typeAgreement?.value ?? values.typeAgreement,
    verificationType: values.verificationType?.value ?? values.verificationType,
    pay: values.pay,
    volume: values?.volume,
    unitVolume: values?.unitVolume,
    observation: values.observation,
    observations: values.observations,
    storages: values.storages,
    evidences: values.evidences.map((element) => ({
      ...element,
      user: element.user?._id,
    })),
    signers: values.signers.map((element) => {
      if (element.userId !== userId) {
        element.signed = false
        element.dateToSign = new Date()
      }
      return element
    }),
    verificationFile: values.verificationFile
      ? {
          name: values.verificationFile.originalName,
          uploadedBy: values.verificationFile.uploadedBy._id,
          uploadedAt: values.verificationFile.uploadedAt,
        }
      : undefined,
    isDirectAchievement: true,
    tag,
    farmId: values?.farmId,
  }
  if (values?.seedDryContent) {
    dataToSend.seedDryContent = values?.seedDryContent
  }

  return dataToSend
}
