import * as yup from 'yup'

export const getFormikValidationSchema = (t) =>
  yup.object().shape({
    farmName: yup
      .string()
      .trim()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .max(
        50,
        t('VIEWS.FARM_CREATE.COMPONENTS.FARM_FORM.ERRORS.TEXT_2', {
          quantity: 50,
        })
      )
      .required(t('VIEWS.FARM_CREATE.COMPONENTS.FARM_FORM.ERRORS.TEXT_1')),
  })
