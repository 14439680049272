import React, { useContext, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { useLinkTo } from '@react-navigation/native'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import {
  primary500,
  white,
  blackMediumEmphasis,
  blackHighEmphasis,
} from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { MODAL_ERRORS } from '@utils/constants'
import { CommonContext } from '@contextState/common'
import { ModalDefault } from '@common/components'

const ModalErrorsDeepLinks = ({ route }) => {
  const linkTo = useLinkTo()
  const ERROR_DEFAULT = MODAL_ERRORS.ERROR_UNEXPECTED
  const { t } = useContext(LanguageContext)
  const { clearErrorDeepLink, errorDeepLink } = useContext(CommonContext)
  const { error } = route.params
  const [isModalVisible, setIsModalVisible] = useState(true)
  const errors = {
    UNAUTHORIZED: {
      title: t('COMMON.MODAL_ERRORS_DEEP_LINKS.UNAUTHORIZED.TEXT_1'),
      description: t('COMMON.MODAL_ERRORS_DEEP_LINKS.UNAUTHORIZED.TEXT_2'),
    },
    NOT_AVAILABLE: {
      title: t('COMMON.MODAL_ERRORS_DEEP_LINKS.NOT_AVAILABLE.TEXT_1'),
      description: t('COMMON.MODAL_ERRORS_DEEP_LINKS.NOT_AVAILABLE.TEXT_2'),
    },
    ERROR_UNEXPECTED: {
      title: t('COMMON.MODAL_ERRORS_DEEP_LINKS.ERROR_UNEXPECTED.TEXT_1'),
      description: t('COMMON.MODAL_ERRORS_DEEP_LINKS.ERROR_UNEXPECTED.TEXT_2'),
    },
    EXIST_VERIFICATION_DRAFT: {
      title: t(
        'COMMON.MODAL_ERRORS_DEEP_LINKS.EXIST_VERIFICATION_DRAFT.TEXT_1'
      ),
      description: t(
        'COMMON.MODAL_ERRORS_DEEP_LINKS.EXIST_VERIFICATION_DRAFT.TEXT_2'
      ),
    },
    NO_LOTS_AVAILABLE: {
      title: t('COMMON.MODAL_ERRORS_DEEP_LINKS.NO_LOTS_AVAILABLE.TEXT_1'),
      description: t('COMMON.MODAL_ERRORS_DEEP_LINKS.NO_LOTS_AVAILABLE.TEXT_2'),
    },
  }

  const goHome = () => {
    setIsModalVisible(false)
    if (errorDeepLink) {
      clearErrorDeepLink()
    }
    linkTo('/crops/list')
  }

  return (
    <View style={styles.content}>
      <ModalDefault
        isModalVisible={isModalVisible}
        style={styles.styleModal}
        hasIconCloseModal={false}
      >
        <Text style={styles.title}>
          {errors[error || ERROR_DEFAULT]?.title}
        </Text>
        <Text style={styles.description}>
          {errors[error || ERROR_DEFAULT]?.description}
        </Text>
        <View style={styles.contentButton}>
          <ButtonPrimary
            text={t('COMMON.MODAL_ERRORS_DEEP_LINKS.TEXT_1')}
            backgroundColor={primary500}
            width={80}
            height={48}
            colorText={white}
            onPress={goHome}
          />
        </View>
      </ModalDefault>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: blackHighEmphasis,
    opacity: 0.5,
  },
  styleModal: {
    backgroundColor: white,
    padding: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
  },
  description: {
    color: blackMediumEmphasis,
    fontSize: 16,
    marginVertical: 40,
  },
  contentButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})

ModalErrorsDeepLinks.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
}

export default ModalErrorsDeepLinks
