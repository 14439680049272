import React, { useState, useContext, useEffect, useRef } from 'react'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { uniqueId } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { selectFileBinaryAction } from '@store/actions'

import { AuthContext } from '@contextState/auth'
import { LanguageContext } from '@contextState/language'
import useNetwork from '@utils/network'
import { white } from '@styles/palette'
import {
  WarningMessage,
  LotsList,
  KmzAddButton,
  CreateFarmButton,
  ModalSuccessChildren,
  ErrorMessage,
} from './components'
import ModalSuccess from '@components/common/v1/ModalSuccess'
import useModal from '@hooks/useModal'
import ModalError from '@components/common/v1/ModalError'
import { CompanyDetails } from '@modules/common/components'
import useActionSheetCustom from '@hooks/useActionSheetCustom'
import BackDropErrorContain from './components/BackDropErrorContain'

const FarmLotsAdd = ({ navigation, route }) => {
  const {
    farmName,
    farmUuid,
    onReturn,
    showSuccess = true,
    removeFarmFile,
    statusFarm,
  } = useRef(route.params).current
  const { t } = useContext(LanguageContext)
  const { config, user } = useContext(AuthContext)
  const { name: companyName, identifier, _id } = config.companySelected
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const [warningMessage, setWarningMessage] = useState()
  const [error, setError] = useState('')
  const [kmzLots, setKmzLots] = useState([])
  const [isCreateFarmButtonAvailable, setIsCreateFarmButtonAvailable] =
    useState(false)
  const [isCreateFarmButtonLoading, setIsCreateFarmButtonLoading] =
    useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const [errorDisabledLot, setErrorDisabledLot] = useState(null)
  const { doRequest } = useNetwork()
  const selectFileBinary = useSelector((state) => state.selectFileBinary)
  const dispatch = useDispatch()
  const { ActionSheetComponent, openActionSheet } = useActionSheetCustom({
    t,
    title: errorDisabledLot?.title,
  })
  /**
   * LISTEN IF AT LEAST ONE FIELD IS SELECTED
   */
  useEffect(() => {
    if (
      kmzLots.find((element) =>
        element.lots.find((subElement) => subElement.selected)
      )
    ) {
      setIsCreateFarmButtonAvailable(true)
    } else {
      setIsCreateFarmButtonAvailable(false)
    }
  }, [kmzLots])

  /**
   * DISPLAY/UNDISPLAY ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const displayKmzToggle = async (kmz) => {
    const newKmzLots = kmzLots.map((element) => {
      if (element._id !== kmz._id) {
        return element
      }

      return {
        ...element,
        display: !element.display,
      }
    })

    setKmzLots(newKmzLots)
  }

  /**
   * SELECT/UNSELECT ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const selectKmzToggle = async (kmz) => {
    if (kmz.isAllDisabled) {
      return
    }

    const newKmzLots = kmzLots.map((element) => {
      if (element._id !== kmz._id) {
        return element
      }

      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disable
            ? !element.selected
            : lotElement.selected,
        }
      })

      return {
        ...element,
        selected: !element.selected,
        lots: newLots,
      }
    })

    setKmzLots(newKmzLots)
  }

  /**
   * SELECT/UNSELECT LOT
   *
   * @param {*} establishment
   * @param {*} lot
   */
  const selectLotToggle = async (kmz, lot) => {
    if (lot.disable) {
      return
    }

    const newKmzLots = kmzLots.map((element) => {
      if (element._id !== kmz._id) {
        return element
      }

      const allLotSelected = []

      const newLots = element.lots.map((lotElement) => {
        if (lotElement._id !== lot._id) {
          if (lotElement.selected) {
            allLotSelected.push(lotElement)
          }

          return lotElement
        }

        if (!lotElement.selected) {
          allLotSelected.push(lotElement)
        }

        return {
          ...lotElement,
          selected: !lotElement.selected,
        }
      })

      return {
        ...element,
        selected:
          allLotSelected.length ===
          newLots.filter((lot) => !lot.disable).length,
        lots: newLots,
      }
    })

    setKmzLots(newKmzLots)
  }

  /**
   * REMOVE KMZ
   *
   * @param {*} indexSelected
   */
  const removeKmz = (position) => {
    const newKmzLots = [...kmzLots]

    newKmzLots.splice(position, 1)

    setKmzLots(newKmzLots)

    if (!newKmzLots.length) {
      navigation.goBack()
    }
    setWarningMessage('')
  }

  /**
   * ON PRESS KMZ ADD
   */
  const onPressKmzAddButton = async (file) => {
    const formData = new FormData()

    formData.append('files', file)
    if (selectFileBinary && Object.entries(selectFileBinary).length) {
      formData.append('filePerimeter', selectFileBinary)
    }

    const params = {
      identifier,
      farmUuid,
    }

    try {
      const response = await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `farms/fields/surfaces/companies`,
        data: formData,
        params,
        displayAlert: false,
        version: 'v2',
      })

      const kmz = {
        _id: uniqueId('KMZ_'),
        kmzName: file.path ?? file.name,
        display: true,
        isAllDisabled: response.data?.isAllDisabled,
        selected: !response.data?.isAllDisabled,
        lots: response.data.lots.map((lot) => {
          return {
            ...lot,
            _id: uniqueId('LOT_'),
            selected: !lot?.disable,
          }
        }),
        file,
      }

      if (!kmz?.lots?.filter((lot) => !lot.disable).length) {
        kmz.disabled = true
        kmz.isAllDisabled = true
        kmz.selected = false
      }
      setKmzLots([...kmzLots, kmz])

      if (response.data?.error && response.data?.error.length) {
        const errorMessage = response.data.errorMessage.join('\n')
        setWarningMessage(errorMessage)
      }
      setError('')
    } catch (error) {
      const serverError = t('VIEWS.FARM_LOTS_ADD.TEXT_1')

      const errorMessage = Object.keys(error?.message).length
        ? error.message.message || error.message
        : serverError

      setError(errorMessage)
    }
  }

  /**
   * ON PRESS CREATE FARM
   */
  const onPressCreateFarmButton = async () => {
    if (isCreateFarmButtonLoading) {
      return
    }

    setIsCreateFarmButtonLoading(true)

    const lotsSelect = kmzLots
      .flatMap((element) => element.lots)
      .filter((element) => element.selected)
      .map((element) => element.signature)
      .join(',')

    const formData = new FormData()

    if (selectFileBinary && Object.entries(selectFileBinary).length) {
      formData.append('farmFile', selectFileBinary)
    } else if (removeFarmFile) {
      formData.append('removeFarmFile', true)
    }

    kmzLots
      .filter((element) =>
        element.lots.find((subElement) => subElement.selected)
      )
      .map((element) => formData.append('lotsFiles', element.file))

    formData.append('name', farmName)
    formData.append('lots', lotsSelect)
    formData.append('userId', user.id)
    formData.append('companyId', _id)
    formData.append('identifier', identifier)

    try {
      if (farmUuid) {
        await doRequest({
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          method: 'PUT',
          url: `farms/${farmUuid}`,
          data: formData,
          displayAlert: false,
        })
      } else {
        await doRequest({
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          method: 'POST',
          url: 'farms',
          data: formData,
          displayAlert: false,
        })
      }

      dispatch(selectFileBinaryAction.clearFileBinary())

      showSuccess ? toggleModal() : onGoFarmList()
    } catch (error) {
      const serverError = t('VIEWS.FARM_LOTS_ADD.TEXT_2')

      const errorMessage =
        error?.message && Object.keys(error?.message).length
          ? error.message.message || error.message
          : serverError

      setError(errorMessage)
    }

    setIsCreateFarmButtonLoading(false)
  }

  /**
   * ON GO TO FARM LIST
   */
  const onGoFarmList = (newFarm) => {
    closeModal()

    if (!onReturn || newFarm) {
      return navigation.navigate('FarmList', {
        newFarm,
      })
    }

    onReturn()
  }

  const closeError = () => {
    setError('')
  }

  const closeWarning = () => {
    setVisibleError(false)
  }

  const errorParser = (lot) => {
    const lotWithError = {
      description: lot.description,
      image: lot.imageError,
      title: t('VIEWS').FARM_LOTS_ADD.TEXT_4,
      typeError: lot.title,
    }

    return lotWithError
  }

  const displayErrorDisabled = (lot) => {
    const { lotWithError, imageError } = lot
    if (imageError) {
      if (lotWithError) {
        setErrorDisabledLot(lotWithError)
      } else {
        setErrorDisabledLot(errorParser(lot))
      }
      openActionSheet()
    }
  }

  const afterClosing = () => {
    setErrorDisabledLot(null)
  }

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />

      <WarningMessage message={warningMessage} />

      <LotsList
        farmName={farmName}
        kmzLots={kmzLots}
        displayKmzToggle={displayKmzToggle}
        selectKmzToggle={selectKmzToggle}
        selectLotToggle={selectLotToggle}
        removeKmz={removeKmz}
        displayErrorDisabled={displayErrorDisabled}
      />

      <KmzAddButton onPress={onPressKmzAddButton} />

      <CreateFarmButton
        isUpdate={Boolean(farmUuid)}
        isButtonAvailable={isCreateFarmButtonAvailable}
        isButtonLoading={isCreateFarmButtonLoading}
        onPress={onPressCreateFarmButton}
        statusFarm={statusFarm}
      />

      <ModalSuccess
        visible={isModalVisible}
        title={''}
        description={''}
        style={styles.buttonContainer}
      >
        <ModalSuccessChildren onGoFarmList={() => onGoFarmList(true)} />
      </ModalSuccess>

      <ErrorMessage message={error} closeError={closeError} />

      {visibleError && (
        <ModalError
          visible={visibleError}
          title={t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_1}
          description={t('VIEWS').FARM_LOTS_ADD.TEXT_2}
        >
          <TouchableOpacity onPress={closeWarning}>
            <Text style={styles.closeWarning}>
              {t('VIEWS').FARM_LOTS_ADD.TEXT_3}
            </Text>
          </TouchableOpacity>
        </ModalError>
      )}

      {errorDisabledLot && (
        <ActionSheetComponent afterClosing={afterClosing}>
          <View style={{ paddingBottom: 10 }}>
            <BackDropErrorContain error={errorDisabledLot} />
          </View>
        </ActionSheetComponent>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
  buttonContainer: {
    marginTop: '10%',
  },
  closeWarning: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    fontWeight: '700',
    fontStyle: 'normal',
    color: '#FFFFFF',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderRadius: 5,
  },
})

FarmLotsAdd.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default FarmLotsAdd
