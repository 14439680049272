import getDatabase from './getDataBase'
import useOfflineQueue from './queue'
import { activitiesStatus } from '@utils/constants'
import { useContext } from 'react'
import { CommonContext } from '@contextState/common'
import { alterTableAddColumns, cropColumns } from '../utils'
import {
  ITEMS_PER_PAGE,
  INITIAL_PAGE,
} from '@modules/crops/screens/CropList/v1/utils'

const CREATE_TABLE_QUERY =
  'CREATE TABLE IF NOT EXISTS crops (id integer primary key not null, data text, _id text, downloaded integer);'
const SELECT_QUERY =
  'SELECT * FROM crops WHERE downloaded = 1 ORDER BY updated_at DESC, created_at DESC;'
const SELECT_PAGINATED_QUERY =
  'SELECT * FROM crops WHERE downloaded = 1 AND (crop_identifier=? or member_identifier=?) ORDER BY updated_at DESC, created_at DESC limit ? offset ?;'
const DELETE_ALL_QUERY = 'DELETE FROM crops'
const DELETE_ONE_QUERY = 'DELETE FROM crops WHERE _id ='
const COUNT_QUERY = 'SELECT COUNT(id) AS count FROM crops'
const COUNT_QUERY_OFFLINE =
  'SELECT COUNT(id) AS count FROM crops WHERE downloaded = 1'
const INSERT_QUERY =
  'INSERT INTO crops (data, _id, downloaded, created_at, updated_at, crop_identifier, member_identifier) values (?, ?, 1, ?, ?, ?, ?)'

function useOfflineCrops() {
  const db = getDatabase('db.offlinedata')
  const { storeQueueItem } = useOfflineQueue()
  const { activities } = useContext(CommonContext)

  const initOfflineCrop = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }

      db.transaction((tx) => {
        tx.executeSql(
          CREATE_TABLE_QUERY,
          [],
          () => {
            resolve()
          },
          (_, err) => console.warn(err)
        )
      })
    })

    alterTableAddColumns('crops', cropColumns)

    return promise
  }

  async function selectAllCrops() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }

      db.transaction(
        (tx) => {
          tx.executeSql(SELECT_QUERY, [], (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)

            resolve(
              results.map((el) => ({
                ...el,
                ...JSON.parse(el.data),
                data: null,
              }))
            )
          })
        },
        (_, err) => console.warn(err)
      )
    })

    return promise
  }

  async function getAllCrops({
    page = INITIAL_PAGE,
    limit = ITEMS_PER_PAGE,
    identifier,
  }) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }

      const offset = (page - 1) * limit

      db.transaction(
        (tx) => {
          tx.executeSql(
            SELECT_PAGINATED_QUERY,
            [identifier, identifier, limit, offset],
            (_, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              resolve(
                results.map((el) => ({
                  ...el,
                  ...JSON.parse(el.data),
                  data: null,
                }))
              )
            }
          )
        },
        (_, err) => console.warn(err)
      )
    })

    return promise
  }

  const getPagedCropsOffline = async ({
    page = INITIAL_PAGE,
    limit = ITEMS_PER_PAGE,
    identifier,
  }) => {
    const items = await getAllCrops({ page, limit, identifier })
    const totalItems = await getTotalCropsOffline()

    const response = {
      meta: {
        totalItems,
        itemsPerPage: limit,
        totalPages: limit > 0 ? Math.ceil(totalItems / limit) : 0,
        currentPage: page,
      },
      items,
    }

    return response
  }

  function addToCropStatus(idCrop, status, activity) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * from individuals_crops where _id = '${idCrop}'`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            let data = JSON.parse(results[0].data)
            const newStatus = [{ name: activitiesStatus[status] }]

            data = {
              ...data,
              [status]: [
                ...data[status],
                {
                  ...activity,
                  type: activities.find((el) => el.value === activity.type),
                  status: newStatus,
                },
              ],
            }

            data = JSON.stringify(data)

            db.transaction((tx) => {
              tx.executeSql(
                'UPDATE individuals_crops SET data = ? where _id = ?',
                [data, idCrop],
                () => resolve(),
                (_, err) => console.warn(err)
              )
            })
          }
        )
      })
    })

    return promise
  }

  function changeActivityStatusOrder(
    idCrop,
    idActivity,
    prevStatus,
    nextStatus
  ) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * from individuals_crops where _id = '${idCrop}'`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            let data = JSON.parse(results[0].data)

            const tempActivity = data[prevStatus].find(
              (el) => el._id === idActivity
            )

            tempActivity.status = [{ name: activitiesStatus[nextStatus] }]

            data = {
              ...data,
              [prevStatus]: data[prevStatus].filter(
                (el) => el._id !== idActivity
              ),
              [nextStatus]: [...data[nextStatus], tempActivity],
            }

            data = JSON.stringify(data)

            db.transaction((tx) => {
              tx.executeSql(
                'UPDATE individuals_crops SET data = ? where _id = ?',
                [data, idCrop],
                () => resolve(),
                (_, err) => console.warn(err)
              )
            })
          }
        )
      })
    })

    return promise
  }

  function updateStatus(id, downloaded) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve({})
      }
      db.transaction(
        (tx) => {
          tx.executeSql(
            `UPDATE crops SET downloaded = ${Number(
              downloaded
            )}  where _id = '${id}';`,
            [],
            (_, res) => {
              resolve(res)
            },
            (_, err) => console.warn(err)
          )
        },
        (_, err) => console.warn(err)
      )
    })

    return promise
  }

  function syncCrops(data, forceOfflineSync) {
    if (!db) {
      return null
    }

    db.transaction(
      (tx) => {
        tx.executeSql(COUNT_QUERY, [], (_, { rows }) => {
          const results = rows._array ?? Array.from(rows)
          if (data.length !== results[0].count || forceOfflineSync) {
            tx.executeSql(
              DELETE_ALL_QUERY,
              [],
              () => {
                data.forEach((el) => {
                  tx.executeSql(INSERT_QUERY, [
                    JSON.stringify(el),
                    el._id,
                    el?.createdAt ?? null,
                    el?.updatedAt ?? null,
                  ])
                })
              },
              (_, err) => console.warn('ERR TRUNCATE CROPS:', err)
            )
          }
        })
      },
      null,
      () => console.warn('OFFLINE CROPS ARE SYNC')
    )
  }

  async function getTotalCropsOffline() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(0)
      }
      db.transaction((tx) => {
        tx.executeSql(
          COUNT_QUERY_OFFLINE,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            resolve(results[0].count)
          },
          (_, err) => alert(err.message)
        )
      })
    })
    return promise
  }

  async function storeOfflineCollaborator(id, values, params, type) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM individuals_crops where _id = '${id}'`,
          [],
          (_, { rows }) => {
            const parsed = JSON.parse(rows._array[0].data)
            const data = {
              ...parsed,
              members: [...parsed.members, values],
            }

            tx.executeSql(
              `UPDATE individuals_crops SET data = '${JSON.stringify(
                data
                // eslint-disable-next-line no-useless-escape
              ).replace(/[\/\(\)\']/g, '&quot;')}' where _id = '${id}'`,
              [],
              () => resolve(),
              (_, err) => console.warn('ERR ADD COLLABORATORS CROPS:', err)
            )
          },
          (_, err) => console.warn(err)
        )
      })
    })

    await storeQueueItem(values, params, type)

    return promise
  }

  async function findOneCrop(withoutData) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve({})
      }
      db.transaction((tx) => {
        tx.executeSql(
          'SELECT * from crops LIMIT 1;',
          [],
          (_, { rows }) => {
            const result = rows._array ?? Array.from(rows)
            const dataToReturn = result[0]
              ? withoutData
                ? result[0]
                : JSON.parse(result[0].data)
              : null
            resolve(dataToReturn)
          },
          (_, err) => alert(err.message)
        )
      })
    })

    return promise
  }

  const insertCrop = async (crop, selectedCompanyIdentifier) => {
    try {
      const dataToInsert = parseDataToInsert(crop, selectedCompanyIdentifier)

      const [errorInsertCrop] = await insertCropToDB(dataToInsert)

      if (errorInsertCrop) {
        return [errorInsertCrop]
      }

      return []
    } catch (error) {
      console.warn('ERROR Insert Crop')
      console.warn(error)

      return [error]
    }
  }

  const parseDataToInsert = (crop, selectedCompanyIdentifier) => {
    const additionalData = JSON.stringify(crop)

    const dataToInsert = [
      additionalData,
      crop._id,
      crop?.createdAt ?? null,
      crop?.updatedAt ?? null,
      crop?.identifier,
      selectedCompanyIdentifier ?? null,
    ]

    return dataToInsert
  }

  const insertCropToDB = async (dataToInsert) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }

        db.transaction((tx) => {
          tx.executeSql(
            INSERT_QUERY,
            dataToInsert,
            () => resolve([]),
            (_, error) => {
              console.warn('ERROR Insert Crop')
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn('ERROR Insert Crop')
      console.warn(error)

      return [error]
    }
  }

  const deleteCrop = async (cropId) => {
    try {
      const [errorDeleteCrop] = await deleteCropInDB(cropId)

      if (errorDeleteCrop) {
        return [errorDeleteCrop]
      }

      return []
    } catch (error) {
      console.warn('ERROR Delete Crop')
      console.warn(error)

      return [error]
    }
  }

  const deleteCropInDB = (cropId) => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }

      db.transaction((tx) => {
        tx.executeSql(
          `${DELETE_ONE_QUERY} '${cropId}'`,
          [],
          () => resolve([]),
          (_, err) => console.warn(`ERR DELETE crop: ${err}`)
        )
      })
    })

    return promise
  }

  return {
    initOfflineCrop,
    syncCrops,
    selectAllCrops,
    getPagedCropsOffline,
    updateStatus,
    storeOfflineCollaborator,
    changeActivityStatusOrder,
    addToCropStatus,
    getTotalCropsOffline,
    findOneCrop,
    insertCrop,
    deleteCrop,
  }
}

export default useOfflineCrops
