import AsyncStorage from '@react-native-async-storage/async-storage'

import useNetwork from '@utils/network'
import activityTypes from '@constants/activityTypes'
import useOfflineCommon from './common'
import { getLocalStorage, setLocalStorage } from '@utils/common'
import getDatabase from './getDataBase'

const CREATE_QUERY =
  'CREATE TABLE IF NOT EXISTS supplies (id integer primary key not null, _id text, name text, brand text, company text, alphaCode text, eiq real, activities text, cropTypes text, tags text, data text);'
const INSERT_QUERY =
  'INSERT INTO supplies (_id, name, brand, company, alphaCode, eiq, activities, cropTypes, tags, data) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);'
const SELECT_QUERY = 'SELECT * FROM supplies'
const COUNT_QUERY = 'SELECT COUNT(id) AS count FROM supplies;'
const DROP_QUERY = 'DROP TABLE IF EXISTS supplies;'
const LAST_VERSION_KEY = 'lastVersionSupply'

const SYNC_SUPPLIES_OFFLINE = 'syncSuppliesOffline'
const FIELDS_TABLE = [
  'id',
  '_id',
  'name',
  'brand',
  'company',
  'alphaCode',
  'eiq',
  'activities',
  'cropTypes',
  'tags',
  'data',
]

let isValidatingVersion = false

const useOfflineSupplies = () => {
  const db = getDatabase('db.offlinedata')
  const { findOneIndividual } = useOfflineCommon()

  const { doRequest } = useNetwork()

  const initOfflineSupply = async () => {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve, reject) => {
      try {
        await createSupplyTable()

        resolve()
      } catch (err) {
        await setInProcess(false)
        console.warn('ERROR OFFLINE SUPPLIES')
        console.warn(err)
        alert(err.message)
        reject(err)
      }
    })

    return promise
  }

  const createSupplyTable = async () => {
    const promise = new Promise((resolve, reject) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          CREATE_QUERY,
          [],
          () => resolve(),
          (_, err) => reject(err)
        )
      })
    })

    return promise
  }

  const dropSupplyTable = async () => {
    const promise = new Promise((resolve, reject) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          DROP_QUERY,
          [],
          () => resolve(),
          (_, err) => reject(err)
        )
      })
    })

    return promise
  }

  const validateVersionSupplies = async () => {
    if (isValidatingVersion) {
      return
    }

    isValidatingVersion = true

    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve, reject) => {
      try {
        if (!db) {
          return resolve()
        }

        await setInProcess(false)

        const supply = await findOneSupply(true)

        let hasColumnsCorrect = true

        if (supply) {
          const keys = Object.keys(supply)
          const fields = []

          FIELDS_TABLE.forEach((field) => {
            fields.push(keys.includes(field))
          })

          hasColumnsCorrect = fields.every((el) => el)
        }

        const lastVersionSupplyLocal = await getLocalStorage(LAST_VERSION_KEY)

        const { data } = await doRequest({
          method: 'GET',
          url: 'supplies/eiq/last-version',
          displayAlert: false,
        })

        const isVersionSupplyEquals =
          lastVersionSupplyLocal === data.versionDate

        if (!supply || !hasColumnsCorrect || !isVersionSupplyEquals) {
          await dropSupplyTable()
          await createSupplyTable()

          const crop = await findOneIndividual('crops')

          if (crop) {
            const alphaCode = crop.company.country.alphaCode

            await setLocalStorage(LAST_VERSION_KEY, data.versionDate)
            await syncSupplies(alphaCode)
          }
        }

        resolve()

        isValidatingVersion = false
      } catch (err) {
        await setInProcess(false)
        console.warn('ERROR OFFLINE SUPPLIES')
        console.warn(err)
        err?.message && alert(err.message)
        reject(err)
      }
    })

    return promise
  }

  const setInProcess = async (inProcess, alphaCode) =>
    await AsyncStorage.setItem(
      SYNC_SUPPLIES_OFFLINE,
      JSON.stringify({ inProcess, alphaCode })
    )

  const getInProcess = async () => {
    const response = await AsyncStorage.getItem(SYNC_SUPPLIES_OFFLINE)
    return response ? JSON.parse(response) : null
  }

  const syncSupplies = async (alphaCode) => {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve, reject) => {
      try {
        const persisted = await getInProcess()

        if (
          persisted &&
          persisted.inProcess &&
          persisted.alphaCode === alphaCode
        ) {
          return resolve()
        }

        const quantitiesResponse = await doRequest({
          method: 'GET',
          url: 'supplies/quantities',
          displayAlert: false,
          params: {
            alphaCode,
          },
        })

        const suppliesQuantity = quantitiesResponse.data

        let pages = 1
        const limit = 5000

        if (suppliesQuantity > limit) {
          pages = Math.ceil(suppliesQuantity / limit)
        }

        await resetSupplies()

        await setInProcess(true, alphaCode)

        const { error } = await asyncLoopsChunk(alphaCode, 1, pages, limit)

        await setInProcess(false)

        if (error) {
          console.warn('Error', error)
          reject(error)
          return
        }

        resolve()
      } catch (err) {
        await setInProcess(false)
        console.warn('ERROR OFFLINE SUPPLIES ARE SYNC')
        console.warn(err)
        reject(err)
      }
    })

    return promise
  }

  const asyncLoopsChunk = async (alphaCode, page, pages, limit) => {
    return new Promise((resolve) => {
      let counter = 0

      const helperChunk = async (page, cb) => {
        try {
          if (page > pages) {
            return cb({ error: null, counter })
          } else {
            helperChunk(page + 1, cb)
          }

          const params = {
            alphaCode,
            limit,
            skip: limit * (page - 1),
          }

          const response = await doRequest({
            method: 'GET',
            url: 'supplies/for-offline',
            displayAlert: false,
            params,
          })

          db.transaction((tx) => {
            for (const element of response.data) {
              const activities = element?.typeId?.activities
                ? element.typeId.activities
                : []
              const cropTypes = element?.typeId?.cropTypes
                ? element.typeId.cropTypes
                : []
              const tags = element?.typeId?.tags ? element.typeId.tags : []

              const dataToInsert = [
                element._id,
                element.name,
                element.brand ?? '',
                element.company ?? '',
                element.alphaCode ?? '',
                element.eiqTotal ?? '',
                activities.join(),
                cropTypes.join(),
                tags.join(),
                JSON.stringify(element),
              ]

              tx.executeSql(INSERT_QUERY, dataToInsert)

              counter++
            }
          })
        } catch (error) {
          console.warn('SUPPLIES asyncLoopsChunk > helperChunk ERROR')
          console.warn(error)

          return cb({ error })
        }
      }

      helperChunk(page, (results) => resolve(results))
    })
  }

  const getSupplies = async ({
    searchValue = '',
    activityType,
    cropType,
    tag,
    orderByAscending,
    limit,
    skip,
  }) => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }
      let sqlStatement = `${SELECT_QUERY} WHERE (name LIKE ? OR brand LIKE ? OR company LIKE ?)`

      const args = [`%${searchValue}%`, `%${searchValue}%`, `%${searchValue}%`]

      if (activityType) {
        sqlStatement = `${sqlStatement} AND (activities = ? OR activities LIKE ? OR activities LIKE ? OR activities LIKE ?)`

        args.push(activityType)
        args.push(`%,${activityType}`)
        args.push(`${activityType},%`)
        args.push(`%,${activityType},%`)
      }

      if (activityType === activityTypes.ACT_SOWING.key && cropType) {
        sqlStatement = `${sqlStatement} AND (cropTypes = ? OR cropTypes LIKE ? OR cropTypes LIKE ? OR cropTypes LIKE ?)`

        args.push(cropType)
        args.push(`%,${cropType}`)
        args.push(`${cropType},%`)
        args.push(`%,${cropType},%`)
      }

      if (tag) {
        sqlStatement = `${sqlStatement} AND (tags = ? OR tags LIKE ? OR tags LIKE ? OR tags LIKE ?)`

        args.push(tag)
        args.push(`%,${tag}`)
        args.push(`${tag},%`)
        args.push(`%,${tag},%`)
      }

      if (orderByAscending) {
        sqlStatement = `${sqlStatement} ORDER BY ${orderByAscending} ASC`
      }

      sqlStatement = `${sqlStatement} LIMIT ? OFFSET ?`

      args.push(limit ? limit + 1 : 16)
      args.push(skip || 0)

      db.transaction((tx) => {
        tx.executeSql(
          sqlStatement,
          args,
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)

            const supplies = results.map((element) => JSON.parse(element.data))

            let hasMoreElements = false

            if (supplies.length === limit + 1) {
              hasMoreElements = true

              supplies.pop()
            }

            const dataToSend = {
              results: supplies,
              hasMoreElements,
            }

            resolve(dataToSend)
          },
          (_, err) => alert(err.message)
        )
      })
    })

    return promise
  }

  const findOneSupply = async (withoutData) => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(null)
      }
      db.transaction((tx) => {
        tx.executeSql(
          'SELECT * from supplies LIMIT 1;',
          [],
          (_, { rows }) => {
            const result = rows._array ?? Array.from(rows)
            const dataToReturn = result[0]
              ? withoutData
                ? result[0]
                : JSON.parse(result[0].data)
              : null
            resolve(dataToReturn)
          },
          (_, err) => alert(err.message)
        )
      })
    })

    return promise
  }

  const countSuppliesOffline = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(0)
      }
      db.transaction((tx) => {
        tx.executeSql(
          COUNT_QUERY,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            resolve(results[0].count)
          },
          (_, err) => alert(err.message)
        )
      })
    })

    return promise
  }

  const resetSupplies = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          'DELETE from supplies;',
          [],
          () => {
            resolve()
          },
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  return {
    initOfflineSupply,
    syncSupplies,
    validateVersionSupplies,
    getSupplies,
    resetSupplies,
    findOneSupply,
    countSuppliesOffline,
  }
}

export default useOfflineSupplies
