/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useNetwork from '@utils/network'

const useFetchPagination = ({ url, method, version = 'v1', params = {} }) => {
  const LIMIT_PAGE = 20
  const [response, setResponse] = useState({
    data: [],
    metaInfo: {},
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { doRequest } = useNetwork()
  const [firstPaginate, setFirstPaginate] = useState(true)
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(true)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const res = await doRequest({
        method,
        url,
        version,
        params: { limit: LIMIT_PAGE, page, ...params },
        displayAlert: false,
      })

      const dataResult = res.data || res
      const { results, metaInfo } = dataResult

      setResponse({
        ...dataResult,
        data: page === 1 ? results : [...response.data, ...results],
        metaInfo,
      })

      setFirstPaginate(false)
      setIsLoading(false)
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page, refresh])

  const nextPage = () => {
    if (page < response.metaInfo.totalPages) {
      setPage(page + 1)
    }
  }

  const refreshPage = () => {
    setPage(1)
    setRefresh(!refresh)
  }

  return {
    response,
    error,
    isLoading,
    setError,
    setResponse,
    firstPaginate,
    nextPage,
    refreshPage,
  }
}

export default useFetchPagination
