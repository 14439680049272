/**
 *
 * @param {array} farms
 * @returns  {array}
 */
export const mapRealizedSurfaceFarmsAndLots = (farms) => {
  return farms.map((farm) => {
    const lots = farm.lots.map((lot) => ({
      ...lot,
      surface: lot.surfaceRealized,
    }))

    return {
      ...farm,
      surface: farm.surfaceRealized,
      lots,
    }
  })
}
