import { useContext } from 'react'
import { useNavigation } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import { ActivityIcon } from '@modules/common/components'
import activityTypesConstants from '@constants/activityTypes'
import { black } from '@styles/palette'
import { IUseFab } from './interfaces'

export const useFab = ({
  cropId,
  areaUnit,
  cropTypeName,
  surface,
}: IUseFab): {
  fabActions: Array<any>
} => {
  const { t }: any = useContext(LanguageContext)

  const navigation = useNavigation()

  const harvestAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_HARVEST.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_2'),
    onPress: (): void => {
      const screen = 'ActivityCreate'

      const params = {
        cropId,
        tag: activityTypesConstants.ACT_HARVEST.key,
        canSign: true,
      }

      return navigation.navigate(screen, params)
    },
  }

  const monitoringAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_MONITORING.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_6'),
    onPress: (): void => {
      const screen = 'ActivityCreate'

      const params = {
        cropId,
        tag: activityTypesConstants.ACT_MONITORING.key,
        canSign: true,
      }

      return navigation.navigate(screen, params)
    },
  }

  const fertilizationAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_FERTILIZATION.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_4'),
    onPress: (): void => {
      const screen = 'CommonOptionalPlanned'

      const params = {
        id: cropId,
        mode: 'fertilization',
        tag: activityTypesConstants.ACT_FERTILIZATION.key,
        areaUnit,
        cropTypeName,
        surface,
      }

      return navigation.navigate(screen, params)
    },
  }

  const applicationAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_APPLICATION.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_5'),
    onPress: (): void => {
      const screen = 'CommonOptionalPlanned'

      const params = {
        id: cropId,
        mode: 'application',
        tag: activityTypesConstants.ACT_APPLICATION.key,
        areaUnit,
        cropTypeName,
        surface,
      }

      return navigation.navigate(screen, params)
    },
  }

  const sowingAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_SOWING.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_1'),
    onPress: (): void => {
      const screen = 'CommonOptionalPlanned'

      const params = {
        id: cropId,
        mode: 'sowing',
        tag: activityTypesConstants.ACT_SOWING.key,
        areaUnit,
        cropTypeName,
        surface,
      }

      return navigation.navigate(screen, params)
    },
  }

  const tillageAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_TILLAGE.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_3'),
    onPress: (): void => {
      const screen = 'CommonOptionalPlanned'

      const params = {
        id: cropId,
        mode: 'tillage',
        tag: activityTypesConstants.ACT_TILLAGE.key,
        areaUnit,
        cropTypeName,
        surface,
      }

      return navigation.navigate(screen, params)
    },
  }

  const agreementAction = {
    icon: (props: any) => (
      <ActivityIcon
        {...props}
        name={activityTypesConstants.ACT_AGREEMENTS.key}
        color={black}
      />
    ),
    label: t('VIEWS.ACTIVITIES.TEXT_7'),
    onPress: (): void => {
      const screen = activityTypesConstants.ACT_AGREEMENTS.canPlanning
        ? 'CommonOptionalPlanned'
        : 'ActivityCreate'

      const params = {
        cropId,
        tag: activityTypesConstants.ACT_AGREEMENTS.key,
        canSign: true,
      }

      return navigation.navigate(screen, params)
    },
  }

  const fabActions: any = [
    harvestAction,
    monitoringAction,
    fertilizationAction,
    applicationAction,
    sowingAction,
    tillageAction,
    agreementAction,
  ]

  return { fabActions }
}
