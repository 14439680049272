import { useState } from 'react'
import useNetwork from '@utils/network'

const useValidateAchievement = () => {
  const { doRequest, loading } = useNetwork()
  const [params, setParams] = useState(null)

  const signAchievements = async (
    cropId,
    activityId,
    achievementId,
    draftId
  ) => {
    const response = await doRequest({
      method: 'POST',
      url: `achievements/${achievementId}/signs`,
      data: {
        activityId,
        cropId,
        draftId,
        dateSigned: new Date(),
      },
    })
    return response
  }

  const validateAchievement = async (data = null) => {
    const localParams = data || params

    if (localParams) {
      const { crop, activity, achievements, data, draftId } = localParams
      if (data) {
        const responseAchievements = await doRequest({
          method: 'POST',
          url: 'activities',
          data: data,
          version: 'v3',
        })
        const achievement = await signAchievements(
          crop,
          activity,
          responseAchievements.data._id,
          draftId
        )
        return { ...localParams, resultAchievement: achievement.data }
      }
      const achievement = await signAchievements(
        crop,
        activity,
        achievements,
        draftId
      )
      return { ...localParams, resultAchievement: achievement.data }
    }
  }

  return {
    validateAchievement,
    loading,
    setParams,
  }
}

export default useValidateAchievement
