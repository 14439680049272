import { useState, useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { useCompanyInfo } from '@common/hooks'
import useNetwork from '@utils/network'

export const useFetchPendingLicenses = ({ cropId }: { cropId: string }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pendingLicenses, setPendingLicenses] = useState([])

  const { doRequest } = useNetwork()
  const { companyId } = useCompanyInfo()

  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  const fetchData = async () => {
    setIsLoading(true)

    const requestData = {
      method: 'GET',
      url: 'licenses/search-by-signed-not-applied',
      params: {
        companyId,
        cropId,
      },
      displayAlert: false,
    }

    let response

    try {
      const { data } = await doRequest(requestData)

      response = data
    } catch (error) {
      setIsLoading(false)

      return
    }

    setPendingLicenses(response)

    setIsLoading(false)
  }

  return {
    isLoading,
    pendingLicenses,
  }
}
