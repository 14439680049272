import React, { useContext } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { blackHighEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

export const Description = ({ surface, farmName, location }) => {
  const { t } = useContext(LanguageContext)

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.title}>
          {t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.DESCRIPTION.TEXT_1')}
        </Text>
        <Text style={styles.subtitle}>{surface}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.title}>
          {t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.DESCRIPTION.TEXT_2')}
        </Text>
        <Text style={styles.subtitle}>{farmName}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.title}>
          {t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.DESCRIPTION.TEXT_3')}
        </Text>
        <Text style={styles.subtitle}>{location}</Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 16,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
})
