import { v4 as uuidv4 } from 'uuid'
import { ObjectId } from '@utils/common'
import getDatabase from './getDataBase'

const CREATE_TABLE_QUERY =
  'CREATE TABLE IF NOT EXISTS drafts (id integer primary key not null, _id text, crop text, activity text, version integer, isSynchronized bool, additionalData text, createdAt text, updatedAt text, deletedAt text, draftGroupId text);'
const SELECT_COLUMNS_QUERY = 'PRAGMA table_info(drafts);'
const CREATE_COLUMN_QUERY = 'ALTER TABLE drafts ADD column_name column_type'
const DROP_TABLE_QUERY = 'DROP TABLE IF EXISTS drafts;'
const SELECT_BY_CROP_QUERY =
  'SELECT * FROM drafts WHERE crop = ? AND deletedAt IS NULL ORDER BY updatedAt DESC;'
const SELECT_BY_ACTIVITY_ORDERED_BY_VERSION_QUERY =
  'SELECT * FROM drafts WHERE activity = ? AND deletedAt IS NULL ORDER BY updatedAt DESC, version DESC;'
const SELECT_BY_ID_QUERY =
  'SELECT * FROM drafts WHERE _id = ? AND deletedAt IS NULL;'
const INSERT_QUERY =
  'INSERT INTO drafts (_id, crop, activity, version, isSynchronized, additionalData, createdAt, updatedAt, deletedAt, draftGroupId) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);'
const UPDATE_QUERY =
  'UPDATE drafts SET isSynchronized = ?, additionalData = ?, updatedAt = ? WHERE _id = ?;'
const UPDATE_DATE_DRAFTS_QUERY =
  'UPDATE drafts SET updatedAt = ? WHERE draftGroupId = ?;'
const DELETE_BY_ID_QUERY =
  'UPDATE drafts SET deletedAt = ?, isSynchronized = ?, additionalData = ? WHERE _id = ?;'
const SELECT_BY_UNSYNCHRONIZED_QUERY =
  'SELECT * FROM drafts WHERE isSynchronized = 0;'
const DELETE_ALL_QUERY = 'DELETE FROM drafts;'
const columns = [
  {
    name: '_id',
    type: 'text',
  },
  {
    name: 'crop',
    type: 'text',
  },
  {
    name: 'activity',
    type: 'text',
  },
  {
    name: 'version',
    type: 'integer',
  },
  {
    name: 'isSynchronized',
    type: 'bool',
  },
  {
    name: 'additionalData',
    type: 'text',
  },
  {
    name: 'createdAt',
    type: 'text',
  },
  {
    name: 'updatedAt',
    type: 'text',
  },
  {
    name: 'deletedAt',
    type: 'text',
  },
  {
    name: 'draftGroupId',
    type: 'text',
  },
]

const useOfflineDrafts = () => {
  const db = getDatabase('db.offlinedata')

  const initOfflineDraft = async () => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }

        db.transaction((tx) => {
          tx.executeSql(
            CREATE_TABLE_QUERY,
            [],
            () => {},
            (_, error) => {
              console.warn(`ERROR Init Table Drafts`)
              console.warn(error)

              reject([error])
            }
          )

          tx.executeSql(
            SELECT_COLUMNS_QUERY,
            [],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              const nonexistentColumns = []

              for (const column of columns) {
                if (!results.find((element) => element.name === column.name)) {
                  nonexistentColumns.push(column)
                }
              }

              for (const nonexistentColumn of nonexistentColumns) {
                tx.executeSql(
                  CREATE_COLUMN_QUERY.replace(
                    'column_name',
                    nonexistentColumn.name
                  ).replace('column_type', nonexistentColumn.type),
                  [],
                  () => {},
                  (_, error) => {
                    console.warn(`ERROR create column Drafts`)
                    console.warn(error)

                    reject([error])
                  }
                )
              }

              resolve([])
            },
            (_, error) => {
              console.warn(`ERROR select columns Drafts`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Init Table Drafts`)
      console.warn(error)

      return [error]
    }
  }

  const dropDraftTable = async () => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            DROP_TABLE_QUERY,
            [],
            () => resolve([]),
            (_, error) => {
              console.warn(`ERROR Drop Table Drafts`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Drop Table Drafts`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByCropIdOffline = async (cropId) => {
    try {
      const [errorGetDrafts, drafts] = await findDraftsByCropId(cropId)

      if (errorGetDrafts) {
        return [errorGetDrafts]
      }

      return [false, drafts]
    } catch (error) {
      console.warn(`ERROR Find Draft By Crop`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByCropId = async (cropId) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            SELECT_BY_CROP_QUERY,
            [cropId],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)
              resolve([
                false,
                results.map((element) => JSON.parse(element.additionalData)),
              ])
            },
            (_, error) => {
              console.warn(`ERROR Find Drafts By Crop`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Find Drafts By Crop`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByActivityIdOffline = async (activityId) => {
    try {
      const [errorGetDrafts, drafts] = await findDraftsByActivityId(activityId)

      if (errorGetDrafts) {
        return [errorGetDrafts]
      }

      return [false, drafts]
    } catch (error) {
      console.warn(`ERROR Find Draft By Activity`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByActivityId = async (activityId) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            SELECT_BY_ACTIVITY_ORDERED_BY_VERSION_QUERY,
            [activityId],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              resolve([
                false,
                results.map((element) => JSON.parse(element.additionalData)),
              ])
            },
            (_, error) => {
              console.warn(`ERROR Find Drafts By Activity`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Find Drafts By Activity`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftByIdOffline = async (draftId) => {
    try {
      const [errorGetDraft, draft] = await findDraftById(draftId)

      if (errorGetDraft) {
        return [errorGetDraft]
      }

      return [false, draft]
    } catch (error) {
      console.warn(`ERROR Find Draft By Id`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftById = async (draftId) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return null
        }
        db.transaction((tx) => {
          tx.executeSql(
            SELECT_BY_ID_QUERY,
            [draftId],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              resolve([
                false,
                results.length
                  ? results.map((element) =>
                      JSON.parse(element.additionalData)
                    )[0]
                  : null,
              ])
            },
            (_, error) => {
              console.warn(`ERROR Find Drafts By Id`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Find Drafts By Id`)
      console.warn(error)

      return [error]
    }
  }

  const insertDraftOffline = async (data, user) => {
    try {
      const dataToInsert = parseDataToInsert(data, user)

      const [errorInsertDraft] = await insertDraft(dataToInsert)

      if (errorInsertDraft) {
        return [errorInsertDraft]
      }

      return []
    } catch (error) {
      console.warn(`ERROR Insert Draft`)
      console.warn(error)

      return [error]
    }
  }

  const parseDataToInsert = (data, user) => {
    const currentDate = new Date().toISOString()

    const draftId = data._id ?? ObjectId()

    const newDraft = {
      ...data,
      id: draftId,
      _id: draftId,
      createdByUserId: user._id,
      createdByUsername: user.name,
      updatedByUserId: user._id,
      updatedByUsername: user.name,
      draftGroupId: data.draftGroupId ?? uuidv4(),
      isSynchronized: data.isSynchronized ?? false,
      createdAt: data.createdAt
        ? new Date(data.createdAt).toISOString()
        : currentDate,
      updatedAt: data.updatedAt
        ? new Date(data.updatedAt).toISOString()
        : currentDate,
      dateAchievement: data.dateAchievement
        ? new Date(data.dateAchievement).toISOString()
        : currentDate,
      dateHarvest: data.dateHarvest
        ? new Date(data.dateHarvest).toISOString()
        : undefined,
      dateObservation: data.dateObservation
        ? new Date(data.dateObservation).toISOString()
        : undefined,
      dateEstimatedHarvest: data.dateEstimatedHarvest
        ? new Date(data.dateEstimatedHarvest).toISOString()
        : undefined,
      evidences: data.evidences?.length ? data.evidences : [],
      signers: data.signers?.length ? data.signers : [],
      storages: data.storages?.length ? data.storages : [],
      deletedAt: null,
      version: data.version ?? 1,
      observations: data.observations
        ? data.observations?.map((observation) => ({
            ...observation,
            createdAt: new Date(observation.createdAt).toISOString(),
          }))
        : [],
    }

    const additionalData = JSON.stringify(newDraft)

    const dataToInsert = [
      newDraft._id,
      newDraft.crop,
      newDraft.activity || null,
      1,
      newDraft.isSynchronized,
      additionalData,
      newDraft.createdAt,
      newDraft.updatedAt,
      null,
      newDraft.draftGroupId,
    ]

    return dataToInsert
  }

  const insertDraft = async (dataToInsert) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            INSERT_QUERY,
            dataToInsert,
            () => resolve([]),
            (_, error) => {
              console.warn(`ERROR Insert Draft`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Insert Draft`)
      console.warn(error)

      return [error]
    }
  }

  const updateDraftOffLine = async (draftId, data, user) => {
    try {
      const [errorFindDraft, draft] = await findDraftById(draftId)
      if (data.isRejected) {
        data.isRejected = false
      }

      if (errorFindDraft) {
        return [errorFindDraft]
      }

      const { dataToUpdate, dataUpdateVersions } = parseDataToUpdate(
        draftId,
        draft,
        data,
        user
      )

      const [errorInsertDraft] = await updateDraft(
        dataToUpdate,
        dataUpdateVersions
      )
      if (errorInsertDraft.length) {
        return [errorInsertDraft]
      }

      return []
    } catch (error) {
      console.warn(`ERROR Updating Draft`)
      console.warn(error)

      return [error]
    }
  }

  const parseDataToUpdate = (draftId, draft, data, user) => {
    const currentDate = new Date().toISOString()

    const newDraft = {
      ...draft,
      ...data,
      updatedByUserId: user._id,
      updatedByUsername: user.name,
      isSynchronized: false,
      updatedAt: currentDate,
    }
    const additionalData = JSON.stringify(newDraft)

    const dataToUpdate = [
      newDraft.isSynchronized,
      additionalData,
      newDraft.updatedAt,
      draftId,
    ]

    const dataUpdateVersions = [currentDate, newDraft.draftGroupId]

    return { dataToUpdate, dataUpdateVersions }
  }

  const updateDraft = async (dataToUpdate, dataUpdateVersions) => {
    const queries = [
      { query: UPDATE_QUERY, params: dataToUpdate },
      { query: UPDATE_DATE_DRAFTS_QUERY, params: dataUpdateVersions },
    ]
    try {
      return executeMulTipleQuery(queries)
    } catch (error) {
      console.warn(`ERROR Update Draft`)
      console.warn(error)

      return [error]
    }
  }

  const deleteDraftByIdOffline = async (draftId) => {
    try {
      const [errorGetDraft, draft] = await findDraftById(draftId)

      if (errorGetDraft) {
        return [errorGetDraft]
      }

      const [errorDeleteDraft] = await deleteDraftById(draftId, draft)

      if (errorDeleteDraft) {
        return [errorDeleteDraft]
      }

      return []
    } catch (error) {
      console.warn(`ERROR Delete Draft By Id`)
      console.warn(error)

      return [error]
    }
  }

  const deleteDraftById = async (draftId, draft) => {
    try {
      const currentDate = new Date().toISOString()

      const isSynchronized = false

      const additionalData = JSON.stringify({
        ...draft,
        deletedAt: currentDate,
        isSynchronized,
      })

      const dataToDelete = [
        currentDate,
        isSynchronized,
        additionalData,
        draftId,
      ]

      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            DELETE_BY_ID_QUERY,
            dataToDelete,
            () => resolve([]),
            (_, error) => {
              console.warn(`ERROR Delete Draft By Id`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Delete Draft By Id`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByUnsynchronizedOffline = async () => {
    try {
      const [errorGetDrafts, drafts] = await findDraftsByUnsynchronized()

      if (errorGetDrafts) {
        return [errorGetDrafts]
      }

      return [false, drafts]
    } catch (error) {
      console.warn(`ERROR Find Draft By Unsynchronized`)
      console.warn(error)

      return [error]
    }
  }

  const findDraftsByUnsynchronized = async () => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            SELECT_BY_UNSYNCHRONIZED_QUERY,
            [],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              resolve([
                false,
                results.map((element) => JSON.parse(element.additionalData)),
              ])
            },
            (_, error) => {
              console.warn(`ERROR Find Drafts By Unsynchronized`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Find Drafts By Unsynchronized`)
      console.warn(error)

      return [error]
    }
  }

  const deleteAllDraftOffline = async () => {
    try {
      const [errorDeleteDrafts] = await deleteAllDraft()

      if (errorDeleteDrafts) {
        return [errorDeleteDrafts]
      }

      return []
    } catch (error) {
      console.warn(`ERROR Delete All Drafts`)
      console.warn(error)

      return [error]
    }
  }

  const deleteAllDraft = async () => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            DELETE_ALL_QUERY,
            [],
            () => resolve([]),
            (_, error) => {
              console.warn(`ERROR Delete All Drafts`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Delete All Drafts`)
      console.warn(error)

      return [error]
    }
  }

  const executeMulTipleQuery = async (arrayQueries) => {
    const arrayPromises = []
    arrayQueries.forEach((elementQuery) => {
      const { query, params } = elementQuery
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }
        db.transaction((tx) => {
          tx.executeSql(
            query,
            params,
            () => resolve([]),
            (_, error) => {
              console.warn(`ERROR Update Draft`)
              console.warn(error)

              reject([error])
            }
          )
        })
      })
      arrayPromises.push(promise)
    })

    return Promise.all(arrayPromises)
  }

  return {
    initOfflineDraft,
    dropDraftTable,
    findDraftsByCropIdOffline,
    findDraftsByActivityIdOffline,
    findDraftByIdOffline,
    insertDraftOffline,
    updateDraftOffLine,
    deleteDraftByIdOffline,
    findDraftsByUnsynchronizedOffline,
    deleteAllDraftOffline,
  }
}

export default useOfflineDrafts
