import { Amplify, Auth } from 'aws-amplify'
import awsCredentials from './aws-export'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

// Todo: Forzar cambio de Config Amplify
if (Platform.OS === 'web') {
  // eslint-disable-next-line no-console
  console.log(awsCredentials)
  // eslint-disable-next-line no-console
  console.clear()
  //Todo web only at the moment
  Amplify.configure(awsCredentials)
}
export const awsSignIn = async (email) => {
  email = email.trim()
  return await Auth.signIn(email)
}

export const awsIsAuthenticated = async () => {
  try {
    await Auth.currentSession()
    return true
  } catch {
    return false
  }
}

export const awsSignUp = async (email, name) => {
  const params = {
    username: email,
    password: process.env.EXPO_USER_PASSWORD,
    attributes: {
      name,
      email,
    },
  }
  await Auth.signUp(params)
}

export const awsSignOut = async () => {
  await Auth.signOut()
}

export const answerCustomChallenge = async (answer, cognitoUser) => {
  await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
  return awsIsAuthenticated()
}

export const getTokenAws = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  return await Auth.userSession(cognitoUser)
}

export const redirectQuickSight = async () => {
  const thisUrlEncoded = encodeURIComponent(window.location.hostname)
  const accessTokenUser = await AsyncStorage.getItem('USER_TOKEN')
  const endpoint = `${process.env.EXPO_API_HOST}/auth/quicksight?accessToken=${accessTokenUser}&Issuer=${thisUrlEncoded}`
  window.open(endpoint, '_blank')
}
