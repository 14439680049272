import './src/utils/ignoreLogsOrWarnings'
import 'intl'
import 'intl/locale-data/jsonp/en'
import React, { useContext, useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import AppLoading from 'expo-app-loading'
import * as Updates from 'expo-updates'
import { useFonts } from 'expo-font'
import {
  ActionSheetProvider,
  connectActionSheet,
} from '@expo/react-native-action-sheet'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Navigator from './src/navigator'
import { ContextProvider } from '@contextState/state'
import { CommonContext } from '@contextState/common'
import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import {
  initializeFirebase,
  firestoreDisableNetwork,
  firestoreEnableNetwork,
} from './src/services/firebase'
import { store, persistor } from './src/store'

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#4CAF50',
    accent: '#FF5722',
  },
  fonts: {
    ...DefaultTheme.fonts,
    thin: {
      fontFamily: 'Inter',
    },
    light: {
      fontFamily: 'Inter',
    },
    regular: {
      fontFamily: 'Inter',
    },
    medium: {
      fontFamily: 'Inter',
    },
  },
}

let isSyncDrafts = false

function Master() {
  const { displayToggleModalNotification } = useContext(CommonContext)
  const { isConnected, isSynchronizing, syncDrafts } =
    useContext(ConnectionContext)
  const { isAuth, user, config } = useContext(AuthContext)
  const [isAppReady, setIsAppReady] = useState(false)
  const [isFontsLoaded] = useFonts({
    crop: require('@assets/icons/crop/font.ttf'),
    activity: require('@assets/icons/activity/font.ttf'),
    supplies: require('@assets/icons/supplies/font.ttf'),
    sustainability: require('@assets/icons/sustainability/font.ttf'),
    common: require('@assets/icons/common/font.ttf'),
    Inter: require('@assets/fonts/inter/Inter-Regular.ttf'),
  })

  /**
   * SYNC DRAFTS 3 SECONDS AFTER GET GOOD CONNECTION
   */
  useEffect(() => {
    if (!isAppReady || !isAuth || isSynchronizing) {
      return
    }

    initFirebase()
  }, [isConnected, isAppReady, isAuth])

  const initFirebase = async () => {
    if (isConnected) {
      await firestoreEnableNetwork()

      setTimeout(initSyncDrafts, 3000)
    } else {
      await firestoreDisableNetwork()
    }
  }

  const initSyncDrafts = async () => {
    try {
      if (isSyncDrafts) {
        return
      }

      isSyncDrafts = true

      await syncDrafts(user, config, displayToggleModalNotification)
    } catch (err) {
      console.warn('initSyncDrafts')
      console.warn(err)
    }

    isSyncDrafts = false
  }

  const fetchUpdate = async () => {
    try {
      const update = await Updates.checkForUpdateAsync()

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync()
        await Updates.reloadAsync()
      }
    } catch (e) {
      // handle or log error
    }
  }

  const fetchInitial = async () => {
    if (isConnected) {
      await fetchUpdate()
    }
    await initializeFirebase()
  }

  if (!isAppReady || !isFontsLoaded) {
    return (
      <AppLoading
        startAsync={fetchInitial}
        onFinish={() => setIsAppReady(true)}
        onError={(error) => {
          console.error(error)
        }}
      />
    )
  } else {
    return (
      <PaperProvider theme={theme}>
        <Navigator />
      </PaperProvider>
    )
  }
}

const ConnectedApp = connectActionSheet(Master)

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <ContextProvider>
            <ActionSheetProvider>
              <ConnectedApp />
            </ActionSheetProvider>
          </ContextProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  )
}
