import React, { useContext, useCallback } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import verificationStatus from '@constants/verificationStatus'
import {
  grayBackground,
  blackHighEmphasis,
  blackMediumEmphasis,
} from '@styles/palette'

const ActivityVerificationInvalid = ({ uploadedBy, uploadedAt }) => {
  const { t } = useContext(LanguageContext)

  const VerificationDescription = useCallback(({ uploadedBy, uploadedAt }) => {
    return (
      <View style={styles.descriptionContainer}>
        <Text style={styles.statusText}>
          {t('COMPONENTS.COMMON.ACTIVITY_VERIFICATION_INVALID.TEXT_1', {
            status: verificationStatus.INVALID.name(t),
          })}
        </Text>

        {uploadedBy && <Text style={styles.uploadedByText}>{uploadedBy}</Text>}

        {uploadedAt && <Text style={styles.uploadedAtText}>{uploadedAt}</Text>}
      </View>
    )
  }, [])

  return (
    <View style={styles.verificationContainer}>
      <VerificationDescription
        uploadedBy={uploadedBy}
        uploadedAt={uploadedAt}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  verificationContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    backgroundColor: grayBackground,
    borderRadius: 4,
  },
  descriptionContainer: {
    flex: 1,
    paddingHorizontal: 24,
  },
  statusText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  uploadedByText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
  uploadedAtText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
})

ActivityVerificationInvalid.propTypes = {
  uploadedBy: PropTypes.string,
  uploadedAt: PropTypes.string,
}

export default ActivityVerificationInvalid
