import { EActiveAgreementTypes } from '@common/utils'
import * as yup from 'yup'

export const agreementValidationSchema = (t) =>
  yup.object().shape({
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(),
              surface: yup.number().required(),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.array(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    surface: yup.number().typeError(),
    seedDryContent: yup
      .number()
      .typeError(t('ERRORS.FORM_ERRORS.IS_NUMBER'))
      .min(0, t('ERRORS.FORM_ERRORS.IS_POSITIVE'))
      .max(100, `${t('ERRORS.FORM_ERRORS.LESS_THAN')} 100`),
    typeAgreement: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    evidences: yup.array().when('typeAgreement', {
      is: (typeAgreement) =>
        typeAgreement.key !== EActiveAgreementTypes.CALCULATION_2BSVS,
      then: yup
        .array()
        .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    signers: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
