import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ScrollView } from 'react-native'
import { Paragraph, Title, Card, Divider, List, Text } from 'react-native-paper'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'

function IntegrationDetailScreen({ route }) {
  const { doRequest } = useNetwork({})
  const [crop, setCrop] = useState(null)
  const [dataIntegration, setDataIntegration] = useState(null)
  const { t } = useContext(LanguageContext)
  const fetchData = useCallback(async (id) => {
    const response = await doRequest({ method: 'GET', url: `crops/${id}` })
    setCrop(response.data)
  }, [])

  const fetchDataSync = useCallback(async (id) => {
    const response = await doRequest({
      method: 'GET',
      url: `exporters/logs/${id}`,
    })
    const logs = response.data.map((item) => item.log)
    setDataIntegration(logs)
  }, [])

  useEffect(() => {
    fetchData(route.params.id)
    fetchDataSync(route.params.id)
  }, [])

  if (!crop) return null

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        <Card.Content>
          <Title>{t('VIEWS').INTEGRATION_DETAILS.TEXT_1}</Title>
          <Divider />
          <Title style={styles.title}>{crop.name}</Title>
          <Paragraph>
            {t('VIEWS').INTEGRATION_DETAILS.TEXT_2}: {crop.surface}{' '}
            {t('VIEWS').INTEGRATION_DETAILS.TEXT_3}
          </Paragraph>
          <Paragraph>
            {t('VIEWS').INTEGRATION_DETAILS.TEXT_4}: {crop.pay}{' '}
            {crop.unitType.key} / {t('VIEWS').INTEGRATION_DETAILS.TEXT_3}
          </Paragraph>
        </Card.Content>
      </Card>
      <Card style={[styles.card, { flex: 1 }]}>
        <Card.Content style={[styles.cardContent, { flex: 1 }]}>
          <Title>{t('VIEWS').INTEGRATION_DETAILS.TEXT_5}</Title>
          <Divider style={{ marginBottom: 8 }} />
          <ScrollView>
            {dataIntegration &&
              dataIntegration.map((item, key) => (
                <React.Fragment key={key}>
                  {item.lots &&
                    item.lots.map((el, key) => (
                      <Fragment key={key}>
                        <List.Item
                          title={el.name}
                          description={
                            <Text>
                              <Text style={{ paddingRight: 5 }}>
                                {el.fullyProcessed
                                  ? t('VIEWS').INTEGRATION_DETAILS.TEXT_6
                                  : t('VIEWS').INTEGRATION_DETAILS.TEXT_7}
                              </Text>
                              <Text
                                style={{ marginBottom: 4 }}
                              >{`${el.description}`}</Text>
                            </Text>
                          }
                        />
                      </Fragment>
                    ))}
                </React.Fragment>
              ))}
          </ScrollView>
        </Card.Content>
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    marginTop: 8,
    fontSize: 16,
  },
  cardContent: {
    padding: 0,
  },
  card: {
    padding: 0,
    margin: 16,
    marginTop: 0,
    marginBottom: 8,
  },
  container: {
    flex: 1,
    paddingVertical: 16,
  },
})

IntegrationDetailScreen.propTypes = {
  route: PropTypes.object,
}

export default IntegrationDetailScreen
