import React from 'react'
import { StyleSheet } from 'react-native'
import HighlightText from '@sanar/react-native-highlight-text'

const BoldText = ({ text, searchWord, style = {} }) => {
  return (
    <HighlightText
      highlightStyle={styles.boldStyle}
      searchWords={[searchWord]}
      textToHighlight={text}
      style={style}
    />
  )
}

const styles = StyleSheet.create({
  boldStyle: { fontWeight: 'bold' },
})

export default BoldText
