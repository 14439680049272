import { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { getUrlDrawFields } from '../utils/getUrlDrawFields'

export const useMakeUrl = () => {
  const [urlWebView, setUrlWebView] = useState(null)
  const route = useRoute()
  useEffect(() => {
    getUrl()
  }, [])

  const typesRender = {
    farm: {
      renderUrl: getUrlDrawFields.getUrlDetailFarm,
    },
    field: {
      renderUrl: getUrlDrawFields.getUrlDetailField,
    },
  }

  const getUrl = async () => {
    if (!route.params) {
      return
    }
    const { type, uuid } = route.params
    const tySelected = typesRender[type]
    const resultUrl = await tySelected.renderUrl(uuid)
    setUrlWebView(resultUrl)
  }

  return { urlWebView }
}
