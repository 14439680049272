import React, { useContext } from 'react'
import { View, Text, StyleSheet, Image, TextInput } from 'react-native'
import PropTypes from 'prop-types'
import { round } from 'lodash'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'

const LotsSurfaceDetails = ({
  id,
  name,
  provinceName,
  imageUrl,
  surface,
  areaUnit,
  readOnly,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <>
      <View key={id}>
        <View style={styles.contentDescription}>
          <View style={styles.contentDescriptionTitle}>
            <View>
              <CommonIcon name={'CHECK'} size={24} color={'#4CAF50'} />
            </View>

            <View>
              <Text style={styles.descriptionTitle}>{name}</Text>
              <Text style={styles.descriptionSubTitle}>{provinceName}</Text>
            </View>
          </View>
          <View style={styles.contentDescriptionImage}>
            <Image source={{ uri: imageUrl }} style={styles.image} />
          </View>
        </View>

        <View
          style={[styles.contentDescription, styles.contentDescriptionSurface]}
        >
          <View style={styles.contentDescriptionTitle}>
            <View>
              <Text style={styles.descriptionTitleSurface}>
                {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_2}
              </Text>
            </View>
          </View>
          <View style={styles.contentDescriptionSubTitle}>
            <View>
              <Text style={styles.descriptionTitleSurface}>
                {round(surface, 2)} {areaUnit}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.contentSurfaceRealize}>
          <View>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_4}
            </Text>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_9}
            </Text>
          </View>
          <View style={styles.contentDescriptionImage}>
            <TextInput
              style={styles.input}
              value={`${round(surface, 2).toString()} ${areaUnit}`}
              keyboardType='numeric'
              editable={!readOnly}
            />
          </View>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
  },
  contentDescription: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  contentDescriptionSurface: {
    marginLeft: 20,
  },
  contentDescriptionTitle: {
    flexDirection: 'row',
  },
  descriptionTitle: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 10,
  },
  descriptionSubTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
  },
  contentDescriptionImage: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  contentDescriptionSubTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  descriptionTitleSurface: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
    marginBottom: 10,
  },

  image: {
    height: 56,
    width: 100,
  },
  contentSurfaceRealize: {
    flexDirection: 'row',
    marginLeft: 20,
    marginBottom: 10,
  },
  surfaceRealizeTitle: {
    fontSize: 16,
    fontWeight: '400',
  },
  input: {
    width: 105,
    padding: 8,
    backgroundColor: '#EEEEEE',
    fontSize: 16,
    fontWeight: '700',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    padding: 10,
    marginRight: 4,
    marginLeft: 4,
  },
})

LotsSurfaceDetails.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  provinceName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  surface: PropTypes.number.isRequired,
  areaUnit: PropTypes.string.isRequired,
}

export default LotsSurfaceDetails
