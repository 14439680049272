import { Field, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Dimensions, Text } from 'react-native'
import { ActivityIndicator, Avatar } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import useValidatePin from '@hooks/useValidatePin'
import Input from '@components/common/Input'
import ButtonCustom from '@components/common/ButtonCustom'
import { AuthContext } from '@contextState/auth'
import SnackbarCustom from '@components/common/SnackbarCustom'
import UploadInput from '@components/common/UploadInput'
import Layout from '@components/common/Layout'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import * as Yup from 'yup'
import { gray } from '@styles/palette'
import { useIsFocused } from '@react-navigation/native'

const width = Dimensions.get('window').width

function ProfileScreen() {
  const isFocused = useIsFocused()

  const { user, handleUser } = useContext(AuthContext)
  const { doRequest } = useNetwork()
  const { persitPinAuth, getPinDecipher } = useValidatePin()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    pin: '',
  })
  const { t } = useContext(LanguageContext)
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    firstName: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    lastName: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    phone: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    pin: Yup.string()
      .matches(/^\d+$/, t('ERRORS.FORM_ERRORS.IS_NUMBER'))
      .test(
        'len',
        t('ERRORS.FORM_ERRORS.LEN_CHARACTERS', { quantity: 4 }),
        (value) => (value ? value.toString().length === 4 : true)
      ),
  })

  useEffect(() => {
    if (user && isFocused) {
      updateInitialValues(user)
    }
  }, [user])

  async function handleSubmit(values, { setSubmitting, setFieldValue }) {
    const response = await doRequest({
      method: 'PUT',
      url: `users/${user._id}`,
      data: values,
    })

    if (values.pin && values.pin !== getPinDecipher()) {
      persitPinAuth(values.pin)
    }

    handleUser(response.data, false)
    setFieldValue('pin', '')

    setSubmitting(false)
    handleSnackbar()
  }

  const updateInitialValues = (user) => {
    if (user) {
      const { firstName, lastName, phone, email } = user
      setInitialValues({ firstName, lastName, phone, email, pin: '' })
    }
  }

  function handleSnackbar() {
    setVisible(!visible)
  }

  async function handleFile({ file }) {
    setIsLoading(true)

    const formData = new FormData()

    formData.append('file', file)

    try {
      const response = await doRequest({
        method: 'POST',
        url: `profile/${user._id}/image`,
        data: formData,
      })

      handleUser(response.data, false)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout>
      <View style={styles.container}>
        <View style={styles.avatarContainer}>
          {user.avatarPath ? (
            <Avatar.Image
              size={85}
              source={{
                uri: user.avatarPath,
              }}
            />
          ) : (
            <Avatar.Icon size={85} icon='shape' style={styles.avatar} />
          )}

          <UploadInput
            style={styles.iconAvatarButton}
            handleFile={handleFile}
            accept={`${ALLOW_IMAGES_FORMAT}`}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <CommonIcon name='CAMERA' size={25} />
            )}
          </UploadInput>
        </View>
        <View style={styles.formContainer}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit, isValid, setFieldTouched }) => (
              <>
                <Field
                  name='email'
                  component={Input}
                  disabled={true}
                  inputProps={{
                    id: 'email',
                    disabled: true,
                    label: t('VIEWS').PROFILE.FIELD_1.LABEL,
                    placeholder: t('VIEWS').PROFILE.FIELD_1.PLACEHOLDER,
                  }}
                />
                <Field
                  name='firstName'
                  component={Input}
                  inputProps={{
                    id: 'firstName',
                    label: t('VIEWS').PROFILE.FIELD_2.LABEL,
                    placeholder: t('VIEWS').PROFILE.FIELD_2.PLACEHOLDER,
                  }}
                />
                <Field
                  name='lastName'
                  component={Input}
                  inputProps={{
                    id: 'lastName',
                    label: t('VIEWS').PROFILE.FIELD_3.LABEL,
                    placeholder: t('VIEWS').PROFILE.FIELD_3.PLACEHOLDER,
                  }}
                />
                <Field
                  name='phone'
                  component={Input}
                  inputProps={{
                    id: 'phone',
                    label: t('VIEWS').PROFILE.FIELD_4.LABEL,
                    placeholder: t('VIEWS').PROFILE.FIELD_4.PLACEHOLDER,
                  }}
                />
                <View style={styles.pinContainerTitleAndSubTitle}>
                  <Text style={styles.pinTitle}>
                    {t('VIEWS').PROFILE.FIELD_5.TITLE}
                  </Text>
                  <Text style={styles.pinSubTitle}>
                    {t('VIEWS').PROFILE.FIELD_5.SUBTITLE}
                  </Text>
                </View>
                <Field
                  name='pin'
                  component={Input}
                  helpText={t('VIEWS').PROFILE.FIELD_5.HELPTEXT}
                  inputProps={{
                    id: 'pin',
                    label: t('VIEWS').PROFILE.FIELD_5.LABEL,
                    placeholder: t('VIEWS').PROFILE.FIELD_5.PLACEHOLDER,
                    returnKeyType: 'done',
                    keyboardType: 'numeric',
                    onFocus: () => {
                      setFieldTouched('pin')
                    },
                  }}
                />
                <ButtonCustom
                  isLoading={isSubmitting}
                  onPress={handleSubmit}
                  mode='contained'
                  type='submit'
                  labelStyle={styles.label}
                  style={styles.button}
                  disabled={!isValid}
                >
                  {t('VIEWS').PROFILE.TEXT_1}
                </ButtonCustom>
              </>
            )}
          </Formik>
        </View>
        <SnackbarCustom
          visible={visible}
          onToggle={handleSnackbar}
          duration={1500}
        >
          {t('VIEWS').PROFILE.TEXT_2}
        </SnackbarCustom>
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    padding: 16,
  },
  label: {
    color: 'white',
  },
  container: {
    flex: 1,
    paddingTop: 16,
  },
  avatarContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  avatar: {
    backgroundColor: '#ccc',
  },
  iconAvatarButton: {
    position: 'absolute',
    bottom: -6,
    right: width / 2.5,
  },
  pinContainerTitleAndSubTitle: {
    paddingLeft: 5,
    paddingBottom: 5,
  },
  pinTitle: {
    fontSize: 16,

    fontWeight: '500',
    paddingBottom: 5,
    lineHeight: 15,
    letterSpacing: 0.15,
  },
  pinSubTitle: {
    fontWeight: '400',
    fontSize: 14,
    color: gray,
  },
})

export default ProfileScreen
