import React, { useContext, useState, useRef } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { Menu, MenuItem } from 'react-native-material-menu'
import orderSeed from './constants/order'

const SupplyOrderBy = ({ setSearchCode, selectedTab }) => {
  const { t } = useContext(LanguageContext)
  const hasEIQ = selectedTab === 'fitoterapicos' || selectedTab === 'insumos'
  const [visibleMenu, setVisibleMenu] = useState(false)
  const menu = useRef()
  const [searchMode, setSearchMode] = useState(
    orderSeed.defaultMode.nameSearch(t)
  )
  const openMenu = () => setVisibleMenu(true)
  const closeMenu = () => setVisibleMenu(false)

  const handlePress = (mode) => {
    setSearchMode(orderSeed[mode]?.nameSearch(t))
    setSearchCode(mode)
    closeMenu()
  }

  return (
    <>
      <TouchableOpacity style={styles.menu} onPress={openMenu}>
        <View style={styles.orderCard}>
          <Text style={styles.orderSeedByText}>
            {t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_1}
            <Text style={styles.searchMode}>{searchMode}</Text>
          </Text>
          <View style={styles.menuButton}>
            <Menu
              ref={menu}
              visible={visibleMenu}
              anchor={<CommonIcon name={'SORT'} size={35} onPress={openMenu} />}
              onRequestClose={closeMenu}
            >
              {hasEIQ && (
                <MenuItem
                  onPress={() => handlePress(orderSeed.eiq.searchOrder)}
                >
                  {orderSeed.eiq.nameSearch(t)}
                </MenuItem>
              )}
              <MenuItem
                onPress={() => handlePress(orderSeed.company.searchOrder)}
              >
                {orderSeed.company.nameSearch(t)}
              </MenuItem>
              <MenuItem onPress={() => handlePress(orderSeed.name.searchOrder)}>
                {orderSeed.name.nameSearch(t)}
              </MenuItem>
            </Menu>
          </View>
        </View>
      </TouchableOpacity>
    </>
  )
}
const styles = StyleSheet.create({
  menu: {
    paddingTop: 8,
  },
  orderCard: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
  },
  cardText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#3A3B3C',
  },
  searchMode: {
    fontWeight: 'bold',
    color: 'black',
  },
  menuButton: {
    marginLeft: 'auto',
  },
  orderSeedByText: {
    color: '#3333338A',
  },
})
SupplyOrderBy.propTypes = {
  setSearchCode: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
}
export default SupplyOrderBy
