import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { LanguageContext } from '@contextState/language'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PropTypes from 'prop-types'
import { white } from '@styles/palette'

const ChildrenModalSuccessLicence = ({
  onPressFirstButton,
  onPressSecondButton,
  numberLicense,
}) => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.contentInfo}>
      <Text style={styles.textInfo}>
        {t('VIEWS.LICENSE_SIGN.TEXT_9', { numberLicense })}
      </Text>

      <View style={styles.contentTextInfo}>
        <Text style={styles.textInfo}>{t('VIEWS').LICENSE_SIGN.TEXT_10}</Text>
      </View>

      <View style={styles.contentButtons}>
        <ButtonPrimary
          text={t('VIEWS').LICENSE_SIGN.TEXT_11}
          onPress={onPressFirstButton}
        />
        <ButtonPrimary
          marginTop={40}
          text={t('VIEWS').LICENSE_SIGN.TEXT_12}
          backgroundColor='transparent'
          colorText='#fff'
          fontSize={14}
          onPress={onPressSecondButton}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentInfo: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: white,
    fontSize: 16,
    textAlign: 'center',
  },
  contentButtons: {
    marginTop: 50,
    width: 300,
  },
})

ChildrenModalSuccessLicence.propTypes = {
  onPressFirstButton: PropTypes.func.isRequired,
  onPressSecondButton: PropTypes.func.isRequired,
  numberLicense: PropTypes.string.isRequired,
}

export default ChildrenModalSuccessLicence
