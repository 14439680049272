import moment from 'moment'
import activityTypesConstants from '@constants/activityTypes'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { formatDateMoment } from '@utils/date'

export const parseDraftDataToFormik = ({
  t,
  verificationTypes,
  unitTypes,
  agreementTypes,
  formikInitialValues,
  draft,
  subTypeActivities,
  tag,
  locale,
  unitTypesVolume,
}) => {
  const newFormikData = {}
  const newLabel = draft?.lots?.length
    ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
    : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

  const labelLots = draft.lots.length ? `${draft.lots.length} ${newLabel}` : ''

  const labelFormikLots = draft?.farm?.tag || labelLots

  newFormikData.lots = {
    value: draft.lots.map((element) => ({
      _id: element._id,
      surface: element.surface,
      tag: element.tag,
      wktFormat: element.wktFormat,
    })),
    label: labelFormikLots,
  }

  newFormikData.surface = draft.surface

  if (draft.dateHarvest) {
    newFormikData.dateHarvest = {
      value: moment(draft.dateHarvest).toDate(),
      label: moment(draft.dateHarvest).format('DD/MM/YYYY'),
    }
  }

  if (draft.dateObservation) {
    newFormikData.dateObservation = {
      value: moment(draft.dateObservation).toDate(),
      label: moment(draft.dateObservation).format('DD/MM/YYYY'),
    }
  }

  if (draft.dateEstimatedHarvest) {
    newFormikData.dateEstimatedHarvest = {
      value: moment(draft.dateEstimatedHarvest).toDate(),
      label: moment(draft.dateEstimatedHarvest).format('DD/MM/YYYY'),
    }
  }

  if (draft.verificationType) {
    newFormikData.verificationType = verificationTypes.find(
      (element) => element.value === draft.verificationType
    )
  }

  if (draft.unitType) {
    newFormikData.unitType = unitTypes.find(
      (element) => element.value === draft.unitType
    )
  }

  if (draft.typeAgreement) {
    newFormikData.typeAgreement = agreementTypes.find(
      (element) => element.value === draft.typeAgreement
    )
  }

  if (draft.observation) {
    newFormikData.observation = draft.observation
  }

  if (draft.observations) {
    newFormikData.observations = draft.observations
  }

  if (draft.pay) {
    newFormikData.pay = String(draft.pay)
  }

  if (draft?.volume) {
    newFormikData.volume = String(draft?.volume)
  }

  if (draft?.unitVolume) {
    newFormikData.unitVolume = unitTypesVolume.find(
      (element) => element.value === draft.unitVolume
    )
  }

  if (draft.signers) {
    newFormikData.signers = draft.signers
  }

  if (draft.evidences) {
    newFormikData.evidences = draft.evidences
  }

  if (draft.storages) {
    newFormikData.storages = draft.storages
  }

  if (draft.draftGroupId) {
    newFormikData.draftGroupId = draft.draftGroupId
  }

  if (draft.isRejected) {
    newFormikData.isRejected = draft.isRejected
  }

  if (draft.subTypeActivity) {
    newFormikData.subTypeActivity = subTypeActivities.find(
      (element) => element.value === draft.subTypeActivity
    )
  }

  if (draft.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(draft.dateAchievement).toDate(),
      label: moment(draft.dateAchievement).format('DD/MMM/YYYY'),
    }
  }

  if (draft.supplies) {
    newFormikData.supplies = draft.supplies
    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(draft.supplies, draft.surface)
      const range = getEIQRange(totalEiqAllSupplies)
      newFormikData.eiqSupplies = { totalEiqAllSupplies, range }
    }
  }

  if (draft.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(draft.dateAchievement).toDate(),
      label: formatDateMoment(
        draft.dateAchievement,
        'DD/MMM/YYYY',
        locale,
        true
      ),
    }
  }

  if (draft.canPlanning) {
    newFormikData.canPlanning = draft.canPlanning
  }

  if (draft.farm) {
    newFormikData.farm = draft.farm
  }

  if (draft.seedDryContent) {
    newFormikData.seedDryContent = String(draft.seedDryContent)
  }

  return {
    ...formikInitialValues,
    ...newFormikData,
  }
}
