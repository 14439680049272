import React, { useContext, useState } from 'react'
import { Linking, StyleSheet, Image, Platform, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { Appbar, Button, ActivityIndicator } from 'react-native-paper'
import { capitalize } from 'lodash'
import { useLinkTo } from '@react-navigation/native'

import { activitiesActions } from '@store/actions'
import { selectHasFilter } from '@store/selectors/activities'
import { ConnectionContext } from '@contextState/connection'
import { CropContext } from '@contextState/crop'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import useNetwork from '@utils/network'
import { CommonIcon } from '@modules/common/components'
import icons from '@constants/icons'
import { black, green500 } from '@modules/common/styles'
import { IconButton } from '@modules/common/components'
import {
  blackMediumEmphasis,
  primary500,
  Orange500,
  white,
  alertText,
} from '@styles/palette'
import { useResponsive } from '@modules/common/hooks'

const Header = ({
  navigation,
  goBack,
  title,
  drawer,
  tools,
  showFilters,
  close,
  showResetFilters,
  showResetNotificationFilters,
  download,
  showNotificationIcon = false,
  filtersScreen = '',
  showFiltersActivities = false,
  showClearOutFiltersActivities = false,
}) => {
  const dispatch = useDispatch()
  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const { data, currentCrop } = useContext(CropContext)
  const { isScreenDesktop } = useResponsive()
  const {
    filters,
    resetFilters,
    setShowDeleteModal,
    showDeleteIcon,
    disableDeleteIcon,
    showDeleteSupplyIcon,
    notificationFilters,
    resetNotificationFilters,
  } = useContext(CommonContext)
  const linkTo = useLinkTo()

  const { hasActivities } = useSelector((state) => state.activities)
  const hasFilter = useSelector(selectHasFilter())

  const [downloading, setDownloading] = useState(false)

  const { doRequest } = useNetwork()
  const route = useRoute()

  function handleBack() {
    const canGoBack = navigation.canGoBack()
    if (!canGoBack) {
      linkTo('/crops/list')
    }
    if (typeof goBack === 'boolean' || typeof close === 'boolean') {
      navigation.goBack()
    } else if (typeof goBack === 'function') {
      goBack()
    } else {
      if (goBack !== 'CropList') {
        navigation.replace(goBack, { ...route.params })
      } else {
        navigation.replace(goBack)
      }
    }
  }

  function handleOpenDrawer() {
    navigation.openDrawer()
  }

  function showChartPage() {
    navigation.navigate('CropChart')
  }

  function showFiltersScreen() {
    //hardcode
    if (route.name !== 'Activities') {
      navigation.navigate('Filters')
    } else {
      //hardcode
      alert('future functionality')
    }
  }

  function doResetFilters() {
    resetFilters('HEADER')
  }

  function deleteAchievementDraft() {
    setShowDeleteModal(true)
  }

  function deleteSupplyDetail() {
    setShowDeleteModal(true)
  }

  async function downloadPDF() {
    setDownloading(true)
    try {
      setDownloading(true)
      const response = await doRequest({
        method: 'post',
        url: `crops/${currentCrop._id}/crop-history/pdf`,
      })
      const data = response.data
      const result = await doRequest({
        method: 'GET',
        url: `crops/${data.nameFile}/pdf-history-crop/`,
      })
      if (Platform.OS === 'web') {
        window.open(`${result.data}`)
        setDownloading(false)
      } else {
        Linking.openURL(result.data)
        setDownloading(false)
      }
      setDownloading(false)
    } catch (err) {
      setDownloading(false)
      alert(t('COMPONENTS').COMMON.HEADER.TEXT_1)
      setDownloading(false)
    }
  }

  const onGoToNotifications = () => {
    navigation.navigate('NotificationList')
  }

  const onGoToFilters = (filtersScreen) => {
    navigation.navigate(filtersScreen)
  }

  const onGoToFiltersActivities = () => {
    navigation.navigate('FilterActivities')
  }

  const cleanUpFiltersActivities = () => {
    dispatch(activitiesActions.removeFilters())
    navigation.goBack()
  }

  return (
    <Appbar.Header style={styles.container}>
      {drawer && !isScreenDesktop && (
        <CommonIcon
          size={24}
          color={black}
          name={'MENU'}
          onPress={handleOpenDrawer}
        />
      )}

      {close && (
        <CommonIcon
          size={24}
          color={black}
          name={'X'}
          onPress={handleBack}
          disabled={!isConnected}
        />
      )}

      {goBack === 'SignIn' && (
        <>
          <Image source={icons.logo} style={styles.logo} />
          <Appbar.Content
            title={title || 'UCROP.IT'}
            titleStyle={styles.title}
          />
        </>
      )}

      {goBack && (
        <CommonIcon
          size={24}
          color={black}
          name={'ARROW-LEFT'}
          onPress={handleBack}
          disabled={!isConnected}
        />
      )}

      {!goBack && !drawer && !close && !download && (
        <Image source={icons.logo} style={styles.logo} />
      )}

      {goBack !== 'SignIn' && <Appbar.Content title={title || 'UCROP.IT'} />}

      {data.length > 0 && tools && (
        <Button disabled={!isConnected} onPress={showChartPage}>
          {t('COMPONENTS').COMMON.HEADER.TEXT_2}
        </Button>
      )}

      {(data.length > 0 || filters.hasAnyFilterActive) && showFilters && (
        <CommonIcon
          size={24}
          color={filters.hasAnyFilterActive ? green500 : black}
          name={filters.hasAnyFilterActive ? 'FILTER-FILLED' : 'FILTER'}
          onPress={showFiltersScreen}
          disabled={!isConnected}
        />
      )}

      {Boolean(filtersScreen) && (
        <CommonIcon
          size={24}
          color={notificationFilters.hasAnyFilterActive ? green500 : black}
          name={
            notificationFilters.hasAnyFilterActive ? 'FILTER-FILLED' : 'FILTER'
          }
          onPress={() => onGoToFilters(filtersScreen)}
          disabled={!isConnected}
        />
      )}

      {showResetFilters && (
        <Button
          disabled={!isConnected}
          onPress={doResetFilters}
          labelStyle={[
            {
              color: filters.hasAnyFilterActive ? green500 : black,
            },
            styles.iconResetFilter,
          ]}
        >
          {capitalize(t('COMPONENTS').COMMON.HEADER.TEXT_3)}
        </Button>
      )}

      {showResetNotificationFilters && (
        <Button
          disabled={
            !(
              notificationFilters.isDirty ||
              notificationFilters.hasAnyFilterActive
            )
          }
          onPress={resetNotificationFilters}
          labelStyle={[
            {
              color:
                notificationFilters.isDirty ||
                notificationFilters.hasAnyFilterActive
                  ? green500
                  : black,
            },
            styles.iconResetFilter,
          ]}
        >
          {capitalize(t('COMPONENTS').COMMON.HEADER.TEXT_3)}
        </Button>
      )}

      {download && (
        <CommonIcon
          size={24}
          color={!downloading ? '#757575' : black}
          name={'FILE-DOWNLOAD'}
          onPress={downloadPDF}
          disabled={downloading}
        />
      )}

      {downloading && (
        <ActivityIndicator
          size={16}
          animating={true}
          style={styles.activityIndicator}
        />
      )}

      {showNotificationIcon && (
        <IconButton onPress={onGoToNotifications} disabled={!isConnected}>
          <View style={styles.notificationIconContainer}>
            <CommonIcon name='NOTIFICATIONS' size={24} />

            <View style={styles.notificationStatusContainer}>
              <CommonIcon name='CIRCLE-FILLED' size={8} color={alertText} />
            </View>
          </View>
        </IconButton>
      )}

      {showDeleteIcon && (
        <CommonIcon
          name={'DELETE'}
          size={24}
          color={black}
          onPress={deleteAchievementDraft}
          disabled={!isConnected || disableDeleteIcon}
        />
      )}

      {showDeleteSupplyIcon && (
        <CommonIcon
          name={'DELETE'}
          size={24}
          color={Orange500}
          onPress={deleteSupplyDetail}
        />
      )}

      {showFiltersActivities && (
        <CommonIcon
          size={24}
          color={hasFilter ? green500 : black}
          name={hasFilter ? 'FILTER-FILLED' : 'FILTER'}
          onPress={onGoToFiltersActivities}
          disabled={!isConnected || (!hasFilter && !hasActivities)}
        />
      )}

      {showClearOutFiltersActivities && (
        <Button
          disabled={!hasFilter}
          onPress={cleanUpFiltersActivities}
          labelStyle={[
            {
              color: hasFilter ? green500 : black,
            },
            styles.iconResetFilter,
          ]}
        >
          {capitalize(t('COMPONENTS').COMMON.HEADER.TEXT_3)}
        </Button>
      )}
    </Appbar.Header>
  )
}

const styles = StyleSheet.create({
  container: {
    elevation: 0,
    backgroundColor: 'white',
    paddingHorizontal: 16,
  },
  logo: {
    resizeMode: 'contain',
    marginLeft: 5,
    width: 40,
    height: 40,
  },
  activityIndicator: {
    color: green500,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
    color: '#333333',
  },
  deleteSupplyIcon: {
    color: '#FF7043',
  },
  iconResetFilter: {
    textTransform: 'capitalize',
  },
  notificationIconContainer: {
    position: 'relative',
  },
  notificationStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 10,
    height: 10,
    backgroundColor: white,
    position: 'absolute',
    top: 2,
    right: 2,
    borderRadius: 50,
  },
  notificationStatus: {
    fontSize: 8,
    color: alertText,
  },
})

export default Header
