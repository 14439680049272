import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, white, grey300, grey500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

export const LotsAddButton = ({ isValid, onGoFarmLotsAdd }) => {
  const { t } = useContext(LanguageContext)

  const addLostStyle = () => (!isValid ? styles.buttonDisabled : styles.button)

  const addLostStyleTitle = () =>
    !isValid ? styles.textButtonDisabled : styles.textButton

  return (
    <View>
      <ButtonCustom
        disabled={!isValid}
        isLoading={false}
        onPress={onGoFarmLotsAdd}
        styles={addLostStyle()}
        labelStyle={addLostStyleTitle()}
      >
        {t('VIEWS.FARM_CREATE.COMPONENTS.LOST_ADD_BUTTON.TEXT_1')}
      </ButtonCustom>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    textTransform: 'uppercase',
    backgroundColor: primary500,
    marginVertical: 5,
  },
  textButton: {
    color: white,
  },
  buttonDisabled: {
    textTransform: 'uppercase',
    backgroundColor: grey300,
    marginVertical: 5,
  },
  textButtonDisabled: {
    color: grey500,
  },
})

LotsAddButton.propTypes = {
  isValid: PropTypes.bool.isRequired,
  onGoFarmLotsAdd: PropTypes.func.isRequired,
}
