import { Card, Avatar } from 'react-native-paper'
import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { CommonContext } from '@contextState/common'
import icons from '@constants/icons'
import PropTypes from 'prop-types'
import CardContactActions from '@components/common/CardContactActions'
import { LanguageContext } from '@contextState/language'

function CardContact({ crop }) {
  const { roleTypes } = useContext(CommonContext)
  const { t } = useContext(LanguageContext)

  function userRole(roleCode) {
    let result = roleTypes.find((role) => role.value === roleCode)
    result = result?.label
    return result
  }

  function showAvatar() {
    return <Avatar.Image size={40} source={icons.logo} />
  }

  if (!crop) return null

  return (
    <>
      {crop.company.contacts?.map((contact, key) => (
        <React.Fragment key={key}>
          <Card style={styles.containerCard}>
            <Card.Title
              style={styles.stylesCard}
              title={`${crop.commercialContact.firstName} ${crop.commercialContact.lastName}`}
              titleStyle={styles.textTitle}
              subtitleNumberOfLines={2}
              subtitle={`${userRole(crop.commercialContact.type)} ${'\n'}${
                t('COMPONENTS').COMMON.CARD_CONTACT.TEXT_1
              } ${crop.commercialContact.theoriticalPotential} ${
                t('COMPONENTS').COMMON.CARD_CONTACT.TEXT_2
              }`}
              subtitleStyle={styles.subtitle}
              left={showAvatar}
              leftStyle={styles.leftStyle}
            />
            <CardContactActions item={crop.commercialContact} />
          </Card>
        </React.Fragment>
      ))}
    </>
  )
}

const styles = StyleSheet.create({
  iconStyle: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
    color: '#263238',
  },
  textTitle: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    marginTop: 4,
    color: '#263238',
    transform: [{ translateY: 8 }],
  },
  leftStyle: {
    marginTop: 16,
  },
  containerCard: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 4,
    backgroundColor: '#F8F8F8',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stylesCard: {
    alignItems: 'flex-start',
    paddingTop: 8,
  },
})

CardContact.propTypes = {
  crop: PropTypes.object,
  user: PropTypes.object,
  props: PropTypes.object,
}

export default CardContact
