import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'

import { CompanyDetails } from '@common/components'
import { useCompanyInfo } from '@modules/common/hooks'

import { OrderMenu, Alert } from '..'
import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'

export const HeaderCropsList = ({
  show,
  handleOrderChange,
  orderMenuOptions,
  orderSelectedOption,
}) => {
  const { isConnected } = useContext(ConnectionContext)
  const { t } = useContext(LanguageContext)

  const { identifier, companyName, companyAllow } = useCompanyInfo()

  if (!show) return null

  const titleAlert = isConnected
    ? undefined
    : t('COMMON.OFFLINE_EMPTY.NO_INTERNET_CONNECTION')
  const messageAlert = isConnected
    ? t('VIEWS').CROP_LIST.COMPONENTS.ALERT.TEXT_1
    : t('COMMON').OFFLINE_EMPTY.AVAILABLE_CROPS_ON_OFFLINE_MODE

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />

      {companyAllow && <Alert title={titleAlert} message={messageAlert} />}

      {companyAllow && (
        <OrderMenu
          handleOrderChange={handleOrderChange}
          orderMenuOptions={orderMenuOptions}
          orderSelectedOption={orderSelectedOption}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
})

HeaderCropsList.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  orderMenuOptions: PropTypes.array.isRequired,
  orderSelectedOption: PropTypes.object,
}
