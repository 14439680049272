export default {
  TITLE: 'Learn more about the activities',
  DRAFT: {
    TITLE: 'Draft',
    DESCRIPTION:
      'These are the activities that have not been uploaded or have been modified by two or more collaborators and need to confirm a version.',
  },
  PLANNED: {
    TITLE: 'Planned',
    DESCRIPTION:
      'These are the activities that were planned and are pending to be performed.',
  },
  PENDING_VALIDATION: {
    TITLE: 'Pending validation',
    DESCRIPTION:
      'These are the activities that were performed but have pending validations.',
  },
  COMPLETED: {
    TITLE: 'Completed',
    DESCRIPTION:
      'These are the activities that have been performed and validated.',
  },
}
