import { useState, useContext, useCallback } from 'react'
import Constants from 'expo-constants'
import PropTypes from 'prop-types'
import { useNavigation } from '@react-navigation/native'
import { Platform } from 'react-native'

import useNetwork from '@utils/network'

import { LanguageContext } from '@contextState/language'
import { useFocusEffect } from '@react-navigation/native'
import { formatDateMoment } from '@utils/date'
import { TYPE_RENDER_WEB_VIEW } from '@modules/webView/constants/typesRendes'

const apiUrl = Constants.manifest.extra.EXPO_HOST

export const useDetailsLotFarm = (lot) => {
  const navigation = useNavigation()
  const { doRequest } = useNetwork()
  const { t, i18n } = useContext(LanguageContext)
  const [detailsLotFarm, setDetailsLotFarm] = useState({})
  const [cropType, setCropType] = useState(t('VIEWS.FARM_LOT_DETAILS.TEXT_5'))
  const [campaign, setCampaign] = useState(t('VIEWS.FARM_LOT_DETAILS.TEXT_6'))
  const [isLoading, setIsLoading] = useState(true)

  useFocusEffect(
    useCallback(() => {
      getDetailsLotFarm()
    }, [])
  )

  const getDetailsLotFarm = async () => {
    try {
      const url = `fields/${lot}`

      const response = await doRequest({
        method: 'GET',
        url,
        displayAlert: false,
      })

      const {
        cropHistory,
        farmName,
        name,
        province,
        department,
        surface,
        urlMap,
        available,
        isRemovable,
        areaUnit,
        farmUuid,
      } = response.data

      if (cropHistory.length && !available) {
        setCropType(cropHistory[0].cropType?.name)
        const campaign = `${formatDateMoment(
          cropHistory[0].startDate,
          'DD/MMM/YYYY',
          i18n.locale,
          true
        )} ${t('VIEWS.FARM_LOT_DETAILS.TO_DATE')} ${formatDateMoment(
          cropHistory[0].endDate,
          'DD/MMM/YYYY',
          i18n.locale,
          true
        )}`

        setCampaign(campaign)
      }

      const image =
        urlMap.indexOf('/uploads/map-static') === -1
          ? urlMap
          : `${apiUrl}${urlMap}`

      const farmDetailsLot = {
        cropHistory,
        farmName,
        name,
        province,
        department,
        surface,
        urlMap: image,
        isRemovable,
        areaUnit,
        farmUuid,
      }

      setDetailsLotFarm(farmDetailsLot)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const onNavigateDetailField = () => {
    if (Platform.OS !== 'web') {
      return
    }
    navigation.navigate('WebViewResponsePage', {
      type: TYPE_RENDER_WEB_VIEW.FIELDS,
      uuid: lot,
      title: `${farmName} - ${name}`,
    })
  }

  const {
    cropHistory,
    farmName,
    name,
    province,
    department,
    surface,
    urlMap,
    isRemovable,
    areaUnit,
    farmUuid,
  } = detailsLotFarm

  return {
    cropHistory,
    farmName,
    name,
    province,
    department,
    surface,
    areaUnit,
    urlMap,
    cropType,
    campaign,
    isLoading,
    isRemovable,
    farmUuid,
    onNavigateDetailField,
  }
}

useDetailsLotFarm.propTypes = {
  lot: PropTypes.string.isRequired,
}
