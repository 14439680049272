import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { CommonContext } from '@contextState/common'
import { SelectorContext } from '@contextState/selectors'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { CropContext } from '@contextState/crop'
import { unitMeasureSystem as UnitMeasureSystem } from '@utils/constants'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import Screen from './Screen'
import { truncateSurface } from '@common/utils'

const SupplyDetail = ({ route, navigation }) => {
  const { id, key, supplySelected, item } = useRef(route.params).current

  const {
    showDeleteModal,
    setShowDeleteModal,
    setShowDeleteSupplyIcon,
    setShowDeleteIcon,
  } = useContext(CommonContext)
  const { config } = useContext(AuthContext)
  const { supplySelector, setSupplySelector } = useContext(SelectorContext)
  const { i18n } = useContext(LanguageContext)
  const { currentCrop } = useContext(CropContext)

  const [supply, setSupply] = useState(null)
  const [unitSurface, setUnitSurface] = useState('ha')

  const code =
    supply?.typeSupply?.code ||
    supply?.supply?.supplyType ||
    supply?.typeId?.code
  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    if (supplySelected || item || id) {
      const {
        companySelected: { unitMeasureSystem },
      } = config

      if (unitMeasureSystem === UnitMeasureSystem.IMPERIAL) {
        setUnitSurface('ac')
      }
      setSupply(supplySelected ?? item ?? id)
    } else {
      getSupply()
    }
  }, [id, supplySelected, item])

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line no-prototype-builtins
      if (route.params.hasOwnProperty('readOnly')) {
        setShowDeleteSupplyIcon(!route.params.readOnly)
      } else {
        setShowDeleteSupplyIcon(true)
      }

      setShowDeleteIcon(false)

      return () => {
        setShowDeleteSupplyIcon(false)

        return true
      }
    }, [])
  )

  const getSupply = useCallback(() => {
    if (id && supplySelector.length) {
      const supply = supplySelector.find(
        (supply, index) => supply._id === id && key === index
      )

      if (supply) {
        setSupply(supply)
      }
    }
  }, [])

  const getComposition = (item) => {
    const lang = i18n.locale
    const activeIngredients = item.activeIngredients
      ? item.activeIngredients
      : item.supply
      ? item.supply.activeIngredients
      : []

    return activeIngredients?.length
      ? activeIngredients
          .map((element) => element.activeIngredient.name[lang].toUpperCase())
          .join('+')
      : ''
  }

  const getEiq = (item) => {
    let eiq = undefined

    if (item?.eiqTotal) {
      eiq = item.eiqTotal
    } else if (item?.supply?.eiqTotal) {
      eiq = item?.supply?.eiqTotal
    }
    return eiq >= 0 ? round(Number(eiq), 2) : ''
  }

  const getSurface = (item) => {
    const { surface } = route.params
    let supplySurface = 0
    if (surface) {
      supplySurface = surface
    } else if (item.surface) {
      supplySurface = item.surface
    } else if (item.surfaceDetail) {
      supplySurface = item.surfaceDetail
    } else if (item?.supply?.surface) {
      supplySurface = item?.supply?.surface
    }

    return truncateSurface(Number(supplySurface))
  }

  const getQuantity = (item) => {
    const { surface } = route.params

    let supplySurface = 0

    if (surface) {
      supplySurface = surface
    } else if (item.surface) {
      supplySurface = item.surface
    } else if (item.surfaceDetail) {
      supplySurface = item.surfaceDetail
    } else if (item?.supply?.surface) {
      supplySurface = item?.supply?.surface
    }

    const quantity = item.total / supplySurface || item.quantity

    return round(Number(quantity), 6)
  }

  const getClassToxic = (item) => {
    let classToxic = ''

    if (item?.classToxic) {
      classToxic = item?.classToxic
    } else if (item.supply?.classToxic) {
      classToxic = item.supply?.classToxic
    }

    return classToxic
  }

  const closeModalDelete = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteSupply = (key) => {
    const result = supplySelector.filter((el, index) => key !== index)
    setSupplySelector(result)
    closeModalDelete()
    if (route.params.callBack) {
      route.params.callBack(key)
      navigation.goBack()
      return
    }
    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.supplies = result
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.goBack()
    }
  }

  return (
    <Screen
      supply={supply}
      route={route}
      showDeleteModal={showDeleteModal}
      closeModalDelete={closeModalDelete}
      handleDeleteSupply={handleDeleteSupply}
      getSurface={getSurface}
      getComposition={getComposition}
      getEiq={getEiq}
      getClassToxic={getClassToxic}
      getQuantity={getQuantity}
      areaUnitCrop={currentCrop?.areaUnit}
      unitSurface={unitSurface}
      code={code}
    />
  )
}

SupplyDetail.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default SupplyDetail
