import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text } from 'react-native'

export const Info = ({
  pay,
  unitType,
  unitTypeKey,
  volume,
  areaUnitVolume,
}) => {
  return (
    <Text style={styles.contentText}>
      {pay ? `${pay} ${unitTypeKey} (${unitType})` : '0'}
      {` - ${volume ?? '0'} ${areaUnitVolume}`}
    </Text>
  )
}

const styles = StyleSheet.create({
  contentText: {
    fontSize: 15,
    marginTop: 5,
    marginBottom: 10,
  },
})

Info.prototype = {
  pay: PropTypes.number.isRequired,
  unitType: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  areaUnitVolume: PropTypes.string.isRequired,
}
