import React from 'react'
import {
  View,
  Text,
  Modal as ModalMobile,
  StyleSheet,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import ModalWeb from 'modal-react-native-web'

import { CommonIcon } from '@modules/common/components'
import { white, primary500Disabled, greenOpacity50 } from '@styles/palette'

export const SuccessPinModal = ({
  isModalVisible,
  children,
  title = '¡Licencia Firmada!',
  toggleModal,
}) => {
  const Modal = Platform.OS === 'web' ? ModalWeb : ModalMobile

  return (
    <Modal
      animationType='slide'
      transparent={false}
      visible={isModalVisible}
      onBackButtonPress={toggleModal}
      ariaHideApp={false}
    >
      <View style={styles.contentPin}>
        <View style={styles.contentText}>
          <Text style={styles.textStyle}>{title}</Text>
        </View>
        <View style={styles.contentIcon}>
          <View style={styles.viewIcon}>
            <CommonIcon name={'CHECK'} size={45} color={white} />
          </View>
        </View>
        {children}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  contentPin: {
    flex: 1,
    backgroundColor: primary500Disabled,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  contentText: {
    marginBottom: 61,
    alignItems: 'center',
  },
  textStyle: {
    color: white,
    fontSize: 34,
    fontWeight: '500',
  },
  contentIcon: {
    backgroundColor: greenOpacity50,
    width: 137,
    height: 137,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewIcon: {
    width: 97,
    height: 97,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: greenOpacity50,
    borderRadius: 100,
  },
  contentInfo: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: white,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: 0.15,
  },
  contentButtons: {
    marginTop: 61,
    width: 300,
  },
})

SuccessPinModal.propTypes = {
  title: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  children: PropTypes.element,
  toggleModal: PropTypes.func.isRequired,
}
