import { useEffect, useState } from 'react'
import { languages } from '@constants'

const filesTerms = {
  es: `https://ucrop-terms-conditions-public.s3.amazonaws.com/terms_and_conditions_es_2022_09_22.md?timestamp=${Date.now()}`,
  en: `https://ucrop-terms-conditions-public.s3.amazonaws.com/terms_and_conditions_en_2022_09_22.md?timestamp=${Date.now()}`,
  pt: `https://ucrop-terms-conditions-public.s3.amazonaws.com/terms_and_conditions_pt_2022_09_22.md?timestamp=${Date.now()}`,
}
export const useFetchTerms = ({ locale }) => {
  const [terms, setTerms] = useState(null)
  useEffect(() => {
    fetchTermsInModal()
  }, [locale])

  const fetchTerms = async (locale) => {
    try {
      const language = languages.find((l) => locale.indexOf(l.key) !== -1)
      const readmePath = filesTerms[language.key]
      const response = await fetch(readmePath)
      const responseText = await response.text()
      return responseText
    } catch (error) {
      console.error('error fetch terms')
    }
  }

  const fetchTermsInModal = async () => {
    const resultTerms = await fetchTerms(locale)
    setTerms(resultTerms)
  }
  return { terms }
}
