import React, { useContext } from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
} from 'react-native'
import { round } from 'lodash'
import PropTypes from 'prop-types'

import {
  white,
  gray12,
  grayInput,
  primary500,
  blackMediumEmphasis,
  blueGrey50,
  blueGrey700,
  blueGray100,
  orange50,
} from '@styles/palette'
import { AuthContext } from '@contextState/auth'
import AccordionItem from '@components/common/v2/AccordionItem'
import { formatDateMoment } from '@utils/date'
import { InfoDisplayActivity } from '../'
import ChipsComponent from '@components/common/v2/ChipsComponent'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import EiqCard from '@components/common/v1/EiqCard'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import { CommonIcon } from '@modules/common/components'

import { truncateSurface } from '@common/utils/truncateSurface'

export const CardAchievement = ({
  achievement,
  t,
  locale,
  onPressAchievement,
  tag,
  isConnected,
  areaUnit,
  subTypeActivities,
  haveEiq,
  activityTypes,
}) => {
  const { user } = useContext(AuthContext)

  const {
    createdAt,
    version,
    hasMoreVersion,
    supplies,
    surface,
    establishments,
    lots,
    activityType,
  } = achievement
  const getBackgroundColor = () => {
    if (hasMoreVersion) {
      return { backgroundColor: orange50 }
    }
    if (haveEiq) {
      return { backgroundColor: blueGray100 }
    } else {
      return {}
    }
  }

  const activityTypeLabel =
    activityType?.codeLabel ?? activityTypes[tag].name(t)

  const getTimeComplete = () =>
    `${formatDateMoment(createdAt, 'DD/MMM/YYYY', locale, true)} - ${String(
      formatDateMoment(createdAt, 'hh:mm a', locale)
    ).toUpperCase()}`

  const valueSubtype = subTypeActivities.find(
    (subType) => subType?.value === achievement?.subTypeActivity
  )

  const getTextDraft = () =>
    hasMoreVersion
      ? `${t(
          'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_7'
        )} ${version}`
      : t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_6')

  const isValidatedByCurrentUser = Boolean(
    achievement.signers.find(
      ({ userId, signed }) => userId === user._id && signed === true
    )
  )

  const ContentHeader = ({ arrowAngle, onPress, isOpened }) => {
    const styleClosed = !isOpened ? styles.stylesClosed : {}
    return (
      <View>
        <View style={[styles.contentFlexRow, styles.borderBottom, styleClosed]}>
          <View>
            <Text style={styles.textDate}>{getTimeComplete()}</Text>
            <Text style={[styles.normalText, styles.textLots]}>
              {`${establishments?.length} ${t(
                establishments?.length > 1
                  ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_2'
                  : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_1'
              )}`}
              {' - '}
              {`${lots?.length} ${t(
                lots?.length > 1
                  ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_4'
                  : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_3'
              )}`}
            </Text>
          </View>
          <TouchableOpacity onPress={onPress}>
            <View>
              <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
                <CommonIcon name='CHEVRON-DOWN' size={25} color={grayInput} />
              </Animated.View>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.contentInfoAchievement}>
          <Text style={styles.normalText}>
            {t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_1',
              { surface: truncateSurface(surface), areaUnit }
            )}
          </Text>
          {valueSubtype && (
            <Text style={styles.normalText}>
              {t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_2',
                {
                  activityType: activityTypeLabel,
                  subTypeActivity: valueSubtype?.label,
                }
              )}
            </Text>
          )}
        </View>
        <View style={styles.chips}>
          {achievement.version && (
            <ChipsComponent
              text={getTextDraft()}
              widthChips={70}
              heightChips={20}
              backgroundColor={blueGrey50}
              textColor={blueGrey700}
              textTransform='uppercase'
            />
          )}

          {isValidatedByCurrentUser && (
            <ChipsComponent
              text={t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.VALIDATED'
              )}
              widthChips={70}
              heightChips={20}
              backgroundColor={primary500}
              textColor={white}
              textTransform='uppercase'
            />
          )}
        </View>
        {haveEiq && isConnected && (
          <View style={styles.cardEiq}>
            <EiqCard
              title={`${t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_3'
              )} ${calculateEiq(supplies, surface)}`}
              subTitle={`${supplies.length} ${t(
                supplies.length > 1
                  ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_5'
                  : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_6'
              )}`}
              eiqRange={getEIQRange(calculateEiq(supplies, surface))}
            />
          </View>
        )}
      </View>
    )
  }

  return (
    <View style={[styles.contentCard, getBackgroundColor()]}>
      <AccordionItem
        ContentHeader={ContentHeader}
        contentStyle={styles.contentStyle}
        extraBodyStyle={{ ...styles.extraBodyStyle, ...getBackgroundColor() }}
      >
        <View>
          <InfoDisplayActivity
            activity={achievement}
            areaUnit={areaUnit}
            t={t}
            haveEiq={haveEiq}
          />
          <ButtonPrimary
            text={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.CARD_ACHIEVEMENT.TEXT_5'
            )}
            onPress={() => onPressAchievement(achievement)}
            width={120}
            customTextStyle={styles.textButton}
            backgroundColor='transparent'
          />
        </View>
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  extraBodyStyle: {
    paddingTop: 0,
    paddingHorizontal: 7,
    paddingBottom: 10,
    backgroundColor: white,
  },
  contentStyle: {
    borderRadius: 4,
  },
  contentCard: {
    backgroundColor: white,
    margin: 10,
    borderRadius: 4,
  },
  contentFlexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 10,
  },
  borderBottom: {
    borderBottomColor: gray12,
    borderBottomWidth: 1,
    marginHorizontal: 10,
    paddingBottom: 5,
  },
  contentInfoAchievement: {
    padding: 10,
    paddingTop: 10,
  },
  textDate: {
    fontSize: 12,
    color: primary500,
  },
  normalText: {
    color: blackMediumEmphasis,
  },
  textLots: {
    marginVertical: 5,
  },
  chips: {
    flexDirection: 'row',
    padding: 10,
  },
  textButton: {
    fontSize: 14,
    marginLeft: 10,
  },
  cardEiq: {
    padding: 10,
  },
})

CardAchievement.propTypes = {
  achievement: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  onPressAchievement: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  areaUnit: PropTypes.string.isRequired,
  subTypeActivities: PropTypes.array,
}
