import React, { useState, useContext, useEffect } from 'react'
import { Platform, Pressable, Alert, Linking } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import * as Location from 'expo-location'
import * as DocumentPicker from 'expo-document-picker'
import mime from 'mime'
import EXIF from 'exif-js'
import { useActionSheet } from '@expo/react-native-action-sheet'
import UploadWeb from '../common/UploadWeb'
import ModalLoading from '../common/ModalLoading'
import LoadingAnimation from '@assets/animations/lottie/loading'
import { VALID_DOCUMENTS, VALID_FORMATS_FILES } from '@utils/constants'
import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import useModal from '@hooks/useModal'

const InputUpload = ({
  style,
  children,
  accept,
  showLocation,
  setFieldValue,
  nameFile = 'file',
  nameLocation = 'location',
  isPictureOnlyPicture,
  isDocumentOnly,
  cancelOnPress,
  onSelectFile,
  disabled,
  autoOpen,
  showAlert = true,
}) => {
  const { t } = useContext(LanguageContext)

  const [loading, setLoading] = useState(false)
  const [isAlreadyHavePermissions, setIsAlreadyHavePermissions] =
    useState(false)
  const { showActionSheetWithOptions } = useActionSheet()
  const { isModalVisible, toggleModal, closeModal } = useModal()

  useEffect(() => {
    getPermissionImages()
  }, [])

  useEffect(() => {
    if (!autoOpen || Platform.OS === 'web' || !isAlreadyHavePermissions) {
      return
    }

    _chooseMultimedia()
  }, [isAlreadyHavePermissions, autoOpen])

  const getPermissionImages = async () => {
    if (Platform.OS !== 'web') {
      let { status: existingStatus } =
        ImagePicker.getMediaLibraryPermissionsAsync()
      if (existingStatus !== 'granted') {
        const status = await ImagePicker.requestMediaLibraryPermissionsAsync()
        existingStatus = status.status
      }

      if (existingStatus === 'granted') {
        const responseCameraPermissions =
          ImagePicker.getCameraPermissionsAsync()
        existingStatus = responseCameraPermissions.status
        if (existingStatus !== 'granted') {
          const responseCameraPermissionRequest =
            await ImagePicker.requestCameraPermissionsAsync()
          existingStatus = responseCameraPermissionRequest.status
        }
      }

      if (existingStatus !== 'granted') {
        Alert.alert(
          t('UTILS').PERMISSIONS.TEXT_1,
          t('UTILS').PERMISSIONS.TEXT_2,
          [
            {
              text: t('UTILS').PERMISSIONS.TEXT_3,
              onPress: () => cancelOnPress(),
            },
            {
              text: t('UTILS').PERMISSIONS.TEXT_4,
              onPress: () => Linking.openSettings(),
            },
          ],
          { cancelable: false }
        )
        return
      }

      await Location.requestForegroundPermissionsAsync()
    }

    setIsAlreadyHavePermissions(true)
  }

  const _setResult = async (result, type = 'photo') => {
    let newUri
    if (result.cancelled) {
      return
    }

    if (type === 'document' && Platform.OS === 'android') {
      newUri = result.uri
    } else {
      newUri = 'file:///' + result.uri.split('file:/').join('')
    }

    const file = {
      uri: newUri,
      name: result.name || result.uri.split('/').pop(),
      type: mime.getType(newUri),
    }

    if (setFieldValue) {
      setFieldValue(nameFile, file)
    }

    if (onSelectFile) {
      onSelectFile(file)
    }
  }

  const _pickGallery = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: true,
        quality: 1,
        mediaType: 'photo',
        cameraType: 'back',
        maxWidth: 8000,
        maxHeight: 8000,
      })

      if (showLocation) {
        setLoading(true)
        const gpsLocation = await getLatLongExifDataInMobil(result?.exif ?? {})
        if (!gpsLocation?.latitude || !gpsLocation?.longitude) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_9)
        }

        if (setFieldValue) {
          setFieldValue(nameLocation, gpsLocation || {})
          setFieldValue('isValidateLot', true)
        }

        setLoading(false)
      }

      _setResult(result)
    } catch (error) {
      console.warn(error.message)
    }
  }

  const getCurrentPosition = async () => {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      try {
        const watchPositionSubscription = await Location.watchPositionAsync(
          {
            accuracy: 1,
            timeInterval: 5000,
            distanceInterval: 0,
          },
          (position) => {
            watchPositionSubscription.remove()

            resolve(position)
          }
        )
      } catch (error) {
        console.warn(error)
        resolve()
      }
    })

    return promise
  }

  const _pickCamera = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        quality: 0.5,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        exif: true,
      })

      if (showLocation) {
        setLoading(true)

        const currentPosition = await getCurrentPosition()
        let location = {}

        if (!currentPosition) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_1)
          return
        } else {
          location = {
            latitude: currentPosition.coords.latitude,
            longitude: currentPosition.coords.longitude,
          }

          if (setFieldValue) {
            setFieldValue('isValidateLot', true)
          }
        }

        if (setFieldValue) {
          setFieldValue(nameLocation, location)
        }

        setLoading(false)
      }
      _setResult(result)
    } catch (error) {
      if (error.message === 'User rejected permissions') {
        Alert.alert(
          t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_10,
          t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_11,
          [
            {
              text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_12,
              onPress: () => console.warn('cancel'),
            },
            {
              text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_13,
              onPress: () => Linking.openURL('app-settings:'),
            },
          ],
          { cancelable: false }
        )
      }
      console.warn('error', error.message)
    }
  }

  const _pickDocument = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    })

    const getType = mime.getType(result.uri)

    const message =
      accept === '.kmz'
        ? t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_2
        : t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_3

    const typeMach =
      getType.match(
        accept === '.kmz' ? VALID_FORMATS_FILES : VALID_DOCUMENTS
      ) != null

    if (!typeMach && showAlert) {
      Alert.alert(
        t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_4,
        message,
        [
          {
            text: t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_5,
            onPress: () => {
              console.warn('Aceptar')
            },
          },
        ],
        { cancelable: false }
      )
      return
    }

    _setResult(result, 'document')
  }

  const getLatLongExifDataInMobil = (metaData) => {
    const { GPSLongitude, GPSLongitudeRef, GPSLatitude, GPSLatitudeRef } =
      metaData
    if (!GPSLongitude || !GPSLongitudeRef || !GPSLatitude || !GPSLatitudeRef)
      return {}

    const latitude =
      GPSLatitudeRef === 'S' && GPSLatitude > 0 ? GPSLatitude * -1 : GPSLatitude
    const longitude =
      GPSLongitudeRef === 'W' && GPSLongitude > 0
        ? GPSLongitude * -1
        : GPSLongitude
    return latitude && longitude ? { latitude, longitude } : {}
  }

  const getLatLongExifData = (metaData) => {
    const {
      GPSLongitude: exifLong,
      GPSLongitudeRef: exifLongRef,
      GPSLatitude: exifLat,
      GPSLatitudeRef: exifLatRef,
    } = metaData
    let latitude, longitude

    if (!exifLong || !exifLongRef || !exifLat || !exifLatRef) return {}

    if (exifLatRef == 'S') {
      latitude = exifLat[0] * -1 + (exifLat[1] * -60 + exifLat[2] * -1) / 3600
    } else {
      latitude = exifLat[0] + (exifLat[1] * 60 + exifLat[2]) / 3600
    }

    if (exifLongRef == 'W') {
      longitude =
        exifLong[0] * -1 + (exifLong[1] * -60 + exifLong[2] * -1) / 3600
    } else {
      longitude = exifLong[0] + (exifLong[1] * 60 + exifLong[2]) / 3600
    }

    return latitude && longitude ? { latitude, longitude } : {}
  }

  const handleFile = async (values) => {
    if (setFieldValue) {
      setFieldValue(nameFile, values.file)
    }

    if (showLocation) {
      EXIF.getData(values.file, function () {
        const metaData = EXIF.getAllTags(this)
        const gpsLocation = getLatLongExifData(metaData)
        if (!gpsLocation.latitude || !gpsLocation.longitude) {
          alert(t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_9)
        }

        if (setFieldValue) {
          setFieldValue(nameLocation, gpsLocation)
          setFieldValue('isValidateLot', true)
        }
      })
    } else {
      if (setFieldValue) {
        setFieldValue(nameLocation, {})
      }
    }

    if (onSelectFile) {
      onSelectFile(values.file)
    }
  }

  const _chooseMultimedia = async () => {
    if (isDocumentOnly) {
      return _pickDocument()
    }

    const options = [
      isPictureOnlyPicture
        ? t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_14
        : t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_15,
      t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_16,
      t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_17,
    ]

    const cancelButtonIndex = 3

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          if (isPictureOnlyPicture) {
            _pickGallery()
          } else {
            _pickDocument()
          }
        } else if (buttonIndex === 1) {
          _pickCamera()
        }
      }
    )
  }

  return (
    <>
      {loading ? (
        <ModalLoading
          isLoading={true}
          handleClose={() => setLoading(false)}
          animation={LoadingAnimation}
          animationStyle={{
            width: 150,
            height: 150,
          }}
          title={t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_6}
          loadingLabels={[
            t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_7,
            t('COMPONENTS').INPUTS.INPUT_FILE_UPLOAD.TEXT_8,
          ]}
        />
      ) : Platform.OS !== 'web' ? (
        <Pressable
          style={style}
          onPress={() => !disabled && _chooseMultimedia()}
        >
          {children}
        </Pressable>
      ) : (
        <UploadWeb
          handleFile={handleFile}
          accept={accept}
          style={style}
          disabled={disabled}
          autoOpen={autoOpen}
          toggleModalError={toggleModal}
        >
          {children}
        </UploadWeb>
      )}
      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onConfirm={closeModal}
        title={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_7}
        contentText={
          accept === '.kmz'
            ? t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_5
            : t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_6
        }
        confirmText={t('COMPONENTS').COMMON.UPLOAD_INPUT.TEXT_8}
      />
    </>
  )
}

export default InputUpload
