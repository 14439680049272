import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View } from 'react-native'

import { SustainabilityIcon } from '@modules/common/components'
import { blackMediumEmphasis } from '@styles/palette'

export const ApplicationType = ({ title, subTitle, eiq }) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentInfo}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subTitle}>{subTitle}</Text>
      </View>

      {Boolean(eiq) && (
        <View style={styles.contentEiq}>
          <SustainabilityIcon name={'EIQ'} size={14} style={styles.icon} />

          <Text style={styles.textEiq}>{`EIQ ${eiq}`}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 12,
  },
  contentInfo: {
    flex: 1,
  },
  contentEiq: {
    flexDirection: 'row',
    marginTop: 5,
  },
  icon: {
    marginRight: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
  },
  textEiq: {
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.06,
    letterSpacing: 0.25,
  },
})

ApplicationType.prototype = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  eiq: PropTypes.string,
}
