import { cropsTypes as type } from '../types'

const initialState = {
  activityTypes: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ACTIVITY_TYPES: {
      return {
        ...state,
        activityTypes: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
