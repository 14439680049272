import React, { useContext } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import PropTypes from 'prop-types'

import { licenseEmpty } from '@constants/images'
import ButtonCustom from '../../common/ButtonCustom'

const LicensesEmpty = ({ goToCrops }) => {
  const { t } = useContext(LanguageContext)
  return (
    <>
      <View style={styles.container}>
        <Image source={licenseEmpty} style={styles.emptyImage} />

        <View style={styles.textsContainer}>
          <Text style={styles.principalText}>
            {t('COMPONENTS').LICENSES.LICENSES_EMPTY.TEXT_1}
          </Text>

          <Text style={styles.principalText}>
            {t('COMPONENTS').LICENSES.LICENSES_EMPTY.TEXT_2}
          </Text>
        </View>

        <ButtonCustom
          onPress={goToCrops}
          styles={styles.button}
          disabled={false}
        >
          {t('COMPONENTS').LICENSES.LICENSES_EMPTY.TEXT_3}
        </ButtonCustom>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    alignSelf: 'stretch',
    marginTop: 40,
  },
  emptyImage: {
    width: 180,
    height: 230,
    resizeMode: 'contain',
  },
  textsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  principalText: {
    fontSize: 18,
    fontWeight: '500',
  },
  button: {
    width: '90%',
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#4CAF50',
  },
})

LicensesEmpty.propTypes = {
  goToCrops: PropTypes.func.isRequired,
}

export default LicensesEmpty
