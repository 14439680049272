import React from 'react'
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native'
import { round } from 'lodash'
import PropTypes from 'prop-types'
import CardDefault from '@components/cards/CardDefault'
import { blackMediumEmphasis } from '@styles/palette'
import { roundNumber } from '@utils'

const ItemLicense = ({ license, onGoToLicenseDetails, t }) => {
  const {
    establishmentLength,
    images,
    lotsLength,
    name,
    surfaceApplied,
    areaUnit = '',
  } = license
  return (
    <CardDefault>
      <TouchableOpacity onPress={() => onGoToLicenseDetails(license)}>
        <Image source={{ uri: images.thumbnail }} style={styles.imageLicense} />
        <View style={styles.contentInfo}>
          <Text style={styles.textTitle}>{name}</Text>
          <View style={styles.contentTextInfo}>
            <Text style={styles.textInfo}>
              {establishmentLength > 1
                ? t('VIEWS.CROP_STORY.TEXT_1', {
                    establishmentsQuantity: establishmentLength,
                  })
                : t('VIEWS.CROP_STORY.TEXT_2', {
                    establishmentsQuantity: establishmentLength,
                  })}
            </Text>
            <Text style={styles.textInfo}>
              {lotsLength > 1
                ? t('VIEWS.CROP_STORY.TEXT_3', {
                    lotsQuantity: lotsLength,
                  })
                : t('VIEWS.CROP_STORY.TEXT_4', {
                    lotsQuantity: lotsLength,
                  })}
            </Text>
          </View>
          <Text style={styles.textInfo}>
            {t('VIEWS.CROP_STORY.TEXT_5', {
              surfaceQuantity: roundNumber(round(surfaceApplied ?? 0, 2)),
              areaUnit,
            })}
          </Text>
        </View>
      </TouchableOpacity>
    </CardDefault>
  )
}

const styles = StyleSheet.create({
  imageLicense: {
    width: 167,
    height: 167,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  contentInfo: {
    width: 167,
    padding: 12,
  },
  textTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  textInfo: {
    color: blackMediumEmphasis,
  },
  contentTextInfo: {
    marginVertical: 10,
  },
})

ItemLicense.propTypes = {
  license: PropTypes.object,
  onGoToLicenseDetails: PropTypes.func,
}

export default ItemLicense
