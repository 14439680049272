import moment from 'moment'
import activityTypesConstants from '@constants/activityTypes'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { formatDateMoment } from '@utils/date'
import { truncateSurface } from '@common/utils/truncateSurface'

export const parseActivityDataToFormik = ({
  t,
  verificationTypes,
  unitTypes,
  agreementTypes,
  formikInitialValues,
  activity,
  subTypeActivities,
  tag,
  locale,
  unitTypesSupplies,
  unitTypesVolume,
}) => {
  const newFormikData = {}

  const newLabel =
    activity.lotsWithSurface?.length > 1
      ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
      : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

  const labelLots = activity.lots.length
    ? `${activity.lots.length} ${newLabel}`
    : ''

  const labelFormikLots = activity?.farm?.name || labelLots

  newFormikData.lots = {
    value: activity.lotsWithSurface?.length
      ? activity.lotsWithSurface.map((element) => ({
          _id: element.lot._id,
          surfacePlanned: element.surfacePlanned,
          surfaceRealized: element.surfaceRealized,
          surface: element.surfaceRealized ?? element.surfacePlanned,
          tag: element.tag,
        }))
      : activity.lots.map((element) => ({
          _id: element._id,
          surfacePlanned: element.surface,
          surfaceRealized: element.surface,
          surface: element.surface,
          tag: element.tag,
        })),
    label: labelFormikLots,
  }

  newFormikData.surface = activity.lotsWithSurface?.length
    ? String(
        truncateSurface(
          activity.lotsWithSurface.reduce(
            (acumulator, currentValue) =>
              acumulator +
              (currentValue.surfaceRealized ?? currentValue.surfacePlanned),
            0
          )
        )
      )
    : String(truncateSurface(activity.surface))

  if (activity.dateHarvest) {
    newFormikData.dateHarvest = {
      value: moment(activity.dateHarvest).toDate(),
      label: moment(activity.dateHarvest).format('DD/MM/YYYY'),
    }
  }

  if (activity.dateObservation) {
    newFormikData.dateObservation = {
      value: moment(activity.dateObservation).toDate(),
      label: moment(activity.dateObservation).format('DD/MM/YYYY'),
    }
  }

  if (activity.dateEstimatedHarvest) {
    newFormikData.dateEstimatedHarvest = {
      value: moment(activity.dateEstimatedHarvest).toDate(),
      label: moment(activity.dateEstimatedHarvest).format('DD/MM/YYYY'),
    }
  }

  if (activity.verificationType) {
    newFormikData.verificationType = verificationTypes.find(
      (element) => element.value === activity.verificationType._id
    )
  }

  if (activity.subTypeActivity) {
    newFormikData.subTypeActivity = subTypeActivities.find(
      (element) => element.value === activity.subTypeActivity._id
    )
  }

  if (activity.unitType) {
    newFormikData.unitType = unitTypes.find(
      (element) => element.value === activity.unitType
    )
  }

  if (activity.typeAgreement) {
    newFormikData.typeAgreement = agreementTypes.find(
      (element) => element.value === activity.typeAgreement._id
    )
  }

  if (activity.observation) {
    newFormikData.observation = activity.observation
  }

  if (activity.observations) {
    newFormikData.observations = activity.observations
  }

  if (activity.pay !== undefined || activity.pay !== null) {
    newFormikData.pay = String(activity.pay)
  }

  if (activity.volume !== undefined || activity.volume !== null) {
    newFormikData.volume = String(activity?.volume)
  }

  if (activity.unitVolume) {
    newFormikData.unitVolume = unitTypesVolume.find(
      (element) =>
        element.value === activity?.unitVolume?._id ?? activity?.unitVolume
    )
  }

  if (activity.signers) {
    newFormikData.signers = activity.signers
  }

  if (activity.files) {
    newFormikData.evidences = activity.files
  }

  if (activity.storages) {
    newFormikData.storages = activity.storages
  }

  if (activity.supplies) {
    newFormikData.supplies = activity.supplies.map((supply) => {
      if (!supply.unitName) {
        const unitName = unitTypesSupplies.find(
          (unit) => unit?.key?.toLowerCase() === supply?.unit?.toLowerCase()
        )
        supply.unitName = unitName?.keyLabel
      }
      return supply
    })

    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(
        activity.supplies,
        activity.surface
      )
      const range = getEIQRange(totalEiqAllSupplies)
      newFormikData.eiqSupplies = { totalEiqAllSupplies, range }
    }
  }

  if (activity.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(activity.dateAchievement).toDate(),
      label: formatDateMoment(
        activity.dateAchievement,
        'DD/MMM/YYYY',
        locale,
        true
      ),
    }
  }
  if (activity.farm) {
    newFormikData.farm = {
      tag: activity?.farm?.name,
      farmId: activity?.farm?._id,
    }
  }

  if (activity.seedDryContent) {
    newFormikData.seedDryContent = String(activity.seedDryContent)
  }

  return {
    ...formikInitialValues,
    ...newFormikData,
  }
}
