import React, { useState, useContext, useCallback, useRef } from 'react'
import { useWindowDimensions } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { round } from 'lodash'
import PropTypes from 'prop-types'

import { ConnectionContext } from '@contextState/connection'
import useNetwork from '@utils/network'
import Screen from './screen'
import { useResponsive } from '@modules/common/hooks'
import { MAGIC_CONTENT_BODY } from '@constants/magic'

const LandscapeSustainability = ({ route }) => {
  const { cropId, lotId } = useRef(route.params).current
  const { isScreenDesktop } = useResponsive()
  const { width } = useWindowDimensions()
  const widthStyle = { width: isScreenDesktop ? MAGIC_CONTENT_BODY : width }

  const { isConnected } = useContext(ConnectionContext)

  const [lot, setLot] = useState({})
  const [isFetchingLot, setIsFetchingLot] = useState(true)

  const { doRequest } = useNetwork()

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      fetchLot()
    }, [])
  )

  const fetchLot = useCallback(async () => {
    if (isConnected) {
      setIsFetchingLot(true)

      const response = await doRequest({
        method: 'GET',
        url: `lots/${lotId}/crop/${cropId}`,
      })

      const lot = buildLotData(response.data)

      setLot(lot)

      setIsFetchingLot(false)
    }
  }, [])

  const buildLotData = useCallback(
    ({
      imageUrl,
      name,
      cityName,
      provinceName,
      surface,
      countAchievement,
      eiq,
      landscapeSustainability,
      centerBound,
    }) => {
      const lot = {
        imageUrl,
        name,
        cityName,
        centerBound,
        provinceName,
        surface,
        applicationQuantity: countAchievement,
        eiq,
        normatives: [],
        details: [],
      }

      if (landscapeSustainability.length) {
        const {
          totals: {
            percentageOfTheTotalInterceptionArea,
            totalAreaOfIntersections,
            fieldArea,
          },
          datasets,
        } = landscapeSustainability.find(
          (element) => element.generalCountryNormative
        )

        lot.percentageWithoutOverlapping = round(
          100 - percentageOfTheTotalInterceptionArea,
          2
        )

        lot.surfaceWithoutOverlapping = round(
          fieldArea - totalAreaOfIntersections,
          2
        )

        lot.overlappingPercentage = round(
          percentageOfTheTotalInterceptionArea,
          2
        )

        lot.overlappingSurface = round(totalAreaOfIntersections, 2)

        lot.normatives = landscapeSustainability
          .filter((element) => !element.generalCountryNormative)
          .map((element) => ({
            name: element.nameSchema,
            percent: round(
              100 - element.totals.percentageOfTheTotalInterceptionArea,
              2
            ),
            surface: round(
              element.totals.fieldArea -
                element.totals.totalAreaOfIntersections,
              2
            ),
          }))

        lot.details = datasets.map((element) => ({
          name: element.name,
          overlappingPercentage: round(element.percent, 2),
          overlappingSurface: round(element.intersectArea, 2),
          categories: element.categories
            ? element.categories.map((subElement) => ({
                name: subElement.name,
                overlappingSurface: round(subElement.solappingArea, 2),
                color: subElement.color,
              }))
            : [],
        }))
      } else {
        lot.percentageWithoutOverlapping = 100

        lot.surfaceWithoutOverlapping = lot.surface

        lot.overlappingPercentage = 0

        lot.overlappingSurface = 0
      }

      return lot
    },
    []
  )

  return (
    <Screen
      isFetchingLot={isFetchingLot}
      image={lot.imageUrl}
      name={lot.name}
      cityName={lot.cityName}
      provinceName={lot.provinceName}
      surface={lot.surface}
      applicationQuantity={lot.applicationQuantity}
      eiq={lot.eiq}
      percentageWithoutOverlapping={lot.percentageWithoutOverlapping}
      surfaceWithoutOverlapping={lot.surfaceWithoutOverlapping}
      overlappingPercentage={lot.overlappingPercentage}
      overlappingSurface={lot.overlappingSurface}
      normatives={lot.normatives}
      details={lot.details}
      cropAreaUnit={route?.params?.cropAreaUnit ?? ''}
      centerBound={lot?.centerBound ?? undefined}
      widthStyle={widthStyle}
    />
  )
}

LandscapeSustainability.propTypes = {
  route: PropTypes.object.isRequired,
}

export default LandscapeSustainability
