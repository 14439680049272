import { Platform, Alert, Linking } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import * as Location from 'expo-location'
import { ROLE_PRODUCER, ROLE_ADVISER } from '@utils/constants'

export const getPermissionImages = async (t) => {
  {
    if (Platform.OS !== 'web') {
      await ImagePicker.requestMediaLibraryPermissionsAsync()

      let { status: existingStatus } = ImagePicker.getCameraPermissionsAsync()

      if (existingStatus !== 'granted') {
        const status = await ImagePicker.requestCameraPermissionsAsync()
        existingStatus = status.status
      }

      const status2 = ImagePicker.getCameraPermissionsAsync()
      existingStatus = status2.status

      if (existingStatus !== 'granted') {
        Alert.alert(
          t('UTILS').PERMISSIONS.TEXT_1,
          t('UTILS').PERMISSIONS.TEXT_2,
          [
            {
              text: t('UTILS').PERMISSIONS.TEXT_3,
              onPress: () => console.warn('cancel'),
            },
            {
              text: t('UTILS').PERMISSIONS.TEXT_4,
              onPress: () => Linking.openSettings(),
            },
          ],
          { cancelable: false }
        )
        return
      }

      await Location.requestForegroundPermissionsAsync()
    }
  }
}

const typesPermissions = {
  CREATE: 'create:',
  VIEW: 'view:',
  EDIT: 'edit:',
  DELETE: 'delete',
  AUTHORIZE: 'authorize:',
  SIGN: 'sign:',
  APPLY_VETTED: 'applyVetted:',
  VIEW_VETTED: 'viewVetted:',
}

const modulesPermissions = {
  COMPANY: 'company',
  FARM: 'farm',
  FIELD: 'field',
  CROP: 'crop',
  COLLABORATOR: 'collaborator',
  LICENSE: 'license',
}

export const userPermissions = {
  CREATE_FARM: `${typesPermissions.CREATE}${modulesPermissions.FARM}`,
  EDIT_FARM: `${typesPermissions.EDIT}${modulesPermissions.FARM}`,
  CREATE_FIELD: `${typesPermissions.CREATE}${modulesPermissions.FIELD}`,
  EDIT_FIELD: `${typesPermissions.EDIT}${modulesPermissions.FIELD}`,
  VIEW_FARM: `${typesPermissions.VIEW}${modulesPermissions.FARM}`,
  EDIT_COMPANY: `${typesPermissions.EDIT}${modulesPermissions.COMPANY}`,
  AUTHORIZE_COLLABORATOR: `${typesPermissions.AUTHORIZE}${modulesPermissions.COLLABORATOR}`,
  CREATE_COLLABORATOR: `${typesPermissions.CREATE}${modulesPermissions.COLLABORATOR}`,
  CREATE_CROP: `${typesPermissions.CREATE}${modulesPermissions.CROP}`,
  VIEW_CROP: `${typesPermissions.VIEW}${modulesPermissions.CROP}`,
  EDIT_CROP: `${typesPermissions.EDIT}${modulesPermissions.CROP}`,
  VIEW_LICENSE: `${typesPermissions.VIEW}${modulesPermissions.LICENSE}`,
  SIGN_LICENSE: `${typesPermissions.SIGN}${modulesPermissions.LICENSE}`,
  APPLY_VETTED_COMPANY: `${typesPermissions.APPLY_VETTED}${modulesPermissions.COMPANY}`,
  VIEW_VETTED_COMPANY: `${typesPermissions.VIEW_VETTED}${modulesPermissions.COMPANY}`,
}

export const permAdmin = [
  userPermissions.AUTHORIZE_COLLABORATOR,
  userPermissions.SIGN_LICENSE,
]

const permissionCreateCollaborator = (role, isAdmin) => {
  if (isAdmin && role.value === ROLE_PRODUCER) {
    return Boolean(role?.assignableCompany?.withFlagAdmin?.length)
  }

  return Boolean(role?.assignableCompany?.withoutFlagAdmin?.length)
}

const validatePermission = (perm, role, isAdmin, isResponsible) => {
  const { permissions = [] } = role

  if (perm === userPermissions.CREATE_COLLABORATOR) {
    return permissionCreateCollaborator(role, isAdmin, isResponsible)
  }

  if (permAdmin.includes(perm) && role.value === ROLE_PRODUCER) {
    return isAdmin && permissions.includes(perm)
  }

  return permissions.includes(perm)
}

export const validateRoleInUserConfigService = (
  perm,
  roleSelected,
  isAdmin,
  isResponsible
) => {
  if (!roleSelected) {
    return false
  }

  if (roleSelected.value === ROLE_ADVISER) {
    return validatePermission(perm, roleSelected, false, isResponsible)
  }

  return validatePermission(perm, roleSelected, isAdmin)
}
