import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Modal, Portal } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import { white, blackHighEmphasis } from '@styles/palette'
import ButtonDefault from '@common/components/buttons/ButtonDefault'

const RequestedReportModal = ({
  isModalVisible,
  closeModal,
  errorResponse,
}) => {
  const { t } = useContext(LanguageContext)
  return (
    <Portal>
      <Modal
        visible={isModalVisible}
        onDismiss={closeModal}
        contentContainerStyle={styles.modal}
      >
        <View style={styles.body}>
          <View style={styles.header}>
            <Text style={styles.headerTitle}>
              {t('VIEWS.FARM_DETAILS.COMPONENTS.REQUESTED_MODAL.TITLE')}
            </Text>
            <TouchableOpacity onPress={closeModal}>
              <Icon name='close' size={14} style={styles.buttonClose} />
            </TouchableOpacity>
          </View>
          <View style={styles.content}>
            <Text style={styles.description}>
              {errorResponse
                ? errorResponse
                : t(
                    'VIEWS.FARM_DETAILS.COMPONENTS.REQUESTED_MODAL.DESCRIPTION'
                  )}
            </Text>
          </View>
          <View style={styles.footer}>
            <ButtonDefault
              text={t(
                'VIEWS.FARM_DETAILS.COMPONENTS.REQUESTED_MODAL.ACCEPT'
              ).toUpperCase()}
              height={32}
              width={83}
              borderRadius={8}
              fontSize={14}
              fontWeight={'700'}
              onPress={closeModal}
            />
          </View>
        </View>
      </Modal>
    </Portal>
  )
}
const styles = StyleSheet.create({
  modal: {
    alignSelf: 'center',
  },
  body: {
    backgroundColor: white,
    width: 312,
    minHeight: 220,
    borderRadius: 2,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginStart: 28,
    marginTop: 28,
    marginEnd: 21,
  },
  headerTitle: {
    fontFamily: 'Roboto',
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  content: {
    marginStart: 40,
    marginTop: 24,
    marginEnd: 24,
  },
  description: {
    fontFamily: 'Roboto',
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  footer: {
    marginTop: 24,
    marginHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 15,
  },
})

RequestedReportModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
}

export default RequestedReportModal
