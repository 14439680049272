import { apiUrl } from '@utils/network'
import getDrawFieldUrl from 'config/draw-field-urls'
import AsyncStorage from '@react-native-async-storage/async-storage'

const getUrlDetailFarm = async (fieldUuid) => {
  const token = await AsyncStorage.getItem('USER_TOKEN')
  const url = `${getDrawFieldUrl(
    apiUrl
  )}/farms/${fieldUuid}?accessToken=${token}&timestamp=${Date.now()}`
  return encodeURI(url)
}

const getUrlDetailField = async (FieldUuid) => {
  const token = await AsyncStorage.getItem('USER_TOKEN')
  const url = `${getDrawFieldUrl(
    apiUrl
  )}/fields/${FieldUuid}?accessToken=${token}&timestamp=${Date.now()}`
  return encodeURI(url)
}

export const getUrlDrawFields = {
  getUrlDetailField,
  getUrlDetailFarm,
}
