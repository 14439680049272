import React, { useContext } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import ModalStorage from '@components/common/ModalStorage'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import activityTypes from '@constants/activityTypes'
import { LanguageContext } from '@contextState/language'

export const AddDestination = ({
  isAddDestinationVisible,
  tag,
  isModalDestinationVisible,
  toggleModalDestination,
  closeModalDestination,
  handleDestination,
  company,
}) => {
  if (tag !== activityTypes.ACT_HARVEST.key || !isAddDestinationVisible) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <View>
      <ButtonPrimary
        text={t('VIEWS').ACTIVITY_CREATE.DESTINATION.TEXT_1}
        onPress={toggleModalDestination}
        backgroundColor={'transparent'}
      />
      <ModalStorage
        visible={isModalDestinationVisible}
        onSelect={handleDestination}
        handleClose={closeModalDestination}
        company={company}
      />
    </View>
  )
}

AddDestination.propTypes = {
  isAddDestinationVisible: PropTypes.bool.isRequired,
  tag: PropTypes.string.isRequired,
  toggleModalDestination: PropTypes.func.isRequired,
  isModalDestinationVisible: PropTypes.bool.isRequired,
  handleDestination: PropTypes.func.isRequired,
  closeModalDestination: PropTypes.func.isRequired,
}
