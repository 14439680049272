import React from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import ItemLicense from './ItemLicense'
import TitleIcon from './TitleIcon'
import { CommonIcon } from '@common/components'

const ListLicenses = ({ licenses, onGoToLicenseDetails, t }) => {
  return (
    <View style={styles.container}>
      <TitleIcon
        text={t('VIEWS.CROP_STORY.TEXT_6')}
        Icon={() => <CommonIcon name={'LICENSES'} size={24} />}
      />

      <View style={styles.licensesContainer}>
        <FlatList
          data={licenses}
          horizontal
          renderItem={({ item }) => {
            return (
              <ItemLicense
                license={item}
                onGoToLicenseDetails={onGoToLicenseDetails}
                t={t}
              />
            )
          }}
          keyExtractor={(item) => item.licenseId}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  licensesContainer: {
    padding: 5,
  },
  separator: {
    width: 5,
  },
})

ListLicenses.propTypes = {
  licenses: PropTypes.array,
  onGoToLicenseDetails: PropTypes.func,
}

export default ListLicenses
