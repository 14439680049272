import { useEffect, useState } from 'react'

export const useShowSurface = (surfaces) => {
  const { surface } = surfaces
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(Boolean(surface))
    return () => {}
  }, [surface])

  return {
    visible,
  }
}
