import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigation } from '@react-navigation/native'
import { Platform } from 'react-native'

import useNetwork from '@utils/network'
import { DEFAULT_FARM_IMAGE } from '../../../FarmCreate/v1/utils/defaultValues'
import { formatSurface } from '@modules/common/utils'
import { AuthContext } from '@contextState/auth'
import { userPermissions } from '@utils/permissions'
import { TYPE_RENDER_WEB_VIEW } from '@modules/webView/constants/typesRendes'

export const useDetailsFarm = (identifier, farmId, isFocused) => {
  const navigation = useNavigation()
  const { doRequest } = useNetwork()
  const { config } = useContext(AuthContext)
  const { roleSelected } = config
  const hasPermissionEdit = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.EDIT_FARM
    )
  )

  const [farmDetails, setFarmDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getDetailsFarm()
  }, [isFocused])

  const getDetailsFarm = async () => {
    try {
      const url = `farms/${farmId}/companies/${identifier}`

      const response = await doRequest({
        method: 'GET',
        url,
        displayAlert: true,
      })

      const {
        name,
        surface,
        mapsURl,
        numberOfFields,
        firstLevelGeometry,
        secondLevelGeometry,
        thirdLevelGeometry,
        uuid,
        areaUnit,
        _id,
      } = response.data

      let image = DEFAULT_FARM_IMAGE

      if (mapsURl && mapsURl.length) {
        const [firstImage] = mapsURl

        image = firstImage
      }

      const farmDetails = {
        name,
        surface,
        image,
        lots: numberOfFields,
        department: thirdLevelGeometry,
        province: secondLevelGeometry,
        country: firstLevelGeometry,
        uuid,
        areaUnit,
        _id,
      }
      setFarmDetails(farmDetails)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const onNavigateDetailFarm = () => {
    if (Platform.OS !== 'web') {
      return
    }
    navigation.navigate('WebViewResponsePage', {
      type: TYPE_RENDER_WEB_VIEW.FARM,
      uuid,
      title: farmDetails.name,
    })
  }

  const hasSurface = () => {
    if (surface) return true

    return false
  }

  const {
    name,
    department,
    province,
    lots,
    surface,
    image,
    uuid,
    areaUnit,
    _id,
  } = farmDetails
  const location = `${department}, ${province}`
  const surfaceWithArea = Number(surface)
    ? formatSurface(surface, areaUnit)
    : null

  return {
    name,
    department,
    province,
    lots,
    surface,
    location,
    image,
    isLoading,
    uuid,
    areaUnit,
    surfaceWithArea,
    hasSurface,
    hasPermissionEdit,
    _id,
    onNavigateDetailFarm,
  }
}

useDetailsFarm.propTypes = {
  identifier: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
}
