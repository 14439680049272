import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Platform } from 'react-native'

import { Menu } from 'react-native-paper'

import { ConnectionContext } from '@contextState/connection'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'

import { CROP_MENU_OPTIONS } from '@modules/common/utils'
import { CommonIcon } from '@modules/common/components'
import {
  canSeeCropMenuDeleteCrop,
  canSeeCropMenuFarmAndField,
} from '../../utils'

export const CropMenu = ({
  disabled,
  status,
  isCropOffline,
  canWrite,
  onPress,
  showOptionDeleteCrop = true,
  showOptionEnableOrDisableOffline = true,
  hasLots = true,
  userType,
  userCanDeleteCrop = false,
  isDeleteCropAvailable = false,
}) => {
  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const {
    isCompanyVerifiers,
    companyIsSynchronized,
    selectedProfile,
    isCompanyUcropit,
  } = useContext(AuthContext)

  const handleCropMenuOptions = async (option) => {
    if (onPress) onPress(option)

    setVisibleMenu(false)
  }

  const [visibleMenu, setVisibleMenu] = useState(false)
  return (
    <Menu
      onDismiss={() => setVisibleMenu(false)}
      visible={visibleMenu}
      anchor={
        <CommonIcon
          name={'DOTS-VERTICAL'}
          size={20}
          style={styles.dotsIcon}
          onPress={() => setVisibleMenu(true)}
          disabled={disabled}
        />
      }
    >
      {status === 'DRAFT' ? (
        <Menu.Item
          key={0}
          title={t('COMMON').COMPONENTS.CROP_MENU.DELETE_DRAFT}
          titleStyle={styles.optionMenuRemove}
          onPress={() => handleCropMenuOptions(CROP_MENU_OPTIONS.DELETE_DRAFT)}
        />
      ) : (
        <>
          {hasLots && (
            <Menu.Item
              key={1}
              disabled={!isConnected}
              onPress={() => {
                handleCropMenuOptions(CROP_MENU_OPTIONS.CROP_STORY)
              }}
              title={t('COMMON').COMPONENTS.CROP_MENU.CROP_STORY}
              titleStyle={
                !isConnected ? styles.optionMenuDisabled : styles.optionMenu
              }
            />
          )}
          {hasLots && (
            <Menu.Item
              key={2}
              disabled={!isConnected}
              onPress={() =>
                handleCropMenuOptions(CROP_MENU_OPTIONS.COLLABORATORS)
              }
              title={t('COMMON').COMPONENTS.CROP_MENU.COLLABORATORS}
              titleStyle={
                !isConnected ? styles.optionMenuDisabled : styles.optionMenu
              }
            />
          )}
          {isConnected && hasLots && !isCompanyVerifiers() && (
            <Menu.Item
              key={3}
              onPress={() => handleCropMenuOptions(CROP_MENU_OPTIONS.LICENSES)}
              title={t('COMMON').COMPONENTS.CROP_MENU.LICENSES}
              titleStyle={styles.optionMenu}
            />
          )}
          {isConnected &&
            hasLots &&
            companyIsSynchronized &&
            !isCompanyVerifiers() && (
              <Menu.Item
                key={4}
                onPress={() =>
                  handleCropMenuOptions(
                    CROP_MENU_OPTIONS.INTEGRATIONS_WITH_THIRD_PARTIES
                  )
                }
                title={
                  t('COMMON').COMPONENTS.CROP_MENU
                    .INTEGRATIONS_WITH_THIRD_PARTIES
                }
                titleStyle={styles.optionMenu}
              />
            )}
          {isConnected &&
            hasLots &&
            Platform.OS !== 'web' &&
            showOptionEnableOrDisableOffline && (
              <Menu.Item
                key={5}
                onPress={() =>
                  handleCropMenuOptions(
                    isCropOffline
                      ? CROP_MENU_OPTIONS.DISABLE_OFFLINE
                      : CROP_MENU_OPTIONS.ENABLE_OFFLINE
                  )
                }
                title={
                  isCropOffline
                    ? t('COMMON').COMPONENTS.CROP_MENU.DISABLE_OFFLINE
                    : t('COMMON').COMPONENTS.CROP_MENU.ENABLE_OFFLINE
                }
                titleStyle={styles.optionMenu}
              />
            )}
          {canSeeCropMenuFarmAndField({ userType }) && (
            <Menu.Item
              key={6}
              disabled={!isConnected}
              onPress={() =>
                handleCropMenuOptions(
                  CROP_MENU_OPTIONS.FARM_AND_FIELD_MANAGEMENT
                )
              }
              title={t('COMMON').COMPONENTS.CROP_MENU.FARM_AND_FIELD_MANAGEMENT}
              titleStyle={styles.optionMenu}
            />
          )}
          {canSeeCropMenuDeleteCrop(
            selectedProfile.isAdmin,
            userType,
            isCompanyUcropit
          ) &&
            canWrite &&
            !isCompanyVerifiers() &&
            showOptionDeleteCrop &&
            userCanDeleteCrop && (
              <Menu.Item
                key={7}
                disabled={!isConnected || !isDeleteCropAvailable}
                onPress={() =>
                  handleCropMenuOptions(CROP_MENU_OPTIONS.DELETE_CROP)
                }
                title={t('COMMON').COMPONENTS.CROP_MENU.DELETE_CROP}
                titleStyle={
                  !isConnected || !isDeleteCropAvailable
                    ? styles.optionMenuDisabled
                    : styles.optionMenuRemove
                }
              />
            )}
        </>
      )}
    </Menu>
  )
}

const styles = StyleSheet.create({
  dotsIcon: {
    margin: 8,
  },
})

CropMenu.propTypes = {
  disabled: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  isCropOffline: PropTypes.bool.isRequired,
  canWrite: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  showOptionDeleteCrop: PropTypes.bool,
  hasLots: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  userCanDeleteCrop: PropTypes.bool,
  isDeleteCropAvailable: PropTypes.bool,
}
