import React from 'react'
import PropTypes from 'prop-types'

import activityTypes from '@constants/activityTypes'
import {
  HarvestForm,
  MonitoringForm,
  AgreementForm,
  VerificationForm,
  AchievementUnplannedForm,
} from '../../components'
import { outlierWarningValues } from '@constants'

export const getFormByActivityType = ({
  tag,
  formik,
  isViewReadOnly = false,
  onPressLotsInput,
  openConfirmationChangeField,
  crop,
  isDirectAchievement = false,
  activityType,
  isConnected,
  openBackdropStandardDeviation,
  outlierHarvestLevel,
  canShowOutlier,
  changeOutlierUnitType,
  changePayAndRequestOutlier,
  isValueOutsideMeanHarvest,
  canShowOutlierHarvest,
  statusOutlier,
  isNewActivity,
  formikInitialValues,
}) => {
  const valueOutsideMean = outlierWarningValues.includes(outlierHarvestLevel)
  if (isDirectAchievement) {
    return (
      <AchievementUnplannedForm
        formik={formik}
        tag={tag}
        crop={crop}
        onPressLotsInput={onPressLotsInput}
        activityType={activityType}
        isConnected={isConnected}
        readOnly={isViewReadOnly}
      />
    )
  }
  if (tag === activityTypes.ACT_HARVEST.key) {
    return (
      <HarvestForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        openBackdropStandardDeviation={openBackdropStandardDeviation}
        canShowOutlier={canShowOutlier}
        valueOutsideMean={valueOutsideMean}
        isNewActivity={isNewActivity}
        changeOutlierUnitType={changeOutlierUnitType}
        changePayAndRequestOutlier={changePayAndRequestOutlier}
        isValueOutsideMeanHarvest={isValueOutsideMeanHarvest}
        canShowOutlierHarvest={canShowOutlierHarvest}
        statusOutlier={statusOutlier}
      />
    )
  } else if (tag === activityTypes.ACT_MONITORING.key) {
    return (
      <MonitoringForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        openBackdropStandardDeviation={openBackdropStandardDeviation}
        canShowOutlier={canShowOutlier}
        valueOutsideMean={valueOutsideMean}
        isNewActivity={isNewActivity}
        changeOutlierUnitType={changeOutlierUnitType}
        changePayAndRequestOutlier={changePayAndRequestOutlier}
        isValueOutsideMeanHarvest={isValueOutsideMeanHarvest}
        canShowOutlierHarvest={canShowOutlierHarvest}
        statusOutlier={statusOutlier}
      />
    )
  } else if (tag === activityTypes.ACT_AGREEMENTS.key) {
    return (
      <AgreementForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        formikInitialValues={formikInitialValues}
      />
    )
  } else if (tag === activityTypes.ACT_VERIFICATION.key) {
    return (
      <VerificationForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        openConfirmationChangeField={openConfirmationChangeField}
        crop={crop}
      />
    )
  }
}

getFormByActivityType.propTypes = {
  tag: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  isViewReadOnly: PropTypes.bool.isRequired,
  onPressLotsInput: PropTypes.func.isRequired,
  openConfirmationChangeField: PropTypes.func,
  crop: PropTypes.obj,
  isDirectAchievement: PropTypes.bool,
  activityType: PropTypes.string,
  isConnected: PropTypes.bool,
  openBackdropStandardDeviation: PropTypes.func,
  outlierHarvestLevel: PropTypes.number,
  canShowOutlier: PropTypes.bool,
  changeOutlierUnitType: PropTypes.func,
  changePayAndRequestOutlier: PropTypes.func,
  isValueOutsideMeanHarvest: PropTypes.bool,
  canShowOutlierHarvest: PropTypes.bool,
  statusOutlier: PropTypes.string,
  isNewActivity: PropTypes.bool,
}
