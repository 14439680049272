import SNACKBAR from '../../components/snackbar/SnackbarRejectedActivity/translate/es'
import TAG_NEW from '../../components/tagNew/translate/es'
import LOST_EMPTY from '../../components/LotsEmpty/translate/es'
import OFFLINE_EMPTY from '../../components/OfflineEmpty/translate/es'
import CROP_MENU from '../../components/CropMenu/translate/es'
import MODAL_ERRORS_DEEP_LINKS from '../../ModalErrorsDeepLinks/translate/es'
import STANDARD_DEVIATION from '../../components/StandardDeviation/translate/es'
import UNASSOCIATE_LICENSE_MENU from '../../components/menu/UnassociatedLicenseMenu/translate/es'
import MODAL_TERMS from '../../components/modals/ModalTerms/translate/es'
import ACTIVITY_CONDITIONS from '../../utils/activityConditions/translate/es'

export default {
  SNACKBAR,
  TAG_NEW,
  LOST_EMPTY,
  OFFLINE_EMPTY,
  COMPONENTS: {
    CROP_MENU,
    UNASSOCIATE_LICENSE_MENU,
    MODAL_TERMS,
  },
  MODAL_ERRORS_DEEP_LINKS,
  STANDARD_DEVIATION,
  ACTIVITY_CONDITIONS,
}
