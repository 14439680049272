import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, grey300 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

export const SaveDraftButton = ({ isValid, createFarmDraft }) => {
  const { t } = useContext(LanguageContext)

  const addDraftStyle = () =>
    !isValid ? styles.buttonDraftDisabled : styles.buttonDraft

  const addDraftStyleTitle = () =>
    !isValid ? styles.textButtonDraftDisabled : styles.textButtonDraft

  return (
    <View>
      <ButtonCustom
        disabled={!isValid}
        isLoading={false}
        onPress={createFarmDraft}
        styles={addDraftStyle()}
        labelStyle={addDraftStyleTitle()}
      >
        {t('VIEWS.FARM_CREATE.COMPONENTS.SAVE_DRAFT_BUTTON.TEXT_1')}
      </ButtonCustom>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonDraft: {
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 1,
    shadowColor: primary500,
    borderWidth: 1,
    borderColor: primary500,
  },
  textButtonDraft: {
    color: primary500,
  },
  buttonDraftDisabled: {
    backgroundColor: 'transparent',
    borderColor: grey300,
    borderWidth: 1,
  },
  textButtonDraftDisabled: {
    color: grey300,
  },
})

SaveDraftButton.propTypes = {
  isValid: PropTypes.bool,
  createFarmDraft: PropTypes.func,
}
