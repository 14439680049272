import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { blackHighEmphasis, white, secondary500 } from '@styles/palette'

export const DeleteModal = ({
  message,
  showModalError,
  close,
  deleteConfirm,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <ModalConfirm
      visible={showModalError}
      onClose={close}
      onConfirm={close}
      onCancel={deleteConfirm}
      contentText={message}
      title={t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.ERROR_MESSAGE.TEXT_2')}
      titleTextStyle={styles.modalTitle}
      contentTextStyle={styles.modalContentTextStyle}
      confirmText={t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.ERROR_MESSAGE.TEXT_4')}
      confirmButtonStyle={styles.modalConfirmButtonStyle}
      confirmButtonTextStyle={styles.confirmButtonTextStyle}
      cancelText={t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.ERROR_MESSAGE.TEXT_3')}
      cancelButtonStyle={styles.modalCancelButtonStyle}
      cancelButtonTextStyle={styles.cancelButtonTextStyle}
    />
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  modalContentTextStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  confirmButtonTextStyle: {
    backgroundColor: secondary500,
    color: white,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
    textTransform: 'uppercase',
  },
  modalConfirmButtonStyle: {
    backgroundColor: secondary500,
    paddingTop: 8,
    paddingBottom: 8,
  },
  modalCancelButtonStyle: {
    paddingTop: 8,
    paddingBottom: 8,
    borderColor: secondary500,
  },
  cancelButtonTextStyle: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 16,
    color: secondary500,
    textTransform: 'uppercase',
  },
})

DeleteModal.propTypes = {
  message: PropTypes.string.isRequired,
  showModalError: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
}
