import React, { useState, createContext, useEffect, useContext } from 'react'
import { Platform } from 'react-native'
import NetInfo from '@react-native-community/netinfo'
import { useDispatch } from 'react-redux'
import { t } from 'i18n-js'

import useOfflineQueue from '@offline/queries/queue'
import useOfflineCrops from '@offline/queries/crops'
import useOfflineDrafts from '@offline/queries/drafts'
import useNetwork from '@utils/network'
import offlineCropQueries from '@offline/queries/crops'
import useOfflineCommon from '@offline/queries/common'
import {
  deleteDraftsByIds,
  syncDraft as syncDraftFirebase,
  getDraftsByCropId,
  getDraftsVerificationByCropIdAndIdentifier,
  insertFileToStorage,
  deleteFilesInStorage,
} from '@services/firebase'
import { urlToBlob } from '@utils/files'
import { ROL_VERIFIER } from '@constants/roles'
import activityTypes from '@constants/activityTypes'
import { lotsActions } from '@store/actions'

import { CommonContext } from '@contextState/common'
import { SYNC_ERROR } from '@modules/common/utils'
import { getObjectId } from '@utils/common'

export const ConnectionContext = createContext({})

const useSync = () => {
  const { showQueue, changeSyncedFlag } = useOfflineQueue()
  const { doRequest } = useNetwork()
  const { displayToggleModalNotification } = useContext(CommonContext)

  const doSync = async () => {
    const queue = await showQueue()

    const requests = queue.map(async (queue) => {
      const { method, url, needsSerialize, files } = queue.params

      if (needsSerialize) {
        const formData = new FormData()
        formData.append('data', JSON.stringify(queue?.data))

        if (files) {
          files
            .filter((el) => !el.persisted)
            .forEach((el) => {
              formData.append('files', el.file)
            })
        }

        try {
          await doRequest({ method, url, data: formData, displayAlert: false })
        } catch (err) {
          if (err.code === SYNC_ERROR.INVALID_LOTS) {
            displayToggleModalNotification({
              text: t('VIEWS').OFFLINE_QUEUE.INVALID_LOTS,
              duration: 15000,
            })
          }
        }
      } else {
        await doRequest({ method, url, data: queue?.data })
      }

      return changeSyncedFlag(queue.id)
    })

    await Promise.all(requests).finally(() => {
      setTimeout(() => alert(t('CONTEXT_STATE').CONNECTION.TEXT_1), 2000)
    })
  }

  return { doSync }
}

export const ConnectionProvider = ({ children }) => {
  const { doSync } = useSync()
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()
  const { selectAllCrops } = useOfflineCrops()
  const { insertCrop, deleteCrop } = offlineCropQueries()
  const { storeAtIndividuals, deleteIndividualCrop } = useOfflineCommon()

  const {
    findDraftsByUnsynchronizedOffline,
    deleteAllDraftOffline,
    insertDraftOffline,
  } = useOfflineDrafts()

  const [isConnected, setIsConnected] = useState(true)
  const [lowConnection, setLowConnection] = useState(false)
  const [isDraftsSynchronizing, setIsDraftsSynchronizing] = useState(false)
  const [isSupportsDatabase, setIsSupportsDatabase] = useState(false)

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const unsubscribe = NetInfo.addEventListener(async (state) => {
        if (
          state.type === 'cellular' &&
          state.details.cellularGeneration !== '4g' &&
          state.details.cellularGeneration !== '3g'
        ) {
          setLowConnection(true)
        } else {
          setLowConnection(false)
        }

        if (state.isInternetReachable) {
          setIsConnected(true)
        } else {
          setIsConnected(false)
        }
      })

      return () => unsubscribe()
    }
  }, [doSync, isConnected])

  const connState = () => {
    NetInfo.fetch().then((state) => {
      return state.isConnected
    })
  }

  /**
   *
   * @param {array | undefined} establishments
   * @param {object} unsynchronizedDraft
   *
   * @return {boolean}
   */
  const hasLotsRemoved = (establishments, unsynchronizedDraft) => {
    if (!establishments || !establishments.length) {
      return true
    }

    const lots = establishments.flatMap((establishment) => establishment.lots)

    let hasLotsRemovedFlag = false

    for (const lotActivity of unsynchronizedDraft.lots) {
      const lot = lots.find((lot) => lot._id === getObjectId(lotActivity))

      if (!lot) {
        hasLotsRemovedFlag = true

        break
      }
    }

    return hasLotsRemovedFlag
  }

  const syncDrafts = async (user, config, displayToggleModalNotification) => {
    if (Platform.OS === 'web') {
      return true
    }

    const [errorFindDraftsOffline, unsynchronizedDrafts] =
      await findDraftsByUnsynchronizedOffline()

    if (errorFindDraftsOffline) {
      alert(errorFindDraftsOffline)

      return
    }

    const allLotsUnsynchronized = []
    const draftsToDelete = []
    const activitiesSearched = []
    const cropsToUpdate = []

    for (const unsynchronizedDraft of unsynchronizedDrafts) {
      const crop = await fetchCrop({
        cropId: unsynchronizedDraft.crop,
        companyId: unsynchronizedDraft.company._id,
      })

      cropsToUpdate.push(crop)

      setIsDraftsSynchronizing(true)

      if (unsynchronizedDraft.deletedAt) {
        draftsToDelete.push(unsynchronizedDraft)

        continue
      }

      const activityHasLotsRemoved = hasLotsRemoved(
        crop.establishments,
        unsynchronizedDraft
      )

      if (activityHasLotsRemoved) {
        displayToggleModalNotification({
          text: t('VIEWS').OFFLINE_QUEUE.INVALID_LOTS,
          duration: 15000,
        })

        continue
      }

      if (unsynchronizedDraft.activity) {
        let activity = activitiesSearched.find(
          (element) => element._id === unsynchronizedDraft.activity
        )

        if (!activity) {
          const response = await doRequest({
            method: 'GET',
            url: `activities/${unsynchronizedDraft.activity}`,
          })

          if (!response.data) {
            continue
          }

          activity = response.data

          activitiesSearched.push(activity)
        }

        const percentOfAchievement = activity.achievements.reduce(
          (accumulator, currentElement) => {
            return accumulator + currentElement.percent
          },
          0
        )

        if (percentOfAchievement >= 100) {
          continue
        }
      }

      for (const evidence of unsynchronizedDraft.evidences) {
        evidence.date = new Date(evidence.date)

        if (!evidence.persisted) {
          evidence.file = await saveFileInStorage(evidence?.file ?? evidence)
        }

        evidence.persisted = true
      }

      const lotsVerified = []

      const lotsSelectedQuantity = unsynchronizedDraft.lots.length

      if (unsynchronizedDraft.tag === activityTypes.ACT_VERIFICATION.key) {
        for (const lot of unsynchronizedDraft.lots) {
          for (const verificationType of crop.verificationTypes) {
            if (
              unsynchronizedDraft.verificationType === verificationType._id &&
              verificationType.lots.find(
                (element) => lot._id === element._id && element.isVerified
              )
            ) {
              lotsVerified.push(lot)
            }
          }
        }

        unsynchronizedDraft.lots = unsynchronizedDraft.lots.filter(
          (element) =>
            !lotsVerified.find((subElement) => subElement._id === element._id)
        )

        const surface = unsynchronizedDraft.lots.reduce(
          (accumulator, { surface }) => accumulator + surface,
          0
        )

        unsynchronizedDraft.surface = surface !== 0 ? surface : undefined
      }

      const user = {
        _id: unsynchronizedDraft.updatedByUserId,
        name: unsynchronizedDraft.updatedByUsername,
      }

      unsynchronizedDraft.isSynchronized = true

      unsynchronizedDraft.createdAt = unsynchronizedDraft.createdAt
        ? new Date(unsynchronizedDraft.createdAt)
        : undefined

      unsynchronizedDraft.updatedAt = unsynchronizedDraft.updatedAt
        ? new Date(unsynchronizedDraft.updatedAt)
        : undefined

      unsynchronizedDraft.deletedAt = unsynchronizedDraft.deletedAt
        ? new Date(unsynchronizedDraft.deletedAt)
        : undefined

      unsynchronizedDraft.dateAchievement = unsynchronizedDraft.dateAchievement
        ? new Date(unsynchronizedDraft.dateAchievement)
        : undefined

      unsynchronizedDraft.dateHarvest = unsynchronizedDraft.dateHarvest
        ? new Date(unsynchronizedDraft.dateHarvest)
        : undefined

      unsynchronizedDraft.dateObservation = unsynchronizedDraft.dateObservation
        ? new Date(unsynchronizedDraft.dateObservation)
        : undefined

      unsynchronizedDraft.dateEstimatedHarvest =
        unsynchronizedDraft.dateEstimatedHarvest
          ? new Date(unsynchronizedDraft.dateEstimatedHarvest)
          : undefined

      unsynchronizedDraft.observations = unsynchronizedDraft.observations
        ?.length
        ? unsynchronizedDraft.observations.map((element) => ({
            ...element,
            createdAt: new Date(element.createdAt),
          }))
        : undefined

      unsynchronizedDraft.signers = unsynchronizedDraft.signers?.length
        ? unsynchronizedDraft.signers.map((element) => ({
            ...element,
            dateToSign: element.dateToSign
              ? new Date(element.dateToSign)
              : undefined,
          }))
        : []

      unsynchronizedDraft.id = unsynchronizedDraft._id

      const draft = await syncDraftFirebase(unsynchronizedDraft, user)

      if (lotsVerified.length) {
        const lotsUnsynchronized = {
          draft: draft.id,
          verificationType: crop.verificationTypes.find(
            (element) => element._id === draft.verificationType
          ),
          lotsVerified,
          lotsSelectedQuantity,
        }

        dispatch(lotsActions.setLotsUnsynchronized(lotsUnsynchronized))

        allLotsUnsynchronized.push(lotsUnsynchronized)
      }
    }

    const draftsIdsToDelete = []

    for (const draftToDelete of draftsToDelete) {
      draftsIdsToDelete.push(draftToDelete._id)

      const namesFilesToDelete = draftToDelete.evidences
        .filter((element) => element.persisted)
        .map((element) => element.name)

      deleteFilesInStorage(
        draftToDelete.activity,
        draftToDelete._id,
        namesFilesToDelete
      )
    }

    deleteDraftsByIds(draftsIdsToDelete)

    await deleteAllDraftOffline()

    const offlineCrops = await selectAllCrops()

    for (const offlineCrop of offlineCrops) {
      const currentCollaborator = offlineCrop.members?.find(
        (element) => element.user._id === user?._id
      )

      let drafts

      if (currentCollaborator?.type === ROL_VERIFIER) {
        drafts = await getDraftsVerificationByCropIdAndIdentifier(
          offlineCrop._id,
          config.companySelected?.identifier
        )
      } else {
        drafts = await getDraftsByCropId(offlineCrop._id)
      }

      for (const draft of drafts) {
        draft.isSynchronized = true
        draft._id = draft.id

        const user = {
          _id: draft.createdByUserId,
          name: draft.createdByUsername,
        }

        await insertDraftOffline(draft, user)
      }
    }

    setIsDraftsSynchronizing(false)

    if (allLotsUnsynchronized.length) {
      let delay = 200

      for (const element of allLotsUnsynchronized) {
        setTimeout(
          () =>
            displayToggleModalNotification({
              text: t('CONTEXT_STATE.CONNECTION.TEXT_2', {
                verificationType: element.verificationType?.codeLabel,
                lotsVerifiedQuantity: element.lotsVerified.length,
                lotsQuantity: element.lotsSelectedQuantity,
              }),
              duration: 15000,
            }),
          delay
        )

        delay += 16000
      }
    }

    let cropList = []
    const identifier = config.companySelected?.identifier

    if (cropsToUpdate.length) {
      try {
        const params = {
          identifier,
          createdAt: Date.now(),
        }

        const { data } = await doRequest({
          method: 'GET',
          url: 'crops/offline',
          params,
          displayAlert: false,
        })

        cropList = data
      } catch (error) {
        console.error(error)
      }
    }

    for (const crop of cropsToUpdate) {
      await deleteCrop(crop._id)
      await deleteIndividualCrop(crop)

      const newCrop = cropList.find((itemCrop) => itemCrop._id === crop._id)

      if (!newCrop) {
        continue
      }

      const [errorInsertCrop] = await insertCrop(
        newCrop,
        config.companySelected?.identifier
      )

      if (errorInsertCrop) {
        console.error('errorInsertCrop')
        console.error(errorInsertCrop)

        continue
      }

      try {
        await storeAtIndividuals('crops', crop._id, {
          companyId: config.companySelected._id,
        })
      } catch (error) {
        console.error(error)
      }
    }

    return true
  }

  const fetchCrop = async ({ cropId, companyId }) => {
    const response = await doRequest({
      method: 'GET',
      url: `crops/${cropId}`,
      version: 'v2',
      params: {
        companyId,
      },
      displayAlert: false,
    })

    return response.data
  }

  const saveFileInStorage = async ({ name, uri, path }) => {
    const blobFile = await urlToBlob({
      url: uri ?? path,
    })

    const evidenceUrl = await insertFileToStorage({
      blobFile,
      fileName: name,
    })

    return evidenceUrl
  }

  return (
    <ConnectionContext.Provider
      value={{
        isConnected,
        lowConnection,
        connectionState: connState,
        syncDrafts,
        isDraftsSynchronizing,
        setIsDraftsSynchronizing,
        isSupportsDatabase,
        setIsSupportsDatabase,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  )
}

export { useSync }
