import React, { useContext, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import AccordionItem from '@components/common/v2/AccordionItem'
import { LanguageContext } from '@contextState/language'
import { primary700, blackMediumEmphasis } from '@styles/palette'
import { ListItemFarm, EmptyItemFarm } from '../index'
import {
  formatSurface,
  statusFarm as enumStatusFarm,
} from '@modules/common/utils'

export const FarmList = ({
  title,
  data,
  statusFarm,
  onLoadMore,
  onPressItem,
}) => {
  const { t } = useContext(LanguageContext)
  const [disableLoadMore, setDisableLoadMore] = useState(false)

  /**
   *
   * @returns boolean
   */
  const showButtonLoadMore = () => data.meta.currentPage < data.meta.totalPages

  /**
   *
   * @param {string|null} province
   * @param {string|null} locality
   * @returns {string}
   */
  const getLocationString = (province, locality) => {
    let location = ''

    if (Boolean(province) && Boolean(locality))
      location = `${locality}, ${province}`

    if (Boolean(province) && !locality) location = `${province}`

    if (!province && Boolean(locality)) location = `${locality}`

    return location
  }

  /**
   *
   * @param {object} farm
   * @param {string} farm.name
   * @param {number} farm.surface
   * @param {string} farm.areaUnit
   * @returns {string}
   */
  const getFarmTitleString = ({ name, surface, areaUnit }) => {
    if (surface) return `${name} - ${formatSurface(surface, areaUnit)}`

    return name
  }

  /**
   *
   * @param {object} farm
   * @param {number} farm.count_lot
   * @param {number} farm.count_available_lot
   *
   * @returns {string}
   */
  const getFarmSubtitleString = ({ count_lot, count_available_lot }) => {
    if (statusFarm === enumStatusFarm.DRAFT) return null

    const SINGULAR = 1

    const textCountLot = count_lot === SINGULAR ? 'TEXT_3' : 'TEXT_4'

    const subtitleCountLot = t(
      `VIEWS.FARM_LIST.COMPONENTS.FARM_LIST.${textCountLot}`,
      {
        count_lot: count_lot,
      }
    )

    if (statusFarm === enumStatusFarm.NOT_AVAILABLE) return subtitleCountLot

    const textCountAvailableLot =
      count_available_lot === SINGULAR ? 'TEXT_5' : 'TEXT_6'

    const subtitleCountAvailableLot = t(
      `VIEWS.FARM_LIST.COMPONENTS.FARM_LIST.${textCountAvailableLot}`,
      {
        count_available_lot: count_available_lot,
      }
    )

    return `${subtitleCountLot} - ${subtitleCountAvailableLot}`
  }

  const handleOnLoadMore = async () => {
    setDisableLoadMore(true)
    await onLoadMore()
    setDisableLoadMore(false)
  }

  const renderItem = (farm) => {
    const location = getLocationString(farm.province, farm.locality)

    const title = getFarmTitleString(farm)
    const subtitle = getFarmSubtitleString(farm)

    const short = statusFarm !== enumStatusFarm.DRAFT

    return (
      <ListItemFarm
        key={farm.uuid}
        title={title}
        titleStyle={styles.listTitle}
        lotInfo={subtitle}
        location={location}
        onPress={() => onPressItem(farm)}
        short={short}
      />
    )
  }

  if (!data?.items?.length) {
    return <EmptyItemFarm title={title} />
  }

  return (
    <View style={styles.container}>
      <AccordionItem
        openByDefault
        title={title}
        subtitle={''}
        key={1}
        titleContainerStyle={styles.cardTitle}
        titleStyle={styles.title}
      >
        {data.items.map(renderItem)}

        {showButtonLoadMore() && (
          <Text
            style={styles.seeMore}
            onPress={!disableLoadMore ? handleOnLoadMore : null}
          >
            {t('VIEWS.FARM_LIST.COMPONENTS.FARM_LIST.TEXT_7')}
          </Text>
        )}
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
  },
  title: {
    fontWeight: '400',
    fontSize: 16,
    color: blackMediumEmphasis,
    lineHeight: 22,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    opacity: 1,
  },
  cardTitle: {
    borderColor: 'transparent',
    borderWidth: 0,
  },
  seeMore: {
    color: primary700,
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    marginTop: 20,
  },
})

FarmList.propTypes = {
  title: PropTypes.string.isRequired,
  farms: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(enumStatusFarm)),
        province: PropTypes.string,
        locality: PropTypes.string,
        surface: PropTypes.number.isRequired,
        count_lot: PropTypes.number.isRequired,
        sum_surface_lot: PropTypes.number.isRequired2,
      })
    ).isRequired,
    meta: PropTypes.shape({
      totalItems: PropTypes.number,
      itemCount: PropTypes.number,
      itemsPerPage: PropTypes.number,
      totalPages: PropTypes.number,
      currentPage: PropTypes.number,
    }),
  }),
  statusFarm: PropTypes.oneOf(Object.values(enumStatusFarm)).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  goToCreateFarm: PropTypes.func,
  onPressItem: PropTypes.func,
}
