import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { blackHighEmphasis, blackMediumEmphasis } from '@styles/palette'
import { green500 } from '@modules/common/styles'
import { CommonIcon } from '@modules/common/components'

export const CompanyDetails = ({
  companyName = '',
  companyIdentifier = '',
  style = {},
  verifiedCompany = false,
}) => {
  return (
    <View style={[styles.container, style]}>
      {Boolean(companyName) && (
        <View style={styles.row}>
          <Text
            style={styles.companyName}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {companyName}
          </Text>
          {verifiedCompany && (
            <View style={styles.iconContainer}>
              <CommonIcon name={'VERIFIED-FILLED'} size={18} color={green500} />
            </View>
          )}
        </View>
      )}

      {Boolean(companyName) && (
        <Text style={styles.companyIdentifier} numberOfLines={1}>
          {companyIdentifier}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
  },
  companyName: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginVertical: 1,
    lineHeight: 24,
    letterSpacing: 0.15,
    textTransform: 'uppercase',
  },
  companyIdentifier: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginStart: 5,
  },
})

CompanyDetails.propTypes = {
  companyName: PropTypes.string,
  companyIdentifier: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  verifiedCompany: PropTypes.bool,
}
