import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { ActivityIndicator, Colors } from 'react-native-paper'

import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import { CropContext } from '@contextState/crop'
import { LicenseContext } from '@contextState/license'
import { LanguageContext } from '@contextState/language'

import useNetwork from '@utils/network'

import { AnimatedScreen } from '@modules/common/components/AnimatedScreen'
import { Details } from '@modules/licenses/screens/LicenseDetail/v1/components/Details'
import ButtonCustom from '@components/common/ButtonCustom'

import USER_TYPES from '@constants/userTypes'
import { grey300, grey500, primary500, white } from '@styles/palette'

const LicenseDetails = ({ navigation, route }) => {
  const { isConnected } = useContext(ConnectionContext)
  const { user } = useContext(AuthContext)
  const { data } = useContext(CropContext)
  const { doRequest } = useNetwork()
  const { licenseSelected, selectLicense } = useContext(LicenseContext)
  const { t } = useContext(LanguageContext)

  const [isLoading, setIsloading] = useState(false)
  const [typeCollaborator, setTypeCollaborator] = useState('')

  const [disableButton, setDisableButton] = useState(false)
  const [textButton, setTextButton] = useState('')
  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      const navigationState = navigation.getState()
      const licenseDetailsRouters = navigationState.routes.filter(
        (item) => item.name === 'LicenseDetails'
      )
      if (licenseDetailsRouters.length === 1) {
        fetchLicenseDetails()
      } else {
        navigation.pop()
      }
    })

    getCollaboratorType()

    return unsubscribe
  }, [])

  /**
   * ACTIONS TO ASSIGN BUTTON TEXT AND ACTIONS CORRECTLY
   */
  useEffect(() => {
    buttonTextShow()
  }, [licenseSelected])

  /**
   * DISABLING BUTTON BASED ON USER TYPE
   */
  useEffect(() => {
    handleDisableButton()
  }, [typeCollaborator, licenseSelected])

  /**
   * FETCH LICENSE DETAILS
   *
   */
  const fetchLicenseDetails = async () => {
    setIsloading(true)

    let data = []

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `licenses/${route.params.licenseId}?cropId=${route.params.cropId}`,
      })

      data = response.data
    }

    selectLicense(data)

    setIsloading(false)
  }

  /**
   * GO TO SIGN LICENSE
   */
  const goToLicenseSign = () => {
    navigation.navigate('LicenseSign', route.params)
  }

  /**
   * GO TO LICENSE LOTS ADD
   */
  const goToNextPage = () => {
    if (licenseSelected.normative) {
      goToSummaryLandscapeSustainability()
    } else {
      goToLicenseLotsAdd()
    }
  }

  /**
   * GO TO SUMMARY LANDSCAPE SUSTAINABILITY
   */
  const goToSummaryLandscapeSustainability = () => {
    navigation.navigate('SummaryLandscapeSustainability', route.params)
  }

  /**
   * GO TO LICENSE LOTS ADD
   */
  const goToLicenseLotsAdd = () => {
    navigation.navigate('LicenseLotsAdd', route.params)
  }

  /**
   * SHOW BUTTON TEXT BY USER TYPE
   */
  const buttonTextShow = () => {
    if (route.params.isReadOnly) {
      setTextButton(t('COMPONENTS').LICENSES.LICENSE_BUTTON.TEXT_3)

      return
    }

    if (licenseSelected?.isSigned) {
      if (licenseSelected?.isApplied) {
        setTextButton(t('COMPONENTS').LICENSES.LICENSE_BUTTON.TEXT_3)
      } else {
        setTextButton(t('COMPONENTS').LICENSES.LICENSE_BUTTON.TEXT_2)
      }
    } else {
      setTextButton(t('COMPONENTS').LICENSES.LICENSE_BUTTON.TEXT_1)
    }
  }

  /**
   * DEFINES THE CURRENT USER RESPONSABILITY IN THE CROP
   */
  const getCollaboratorType = async () => {
    const cropId = route.params.cropId
    const currentCrop = data.find((element) => element._id === cropId)

    if (!currentCrop) {
      return
    }

    const currentUser = currentCrop.members.find(
      (element) => element.user?._id === user._id
    )

    if (!currentUser) {
      return
    }

    setTypeCollaborator(currentUser.type)
  }

  /**
   * HANDLE TO DISABLE BUTTON
   */
  const handleDisableButton = () => {
    if (!licenseSelected?.isSigned) {
      setDisableButton(typeCollaborator !== USER_TYPES.PRODUCER.key)
    } else {
      setDisableButton(
        ![
          USER_TYPES.PRODUCER.key,
          USER_TYPES.PRODUCER_ADVISER.key,
          USER_TYPES.MARKETER.key,
        ].includes(typeCollaborator)
      )
    }
  }

  return (
    <>
      {licenseSelected && !isLoading ? (
        <View style={styles.container}>
          <AnimatedScreen imageBackground={licenseSelected.image}>
            <Details license={licenseSelected} />
          </AnimatedScreen>

          <ButtonCustom
            disabled={disableButton}
            onPress={
              !licenseSelected?.isSigned ? goToLicenseSign : goToNextPage
            }
            styles={[
              styles.button,
              disableButton ? styles.buttonDisabled : null,
            ]}
            labelStyle={
              disableButton ? styles.textButtonDisabled : styles.textButton
            }
            uppercase={false}
          >
            {textButton}
          </ButtonCustom>
        </View>
      ) : (
        <ActivityIndicator
          animating
          color={Colors.green500}
          style={styles.loader}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
  loader: {
    marginTop: 100,
  },
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
    marginHorizontal: 16,
  },
  buttonDisabled: {
    backgroundColor: grey300,
  },
  textButton: {
    color: white,
  },
  textButtonDisabled: {
    color: grey500,
  },
})

LicenseDetails.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default LicenseDetails
