import React from 'react'
import { useMakeUrl } from '@modules/webView/hooks'
import { WebViewResponsive } from '@modules/webView/components'

const WebViewResponsivePage = () => {
  const { urlWebView } = useMakeUrl()
  return urlWebView ? <WebViewResponsive url={urlWebView} /> : null
}

export default WebViewResponsivePage
