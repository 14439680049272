import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, TouchableOpacity, Dimensions } from 'react-native'
import { Paragraph } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { blackHighEmphasis, blackDisabled, primary500 } from '@styles/palette'
import { canSeeFarms, canSeeLicense } from '../utils'
import { ConnectionContext } from '@contextState/connection'
import { companySelectedHasCountry } from '@utils/countries'

const { width } = Dimensions.get('window')

function CustomTabBar({
  state,
  descriptors,
  navigation,
  isPendingAuthorization,
  hasCompany,
  roleSelected,
  currentRouteName,
  companySelected,
}) {
  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const iconName = {
    FarmList: t('NAVIGATION').CUSTOM_TAB_BAR.TEXT_3,
    CropList: t('NAVIGATION').CUSTOM_TAB_BAR.TEXT_4,
    LicenseList: t('NAVIGATION.CUSTOM_TAB_BAR.LICENCES'),
  }

  const disableFarm =
    isPendingAuthorization || !hasCompany || !canSeeFarms({ roleSelected })

  const [routes, setRoutes] = useState({
    FarmList: {
      icon: 'FARMS',
      disabled: disableFarm,
      show: true,
    },
    CropList: {
      icon: 'CROPS',
      disabled: false,
      show: true,
    },
    LicenseList: {
      icon: 'LICENSES',
      disabled: isPendingAuthorization || !isConnected,
      show:
        canSeeLicense({ roleSelected }) &&
        !companySelectedHasCountry(companySelected, 'PRY'),
    },
  })

  useEffect(() => {
    setRoutes((prevState) => ({
      ...prevState,
      LicenseList: {
        ...prevState.LicenseList,
        disabled: isPendingAuthorization || !isConnected,
      },
    }))
  }, [isConnected])

  return (
    <>
      <View style={styles.container}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key]

          const label = options?.tabBarLabel
            ? options.tabBarLabel
            : options?.title
            ? options.title
            : route.name

          const isFocused = route.name === currentRouteName

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            })

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name)
            }
          }

          if (!routes[label].show) {
            return null
          }
          return (
            <TouchableOpacity
              key={index}
              accessibilityRole='button'
              accessibilityStates={isFocused ? ['selected'] : []}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              style={styles.touchable}
              disabled={routes[label].disabled}
            >
              <View style={styles.buttonContainer}>
                <CommonIcon
                  name={routes[label].icon}
                  size={28}
                  color={
                    routes[label].disabled
                      ? blackDisabled
                      : isFocused
                      ? primary500
                      : blackHighEmphasis
                  }
                />

                <Paragraph
                  allowFontScaling={false}
                  style={[
                    {
                      color: routes[label].disabled
                        ? blackDisabled
                        : isFocused
                        ? primary500
                        : blackHighEmphasis,
                      textAlign: 'center',
                    },
                    styles.label,
                  ]}
                >
                  {iconName[label]}
                </Paragraph>
              </View>
            </TouchableOpacity>
          )
        })}
      </View>
    </>
  )
}

const PERCENTAGE_SEPARATION_BETWEEN_ICONS = 0.1

const styles = StyleSheet.create({
  touchable: {
    height: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
  },
  container: {
    backgroundColor: '#FFFFFF',
    height: 56,
    elevation: 3,
    justifyContent: 'space-around',
    flexDirection: 'row',
    padding: 8,
    paddingHorizontal: width * PERCENTAGE_SEPARATION_BETWEEN_ICONS,
  },
})

CustomTabBar.propTypes = {
  state: PropTypes.object.isRequired,
  descriptors: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  isPendingAuthorization: PropTypes.bool.isRequired,
  hasCompany: PropTypes.bool.isRequired,
  roleSelected: PropTypes.object.isRequired,
  companySelected: PropTypes.object.isRequired,
}

export default CustomTabBar
