import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'

import { FabButton, ActivityIndicator } from '@common/components'
import { CompanyDetails } from './components/CompanyDetails'
import { EmptyState } from '@modules/farms/screens/FarmList/v1/components'
import { useFarmList } from './hooks'
import Home from '@modules/home/screens/HomeWithoutCompany'
import { black, carbon400, carbon50 } from '@styles/palette'
import { SearchFarm } from './components'
import { FarmList as List } from './components'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'

const FarmList = ({ navigation }) => {
  const { t } = useContext(LanguageContext)

  const { isConnected } = useContext(ConnectionContext)
  const inputRef = useRef()
  const flatListRef = useRef()

  const [searchFarmText, setSearchFarmText] = useState('')

  const {
    isLoading,
    showEmptyState,
    companyName,
    identifier,
    goToCreateFarm,
    hasPermissionAdd,
    config,
    data,
    nextPage,
    refreshPage,
    goToFarmDraft,
    goToFarmAvailable,
    handleFarmSearch,
    firstPaginate,
    hasSearchedFarm,
    isFocusedScreen,
  } = useFarmList()

  const moveToTop = () =>
    flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 })

  useEffect(() => {
    moveToTop()
  }, [isFocusedScreen, identifier])

  const handleSearch = (value) => {
    handleFarmSearch(value)
    setSearchFarmText(value)
    moveToTop()
  }

  const handleClearSearch = () => {
    handleFarmSearch('')
    setSearchFarmText('')
    inputRef?.current?.blur()
    moveToTop()
  }

  if (
    isLoading &&
    Boolean(!searchFarmText.length) &&
    firstPaginate &&
    config?.companySelected
  )
    return <ActivityIndicator />

  return (
    <View style={styles.container}>
      {!config?.companySelected ? (
        <Home navigation={navigation} />
      ) : showEmptyState() &&
        Boolean(!searchFarmText.length) &&
        !hasSearchedFarm ? (
        <EmptyState />
      ) : (
        <View style={styles.mainView}>
          <CompanyDetails
            companyName={companyName}
            companyIdentifier={identifier}
          />

          <SearchFarm
            placeholder={t(
              'VIEWS.FARM_LIST_V2.COMPONENTS.FARM_SEARCH.PLACEHOLDER'
            )}
            inputRef={inputRef}
            value={searchFarmText}
            onChangeText={handleSearch}
            rightIcon={
              <Icon
                name={'close'}
                size={16}
                color={black}
                onPress={handleClearSearch}
              />
            }
          />

          {showEmptyState() ? (
            <View style={styles.notFound}>
              <Text style={styles.notFountText}>
                {t('VIEWS.FARM_LIST_V2.NOT_FOUND')}
              </Text>
            </View>
          ) : (
            <List
              flatListRef={flatListRef}
              goToFarmAvailable={goToFarmAvailable}
              goToFarmDraft={goToFarmDraft}
              farmsData={data}
              nextPage={nextPage}
              refreshPage={refreshPage}
              isLoading={isLoading}
            />
          )}
        </View>
      )}

      <FabButton
        icon='plus'
        onPress={goToCreateFarm}
        visible={isConnected && hasPermissionAdd}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: carbon50,
  },
  mainView: {
    flex: 1,
    marginTop: 14,
  },
  notFound: {
    marginTop: 16,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  notFountText: {
    fontSize: 14,
    lineHeight: 20,
    color: carbon400,
  },
  company: {
    margin: 16,
  },
})

FarmList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default FarmList
