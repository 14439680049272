import React from 'react'
import PropTypes from 'prop-types'
import { ActivityCardDone, ActivityCardPlanned } from '..'

export const ActivityCardBody = ({
  activityType,
  percent,
  isDirectAchievement,
  farms,
  subTypeActivity,
  achievements,
  status,
  signed,
  signedIf,
  dateObservation,
  dateHarvest,
  storages,
  areaUnitVolume,
  pay,
  unitType,
  unitTypeKey,
  volume,
  supplies,
  suppliesList,
  areaUnit,
  dateOrder,
  dateStart,
  dateEnd,
  envImpactIndex,
  surface,
}) => {
  return (
    <>
      {isDirectAchievement ? (
        <ActivityCardDone
          activityType={activityType}
          percent={percent}
          farms={farms}
          subTypeActivity={subTypeActivity}
          status={status}
          signed={signed}
          signedIf={signedIf}
          supplies={supplies}
          suppliesList={suppliesList}
          envImpactIndex={envImpactIndex}
          pay={pay}
          storages={storages}
          dateObservation={dateObservation}
          dateHarvest={dateHarvest}
          unitType={unitType}
          unitTypeKey={unitTypeKey}
          volume={volume}
          areaUnitVolume={areaUnitVolume}
          areaUnit={areaUnit}
          dateOrder={dateOrder}
          surface={surface}
        />
      ) : (
        <ActivityCardPlanned
          activityType={activityType}
          percent={percent}
          farms={farms}
          subTypeActivity={subTypeActivity}
          dateObservation={dateObservation}
          dateHarvest={dateHarvest}
          storages={storages}
          areaUnitVolume={areaUnitVolume}
          achievements={achievements}
          pay={pay}
          unitType={unitType}
          unitTypeKey={unitTypeKey}
          volume={volume}
          areaUnit={areaUnit}
          dateOrder={dateOrder}
          supplies={supplies}
          suppliesList={suppliesList}
          envImpactIndex={envImpactIndex}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      )}
    </>
  )
}

ActivityCardBody.prototype = {
  activityType: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  isDirectAchievement: PropTypes.string.isRequired,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  achievements: PropTypes.array,
  status: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  dateObservation: PropTypes.string,
  dateHarvest: PropTypes.string,
  storages: PropTypes.array,
  areaUnitVolume: PropTypes.string,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  supplies: PropTypes.array,
  suppliesList: PropTypes.array,
  areaUnit: PropTypes.string,
  dateOrder: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  envImpactIndex: PropTypes.object,
}
