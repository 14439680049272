import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'
import InputSelectDropdown from '@components/inputs/InputSelectDropdown'
import { black } from '@styles/palette'
import { getActiveAgreementTypes } from '../utils'
import { truncateSurface } from '@common/utils/truncateSurface'
import { EActiveAgreementTypes } from '@common/utils'
import useAgreementsTypes from '../hooks/useAgreementsTypes'

export const AgreementForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  readOnly = false,
  onPressLotsInput,
  crop: { areaUnit, cropType },
  formikInitialValues,
}) => {
  const { t } = useContext(LanguageContext)
  const { agreementTypes } = useAgreementsTypes({ cropType })
  const labelLots =
    values?.typeAgreement?.key === EActiveAgreementTypes.CALCULATION_2BSVS
      ? t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')
      : t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.PLACEHOLDER')

  const clearForm = () => {
    setFieldValue('surface', formikInitialValues.surface)
    setFieldValue('lots', formikInitialValues.lots)
    setFieldValue('farm', formikInitialValues.farm)
  }
  return (
    <View>
      <InputSelectDropdown
        id='typeAgreement'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-DOWN'}
            size={20}
            color={readOnly ? 'rgba(0, 0, 0, .4)' : black}
          />
        }
        onChangeText={(value) => {
          if (
            values?.typeAgreement?.key ===
              EActiveAgreementTypes.CALCULATION_2BSVS ||
            (values?.typeAgreement?.key !==
              EActiveAgreementTypes.CALCULATION_2BSVS &&
              value.key === EActiveAgreementTypes.CALCULATION_2BSVS)
          ) {
            clearForm()
          }
          setFieldValue('typeAgreement', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('typeAgreement'))}
        value={values.typeAgreement}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_1.LABEL')}`}
        options={getActiveAgreementTypes(agreementTypes)}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.typeAgreement}
        error={errors.typeAgreement?.value}
      />

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={labelLots}
        label={labelLots}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      {values?.typeAgreement?.key ===
        EActiveAgreementTypes.CALCULATION_2BSVS && (
        <InputText
          id='seedDryContent'
          containerStyle={styles.containerInput}
          value={values.seedDryContent}
          placeholder={`${t(
            'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_4.PLACEHOLDER',
            { areaUnit }
          )}`}
          label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_4.LABEL', {
            areaUnit,
          })}`}
          onBlur={() => setFieldTouched('seedDryContent')}
          touched={touched.seedDryContent}
          error={errors.seedDryContent}
          setFieldValue={setFieldValue}
          editable={Boolean(!readOnly)}
          disabled={Boolean(readOnly)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})

AgreementForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
}
