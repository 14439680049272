import { Platform } from 'react-native'

const scheme = Platform.select({
  ios: 'maps:0,0?q=',
  android: 'geo:0,0?q=',
  web: 'http://www.google.com/maps/place/',
})

/**
 *
 * @param {object} lot
 * @param {string} lot.name
 * @param {object} lot.centerBound
 * @param {number} lot.centerBound.latitude
 * @param {number} lot.centerBound.longitude
 *
 * @returns {string}
 */
export const getLotLocationUrl = (lot) => {
  if (!lot.centerBound) return null

  const { centerBound } = lot
  const { latitude, longitude } = centerBound
  const latLng = `${latitude},${longitude}`
  const label = lot.name

  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
    web: `${scheme}${latLng}`,
  })

  return url
}
