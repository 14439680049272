import CROP from './crop'
import CROP_DETAILS from './crop-details'
import LANDSCAPE_SUSTAINABILITY from './landscape-sustainability'
import CROP_CREATE from './crop-create'
import LOTS_ADD from './lots-add'
import KMZ_ADD from './kmz-add'
import CROP_CREATE_CONFIRM from './crop-create-confirm'
import LICENSES from './licenses'
import LICENSE_DETAILS from './license-details'
import ACTIVITY_APPLICATION_DETAILS from './activity-application-details'
import ACTIVITY_VALIDATION from './activity-validation'
import ACHIEVEMENT_VALIDATION from './achievement-validation'
import LICENCE_SIGN from './licence-sign'
import REGISTER from './register'
import VERIFY_CODE from './verify-code'
import CREATE_PIN from './create-pin'
import ACHIEVEMENT from './achievement'
import ACHIEVEMENT_UNPLANNED from './achievement-unplanned'
import ACTIVITIES from './activities'
import AGREEMENTS from './agreements'
import COLLABORATORS from './collaborator'
import COMMON_ACTIVITIES_DETAILS from './common-activities-details'
import COMMON_ACTIVITIES_EDIT from './common-activities-edit'
import COMMON_ACTIVITIES from './common-activities'
import COMPANY_INTEGRATION_AGREEMENT from './company-integration-agreement'
import COMPANY_INTEGRATION_APP_LIST from './company-integration-app-list'
import COMPANY_INTEGRATION_LINK from './company-integration-link'
import COMPANY_PROFILE from './company-profile'
import COMPANY_SERVICE_INTEGRATION_ADD from './company-service-integration-add'
import COMPANY_SERVICE_INTEGRATION_ACCOUNT from './company-service-integration-account'
import COMPANY_SERVICE_INTEGRATION from './company-service-integration'
import COMPANY_SEVICE_INTEGRATION_SELECTOR from './company-sevice-integration-selector'
import COMPANY_UNLINK_SERVICE_INTEGRATION from './company-unlink-service-integration'
import CROP_CHART from './crop-chart'
import CROP_COLLABORATOR_ADD from './crop-collaborator-add'
import CROP_COLLABORATOR from './crop-collaborator'
import CROP_SERVICE_INTEGRATION from './crop-service-integration'
import FILTERS from './filters'
import HARVEST from './harvest'
import INTEGRATION_DETAILS from './integration-details'
import INTEGRATION_QUEUE from './integration-queue'
import LOT_ADD from './lot-add'
import LOTS_SELECTOR from './lots-selector'
import MONITORING from './monitoring'
import PROFILE from './profile'
import SETUP from './setup'
import SUPPLY_SELECTOR from './supply-selector'
import LICENSE_SIGN from './license-sign'
import LICENSE_LOTS_ADD from './license-lots-add'
import ACHIEVEMENT_LOTS_SURFACE from './achievement-lots-surface'
import ACHIEVEMENT_LOTS_ADD from './achievement-lots-add'
import SUPPLY_DETAIL from './supply-detail'
import SUPPLY_SELECTOR_LOADER from './supply-selector-loader'
import SUMMARY_LANDSCAPE_SUSTAINABILITY from './summary-landscape-sustainability.js'
import SUMMARY_LICENSE_LOTS from './summary-license-lots'
import ACTIVITY_CREATE from './activity-create'
import COLLABORATOR_CREATE from './collaborator-create'
import CROP_SELECT_RESPONSIBLE from './crop-select-responsible'
import SUBLICENSES_SELECT_COMPANIES from './sublicenses-select-companies'
import CROP_STORY from './crop-story'
import FARM_CREATE from '@modules/farms/screens/FarmCreate/v1/translate/es'
import FARM_LIST from '@modules/farms/screens/FarmList/v1/translate/es'
import FARM_LIST_V2 from '@modules/farms/screens/FarmList/v2/translate/es'
import FARM_LOTS_ADD from '@modules/farms/screens/FarmLotsAdd/v1/translate/es'
import FARM_DETAILS from '@modules/farms/screens/FarmDetails/v1/translate/es'
import FARM_LOT_DETAILS from '@modules/farms/screens/FarmLotDetails/v1/translate/es'
import FARM_EDIT_NAME from '@modules/farms/screens/FarmEditName/v1/translate/es'
import FARM_LOT_EDIT_NAME from '@modules/farms/screens/FarmLotEditName/v1/translate/es'
import SIGN_IN from '@modules/authentication/screens/SignIn/v1/translate/es'
import CROP_STORY_LOT_DETAIL from '@modules/cropStory/screens/CropStoryLotDetail/v1/translate/es'
import CROP_LIST from '@modules/crops/screens/CropList/v1/translate/es'
import CROP_FARM_LIST from '@modules/crops/screens/CropFarmList/v1/translate/es'
import CROP_FARM_FIELD_DETAILS from '@modules/crops/screens/CropFarmFieldDetails/v1/translate/es'
import ACHIEVEMENTS_ACTIVITY_LIST from '@modules/activities/screens/achievementsActivityList/v1/translate/es'
import OFFLINE_QUEUE from '@modules/settings/screens/OfflineQueue/v1/translate/es'
import COMPANY_LIST from '@modules/companies/screens/CompanyList/v1/translate/es'
import COMPANY_DETAIL from '@modules/companies/screens/CompanyDetail/v1/translate/es'
import COLLABORATORS_LIST_COMPANY from '@modules/collaborators/screens/CollaboratorListCompany/v1/translate/es'
import COMMON_LOADER from '@modules/loaders/screens/CommonLoader/v1/translate/es'
import COMPANY_ADD from '@modules/companies/screens/AddCompanyScreen/v1/translate/es'
import COMPANY_EDIT from '@modules/companies/screens/EditCompany/v1/translate/es'
import ADD_COLLABORATOR from '@modules/collaborators/screens/AddCollaborator/v1/translate/es'
import SETTINGS from '@modules/settings/screens/SettingsScreen/v1/translate/es'
import COLLABORATORS_LIST_NEW_COMPANY from '@modules/collaborators/screens/CollaboratorsListNewCompany/v1/translate/es'
import ADD_COMPANY_SCREEN from '@modules/companies/screens/AddCompanyScreen/v1/translate/es'
import DELETE_ACCOUNT from '@modules/settings/screens/DeleteAccount/v1/translate/es'
import LANGUAGES from '@modules/settings/screens/Languages/v1/translate/es'
import NOTIFICATIONS from '@modules/notifications/screens/NotificationList/v1/translate/es'
import NOTIFICATION_FILTER from '@modules/notifications/screens/Filters/v1/translate/es'
import CREATE_PIN_SCREEN from '@modules/pin/screens/CreatePin/translate/es'
import PIN_WIZARD from '@modules/pin/screens/Wizard/v1/translate/es'
import SIGN_IN_V2 from '@modules/authentication/screens/SignIn/v2/translate/es'
import VERIFY_PIN from '@modules/common/components/pin/VerifyPin/translate/es'
import COGNITO_CODE from '@modules/authentication/screens/CognitoCode/v1/translate/es'
import SIGN_UP from '@modules/authentication/screens/SignUp/v1/translate/es'
import VERIFY_CODE_SCREEN from '@modules/authentication/screens/VerifyCode/translate/es'
import VALIDATE_TAXID from '@modules/companies/screens/ValidateTaxId/v1/translate/es'
import ACTIVITY_CREATE_CONFIRM from '@modules/activities/screens/ActivityCreateConfirm/v1/translate/es'
import HOME_SCREEN from '@modules/home/screens/HomeWithoutCompany/translate/es'
import WELCOME_COMPANY from '@modules/companies/screens/WelcomeCompany/v1/translate/es'
import LICENSE_LIST from '@modules/licenses/screens/LicenseList/v1/translate/es'
import LICENSE_SIGN_V2 from '@modules/licenses/screens/LicenseSign/v2/translate/es'
import ACTIVITY_LIST from '@modules/activities/screens/activitiesList/v2/translate/es'

export default {
  CROP,
  CROP_DETAILS,
  LANDSCAPE_SUSTAINABILITY,
  CROP_CREATE,
  LOTS_ADD,
  KMZ_ADD,
  CROP_CREATE_CONFIRM,
  LICENSES,
  LICENSE_DETAILS,
  ACTIVITY_APPLICATION_DETAILS,
  ACTIVITY_VALIDATION,
  ACHIEVEMENT_VALIDATION,
  LICENCE_SIGN,
  SIGN_IN,
  REGISTER,
  VERIFY_CODE,
  CREATE_PIN,
  ACHIEVEMENT,
  ACTIVITIES,
  AGREEMENTS,
  COLLABORATORS,
  COMMON_ACTIVITIES_DETAILS,
  COMMON_ACTIVITIES_EDIT,
  COMMON_ACTIVITIES,
  COMPANY_INTEGRATION_AGREEMENT,
  COMPANY_INTEGRATION_APP_LIST,
  COMPANY_INTEGRATION_LINK,
  COMPANY_PROFILE,
  COMPANY_SERVICE_INTEGRATION_ADD,
  COMPANY_SERVICE_INTEGRATION_ACCOUNT,
  COMPANY_SERVICE_INTEGRATION,
  COMPANY_SEVICE_INTEGRATION_SELECTOR,
  COMPANY_UNLINK_SERVICE_INTEGRATION,
  CROP_CHART,
  CROP_COLLABORATOR_ADD,
  CROP_COLLABORATOR,
  CROP_SERVICE_INTEGRATION,
  FILTERS,
  HARVEST,
  INTEGRATION_DETAILS,
  INTEGRATION_QUEUE,
  LOT_ADD,
  LOTS_SELECTOR,
  MONITORING,
  OFFLINE_QUEUE,
  PROFILE,
  SETUP,
  SUPPLY_SELECTOR,
  SUPPLY_SELECTOR_LOADER,
  LICENSE_SIGN,
  LICENSE_LOTS_ADD,
  ACHIEVEMENT_LOTS_SURFACE,
  ACHIEVEMENT_LOTS_ADD,
  ACHIEVEMENT_UNPLANNED,
  SUPPLY_DETAIL,
  SUMMARY_LANDSCAPE_SUSTAINABILITY,
  SUMMARY_LICENSE_LOTS,
  ACTIVITY_CREATE,
  COLLABORATOR_CREATE,
  CROP_SELECT_RESPONSIBLE,
  SUBLICENSES_SELECT_COMPANIES,
  CROP_STORY,
  FARM_CREATE,
  FARM_LIST,
  FARM_LIST_V2,
  FARM_LOTS_ADD,
  FARM_DETAILS,
  FARM_LOT_DETAILS,
  FARM_EDIT_NAME,
  FARM_LOT_EDIT_NAME,
  CROP_STORY_LOT_DETAIL,
  CROP_LIST,
  CROP_FARM_LIST,
  CROP_FARM_FIELD_DETAILS,
  ACHIEVEMENTS_ACTIVITY_LIST,
  COMPANY_LIST,
  COMPANY_DETAIL,
  COLLABORATORS_LIST_COMPANY,
  COMMON_LOADER,
  COMPANY_ADD,
  COMPANY_EDIT,
  ADD_COLLABORATOR,
  SETTINGS,
  COLLABORATORS_LIST_NEW_COMPANY,
  ADD_COMPANY_SCREEN,
  DELETE_ACCOUNT,
  LANGUAGES,
  NOTIFICATIONS,
  NOTIFICATION_FILTER,
  CREATE_PIN_SCREEN,
  PIN_WIZARD,
  SIGN_IN_V2,
  VERIFY_PIN,
  COGNITO_CODE,
  SIGN_UP,
  VERIFY_CODE_SCREEN,
  VALIDATE_TAXID,
  ACTIVITY_CREATE_CONFIRM,
  HOME_SCREEN,
  WELCOME_COMPANY,
  LICENSE_LIST,
  LICENSE_SIGN_V2,
  ACTIVITY_LIST,
}
