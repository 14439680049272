import React from 'react'
import PropTypes from 'prop-types'
import { ActivityProgressBar } from '../ActivityProgressBar'
import { ActivityCardRealization } from '../ActivityCardRealization'

import ACTIVITY_TYPES from '@constants/activityTypes'
import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

export const DirectTillageActivity = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  signed,
  signedIf,
  supplies,
  dateOrder,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_TILLAGE.key) return null

  const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(farms)

  return (
    <>
      <ActivityProgressBar percent={percent} />

      <ActivityCardRealization
        activityType={activityType}
        supplies={supplies}
        farms={farmAndLotsWithSurfaceRealized}
        subTypeActivity={subTypeActivity}
        signed={signed}
        signedIf={signedIf}
        date={dateOrder}
        farmRealized={true}
      />
    </>
  )
}

DirectTillageActivity.prototype = {
  activityType: PropTypes.oneOf([ACTIVITY_TYPES.ACT_TILLAGE.key]),
  percent: PropTypes.number,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  dateOrder: PropTypes.string,
}
