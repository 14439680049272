export default {
  FARMS: 'Campos',
  FARM: 'Campo',
  FIELDS: 'Lotes',
  FIELD: 'Lote',
  SURFACE: 'Superficie: %{surface} %{areaUnit}',
  SUB_ACTIVITY_TYPE: 'Tipo de %{activityType}: %{subActivityType}',
  ESTIMATED_YIELD: 'Rendimento estimado: %{pay} %{unitPay}',
  YIELD: 'Rendimento: %{pay} %{unitPay}',
  EIQ: 'EIQ Gerado: %{eiq}',
  PERCENTAGE_REALIZED: 'Feito %{percentageRealized}%',
  PENDING_SIGNS: '%{pendingSignsQuantity} Assinaturas pendentes',
  PENDING_SIGN: '%{pendingSignsQuantity} Assinatura pendente',
  VERIFICATION_STATUS_VALID: 'Verificado',
  VERIFICATION_STATUS_INVALID: 'Ñão compatível',
  BUTTON_TEXT: 'Ver detalhe',
}
