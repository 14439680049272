import React from 'react'
import { View, Text, FlatList, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { ItemCropType } from '../ItemCropType'
import { carbon600 } from '@styles/palette'

const CONTENT_FLEX = 1

export const ContentBackDropCropTypes = ({
  cropTypes,
  numberColumns,
  t,
  selectItem,
  itemSelected,
}) => {
  const flex = CONTENT_FLEX / numberColumns
  const renderItem = ({ item }) => {
    return (
      <View style={{ flex }}>
        <ItemCropType
          item={item}
          iconName={item.key}
          label={item.keyLabel}
          isSelected={itemSelected === item._id}
          onPress={selectItem}
        />
      </View>
    )
  }
  return (
    <View style={styles.contentBackDrop}>
      <Text style={styles.title}>
        {t(
          'VIEWS.LICENSE_LIST.COMPONENTS.CONTENT_BACKDROP_CROP_TYPES.CROP_TYPES'
        )}
      </Text>
      <Text style={styles.subtitle}>
        {t(
          'VIEWS.LICENSE_LIST.COMPONENTS.CONTENT_BACKDROP_CROP_TYPES.TEXT_INFO'
        )}
      </Text>
      <FlatList
        data={cropTypes}
        keyExtractor={(item) => `backdrop-item-${item._id}`}
        renderItem={renderItem}
        numColumns={numberColumns}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  contentBackDrop: {
    paddingBottom: 68,
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 21,
  },
  subtitle: {
    fontSize: 16,
    color: carbon600,
    marginBottom: 40,
  },
  separator: {
    height: 20,
  },
})

ContentBackDropCropTypes.propTypes = {
  cropTypes: PropTypes.array.isRequired,
  numberColumns: PropTypes.number.isRequired,
}
