import activityTypes from '@constants/activityTypes'
import {
  harvestValidationSchema,
  monitoringValidationSchema,
  agreementValidationSchema,
  verificationValidationSchema,
  achievementUnPlannedValidationSchema,
} from './index'

export const getFormikValidationSchema = (t, tag, isDirectAchievement) => {
  if (isDirectAchievement) {
    return achievementUnPlannedValidationSchema(t, tag)
  }
  switch (tag) {
    case activityTypes.ACT_HARVEST.key:
      return harvestValidationSchema(t)

    case activityTypes.ACT_MONITORING.key:
      return monitoringValidationSchema(t)

    case activityTypes.ACT_AGREEMENTS.key:
      return agreementValidationSchema(t)

    case activityTypes.ACT_VERIFICATION.key:
      return verificationValidationSchema(t)
  }
}
