import { useState, useRef } from 'react'
import { Animated } from 'react-native'
import PropTypes from 'prop-types'

const useFadeAnimation = ({ durationIn, durationOut }) => {
  const animatedValue = useRef(new Animated.Value(0)).current
  const [alreadyIn, setAlreadyIn] = useState(false)

  const fadeIn = () => {
    if (alreadyIn) {
      return
    }

    setAlreadyIn(true)

    Animated.timing(animatedValue, {
      toValue: 1,
      duration: durationIn,
      useNativeDriver: true,
    }).start()
  }

  const fadeOut = () => {
    if (!alreadyIn) {
      return
    }

    setAlreadyIn(false)

    Animated.timing(animatedValue, {
      toValue: 0,
      duration: durationOut,
      useNativeDriver: true,
    }).start()
  }

  return {
    animatedValue,
    fadeIn,
    fadeOut,
  }
}

useFadeAnimation.propTypes = {
  durationIn: PropTypes.number.isRequired,
  durationOut: PropTypes.number.isRequired,
}

export default useFadeAnimation
