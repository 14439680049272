import { fileBinary as type } from '../types'

export const selectFileBinaryAction = {
  setFileBinary: (payload) => ({
    type: type.SET_FILE_BINARY,
    payload,
  }),
  clearFileBinary: (payload) => ({
    type: type.CLEAR_FILE_BINARY,
    payload,
  }),
}
