import { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { black } from '@styles/palette'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { ABOUT_ACTIVITIES } from '@utils/constants'
import { ItemAboutActivities } from '../ItemAboutActivities'

export const AboutActivities = () => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <CommonIcon name={'ACTIVITIES'} size={48} />

      <Text style={styles.title}>
        {t('ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.TITLE')}
      </Text>

      {ABOUT_ACTIVITIES.map((activity, key) => (
        <ItemAboutActivities
          key={key}
          title={activity.TITLE(t)}
          description={activity.DESCRIPTION(t)}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  title: {
    marginTop: 26,
    fontWeight: '700',
    fontSize: 20,
    lineHeight: 28,
    color: black,
    fontFamily: 'Inter',
  },
})
