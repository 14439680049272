import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from 'react'

import { Platform } from 'react-native'
import PropTypes from 'prop-types'
import useNetwork from '@utils/network'
import { AuthContext } from './auth'
import { ConnectionContext } from './connection'
import useOfflineTypes from '../offline/queries/types'
import useOfflineSubTypeActivities from '../offline/queries/subTypeActivities'
import { LanguageContext } from './language'
import { orderMenuOptions } from '@modules/crops/screens/CropList/v1/utils'
import {
  INIT_NOTIFICATION_FILTER,
  cropTypesNotification,
  activityTypesNotification,
} from '@modules/notifications/screens/Filters/v1/utils'
import { cropStages } from '@utils/constants'

export const CommonContext = createContext({})

export const CommonProvider = ({ children }) => {
  const { doRequest } = useNetwork()
  const { isConnected } = useContext(ConnectionContext)
  const [showDeleteIcon, setShowDeleteIcon] = useState(false)
  const [disableDeleteIcon, setDisableDeleteIcon] = useState(false)
  const [showDeleteSupplyIcon, setShowDeleteSupplyIcon] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false)
  const [notification, setNotification] = useState({})
  const { user, config } = useContext(AuthContext)
  const { i18n } = useContext(LanguageContext)
  const [cropTypes, setCropTypes] = useState([])
  const [verificationTypes, setVerificationTypes] = useState([])
  const [unitTypes, setUnitTypes] = useState([])
  const [unitTypesVolume, setUnitTypesVolume] = useState([])
  const [roleTypes, setRoleTypes] = useState([])
  const [agreementTypes, setAgreementTypes] = useState([])
  const [activities, setActivities] = useState([])
  const [evidenceConcept, setEvidencesConcept] = useState([])
  const [storageTypes, setStorageTypes] = useState([])
  const [subTypeActivities, setSubTypeActivities] = useState([])
  const [servicesIntegration, setServicesIntegration] = useState([])
  const [filters, setFilters] = useState({
    cropTypes: [],
    companies: [],
    collaborators: [],
    cropVolume: undefined,
    allCropTypes: [],
    allCompanies: [],
    allCollaborators: [],
    hasAnyFilterActive: false,
  })
  const [countries, setCountries] = useState([])
  const [optionsCountries, setOptionsCountry] = useState([])
  const { storeType, getTypes } = useOfflineTypes()
  const [isFetching, setIsFetching] = useState(false)
  const { syncSubTypeActivities, getOfflineSubTypeActivities } =
    useOfflineSubTypeActivities()
  const [unitTypesSupplies, setUnitTypeSupplies] = useState([])
  const [orderCropsBy, setOrderCropsBy] = useState(orderMenuOptions[0])
  const [cropsFilter, setCropsFilter] = useState([])
  const [isFetchingCropsFilter, setIsFetchingCropsFilter] = useState(false)
  const [fetchCommonIsComplete, setFetchCommonIsComplete] = useState(null)
  const [isFetchingCommonSuccess, setIsFetchingCommonSuccess] = useState(false)
  const [temporalData, setTemporalData] = useState()
  const [stages, setStages] = useState(cropStages)
  const [errorDeepLink, setErrorDeepLink] = useState(null)

  const [notificationFilters, setNotificationFilters] = useState(
    INIT_NOTIFICATION_FILTER
  )

  const fetchUseCallback = useCallback(async (url) => {
    return await doRequest({
      method: 'GET',
      url,
    })
  }, [])

  async function fetchCropTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/croptypes')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('croptypes', data)
      }
    } else {
      data = await getTypes('croptypes')
    }

    setCropTypes(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
        key: el.key,
      }))
    )
  }

  async function fetchRoleTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/roles')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('roletypes', data)
      }
    } else {
      data = await getTypes('roletypes')
    }

    setRoleTypes(
      data.map((el) => ({
        isInactive: el.isInactive,
        label: el.keyLabel,
        value: el.value,
        assignable: el.assignable ?? [],
        canMarkFlags: el.canMarkFlags ?? [],
      }))
    )
  }

  async function fetchUnitTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/unitypes')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('unitypes', data)
      }
    } else {
      data = await getTypes('unitypes')
    }
    setUnitTypes(
      data.map((el) => ({
        label: `${el.key} (${el.keyLabel})`,
        value: el._id,
        key: el.key,
        unitMeasureSystem: el.unitMeasureSystem,
      }))
    )
  }

  async function fetchUnitTypesVolume() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('unit-types-volume')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('unitypesvolume', data)
      }
    } else {
      data = await getTypes('unitypesvolume')
    }
    setUnitTypesVolume(
      data.map((el) => ({
        label: `${el.key} (${el.keyLabel})`,
        value: el._id,
        key: el.key,
        unitMeasureSystem: el.unitMeasureSystem,
      }))
    )
  }

  async function fetchAgreementTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/agreements')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('agreements', data)
      }
    } else {
      data = await getTypes('agreements')
    }

    setAgreementTypes(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
        key: el.key,
      }))
    )
  }

  async function fetchVerificationTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/verification-types')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('verificationTypes', data)
      }
    } else {
      data = await getTypes('verificationTypes')
    }

    setVerificationTypes(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
      }))
    )
  }

  async function fetchActivities() {
    let data = []

    if (isConnected) {
      const response = await fetchUseCallback('commons/activities')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('activities', data)
      }
    } else {
      data = await getTypes('activities')
    }

    setActivities(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
        tag: el.tag,
      }))
    )
  }

  async function fetchEvidenceConcept() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/concepts')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('concepts', data)
      }
    } else {
      data = await getTypes('concepts')
    }
    setEvidencesConcept(
      data.map((el) => ({
        label: el.keyLabel,
        value: el.keyLabel,
        code: el.code,
        _id: el._id,
        tagActivityType: el?.tagActivityType,
      }))
    )
  }

  async function fetchStorageTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/storagetypes')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('storagetypes', data)
      }
    } else {
      data = await getTypes('storagetypes')
    }

    setStorageTypes(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
      }))
    )
  }

  async function fetchServiceIntegration() {
    const response = await fetchUseCallback('commons/integrations')
    setServicesIntegration(
      response.data.map((el) => ({
        label: el.name,
        value: el.erpAgent,
        description: el.description,
        conditions: el.conditions,
      }))
    )
  }

  async function fetchSubTypeActivities() {
    let data = []

    if (isConnected) {
      const response = await fetchUseCallback('activities/subType')

      data = response.data

      if (Platform.OS !== 'web') {
        syncSubTypeActivities()
      }
    } else {
      data = await getOfflineSubTypeActivities()
    }

    setSubTypeActivities(
      data.map((el) => ({
        label: el.codeLabel,
        activityType: el.activityType,
        value: el._id,
      }))
    )
  }

  async function resetFilters(from) {
    if (from === 'HEADER' && filters.hasAnyFilterActive) {
      setFilters({
        ...filters,
        cropTypes: [],
        companies: [],
        collaborators: [],
        cropVolume: undefined,
        hasAnyFilterActive: false,
      })

      return
    }

    setFilters({
      ...filters,
      cropTypes: [],
      companies: [],
      collaborators: [],
      cropVolume: undefined,
      allCropTypes: [],
      allCompanies: [],
      allCollaborators: [],
      hasAnyFilterActive: false,
    })
  }

  function getNotificationFilterValues() {
    const { farmListSelected, cropName, cropTypes, activityTypes } =
      notificationFilters.values

    const farmIds = farmListSelected.map((farm) => farm._id)

    const cropNames = []
    if (cropName.trim().length) {
      cropNames.push(cropName)
    }

    const newCropTypes = cropTypes
      .filter((cropType) => cropType.selected)
      .map((cropType) => cropType.key)

    const newActivityTypes = activityTypes
      .filter((activityType) => activityType.selected)
      .map((activityType) => activityType.key)

    return {
      farmIds,
      cropNames: cropNames,
      cropTypes: newCropTypes,
      activityTypes: newActivityTypes,
    }
  }

  async function resetNotificationFilters() {
    if (
      typeof notificationFilters.callbackResetNotificationFilter === 'function'
    ) {
      notificationFilters.callbackResetNotificationFilter()
    }

    setNotificationFilters(INIT_NOTIFICATION_FILTER)
  }

  async function syncCropTypesWithNotifications() {
    const cropTypesNotificationFilter = cropTypesNotification(cropTypes)

    setNotificationFilters((filters) => {
      const newFilters = { ...filters }

      newFilters.values.cropTypes = [...cropTypesNotificationFilter]

      return newFilters
    })
  }

  async function syncActivityTypesWithNotifications() {
    const activityTypesNotificationFilter =
      activityTypesNotification(activities)

    setNotificationFilters((filters) => {
      const newFilters = { ...filters }
      newFilters.values.activityTypes = [...activityTypesNotificationFilter]

      return newFilters
    })
  }

  async function resetOrderCropsBy() {
    setOrderCropsBy(orderMenuOptions[0])
  }

  async function fetchCountries() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/countries')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('countries', data)
      }
    } else {
      data = await getTypes('countries')
    }

    setCountries(data)
    setOptionsCountry(
      data.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const displayToggleModalNotification = ({
    text,
    duration = 15000,
    extraStyle = {},
  }) => {
    if (text) {
      setNotification({
        text,
        duration,
        extraStyle,
      })
    }

    setIsNotificationModalVisible(!isNotificationModalVisible)
  }

  const closeModalNotification = () => {
    setIsNotificationModalVisible(false)
  }

  const fetchUnitTypesSupplies = async () => {
    try {
      const response = await fetchUseCallback('commons/unit-types-supplies')
      setUnitTypeSupplies(response.data)
    } catch (error) {
      console.error('error fetchUnitSupplies', error)
    }
  }

  async function fetchCropsForFilter() {
    try {
      setIsFetchingCropsFilter(true)
      const { companySelected, companyAdmin } = config
      const identifier = companyAdmin?.identifier || companySelected?.identifier
      const companyId = companySelected?._id
      let url = 'crops'
      if (identifier) {
        url = `${url}?identifier=${identifier}`
      }
      if (companyId) {
        url = `${url}&companyId=${companyId}`
      }
      const response = await fetchUseCallback(url)

      setCropsFilter(response.data)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsFetchingCropsFilter(false)
    }
  }

  const initialFetch = () => {
    setIsFetching(true)
    fetchCropTypes()
    fetchCountries()
    fetchUnitTypes()
    fetchUnitTypesVolume()
    fetchAgreementTypes()
    fetchVerificationTypes()
    fetchActivities()
    fetchEvidenceConcept()
    fetchRoleTypes()
    fetchStorageTypes()
    fetchServiceIntegration()
    fetchSubTypeActivities()
    fetchUnitTypesSupplies()
    setIsFetching(false)
  }

  const onEnableDeleteIcon = () => {
    setDisableDeleteIcon(false)
  }

  const onDisableDeleteIcon = () => {
    setDisableDeleteIcon(true)
  }

  const allCountryData = (countryId) => {
    return countries.find((element) => element._id === countryId)
  }

  const fetchCommonIsReady = () => {
    if (
      cropTypes.length &&
      verificationTypes.length &&
      unitTypes.length &&
      unitTypesVolume.length &&
      roleTypes.length &&
      agreementTypes.length &&
      activities.length &&
      evidenceConcept.length &&
      storageTypes.length &&
      subTypeActivities.length &&
      servicesIntegration.length &&
      countries.length &&
      optionsCountries.length &&
      unitTypesSupplies.length
    ) {
      setFetchCommonIsComplete('success')
    }
  }

  const saveTemporalData = (data) => {
    setTemporalData(data)
  }

  const resetTemporalData = () => {
    setTemporalData(null)
  }

  const clearErrorDeepLink = () => {
    setErrorDeepLink(null)
  }

  useEffect(() => {
    if (fetchCommonIsComplete !== 'success') {
      fetchCommonIsReady()
    }
  }, [
    cropTypes,
    verificationTypes,
    unitTypes,
    unitTypesVolume,
    roleTypes,
    agreementTypes,
    activities,
    evidenceConcept,
    storageTypes,
    subTypeActivities,
    servicesIntegration,
    countries,
    optionsCountries,
    unitTypesSupplies,
    cropsFilter,
  ])

  useEffect(() => {
    if (isFetching) {
      return
    }
    initialFetch()
  }, [i18n?.locale, user?.config?.languageSelected])

  useEffect(() => {
    syncCropTypesWithNotifications()
  }, [cropTypes])

  useEffect(() => {
    syncActivityTypesWithNotifications()
  }, [activities])

  return (
    <CommonContext.Provider
      value={{
        cropTypes,
        verificationTypes,
        unitTypes,
        unitTypesVolume,
        agreementTypes,
        activities,
        evidenceConcept,
        roleTypes,
        servicesIntegration,
        storageTypes,
        subTypeActivities,
        filters,
        countries,
        setFilters,
        resetFilters,
        showDeleteIcon,
        disableDeleteIcon,
        onDisableDeleteIcon,
        onEnableDeleteIcon,
        showDeleteSupplyIcon,
        setShowDeleteSupplyIcon,
        setShowDeleteIcon,
        showDeleteModal,
        setShowDeleteModal,
        closeDeleteModal,
        isNotificationModalVisible,
        displayToggleModalNotification,
        closeModalNotification,
        notification,
        optionsCountries,
        setVerificationTypes,
        allCountryData,
        unitTypesSupplies,
        orderCropsBy,
        setOrderCropsBy,
        resetOrderCropsBy,
        fetchVerificationTypes,
        cropsFilter,
        fetchCropsForFilter,
        isFetchingCropsFilter,
        notificationFilters,
        setNotificationFilters,
        resetNotificationFilters,
        getNotificationFilterValues,
        fetchTypeActivities: fetchActivities,
        fetchCommonIsComplete,
        isFetchingCommonSuccess,
        setIsFetchingCommonSuccess,
        temporalData,
        saveTemporalData,
        resetTemporalData,
        stages,
        setErrorDeepLink,
        clearErrorDeepLink,
        errorDeepLink,
      }}
    >
      {children}
    </CommonContext.Provider>
  )
}

CommonProvider.propTypes = {
  children: PropTypes.object,
}
