import { FastField, Formik } from 'formik'
import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, Text, Image } from 'react-native'
import Input from '@components/common/Input'
import ButtonCustom from '@components/common/ButtonCustom'
import useNetwork from '@utils/network'
import SnackbarCustom from '@components/common/SnackbarCustom'
import { AuthContext } from '@contextState/auth'
import integrations from '@constants/integrations'
import { Card } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'

function CompanyServiceIntegrationAddScreen({ route, navigation }) {
  const { doRequest, loading } = useNetwork()
  const [message, setMessage] = useState('')
  const [visible, setVisible] = useState(false)
  const { selectedProfile } = useContext(AuthContext)
  const [sourceImg, setSourceImg] = useState('')
  const [mode, setMode] = useState('created')
  const [initialValues, setInitialValues] = useState({
    erpAgent: '',
    identifier: '',
    user: '',
    password: '',
    ucropitCompanyId: '',
  })
  const { t } = useContext(LanguageContext)

  const fetchAccountCompany = useCallback(async (identifier, service) => {
    const response = await doRequest({
      method: 'GET',
      url: `exporters/company/${identifier}/${service}`,
    })

    setInitialValues({
      ...initialValues,
      user: response.data.user,
      password: response.data.password,
      identifier: selectedProfile.company?.identifier,
      ucropitCompanyId: selectedProfile.company?._id,
      erpAgent: route.params.service.toLowerCase(),
    })
  }, [])

  React.useEffect(() => {
    if (route.params.mode) {
      setMode(route.params.mode)

      fetchAccountCompany(
        selectedProfile.company?.identifier,
        route.params.service.toLowerCase()
      )
    } else {
      setInitialValues({
        ...initialValues,
        identifier: selectedProfile.company?.identifier,
        ucropitCompanyId: selectedProfile.company?._id,
        erpAgent: route.params.service.toLowerCase(),
      })
    }
  }, [])

  React.useEffect(() => {
    imageSelection(route.params.service.toLowerCase())
  }, [initialValues])

  function imageSelection(service) {
    let image
    switch (service) {
      case 'auravant':
        image = integrations.auravant
        break

      default:
        image = integrations.auravant
    }
    setSourceImg(image)
  }

  async function handleSubmit(values) {
    const request = {
      data: values,
    }

    if (mode !== 'edit') {
      request.method = 'POST'
      request.url = `exporters/company`
    } else {
      request.method = 'PUT'
      request.url = `exporters/company/${selectedProfile.company?.identifier}`
    }

    const response = await doRequest(request)

    if (response) {
      if (mode !== 'edit') {
        setMessage(t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_1)
        setVisible(true)
      } else {
        setMessage(t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_2)
        setVisible(true)
      }

      navigation.navigate('CropList')
    }
  }

  if (!initialValues) return null

  return (
    <View style={styles.container}>
      <Card style={styles.containerCard}>
        <View style={styles.imageContainer}>
          <Image style={styles.styleImage} source={sourceImg} />
        </View>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <View>
                <FastField
                  name='user'
                  component={Input}
                  inputProps={{
                    label: t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_6,
                  }}
                  validate={(value) =>
                    !value && t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_3
                  }
                />
                <FastField
                  name='password'
                  component={Input}
                  inputProps={{
                    label: t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_7,
                    secureTextEntry: true,
                  }}
                  validate={(value) =>
                    !value && t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_3
                  }
                />

                <ButtonCustom isLoading={loading} onPress={handleSubmit}>
                  {mode !== 'edit'
                    ? t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_4
                    : t('VIEWS').COMPANY_SERVICE_INTEGRATION_ADD.TEXT_5}
                </ButtonCustom>
              </View>
              <SnackbarCustom
                visible={visible}
                onToggle={() => setVisible(!visible)}
              >
                <Text>{message}</Text>
              </SnackbarCustom>
            </>
          )}
        </Formik>
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  styleImage: {
    width: 100,
    height: 100,
  },
  containerCard: {
    marginTop: 50,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 50,
  },
  imageContainer: {
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 50,
  },
})

CompanyServiceIntegrationAddScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyServiceIntegrationAddScreen
