import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { black, colorWarningMessage } from '@styles/palette'

export const WarningMessage = ({ message = '' }) => {
  const showMessage = message ? true : false
  return (
    <>
      {showMessage && (
        <View style={styles.container}>
          <Text style={styles.message}>{message}</Text>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 4,
    padding: 12,
    backgroundColor: colorWarningMessage,
    borderRadius: 4,
  },
  companyName: {
    fontSize: 14,
    fontWeight: '500',
    color: black,
  },
})

WarningMessage.propTypes = {
  message: PropTypes.string,
}
