export default {
  TEXT_1: 'EIQ',
  TEXT_2: 'Campo obrigatório',
  TEXT_3: 'Número inválido',
  TEXT_4: 'Número negativo',
  TEXT_5: 'A superfície deve ser maior que 0',
  FIELD_1: {
    LABEL: 'Insumo',
  },
  FIELD_2: {
    LABEL: 'Unidade de medida',
  },
  FIELD_3: {
    LABEL: 'Total',
    KEY: 'Aceito',
  },
  FIELD_4: {
    LABEL: 'Superficie Has',
  },
  FIELD_5: {
    LABEL: 'Quantidade de',
  },
  TEXT_6: 'ha',
  TEXT_7: 'Cancelar',
  TEXT_8: 'Selecionar',
  TEXT_9: 'Procurar o insumo requerido',
  TEXT_10: 'insumos',
}
