import React, { useEffect, useContext, useState } from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { CommonContext } from '@contextState/common'
import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import useOfflineCommon from '@offline/queries/common'
import useOfflineQueue from '@offline/queries/queue'
import useOfflineSupplies from '@offline/queries/supplies'
import useOfflineCrops from '@offline/queries/crops'
import useOfflineUser from '@offline/queries/user'
import useOfflineDrafts from '@offline/queries/drafts'
import useOfflineSubTypeActivities from '@offline/queries/subTypeActivities'
import getDatabase from '@offline/queries/getDataBase'
import { cropsActions } from '@store/actions'
import useNetwork from '@utils/network'
import FullLoaderCommon from './components/FullLoaderCommon'
import { logLastEntry } from '@services/analytics'
import { useSelector } from 'react-redux'
import { MODAL_ERRORS } from '@utils/constants'

const urls = {
  urlCrop: '/crops/crops/',
  urlActivities: '/crops/activities/',
  urlLicenses: '/licenses/',
}

const CommonLoader = () => {
  const activityTypes = useSelector((state) => state.crops.activityTypes)
  const {
    fetchCommonIsComplete,
    setIsFetchingCommonSuccess,
    setErrorDeepLink,
  } = useContext(CommonContext)
  const { isConnected, setIsSupportsDatabase } = useContext(ConnectionContext)
  const { isAuth, user, handleUser, fetchUser } = useContext(AuthContext)
  const { initOfflineQueue } = useOfflineQueue()
  const { initOfflineCommon } = useOfflineCommon()
  const { initOfflineSupply, validateVersionSupplies } = useOfflineSupplies()
  const { initOfflineSubTypeActivities } = useOfflineSubTypeActivities()
  const { initOfflineCrop } = useOfflineCrops()
  const { initOfflineUser } = useOfflineUser()
  const { initOfflineDraft } = useOfflineDrafts()
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()
  const [isFetchReady, setFetchReady] = useState(false)
  const [isCompanySelected, setIsCompanySelected] = useState(true)

  const initOffline = async () => {
    if (Platform.OS === 'web') {
      return
    }

    const db = getDatabase('db.offlinedata')

    if (!db) {
      setIsSupportsDatabase(false)

      return true
    }

    setIsSupportsDatabase(true)

    await Promise.all([
      initOfflineCommon(),
      initOfflineQueue(),
      initOfflineUser(),
      initOfflineCrop(),
      initOfflineSupply(),
      initOfflineDraft(),
      initOfflineSubTypeActivities(),
    ])
  }

  const getActivityTypes = async () => {
    if (isConnected && !activityTypes.length) {
      const response = await doRequest({
        method: 'GET',
        url: `commons/activities`,
        displayAlert: false,
      })
      dispatch(cropsActions.setActivityTypes(response.data))
    }
  }

  const sendLastEntry = async () => {
    if (isConnected) {
      const dataToSend = {
        os: Platform.OS,
        email: user.email,
        date: new Date(),
      }

      logLastEntry(dataToSend)
    }
  }

  const fetchInitial = async () => {
    if (isConnected) {
      await Promise.all([sendLastEntry(), getActivityTypes()])
    }
    if (isAuth && Platform.OS !== 'web') {
      validateVersionSupplies()
    }
    await Promise.all([initOffline()])
    setFetchReady(true)
  }

  const selectCompany = async (companyId) => {
    try {
      const { companies, config } = user
      const { companySelected } = config
      const company = companies.find(
        (company) => company?.company?._id === companyId
      )
      if (!company) {
        throw new Error(MODAL_ERRORS.UNAUTHORIZED)
      }
      if (companySelected._id !== companyId) {
        const data = {
          companySelected: companyId,
        }
        const configResponse = await doRequest({
          method: 'PUT',
          url: `configurations/${user.config._id}`,
          data,
          displayAlert: false,
        })
        await handleUser(configResponse.data)
        await fetchUser()
      }
    } catch (error) {
      setErrorDeepLink(MODAL_ERRORS.UNAUTHORIZED)
    }
    setIsCompanySelected(true)
  }

  useEffect(() => {
    if (fetchCommonIsComplete && isAuth && isFetchReady && isCompanySelected) {
      setIsFetchingCommonSuccess(true)
    }
  }, [fetchCommonIsComplete, isAuth, isFetchReady, isCompanySelected])

  useEffect(() => {
    if (isAuth && !isFetchReady) {
      fetchInitial()
    }
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (
        window.location.pathname.includes(urls.urlCrop) ||
        window.location.pathname.includes(urls.urlActivities) ||
        window.location.pathname.includes(urls.urlLicenses)
      ) {
        const params = new URLSearchParams(window.location.search)
        const companyId = params.get('companyId')
        if (companyId) {
          setIsCompanySelected(false)
          selectCompany(companyId)
        }
      }
    }
  }, [])

  return <FullLoaderCommon />
}

export default CommonLoader
