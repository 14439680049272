import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { carbon400, blackHighEmphasis } from '@styles/palette'

export const ObservationCard = ({ observation }) => {
  const { t } = useContext(LanguageContext)

  return (
    <Card>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.OBSERVATION_CARD.OBSERVATIONS_TITLE'
          )}
        </Text>

        <Text style={styles.text}>{observation}</Text>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    padding: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    color: blackHighEmphasis,
    marginBottom: 2,
  },
})

ObservationCard.propTypes = {
  observation: PropTypes.string.isRequired,
}
