import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { LanguageContext } from '@contextState/language'
import { PressableInfo } from '../PressableInfo'

export const Location = ({ department, lots, goToFarmLotList, hasSurface }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={[styles.container]}>
      {hasSurface() && (
        <PressableInfo
          label={t('VIEWS.FARM_DETAILS.LOCATION')}
          content={department}
          showIcon={false}
          containerStyle={styles.pressableLocation}
        />
      )}

      <PressableInfo
        label={t('VIEWS.FARM_DETAILS.LOTS')}
        content={lots}
        onPress={goToFarmLotList}
        showBorder={true}
        containerStyle={styles.pressableLots}
      />
    </View>
  )
}

Location.propTypes = {
  department: PropTypes.string.isRequired,
  lots: PropTypes.string.isRequired,
  hasSurface: PropTypes.func.isRequired,
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 35,
    marginHorizontal: 20.5,
  },
  pressableLocation: {
    paddingLeft: 11.5,
  },
  pressableLots: {
    paddingLeft: 11.5,
    marginTop: 20,
    paddingBottom: 17,
  },
})
