import React from 'react'
import { StyleSheet } from 'react-native'
import { ActivityIcon } from '@modules/common/components'
import PropTypes from 'prop-types'

export const EiqIcon = ({ name, color }) => {
  return (
    <ActivityIcon name={name} size={14} color={color} style={styles.eiqIcon} />
  )
}

EiqIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

const styles = StyleSheet.create({
  eiqIcon: {
    marginLeft: 7.5,
    marginRight: 12.5,
  },
})
