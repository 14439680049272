import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, View, FlatList } from 'react-native'
import PropTypes from 'prop-types'
import { Title } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import { primary50 } from '@styles/palette'
import ActivityCard from '@modules/cropStory/screens/CropStoryLotDetail/v1/components/ActivityCard'
import activityTypes from '@constants/activityTypes'

function ActivityDetailCard({ cropActivities, areaUnitVolume }) {
  const [activities, setActivities] = useState([])
  const { t } = useContext(LanguageContext)

  /*
  SET ACTIVITIES TO STATE
  */
  useEffect(() => {
    setActivities(cropActivities)
  }, [cropActivities])

  const renderItem = ({ item }) => {
    const isDirectAchievement =
      item.isDirectAchievement ||
      item.tag === activityTypes.ACT_HARVEST.key ||
      item.tag === activityTypes.ACT_MONITORING.key

    return (
      <ActivityCard
        name={item.name}
        activityType={item.tag}
        dateObservation={item.dateObservation}
        dateHarvest={item.dateHarvest}
        pay={item.pay}
        unitType={item.unitType}
        volume={item.volume}
        lots={item.lotsQuantity}
        unitTypeKey={item.unitTypeKey}
        areaUnitVolume={areaUnitVolume}
        surface={item.surface}
        areaUnit={item.areaUnit}
        storages={item.storages}
        percent={item.percent}
        isDirectAchievement={isDirectAchievement}
        farms={item.farms}
        subTypeActivity={item.subTypeActivity}
        achievements={item.achievements}
        status={item.status}
        signed={item.signed}
        signedIf={item.signedIf}
        supplies={item.supplies.supplies}
        envImpactIndex={item.envImpactIndex}
        dateOrder={item.dateOrder}
        dateStart={item.dateStart}
        dateEnd={item.dateEnd}
        suppliesList={item.suppliesList}
      />
    )
  }

  return (
    <>
      <Title style={styles.title}>
        {t('COMPONENTS').BINNACLE.ACTIVITIES_DETAILS_CARD.TEXT_1}
      </Title>

      <View style={styles.activityContainer}>
        <FlatList
          data={activities}
          renderItem={renderItem}
          keyExtractor={(item) => `ACTIVITY_${item._id}`}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  activityContainer: {
    paddingTop: 16,
    // paddingLeft: 16,
    paddingBottom: 16,
    paddingRight: 0,
  },
  title: {
    marginBottom: 10,
    paddingLeft: 16,
    paddingVertical: 5,
    backgroundColor: primary50,
  },
})

ActivityDetailCard.propTypes = {
  cropActivities: PropTypes.array,
}

export default ActivityDetailCard
