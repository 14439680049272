import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'

import {
  PlannedActivityDate,
  ActivityCardRealization,
  ActivityProgressBar,
  Achievements,
} from '..'

import ACTIVITY_TYPES from '@constants/activityTypes'
import { mapPlannedSurfaceFarmsAndLots } from '../../utils'

export const PlannedFertilizationActivity = ({
  activityType,
  farms,
  percent,
  supplies,
  suppliesList,
  achievements,
  dateStart,
  dateEnd,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_FERTILIZATION.key) return null

  const farmAndLotsWithSurfacePlanned = mapPlannedSurfaceFarmsAndLots(farms)

  return (
    <>
      <PlannedActivityDate dateStart={dateStart} dateEnd={dateEnd} />

      <ActivityCardRealization
        activityType={activityType}
        supplies={supplies}
        suppliesList={suppliesList}
        farms={farmAndLotsWithSurfacePlanned}
        contentStyle={styles.plannedRealization}
        showTextDone={false}
        isDirectAchievement={false}
      />

      <ActivityProgressBar percent={percent} />

      <Achievements
        achievements={achievements}
        activityType={activityType}
        isDirectAchievement={true}
      />
    </>
  )
}

const styles = StyleSheet.create({
  plannedRealization: {
    backgroundColor: 'transparent',
    marginTop: 15,
    paddingTop: 0,
    marginBottom: 10,
  },
})

PlannedFertilizationActivity.prototype = {
  activityType: PropTypes.oneOf([ACTIVITY_TYPES.ACT_TILLAGE.key]),
  percent: PropTypes.number.isRequired,
  farms: PropTypes.array.isRequired,
  subTypeActivity: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array.isRequired,
  dateOrder: PropTypes.string,
  suppliesList: PropTypes.array.isRequired,
  areaUnit: PropTypes.string,
  achievements: PropTypes.array.isRequired,
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
}
