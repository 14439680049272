import { StyleSheet, View } from 'react-native'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import InputSelectDropdown from '@components/inputs/InputSelectDropdown'
import InputText from '@components/inputs/InputText'
import EvidencesList from '@components/common/v1/EvidencesList'
import { typePersonOptions } from '@modules/common/utils'
import { identifierName } from '@utils/countries'
import AttachDocumentation from '../AttachDocumentation'

const FormCompany = ({
  formik,
  countriesData,
  allCountryData,
  onDeleteFile,
  onChangeCountry,
  onPressAddFile,
  toEditCompany = false,
  onEndEditingIdentifier,
  errorIdentifier,
}) => {
  const { setFieldValue, values, setFieldTouched, touched, errors } = formik
  const { t } = useContext(LanguageContext)
  return (
    <>
      {!toEditCompany && (
        <InputSelectDropdown
          id='country'
          options={countriesData}
          label={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COUNTRY`)}
          placeholder={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COUNTRY`)}
          value={values.country}
          rightIcon={<CommonIcon name={'CHEVRON-DOWN'} size={20} />}
          onChangeText={(value) => {
            if (onChangeCountry) {
              onChangeCountry(value)
            }
            setFieldValue('country', value)
          }}
          onBlur={() => setTimeout(() => setFieldTouched('country'))}
          touched={touched.country}
          error={errors.country?.value}
          containerStyle={{ marginTop: 16 }}
        />
      )}
      {!toEditCompany && (
        <InputText
          id='identifier'
          value={values.identifier}
          label={identifierName(
            allCountryData(values?.country?.value)?.alpha3Code,
            t
          )}
          placeholder={identifierName(
            allCountryData(values?.country?.value)?.alpha3Code,
            t
          )}
          touched={touched.identifier}
          error={errors.identifier || errorIdentifier}
          onChangeText={(value) => {
            setFieldValue('identifier', value)
          }}
          onBlur={() => {
            setFieldTouched('identifier')
            onEndEditingIdentifier()
          }}
        />
      )}
      {!toEditCompany && (
        <InputText
          id='name'
          value={values.name}
          label={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COMPANY_NAME`)}
          placeholder={t(
            `COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COMPANY_NAME`
          )}
          touched={touched.name}
          error={errors.name}
          onChangeText={(value) => {
            setFieldValue('name', value)
          }}
          onBlur={() => setFieldTouched('name')}
        />
      )}
      <InputSelectDropdown
        id='typePerson'
        rightIcon={<CommonIcon name={'CHEVRON-DOWN'} size={20} />}
        onChangeText={(value) => {
          setFieldValue('typePerson', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('typePerson'))}
        value={values.typePerson}
        placeholder={t(
          `COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COMPANY_TYPE`
        )}
        label={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.COMPANY_TYPE`)}
        options={typePersonOptions(t)}
        touched={!!touched?.typePerson}
        error={errors.typePerson?.value}
        containerStyle={toEditCompany ? { marginTop: 16 } : {}}
      />
      <InputText
        id='address'
        value={values.address}
        label={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.ADDRESS`)}
        placeholder={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.ADDRESS`)}
        touched={touched.address}
        error={errors.address}
        onChangeText={(value) => {
          setFieldValue('address', value)
        }}
        onBlur={() => setFieldTouched('address')}
      />
      <InputText
        id='specificAddress'
        value={values.specificAddress}
        label={t(`COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.SPECIFIC_ADDRESS`)}
        placeholder={t(
          `COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.SPECIFIC_ADDRESS`
        )}
        touched={touched.specificAddress}
        error={errors.specificAddress}
        onChangeText={(value) => {
          setFieldValue('specificAddress', value)
        }}
        onBlur={() => setFieldTouched('specificAddress')}
      />
      <AttachDocumentation
        setFieldValue={setFieldValue}
        values={values}
        onPressAddEvidence={onPressAddFile}
      />
      {values.files && values.files.length > 0 && (
        <View style={styles.listEvidences}>
          <EvidencesList
            evidences={values.files}
            onDelete={onDeleteFile}
            evidenceNameTitle
            canRemovePersisted={false}
          />
        </View>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  listEvidences: {
    marginTop: 20,
  },
})

FormCompany.propTypes = {
  formik: PropTypes.object.isRequired,
  countriesData: PropTypes.array.isRequired,
  allCountryData: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
}

export default FormCompany
