export default {
  TEXT_1: '¿Deseja sair de UCROP.IT?',
  TEXT_2: 'Esta ação deletará a fila de sincronização',
  TEXT_3: 'Sem empresa atribuída',
  TEXT_4: 'Cadastro de empresa',
  TEXT_5: 'EDITAR PERFIL',
  TEXT_6: 'Empresa',
  TEXT_7: 'Integrações',
  TEXT_8: 'Definições',
  TEXT_9: 'Finalizar a sessão',
  TEXT_10: 'Versão %{version} Build %{build}',
  TEXT_11: 'Perfil',
  TEXT_12: 'Idioma',
  TEXT_13: 'Detalhes da empresa',
  TEXT_14: 'Sincronize offline',
  TEXT_15: 'Conectar aplicativo',
  TEXT_16: 'Consultoria',
  SETTINGS: 'Contexto',
  PRODUCTION_BOARD: 'Quadro de produção',
  ADD_COMPANY: 'Adicionar empresa',
}
