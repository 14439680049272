import React, { useCallback, useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { carbon400, blackHighEmphasis } from '@styles/palette'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { useFocusEffect } from '@react-navigation/native'
import { useChangeOutliersHarvest } from '@common/hooks'
import { roundSurface } from '@utils/common'

export const YieldCard = ({
  cropType,
  pay,
  unitTypeKey,
  unitVolume,
  volume,
}) => {
  const { t } = useContext(LanguageContext)
  const {
    changeOutLierHarvest,
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  useFocusEffect(
    useCallback(() => {
      changeOutLierHarvest(pay, unitTypeKey, cropType)
    }, [])
  )

  return (
    <Card>
      <View style={styles.infoContainer}>
        {unitVolume?.key && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.YIELD_CARD.VOLUME_UNIT'
              )}
            </Text>
            <Text style={styles.textBold}>{unitVolume.key}</Text>
          </>
        )}

        {volume && (
          <>
            <Text style={styles.textTitle}>
              {t('VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.YIELD_CARD.VOLUME')}
            </Text>
            <Text style={styles.textBold}>{`${volume}`}</Text>
          </>
        )}

        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.YIELD_CARD.ESTIMATION_TITLE'
          )}
        </Text>
        <Text style={styles.textBold}>{`${roundSurface(
          pay
        )} ${unitTypeKey}`}</Text>

        {canShowOutlierHarvest && isValueOutsideMeanHarvest && (
          <Outlier
            title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
            icon={outlierIcons.InfoFill}
            description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
            type={statusOutlier}
            style={styles.outlier}
          />
        )}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 18,
  },
  outlier: {
    marginBottom: 10,
  },
})

YieldCard.propTypes = {
  unitTypeName: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  pay: PropTypes.number.isRequired,
  cropType: PropTypes.string.isRequired,
}
