import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, FlatList } from 'react-native'

import { FieldItem } from '../'

export const FieldList = ({ fields }) => {
  const fieldRenderItem = ({ item: { name, surface, areaUnit } }) => (
    <FieldItem name={name} surface={surface} areaUnit={areaUnit} />
  )

  return (
    <FlatList
      data={fields}
      style={styles.fieldsContainer}
      renderItem={fieldRenderItem}
      keyExtractor={(_, subIndex) => `FIELD_${subIndex}`}
    />
  )
}

const styles = StyleSheet.create({
  fieldsContainer: {
    paddingVertical: 0,
  },
})

FieldList.prototype = {
  fields: PropTypes.array.isRequired,
}
