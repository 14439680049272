import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { blackHighEmphasis, white } from '@styles/palette'

export const ErrorMessage = ({ message, closeError }) => {
  const { t } = useContext(LanguageContext)
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    if (message) {
      setErrorMessage(true)
    }

    return () => {}
  }, [message])

  const closeModal = () => {
    setErrorMessage(false)
    closeError()
  }

  return (
    <ModalConfirm
      visible={errorMessage}
      onClose={closeModal}
      onConfirm={closeModal}
      title={t('VIEWS').FARM_CREATE.TEXT_1}
      contentText={message}
      titleTextStyle={styles.modalTitle}
      contentTextStyle={styles.modalContentTextStyle}
      confirmText={t('VIEWS').FARM_CREATE.TEXT_2}
      confirmButtonTextStyle={styles.confirmButtonTextStyle}
    />
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  modalContentTextStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  confirmButtonTextStyle: {
    color: white,
    paddingRight: 8,
    paddingLeft: 8,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
  },
})

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  closeError: PropTypes.func.isRequired,
}
