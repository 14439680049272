import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, ScrollView, Dimensions, Platform } from 'react-native'
import { ActivityCardRealization } from '../'

import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

const width = Platform.select({
  web: Dimensions.get('window').width - 40,
  default: Dimensions.get('screen').width - 40,
})

export const Achievements = ({
  achievements,
  activityType,
  pay,
  unitType,
  unitTypeKey,
  volume,
  areaUnitVolume,
  isDirectAchievement = false,
}) => {
  return (
    <ScrollView
      horizontal={true}
      snapToInterval={width}
      snapToAlignment='start'
      decelerationRate='fast'
    >
      {achievements.map((achievement, index) => {
        const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(
          achievement.farms
        )

        return (
          <ActivityCardRealization
            key={`ACHIEVEMENTS_${index}`}
            contentStyle={styles.realization}
            activityType={activityType}
            supplies={achievement.supplies.supplies}
            suppliesList={achievement.suppliesList}
            envImpactIndex={achievement.envImpactIndex}
            farms={farmAndLotsWithSurfaceRealized}
            farmRealized={true}
            subTypeActivity={achievement.subTypeActivity}
            signed={achievement.signed}
            signedIf={achievement.signedIf}
            pay={pay}
            unitType={unitType}
            unitTypeKey={unitTypeKey}
            volume={volume}
            areaUnitVolume={areaUnitVolume}
            areaUnit={achievement.areaUnit}
            date={achievement.dateAchievement}
            showTextDone={true}
            isDirectAchievement={isDirectAchievement}
          />
        )
      })}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  realization: {
    width,
    marginTop: 7,
  },
})

Achievements.prototype = {
  achievements: PropTypes.array.isRequired,
  activityType: PropTypes.string.isRequired,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  areaUnitVolume: PropTypes.string,
  isDirectAchievement: PropTypes.bool,
}
