import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet } from 'react-native'
import { SwiperFlatList as SwiperFlatListComponent } from 'react-native-swiper-flatlist'
import { primary500, surfaceSnackbar } from '@styles/palette'

export const SwiperFlatList = ({
  paginationActiveColor = primary500,
  paginationDefaultColor = surfaceSnackbar,
  items,
  ItemComponent,
  goToNextItem,
  onChangeIndex,
  swiperRef,
}) => {
  return (
    <SwiperFlatListComponent
      data={items}
      showPagination
      ref={swiperRef}
      autoplayLoop={false}
      onChangeIndex={onChangeIndex}
      paginationStyleItem={styles.paginationItem}
      paginationStyle={styles.pagination}
      paginationActiveColor={paginationActiveColor}
      paginationDefaultColor={paginationDefaultColor}
      renderItem={({ item }) => (
        <ItemComponent
          {...item}
          onPress={goToNextItem}
          animationRef={item.animationRef}
        />
      )}
    />
  )
}

const styles = StyleSheet.create({
  paginationItem: {
    width: 8,
    height: 8,
    marginLeft: 0,
    marginRight: 16,
  },
  pagination: {
    alignSelf: 'flex-start',
    height: 40,
    marginLeft: 32,
  },
})

SwiperFlatList.propTypes = {
  paginationActiveColor: PropTypes.string,
  paginationDefaultColor: PropTypes.string,
  items: PropTypes.array,
  ItemComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onDone: PropTypes.func,
}
