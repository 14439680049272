import { useContext } from 'react'
import useNetwork from '@utils/network'

import { statusOutlier, outlierType } from '@constants/outlierData'
import { AuthContext } from '@contextState/auth'

export const useFetchOutliers = () => {
  const { config } = useContext(AuthContext)
  const { doRequest } = useNetwork()

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  const fetchSowingOutlier = async ({ data }) => {
    let sowingOutlierStatus
    if (alpha3Code === 'USA') {
      return
    }

    await Promise.all(
      data.map(async (params) => {
        try {
          const requestConfig = {
            method: 'GET',
            version: 'v1',
            url: 'outliers/sowing',
            params,
            displayAlert: false,
          }

          const { data } = await doRequest(requestConfig)

          if (!data.status) {
            return
          }

          if (statusOutlier[data.status] === outlierType.DANGER) {
            sowingOutlierStatus = outlierType.DANGER

            return
          }

          if (
            statusOutlier[data.status] === outlierType.WARNING &&
            sowingOutlierStatus !== outlierType.DANGER
          ) {
            sowingOutlierStatus = outlierType.WARNING

            return
          }
        } catch (error) {
          console.error(error)
        }
      })
    )

    return sowingOutlierStatus
  }

  const fetchPhytotherapicOutlier = async ({ params }) => {
    let phytotherapicOutlierStatus

    if (alpha3Code === 'USA') {
      return
    }

    try {
      const requestConfig = {
        method: 'GET',
        version: 'v1',
        url: 'outliers/active-ingredients',
        params,
        displayAlert: false,
      }

      const { data } = await doRequest(requestConfig)

      if (!data.length) {
        return
      }

      let highOutlier = {}

      data.forEach((element) => {
        if (!element) {
          return
        }

        if (!highOutlier.level || element.level > highOutlier.level) {
          highOutlier = element
        }
      })

      phytotherapicOutlierStatus = statusOutlier[highOutlier.status]
    } catch (error) {
      console.error(error)
    }

    return phytotherapicOutlierStatus
  }

  return {
    fetchSowingOutlier,
    fetchPhytotherapicOutlier,
  }
}
