import { useContext, useEffect, useState, useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
/* import { useDispatch } from 'react-redux' */
import { useFormik } from 'formik'
import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'
import { useCompanyInfo } from '@modules/common/hooks'
import { AuthContext } from '@contextState/auth'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  parseDataToEdit,
} from '../utils'
import { typePersonOptions } from '@modules/common/utils'

export const useCompanyEdit = ({ t }) => {
  const { doRequest } = useNetwork()
  const navigation = useNavigation()
  const { countries, displayToggleModalNotification } =
    useContext(CommonContext)
  const { user, handleUser } = useContext(AuthContext)
  const { _id } = useCompanyInfo()
  const [formikInitialValues, setFormikInitialValues] = useState(
    getFormikInitialValues()
  )

  const [countriesData, setCountriesData] = useState([])
  const [companyData, setCompanyData] = useState({})
  const [files, setFiles] = useState([])

  useEffect(() => {
    fetchCompany()
  }, [_id])

  const fetchCompany = useCallback(async () => {
    const response = await doRequest({
      method: 'GET',
      url: `companies/${_id}`,
    })
    setCompanyData(response.data)
    setFiles(
      response.data.files.map((file) => ({
        ...file,
        persisted: true,
      }))
    )
  }, [_id])

  useEffect(() => {
    if (!countries.length) {
      return
    }

    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (values) => {
      editCompany(values)
    },
  })
  const { values, setFieldValue, setSubmitting } = formik

  useEffect(() => {
    if (companyData._id) {
      const verifyTypePerson = typePersonOptions(t).find(
        (typePerson) => typePerson.value === companyData.typePerson
      )
      setFormikInitialValues((initialValues) => ({
        ...initialValues,
        typePerson: verifyTypePerson,
        address: companyData.address,
        specificAddress: companyData.addressFloor,
        files,
      }))
    }
  }, [companyData, files])
  const editCompany = async (valuesFormik) => {
    setSubmitting(true)
    const data = parseDataToEdit(valuesFormik)
    try {
      const response = await doRequest({
        method: 'PUT',
        url: `companies/${_id}`,
        version: 'v1.1',
        data: data,
      })
      if (response.status === 200) {
        handleUser({
          ...user,
          config: { ...user.config, companySelected: response.data },
        })
        navigation.goBack()
        setSubmitting(false)
        displayToggleModalNotification({
          text: t('COMPONENTS.COMPANIES.FORM_UPDATE.TEXT_1'),
          duration: 5000,
          extraStyle: styles.snackbar,
        })
      }
    } catch (error) {
      console.error('error al editar empresa', error)
      setSubmitting(false)
    }
  }

  const onPressAddFile = (valuesFile) => {
    const fileExtension = valuesFile.name.substring(
      valuesFile.name.lastIndexOf('.'),
      valuesFile.name.length
    )
    const evidenceFileName = uuidv4() + fileExtension
    const resultFiles = [
      ...values.files,
      {
        file: valuesFile,
        type: valuesFile.type,
        name: valuesFile.name,
        fileNamePath: evidenceFileName,
        originalName: valuesFile.name,
        date: moment.isMoment(valuesFile.date)
          ? valuesFile.date.toDate()
          : moment(),
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
    ]
    setFieldValue('files', resultFiles)
  }

  const onDeleteFile = (key) => {
    const newFiles = values.files.filter((_, index) => index !== key)
    setFieldValue('files', newFiles)
  }

  return {
    countriesData,
    formik,
    onPressAddFile,
    onDeleteFile,
  }
}

const styles = StyleSheet.create({
  snackbar: {
    backgroundColor: '#000',
  },
})
