import { Platform } from 'react-native'

let deleteBeforeRemoveListener

export const preventLeaveViewListener = (
  navigation,
  beforeRemoveListener,
  beforeunloadListener
) => {
  if (deleteBeforeRemoveListener) {
    deleteBeforeRemoveListener()
  }

  deleteBeforeRemoveListener = navigation.addListener(
    'beforeRemove',
    beforeRemoveListener
  )

  if (Platform.OS === 'web') {
    window.removeEventListener('beforeunload', beforeunloadListener, false)
    window.addEventListener('beforeunload', beforeunloadListener, false)
  }

  return () => {
    deleteBeforeRemoveListener()

    if (Platform.OS === 'web') {
      window.removeEventListener('beforeunload', beforeunloadListener, false)
    }
  }
}
