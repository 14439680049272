export default {
  ACTIVITY_TYPE_TITLE: 'Tipo de %{activityType}',
  FIELDS_QUANTITY_TITLE: 'Lotes seleccionados',
  SURFACE_TITLE: 'Superficie',
  PLANNED_DATE_TITLE: 'Planificación estimada de la %{activityType}',
  SINCE: 'Desde %{date}',
  UNTIL: 'Hasta %{date}',
  REALIZED_DATE_TITLE: 'Fecha de realización',
  HARVEST_DATE_TITLE: 'Fecha de cosecha',
  MONITORING_DATE_TITLE: 'Fecha de monitoreo',
  AGREEMENT_TYPE_TITLE: 'Tipo de acuerdo',
}
