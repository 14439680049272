import React, { useContext } from 'react'
import {
  StyleSheet,
  View,
  ImageBackground,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import licenseStates from '@constants/licenseStates'
import { LanguageContext } from '@contextState/language'

const LicensesList = ({ licenses, goToLicenseDetails }) => {
  const { t } = useContext(LanguageContext)

  /**
   * GET LICENSE ICON
   */
  const getLicenseIcon = ({ state }) => {
    let statusIcon = false

    if (
      state === licenseStates.APPLIED.key ||
      state === licenseStates.NEW_COMPANY.key
    ) {
      statusIcon = (
        <CommonIcon
          name='CHECK'
          size={24}
          color='#FFFFFF'
          style={styles.licenseImageHeaderIcon}
        />
      )
    }

    if (
      state === licenseStates.SIGNED.key ||
      state === licenseStates.SELECT_COMPANY.key
    ) {
      statusIcon = (
        <CommonIcon
          name='SIGN'
          size={24}
          color='#FFFFFF'
          style={styles.licenseImageHeaderIcon}
        />
      )
    }

    if (state === licenseStates.SHORT_HAS.key) {
      statusIcon = (
        <CommonIcon
          name='CLOCK'
          size={24}
          color='#333333'
          style={styles.licenseImageHeaderIcon}
        />
      )
    }

    if (state === licenseStates.SHORT_TIME.key) {
      statusIcon = (
        <CommonIcon
          name='CLOCK'
          size={24}
          color='#333333'
          style={styles.licenseImageHeaderIcon}
        />
      )
    }

    return statusIcon
  }

  const getFlagIcon = ({ state }) => {
    let statusIcon = false

    if (licenseStates[state].flag) {
      statusIcon = (
        <CommonIcon
          name={licenseStates[state].flag.icon}
          size={24}
          color={licenseStates[state].flag.color}
          style={styles.flagIcon}
        />
      )
    }

    return statusIcon
  }

  return (
    <>
      <View style={styles.licensesContainer}>
        <FlatList
          data={licenses}
          numColumns={2}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={
                !item.disabled
                  ? styles.licenseContainer
                  : styles.licenseContainerDisabled
              }
              onPress={() => goToLicenseDetails(item)}
            >
              <ImageBackground
                source={{ uri: item.image }}
                style={styles.licenseImage}
              >
                <View
                  style={[
                    styles.licenseImageHeader,
                    {
                      backgroundColor: licenseStates[item.state]?.background,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.licenseImageHeaderText,
                      { color: licenseStates[item.state]?.color },
                    ]}
                  >
                    {licenseStates[item.state]?.name(t)}
                  </Text>

                  {getLicenseIcon(item)}
                </View>

                {licenseStates[item.state]?.flag && (
                  <View
                    style={[
                      styles.licenseImageHeader,
                      {
                        backgroundColor:
                          licenseStates[item.state]?.flag.background,
                      },
                    ]}
                  >
                    {getFlagIcon(item)}
                    <Text
                      style={[
                        styles.licenseImageHeaderText,
                        { color: licenseStates[item.state]?.flag.color },
                        styles.flagTitle,
                      ]}
                    >
                      {licenseStates[item.state]?.flag.name(t)}
                    </Text>
                  </View>
                )}
              </ImageBackground>

              <View
                style={[
                  styles.licenseTexts,
                  licenseStates[item.state]?.flag
                    ? {
                        backgroundColor:
                          licenseStates[item.state]?.flag.background,
                      }
                    : {},
                ]}
              >
                <Text style={styles.licenseName} numberOfLines={2}>
                  {item.name}
                </Text>
                <Text style={styles.licenseDescription} numberOfLines={2}>
                  {item.previewDescription}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          keyExtractor={(item) => `${item._id}`}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  licensesContainer: {
    paddingHorizontal: 2,
  },
  licenseContainer: {
    flex: 1,
    marginHorizontal: 2,
    marginBottom: 10,
  },
  licenseContainerDisabled: {
    flex: 1,
    marginHorizontal: 2,
    opacity: 0.5,
  },
  licenseImage: {
    width: '100%',
    height: 168,
    justifyContent: 'space-between',
  },
  licenseImageHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
  },
  licenseImageHeaderText: {
    flex: 1,
    fontSize: 12,
    fontWeight: '600',
    marginLeft: 15,
    marginRight: 15,
  },
  licenseImageHeaderIcon: {
    marginLeft: 'auto',
    marginRight: 15,
  },
  flagIcon: {
    fontSize: 18,
    marginRight: 'auto',
    marginLeft: 15,
  },
  licenseTexts: {
    height: 110,
    padding: 15,
    backgroundColor: '#F8F8F8',
  },
  licenseName: {
    fontSize: 18,
    fontWeight: '500',
    color: '#333333',
  },
  licenseDescription: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
    marginTop: 5,
  },
  flagTitle: {
    textTransform: 'uppercase',
  },
})

LicensesList.propTypes = {
  licenses: PropTypes.array.isRequired,
  goToLicenseDetails: PropTypes.func.isRequired,
}

export default LicensesList
