import PropTypes from 'prop-types'
import React from 'react'
import { View } from 'react-native'
import { useCompanyInfo } from '@modules/common/hooks'
import { CompanyDetails } from '@common/components'

import { AuthorizationCard } from '../AuthorizationCard'

const PendingCard = ({ username }) => {
  const { identifier, companyName } = useCompanyInfo()
  return (
    <View>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />
      <AuthorizationCard companyName={companyName} username={username} />
    </View>
  )
}

PendingCard.propTypes = {
  username: PropTypes.string.isRequired,
}

export default PendingCard
