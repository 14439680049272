import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, Platform } from 'react-native'
import { Menu, Text, Button } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import { ORDER_MENU_ENUM } from '@modules/common/utils'

export const OrderMenu = ({
  orderMenuOptions,
  orderSelectedOption,
  handleOrderChange,
}) => {
  const { isConnected } = useContext(ConnectionContext)
  const { t } = useContext(LanguageContext)

  const [visibleMenu, setVisibleMenu] = useState(false)

  const handleSelectedOption = (option) => {
    closeOrderMenu()

    handleOrderChange(option)
  }

  useEffect(() => {
    if (!isConnected) {
      const option = orderMenuOptions.find(
        (option) => option.key === ORDER_MENU_ENUM.ENABLED_OFFLINE
      )

      handleOrderChange(option)
    }
  }, [isConnected])

  const openOrderMenu = useCallback(() => setVisibleMenu(true), [])
  const closeOrderMenu = useCallback(() => setVisibleMenu(false), [])

  const hiddenMenu = (option) => {
    return (Platform.OS === 'web' &&
      option.key === ORDER_MENU_ENUM.ENABLED_OFFLINE) ||
      (!isConnected && !option.activeOffline)
      ? false
      : true
  }

  return (
    <View style={styles.container}>
      <Text style={styles.textFilter} numberOfLines={1} ellipsizeMode='tail'>
        {t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_1}:{' '}
        {orderSelectedOption?.title(t)}
      </Text>

      <Menu
        visible={visibleMenu}
        onDismiss={closeOrderMenu}
        anchor={
          <Button onPress={openOrderMenu}>
            <CommonIcon name={'SORT'} size={25} color={blackHighEmphasis} />
          </Button>
        }
      >
        {orderMenuOptions.filter(hiddenMenu).map((option, index) => (
          <Menu.Item
            key={index}
            title={option.title(t)}
            onPress={() => handleSelectedOption(option)}
            disabled={!isConnected && !option.activeOffline}
          />
        ))}
      </Menu>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    paddingRight: 0,
  },
  textFilter: {
    flex: 1,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    color: blackHighEmphasis,
  },
})

OrderMenu.propTypes = {
  handleOrderChange: PropTypes.func.isRequired,
  orderMenuOptions: PropTypes.array.isRequired,
  orderSelectedOption: PropTypes.object,
}
