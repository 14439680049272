/* eslint-disable no-useless-catch */
import React, { useState, createContext, useContext } from 'react'
import { Platform } from 'react-native'
import PropTypes from 'prop-types'
import useNetwork from '@utils/network'
import useOfflineUser from '../offline/queries/user'
import { ConnectionContext } from './connection'
import companyTypes from '../constants/companyTypes'
import { TYPE_UCROPIT, TYPE_VERIFIER, TYPE_PRODUCER } from '@utils/constants'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'
import { authActions } from '@store/actions'
import { useDispatch } from 'react-redux'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const { doRequest } = useNetwork()
  const [isAuth, setAuth] = useState(false)
  const [user, setUser] = useState(false)
  const [config, setConfig] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState({ isAdmin: true })
  const [companyAllow, setCompanyAllow] = useState(true)
  const [companyIsSynchronized, setCompanyIsSynchronized] = useState(false)
  const { syncUser, selectUser } = useOfflineUser()
  const { isConnected } = useContext(ConnectionContext)
  const [isCompanyUcropit, setIsCompanyUcropit] = useState(false)
  const [isVerifierUcropit, setIsVerifierUcropit] = useState(false)
  const [isCompanyProducer, setIsCompanyProducer] = useState(false)
  const [isPendingAuthorization, setIsPendingAuthorization] = useState(false)
  const dispatch = useDispatch()

  function handleUser(data, config = true, cb) {
    setUser(data)
    if (config) {
      setIsPendingAuthorization(
        data.collaboratorRequest.find(
          (request) =>
            request.company === data?.config.companySelected?._id &&
            request.user === data?._id
        )?.status === COLLABORATORS_REQUEST_STATUS.PENDING
      )
      setConfig({ ...data.config })
    }

    if (data.config.companySelected) {
      setSelectedProfile(
        data.companies.find(
          (company) =>
            company.company?._id === data?.config?.companySelected?._id ||
            company.company?._id === data?.config?.companyAdmin?._id
        ) || { isAdmin: false }
      )

      const types = data.config.companySelected.types
      const typesAdmin = data.config?.companyAdmin?.types
      const isUcropit =
        Boolean(data.config?.companyAdmin) ||
        Boolean(typesAdmin?.find((type) => type?.name === TYPE_UCROPIT))
      setIsCompanyUcropit(isUcropit)

      setIsVerifierUcropit(
        Boolean(typesAdmin?.find((type) => type?.name === TYPE_VERIFIER))
      )

      setIsCompanyProducer(
        Boolean(types.find((type) => type?.name === TYPE_PRODUCER))
      )

      if (data.collaboratorRequest && data.collaboratorRequest.length > 0) {
        const found = data.collaboratorRequest.find(
          (el) => el.company === data.config.companySelected._id
        )

        if (found !== undefined && found.status !== 'accepted') {
          setCompanyAllow(false)
        } else {
          setCompanyAllow(true)
        }
      } else {
        setCompanyAllow(true)
      }
      dispatch(authActions.removeEmailOfWelcomeCompanyList(data?.email))
    }

    if (cb) cb()
  }

  async function fetchUser() {
    try {
      let user

      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          url: 'auth/me',
        })

        user = response.data

        if (Platform.OS !== 'web') {
          syncUser(user)
        }

        handleUser(user)
      } else {
        user = await selectUser()

        handleUser(user)
      }

      return user
    } catch (err) {
      throw err
    }
  }

  async function fetchCompany(identifier) {
    try {
      const response = await doRequest({
        method: 'GET',
        url: `companies?identifier=${identifier}`,
      })
      return response.data[0]
    } catch (err) {
      console.warn(err)
    }
  }

  async function addCompanyConfigUser(id, companyId, identifier) {
    try {
      const response = await doRequest({
        method: 'PUT',
        url: `configurations/${id}/companies?identifier=${identifier}`,
        data: { companySelected: companyId },
      })
      return response.data
    } catch (err) {
      console.warn(err)
    }
  }

  async function canSynchronized(id) {
    const company = await fetchCompany(id)
    if (
      company &&
      company.servicesIntegrations &&
      company.servicesIntegrations.length > 0
    ) {
      setCompanyIsSynchronized(true)
    } else {
      setCompanyIsSynchronized(false)
    }
  }

  const isCompanyVerifiers = () => {
    const types = config?.companySelected?.types ?? []
    return Boolean(
      types.find((type) => type?.name === companyTypes.VERIFIERS.key)
    )
  }

  const countryAuth = () => {
    return config?.companySelected?.country
  }

  return (
    <AuthContext.Provider
      value={{
        setAuth,
        isAuth,
        user,
        config,
        fetchUser,
        handleUser,
        selectedProfile,
        companyAllow,
        fetchCompany,
        addCompanyConfigUser,
        canSynchronized,
        companyIsSynchronized,
        setUser,
        isCompanyVerifiers,
        isCompanyUcropit,
        isVerifierUcropit,
        countryAuth,
        isCompanyProducer,
        isPendingAuthorization,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.object,
}
