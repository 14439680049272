import React, { useContext } from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { kmzEmpty } from '@constants/images'
import { blackHighEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { CompanyDetails } from '@modules/farms/screens/FarmLotsAdd/v1/components'

export const EmptyState = () => {
  const { t } = useContext(LanguageContext)
  const { config } = useContext(AuthContext)

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={config.companySelected?.name}
        companyIdentifier={config.companySelected?.identifier}
      />

      <Image source={kmzEmpty} style={styles.emptyImage} />

      <Text style={styles.message}>
        {t('VIEWS.FARM_LIST.COMPONENTS.EMPTY_STATE.TEXT_1')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emptyImage: {
    width: 280,
    height: 220,
    marginTop: 55,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  message: {
    paddingHorizontal: 26,
    marginTop: 63,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
})
