import React, { useContext } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { formatDateLabel } from '@modules/common/utils'
import { carbon400, blackHighEmphasis } from '@styles/palette'
import { CommonIcon } from '@modules/common/components'

const ICON_PDF = 'FILETYPE-PDF'
const ICON_TXT = 'FILETYPE-TXT'
const ICON_DOCUMENT = 'FILETYPE-DOC'

export const EvidenceCard = ({ evidences = [] }) => {
  const { t } = useContext(LanguageContext)

  const RenderEvidenceIcon = ({ fileName, filePath }) => {
    if (
      fileName.indexOf('.png') !== -1 ||
      fileName.indexOf('.jpg') !== -1 ||
      fileName.indexOf('.jpeg') !== -1
    ) {
      return (
        <Image
          source={{
            uri: filePath,
          }}
          style={styles.evidenceImage}
        />
      )
    }

    let ICON

    if (fileName.indexOf('.pdf') !== -1) {
      ICON = ICON_PDF
    } else if (fileName.indexOf('.txt') !== -1) {
      ICON = ICON_TXT
    } else {
      ICON = ICON_DOCUMENT
    }

    return <CommonIcon name={ICON} size={45} style={styles.evidenceIcon} />
  }

  const RenderEvidence = ({
    description,
    fileName,
    uploadedBy,
    uploadedAt,
    filePath,
  }) => {
    return (
      <View style={styles.evidenceContainer}>
        <RenderEvidenceIcon
          fileName={fileName}
          filePath={filePath}
        ></RenderEvidenceIcon>

        <View style={styles.textContainer}>
          <Text style={styles.textBold}>{description}</Text>

          <Text style={styles.text}>{fileName}</Text>

          <Text style={styles.text}>{uploadedBy}</Text>

          <Text style={styles.text}>
            {formatDateLabel({ t, date: uploadedAt })}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <Card>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.EVIDENCE_CARD.EVIDENCE_TITLE'
          )}
        </Text>

        {evidences.map(
          (
            { description, fileName, uploadedBy, uploadedAt, filePath },
            key
          ) => (
            <RenderEvidence
              key={key}
              description={description}
              fileName={fileName}
              uploadedBy={uploadedBy}
              uploadedAt={uploadedAt}
              filePath={filePath}
            />
          )
        )}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  evidenceContainer: {
    flexDirection: 'row',
    marginTop: 24,
  },
  evidenceImage: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 4,
    marginRight: 15,
    marginTop: 5,
  },
  evidenceIcon: {
    marginRight: 20,
    marginTop: 5,
  },
  textContainer: {
    flex: 1,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
  },
})

EvidenceCard.propTypes = {
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
}
