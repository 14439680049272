import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'

import { blackHighEmphasis } from '@styles/palette'
import { ActivityCardHeader, ActivityCardBody } from '../'

const ActivityCard = ({
  name,
  activityType,
  dateObservation,
  dateHarvest,
  percent,
  isDirectAchievement,
  farms,
  subTypeActivity,
  achievements,
  status,
  signed,
  signedIf,
  storages,
  areaUnitVolume,
  pay,
  unitType,
  unitTypeKey,
  volume,
  supplies,
  suppliesList,
  envImpactIndex,
  areaUnit,
  dateOrder,
  dateStart,
  dateEnd,
  surface,
}) => {
  return (
    <View style={styles.container}>
      <ActivityCardHeader name={name} activityType={activityType} />

      <ActivityCardBody
        storages={storages}
        percent={percent}
        isDirectAchievement={isDirectAchievement}
        farms={farms}
        subTypeActivity={subTypeActivity}
        activityType={activityType}
        achievements={achievements}
        status={status}
        signed={signed}
        signedIf={signedIf}
        dateObservation={dateObservation}
        dateHarvest={dateHarvest}
        areaUnitVolume={areaUnitVolume}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        supplies={supplies}
        suppliesList={suppliesList}
        envImpactIndex={envImpactIndex}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        dateStart={dateStart}
        dateEnd={dateEnd}
        surface={surface}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 17,
  },
  date: {
    marginTop: 36,
    fontSize: 12,
    color: blackHighEmphasis,
  },
  surface: {
    color: blackHighEmphasis,
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 22,
    marginTop: 12,
  },
  farmList: {
    marginRight: 10,
  },
})

export default ActivityCard

ActivityCard.prototype = {
  name: PropTypes.string,
  activityType: PropTypes.string,
  dateObservation: PropTypes.string,
  dateHarvest: PropTypes.string,
  percent: PropTypes.string.isRequired,
  isDirectAchievement: PropTypes.string.isRequired,
  farms: PropTypes.array.isRequired,
  subTypeActivity: PropTypes.string,
  achievements: PropTypes.string,
  status: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  storages: PropTypes.array,
  areaUnitVolume: PropTypes.string,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  supplies: PropTypes.array,
  suppliesList: PropTypes.array,
  envImpactIndex: PropTypes.object,
  areaUnit: PropTypes.string,
  dateOrder: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
}
