import MODAL_UPLOAD_FILE from '../../components/ModalUploadFile/translate/es'
import APPROVE_MODAL from '../../components/ApproveCompanyModal/translate/es'
export default {
  TEXT_1: 'Fisica',
  TEXT_2: 'Jurídica',
  TEXT_3: 'País',
  TEXT_4: 'Personería',
  TEXT_5: 'Dirección',
  TEXT_6: 'Piso/Depto/Oficina',
  TEXT_7: 'Editar empresa',
  TEXT_8: 'AGREGAR DOCUMENTACIÓN',
  TEXT_9: 'Volver a campos',
  TEXT_10: 'Detalle Empresa',
  APPROVE: 'Aprobar empresa',
  COMPONENTS: {
    MODAL_UPLOAD_FILE,
    APPROVE_MODAL,
  },
}
