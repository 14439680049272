import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'

import ListItem from '@components/common/ListItem'
import AccordionItem from '@components/common/v2/AccordionItem'
import { LanguageContext } from '@contextState/language'

export const NotLotsAvailable = () => {
  const { t } = useContext(LanguageContext)

  return (
    <AccordionItem
      openByDefault
      title={t('VIEWS.FARM_LIST.COMPONENTS.NO_LOTS_AVAILABLE.TEXT_1')}
      subtitle={''}
      key={3}
      titleContainerStyle={styles.cardTitle}
      titleStyle={styles.title}
    >
      <ListItem
        onPress={() => {}}
        key={2}
        title={'La Juana - 120 ha'}
        titleStyle={styles.listTitle}
        subtitle={'2 lotes \nDepartamento, Provincia'}
      />
    </AccordionItem>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: '400',
    fontSize: 16,
    color: '#333',
    lineHeight: 22,
  },
  list: {
    marginTop: 10,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginTop: 19,
  },
  contentAccordion: {
    paddingRight: 10,
  },

  cardTitle: {
    borderColor: 'transparent',
    borderWidth: 0,
  },
})
