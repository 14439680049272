import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { ActivityIndicator as AIndicator } from 'react-native-paper'

import { primary500 } from '@styles/palette'

export const ActivityIndicator = ({
  size = 'large',
  color = primary500,
  containerStyle = {},
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <AIndicator animating size={size} color={color} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

ActivityIndicator.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}
