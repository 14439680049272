import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import ActionSheet from 'react-native-actions-sheet'
import DelegateValidations from './DelegateValidations'
import PropTypes from 'prop-types'

const ActionSheetDelegateValidations = ({
  reference,
  currentKey,
  findCropCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToCollaboratorsDelegateSignatures,
  onClose,
  notfound = false,
}) => {
  return (
    Platform.OS !== 'web' && (
      <ActionSheet ref={reference} containerStyle={styles.actionStyles}>
        <View style={styles.containerActionSheet}>
          <DelegateValidations
            notfound={notfound}
            currentKey={currentKey}
            findCropCollaborator={findCropCollaborator}
            isFetchingCrop={isFetchingCrop}
            setDisabled={setDisabled}
            getTitleMenu={getTitleMenu}
            goToCollaboratorsDelegateSignatures={() =>
              goToCollaboratorsDelegateSignatures()
            }
            onClose={onClose}
          />
        </View>
      </ActionSheet>
    )
  )
}

const styles = StyleSheet.create({
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerActionSheet: {
    height: 370,
    marginHorizontal: 10,
  },
})

ActionSheetDelegateValidations.propTypes = {
  reference: PropTypes.object.isRequired,
  currentKey: PropTypes.number,
  findCropCollaborator: PropTypes.array.isRequired,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToCollaboratorsDelegateSignatures: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  notfound: PropTypes.bool,
}

export default ActionSheetDelegateValidations
