export default {
  TEXT_1: 'Perfil',
  TEXT_2: 'Definições',
  TEXT_3: 'Sincronização',
  TEXT_4: 'Fila de sincronização',
  TEXT_5: 'Cultivos',
  TEXT_6: 'Filtros',
  TEXT_7: 'História do cultivo',
  TEXT_8: 'Detalhe sincronização',
  TEXT_9: 'Cadastro de empresa',
  TEXT_10: 'Colaboradores',
  TEXT_11: 'Adicionar Colaboradores',
  TEXT_12: 'Sincronizar Cultivo',
  TEXT_13: 'Integrações',
  TEXT_14: 'Integrações',
  TEXT_15: 'Opções integração',
  TEXT_16: 'Fila de integração',
  TEXT_17: 'Credenciais de integração',
  TEXT_18: 'Minha empresa',
  TEXT_19: 'Atividades',
  TEXT_20: 'Adicionar acordo',
  TEXT_21: 'Editar acordo',
  TEXT_22: 'Adicionar Observação',
  TEXT_23: 'Editar observação',
  TEXT_24: 'Agregar Colheita',
  TEXT_25: 'Editar Colheita',
  TEXT_26: 'Planejar Semeadura',
  TEXT_27: 'Planejar Labrança',
  TEXT_28: 'Planejar Fertilização',
  TEXT_29: 'Planejar Aplicação',
  TEXT_30: 'Planejar Colheita',
  TEXT_31: 'Atividade Semeadura',
  TEXT_32: 'Atividade Labrança',
  TEXT_33: 'Atividade Fertilização',
  TEXT_34: 'Atividade Aplicação',
  TEXT_35: 'Atividade Colheita',
  TEXT_36: 'Realizar semeadura',
  TEXT_37: 'Realizar Labrança',
  TEXT_38: 'Realizar Fertilização',
  TEXT_39: 'Realizar Aplicação',
  TEXT_40: 'Realizar Colheita',
  TEXT_41: 'Detalhe realização',
  TEXT_42: 'Confirme a área realizada',
  TEXT_43: 'Selecione a entrada',
  TEXT_44: 'Detalhe de entrada',
  TEXT_45: 'Detalhe do lote',
  TEXT_46: 'Adicionar %{activityType}',
  TEXT_47: 'Editar %{activityType}',
  TEXT_48: 'Detalhe %{activityType}',
  TEXT_49: 'Empresa Associada de Licença',
  TEXT_50: 'Campos',
  TEXT_51: 'Adicionar lotes ao campo',
  TEXT_52: 'Novo Campo',
  TEXT_53: 'Campo',
  TEXT_54: 'Lotes',
  TEXT_55: 'Lote',
  TEXT_56: 'Atividade Semeadura',
  TEXT_57: 'Atividade Labrança',
  TEXT_58: 'Atividade Fertilização',
  TEXT_59: 'Atividade Aplicaçãon',
  TEXT_60: 'Editar nome',
  TEXT_61: 'Realizar %{activityType}',
  TEXT_62: 'Detalhe do lote',
  TEXT_63: 'Campos de cultivo e parcelas',
  TEXT_64: 'Lote',
  TEXT_65: 'Empresas aconselhadas',
  TEXT_66: 'Empresas',
  TEXT_67: 'Campos de cultivo e parcelas',
  COLLABORATORS: 'Colaboradores',
  MANAGE_COMPANY: 'Gerenciar nova empresa',
  EDIT_COMPANY: 'Editar empresa',
  ADVISE_COMPANY: 'Aconselhar uma nova empresa',
  ADD_COLLABORATORS: 'Adicionar Colaborador',
  SETTINGS: 'Contexto',
  ADD_PRODUCER_ADMIN: 'Adicionar Produtor - Administrador',
  ADD_ADVISER_RESPONSIBLE: 'Adicionar Consultor - Responsável',
  DELETE_ACCOUNT: 'Excluir conta',
  LANGUAGES: 'Língua',
  LICENCES: 'Licenças',
  FILTERS: 'Filtrar por:',
  SELECT_FARM: 'Selecione Campo',
  FIELD_STORY: 'História do lote',
}
