import React from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
  ScrollView,
} from 'react-native'

import { CommonIcon } from '@modules/common/components'
import InputText from '@components/inputs/InputText'
import { grayDA, blackMediumEmphasis, grayBackground } from '@styles/palette'

export const InputSelect = ({
  id,
  name,
  label,
  placeholder,
  value,
  data = [],
  touched,
  disabled,
  multiline,
  keyboardType,
  inputStyle,
  onChangeText,
  onBlur,
  onfocus,
  onSelected,
  contentStyle = {},
  contentDataStyle = {},
  itemStyle = {},
  emptyStateComponent,
  showEmptyState,
}) => {
  return (
    <View style={[styles.container, contentStyle]}>
      <InputText
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChangeText={onChangeText}
        onBlur={onBlur}
        onfocus={onfocus}
        disabled={disabled}
        touched={touched}
        multiline={multiline}
        keyboardType={keyboardType}
        inputStyle={inputStyle}
        inputContainerStyle={styles.inputContainerStyle}
        leftIcon={<CommonIcon name='SEARCH' size={25} />}
      />
      <View style={styles.containerData}>
        {Boolean(data && data.length) && (
          <ScrollView style={[styles.contentData, contentDataStyle]}>
            {data.map((item, key) => {
              return (
                <TouchableWithoutFeedback
                  key={`INPUT_SELECT_${key}`}
                  onPress={() => onSelected(item)}
                  disabled={disabled}
                >
                  <View style={[styles.item, itemStyle]}>
                    <Text
                      style={styles.itemText}
                      numberOfLines={1}
                      ellipsizeMode='tail'
                    >
                      {item.name}
                    </Text>
                  </View>
                </TouchableWithoutFeedback>
              )
            })}
          </ScrollView>
        )}

        {showEmptyState && emptyStateComponent()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: grayBackground,
  },
  iconInputSearch: {
    marginRight: -10,
    marginLeft: 19,
    color: blackMediumEmphasis,
  },
  containerData: {
    marginTop: -22,
    maxHeight: 63 * 5,
  },
  contentData: {
    borderWidth: 1,
    borderColor: blackMediumEmphasis,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 18,
    marginHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: grayDA,
  },
  itemText: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackMediumEmphasis,
  },
})

InputSelect.prototype = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onfocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelected: PropTypes.func,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  touched: PropTypes.bool,
  keyboardType: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  contentStyle: PropTypes.object,
  contentDataStyle: PropTypes.object,
  itemStyle: PropTypes.object,
}
