import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { blackHighEmphasis, white, blackMediumEmphasis } from '@styles/palette'

export const ListItemFarm = ({
  title,
  lotInfo,
  location,
  short = false,
  titleStyle = {},
  onPress = null,
}) => {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.8}>
      <View style={styles.card}>
        <Text style={[styles.cardTitle, titleStyle]} numberOfLines={1}>
          {title}
        </Text>

        {Boolean(lotInfo) && short && (
          <Text style={[styles.cardSubtitle]} numberOfLines={1}>
            {lotInfo}
          </Text>
        )}

        {Boolean(location) && short && (
          <Text style={[styles.cardSubtitle]} numberOfLines={1}>
            {location}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: white,
    paddingVertical: 8,
    paddingLeft: 16,
    paddingRight: 8,
    marginBottom: 8,
  },
  cardTitle: {
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
    opacity: 1,
    paddingRight: 8,
    marginBottom: 8,
  },
  cardSubtitle: {
    fontWeight: '400',
    fontSize: 14,
    color: blackMediumEmphasis,
    lineHeight: 20,
    letterSpacing: 0.25,
    marginBottom: 2,
  },
})
