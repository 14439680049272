import React, { useState, useContext, useEffect } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { Divider } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { SelectorContext } from '@contextState/selectors'
import ButtonCustom from '@components/common/ButtonCustom'
import EditLotsSurface from '@components/lots/v1/EditLotsSurface'
import { CropContext } from '@contextState/crop'
import { AuthContext } from '@contextState/auth'
import { formatSurface, truncateSurface } from '@common/utils'
import { unitMeasureSystem as UnitMeasureSystem } from '@utils/constants'

const INCREMENTE = 10

const ActivityMinimumUnitLot = ({ route, navigation }) => {
  const { currentCrop } = useContext(CropContext)
  const { config } = useContext(AuthContext)
  const { t } = useContext(LanguageContext)
  const {
    lotSelector,
    establishmentsSelector,
    setEstablishmentsSelector,
    lotSelectorTotalSurface,
    setSelectorTotalSurface,
  } = useContext(SelectorContext)

  const [establishments, setEstablishments] = useState(establishmentsSelector)

  useEffect(() => {
    getTotalSurface()
  }, [establishments])

  const onPressAdd = (lotId, editSurfaces) => {
    const establishmentsLots = establishments.map((establishment) => {
      return {
        ...establishment,
        lots: establishment.lots.map((lot) => {
          lot.surfaceRealized =
            lot._id === lotId && editSurfaces
              ? truncateSurface(Number(editSurfaces + INCREMENTE))
              : lot.surfaceRealized
          return lot
        }),
      }
    })

    setEstablishments(establishmentsLots)
  }

  const onPressSubtract = (lotId, editSurfaces) => {
    const establishmentsLots = establishments.map((establishment) => {
      return {
        ...establishment,
        lots: establishment.lots.map((lot) => {
          lot.surfaceRealized =
            lot._id === lotId
              ? editSurfaces - 10 > 0
                ? truncateSurface(Number(editSurfaces - INCREMENTE))
                : lot.surfaceRealized
              : lot.surfaceRealized
          return lot
        }),
      }
    })

    setEstablishments(establishmentsLots)
  }

  const editSurfaceLot = (lotId, surface) => {
    const establishmentsLots = establishments.map((establishment) => {
      return {
        ...establishment,
        lots: establishment.lots.map((lot) => {
          lot.surfaceRealized =
            lot._id === lotId && surface
              ? truncateSurface(Number(surface))
              : lot.surfaceRealized
          return lot
        }),
      }
    })

    setEstablishments(establishmentsLots)
  }

  const getTotalSurface = () => {
    let total = 0

    establishments.forEach((establishment) =>
      establishment.lots.forEach((lot) => (total += lot.surfaceRealized))
    )

    setSelectorTotalSurface(truncateSurface(total))
  }

  const renderLotsInEstablishment = (establishments) =>
    establishments &&
    establishments.map((establishment, key) => (
      <View key={key}>
        <View style={styles.contentTitle}>
          <Text style={styles.title}>{establishment.tag}</Text>
        </View>
        {establishment.lots.map((lot, key) => (
          <View key={key}>
            <EditLotsSurface
              key={lot._id}
              id={lot._id}
              name={lot.name}
              cityName={lot.cityName}
              provinceName={lot.provinceName}
              imageUrl={lot.imageUrl}
              surface={lot.surface}
              surfacePlanned={
                lot.surfacePlanned !== undefined
                  ? lot.surfacePlanned
                  : lot.surface
              }
              surfaceRealized={
                lot.surfaceRealized ?? lot.surfacePlanned ?? lot.surface
              }
              onPressAdd={onPressAdd}
              onPressSubtract={onPressSubtract}
              editSurfaceLot={editSurfaceLot}
              readOnly={route.params.readOnly}
              hideSurfacePlanned={route.params.hideSurfacePlanned}
              areaUnit={lot?.areaUnit ?? ''}
            />
            <Divider />
          </View>
        ))}
      </View>
    ))

  const goToAchievementForm = () => {
    if (route.params.callback) {
      const dataToReturn = establishments
        .flatMap((element) =>
          element.lots.map((subElement) => ({
            ...subElement,
            tag: element.tag,
          }))
        )
        .map((element) => ({
          _id: element._id,
          surface: element.surfaceRealized,
          tag: element.tag,
          farm: element.farm,
        }))

      route.params.callback(dataToReturn)

      return
    }

    const newEstablishments = establishments
      .flatMap((element) =>
        element.lots.map((subElement) => ({
          ...subElement,
          tag: element.tag,
        }))
      )
      .map((element) => ({
        lot: element._id,
        tag: element.tag,
        surfacePlanned: element.surfacePlanned ?? element.surfaceRealized,
        surfaceAchievement: element.surfaceRealized,
        farm: element.farm,
      }))

    setEstablishmentsSelector(newEstablishments)

    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      if (lotSelector.length) {
        valuesForm.lots = {
          value: lotSelector,
          label: `${lotSelector.length} ${t(
            'COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_1'
          )}`,
        }
        valuesForm.surface = String(lotSelectorTotalSurface)
      }
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.navigate('AchievementsScreen', {
        id: route.params.cropId,
        activity: route.params.activityId,
      })
    }
  }

  const getUnitSurface = () => {
    return currentCrop?.areaUnit
      ? currentCrop?.areaUnit
      : (config?.companySelected?.unitMeasureSystem ===
        UnitMeasureSystem.IMPERIAL
          ? 'ac'
          : 'ha') || ''
  }

  return (
    <>
      <ScrollView style={styles.scroll}>
        <View style={styles.container}>
          {renderLotsInEstablishment(establishments)}

          <View style={styles.contentSurface}>
            <Text style={styles.surfaceTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_7}
            </Text>
            <Text style={styles.surfaceSubTitle}>
              {formatSurface(lotSelectorTotalSurface ?? 0, getUnitSurface())}
            </Text>
          </View>

          {!route.params.readOnly && (
            <View style={styles.contentButton}>
              <ButtonCustom onPress={goToAchievementForm}>
                <Text style={styles.titleSign}>
                  {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_8}
                </Text>
              </ButtonCustom>
            </View>
          )}
        </View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
  },
  contentSurface: {
    paddingVertical: 10,
    marginBottom: 20,
  },
  surfaceTitle: {
    fontSize: 20,
    fontWeight: '700',
    paddingVertical: 10,
  },
  surfaceSubTitle: {
    fontSize: 14,
    fontWeight: '400',
  },
  contentButton: {
    paddingVertical: 10,
  },
  titleSign: {
    color: '#FFFFFF',
  },
})

ActivityMinimumUnitLot.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default ActivityMinimumUnitLot
