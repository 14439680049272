import { Field, Formik } from 'formik'
import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Button, List, Text, Title } from 'react-native-paper'
import PropTypes from 'prop-types'

import { AuthContext } from '@contextState/auth'
import { CommonContext } from '@contextState/common'

import useNetwork from '@utils/network'
import ButtonCustom from '../common/ButtonCustom'
import CustomDatePicker from '../common/CustomDatePicker'
import FilesList from '../common/FilesList'
import Input from '../common/Input'
import ModalCustom from '../common/ModalCustom'
import Select from '../common/Select'
import SnackbarCustom from '../common/SnackbarCustom'
import UploadInput from '../common/UploadInput'
import { cuitValidator } from '@utils/validation'
import { LanguageContext } from '@contextState/language'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import { CommonIcon } from '@modules/common/components'

function FormOnboarding({ data, navigation }) {
  const { doRequest } = useNetwork()
  const { config, fetchUser, user } = useContext(AuthContext)
  const { countries } = useContext(CommonContext)
  const { t } = useContext(LanguageContext)

  const [files, setFiles] = useState([])
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [visible, setVisible] = useState(false)
  const [countriesData, setCountriesData] = useState([])

  /**
   * UPDATE COUNTRY STATE
   */
  useEffect(() => {
    if (!countries.length) {
      return
    }

    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  function handleFile({ file }) {
    setSelectedFile(file)
  }

  function handleFileSubmit(values) {
    setFiles([...files, { ...values, file: selectedFile }])
    setVisible(false)
    setSelectedFile(null)
  }

  function handleFileDelete(index) {
    setFiles(files.filter((el, key) => key !== index))
  }

  async function handleSubmitForm(values, { setSubmitting }) {
    const formData = new FormData()
    const newData = JSON.stringify({
      ...values,
      identifier: data?.identifier || values.identifier,
      evidences: files.map(({ name, description, date }) => ({
        name,
        description,
        date,
      })),
    })

    formData.append('data', newData)

    files.forEach((el) => {
      formData.append('files', el.file)
    })

    try {
      const newCompany = await doRequest({
        method: 'POST',
        url: 'companies',
        data: formData,
      })

      const identifier = user.companies.length
        ? user.companies[0].identifier
        : newCompany.data.identifier

      await doRequest({
        method: 'PUT',
        url: `configurations/${config._id}`,
        data: {
          companySelected: newCompany.data._id,
          identifier,
        },
      })

      await fetchUser()

      navigation.navigate('CropList')

      setSubmitting(false)
    } catch (err) {
      console.warn(err)
      showError(t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_1)
    }
  }

  function showError(msg) {
    setSnackBarMessage(msg)
    setVisibleSnackbar(true)
  }

  /**
   *
   * @param {*} value
   * @returns VALIDATE COUNTRY SELECTED
   */
  const validateCompany = (value) => {
    let error = ''

    if (!value) {
      error = t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_2
    } else if (value && isNaN(Number(value))) {
      error = t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_3
    } else if (!cuitValidator.test(value)) {
      error = t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_4
    } else if (/\s/g.test(value)) {
      error = t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_5
    }

    return error
  }

  /**
   *
   * @param {*} values
   * @returns Input Company
   */
  const renderInputIdentifier = ({ country }) => {
    const nameCountry = countriesData.find(
      (element) => element.value === country
    )?.label

    if (nameCountry === 'Argentina') {
      return (
        <Field
          component={Input}
          name='identifier'
          validate={validateCompany}
          inputProps={{
            label: t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_1.LABEL,
            disabled: user.companies.length,
          }}
          helpText={t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_1.HELPTEXT}
        />
      )
    } else {
      return (
        <Field
          component={Input}
          name='identifier'
          inputProps={{
            label: t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_2.LABEL,
            disabled: user.companies.length,
          }}
          helpText={t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_2.HELPTEXT}
        />
      )
    }
  }

  return (
    <>
      <ModalCustom visible={visible} hideModal={() => setVisible(false)}>
        <Title style={styles.modalTitle}>
          {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_6}
        </Title>
        <Formik
          initialValues={{
            name: '',
            description: '',
            date: new Date(),
          }}
          onSubmit={handleFileSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <Field
                component={Input}
                name='name'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_3.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_3.LABEL,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_3.VALIDATE
                }
              />
              <Field
                component={Input}
                name='description'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_4.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_4.LABEL,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_4.VALIDATE
                }
              />
              <Field
                component={CustomDatePicker}
                name='date'
                placeholder={
                  t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_5.PLACEHOLDER
                }
                label={t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_5.LABEL}
              />
              <UploadInput
                cancelOnPress={() => setVisible(false)}
                handleFile={handleFile}
                accept={`.doc,.docx,.pdf,${ALLOW_IMAGES_FORMAT}`}
              >
                <View
                  style={{
                    borderRadius: 2,
                    padding: 4,
                    fontWeight: '400',
                  }}
                >
                  <Text
                    style={{
                      color: 'green',
                      textAlign: 'center',
                      padding: 4,
                    }}
                  >
                    {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_7}
                  </Text>
                </View>
              </UploadInput>
              {selectedFile && (
                <List.Item
                  title={selectedFile.name}
                  left={() => <CommonIcon name='FILETYPE-LEGAL' size={20} />}
                />
              )}
              <View style={styles.actions}>
                <Button onPress={() => setVisible(false)}>
                  {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_8}
                </Button>
                <Button
                  disabled={!selectedFile}
                  onPress={handleSubmit}
                  labelStyle={{ color: 'white' }}
                  mode='contained'
                >
                  {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_9}
                </Button>
              </View>
            </>
          )}
        </Formik>
      </ModalCustom>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{
          identifier: user.companies.length ? user.companies[0].identifier : '',
          name: '',
          address: '',
          addressFloor: '',
          country: '',
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <View style={{ flex: 1 }}>
              <ScrollView style={styles.container}>
                <Field
                  component={Select}
                  name='country'
                  label={t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_6.LABEL}
                  options={countriesData}
                />

                {data?.identifier ? (
                  <View style={styles.headerText}>
                    <Title>
                      {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_10}{' '}
                    </Title>
                    <Text>{data.identifier}</Text>
                  </View>
                ) : (
                  renderInputIdentifier(values)
                )}
                <Field
                  component={Input}
                  name='name'
                  inputProps={{
                    label:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_7.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_7
                        .PLACEHOLDER,
                  }}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_7.VALIDATE
                  }
                />

                <Field
                  component={Select}
                  name='typePerson'
                  label={t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_8.LABEL}
                  options={[
                    {
                      value: 'PHYSICAL_PERSON',
                      label:
                        t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_8
                          .OPTION_1,
                    },
                    {
                      value: 'LEGAL_PERSON',
                      label:
                        t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_8
                          .OPTION_2,
                    },
                  ]}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_8.VALIDATE
                  }
                />

                <Field
                  component={Input}
                  name='address'
                  inputProps={{
                    label:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_9.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_9
                        .PLACEHOLDER,
                  }}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_9.VALIDATE
                  }
                />

                <Field
                  component={Input}
                  name='addressFloor'
                  inputProps={{
                    label:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_10.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_BOARDING.FIELD_10
                        .PLACEHOLDER,
                  }}
                />
                <Button onPress={() => setVisible(true)}>
                  {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_11}
                </Button>
                <FilesList data={files} onDelete={handleFileDelete} />
              </ScrollView>

              <ButtonCustom
                isLoading={isSubmitting}
                disabled={files.length === 0}
                onPress={handleSubmit}
                styles={styles.button}
              >
                {t('COMPONENTS').COMPANIES.FORM_BOARDING.TEXT_12}
              </ButtonCustom>
            </View>
          )
        }}
      </Formik>
      <SnackbarCustom
        visible={visibleSnackbar}
        onToggle={() => {
          setSnackBarMessage('')
          setVisibleSnackbar(false)
        }}
      >
        {snackBarMessage}
      </SnackbarCustom>
    </>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  container: {
    padding: 16,
    flex: 1,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  button: {
    margin: 16,
  },
})
FormOnboarding.propTypes = {
  data: PropTypes.object,
  navigation: PropTypes.object,
}

export default FormOnboarding
