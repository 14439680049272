import React, { useContext, useState, useEffect } from 'react'
import { View, StyleSheet, ScrollView, Platform } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { selectFileBinaryAction } from '@store/actions'

import { AuthContext } from '@contextState/auth'
import { LanguageContext } from '@contextState/language'
import { primary500, white } from '@styles/palette'

import { ActivityIndicator, CompanyDetails } from '@modules/common/components'
import { UploadArea } from './components/UploadArea'
import ButtonCustom from '@components/common/ButtonCustom'
import { Location, EditableTitle } from './components'
import { useDetailsFarm, useUpdateSurface, useShowSurface } from './hooks'
import useNetwork from '@utils/network'
import useModal from '@hooks/useModal'
import ModalConfirm from '@components/common/v1/ModalConfirm'

const FarmDetails = ({ route, navigation }) => {
  const { t } = useContext(LanguageContext)
  const { config } = useContext(AuthContext)
  const isFocused = useIsFocused()

  const {
    name: companyName,
    identifier,
    _id: idCompany,
  } = config.companySelected

  const { params } = route
  const farmId = params?.farmId

  const [selectedFile, setSelectedFile] = useState(null)
  const [error, setError] = useState({})
  const [surface, setSurface] = useState(null)

  const { doRequest } = useNetwork()
  const { isModalVisible, toggleModal, closeModal } = useModal()

  const dispatch = useDispatch()

  const {
    name,
    location,
    lots,
    surfaceWithArea,
    areaUnit,
    image,
    isLoading,
    uuid,
    hasSurface,
    hasPermissionEdit,
    _id,
    onNavigateDetailFarm,
  } = useDetailsFarm(identifier, farmId, isFocused)

  const { visible, setVisible } = useShowSurface(surface, hasSurface)

  const { updateSurfaceFarms } = useUpdateSurface({
    farmUuid: farmId,
    farmName: name,
    company: idCompany,
    identifier,
    File: selectedFile,
    setError,
    setVisible,
    toggleModal,
  })

  const goToEditTitleFarm = () =>
    navigation.navigate('FarmEditName', { farmUuid: farmId })

  const goToFarmLotList = () => {
    navigation.navigate('FarmLotList', {
      farmId: uuid,
      name,
    })
  }

  /**
   * ON GO TO FARM LOTS ADD
   */
  const onGoFarmLotsAdd = () => {
    navigation.navigate('FarmLotsAdd', {
      farmName: name,
      farmUuid: farmId,
      showSuccess: false,
      onReturn: () => {
        navigation.goBack()
      },
    })
  }
  useEffect(() => {
    return () => {
      clearFileSurface()
    }
  }, [])

  useEffect(() => {
    if (selectedFile && Object.entries(selectedFile).length) {
      onUploadSurface()
    }

    return () => {}
  }, [selectedFile])

  const onUploadSurface = async () => {
    try {
      await uploadSurfaces()
      await updateSurfaceFarms()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (surfaceWithArea) {
      setSurface(surfaceWithArea)
    }
  }, [surfaceWithArea])

  const handleFile = ({ file }) => {
    setSelectedFile(file)
  }

  const uploadSurfaces = async () => {
    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const response = await doRequest({
        method: 'POST',
        url: 'farms/surfaces',
        data: formData,
        displayAlert: false,
        params: { identifier },
      })

      if (response.data[0]?.surface <= 0) {
        setError({ message: t('VIEWS').FARM_CREATE.TEXT_3 })
        toggleModal()
      } else {
        setSurface(response.data[0]?.surface)
        dispatch(selectFileBinaryAction.setFileBinary(selectedFile))
      }
    } catch (error) {
      setSurface(null)
      setError(error)
      toggleModal()
    }
  }

  const clearFileSurface = () => {
    dispatch(selectFileBinaryAction.clearFileBinary())
  }

  if (isLoading) return <ActivityIndicator />

  return (
    <>
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.scroll}
          showsVerticalScrollIndicator={Platform.OS === 'web'}
        >
          <CompanyDetails
            companyName={companyName}
            companyIdentifier={identifier}
          />

          <EditableTitle
            title={name}
            onPress={hasPermissionEdit && goToEditTitleFarm}
            showIcon={hasPermissionEdit}
          />

          <Location
            department={location}
            lots={`${lots} ${t('VIEWS.FARM_DETAILS.LOTS')}`}
            goToFarmLotList={goToFarmLotList}
            hasSurface={hasSurface}
          />

          {hasPermissionEdit && (
            <UploadArea
              handleFile={handleFile}
              surface={surface}
              areaUnit={areaUnit}
              image={image}
              visible={visible}
              farmId={_id}
              onPress={onNavigateDetailFarm}
            />
          )}
        </ScrollView>
        {hasPermissionEdit && (
          <ButtonCustom
            onPress={onGoFarmLotsAdd}
            styles={styles.button}
            labelStyle={styles.textButton}
            style={styles}
          >
            {t('VIEWS.FARM_DETAILS.BUTTON_ADD_LOTS_TO_FARM')}
          </ButtonCustom>
        )}
      </View>

      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onConfirm={closeModal}
        title={t('VIEWS').FARM_CREATE.TEXT_1}
        contentText={error?.message}
        titleTextStyle={styles.modalTitle}
        contentTextStyle={styles.modalContentTextStyle}
        confirmText={t('VIEWS').FARM_CREATE.TEXT_2}
        confirmButtonTextStyle={styles.confirmButtonTextStyle}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scroll: {
    marginTop: 8,
    marginBottom: 16,
  },
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
    marginHorizontal: 16,
  },
  textButton: {
    color: white,
  },
})

FarmDetails.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default FarmDetails
