export default {
  TEXT_1: 'UCROP.IT | Iniciar Sesión',
  TEXT_2: 'UCROP.IT | Registro',
  TEXT_3: 'UCROP.IT | Verificar código',
  TEXT_4: 'UCROP.IT | Crear Pin',
  TEXT_5: 'UCROP.IT | Alta de empresa',
  TEXT_6: 'Alta de Empresa',
  TEXT_7: 'Seleccionar Lotes',
  TEXT_8: 'Seleccionar Colaboradores',
  TEXT_9: 'Buscar insumos',
  TEXT_10: 'Conexión no disponible',
  TEXT_11: 'Conexión limitada',
  TEXT_12: 'CERRAR',
  TEXT_13: 'Sincronizando borradores',
}
