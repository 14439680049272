import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { AuthContext } from '@contextState/auth'
import { View, Text, StyleSheet, Pressable } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import {
  alertText,
  brown400,
  cherry200,
  cherry600,
  lemon500,
  yellow200,
  cherry300,
  lemon300,
} from '@styles/palette'

const DANGER = 'DANGER'
const Outlier = ({ title, description, icon, type, style, onPress }) => {
  const { config } = useContext(AuthContext)

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  return (
    alpha3Code !== 'USA' && (
      <Pressable onPress={onPress ? onPress : null}>
        <View
          style={[
            styles.container,
            type === DANGER ? styles.danger : styles.warning,
            style,
          ]}
        >
          {icon && (
            <CommonIcon
              name={icon}
              size={20}
              color={type === DANGER ? alertText : lemon500}
              style={styles.icon}
            />
          )}
          <View style={styles.containerText}>
            <Text
              style={[
                styles.text,
                type === DANGER ? styles.textDanger : styles.textWarning,
              ]}
            >
              {title}
            </Text>
            {description && (
              <Text
                style={[
                  styles.text,
                  type === DANGER ? styles.textDanger : styles.textWarning,
                  styles.description,
                ]}
              >
                {description}
              </Text>
            )}
          </View>
        </View>
      </Pressable>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: cherry200,
    paddingVertical: 11,
    borderWidth: 1,
    borderRadius: 4,
  },
  danger: {
    backgroundColor: cherry200,
    borderColor: cherry300,
  },
  icon: {
    marginLeft: 10,
  },
  iconDanger: {
    color: alertText,
  },
  containerText: {
    marginLeft: 10,
    marginRight: 16,
  },
  text: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 18,
  },
  description: {
    fontWeight: '500',
  },
  textDanger: {
    color: cherry600,
  },
  warning: { backgroundColor: yellow200, borderColor: lemon300 },
  iconWarning: { color: lemon500 },
  textWarning: { color: brown400 },
})

Outlier.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default Outlier
