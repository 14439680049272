import React, { useContext } from 'react'
import CommonList from '@components/common/CommonList'
import { SUPPLY } from '@constants/iconGuide'
import { getDescriptionSupplySelect } from '@utils/supply'
import { CommonContext } from '@contextState/common'
import { outlierWarningValues } from '@constants'
import { lastFertilizer, lastPhytotherapics, lastSeeds } from './utils'
import { statusOutlier as statusOutlierEnum } from '@constants/outlierData'

export const ListSupplies = ({
  supplies,
  navigateToSupplyDetail,
  areaUnit,
  canShowOutlier,
  t,
  outlierLevelSowing,
  outlierActiveIngredientsLevel,
  outlierFertilizerLevel,
  openBackdropStandardDeviation,
  surface,
}) => {
  const { unitTypesSupplies } = useContext(CommonContext)

  const isValueOutsideMeanFertilizer = outlierWarningValues.includes(
    outlierFertilizerLevel?.level || outlierFertilizerLevel
  )
  const isValueOutsideMeanSeeds = outlierWarningValues.includes(
    outlierLevelSowing?.level || outlierLevelSowing
  )
  const isValueOutsideMeanPhytotherapics = outlierWarningValues.includes(
    outlierActiveIngredientsLevel?.level || outlierActiveIngredientsLevel
  )

  const isLastFertilizer = (supply) =>
    lastFertilizer(supplies)._id === supply._id
  const isLastSeeds = (supply) => lastSeeds(supplies)._id === supply._id
  const isLastPhytotherapics = (supply) =>
    lastPhytotherapics(supplies)._id === supply._id

  return supplies
    ? supplies.map((el, key) => {
        const code = el.typeSupply
          ? el.typeSupply.code
          : el?.typeId?.code ?? el?.supply?.supplyType

        let outlierType = 'atypical'
        if (outlierFertilizerLevel?.status && isLastFertilizer(el)) {
          outlierType = outlierFertilizerLevel?.status
        }
        if (outlierLevelSowing?.status && isLastSeeds(el)) {
          outlierType = outlierLevelSowing?.status
        }
        if (outlierActiveIngredientsLevel?.status && isLastPhytotherapics(el)) {
          outlierType = outlierActiveIngredientsLevel?.status
        }

        el.unitName =
          el.unitName ||
          unitTypesSupplies.find((unit) => unit.key === el.unit)?.keyLabel

        return (
          <CommonList
            key={key}
            index={key}
            img={SUPPLY[code] || SUPPLY['default']}
            onPress={() => navigateToSupplyDetail(el, key)}
            data={getDescriptionSupplySelect(el, areaUnit, surface)}
            canShowOutlier={canShowOutlier}
            t={t}
            openBackdropStandardDeviation={openBackdropStandardDeviation}
            isValueOutsideMean={
              (isValueOutsideMeanFertilizer && isLastFertilizer(el)) ||
              (isValueOutsideMeanSeeds && isLastSeeds(el)) ||
              (isValueOutsideMeanPhytotherapics && isLastPhytotherapics(el))
            }
            outlierType={statusOutlierEnum[outlierType]}
          />
        )
      })
    : null
}
