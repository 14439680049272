import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { primary500, carbon50 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'

export const ModifyButton = ({ onPressModifyButton }) => {
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      onPress={onPressModifyButton}
      styles={styles.button}
      labelStyle={styles.textButton}
    >
      {t('VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.MODIFY_BUTTON.MODIFY')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: carbon50,
    borderColor: '#4CAF50',
    borderWidth: 1,
    marginTop: 4,
  },
  textButton: {
    color: primary500,
  },
})

ModifyButton.propTypes = {
  onPressModifyButton: PropTypes.func.isRequired,
}
