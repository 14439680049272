export const agreementFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  seedDryContent: '',
  typeAgreement: {
    value: '',
    label: '',
  },
  dateLimitValidation: {
    value: '',
    label: '',
  },
  signers: [],
  evidences: [],
  files: [],
  farm: {},
})

export const dataAgreement = {
  name: 'Acuerdos - Explotación',
  dateLimitValidation: '2021-09-10T19:59:15.340Z',
  surface: 14.88,
  type: '5fa01d56d109e6791d233c67',
  typeAgreement: '5fa01d56d109e6791d233c6a',
  crop: '613bb932c53c358eaa773f76',
  lots: ['613bb932c53c358eaa773f57'],
  signers: [
    {
      userId: '61244f9329c5bb4ec1157d9a',
      fullName: 'Alfonso Tortorici',
      email: 'alfonso@ucrop.it',
      type: 'PRODUCER',
      signed: false,
    },
    {
      userId: '6064c1dcbd342c6b61050ca7',
      fullName: 'M Falcon',
      email: 'marcos.falcon@ucrop.it',
      type: 'KAM',
    },
  ],
  status: 'DONE',
  evidences: [
    {
      description: 'Carta de porte',
      date: '2021-11-03T18:54:48.628Z',
      file: { path: 'Dieta - Guia basica del protocolo autoinmune paleo.pdf' },
      location: {},
    },
  ],
}
