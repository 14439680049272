import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import ButtonRipple from '@common/components/buttons/ButtonRipple'
import { blackMediumEmphasis } from '@styles/palette'

const ButtonCommunication = ({ onPress, text, iconName }) => {
  return (
    <ButtonRipple onPress={onPress}>
      <View style={styles.contentButton}>
        <CommonIcon size={16} name={iconName} color={blackMediumEmphasis} />

        <Text style={styles.textButton}>{text}</Text>
      </View>
    </ButtonRipple>
  )
}

ButtonCommunication.propTypes = {
  onPress: PropTypes.func,
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
}

const styles = StyleSheet.create({
  contentButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  textButton: {
    color: blackMediumEmphasis,
    fontSize: 12,
    marginLeft: 5,
  },
})

export default ButtonCommunication
