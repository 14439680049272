import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import Screen from './screen'
import useNetwork from '@utils/network'
import { ConnectionContext } from '@contextState/connection'
import useOfflineCommon from '@offline/queries/common'
import { AuthContext } from '@contextState/auth'

const OptionalPlanned = ({ navigation, route }) => {
  const { isConnected } = useContext(ConnectionContext)
  const [crop, setCrop] = useState({})
  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const { user } = useContext(AuthContext)
  const [isViewReady, setIsViewReady] = useState(false)

  const {
    id: cropId,
    tag: tagActivity,
    mode: modeActivity,
    areaUnit: areaUnitCrop,
    cropTypeName,
    surface,
  } = useRef(route.params).current

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    fetchInitData()
    return () => {}
  }, [])

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    setIsViewReady(false)
    await fetchCrop()
    setIsViewReady(true)
  }, [])

  /**
   * FETCH CROP
   */
  const fetchCrop = useCallback(async () => {
    let crop
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        version: 'v2',
        url: `crops/${cropId}`,
        params: {
          companyId: user.config.companySelected?._id,
        },
        displayAlert: false,
      })
      crop = response.data
    } else {
      const offlineData = await showIndividuals('crops', cropId)
      crop = offlineData
    }
    setCrop(crop)
    return crop
  }, [isConnected])

  const goToRealization = () => {
    navigation.navigate('ActivityCreate', {
      cropId: cropId,
      tag: tagActivity,
      canSign: true,
      isDirectAchievement: true,
      isNewActivity: true,
      canPlanning: true,
    })
  }

  const goToPlanning = () => {
    navigation.navigate('CommonActivities', {
      id: cropId,
      mode: modeActivity,
      tag: tagActivity,
      areaUnit: areaUnitCrop,
      cropTypeName,
      surface,
    })
  }

  return (
    <Screen
      crop={crop}
      isViewReady={isViewReady}
      goToPlanning={goToPlanning}
      goToRealization={goToRealization}
    />
  )
}

OptionalPlanned.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default OptionalPlanned
