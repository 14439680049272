import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Chip } from 'react-native-elements'
import { yellow200, brown400 } from '@styles/palette'
import PropTypes from 'prop-types'

export const NotificationChip = ({
  text,
  contentStyle,
  chipStyle,
  textStyle,
}) => {
  return (
    <View style={[styles.contentChip, contentStyle]}>
      <Chip
        title={text}
        containerStyle={[styles.chipContainerStyle, chipStyle]}
        buttonStyle={styles.chipStyle}
        titleStyle={[styles.textChip, textStyle]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  contentChip: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chipContainerStyle: {
    maxWidth: '100%',
  },
  chipStyle: {
    paddingHorizontal: 18,
    paddingVertical: 6,
    backgroundColor: yellow200,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginHorizontal: 2,
    marginTop: 8,
    marginBottom: 5,
  },
  textChip: {
    fontFamily: 'roboto',
    color: brown400,
    fontWeight: 'bold',
    fontSize: 10,
    textTransform: 'none',
  },
})

NotificationChip.propTypes = {
  text: PropTypes.string.isRequired,
  contentStyle: PropTypes.object,
  chipStyle: PropTypes.object,
  textStyle: PropTypes.object,
}
