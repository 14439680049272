import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'

import ACTIVITY_TYPES from '@constants/activityTypes'
import {
  ActivityProgressBar,
  ActivityCardRealization,
  PlannedActivityDate,
  Achievements,
} from '../'

import { mapPlannedSurfaceFarmsAndLots } from '../../utils'

export const PlannedApplicationActivity = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  signed,
  signedIf,
  supplies,
  envImpactIndex,
  pay,
  unitType,
  unitTypeKey,
  volume,
  areaUnitVolume,
  areaUnit,
  achievements,
  dateStart,
  dateEnd,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_APPLICATION.key) return null

  const farmAndLotsWithSurfacePlanned = mapPlannedSurfaceFarmsAndLots(farms)

  return (
    <>
      <PlannedActivityDate dateStart={dateStart} dateEnd={dateEnd} />

      <ActivityCardRealization
        activityType={activityType}
        supplies={supplies}
        envImpactIndex={envImpactIndex}
        farms={farmAndLotsWithSurfacePlanned}
        subTypeActivity={subTypeActivity}
        signed={signed}
        signedIf={signedIf}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
        areaUnit={areaUnit}
        contentStyle={styles.plannedRealization}
        showTextDone={false}
        isDirectAchievement={false}
      />

      <ActivityProgressBar percent={percent} />

      <Achievements
        achievements={achievements}
        activityType={activityType}
        subTypeActivity={subTypeActivity}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
      />
    </>
  )
}

const styles = StyleSheet.create({
  plannedRealization: {
    backgroundColor: 'transparent',
    marginTop: 10,
    paddingTop: 0,
  },
})

PlannedApplicationActivity.prototype = {
  activityType: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  envImpactIndex: PropTypes.object,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  areaUnitVolume: PropTypes.array,
  areaUnit: PropTypes.array,
  achievements: PropTypes.array,
  dateStart: PropTypes.array,
  dateEnd: PropTypes.array,
}
