import React, { useCallback, useContext } from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import {
  grayBackground,
  blackHighEmphasis,
  blackMediumEmphasis,
  grayInput,
} from '@styles/palette'
import { useResponsive } from '@modules/common/hooks'
import { MAGIC_CONTENT_BODY } from '@constants/magic'

const MARGIN_HORIZONTAL = 18
const MARGIN_RIGHT = 18
const WIDTH_DESKTOP = MAGIC_CONTENT_BODY - MARGIN_RIGHT

export const MapCard = ({ onPress }) => {
  const { t } = useContext(LanguageContext)
  const { width } = useWindowDimensions()
  const { isScreenDesktop } = useResponsive()
  const widthContent = isScreenDesktop ? WIDTH_DESKTOP : width - MARGIN_RIGHT
  const styleWidth = useCallback(
    { width: widthContent - MARGIN_HORIZONTAL * 2 },
    [widthContent]
  )

  return (
    <TouchableOpacity activeOpacity={0.95} onPress={onPress}>
      <View style={[styles.container, styleWidth]}>
        <View style={styles.contentIcon}>
          <CommonIcon name={'FIELDS'} size={36} />
        </View>

        <View style={styles.contentText}>
          <Text style={styles.title}>
            {t('VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.MAP_CARD.TEXT_1')}
          </Text>

          <Text style={styles.paragraph}>
            {t('VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.MAP_CARD.TEXT_2')}
          </Text>
        </View>

        <CommonIcon
          name='CHEVRON-RIGHT'
          size={25}
          color={grayInput}
          style={styles.icon}
        />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 92,
    marginHorizontal: MARGIN_HORIZONTAL,
    backgroundColor: grayBackground,
    bottom: 21,
    borderRadius: 4,
    flexDirection: 'row',
    paddingTop: 14,
    justifyContent: 'space-evenly',
  },
  contentIcon: {
    flex: 3,
    alignItems: 'center',
    marginTop: 6,
  },
  contentText: {
    flex: 9,
    paddingRight: 30,
  },
  title: {
    color: blackHighEmphasis,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    fontWeight: '500',
    paddingBottom: 2,
  },
  paragraph: {
    color: blackMediumEmphasis,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  icon: {
    flex: 2,
    alignSelf: 'center',
    textAlign: 'center',
  },
})

MapCard.prototype = {
  onPress: PropTypes.func.isRequired,
}
