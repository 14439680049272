import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'

import { backgroundDefault, secondary500 } from '@styles/palette'
import ButtonCustom from '@components/common/ButtonCustom'

export const DeclineButton = ({
  isDeclineButtonAvailable = true,
  isDeclineButtonVisible = true,
  onPressDeclineButton,
}) => {
  if (!isDeclineButtonVisible) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      disabled={!isDeclineButtonAvailable}
      onPress={onPressDeclineButton}
      styles={[
        styles.button,
        !isDeclineButtonAvailable ? styles.buttonDisabled : {},
      ]}
      labelStyle={[
        styles.textButton,
        !isDeclineButtonAvailable ? styles.textButtonDisabled : {},
      ]}
    >
      {t('VIEWS').ACTIVITY_CREATE.TEXT_10}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: backgroundDefault,
    borderColor: secondary500,
    borderWidth: 1,
    marginTop: 5,
  },
  buttonDisabled: {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: secondary500,
  },
  textButtonDisabled: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
})

DeclineButton.propTypes = {
  isDeclineButtonAvailable: PropTypes.bool.isRequired,
  isDeclineButtonVisible: PropTypes.bool,
  onPressDeclineButton: PropTypes.func.isRequired,
}
