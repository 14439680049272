import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import {
  blackHighEmphasisBorder,
  blackMediumEmphasis,
  blackHighEmphasis,
} from '@styles/palette'

export const DateActivity = ({ text, date }) => {
  return (
    <View style={styles.contentDate}>
      <Text style={styles.text}>{text}</Text>
      <Text style={styles.date}>{date}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  contentDate: {
    borderColor: blackHighEmphasisBorder,
    paddingTop: 15,
    paddingHorizontal: 15,
    paddingBottom: 5,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    width: '46%',
  },
  text: {
    fontSize: 12,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
  date: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
})

DateActivity.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
}
