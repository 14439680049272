import FARM_FORM from '../../components/FarmForm/translate/es'
import LOST_ADD_BUTTON from '../../components/LotsAddButton/translate/es'
import SAVE_DRAFT_BUTTON from '../../components/SaveDraftButton/translate/es'
import UPLOAD_AREA from '../../components/UploadArea/translate/es'

export default {
  TEXT_1: 'Intenta subir otro archivo',
  TEXT_2: 'Cerrar',
  TEXT_3: 'Superficie no puede ser 0',
  COMPONENTS: {
    FARM_FORM,
    LOST_ADD_BUTTON,
    SAVE_DRAFT_BUTTON,
    UPLOAD_AREA,
  },
}
