import React from 'react'

import { createDrawerNavigator } from '@react-navigation/drawer'

import TabNavigator from './TabNavigator'
import CustomDrawer from './CustomDrawer'
import { ProfileStack, SetupStack } from './StackNavigator'
import { StyleSheet } from 'react-native-web'
import { useResponsive } from '../modules/common/hooks'
const Drawer = createDrawerNavigator()

const DrawerNavigator = ({ companyTypes }) => {
  const { isScreenDesktop } = useResponsive()
  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      drawerType={isScreenDesktop ? 'permanent' : 'front'}
      drawerStyle={styles.contentDrawer}
    >
      <Drawer.Screen name='Tab'>
        {(props) => <TabNavigator {...props} companyTypes={companyTypes} />}
      </Drawer.Screen>
      <Drawer.Screen name='Profile' component={ProfileStack} />
      <Drawer.Screen name='Setup' component={SetupStack} />
    </Drawer.Navigator>
  )
}
const styles = StyleSheet.create({
  contentDrawer: {
    width: 360,
  },
})
export default DrawerNavigator
