import React from 'react'
import { TouchableRipple } from 'react-native-paper'

const ButtonRipple = ({ children, onPress, style = {} }) => {
  return (
    <TouchableRipple onPress={onPress} style={style}>
      {children}
    </TouchableRipple>
  )
}

export default ButtonRipple
