export default {
  TEXT_1: 'Profile',
  TEXT_2: 'Settings',
  TEXT_3: 'Synchronization',
  TEXT_4: 'Synchronization queue',
  TEXT_5: 'Crops',
  TEXT_6: 'Filters',
  TEXT_7: 'Crop details',
  TEXT_8: 'Synchronization Details',
  TEXT_9: 'New company',
  TEXT_10: 'Collaborators',
  TEXT_11: 'Add collaborators',
  TEXT_12: 'Synchronize crop',
  TEXT_13: 'Integrations',
  TEXT_14: 'Integrations',
  TEXT_15: 'Integrations options',
  TEXT_16: 'Integration queue',
  TEXT_17: 'Integration Credentials',
  TEXT_18: 'My company',
  TEXT_19: 'Crop Activities',
  TEXT_20: 'Add a contract',
  TEXT_21: 'Edit contract',
  TEXT_22: 'Add observation',
  TEXT_23: 'Edit observation',
  TEXT_24: 'Add harvest',
  TEXT_25: 'Edit harvest',
  TEXT_26: 'Plan Planting',
  TEXT_27: 'Plan Tillage',
  TEXT_28: 'Plan Fertilization',
  TEXT_29: 'Plan Crop Protection',
  TEXT_30: 'Plan Harvest',
  TEXT_31: 'Activity Planting',
  TEXT_32: 'Activity Tillage',
  TEXT_33: 'Activity Fertilization',
  TEXT_34: 'Activity Crop Protection',
  TEXT_35: 'Activity Harvest',
  TEXT_36: 'Executed Planting',
  TEXT_37: 'Executed Tillage',
  TEXT_38: 'Executed Fertilization',
  TEXT_39: 'Execute Crop Protection',
  TEXT_40: 'Executed Harvest',
  TEXT_41: 'Execution details',
  TEXT_42: 'Confirm Executed Area',
  TEXT_43: 'Select input',
  TEXT_44: 'Input detail',
  TEXT_45: 'Field detail',
  TEXT_46: 'Add %{activityType}',
  TEXT_47: 'Edit %{activityType}',
  TEXT_48: 'Detail %{activityType}',
  TEXT_49: 'Ecosystem Associated Company',
  TEXT_50: 'Farms',
  TEXT_51: 'Add Fields',
  TEXT_52: 'New Farm',
  TEXT_53: 'Farm',
  TEXT_54: 'Fields',
  TEXT_55: 'Field',
  TEXT_56: 'Activity Planting',
  TEXT_57: 'Activity Tillage',
  TEXT_58: 'Activity Fertilization',
  TEXT_59: 'Activity Crop Protection',
  TEXT_60: 'Edit name',
  TEXT_61: '%{Realize} Executed',
  TEXT_62: 'Field detail',
  TEXT_63: 'Crop fields',
  TEXT_64: 'Field',
  TEXT_65: 'Advised Companies',
  TEXT_66: 'Companies',
  TEXT_67: 'Crop fields',
  COLLABORATORS: 'Collaborators',
  MANAGE_COMPANY: 'Manage new company',
  EDIT_COMPANY: 'Edit company',
  ADVISE_COMPANY: 'Advise new company',
  ADD_COLLABORATORS: 'Add Collaborator',
  SETTINGS: 'Settings',
  ADD_PRODUCER_ADMIN: 'Add Farming - Administrator',
  ADD_ADVISER_RESPONSIBLE: 'Add Advisor - Responsible',
  DELETE_ACCOUNT: 'Delete account',
  LANGUAGES: 'Language',
  LICENCES: 'Ecosystems',
  FILTERS: 'Filter by:',
  SELECT_FARM: 'Select farm',
  FIELD_STORY: 'Field story',
}
