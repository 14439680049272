import { useContext } from 'react'
import activityTypes from '@constants/activityTypes'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import { dateFormatedForMonitorings } from '..'

export const activityToMakeCard = (activity, crop) => {
  const { t } = useContext(LanguageContext)
  const { unitTypes } = useContext(CommonContext)

  if (activity?.type?.tag === activityTypes.ACT_MONITORING.key) {
    const unitPay = unitTypes.find((unit) => unit.value === activity.unitType)

    return `${t('VIEWS').ACTIVITIES.TEXT_8}: ${activity.pay} ${
      unitPay?.label
    }\n${t('VIEWS').ACTIVITIES.TEXT_9}: ${dateFormatedForMonitorings(
      activity,
      crop
    )}`
  } else if (activity?.type?.tag === activityTypes.ACT_APPLICATION.key) {
    return `${t('VIEWS').ACTIVITIES.TEXT_14} ${activity.surface} ${
      t('VIEWS').ACTIVITIES.TEXT_13
    }\n${activity.supplies.length} ${t('VIEWS').ACTIVITIES.TEXT_15}`
  } else {
    return `${t('VIEWS').ACTIVITIES.TEXT_12} ${activity.surface} ${
      t('VIEWS').ACTIVITIES.TEXT_13
    }\n${t('VIEWS').ACTIVITIES.TEXT_16}: ${activity.signers.length}`
  }
}
