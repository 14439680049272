import { authTypes as type } from '../types'

export const authActions = {
  setPin: (payload) => ({
    type: type.SET_PIN,
    payload,
  }),
  removePin: () => ({
    type: type.REMOVE_PIN,
  }),
  setHideWizzardIntro: (payload) => ({
    type: type.SET_SHOW_INTRO_WIZZARD,
    payload,
  }),
  setEmailWelcomeCompanyList: (payload) => ({
    type: type.SET_EMAIL_WELCOME_COMPANY,
    payload,
  }),
  removeEmailOfWelcomeCompanyList: (payload) => ({
    type: type.REMOVE_EMAIL_WELCOME_COMPANY,
    payload,
  }),
}
