import React, { useState, useRef } from 'react'
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native'
import PropTypes from 'prop-types'

import { grayInput } from '@styles/palette'
import { CommonIcon } from '@modules/common/components'

const AccordionItem = ({
  title = '',
  children,
  ContentHeader,
  titleContainerStyle = {},
  titleStyle = {},
  openByDefault = false,
  contentStyle = {},
  extraBodyStyle = {},
  otherParams = {},
  contentTop = false,
}) => {
  const [open, setOpen] = useState(openByDefault)
  const animatedController = useRef(
    new Animated.Value(Number(openByDefault))
  ).current
  const [bodySectionHeight, setBodySectionHeight] = useState(1)

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  })

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  })

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    }
    setOpen(!open)
  }

  return (
    <>
      {ContentHeader ? (
        <ContentHeader
          arrowAngle={arrowAngle}
          onPress={() => toggleListItem()}
          isOpened={open}
          otherParams={otherParams}
        />
      ) : (
        <TouchableWithoutFeedback onPress={() => toggleListItem()}>
          <View style={[styles.titleContainer, titleContainerStyle]}>
            <Text style={titleStyle}>{title}</Text>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <CommonIcon name='CHEVRON-DOWN' size={20} color={grayInput} />
            </Animated.View>
          </View>
        </TouchableWithoutFeedback>
      )}
      {contentTop}
      <Animated.View
        style={[styles.bodyBackground, contentStyle, { height: bodyHeight }]}
      >
        <View
          style={[styles.bodyContainer, extraBodyStyle]}
          onLayout={(event) =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
          {children}
        </View>
      </Animated.View>
    </>
  )
}

const styles = StyleSheet.create({
  bodyBackground: {
    //backgroundColor: '#EFEFEF', //TODO: INJECT STYLE WITH PROPS
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderWidth: 1,
  },
  bodyContainer: {
    padding: 10,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
})

AccordionItem.propTypes = {
  title: PropTypes.string,
  ContentHeader: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contentTop: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  extraBodyStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  titleContainerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  openByDefault: PropTypes.bool,
  contentStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

export default AccordionItem
