import React from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import Accordion from '@components/common/v2/AccordionItem'
import { AccordionHeader, FieldList } from '../'

export const FarmItem = ({ name, surface, areaUnit, fields, realized }) => {
  return (
    <Accordion
      otherParams={{
        name,
        surface,
        areaUnit,
        fieldsLength: fields.length,
        realized: realized,
      }}
      ContentHeader={AccordionHeader}
      extraBodyStyle={styles.extraBodyAccordion}
    >
      <FieldList fields={fields} />
    </Accordion>
  )
}

const styles = StyleSheet.create({
  extraBodyAccordion: {
    margin: 0,
    padding: 0,
  },
})

FarmItem.prototype = {
  name: PropTypes.string.isRequired,
  surface: PropTypes.number.isRequired,
  areaUnit: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
}
