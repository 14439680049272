import { FC, ReactElement } from 'react'
import { StyleSheet, View, Text } from 'react-native'

import { CropIcon } from '@modules/common/components/icons'
import { black, gray500 } from '@modules/common/styles/palette'
import { useWrapper } from './hooks'
import { ICropDetails } from './interfaces'
import { SIZES } from '../icons/interfaces'

export const CropDetails: FC<ICropDetails> = ({
  cropTypeKey,
  cropSurface,
  areaUnit,
  cropName,
  companyName,
  RightComponent,
}): ReactElement => {
  const {
    iconName,
    cropTypeLabel,
    cropSurfaceLabel,
    cropNameLabel,
    companyNameLabel,
  } = useWrapper({
    cropTypeKey,
    cropSurface,
    areaUnit,
    cropName,
    companyName,
  })

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <CropIcon name={iconName} size={SIZES.BIG} color={black} />
      </View>

      <View style={styles.textContainer}>
        <View style={styles.cropTypeContainer}>
          <Text
            style={styles.cropTypeText}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {cropTypeLabel}
          </Text>

          <Text style={styles.cropSurfaceText}>{cropSurfaceLabel}</Text>
        </View>

        <Text
          style={styles.cropNameText}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {cropNameLabel}
        </Text>

        <Text
          style={styles.companyNameText}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {companyNameLabel}
        </Text>
      </View>

      {Boolean(RightComponent) && RightComponent?.()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 20,
  },
  iconContainer: {
    marginRight: 14,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  cropTypeContainer: {
    flexDirection: 'row',
  },
  cropTypeText: {
    fontSize: 16,
    fontWeight: '600',
    color: black,
    marginRight: 8,
  },
  cropSurfaceText: {
    fontSize: 16,
    fontWeight: '600',
    color: gray500,
  },
  cropNameText: {
    fontSize: 12,
    fontWeight: '500',
    color: gray500,
    marginTop: 4,
  },
  companyNameText: {
    fontSize: 12,
    fontWeight: '500',
    color: gray500,
  },
})
