import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { blackMediumEmphasis } from '@styles/palette'

const VerifiedStatusText = ({ t }) => {
  return (
    <Text style={styles.text}>
      {t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_7}
    </Text>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    color: blackMediumEmphasis,
    fontStyle: 'italic',
    letterSpacing: 0.4,
    marginHorizontal: 16,
    marginTop: -10,
  },
})

VerifiedStatusText.propTypes = {
  t: PropTypes.func.isRequired,
}

export default VerifiedStatusText
