import React, { useState, createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from '../translate'
import { AuthContext } from './auth'
import useNetwork from '@utils/network'
import { languages } from '@constants'
import { Text } from 'react-native'

export const LanguageContext = createContext({ t: null, i18n: null })

export const LanguageProvider = ({ children }) => {
  const { doRequest } = useNetwork()
  const { config, isAuth, user, setUser, fetchUser } = useContext(AuthContext)
  const [translation, setTranslation] = useState(i18n)
  const [flagTranslate, setFlagTranslate] = useState(true)
  const updateLanguage = async (languageSelected) => {
    await doRequest({
      method: 'PUT',
      url: `configurations/${config._id}`,
      data: { languageSelected },
    })
  }

  const changeLanguage = (value) => {
    setFlagTranslate(!flagTranslate)
    setTranslation(value)
    updateLanguage(value.locale)
    setUser({
      ...user,
      config: {
        ...user.config,
        languageSelected: value.locale,
      },
    })
    fetchUser()
  }

  useEffect(() => {
    if (isAuth && config._id) {
      const valueLocalize = languages.find(
        (l) => config?.languageSelected?.indexOf(l.key) !== -1
      )
      if (valueLocalize) {
        const resultTranslation = translation
        resultTranslation.locale = valueLocalize.key
        changeLanguage(resultTranslation)
      }
    }
  }, [isAuth])

  useEffect(() => {
    if (translation.locale && config._id) {
      updateLanguage(translation.locale)
    }
  }, [translation, config._id])

  //DOC: https://github.com/fnando/i18n-js/issues/461#issuecomment-975485315
  const T = ({ id, values, components = [] }) => {
    const message = i18n.t(id, values)
    const row = React.useMemo(() => {
      const nodes = []
      let messageToParse = message
      components.forEach(({ component: Component, props }, index) => {
        const componentRegExp = new RegExp(`<${index}>(.*)</${index}>`, 'gi')
        const [beforeText, componentText, restText] =
          messageToParse.split(componentRegExp)
        messageToParse = restText
        nodes.push(
          <Text key={`T_${index}`} id={beforeText}>
            {beforeText}
          </Text>,
          <Component key={`C_${index}`} {...props}>
            {componentText}
          </Component>
        )
      })
      nodes.push(<Text id={messageToParse}>{messageToParse}</Text>)
      return nodes
    }, [message, components])
    return <>{row}</>
  }

  const value = { i18n: translation, t: translation.t, changeLanguage, T }

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.object,
}
