import { Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import getDrawFieldUrl from '../../../../../../config/draw-field-urls'
import { apiUrl } from '@utils/network'
import AsyncStorage from '@react-native-async-storage/async-storage'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { farmActions } from '@store/actions'
import { useDispatch } from 'react-redux'
import { WebViewRender } from '@modules/webView/components'

const FarmMap = ({ navigation, route }) => {
  const url = getDrawFieldUrl(apiUrl)
  const dispatch = useDispatch()
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      setAccessToken(token)
    })()
  })

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(farmActions.setFullScreen(true))
    })
    navigation.addListener('beforeRemove', () => {
      dispatch(farmActions.setFullScreen(false))
    })
  }, [navigation])

  const makeWebViewUrl = () => {
    let urlToGenerate = `${url}?accessToken=${accessToken}&timestamp=${Date.now()}`

    if (route?.params?.farmUuid) {
      const { farmUuid } = route?.params

      urlToGenerate += `&farmUuid=${farmUuid}`
    }

    return encodeURI(urlToGenerate)
  }

  const validateStateChange = (url) => {
    if (url?.indexOf('/close') !== -1) {
      navigation.navigate('FarmList')
    }
  }
  const handleWebViewNavigationStateChange = (newNavState) =>
    validateStateChange(newNavState?.url)

  const handleIFrameMessages = (event) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data
    if (data === 'close-iframe') {
      navigation.navigate('FarmList')
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      {!accessToken ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender
          url={makeWebViewUrl()}
          onNavigationStateChange={handleWebViewNavigationStateChange}
          onMessage={handleIFrameMessages}
        />
      )}
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
export default FarmMap
