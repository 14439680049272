import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Platform, StyleSheet, Text, View } from 'react-native'
import AnimatedLottieView from 'lottie-react-native'

import LoadingActivities from '@assets/animations/lottie/loadingActivities'
import { black } from '@styles/palette'

const isWeb = Platform.OS === 'web'
let Lottie

if (isWeb) {
  Lottie = require('react-lottie').default
}

export const ModalLoading = ({
  visible,
  title,
  subtitle,
  animation,
  animationStyle,
  animationType = 'slide',
  style,
}) => {
  const lottieWeb = () => {
    return (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation ? animation : LoadingActivities,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={Number(animationStyle.height)}
        height={Number(animationStyle.width)}
      />
    )
  }

  const lottieMobile = () => {
    return (
      <AnimatedLottieView
        style={animationStyle}
        source={animation ? animation : LoadingActivities}
        autoPlay
        loop
      />
    )
  }

  return (
    <Modal
      visible={visible}
      style={styles.container}
      animationType={animationType}
    >
      <View style={[styles.containerModal, style]}>
        {isWeb ? lottieWeb() : lottieMobile()}

        <View style={styles.contentText}>
          {title && <Text style={styles.title}>{title}</Text>}
          {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentText: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 36,
    color: black,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: black,
    marginTop: 30,
  },
})

ModalLoading.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  animation: PropTypes.object,
  animationStyle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  ]),
  style: PropTypes.object,
  animationType: PropTypes.oneOf(['slide', 'fade', 'none']),
}
