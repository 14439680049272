import React, { useCallback, useContext, useState } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import {
  Subheading,
  Colors,
  List,
  Divider,
  ActivityIndicator,
} from 'react-native-paper'
import PropTypes from 'prop-types'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import integrations from '@constants/integrations'
import { LanguageContext } from '@contextState/language'

function CropServiceIntegrationsScreen({ route, navigation }) {
  const { doRequest } = useNetwork()
  const { user, config } = useContext(AuthContext)
  const [crop, setCrop] = useState(null)
  const [services, setServices] = useState([])
  const { t } = useContext(LanguageContext)

  const fetchData = useCallback(async (id) => {
    let services = []

    const response = await doRequest({ method: 'GET', url: `crops/${id}` })
    const responseCompany = await doRequest({
      method: 'GET',
      url: `companies/${config.companySelected._id}`,
    })
    services = responseCompany.data?.servicesIntegrations.map((item) => {
      const service = response.data.synchronizedList
        ? response.data.synchronizedList.find(
            (el) => el.service === item.service
          )
        : null
      if (service) {
        return {
          service: service.service,
          isSynchronized: service.isSynchronized,
        }
      }

      return {
        service: item.service,
        isSynchronized: false,
      }
    })

    setCrop(response.data)
    setServices(services)

    response.data.members.find((el) => el.user._id === user._id)
  }, [])

  React.useEffect(() => {
    const { id } = route.params
    const unsubscribe = navigation.addListener('focus', async () => {
      await fetchData(id)
    })

    return unsubscribe
  }, [navigation])

  function imageSelection(service) {
    let image
    switch (service) {
      case 'auravant':
        image = integrations.auravant
        break

      default:
        image = integrations.auravant
    }
    return image
  }

  if (!services) {
    return (
      <View style={styles.indicatorContainer}>
        <ActivityIndicator animating color={Colors.green500} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <Subheading style={styles.title}>
        {t('VIEWS').CROP_SERVICE_INTEGRATION.TEXT_1}
      </Subheading>
      <Divider />
      <View>
        {services.map((item, key) => (
          <React.Fragment key={key}>
            <List.Item
              title={item?.service}
              titleStyle={styles.textItem}
              style={{ padding: 15 }}
              right={() => (
                <Image
                  style={styles.stylesImage}
                  source={imageSelection(item.service)}
                />
              )}
              onPress={() => {
                navigation.navigate('IntegrationQueue', {
                  id: crop._id,
                  service: item.service,
                })
              }}
            />
            <Divider />
          </React.Fragment>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stylesImage: {
    width: 50,
    height: 50,
  },
  button: {
    fontSize: 15,
    marginRight: 4,
  },
  chipContainer: {
    flex: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  chipStyle: {
    backgroundColor: '#FF8A65',
    color: 'white',
    fontSize: 12,
  },
  chipStyleText: {
    color: 'white',
    textTransform: 'capitalize',
  },
  title: {
    padding: 12,
    alignItems: 'flex-start',
    fontWeight: '400',
    fontSize: 18,
  },
  textFetch: {
    marginTop: 10,
  },
  textItem: {
    textTransform: 'capitalize',
    fontWeight: '400',
    textAlign: 'left',
  },
  syncStatus: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 1,
  },
})

CropServiceIntegrationsScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CropServiceIntegrationsScreen
