import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'

export const GoBackButton = ({ onSubmit }) => {
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      onPress={onSubmit}
      styles={styles.button}
      labelStyle={styles.textButton}
    >
      {t('VIEWS.CROP_FARM_LIST.COMPONENTS.GO_BACK_BUTTON.BACK_TO_CROP_TEXT')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#f2f2f2',
    borderColor: '#4CAF50',
    borderWidth: 1,
    marginHorizontal: 16,
    marginBottom: 16,
  },
  textButton: {
    color: '#4CAF50',
  },
})

GoBackButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
