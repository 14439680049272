import _ from 'lodash'
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import { CommonContext } from '@contextState/common'
import { CropContext } from '@contextState/crop'
import { SelectorContext } from '@contextState/selectors'
import { AuthContext } from '@contextState/auth'

import useNetwork from '@utils/network'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import useModal from '@hooks/useModal'
import useOfflineCommon from '@offline/queries/common'
import useOfflineDrafts from '@offline/queries/drafts'
import {
  getDraftsByCropId,
  getDraftsVerificationByCropIdAndIdentifier,
} from '@services/firebase'

import activityTypesConstants from '@constants/activityTypes'
import userTypes from '@constants/userTypes'
import { activitiesStatus } from '@utils/constants'

import Screen from './screen'
import { ActivityIcon } from '@modules/common/components/icons'
import { verificationTypesActions } from '@store/actions/verificationTypes'
import EiqAchievement from './components/EiqAchievement'
import { getObjectId } from '@utils'
import {
  searchErrorInAcVerification,
  ORDER_MENU_ENUM,
  CROP_MENU_OPTIONS,
} from '@modules/common/utils'
import { MODAL_ERRORS } from '@utils/constants'
import { useCropMenu } from '@modules/common/hooks'

import { enterToCropStory } from '@services/analytics'
import { selectHasFilter } from '@store/selectors/activities'
import { activitiesActions } from '@store/actions'

const ActivitiesScreen = ({ navigation, route }) => {
  const dispatch = useDispatch()
  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const {
    activities: activityTypes,
    unitTypes,
    storageTypes,
    agreementTypes,
    subTypeActivities,
    verificationTypes,
    orderCropsBy,
  } = useContext(CommonContext)
  const { setCurrentCrop } = useContext(CropContext)
  const { user, config, isCompanyVerifiers, isVerifierUcropit, companyAllow } =
    useContext(AuthContext)
  const { clearSelectors } = useContext(SelectorContext)

  const hasFilter = useSelector(selectHasFilter())
  const { activityStatus, activityType } = useSelector(
    (state) => state.activities
  )

  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const { findDraftsByCropIdOffline } = useOfflineDrafts()

  const [loading, setLoading] = useState(true)
  const [crop, setCrop] = useState(null)
  const [open, setOpen] = useState(false)
  const [cropName, setCropName] = useState(null)
  const [drafts, setDrafts] = useState([])
  const [licensesPending, setLicensesPending] = useState([])

  const [
    isSnackbarLicensesPendingVisible,
    setIsSnackbarLicensesPendingVisible,
  ] = useState(false)
  const [snackbarText, setSnackbarText] = useState('')
  const {
    isModalVisible: isModalValidateVisible,
    toggleModal: displayToggleModalValidate,
    closeModal: closeModalValidate,
  } = useModal()
  const {
    isModalVisible: isModalNoLogerLotsVisible,
    toggleModal: displayToggleModalNoLogerLots,
    closeModal: closeModalNoLogerLots,
  } = useModal()

  const fabActions = [
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_HARVEST.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_2,
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_HARVEST.canPlanning
            ? 'CommonOptionalPlanned'
            : 'ActivityCreate',
          {
            cropId: route.params.id,
            tag: activityTypesConstants.ACT_HARVEST.key,
            canSign: true,
          }
        ),
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_MONITORING.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_6,
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_MONITORING.canPlanning
            ? 'CommonOptionalPlanned'
            : 'ActivityCreate',
          {
            cropId: route.params.id,
            tag: activityTypesConstants.ACT_MONITORING.key,
            canSign: true,
          }
        ),
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_FERTILIZATION.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_4,
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_FERTILIZATION.canPlanning
            ? 'CommonOptionalPlanned'
            : 'CommonActivities',
          {
            id: route.params.id,
            mode: 'fertilization',
            tag: activityTypesConstants.ACT_FERTILIZATION.key,
            areaUnit: crop.areaUnit,
            cropTypeName: crop.cropType.codeLabel,
            surface: crop?.surface,
          }
        ),
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_APPLICATION.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_5,
      onPress: () => {
        clearSelectors()
        navigation.navigate(
          activityTypesConstants.ACT_APPLICATION.canPlanning
            ? 'CommonOptionalPlanned'
            : 'CommonActivities',
          {
            id: route.params.id,
            mode: 'application',
            tag: activityTypesConstants.ACT_APPLICATION.key,
            areaUnit: crop.areaUnit,
            cropTypeName: crop.cropType.codeLabel,
            surface: crop?.surface,
          }
        )
      },
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon name={activityTypesConstants.ACT_SOWING.key} {...props} />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_1,
      style: { color: 'red' },
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_SOWING.canPlanning
            ? 'CommonOptionalPlanned'
            : 'CommonActivities',
          {
            id: route.params.id,
            mode: 'sowing',
            tag: activityTypesConstants.ACT_SOWING.key,
            areaUnit: crop.areaUnit,
            cropTypeName: crop.cropType.codeLabel,
            surface: crop?.surface,
          }
        ),
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_TILLAGE.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_3,
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_TILLAGE.canPlanning
            ? 'CommonOptionalPlanned'
            : 'CommonActivities',
          {
            id: route.params.id,
            mode: 'tillage',
            tag: activityTypesConstants.ACT_TILLAGE.key,
            areaUnit: crop.areaUnit,
            cropTypeName: crop.cropType.codeLabel,
            surface: crop?.surface,
          }
        ),
    },
    {
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <ActivityIcon
          name={activityTypesConstants.ACT_AGREEMENTS.key}
          {...props}
        />
      ),
      label: t('VIEWS').ACTIVITIES.TEXT_7,
      onPress: () =>
        navigation.navigate(
          activityTypesConstants.ACT_AGREEMENTS.canPlanning
            ? 'CommonOptionalPlanned'
            : 'ActivityCreate',
          {
            cropId: route.params.id,
            tag: activityTypesConstants.ACT_AGREEMENTS.key,
            canSign: true,
          }
        ),
    },
  ]

  const type = crop?.members?.find(
    (member) => member?.user?._id === user._id
  )?.type
  const canWrite =
    type !== userTypes.MARKETER.key && type !== userTypes.PROVIDER.key

  const cropStatus =
    !crop?.company && crop?.status !== 'DRAFT'
      ? t('VIEWS').CROP.TEXT_29
      : crop?.status !== 'DRAFT'
      ? `${crop.pending.length} ${t('VIEWS').CROP.TEXT_30}`
      : crop?.status

  const userType = useCallback(
    String(
      crop?.members?.find((member) => member?.user?._id === user._id)?.type
    ),
    [crop?.members]
  )

  const { onPressCropMenu } = useCropMenu({})

  const getIsCropOffline = (members) =>
    !!members?.find(
      (member) => member?.user?._id === user._id && member.isOfflineEnabled
    ) || orderCropsBy?.key === ORDER_MENU_ENUM.ENABLED_OFFLINE

  const isCropOffline = useCallback(getIsCropOffline(crop?.members), [
    getIsCropOffline,
  ])

  const handleCropMenu = (option) => {
    if (option === CROP_MENU_OPTIONS.CROP_STORY) {
      enterToCropStory({
        date: new Date(),
        fromView: 'ACTIVITY_LIST',
        cropId: crop._id,
        fieldId: '',
        userId: user._id,
        userRol: config?.roleSelected?.equivalentRole,
        userIsAdmin: config?.isAdmin,
        userIsResponsible: config?.isResponsible,
        os: Platform.OS,
      })
    }

    onPressCropMenu(crop, option, canWrite)
  }

  useFocusEffect(
    useCallback(() => {
      fetchData(route.params.id)
      return () => dispatch(activitiesActions.setIsActivities(false))
    }, [isConnected])
  )

  useEffect(() => {
    if (isConnected && !isCompanyVerifiers() && licensesPending.length) {
      setIsSnackbarLicensesPendingVisible(true)
    }
  }, [isConnected, licensesPending])

  const fetchData = useCallback(
    async (id) => {
      let me = {}
      let responseData = null
      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          url: `crops/${id}`,
          version: 'v2',
          params: {
            companyId: config.companySelected?._id,
            createdAt: Date.now(),
          },
          displayAlert: false,
        })
        responseData = response ? response.data : []
      } else {
        responseData = await showIndividuals('crops', id)
      }

      let draftsByCrop = []

      if (isConnected) {
        if (isVerifierUcropit) {
          draftsByCrop = await getDraftsVerificationByCropIdAndIdentifier(
            id,
            config.companySelected?.identifier
          )
        } else {
          draftsByCrop = await getDraftsByCropId(id)
        }
      } else {
        const [errorFindDraftsOffline, responseDrafts] =
          await findDraftsByCropIdOffline(id)
        if (errorFindDraftsOffline) {
          alert(errorFindDraftsOffline)

          return
        }
        draftsByCrop = responseDrafts
      }

      if (responseData.verificationTypes) {
        dispatch(
          verificationTypesActions.setVerificationTypes({
            verificationTypesData: responseData.verificationTypes,
            draftsByCrop,
          })
        )
      }
      setCurrentCrop(responseData)

      me = responseData.members.find((el) => el.user._id === user._id)

      /*
      TODO: THIS IS NOT NECESSARY BECAUSE THE SERVER ALREADY FILTER BY USER TYPE

      responseData.pending = responseData.pending
        .filter((activity) => {
          return canSeeActivity(activity, me)
        })
        .filter((activity) => activity)

      responseData.toMake = responseData.toMake
        .filter((activity) => {
          return canSeeActivity(activity, me)
        })
        .filter((activity) => activity)

      responseData.done = responseData.done
        .filter((activity) => {
          return canSeeActivity(activity, me)
        })
        .filter((activity) => activity)

      responseData.finished = responseData.finished
        .filter((activity) => {
          return canSeeActivity(activity, me)
        })
        .filter((activity) => activity)

        */

      let auxDrafts
      if (isVerifierUcropit) {
        auxDrafts = draftsByCrop
      } else {
        auxDrafts = draftsByCrop?.filter(
          (element) =>
            element.tag === activityTypesConstants.ACT_AGREEMENTS.key ||
            element.tag === activityTypesConstants.ACT_MONITORING.key ||
            element.tag === activityTypesConstants.ACT_HARVEST.key ||
            element.isDirectAchievement
        )
      }
      setDrafts(auxDrafts || [])

      for (const activity of [...responseData.toMake, ...responseData.done]) {
        const activityDrafts = draftsByCrop?.filter(
          (data) => data.activity === activity._id
        )
        activity.isDraft = activityDrafts?.filter(
          (activity) => !activity.isRejected
        )?.length
        activity.draftsRejected = activityDrafts?.filter(
          (activity) => activity.isRejected
        )?.length
      }

      setCropName(
        `${responseData.cropType.codeLabel} ${_.capitalize(
          moment(responseData.dateCrop).format('MMM/YYYY').replace('.', '')
        )} - ${_.capitalize(
          moment(responseData.dateHarvest).format('MMM/YYYY').replace('.', '')
        )}`
      )

      setCrop(responseData)
      const isEmpty = !(
        auxDrafts.length ||
        responseData.toMake.length ||
        responseData.done.length ||
        responseData.finished.length
      )
      dispatch(activitiesActions.setIsActivities(!isEmpty))

      if (
        me?.type === userTypes.PRODUCER.key ||
        me?.type === userTypes.PRODUCER_ADVISER.key ||
        me?.type === userTypes.CAM.key ||
        me?.type === userTypes.KAM.key
      ) {
        fetchLicensesPending({
          companyId: config.companySelected?._id,
          cropId: responseData._id,
        })
      }

      setLoading(false)
    },
    [loading, isConnected]
  )

  const fetchLicensesPending = async ({ companyId, cropId }) => {
    if (!isConnected) {
      return
    }

    let licensesPending = []

    try {
      const { data } = await doRequest({
        method: 'GET',
        url: 'licenses/search-by-signed-not-applied',
        params: {
          companyId,
          cropId,
        },
      })

      licensesPending = data
    } catch (error) {
      console.error(error)

      return
    }

    if (!licensesPending?.length) {
      return
    }

    setLicensesPending(licensesPending)

    let licenseName

    if (licensesPending[0].name.length > 45) {
      licenseName = `${licensesPending[0].name.length.substring(0, 45)}...`
    } else {
      licenseName = licensesPending[0].name
    }

    let snackbarText = ''

    if (!licensesPending.find((element) => element.hasSubLicenses)) {
      if (licensesPending.length === 1) {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_34', {
          licenseName: licenseName,
        })
      } else {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_35')
      }
    } else {
      if (licensesPending.length === 1) {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_36', {
          licenseName: licenseName,
        })
      } else {
        snackbarText = t('VIEWS.ACTIVITIES.TEXT_37')
      }
    }

    setSnackbarText(snackbarText)

    setTimeout(() => setIsSnackbarLicensesPendingVisible(false), 10000)
  }

  const handleFab = () => {
    if (isVerifierUcropit) {
      const isError = searchErrorInAcVerification(crop, drafts)
      if (isError === MODAL_ERRORS.EXIST_VERIFICATION_DRAFT) {
        displayToggleModalValidate()
      } else if (isError === MODAL_ERRORS.NO_LOTS_AVAILABLE) {
        displayToggleModalNoLogerLots()
      } else {
        navigation.navigate('ActivityCreate', {
          cropId: route.params.id,
          tag: activityTypesConstants.ACT_VERIFICATION.key,
          canSign: true,
          notDeepLink: true,
        })
      }
    } else {
      setOpen(!open)
    }
  }

  /*
  TODO: THIS IS NOT NECESSARY BECAUSE THE SERVER ALREADY FILTER BY USER TYPE

  const canSeeActivity = (activity, user) => {
    if (!activity.typeAgreement) return activity

    if (
      activity.typeAgreement.key === 'COMMER' ||
      activity.typeAgreement.key === 'FINAN' ||
      activity.typeAgreement.key === 'EXPLO'
    )
      return activity

    if (!isUserProvider(user) && !isUserMarketer(user)) return activity

    if (
      isUserProvider(user) &&
      activity.typeAgreement &&
      agreementIncludeKey(activity.typeAgreement.key)
    ) {
      if (activity.typeAgreement?.visible?.includes(user.identifier)) {
        return activity
      }
    }

    if (
      isUserMarketer(user) &&
      activity.typeAgreement &&
      agreementIncludeKey(activity.typeAgreement.key)
    ) {
      if (activity.typeAgreement?.visible?.includes(user.identifier)) {
        return activity
      }
    }

    return undefined
  }
  */

  const handleNav = (item, status) => {
    if (!item?.type?.canPlanning || item?.isDirectAchievement === true) {
      if (status === activitiesStatus.pending.key) {
        return navigation.navigate('ActivityCreate', {
          cropId: route.params.id,
          activityToDelete: item._id,
          tag: item.type.tag,
          canSign: true,
          isDirectAchievement: item?.isDirectAchievement,
          notDeepLink: true,
        })
      }

      if (status === activitiesStatus.done.key) {
        let canSign = false
        let isSigned = false

        for (const element of item.signers) {
          if (!element.signed && element.userId === user._id) {
            canSign = true
          }

          if (element.signed && element.userId === user._id) {
            isSigned = true
          }
        }

        return navigation.navigate('ActivityUpdate', {
          cropId: route.params.id,
          activityId: item._id,
          tag: item.type.tag,
          canSign,
          isSigned,
          isRealized: true,
          isDirectAchievement: item?.isDirectAchievement,
          notDeepLink: true,
        })
      }

      if (status === activitiesStatus.finished.key) {
        return navigation.navigate('ActivityFinished', {
          cropId: route.params.id,
          activityId: item._id,
          tag: item.type.tag,
          isRealized: true,
          isFinished: true,
          isDirectAchievement: item?.isDirectAchievement,
          notDeepLink: true,
        })
      }
    }

    const paths = {
      ACT_AGREEMENTS: 'AgreementEdit',
      ACT_MONITORING: 'MonitoringEdit',
      ACT_SOWING: 'AchievementsActivityList',
      ACT_TILLAGE: 'AchievementsActivityList',
      ACT_FERTILIZATION: 'AchievementsActivityList',
      ACT_APPLICATION: 'AchievementsActivityList',
      ACT_HARVEST: 'HarvestEdit',
    }

    const modes = {
      ACT_SOWING: 'sowing',
      ACT_TILLAGE: 'tillage',
      ACT_FERTILIZATION: 'fertilization',
      ACT_APPLICATION: 'application',
    }

    const mode = modes[item.type.tag]

    if (!paths[item.type.tag]) return false

    if (paths[item.type.tag] && status !== activitiesStatus.pending.key) {
      return navigation.navigate('AchievementsActivityList', {
        cropId: route.params.id,
        mode: mode,
        tag: item.type.tag,
        activityId: item._id,
      })
    } else {
      return navigation.navigate('CommonActivities', {
        id: route.params.id,
        mode: mode,
        tag: item.type.tag,
        activity: item._id,
        areaUnit: crop.areaUnit,
        cropTypeName: crop.cropType.codeLabel,
        surface: crop?.surface,
        screensToReturn: 2,
      })
    }
  }

  const showEiqAchievement = (item) => {
    let achievementsEiq = 0

    achievementsEiq = item.achievements.reduce(
      (accumulator, { supplies, lotsWithSurface, surface }) => {
        const surfaceRealized = lotsWithSurface
          ? lotsWithSurface.reduce(
              (subAccumulator, { surfaceAchievement }) =>
                subAccumulator + surfaceAchievement,
              0
            )
          : surface

        return accumulator + calculateEiq(supplies, surfaceRealized)
      },
      0
    )

    const eiqRange = getEIQRange(achievementsEiq)

    return (
      <EiqAchievement
        item={item}
        crop={crop}
        eiqRange={eiqRange}
        achievementsEiq={achievementsEiq}
      />
    )
  }

  const goToDraft = (draft) => {
    return navigation.navigate('ActivityUpdate', {
      cropId: route.params.id,
      draftId: draft.id,
      tag: draft.tag,
      canSign: true,
      isDirectAchievement: draft?.isDirectAchievement,
      notDeepLink: true,
    })
  }

  /**
   * ON PRESS GO TO LICENSE DETAILS
   */
  const onPressGoToLicenseDetails = () => {
    if (licensesPending.length === 1) {
      navigation.navigate('LicenseDetails', {
        cropId: route.params.id,
        licenseId: licensesPending[0]._id,
      })
    } else {
      navigation.navigate('Licenses', {
        cropId: route.params.id,
      })
    }
  }

  /**
   * BUILD ESTABLISHMENT FOR RENDER IN DRAFTS CARDS
   */
  const buildEstablishmentDraft = (lotsInDraft) => {
    const result = crop.establishments
      .map((establishment) => {
        const lots = establishment.lots.filter((lot) =>
          lotsInDraft.find((lotInDraft) => getObjectId(lotInDraft) === lot._id)
        )
        if (lots.length) {
          return {
            ...establishment,
            lots,
          }
        }
      })
      .filter((establishment) => establishment)
    return result
  }

  return (
    <Screen
      activityTypes={activityTypes}
      agreementTypes={agreementTypes}
      buildEstablishmentDraft={buildEstablishmentDraft}
      canWrite={canWrite}
      closeModalNoLogerLots={closeModalNoLogerLots}
      closeModalValidate={closeModalValidate}
      companyAllow={companyAllow}
      crop={crop}
      cropName={cropName}
      cropStatus={cropStatus}
      drafts={drafts}
      fabActions={fabActions}
      goToDraft={goToDraft}
      handleCropMenu={handleCropMenu}
      handleFab={handleFab}
      handleNav={handleNav}
      hasFilter={hasFilter}
      isConnected={isConnected}
      isCropOffline={isCropOffline}
      isModalNoLogerLotsVisible={isModalNoLogerLotsVisible}
      isModalValidateVisible={isModalValidateVisible}
      isSnackbarLicensesPendingVisible={isSnackbarLicensesPendingVisible}
      isVerifierUcropit={isVerifierUcropit}
      licensesPending={licensesPending}
      loading={loading}
      onPressGoToLicenseDetails={onPressGoToLicenseDetails}
      open={open}
      showEiqAchievement={showEiqAchievement}
      snackbarText={snackbarText}
      storageTypes={storageTypes}
      subTypeActivities={subTypeActivities}
      unitTypes={unitTypes}
      userType={userType}
      verificationTypes={verificationTypes}
    />
  )
}

ActivitiesScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default ActivitiesScreen
