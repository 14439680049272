import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Card, Paragraph, Title } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon, SustainabilityIcon } from '@modules/common/components'
import statusEiq from '@constants/statusEiq'
import { LanguageContext } from '@contextState/language'
import activityTypes from '@constants/activityTypes'

const EiqCardSimple = ({ title, range, tag }) => {
  const { t } = useContext(LanguageContext)
  const getStars = () => {
    const stars = []

    for (let i = 0; i < statusEiq[range].starsQuantity; i++) {
      stars.push(
        <CommonIcon
          key={i}
          name='STAR'
          size={12}
          color={statusEiq[range].color}
        />
      )
    }

    return <>{stars}</>
  }

  return (
    <View style={styles.spaceCard}>
      <Card>
        <Card.Content style={styles.card}>
          <View style={styles.cardItem}>
            <View
              style={[
                styles.icoContainer,
                { backgroundColor: statusEiq[range].background },
              ]}
            >
              <SustainabilityIcon
                name={'EIQ'}
                size={20}
                color={statusEiq[range].color}
              />
            </View>
          </View>
          <View style={styles.dataMargin}>
            <Title style={styles.title}>{title}</Title>
            <Paragraph style={styles.subTitle}>
              {getStars()} {statusEiq[range].name(t)}
            </Paragraph>
          </View>
        </Card.Content>
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
    lineHeight: 16,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333',
  },
  spaceCard: {
    marginBottom: 10,
  },
  card: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  cardItem: {
    marginLeft: 6,
    marginRight: 6,
  },
  icoContainer: {
    height: 30,
    width: 41,
    borderRadius: 64,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataMargin: {
    marginLeft: 10,
  },
})

EiqCardSimple.propTypes = {
  title: PropTypes.string,
  range: PropTypes.string,
  tag: PropTypes.string,
}

export default EiqCardSimple
