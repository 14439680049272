import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView } from 'react-native-gesture-handler'
import { Button, List, Text, Title } from 'react-native-paper'
import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'

import { AuthContext } from '@contextState/auth'
import { CommonContext } from '@contextState/common'

import useNetwork from '@utils/network'
import ButtonCustom from '../common/ButtonCustom'
import CustomDatePicker from '../common/CustomDatePicker'
import FilesList from '../common/FilesList'
import Input from '../common/Input'
import ModalCustom from '../common/ModalCustom'
import Select from '../common/Select'
import SnackbarCustom from '../common/SnackbarCustom'
import UploadInput from '../common/UploadInput'
import { cuitValidator } from '@utils/validation'
import { LanguageContext } from '@contextState/language'
import { useFocusEffect } from '@react-navigation/core'
import { collaboratorsActions, draftCropActions } from '@store/actions'
import userTypes from '@constants/userTypes'
import { selectDraftCrop } from '@store/selectors/draftCrops'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import { identifierName } from '@utils/countries'
import { CommonIcon } from '@modules/common/components'

function FormAdd({ data, navigation, inNewCrop, identifierCrop }) {
  const dispatch = useDispatch()
  const { doRequest } = useNetwork()
  const { config, fetchUser } = useContext(AuthContext)
  const { countries, allCountryData } = useContext(CommonContext)

  const valuesForm = useSelector(
    selectDraftCrop(config.companySelected?.identifier)
  )

  const [files, setFiles] = useState([])
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [visible, setVisible] = useState(false)
  const [countriesData, setCountriesData] = useState([])
  const [initialValues, setInitialValues] = useState({
    identifier: '',
    name: '',
    address: '',
    addressFloor: '',
    country: '',
  })
  const { t } = useContext(LanguageContext)
  /**
   * UPDATE COUNTRY STATE
   */
  useEffect(() => {
    if (!countries.length) {
      return
    }

    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  useFocusEffect(
    useCallback(() => {
      if (inNewCrop) {
        setInitialValues({
          ...initialValues,
          country: valuesForm?.country?.value,
          identifier: valuesForm.identifier,
        })
      }
    }, [])
  )

  function handleFile({ file }) {
    setSelectedFile(file)
  }

  function handleFileSubmit(values) {
    setFiles([...files, { ...values, file: selectedFile }])
    setVisible(false)
    setSelectedFile(null)
  }

  function handleFileDelete(index) {
    setFiles(files.filter((el, key) => key !== index))
  }

  const makeFormData = (dataCrop) => {
    const formData = new FormData()
    const newData = JSON.stringify(dataCrop)
    formData.append('data', newData)

    files.forEach((el) => {
      formData.append('files', el.file)
    })

    return formData
  }

  async function handleSubmitForm(values, { setSubmitting }) {
    values.identifier = values.identifier.trim()
    const dataCrop = {
      ...values,
      identifier: data?.identifier || values.identifier,
      evidences: files.map(({ name, description, date }) => ({
        name,
        description,
        date,
      })),
    }

    try {
      if (inNewCrop) {
        dispatch(
          collaboratorsActions.setCreateCollaboratorConfig({
            isCountryDisabled: true,
            isIdentifierDisabled: true,
            isEmailDisabled: false,
            isUserTypeDisabled: true,
            defaultUserType: {
              value: userTypes.PRODUCER.key,
              label: userTypes.PRODUCER.name(t),
            },
            userTypesAllowed: [userTypes.PRODUCER.key],
            defaultCountry: valuesForm.country,
            defaultIdentifier: valuesForm.identifier,
            defaultEmail: '',
            callback: async (data, setSubmitting) => {
              try {
                const collaborators = [
                  {
                    identifier: data.identifier,
                    type: data.userType,
                    email: data.email,
                    owner: true,
                  },
                ]
                dataCrop.email = data.email
                const formData = makeFormData(dataCrop)
                await doRequest({
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  method: 'POST',
                  url: 'companies/companies-users',
                  data: formData,
                })
                dispatch(draftCropActions.setDraftCollaborators(collaborators))
                navigation.navigate('LotsAdd', { identifier: identifierCrop })
              } catch (error) {
                console.error('error company-users', error)
              }
              setSubmitting(false)
            },
          })
        )
        dispatch(draftCropActions.setDraftValuesCompany(dataCrop))
        navigation.navigate('CollaboratorCreate', {
          identifier: identifierCrop,
        })
      } else {
        const formData = makeFormData(dataCrop)
        const newCompany = await doRequest({
          method: 'POST',
          url: 'companies',
          data: formData,
        })
        await fetchUser()
        navigation.navigate('CropList')
        await doRequest({
          method: 'PUT',
          url: `configurations/${config._id}`,
          data: {
            companySelected: newCompany.data._id,
          },
        })
        setSubmitting(false)
      }
    } catch (err) {
      console.warn(err)
      showError(t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_1)
    }
  }

  function showError(msg) {
    setSnackBarMessage(msg)
    setVisibleSnackbar(true)
  }

  /**
   *
   * @param {*} value
   * @returns VALIDATE COUNTRY SELECTED
   */
  const validateCompany = (value) => {
    value = value.trim()
    let error = ''

    if (!value) {
      error = t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_2
    } else if (value && isNaN(Number(value))) {
      error = t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_3
    } else if (!cuitValidator.test(value)) {
      error = t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_4
    } else if (/\s/g.test(value)) {
      error = t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_5
    }

    return error
  }

  /**
   *
   * @param {*} values
   * @returns Input Company
   */
  const renderInputIdentifier = ({ country }) => {
    const nameCountry = countriesData.find(
      (element) => element.value === country
    )?.label

    if (nameCountry === 'Argentina') {
      return (
        <Field
          component={Input}
          name='identifier'
          validate={validateCompany}
          inputProps={{
            label: identifierName(allCountryData(country)?.alpha3Code, t),
          }}
          helpText={t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_1.HELPTEXT}
          disabled={inNewCrop}
        />
      )
    } else {
      return (
        <Field
          component={Input}
          name='identifier'
          inputProps={{
            label: identifierName(allCountryData(country)?.alpha3Code, t),
          }}
          helpText={t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_1.HELPTEXT}
          disabled={inNewCrop}
        />
      )
    }
  }

  return (
    <>
      <ModalCustom visible={visible} hideModal={() => setVisible(false)}>
        <Title style={styles.modalTitle}>
          {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_6}
        </Title>
        <Formik
          initialValues={{ name: '', description: '', date: new Date() }}
          onSubmit={handleFileSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <Field
                component={Input}
                name='name'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_3.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_3.LABEL,
                }}
                validate={(value) =>
                  !value && t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_3.VALIDATE
                }
              />
              <Field
                component={Input}
                name='description'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_4.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_4.LABEL,
                }}
                validate={(value) =>
                  !value && t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_4.VALIDATE
                }
              />
              <Field
                component={CustomDatePicker}
                name='date'
                placeholder={
                  t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_5.PLACEHOLDER
                }
                label={t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_5.LABEL}
              />
              <UploadInput
                cancelOnPress={() => setVisible(false)}
                showCameraRollOption={Platform.OS !== 'web'}
                handleFile={handleFile}
                accept={`.doc,.docx,.pdf,${ALLOW_IMAGES_FORMAT}`}
              >
                <View
                  style={{
                    borderRadius: 2,
                    padding: 4,
                    fontWeight: '400',
                  }}
                >
                  <Text
                    style={{
                      color: 'green',
                      textAlign: 'center',
                      padding: 4,
                    }}
                  >
                    {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_7}
                  </Text>
                </View>
              </UploadInput>
              {selectedFile && (
                <List.Item
                  title={selectedFile.name}
                  left={() => <CommonIcon name='FILE-UPLOAD' size={20} />}
                />
              )}
              <View style={styles.actions}>
                <Button onPress={() => setVisible(false)}>
                  {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_8}
                </Button>
                <Button
                  disabled={!selectedFile}
                  onPress={handleSubmit}
                  labelStyle={{ color: 'white' }}
                  mode='contained'
                >
                  {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_9}
                </Button>
              </View>
            </>
          )}
        </Formik>
      </ModalCustom>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <View style={{ flex: 1 }}>
              <ScrollView style={styles.container}>
                <Field
                  component={Select}
                  name='country'
                  label={t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_6.LABEL}
                  options={countriesData}
                  disabled={inNewCrop}
                  values={values.country}
                  withOutIcon={inNewCrop}
                />
                {data?.identifier ? (
                  <View style={styles.headerText}>
                    <Title>
                      {identifierName(
                        allCountryData(values.country)?.alpha3Code,
                        t
                      )}
                    </Title>
                    <Text>{data.identifier}</Text>
                  </View>
                ) : (
                  renderInputIdentifier(values)
                )}
                <Field
                  component={Input}
                  name='name'
                  inputProps={{
                    label: t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_7.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_7.PLACEHOLDER,
                  }}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_7.VALIDATE
                  }
                />

                <Field
                  component={Select}
                  name='typePerson'
                  label={t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_13}
                  options={[
                    {
                      value: 'PHYSICAL_PERSON',
                      label:
                        t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_8.OPTION_1,
                    },
                    {
                      value: 'LEGAL_PERSON',
                      label:
                        t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_8.OPTION_2,
                    },
                  ]}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_8.VALIDATE
                  }
                />

                <Field
                  component={Input}
                  name='address'
                  inputProps={{
                    label: t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_9.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_9.PLACEHOLDER,
                  }}
                  validate={(value) =>
                    !value &&
                    t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_9.VALIDATE
                  }
                />

                <Field
                  component={Input}
                  name='addressFloor'
                  inputProps={{
                    label: t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_10.LABEL,
                    placeholder:
                      t('COMPONENTS').COMPANIES.FORM_ADD.FIELD_10.PLACEHOLDER,
                  }}
                />

                <Button
                  style={{ marginBottom: 30 }}
                  onPress={() => setVisible(true)}
                >
                  {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_11}
                </Button>

                <View style={styles.filesContainer}>
                  <FilesList
                    data={files}
                    files={files}
                    onDelete={handleFileDelete}
                  />
                </View>
              </ScrollView>

              <ButtonCustom
                isLoading={isSubmitting}
                disabled={files.length === 0}
                onPress={handleSubmit}
                styles={styles.button}
              >
                {t('COMPONENTS').COMPANIES.FORM_ADD.TEXT_12}
              </ButtonCustom>
            </View>
          )
        }}
      </Formik>
      <SnackbarCustom
        visible={visibleSnackbar}
        onToggle={() => {
          setSnackBarMessage('')
          setVisibleSnackbar(false)
        }}
      >
        {snackBarMessage}
      </SnackbarCustom>
    </>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  container: {
    padding: 16,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  filesContainer: {
    marginBottom: 30,
  },
  button: {
    margin: 16,
  },
})

FormAdd.propTypes = {
  data: PropTypes.object,
  navigation: PropTypes.object,
}

export default FormAdd
