import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'

export const ValidateButton = ({
  isPlanification,
  isValidateButtonAvailable,
  onPressValidateButton,
}) => {
  const { t } = useContext(LanguageContext)

  const buttonLabel = isPlanification
    ? t('VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.VALIDATE_BUTTON.PLAN')
    : t('VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.VALIDATE_BUTTON.VALIDATE')

  return (
    <ButtonCustom
      disabled={!isValidateButtonAvailable}
      onPress={onPressValidateButton}
      styles={[
        styles.button,
        !isValidateButtonAvailable ? styles.buttonDisabled : {},
      ]}
      labelStyle={[
        styles.textButton,
        !isValidateButtonAvailable ? styles.textButtonDisabled : {},
      ]}
    >
      {buttonLabel}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    marginTop: 32,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
})

ValidateButton.propTypes = {
  isPlanification: PropTypes.bool.isRequired,
  isValidateButtonAvailable: PropTypes.bool.isRequired,
  onPressValidateButton: PropTypes.func.isRequired,
}
