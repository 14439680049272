import React from 'react'
import PropTypes from 'prop-types'
import {
  DirectTillageActivity,
  DirectApplicationActivity,
  DirectSowingActivity,
  DirectFertilizationActivity,
} from '../'
import { ActivityHarvest, ActivityMonitoring } from '../'

export const ActivityCardDone = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  status,
  signed,
  signedIf,
  supplies,
  suppliesList,
  envImpactIndex,
  pay,
  storages,
  dateHarvest,
  dateObservation,
  unitType,
  unitTypeKey,
  volume,
  areaUnitVolume,
  areaUnit,
  dateOrder,
  surface,
}) => {
  return (
    <>
      <ActivityHarvest
        activityType={activityType}
        storages={storages}
        areaUnitVolume={areaUnitVolume}
        dateHarvest={dateHarvest}
        dateObservation={dateObservation}
        percent={percent}
        farms={farms}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
      />

      <ActivityMonitoring
        activityType={activityType}
        storages={storages}
        areaUnitVolume={areaUnitVolume}
        dateHarvest={dateHarvest}
        dateObservation={dateObservation}
        percent={percent}
        farms={farms}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
      />

      <DirectTillageActivity
        activityType={activityType}
        percent={percent}
        farms={farms}
        subTypeActivity={subTypeActivity}
        status={status}
        signed={signed}
        signedIf={signedIf}
        supplies={supplies}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        surface={surface}
      />

      <DirectApplicationActivity
        activityType={activityType}
        percent={percent}
        farms={farms}
        subTypeActivity={subTypeActivity}
        status={status}
        signed={signed}
        signedIf={signedIf}
        supplies={supplies}
        envImpactIndex={envImpactIndex}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        surface={surface}
      />

      <DirectSowingActivity
        activityType={activityType}
        percent={percent}
        farms={farms}
        subTypeActivity={subTypeActivity}
        status={status}
        signed={signed}
        signedIf={signedIf}
        supplies={supplies}
        suppliesList={suppliesList}
        envImpactIndex={envImpactIndex}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        surface={surface}
      />

      <DirectFertilizationActivity
        activityType={activityType}
        percent={percent}
        farms={farms}
        subTypeActivity={subTypeActivity}
        status={status}
        signed={signed}
        signedIf={signedIf}
        supplies={supplies}
        suppliesList={suppliesList}
        envImpactIndex={envImpactIndex}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
        areaUnit={areaUnit}
        dateOrder={dateOrder}
        surface={surface}
      />
    </>
  )
}

ActivityCardDone.prototype = {
  activityType: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  farms: PropTypes.array,
  subTypeActivity: PropTypes.string,
  status: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  suppliesList: PropTypes.array,
  envImpactIndex: PropTypes.object,
  pay: PropTypes.number,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.number,
  areaUnitVolume: PropTypes.string,
  areaUnit: PropTypes.string,
  dateOrder: PropTypes.string,
}
