export default {
  TEXT_1: 'UCROP.IT | Iniciar sessão',
  TEXT_2: 'UCROP.IT | Registro',
  TEXT_3: 'UCROP.IT | Verificar código',
  TEXT_4: 'UCROP.IT | Criar Pin',
  TEXT_5: 'UCROP.IT | Registro de empresa',
  TEXT_6: 'Registro de empresa',
  TEXT_7: 'Selecionar Lotes',
  TEXT_8: 'Selecionar colaboradores',
  TEXT_9: 'Selecionar insumos',
  TEXT_10: 'Conexão não disponível',
  TEXT_11: 'Conexão limitada',
  TEXT_12: 'FECHAR',
  TEXT_13: 'Sincronizando rascunhos',
}
