import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { ConnectionContext } from '@contextState/connection'
import useNetwork from '@utils/network'
import useOfflineCommon from '@offline/queries/common'

const useFetchFarmListByCrop = () => {
  const { isConnected } = useContext(ConnectionContext)
  const { doRequest } = useNetwork()
  const route = useRoute()
  const { cropId, activityId, callback, farmId } =
    useRef(route.params).current ?? {}
  const { showIndividuals } = useOfflineCommon()
  const [farms, setFarms] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [farmSelected, setFarmSelected] = useState(null)
  useEffect(() => {
    fetchFarm()
  }, [cropId, farmId])

  const fetchFarm = async () => {
    setIsLoading(true)
    let farmsResult
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${cropId}/establishments`,
        params: {
          cropId: cropId,
          activityId: activityId,
        },
      })
      farmsResult = response.data
    } else {
      const cropResponse = await showIndividuals('crops', cropId)

      let establishments
      if (activityId) {
        const offlineActivityData = await showIndividuals(
          'activities',
          activityId
        )
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots.filter((lot) =>
            offlineActivityData.lots.some(
              (lotActivity) => lotActivity._id === lot._id
            )
          ),
        }))
      } else {
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots,
        }))
      }
      farmsResult = establishments
    }
    const auxFarmSelected = farmsResult.find(
      (fResult) => String(fResult.farm) === String(farmId)
    )
    if (auxFarmSelected) {
      setFarmSelected(auxFarmSelected)
    }
    setFarms(farmsResult)
    setIsLoading(false)
  }

  const onSelectFarm = (farm) => {
    setFarmSelected(farm)
  }

  const selectFarm = () => {
    if (callback) {
      callback(farmSelected)
    }
  }

  return { farms, isLoading, onSelectFarm, farmSelected, selectFarm }
}

export default useFetchFarmListByCrop
