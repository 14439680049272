import { useContext, useMemo } from 'react'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { useCompanyInfo, useChangeOutliersHarvest } from '@modules/common/hooks'
import { parseSupplies, parseEvidences, parseStorages } from '../utils'

export const useGetActivityDTO = ({ activityTypeTag }) => {
  const { t } = useContext(LanguageContext)

  const {
    temporalData: {
      cropName,
      cropType,
      supplies: selectorSupplies = [],
      evidences: selectorEvidences = [],
      dataToValidate: {
        lots: activityLots = [],
        surface: activitySurface,
        subTypeActivity: activitySubTypeActivity,
        dateStart: activityDateStart,
        dateEnd: activityDateEnd,
        dateAchievement: activityDateAchievement,
        dateHarvest: activityDateHarvest,
        dateEstimatedHarvest: activityDateEstimatedHarvest,
        dateObservation: activityDateObservation,
        pay: activityPay,
        volume: activityVolume,
        unitVolume: activityUnitVolume,
        unitType: activityUnitType,
        observation: activityObservation,
        signers: activitySigners = [],
        typeAgreement: activityAgreementType,
        storages: activityStorages = [],
        supplies: activitySupplies = [],
      },
    },
    activities: activityTypes,
    subTypeActivities: subActivityTypes,
    agreementTypes,
    unitTypes,
    storageTypes,
  } = useContext(CommonContext)

  const { unitArea, companyName } = useCompanyInfo()

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const fieldsQuantity = Number(activityLots.length)
  const surface = Number(activitySurface)
  const activityTypeName = activityTypes
    .find(({ tag }) => tag === activityTypeTag)
    .label.toLowerCase()
  const subActivityTypeName = subActivityTypes.find(
    ({ value }) => value === activitySubTypeActivity
  )?.label
  const dateStart = activityDateStart ? String(activityDateStart) : undefined
  const dateEnd = activityDateEnd ? String(activityDateEnd) : undefined
  const dateAchievement = activityDateAchievement
    ? String(activityDateAchievement)
    : undefined
  const dateHarvest = activityDateHarvest
    ? String(activityDateHarvest)
    : undefined
  const dateEstimatedHarvest = activityDateEstimatedHarvest
    ? String(activityDateEstimatedHarvest)
    : undefined
  const dateObservation = activityDateObservation
    ? String(activityDateObservation)
    : undefined
  const pay = activityPay ? Number(activityPay) : undefined
  const volume = activityVolume ? Number(activityVolume) : ''
  const unitVolume = activityUnitVolume ? activityUnitVolume : ''
  const unitType = unitTypes.find(({ value }) => value === activityUnitType)
  const unitTypeName = unitType?.label
  const unitTypeKey = unitType?.key
  const observation = activityObservation
    ? String(activityObservation)
    : undefined
  const collaboratorsQuantity = Number(activitySigners.length)
  const agreementTypeName = agreementTypes.find(
    ({ value }) => value === activityAgreementType
  )?.label
  const {
    suppliesSeeds = {},
    suppliesFertilizers = {},
    suppliesPhytotherapics = {},
  } = useMemo(
    () =>
      parseSupplies({
        supplies: selectorSupplies.length ? selectorSupplies : activitySupplies,
      }),
    [t]
  )
  const storages = useMemo(
    () => parseStorages({ storageTypes, storages: activityStorages }),
    [t]
  )
  const evidences = useMemo(
    () => parseEvidences({ evidences: selectorEvidences }),
    [t]
  )

  const activityDTO = {
    cropName,
    cropType,
    companyName,
    fieldsQuantity,
    surface,
    unitArea,
    activityTypeName,
    subActivityTypeName,
    dateStart,
    dateEnd,
    dateAchievement,
    dateHarvest,
    dateEstimatedHarvest,
    dateObservation,
    pay,
    volume,
    unitVolume,
    unitTypeName,
    unitTypeKey,
    observation,
    agreementTypeName,
    suppliesSeeds,
    suppliesFertilizers,
    suppliesPhytotherapics,
    storages,
    evidences,
    collaboratorsQuantity,
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  }

  return {
    activityDTO,
  }
}
