import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { Text } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import CircleChart from '@components/charts/CircleChart'
import { convertNumbertoString } from '@utils/number'
import { blackHighEmphasis } from '@styles/palette'
import { round } from 'lodash'

const ChartLots = ({ percent, size = 90, lots, total, style, areaUnit }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={[styles.containerSurface, style]}>
      <CircleChart percent={percent} size={size} />
      <View style={styles.containerTextHas}>
        <Text style={styles.textHas}>
          {`${convertNumbertoString(round(lots, 2))}/${convertNumbertoString(
            round(total, 2)
          )} ${areaUnit}.`}
        </Text>
        <Text style={styles.titleHas}>
          {t('VIEWS').SUMMARY_LANDSCAPE_SUSTAINABILITY.TEXT_2}
        </Text>
      </View>
    </View>
  )
}
ChartLots.propTypes = {
  percent: PropTypes.number.isRequired,
  size: PropTypes.number,
  lots: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}
const styles = StyleSheet.create({
  containerSurface: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerTextHas: {
    marginLeft: 25,
  },
  textHas: {
    fontSize: 20,
    fontWeight: '500',
  },
  titleHas: {
    fontSize: 10,
    fontWeight: '500',
    letterSpacing: 1.5,
    color: blackHighEmphasis,
  },
})
export default ChartLots
