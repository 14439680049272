import React from 'react'
import PropTypes from 'prop-types'
import { FlatList, StyleSheet } from 'react-native'
import { FarmCard } from '../FarmCard'
import { getFullAddres } from '../../utils/index'
import { statusFarm } from '@modules/common/utils'

export const FarmList = ({
  flatListRef,
  farmsData,
  nextPage,
  refreshPage,
  isLoading,
  goToFarmAvailable,
  goToFarmDraft,
}) => {
  return (
    <FlatList
      ref={flatListRef}
      data={farmsData}
      renderItem={({ item }) => {
        const {
          name,
          surface,
          areaUnit,
          locality,
          province,
          status,
          count_available_lot,
          count_lot,
        } = item

        return (
          <FarmCard
            name={name}
            surface={surface}
            areaUnit={areaUnit}
            address={getFullAddres(locality, province)}
            status={status}
            totalLots={count_lot}
            availableLots={count_available_lot}
            onPress={() =>
              status === statusFarm.DRAFT
                ? goToFarmDraft(item)
                : goToFarmAvailable(item)
            }
          />
        )
      }}
      keyExtractor={(item) => {
        return item.id
      }}
      contentContainerStyle={styles.contentContainerStyle}
      style={styles.flatStyle}
      onEndReachedThreshold={0.1}
      onEndReached={nextPage}
      onRefresh={refreshPage}
      refreshing={isLoading}
    />
  )
}

const styles = StyleSheet.create({
  flatStyle: {
    height: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingBottom: 25,
    marginTop: 14,
  },
})

FarmList.propTypes = {
  flatListRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  farmsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(statusFarm)),
      province: PropTypes.string,
      locality: PropTypes.string,
      surface: PropTypes.number,
      count_lot: PropTypes.number,
      sum_surface_lot: PropTypes.number,
      count_available_lot: PropTypes.number,
    })
  ).isRequired,
  nextPage: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
  isLoading: PropTypes.bool,
  goToFarmAvailable: PropTypes.func.isRequired,
  goToFarmDraft: PropTypes.func.isRequired,
}
