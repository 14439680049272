/**
 *
 * @param {string} farmName
 * @returns {object}
 */
export const getFormikInitialValues = (farmName = '') => {
  return {
    farmName,
  }
}
