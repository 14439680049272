import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import Accordion from '../common/v1/Accordion'
import PercentageOverlap from '../landscapeSustainability/PercentageOverlap'
import ItemLandscapeSustainability from '../landscapeSustainability/ItemLandscapeSustainability'
import ItemOverlappingSurface from '../landscapeSustainability/ItemOverlappingSurface'
import { roundNumber } from '../../utils/number'
import TitleIcon from '../../screens/v1/CropStory/components/TitleIcon'
import { round } from 'lodash'
import { SustainabilityIcon } from '@common/components'

const SectionLandscapeSustainability = ({
  loading = false,
  totals = {},
  datasets = [],
  otherNormative = [],
  crop,
  areaUnit = '',
}) => {
  const { t } = useContext(LanguageContext)
  const {
    percentageOfTheTotalInterceptionArea = 0,
    remainingPercentageOfTheTotalInterceptionArea = 0,
    totalAreaOfIntersections = 0,
    availableArea = crop.surface,
  } = totals

  const percentInterception = roundNumber(percentageOfTheTotalInterceptionArea)
  const percentNotInterception = datasets.length
    ? roundNumber(remainingPercentageOfTheTotalInterceptionArea)
    : 100

  if (loading) {
    return (
      <View style={styles.containerbg}>
        <Text>
          {t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_1}
        </Text>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <TitleIcon
        text={t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_2}
        Icon={() => (
          <SustainabilityIcon name={'LANDSCAPE-SUSTAINABILITY'} size={24} />
        )}
      />
      <View style={styles.generalOverlappingPercent}>
        <PercentageOverlap
          percentage={`${percentNotInterception}%`}
          text={t(
            'COMPONENTS.BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_3',
            {
              value: roundNumber(round(availableArea, 2)),
              areaUnit: areaUnit,
            }
          )}
          overlap
        />
        <PercentageOverlap
          percentage={`${percentInterception}%`}
          text={t(
            'COMPONENTS.BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_4',
            {
              value: roundNumber(round(totalAreaOfIntersections, 2)),
              areaUnit: areaUnit,
            }
          )}
        />
      </View>

      <Accordion
        style={styles.accordion}
        title={t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_5}
      >
        {otherNormative.map((normative, index) => {
          const { nameSchema, totals = {} } = normative
          const { remainingPercentageOfTheTotalInterceptionArea = 0 } = totals
          return (
            <ItemLandscapeSustainability
              key={`dataSet-${index}`}
              name={nameSchema}
              value={`${roundNumber(
                round(remainingPercentageOfTheTotalInterceptionArea, 2)
              )}% ${areaUnit}`}
              horizontal
            />
          )
        })}
      </Accordion>

      <Accordion
        style={styles.accordion}
        title={t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_7}
      >
        {datasets.map((dataset, index) => {
          const {
            nameShow,
            percent,
            solappingArea = 0,
            intersectArea = 0,
            categories,
          } = dataset
          return (
            <ItemLandscapeSustainability
              key={`detail-${index}`}
              name={nameShow}
              value={t(
                'COMPONENTS.BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_9',
                {
                  value: roundNumber(percent ? percent : solappingArea),
                }
              )}
            >
              {categories ? (
                categories.map((category, idx) => {
                  const { solappingArea = 0, color, name } = category
                  return (
                    <ItemOverlappingSurface
                      key={`category${index}${idx}`}
                      name={name}
                      value={roundNumber(round(solappingArea, 2))}
                      color={color}
                      areaUnit={areaUnit}
                    />
                  )
                })
              ) : (
                <ItemOverlappingSurface
                  value={roundNumber(round(intersectArea, 2))}
                  areaUnit={areaUnit}
                />
              )}
            </ItemLandscapeSustainability>
          )
        })}

        <View style={styles.textClarificationContainer}>
          <Text style={styles.textClarification}>
            {t('COMPONENTS').BINNACLE.SECTION_LANDSCAPE_SUSTAINABILITY.TEXT_18}
          </Text>
        </View>
      </Accordion>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  containerbg: {
    backgroundColor: '#F8F8F8',
    padding: 16,
    marginBottom: 4,
  },
  generalOverlappingPercent: {
    backgroundColor: '#F8F8F8',
    paddingTop: 16,
  },
  accordion: {
    padding: 16,
    backgroundColor: '#F8F8F8',
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    borderBottomWidth: 1,
  },
  textClarificationContainer: {
    backgroundColor: '#F8F8F8',
    padding: 16,
    marginBottom: 4,
  },
  textClarification: {
    fontSize: 14,
    color: '#6E6E6E',
  },
})

SectionLandscapeSustainability.propTypes = {
  loading: PropTypes.bool,
  normative: PropTypes.string,
}

export default SectionLandscapeSustainability
