const urls = {
  'http://localhost:3000': 'http://localhost:4040',
  'https://aws-apidev.ucrop.it': 'https://draw-fields-dev.ucrop.it',
  'https://aws-apiqa.ucrop.it': 'https://draw-fields-qa.ucrop.it',
  'https://aws-apipreprod.ucrop.it': 'https://draw-fields-preprod.ucrop.it',
  'https://api.ucrop.it': 'https://draw-fields.ucrop.it',
  'https://aws-apidemo.ucrop.it': 'https://draw-fields-preprod.ucrop.it',
}

const getDrawFieldUrl = (apiUrl) => urls[apiUrl]

export default getDrawFieldUrl
