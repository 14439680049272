import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

const TextTitle = ({ text, color = '#000', extraStyle = {} }) => {
  const styleColor = { color }
  return (
    <View style={[styles.contentTitle, extraStyle]}>
      <Text style={[styles.textTitle, styleColor]}>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  contentTitle: {
    marginTop: 10,
    marginLeft: 17,
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
  },
})

TextTitle.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  extraStyle: PropTypes.object,
}

export default TextTitle
