import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

export const EditableTitle = ({ title, onPress, showIcon = false }) => {
  return (
    <View style={[styles.container, styles.flexDirectionRow]}>
      <View style={styles.titleContent}>
        <Text style={styles.title} numberOfLines={1} ellipsizeMode='tail'>
          {title}
        </Text>
      </View>

      {showIcon && (
        <CommonIcon
          name={'EDIT'}
          size={20}
          color={blackHighEmphasis}
          style={styles.icon}
          onPress={onPress}
        />
      )}
    </View>
  )
}

EditableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  showIcon: PropTypes.bool,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 15,
    marginHorizontal: 20.5,
    paddingBottom: 9,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  titleContent: {
    flex: 1,
    marginLeft: 11.5,
  },
  title: {
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 28.13,
    letterSpacing: 0.25,
    color: blackHighEmphasis,
  },
  icon: {
    marginRight: 18.5,
    color: blackHighEmphasis,
  },
})
