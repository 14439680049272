import * as React from 'react'
import { Dimensions, Text, StyleSheet } from 'react-native'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import CollaboratorRequestList from '@components/companies/CollaboratorRequestList'
import FormUpdate from '@components/companies/FormUpdate'
import { AuthContext } from '@contextState/auth'
import { LanguageContext } from '@contextState/language'

const FirstRoute = () => <FormUpdate />

const SecondRoute = () => <CollaboratorRequestList />

const initialLayout = { width: Dimensions.get('window').width }

function CompanyProfileScreen() {
  const { t } = React.useContext(LanguageContext)
  const { selectedProfile } = React.useContext(AuthContext)
  const [index, setIndex] = React.useState(0)
  const [routes, setRoutes] = React.useState([
    { key: 'first', title: t('VIEWS').COMPANY_PROFILE.TEXT_1 },
    { key: 'second', title: t('VIEWS').COMPANY_PROFILE.TEXT_2 },
  ])

  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  })

  React.useEffect(() => {
    if (!selectedProfile.isAdmin) {
      setRoutes([{ key: 'first', title: t('VIEWS').COMPANY_PROFILE.TEXT_1 }])
    }
  }, [selectedProfile])

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      renderTabBar={(props) => (
        <TabBar
          {...props}
          style={{ backgroundColor: 'white' }}
          tabStyle={{ padding: 0, margin: 0 }}
          renderLabel={({ route }) => (
            <Text style={styles.textStyle}>{route.title}</Text>
          )}
          indicatorStyle={styles.indicatorStyle}
        />
      )}
      initialLayout={initialLayout}
    />
  )
}

const styles = StyleSheet.create({
  indicatorStyle: {
    backgroundColor: '#4CAF50',
    height: 2,
  },
  textStyle: {
    color: '#4CAF50',
    fontWeight: '500',
    fontSize: 13,
    padding: 0,
    margin: 0,
  },
})

export default CompanyProfileScreen
