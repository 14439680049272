/*
  Index reducer, combine all app reducer.
 */
import { combineReducers } from 'redux'

import collaborators from './collaborators'
import draftCrop from './draftCrop'
import verificationTypes from './verificationTypes'
import lots from './lots'
import selectFileBinary from './selectFileBinary'
import crops from './crops'
import collaboratorsCompany from './collaboratorsCompany'
import auth from './auth'
import farm from './farm'
import activities from './activities'

export default combineReducers({
  collaborators,
  draftCrop,
  verificationTypes,
  lots,
  selectFileBinary,
  crops,
  collaboratorsCompany,
  auth,
  farm,
  activities,
})
