import React, { useEffect, useRef } from 'react'
import { Animated } from 'react-native'
import PropTypes from 'prop-types'

const ViewAnimatedBackground = ({
  children,
  firstColor = '#E8F5E9',
  secondColor = '#fff',
  duration = 6000,
  extraStyle = {},
}) => {
  const colorAnim = useRef(new Animated.Value(0)).current
  const background = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [firstColor, secondColor],
  })
  useEffect(() => {
    Animated.timing(colorAnim, {
      toValue: 1,
      duration,
    }).start()
  }, [colorAnim])

  return (
    <Animated.View
      style={{
        backgroundColor: background,
        ...extraStyle,
      }}
    >
      {children}
    </Animated.View>
  )
}

ViewAnimatedBackground.propTypes = {
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
  duration: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  extraStyle: PropTypes.object,
}

export default ViewAnimatedBackground
