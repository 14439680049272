export const validateVerification = async ({
  isRealized,
  doRequest,
  dataToSend,
  values,
  pinToken,
}) => {
  const formData = new FormData()

  formData.append('data', JSON.stringify(dataToSend))

  values.files.forEach((element) => formData.append('evidences', element))

  formData.append('verificationFile', values.verificationFile?.file)

  if (!values.activityId && !isRealized) {
    await doRequest({
      headers: {
        'validate-token-pin': `Bearer ${pinToken}`,
      },
      method: 'POST',
      url: 'activities/verifications',
      data: formData,
      version: 'v2',
    })
  }

  if (values.activityId) {
    await doRequest({
      headers: {
        'validate-token-pin': `Bearer ${pinToken}`,
      },
      method: 'PUT',
      url: `activities/${values.activityId}/crops/${values.cropId}/signed`,
    })
  }

  return true
}
