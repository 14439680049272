import React from 'react'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { primary500, grayDA, blackHighEmphasis } from '@styles/palette'

const InputCheckbox = ({
  checked,
  colorCheckBox = primary500,
  colorDisabled = grayDA,
  colorUnchecked = blackHighEmphasis,
  disabled,
  onPress,
  size = 24,
}) => {
  return (
    <CommonIcon
      name={checked ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'}
      size={size}
      color={
        disabled ? colorDisabled : checked ? colorCheckBox : colorUnchecked
      }
      onPress={(value) => {
        if (!disabled) {
          onPress(value)
        }
      }}
    />
  )
}

InputCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  colorCheckBox: PropTypes.string,
  colorDisabled: PropTypes.string,
  disabled: PropTypes.bool,
}

export default InputCheckbox
