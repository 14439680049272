import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Menu } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'

export const UnassociatedLicenseMenu = ({ onPress }) => {
  const { t } = useContext(LanguageContext)

  const handleCropMenuOptions = async () => {
    if (onPress) {
      onPress()
    }

    setVisibleMenu(false)
  }

  const [visibleMenu, setVisibleMenu] = useState(false)
  return (
    <Menu
      onDismiss={() => setVisibleMenu(false)}
      visible={visibleMenu}
      anchor={
        <CommonIcon
          name={'DOTS-VERTICAL'}
          size={20}
          onPress={() => setVisibleMenu(true)}
        />
      }
    >
      <Menu.Item
        key={0}
        title={t(
          'COMMON.COMPONENTS.UNASSOCIATE_LICENSE_MENU.UNASSOCIATE_LICENSE'
        )}
        onPress={() => handleCropMenuOptions()}
      />
    </Menu>
  )
}

UnassociatedLicenseMenu.propTypes = {
  onPress: PropTypes.func.isRequired,
}
