import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Button, List, Text, Title } from 'react-native-paper'
import { Field, Formik } from 'formik'

import { AuthContext } from '@contextState/auth'
import { CommonContext } from '@contextState/common'

import useNetwork from '@utils/network'
import ButtonCustom from '../common/ButtonCustom'
import CustomDatePicker from '../common/CustomDatePicker'
import FilesList from '../common/FilesList'
import Input from '../common/Input'
import ModalCustom from '../common/ModalCustom'
import Select from '../common/Select'
import SnackbarCustom from '../common/SnackbarCustom'
import UploadInput from '../common/UploadInput'
import { LanguageContext } from '@contextState/language'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import { identifierName } from '@utils/countries'
import { getObjectId } from '../../utils'
import { CommonIcon } from '@modules/common/components'

function FormUpdate({ data }) {
  const { config, fetchUser, selectedProfile } = useContext(AuthContext)
  const { countries, allCountryData } = useContext(CommonContext)
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const [files, setFiles] = useState([])
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [visible, setVisible] = useState(false)
  const [initialValues, setInitialValues] = useState({
    identifier: '',
    name: '',
    address: '',
    addressFloor: '',
  })
  const [countriesData, setCountriesData] = useState([])

  useEffect(() => {
    fetchCompany()
  }, [])

  /**
   * UPDATE COUNTRY STATE
   */
  useEffect(() => {
    if (!countries.length) {
      return
    }

    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  const fetchCompany = useCallback(async () => {
    const response = await doRequest({
      method: 'GET',
      url: `companies/${config.companySelected._id}`,
    })

    const {
      identifier,
      name,
      address,
      addressFloor,
      country,
      typePerson,
      ...rest
    } = response.data

    setFiles(
      rest.files.map((file) => ({
        ...file,
        persisted: true,
      }))
    )
    setInitialValues({
      identifier,
      name,
      address,
      addressFloor,
      typePerson,
      country: getObjectId(country),
    })
  }, [])

  function handleFile({ file }) {
    setSelectedFile(file)
  }

  function handleFileSubmit(values) {
    setFiles([...files, { ...values, file: selectedFile }])
    setVisible(false)
    setSelectedFile(null)
  }

  function handleFileDelete(index) {
    if (!files[index].persisted) {
      setFiles(files.filter((el, key) => key !== index))
    }
  }

  async function handleSubmitForm(values, { setSubmitting }) {
    const formData = new FormData()
    const newData = JSON.stringify({
      ...values,
      identifier: data?.identifier || values.identifier,
      evidences: files
        .filter((el) => !el.persisted)
        .map(({ name, description, date }) => ({
          name,
          description,
          date,
        })),
    })

    formData.append('data', newData)

    const dataFiles = files.filter((el) => !el.persisted)

    dataFiles.forEach((el) => {
      formData.append('files', el.file)
    })

    try {
      await doRequest({
        method: 'PUT',
        url: `companies/${config.companySelected._id}`,
        data: formData,
      })

      await fetchUser()

      setSubmitting(false)

      showSnack(t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_1)
    } catch (err) {
      console.warn(err)
      showSnack(t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_2)
    }
  }

  function showSnack(msg) {
    setSnackBarMessage(msg)
    setVisibleSnackbar(true)
  }

  return (
    <>
      <ModalCustom visible={visible} hideModal={() => setVisible(false)}>
        <Title style={styles.modalTitle}>
          {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_3}
        </Title>
        <Formik
          initialValues={{ name: '', description: '', date: new Date() }}
          onSubmit={handleFileSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <Field
                component={Input}
                name='name'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_1.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_1.LABEL,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_1.VALIDATE
                }
              />
              <Field
                component={Input}
                name='description'
                inputProps={{
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_2.PLACEHOLDER,
                  label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_2.LABEL,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_2.VALIDATE
                }
              />
              <Field
                component={CustomDatePicker}
                name='date'
                placeholder={
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_3.PLACEHOLDER
                }
                label={t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_3.LABEL}
              />
              <UploadInput
                cancelOnPress={() => setVisible(false)}
                handleFile={handleFile}
                accept={`.doc,.docx,.pdf,${ALLOW_IMAGES_FORMAT}`}
              >
                <View
                  style={{
                    borderRadius: 2,
                    padding: 4,
                    fontWeight: '400',
                  }}
                >
                  <Text
                    style={{
                      color: 'green',
                      textAlign: 'center',
                      padding: 4,
                    }}
                  >
                    {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_4}
                  </Text>
                </View>
              </UploadInput>
              {selectedFile && (
                <List.Item
                  title={selectedFile.name}
                  left={() => <CommonIcon name='FILETYPE-LEGAL' size={20} />}
                />
              )}
              <View style={styles.actions}>
                <Button onPress={() => setVisible(false)}>
                  {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_5}
                </Button>
                <Button
                  disabled={!selectedFile}
                  onPress={handleSubmit}
                  labelStyle={{ color: 'white' }}
                  mode='contained'
                >
                  {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_6}
                </Button>
              </View>
            </>
          )}
        </Formik>
      </ModalCustom>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <View style={{ flex: 1 }}>
            <ScrollView style={styles.container}>
              <Field
                component={Select}
                name='country'
                label={t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_4.LABEL}
                options={countriesData}
                disabled
                withOutIcon
              />
              {data?.identifier ? (
                <View style={styles.headerText}>
                  <Title>
                    {identifierName(
                      allCountryData(values?.country)?.alpha3Code,
                      t
                    )}
                  </Title>
                  <Text>{data.identifier}</Text>
                </View>
              ) : (
                <Field
                  component={Input}
                  disabled
                  name='identifier'
                  inputProps={{
                    label: identifierName(
                      allCountryData(values?.country)?.alpha3Code,
                      t
                    ),
                    disabled: true,
                  }}
                />
              )}
              <Field
                component={Input}
                name='name'
                inputProps={{
                  label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_6.LABEL,
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_6.PLACEHOLDER,
                  disabled: true,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_6.VALIDATE
                }
              />

              <Field
                component={Select}
                name='typePerson'
                label={t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.LABEL}
                options={[
                  {
                    value: 'PHYSICAL_PERSON',
                    label:
                      t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.OPTION_1,
                  },
                  {
                    value: 'LEGAL_PERSON',
                    label:
                      t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.OPTION_2,
                  },
                ]}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.VALIDATE
                }
              />

              <Field
                component={Input}
                name='address'
                inputProps={{
                  label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_8.LABEL,
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_8.PLACEHOLDER,
                }}
                validate={(value) =>
                  !value &&
                  t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_8.VALIDATE
                }
              />

              <Field
                component={Input}
                name='addressFloor'
                inputProps={{
                  label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_9.LABEL,
                  placeholder:
                    t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_9.PLACEHOLDER,
                }}
              />
              {selectedProfile.isAdmin && (
                <Button onPress={() => setVisible(true)}>
                  {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_8}
                </Button>
              )}
              <FilesList
                disabled={!selectedProfile.isAdmin}
                data={files}
                files={files}
                onDelete={handleFileDelete}
              />
            </ScrollView>

            {selectedProfile.isAdmin && (
              <ButtonCustom
                isLoading={isSubmitting}
                disabled={files.length === 0}
                onPress={handleSubmit}
                styles={styles.button}
              >
                {t('COMPONENTS').COMPANIES.FORM_UPDATE.TEXT_9}
              </ButtonCustom>
            )}
          </View>
        )}
      </Formik>
      <SnackbarCustom
        visible={visibleSnackbar}
        onToggle={() => {
          setSnackBarMessage('')
          setVisibleSnackbar(false)
        }}
      >
        {snackBarMessage}
      </SnackbarCustom>
    </>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  container: {
    padding: 16,
    flex: 1,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  button: {
    margin: 16,
  },
})

export default FormUpdate
