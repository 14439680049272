import React from 'react'
import PropTypes from 'prop-types'
import { ActivityCardDate, ActivityProgressBar, FarmList, Info } from '..'
import activityTypes from '@constants/activityTypes'
import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

export const ActivityMonitoring = ({
  activityType,
  farms,
  dateHarvest,
  dateObservation,
  percent,
  areaUnitVolume,
  pay,
  unitType,
  unitTypeKey,
  volume,
}) => {
  if (activityType !== activityTypes.ACT_MONITORING.key) return null

  const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(farms)

  return (
    <>
      <ActivityCardDate
        activityType={activityType}
        dateHarvest={dateHarvest}
        dateObservation={dateObservation}
      />

      <ActivityProgressBar percent={percent} />

      <Info
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
      />

      <FarmList farms={farmAndLotsWithSurfaceRealized} realized={true} />
    </>
  )
}

ActivityMonitoring.prototype = {
  activityType: PropTypes.string.isRequired,
  farms: PropTypes.array.isRequired,
  areaUnitVolume: PropTypes.string.isRequired,
  dateHarvest: PropTypes.string.isRequired,
  dateObservation: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  pay: PropTypes.number.isRequired,
  unitType: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
}
