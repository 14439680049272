import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Button, Card, Chip } from 'react-native-paper'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'

function CollaboratorRequestList() {
  const { showActionSheetWithOptions } = useActionSheet()
  const { doRequest, loading } = useNetwork()
  const { config } = useContext(AuthContext)
  const [requests, setRequests] = useState([])
  const { t } = useContext(LanguageContext)
  const fetchCollaboratorRequests = useCallback(async () => {
    const request = await doRequest({
      method: 'GET',
      url: `collaborators?company=${config.companySelected._id}`,
    })

    setRequests(request.data)
  }, [])

  async function handleState(id, type) {
    await doRequest({
      method: 'PUT',
      url: `collaborators/${id}`,
      data: { status: 'accepted', isAdmin: type === 'ADMIN' },
    })

    await fetchCollaboratorRequests()
  }

  function _onOpenActionSheet(id) {
    const options = [
      t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST.TEXT_1,
      t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST.TEXT_2,
      t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST.TEXT_3,
    ]

    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          handleState(id, 'COLABORATOR')
        } else if (buttonIndex === 1) {
          handleState(id, 'ADMIN')
        }
      }
    )
  }

  useEffect(() => {
    fetchCollaboratorRequests()
  }, [config])

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {requests.map((request, key) => (
        <Card key={key} style={styles.card}>
          <Card.Title
            right={
              request.status === 'pending'
                ? () => (
                    <Button
                      style={styles.button}
                      disabled={loading}
                      onPress={() => _onOpenActionSheet(request._id)}
                    >
                      {
                        t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST
                          .TEXT_4
                      }
                    </Button>
                  )
                : null
            }
            style={styles.title}
            title={
              request.user.firstName
                ? `${request.user.firstName} ${request.user.lastName}`
                : request.user.email
            }
            subtitle={request.user.email}
          />
          <Card.Content>
            <View style={styles.chipContainer}>
              <Chip
                textStyle={styles.chipStyleText}
                style={[
                  styles.chipStyle,
                  {
                    backgroundColor:
                      request.status === 'accepted'
                        ? 'rgb(76, 175, 80)'
                        : '#FF8A65',
                  },
                ]}
              >
                {request.status === 'accepted'
                  ? t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST.TEXT_5
                  : t('COMPONENTS').COMPANIES.COLLABORATOR_REQUEST_LIST.TEXT_6}
              </Chip>
            </View>
          </Card.Content>
        </Card>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginBottom: 25,
  },
  card: {
    marginBottom: 16,
  },
  button: {
    fontSize: 15,
    marginRight: 4,
  },
  chipContainer: {
    alignSelf: 'flex-start',
  },
  chipStyle: {
    backgroundColor: '#FF8A65',
    color: 'white',
    fontSize: 12,
  },
  chipStyleText: {
    color: 'white',
    textTransform: 'capitalize',
  },
  title: {
    paddingTop: 8,
    alignItems: 'flex-start',
  },
})

export default CollaboratorRequestList
