import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FlatList, Platform, Text, StyleSheet } from 'react-native'

import { LanguageContext } from '@contextState/language'

import { FarmItem } from '../'
import { blackHighEmphasis } from '@styles/palette'

export const FarmList = ({ farms, contentStyles = {}, realized = false }) => {
  const { t } = useContext(LanguageContext)

  const farmRenderItem = ({ item: farm }) => (
    <FarmItem
      name={farm.name}
      surface={farm.surface}
      areaUnit={farm.areaUnit}
      fields={farm.lots}
      realized={realized}
    />
  )

  return (
    <FlatList
      ListHeaderComponent={
        <Text style={styles.farmTitle}>
          {t('VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.FARM_LIST.FARMS')}
        </Text>
      }
      data={farms}
      renderItem={farmRenderItem}
      keyExtractor={(_, index) => `FARM_${index}`}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      contentContainerStyle={contentStyles}
    />
  )
}

const styles = StyleSheet.create({
  farmTitle: {
    color: blackHighEmphasis,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 16,
    marginTop: 12,
    marginBottom: 5,
    textTransform: 'uppercase',
  },
})

FarmList.prototype = {
  farms: PropTypes.array.isRequired,
  contentStyles: PropTypes.object,
  realized: PropTypes.bool,
}
