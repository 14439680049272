import React, { useState } from 'react'
import { View, Text, StyleSheet, Animated } from 'react-native'
import { Menu } from 'react-native-paper'
import moment from 'moment'
import PropTypes from 'prop-types'

import AccordionItem from '../v2/AccordionItem'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { blackDisabled, blackMediumEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'

const ObservationItem = ({
  item,
  index,
  deleteObservation,
  copyObservation,
  showMenu = true,
}) => {
  const { t } = React.useContext(LanguageContext)
  const { createdAt, text, createdBy } = item
  const [openMenu, setOpenMenu] = useState({})
  const handleOpenMenu = (key) => {
    setOpenMenu({ [key]: !openMenu[key] })
  }
  const ContentHeader = ({ arrowAngle, onPress }) => {
    return (
      <TouchableOpacity style={styles.contentHeader} onPress={onPress}>
        <View>
          <CommonIcon name={'OBSERVATION'} size={32} />
        </View>
        <View style={styles.infoHeader}>
          <View>
            <Text style={styles.textBold}>
              {t('COMPONENTS.COMMON.OBSERVATION_ITEM.TEXT_1')}
            </Text>
            <Text style={styles.textBold}>{`${moment(createdAt).format(
              'DD/MM/YYYY HH.mm'
            )} hs.`}</Text>
            <Text
              style={styles.subTextGray}
            >{`${createdBy.firstName} ${createdBy.lastName}`}</Text>
          </View>
          <View>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <CommonIcon name={'CHEVRON-DOWN'} size={25} />
            </Animated.View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
  return (
    <View>
      <AccordionItem ContentHeader={ContentHeader}>
        <View style={styles.borderBottom}>
          <View>
            <Text style={styles.subTextGray}>
              {t('COMPONENTS.COMMON.OBSERVATION_ITEM.TEXT_2')}
            </Text>
            <Text style={styles.subTextInfo}>{text}</Text>
          </View>
          {showMenu && (
            <View style={styles.iconMenu}>
              <Menu
                onDismiss={handleOpenMenu}
                visible={Boolean(openMenu[index])}
                anchor={
                  <CommonIcon
                    name={'DOTS-VERTICAL'}
                    size={20}
                    onPress={() => handleOpenMenu(index)}
                  />
                }
              >
                <Menu.Item
                  key={0}
                  onPress={() => {
                    handleOpenMenu(index)
                    copyObservation(text)
                  }}
                  title={t('COMPONENTS.COMMON.OBSERVATION_ITEM.TEXT_3')}
                  icon={(props) => (
                    <CommonIcon {...props} name={'COPY'} size={20} />
                  )}
                />
                <Menu.Item
                  key={1}
                  onPress={() => {
                    handleOpenMenu(index)
                    deleteObservation(index)
                  }}
                  title={t('COMPONENTS.COMMON.OBSERVATION_ITEM.TEXT_4')}
                  icon={(props) => (
                    <CommonIcon {...props} name={'DELETE'} size={20} />
                  )}
                />
              </Menu>
            </View>
          )}
        </View>
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
    paddingLeft: 10,
    paddingRight: 15,
  },
  infoHeader: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  textBold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subTextGray: {
    color: blackMediumEmphasis,
  },
  subTextInfo: {
    color: blackDisabled,
    fontSize: 12,
  },
  iconMenu: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})

ObservationItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  deleteObservation: PropTypes.func.isRequired,
  copyObservation: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
}

export default ObservationItem
