import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import InputText from '@components/inputs/InputText'

export const FormAuth = ({ formik, t }) => {
  const { setFieldTouched, setFieldValue, values, touched, errors } = formik
  return (
    <View>
      <InputText
        id='email'
        onChangeText={(value) => setFieldValue('email', value)}
        value={values.email}
        placeholder={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_3.PLACEHOLDER')}`}
        label={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_3.LABEL')}`}
        editable={true}
        disabled={false}
        onBlur={() => setFieldTouched('email')}
        touched={touched.email}
        keyboardType={'email-address'}
        error={errors.email}
      />
    </View>
  )
}

FormAuth.propTypes = {
  formik: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}
