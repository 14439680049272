import React, { useContext, useState, useEffect, createRef } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Platform,
  TouchableOpacity,
  Text,
} from 'react-native'
import { useSelector } from 'react-redux'
import { Subheading, Snackbar } from 'react-native-paper'
import ActionSheet from 'react-native-actions-sheet'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { ConnectionContext } from '@contextState/connection'
import { ITS_CAM, ROL, ROL_COLLABORATOR_DISABLED } from '@constants/roles'
import { cropEmpty } from '@constants/images'
import userTypes from '@constants/userTypes'
import CollaboratorCard from '@components/collaborators/CollaboratorCard'
import ButtonCustom from '@components/common/ButtonCustom'
import CollaboratorCardDetails from '@components/collaborators/CollaboratorCardDetails'
import useNetwork from '@utils/network'
import { ModalDefault, VerifyPin } from '@modules/common/components'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'
import { formatDateMoment } from '@utils/date'

const CollaboratorsDelegateSignaturesList = ({ navigation, route }) => {
  const { t, i18n } = useContext(LanguageContext)
  const { user } = useContext(AuthContext)
  const { isConnected } = useContext(ConnectionContext)

  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const [currentCollaborator, setCurrentCollaborator] = useState({})
  const [delegateCollaborator, setDelegateCollaborator] = useState({})
  const [selectedKey, setSelectedKey] = useState(null)
  const [collaborators, setCollaborators] = useState([])
  const [isModalConfirmDelegate, setIsModalConfirmDelegate] = useState(false)
  const [selectedCollaborator, setSelectedCollaborator] = useState('')
  const [replacementCollaborator, setReplacementCollaborator] = useState({})
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [isDelegatingSignature, setIsDelegatingSignature] = useState(false)
  const [successDelegation, setSuccessDelegation] = useState(false)

  const { doRequest } = useNetwork()

  const confirmDelegateRef = createRef()

  const collaboratorsRedux = useSelector(
    (state) => state.collaborators.collaborators
  )

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    setReplacementCollaborator(
      collaborators.find(
        (collaborator) => collaborator.memberId === selectedCollaborator
      )
    )
  }, [selectedCollaborator])

  const loadData = () => {
    const { collaboratorsIds } = route.params

    let currentUser = {}
    let toDelegate = {}
    let collaborators = collaboratorsRedux

    currentUser = collaborators.find(
      (member) => member.userId?._id === user._id
    )
    toDelegate = collaborators.find(
      (member) => member.userId?._id === collaboratorsIds
    )
    collaborators = collaborators.filter(
      (member) => member.userId?._id !== collaboratorsIds
    )

    setDelegateCollaborator(toDelegate)
    setCurrentCollaborator(currentUser)
    setCollaborators(collaborators)
  }

  const handlePressedItem = (key) => {
    setSelectedKey(key)
    setSelectedCollaborator(key)
  }

  const setDisabled = (collaborator) =>
    currentCollaborator?.rol === collaborator?.rol
      ? false
      : ROL_COLLABORATOR_DISABLED[currentCollaborator?.rol][collaborator?.rol]

  const getTitleMenu = (title) =>
    title?.firstName ? `${title.firstName} ${title.lastName}` : `${title.email}`

  const setDisabledItem = (rol) => {
    if (ITS_CAM[delegateCollaborator.rol] && !ITS_CAM[rol]) {
      return true
    }
    if (
      (delegateCollaborator.rol === userTypes.PRODUCER.key ||
        delegateCollaborator.rol === userTypes.PRODUCER_ADVISER.key ||
        delegateCollaborator.rol ===
          userTypes.PRODUCER_ADVISER_ENCOURAGED.key) &&
      ITS_CAM[rol]
    ) {
      return true
    }
    return false
  }

  const openConfirmDelegate = () => {
    Platform.OS !== 'web'
      ? confirmDelegateRef.current?.show()
      : setIsModalConfirmDelegate(true)
  }

  const onSubmitDelegateSigner = async () => {
    setIsDelegatingSignature(true)
    closeModal()

    if (isConnected) {
      try {
        const dateReassign = formatDateMoment(
          new Date(),
          'YYYY-MM-DD HH:mm:ss',
          i18n.locale,
          true
        )

        await doRequest({
          method: 'PUT',
          url: `collaborators/delegate-signs/current/${delegateCollaborator.memberId}/new/${selectedCollaborator}`,
          data: {
            dateReassign,
          },
        })
      } catch (error) {
        console.warn(error)

        setSuccessDelegation(false)
        setVisibleSnackbar(true)
        setIsDelegatingSignature(false)

        return
      }
    }

    setSuccessDelegation(true)
    setVisibleSnackbar(true)
    setIsDelegatingSignature(false)

    setTimeout(() => navigation.goBack(), 3000)
  }

  const openModalPin = async () => {
    Platform.OS !== 'web'
      ? confirmDelegateRef.current?.hide()
      : setIsModalConfirmDelegate(false)

    if (user?.config?.hasPin) {
      if (verifyExpirationPin()) {
        setTimeout(toggleModal, 300)
      } else {
        const { validate } = await geTokenAuthPin()
        if (!validate) {
          setTimeout(toggleModal, 300)
          return
        }
        onSubmitDelegateSigner()
      }
    } else {
      navigation.navigate('CreatePin', {
        callbackPin,
      })
    }
  }

  const callbackPin = () => {
    onSubmitDelegateSigner()
  }

  const ContentModals = ({ onClose, notfound = false, modal = false }) => {
    return (
      <View
        style={notfound ? styles.containerModalNotFound : styles.containerModal}
      >
        <View
          style={[
            styles.containerHeaderModalGeneral,
            modal ? styles.containerHeaderIsModal : styles.containerHeaderModal,
          ]}
        >
          <TouchableOpacity onPress={onClose}>
            <CommonIcon name='X' size={20} style={styles.buttonClose} />
          </TouchableOpacity>
          <Text style={styles.titleModal}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_28}
          </Text>
        </View>
        <View style={modal ? styles.contentIsModal : styles.contentModal}>
          <CollaboratorCardDetails
            notfound={notfound}
            collaborator={delegateCollaborator}
            companyName={delegateCollaborator.company.name}
            collaboratorType={delegateCollaborator.rol}
            title={getTitleMenu(delegateCollaborator)}
            subtitle={ROL[delegateCollaborator.rol](t) || ROL['DEFAULT'](t)}
            status={!!delegateCollaborator.firstName}
            pendingValidation={0}
            delegating
          />
          <Text style={styles.warningReplacement}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_23}
          </Text>
          <CollaboratorCardDetails
            style={styles.delegateCollaborator}
            notfound={notfound}
            collaborator={replacementCollaborator}
            companyName={replacementCollaborator.company.name}
            collaboratorType={replacementCollaborator.rol}
            title={getTitleMenu(replacementCollaborator)}
            subtitle={ROL[replacementCollaborator.rol](t) || ROL['DEFAULT'](t)}
            status={!!replacementCollaborator.firstName}
            pendingValidation={0}
            delegating
          />
        </View>
        <View style={styles.containerButtonBottom}>
          <ButtonCustom
            disabled={!selectedKey}
            styles={[!selectedKey ? styles.buttonDisabled : styles.button]}
            labelStyle={[
              styles.textButton,
              !selectedKey ? styles.textButtonDisabled : {},
            ]}
            onPress={openModalPin}
          >
            {t('VIEWS').CROP_COLLABORATOR.TEXT_28}
          </ButtonCustom>
        </View>
      </View>
    )
  }

  const RadioCheck = ({ id }) => (
    <View style={styles.containerRadioCheck}>
      {selectedKey === id ? (
        <CommonIcon name='SELECTED-FILLED' size={20} color='#2E7D32' />
      ) : (
        <CommonIcon name='CIRCLE' size={20} color='#DADADA' />
      )}
    </View>
  )

  return (
    <View style={styles.flex}>
      {!collaborators.length ? (
        <View style={styles.container}>
          <Image source={cropEmpty} style={styles.emptyState} />
          <Subheading style={styles.emptyStateText}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_11}
          </Subheading>
        </View>
      ) : (
        <ScrollView>
          <View style={styles.scrollContainer}>
            <CollaboratorCard
              style={styles.delegateCollaborator}
              collaborator={delegateCollaborator}
              companyName={delegateCollaborator.company.name}
              collaboratorType={delegateCollaborator.rol}
              title={getTitleMenu(delegateCollaborator.userId)}
              subtitle={ROL[delegateCollaborator.rol](t) || ROL['DEFAULT'](t)}
              status={!!delegateCollaborator.firstName}
              pendingValidation={delegateCollaborator.pendingValidations.length}
            />
            {collaborators.map((collaborator, key) => {
              if (
                !ROL_COLLABORATOR_DISABLED[currentCollaborator.rol][
                  collaborator.rol
                ]
              ) {
                return (
                  <CollaboratorCard
                    key={key}
                    collaborator={collaborator}
                    onPress={() => handlePressedItem(collaborator.memberId)}
                    disabled={
                      setDisabled(collaborator) ||
                      setDisabledItem(collaborator.rol)
                    }
                    companyName={collaborator.company.name}
                    collaboratorType={collaborator.rol}
                    title={`${getTitleMenu(collaborator.userId)} ${
                      collaborator.memberId == currentCollaborator.memberId
                        ? '(' + t('VIEWS').CROP_COLLABORATOR.TEXT_21 + ')'
                        : ''
                    }`}
                    subtitle={ROL[collaborator.rol](t) || ROL['DEFAULT'](t)}
                    status={!!collaborator.firstName}
                    pendingValidation={collaborator.pendingValidations.length}
                    right={<RadioCheck id={collaborator.memberId} />}
                  />
                )
              }
            })}
          </View>
        </ScrollView>
      )}

      <View style={styles.containerButtonBottom}>
        <ButtonCustom
          disabled={!selectedKey || isDelegatingSignature}
          styles={[
            !selectedKey || isDelegatingSignature
              ? styles.buttonDisabled
              : styles.button,
          ]}
          isLoading={isDelegatingSignature}
          labelStyle={[
            styles.textButton,
            !selectedKey ? styles.textButtonDisabled : {},
          ]}
          onPress={openConfirmDelegate}
        >
          {t('VIEWS').CROP_COLLABORATOR.TEXT_20}
        </ButtonCustom>
      </View>

      {Platform.OS !== 'web' && (
        <ActionSheet
          ref={confirmDelegateRef}
          containerStyle={styles.actionStyles}
        >
          <View style={styles.containerActionSheet}>
            <ContentModals onClose={() => confirmDelegateRef.current?.hide()} />
          </View>
        </ActionSheet>
      )}

      <ModalDefault
        isModalVisible={isModalConfirmDelegate}
        closeModal={() => setIsModalConfirmDelegate(false)}
      >
        <ContentModals onClose={() => setIsModalConfirmDelegate(false)} />
      </ModalDefault>

      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={onSubmitDelegateSigner}
        externalLoading={isDelegatingSignature}
      />

      <Snackbar
        visible={visibleSnackbar}
        duration={5000}
        onDismiss={() => {
          setVisibleSnackbar(false)
        }}
        style={
          successDelegation
            ? styles.snackBarColorSuccess
            : styles.snackBarColorError
        }
      >
        <Text style={styles.snackText}>
          {successDelegation
            ? t('VIEWS').CROP_COLLABORATOR.TEXT_24
            : t('VIEWS').CROP_COLLABORATOR.TEXT_25}
        </Text>
      </Snackbar>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerCard: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    alignItems: 'flex-start',
  },
  stylesCard: {
    alignItems: 'flex-start',
    paddingTop: 4,
    minHeight: 35,
  },
  goToCrop: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    fontWeight: '700',
    fontStyle: 'normal',
    color: '#FFFFFF',
  },
  stylesSubTitleCard: {
    fontSize: 14,
    marginBottom: 8,
    marginRight: 8,
    color: '#9d9d9d',
    textAlign: 'justify',
  },
  scrollContainer: {
    padding: 16,
    flex: 1,
  },
  emptyStateText: {
    textAlign: 'center',
    marginTop: 16,
  },
  emptyState: {
    width: 203,
    height: 150,
    resizeMode: 'contain',
  },
  titleMenu: {
    fontSize: 14,
    fontWeight: '500',
  },
  warningReplacement: {
    fontSize: 16,
    fontWeight: '700',
    padding: 12,
  },
  actions: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: '-5%',
  },
  actionsWeb: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  unlinkMenu: {
    fontSize: 14,
    fontWeight: '500',
    color: '#FF7043',
  },
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  confirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  containerModal: {
    backgroundColor: 'white',
  },
  containerModalNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  snackBarColorSuccess: {
    backgroundColor: '#C8E6C9',
  },
  snackBarColorError: {
    backgroundColor: '#FBE9E7',
  },
  snackText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'left',
    color: 'black',
  },
  titleModal: {
    color: '#333333',
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 18,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  containerButton: {
    paddingVertical: 15,
    marginTop: 15,
  },
  contentNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerActionSheet: {
    marginHorizontal: 10,
  },
  button: {
    backgroundColor: '#4CAF50',
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButtonDisabled: {
    color: 'rgba(255, 255, 255, .5)',
  },
  textButton: {
    color: 'white',
  },
  containerButtonBottom: {
    backgroundColor: 'white',
    padding: 16,
  },
  delegateCollaborator: {
    backgroundColor: '#E8F5E9',
    marginBottom: 8,
    padding: 12,
  },
  containerRadioCheck: {
    padding: 8,
  },
})

CollaboratorsDelegateSignaturesList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CollaboratorsDelegateSignaturesList
