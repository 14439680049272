import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import { Title, ProgressBar } from 'react-native-paper'
import { primary500 } from '@styles/palette'

export const ActivityProgressBar = ({ percent, contentStyle = {} }) => {
  return (
    <View style={[styles.progressBarContainer, contentStyle]}>
      <View style={styles.progressBar}>
        <ProgressBar progress={percent / 100} color={primary500} />
      </View>

      <Title style={styles.progressBarTitle}>{Math.ceil(percent)}%</Title>
    </View>
  )
}

const styles = StyleSheet.create({
  progressBarContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    marginBottom: 5,
  },
  progressBar: {
    marginBottom: 8,
    marginTop: 9,
    marginRight: 8,
    flex: 1,
  },
  progressBarTitle: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '700',
    marginTop: 4,
  },
})

ActivityProgressBar.prototype = {
  percent: PropTypes.number.isRequired,
  contentStyle: PropTypes.object,
}
