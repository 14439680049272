import React, { useContext } from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { kmzEmpty } from '@constants/images'
import { blackHighEmphasis, primary500, white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import PropTypes from 'prop-types'

export const LotsEmpty = ({ title, onPressButton }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Image source={kmzEmpty} style={styles.emptyImage} />

      <Text style={styles.message}>{title}</Text>
      <View style={styles.footer}>
        <ButtonPrimary
          onPress={onPressButton}
          text={t('COMMON.LOST_EMPTY.TEXT_1')}
          backgroundColor={primary500}
          colorText={white}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emptyImage: {
    width: 280,
    height: 220,
    marginTop: 55,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  message: {
    paddingHorizontal: 26,
    marginTop: 63,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 16,
    paddingBottom: 5,
  },
})

LotsEmpty.propTypes = {
  title: PropTypes.string,
  onPressButton: PropTypes.func,
}
