import React, { useContext, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  Text,
  ScrollView,
  TouchableOpacity,
} from 'react-native'

import { LanguageContext } from '@contextState/language'

import ButtonCustom from '@components/common/ButtonCustom'
import { lotDefault } from '@constants/images'
import { EditableTitle, Description, DeleteModal } from './components/index'
import { blackHighEmphasis, white, secondary500 } from '@styles/palette'
import { ActivityIndicator, CompanyDetails } from '@modules/common/components'
import { useDetailsLotFarm } from './hook'
import { formatSurface } from '@modules/common/utils'
import useNetwork from '@utils/network'
import { AuthContext } from '@contextState/auth'
import { userPermissions } from '@utils/permissions'

const FarmLotDetails = ({ route, navigation }) => {
  const { t } = useContext(LanguageContext)
  const [error, setError] = useState(false)
  const { doRequest } = useNetwork()

  const { config } = useContext(AuthContext)
  const { name, identifier } = config.companySelected
  const { roleSelected } = config
  const hasPermissionEdit = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.EDIT_FIELD
    )
  )

  const { params } = route
  const lotId = params?.lotId

  const {
    farmName,
    name: nameLot,
    province,
    department,
    surface,
    areaUnit,
    urlMap,
    cropType,
    campaign,
    isLoading,
    isRemovable,
    farmUuid,
    onNavigateDetailField,
  } = useDetailsLotFarm(lotId)

  const openDelete = () => {
    setError(true)
  }

  const deleteConfirm = async () => {
    setError(false)

    try {
      const url = `fields/${lotId}`

      await doRequest({
        method: 'DELETE',
        url,
        displayAlert: false,
      })

      navigation.navigate('FarmLotList', {
        farmId: farmUuid,
        name: farmName,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const close = () => {
    setError(false)
  }

  const goToEditLotName = () =>
    navigation.navigate('FarmLotEditName', { farmUuid, lotUuid: lotId })

  if (isLoading) return <ActivityIndicator />

  return (
    <ScrollView style={styles.container}>
      <CompanyDetails
        companyName={name}
        companyIdentifier={identifier}
        style={styles.companyDetails}
      />
      <View style={styles.containerDetail}>
        <View style={styles.containerLotName}>
          <EditableTitle
            subTitle={nameLot}
            onPress={goToEditLotName}
            showIcon={hasPermissionEdit}
          />
        </View>
        <Description
          surface={formatSurface(surface, areaUnit)}
          farmName={farmName}
          location={`${department}, ${province}`}
        />
        <View style={styles.containerLotName}>
          <EditableTitle
            title={t('VIEWS.FARM_LOT_DETAILS.TEXT_1')}
            subTitle={cropType}
            styleSubTitle={styles.subtitleField}
          />
        </View>
        <View style={styles.containerLotName}>
          <EditableTitle
            title={t('VIEWS.FARM_LOT_DETAILS.TEXT_2')}
            subTitle={campaign}
            styleSubTitle={styles.subtitleField}
          />
        </View>
        <Text style={styles.map}>{t('VIEWS.FARM_LOT_DETAILS.TEXT_3')}</Text>

        <TouchableOpacity onPress={onNavigateDetailField}>
          <Image
            source={{ uri: urlMap || lotDefault }}
            style={styles.image}
            resizeMode={'contain'}
          />
        </TouchableOpacity>

        {isRemovable && (
          <View style={styles.footer}>
            <ButtonCustom
              onPress={openDelete}
              styles={styles.buttonDelete}
              labelStyle={styles.textButtonDelete}
            >
              {t('VIEWS.FARM_LOT_DETAILS.TEXT_4')}
            </ButtonCustom>
          </View>
        )}
      </View>
      <DeleteModal
        message={t('VIEWS.FARM_LOT_DETAILS.COMPONENTS.ERROR_MESSAGE.TEXT_1')}
        showModalError={error}
        close={close}
        deleteConfirm={deleteConfirm}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  containerDetail: {
    marginHorizontal: 10,
  },
  containerLotName: {
    marginTop: 20,
    marginBottom: 5,
  },
  subtitleField: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    color: blackHighEmphasis,
  },
  map: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    color: blackHighEmphasis,
    marginTop: 40,
    marginBottom: 6,
  },
  image: {
    flex: 1,
    height: 350,
    marginBottom: 20,
  },
  buttonDelete: {
    textTransform: 'uppercase',
    backgroundColor: secondary500,
    marginVertical: 5,
  },
  textButtonDelete: {
    height: 14,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    alignSelf: 'center',
    color: white,
  },
  footer: {
    marginTop: 30,
    marginBottom: 20,
  },
  companyDetails: {
    margin: 0,
  },
})

export default FarmLotDetails
