import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { formatDateLabel } from '@modules/common/utils'
import { carbon400, blackHighEmphasis } from '@styles/palette'

export const ActivityCard = ({
  activityTypeName,
  subActivityTypeName,
  fieldsQuantity,
  surface,
  unitArea,
  dateStart,
  dateEnd,
  dateAchievement,
  dateHarvest,
  dateMonitoring,
  agreementTypeName,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <Card>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.FIELDS_QUANTITY_TITLE'
          )}
        </Text>

        <Text style={styles.textBold}>{fieldsQuantity}</Text>

        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.SURFACE_TITLE'
          )}
        </Text>

        <Text style={styles.textBold}>{`${surface} ${unitArea}`}</Text>

        {activityTypeName && subActivityTypeName && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.ACTIVITY_TYPE_TITLE',
                {
                  activityType: activityTypeName.toLowerCase(),
                }
              )}
            </Text>

            <Text style={styles.textBold}>{subActivityTypeName}</Text>
          </>
        )}

        {dateStart && dateEnd && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.PLANNED_DATE_TITLE',
                {
                  activityType: activityTypeName,
                }
              )}
            </Text>

            <Text style={styles.textBold}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.SINCE',
                {
                  date: formatDateLabel({ t, date: dateStart }),
                }
              )}
            </Text>

            <Text style={styles.textBold}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.UNTIL',
                {
                  date: formatDateLabel({ t, date: dateEnd }),
                }
              )}
            </Text>
          </>
        )}

        {dateAchievement && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.REALIZED_DATE_TITLE'
              )}
            </Text>

            <Text style={styles.textBold}>
              {formatDateLabel({ t, date: dateAchievement })}
            </Text>
          </>
        )}

        {dateHarvest && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.HARVEST_DATE_TITLE'
              )}
            </Text>

            <Text style={styles.textBold}>
              {formatDateLabel({ t, date: dateHarvest })}
            </Text>
          </>
        )}

        {dateMonitoring && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.MONITORING_DATE_TITLE'
              )}
            </Text>

            <Text style={styles.textBold}>
              {formatDateLabel({ t, date: dateMonitoring })}
            </Text>
          </>
        )}

        {agreementTypeName && (
          <>
            <Text style={styles.textTitle}>
              {t(
                'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.AGREEMENT_TYPE_TITLE'
              )}
            </Text>

            <Text style={styles.textBold}>{agreementTypeName}</Text>
          </>
        )}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 18,
  },
})

ActivityCard.propTypes = {
  activityTypeName: PropTypes.string,
  subActivityTypeName: PropTypes.string,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  dateAchievement: PropTypes.string,
  dateHarvest: PropTypes.string,
  dateMonitoring: PropTypes.string,
  agreementTypeName: PropTypes.string,
}
