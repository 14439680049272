import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import InputText from '@components/inputs/InputText'
import { LanguageContext } from '@contextState/language'

export const FarmForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  validateName,
  onBlur,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <>
      <InputText
        id='farmName'
        label={t('VIEWS.FARM_CREATE.COMPONENTS.FARM_FORM.TEXT_1')}
        placeholder={t('VIEWS.FARM_CREATE.COMPONENTS.FARM_FORM.TEXT_2')}
        value={values.farmName}
        onChangeText={(value) => setFieldValue('farmName', value?.trimStart())}
        onBlur={async () => {
          setFieldTouched('farmName')
          await onBlur()
        }}
        touched={touched.farmName}
        error={errors.farmName || validateName?.message}
      />
    </>
  )
}

FarmForm.propTypes = {
  formik: PropTypes.object.isRequired,
  validateName: PropTypes.object.isRequired,
}
