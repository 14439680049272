import { useState, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'

import { useCompanyInfo } from '@common/hooks'
import useNetwork from '@utils/network'
import { activitiesActions } from '@store/actions'
import { AuthContext } from '@contextState/auth'
import userTypes from '@constants/userTypes'

export const useFetchCrop = ({ cropId }: { cropId: string }) => {
  const dispatch = useDispatch()

  const { user, isVerifierUcropit }: any = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [crop, setCrop] = useState(null)

  const { doRequest } = useNetwork()
  const { identifier, companyId } = useCompanyInfo()

  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  const fetchData = async () => {
    setIsLoading(true)

    const requestData = {
      method: 'GET',
      url: `crops/${cropId}`,
      version: 'v2',
      params: {
        companyId,
        createdAt: Date.now(),
      },
      displayAlert: false,
    }

    let response

    try {
      const { data } = await doRequest(requestData)

      response = data
    } catch (error) {
      setIsLoading(false)

      return
    }

    const parsedData = {
      ...response,
      activities: [],
    }

    const userType = String(
      response?.members?.find((member: any) => member?.user?._id === user._id)
        ?.type
    )

    dispatch(
      activitiesActions.setCanSeeVerification(
        isVerifierUcropit
          ? identifier === response?.identifier
          : userType !== userTypes.MARKETER.key
      )
    )

    setCrop(parsedData)

    setIsLoading(false)
  }

  return {
    isLoading,
    crop,
  }
}
