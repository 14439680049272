import { round } from 'lodash'
import { useContext } from 'react'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { ConnectionContext } from '@contextState/connection'

import activityTypes from '@constants/activityTypes'
import {
  cantValidateAchievement,
  checkIsHaveAchievements,
  countSignAchievements,
  dateFormatedForMonitorings,
} from '..'

export const achievementCard = ({ activity, crop }) => {
  const { t } = useContext(LanguageContext)
  const { unitTypes, storageTypes } = useContext(CommonContext)
  const { isConnected } = useContext(ConnectionContext)

  let lotsText = ''
  if (activity.lots) {
    lotsText =
      activity.lots.length > 1
        ? t('VIEWS.ACTIVITIES.TEXT_28', {
            lotsQuantity: activity.lots.length,
            surface: round(activity.surface, 2),
          })
        : t('VIEWS.ACTIVITIES.TEXT_29', {
            lotsQuantity: activity.lots.length,
            surface: round(activity.surface, 2),
          })
  }
  const unitPay = unitTypes.find((unit) => unit.value === activity.unitType)
  if (activity.type.tag === activityTypes.ACT_MONITORING.key) {
    return `${lotsText}\n${t('VIEWS').ACTIVITIES.TEXT_8}: ${activity.pay} ${
      unitPay?.label
    } \n${t('VIEWS').ACTIVITIES.TEXT_9}: ${dateFormatedForMonitorings(
      activity,
      crop
    )}`
  } else if (activity.type.tag === activityTypes.ACT_HARVEST.key) {
    return `${t('VIEWS.ACTIVITIES.TEXT_31', {
      pay: activity.pay,
      unitType: unitPay?.label,
    })} \n${t('VIEWS.ACTIVITIES.TEXT_33', {
      storage: activity.storages
        .map(
          (subElement) =>
            storageTypes?.find(
              (subSubElement) => subSubElement.value === subElement.storageType
            )?.label
        )
        .join(', '),
    })}`
  } else if (activity.type.tag === activityTypes.ACT_APPLICATION.key) {
    return isConnected
      ? `${t('VIEWS').ACTIVITIES.TEXT_10} ${
          activity.percentTotal ? activity.percentTotal : 0
        }%`
      : `${t('VIEWS').ACTIVITIES.TEXT_10} -`
  } else if (activity.type.tag !== activityTypes.ACT_VERIFICATION.key) {
    return `${t('VIEWS').ACTIVITIES.TEXT_11}:${
      !checkIsHaveAchievements(activity)
        ? activity.signers.filter((el) => el.signed).length
        : countSignAchievements(activity)
    }/${
      !checkIsHaveAchievements(activity)
        ? activity.signers.length
        : cantValidateAchievement(activity)
    }`
  }
}
