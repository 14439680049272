import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import lotDefault from '@images/lot_default_image.png'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  gray12,
  lime900,
} from '@styles/palette'
import { formatSurface } from '@modules/common/utils'

export const LotItem = ({
  id,
  name,
  surface,
  areaUnit,
  location,
  image,
  goToDetailsLotFarm,
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={() => goToDetailsLotFarm(id)}
      key={id}
    >
      <View style={styles.container}>
        <Image
          source={{
            uri: image,
          }}
          defaultSource={lotDefault}
          style={styles.image}
        />
        <View style={styles.contentText}>
          <Text style={styles.title} numberOfLines={1} ellipsizeMode='tail'>
            {name}
          </Text>
          <Text style={styles.subtitle}>
            {formatSurface(surface, areaUnit)}
          </Text>
          <Text style={styles.subtitle} numberOfLines={1} ellipsizeMode='tail'>
            {location}
          </Text>
        </View>

        <CommonIcon name='CHEVRON-RIGHT' size={26} color={lime900} />

        <View style={styles.line} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    marginLeft: 16,
    marginRight: 10,
    paddingRight: 16,
    paddingBottom: 9,
  },
  image: {
    width: 100,
    height: 56,
  },
  contentText: {
    flex: 1,
    paddingLeft: 16,
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackHighEmphasis,
  },
  subtitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
    marginVertical: 1,
  },
  line: {
    height: 1,
    backgroundColor: gray12,
    width: '88%',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
})

LotItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surface: PropTypes.number.isRequired,
  areaUnit: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  goToDetailsLotFarm: PropTypes.func.isRequired,
}
