import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { Title, List, Chip } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonContext } from '@contextState/common'
import ButtonCustom from '@components/common/ButtonCustom'
import { LanguageContext } from '@contextState/language'

import { ActivityIndicator } from '@modules/common/components'
import { CommonIcon } from '@modules/common/components'

function FiltersScreen({ navigation }) {
  const {
    cropsFilter: data,
    isFetchingCropsFilter,
    filters,
    setFilters,
    cropTypes,
    roleTypes,
  } = useContext(CommonContext)

  const { t } = useContext(LanguageContext)

  const [hasAnyFilterSelected, setHasAnyFilterSelected] = useState(false)
  const [cropTypesState, setCropTypesState] = useState([])
  const [companiesState, setCompaniesState] = useState([])
  const [collaboratorsState, setCollaboratorsState] = useState([])
  const [cropVolumeState, setCropVolumeState] = useState(
    filters.cropVolume ? filters.cropVolume : 0
  )

  /*
  BUILD CROP TYPES FILTER
  */
  useEffect(() => {
    const cropTypesState = filters.allCropTypes || []

    if (cropTypesState.length === 0) {
      data.map((crop) => {
        cropTypes.map((cropType) => {
          const newCropType = { ...cropType }

          if (newCropType.value === crop.cropType._id) {
            if (cropTypesState.length > 0) {
              const exist = cropTypesState.filter(
                (element) => element.value === newCropType.value
              )[0]

              if (!exist) {
                cropTypesState.push(newCropType)
              }
            } else {
              cropTypesState.push(newCropType)
            }
          }
        })
      })
    }

    const newCropTypesState = []

    cropTypesState.map((cropType) => {
      let isSelected = false

      filters.cropTypes.map((cropTypeId) => {
        if (cropTypeId === cropType.value) {
          isSelected = true
        }
      })

      if (isSelected) {
        cropType.selected = true
      } else {
        cropType.selected = false
      }

      newCropTypesState.push(cropType)
    })

    setCropTypesState(newCropTypesState)

    if (filters.allCropTypes.length === 0) {
      setFilters({
        ...filters,
        allCropTypes: cropTypesState,
      })
    }
  }, [data, cropTypes, filters])

  /*
  BUILD COMPANIES FILTER
  */
  useEffect(() => {
    const companiesState = filters.allCompanies || []

    if (companiesState.length === 0) {
      data.map((crop) => {
        if (!crop.company) return

        const company = { ...crop.company }

        if (companiesState.length > 0) {
          const exist = companiesState.filter(
            (element) => element._id === company._id
          )[0]

          if (!exist) {
            companiesState.push(company)
          }
        } else {
          companiesState.push(company)
        }
      })
    }

    const newCompaniesState = []

    companiesState.map((company) => {
      let isSelected = false

      filters.companies.map((companyId) => {
        if (companyId === company._id) {
          isSelected = true
        }
      })

      if (isSelected) {
        company.selected = true
      } else {
        company.selected = false
      }

      newCompaniesState.push(company)
    })

    setCompaniesState(newCompaniesState)

    if (filters.allCompanies.length === 0) {
      setFilters({
        ...filters,
        allCompanies: companiesState,
      })
    }
  }, [data, filters])

  /*
  BUILD COLLABORATORS FILTER
  */
  useEffect(() => {
    const collaborators = filters.allCollaborators || []

    if (collaborators.length === 0) {
      data.map((crop) => {
        crop.members?.map((member) => {
          if (!member?.user?.firstName || !member?.user?.lastName) {
            return
          }

          if (collaborators.length === 0) {
            const collaborator = {
              ...member,
              types: [member.type],
            }

            collaborators.push(collaborator)

            return
          }

          let exist = false

          collaborators.map((collaborator) => {
            if (collaborator.user._id === member.user._id) {
              exist = true

              if (collaborator.types.indexOf(member.type) === -1) {
                collaborator.types.push(member.type)
              }
            }
          })

          if (!exist) {
            const collaborator = {
              ...member,
              types: [member.type],
            }

            collaborators.push(collaborator)
          }
        })
      })
    }

    const collaboratorsState = []

    collaborators.map((collaborator) => {
      let isSelected = false

      filters.collaborators.map((collaboratorId) => {
        if (collaboratorId === collaborator.user._id) {
          isSelected = true
        }
      })

      if (isSelected) {
        collaborator.selected = true
      } else {
        collaborator.selected = false
      }
      collaboratorsState.push(collaborator)
    })

    setCollaboratorsState(collaboratorsState)

    if (filters.allCollaborators.length === 0) {
      setFilters({
        ...filters,
        allCollaborators: collaboratorsState,
      })
    }
  }, [data, filters])

  /*
  BUILD CROP VOLUME FILTER
  */
  useEffect(() => {
    if (!filters.cropVolume) {
      setCropVolumeState(0)
    }
  }, [filters])

  /*
  DETECT WHEN ANY FILTER IS SELECTED
  */
  useEffect(() => {
    let existAlmostOneSelected = false
    cropTypesState.map((cropTypeState) => {
      if (cropTypeState.selected) {
        existAlmostOneSelected = true
      }
    })
    companiesState.map((companyState) => {
      if (companyState.selected) {
        existAlmostOneSelected = true
      }
    })
    collaboratorsState.map((collaboratorState) => {
      if (collaboratorState.selected) {
        existAlmostOneSelected = true
      }
    })
    if (cropVolumeState != 0) {
      existAlmostOneSelected = true
    }
    if (existAlmostOneSelected && !hasAnyFilterSelected) {
      setHasAnyFilterSelected(true)
    } else if (!existAlmostOneSelected && hasAnyFilterSelected) {
      setHasAnyFilterSelected(false)
    }
  }, [cropTypesState, companiesState, collaboratorsState, cropVolumeState])

  function selectCropType(cropTypeSelected) {
    const newCropTypesState = cropTypesState.map((cropType) => {
      if (cropType.value === cropTypeSelected.value) {
        if (!cropType.selected) {
          cropType.selected = true
        } else {
          cropType.selected = false
        }
      }
      return cropType
    })

    setCropTypesState(newCropTypesState)
  }

  function selectCompany(companySelected) {
    const newCompaniesState = companiesState.map((company) => {
      if (company._id === companySelected._id) {
        if (!company.selected) {
          company.selected = true
        } else {
          company.selected = false
        }
      }
      return company
    })

    setCompaniesState(newCompaniesState)
  }

  function selectCollaborators(collaboratorSelected) {
    const newCollaboratorsState = collaboratorsState.map((collaborator) => {
      if (collaborator._id === collaboratorSelected._id) {
        if (!collaborator.selected) {
          collaborator.selected = true
        } else {
          collaborator.selected = false
        }
      }

      return collaborator
    })

    setCollaboratorsState(newCollaboratorsState)
  }

  function getRoles(collaborator) {
    let roles = ''

    collaborator.types.map((type) => {
      if (roles !== '') {
        roles =
          roles + ' - ' + roleTypes.find((role) => role.value === type)?.label
      } else {
        roles = roleTypes.find((role) => role.value === type)?.label
      }
    })

    return roles
  }

  function applyFilters() {
    const cropTypesSelected = []

    cropTypesState.map((cropType) => {
      if (cropType.selected) {
        cropTypesSelected.push(cropType.value)
      }
    })

    const companiesSelected = []

    companiesState.map((company) => {
      if (company.selected) {
        companiesSelected.push(company._id)
      }
    })

    const collaboratorsSelected = []

    collaboratorsState.map((collaborator) => {
      if (collaborator.selected) {
        collaboratorsSelected.push(collaborator.user._id)
      }
    })

    let hasAnyFilterActive = false

    if (
      cropTypesSelected.length > 0 ||
      companiesSelected.length > 0 ||
      collaboratorsSelected.length > 0 ||
      cropVolumeState !== 0
    ) {
      hasAnyFilterActive = true
    }

    if (!filters.hasAnyFilterActive && !hasAnyFilterActive) {
      return
    }

    setFilters({
      ...filters,
      cropTypes: cropTypesSelected,
      companies: companiesSelected,
      collaborators: collaboratorsSelected,
      cropVolume: cropVolumeState === 0 ? undefined : cropVolumeState,
      hasAnyFilterActive: hasAnyFilterActive,
    })

    navigation.goBack()
  }

  if (isFetchingCropsFilter)
    return <ActivityIndicator containerStyle={styles.activityIndicator} />

  return (
    <ScrollView style={styles.mainContainer}>
      <View style={styles.cropTypesContainer}>
        <Title>{t('VIEWS').FILTERS.TEXT_1}</Title>
        <View style={styles.cropTypesChipsContainer}>
          {cropTypesState.map((cropType, key) => (
            <Chip
              key={key}
              onPress={() => {
                selectCropType(cropType)
              }}
              style={
                !cropType.selected
                  ? styles.cropTypesChipsContainerChip
                  : styles.cropTypesChipsContainerChipSelected
              }
              textStyle={
                !cropType.selected
                  ? styles.cropTypesChipsContainerChipText
                  : styles.cropTypesChipsContainerChipTextSelected
              }
            >
              {cropType.label}
            </Chip>
          ))}
        </View>
      </View>
      <View style={styles.companiesContainer}>
        <Title>{t('VIEWS').FILTERS.TEXT_2}</Title>
        <View style={styles.companiesChipsContainer}>
          {companiesState.map((company, key) => (
            <Chip
              key={key}
              onPress={() => {
                selectCompany(company)
              }}
              style={
                !company.selected
                  ? styles.cropTypesChipsContainerChip
                  : styles.cropTypesChipsContainerChipSelected
              }
              textStyle={
                !company.selected
                  ? styles.cropTypesChipsContainerChipText
                  : styles.cropTypesChipsContainerChipTextSelected
              }
            >
              {company.name}
            </Chip>
          ))}
        </View>
      </View>
      <View style={styles.collaboratorsContainer}>
        <List.Section>
          <List.Accordion
            title={t('VIEWS').FILTERS.TEXT_3}
            titleStyle={styles.collaboratorsDropdownTitle}
            style={styles.collaboratorsDropdownContainer}
          >
            {collaboratorsState.map((collaborator, key) => (
              <List.Item
                key={key}
                title={
                  collaborator.user.firstName + ' ' + collaborator.user.lastName
                }
                titleStyle={styles.collaboratorsDropdownItemTitle}
                description={getRoles(collaborator)}
                onPress={() => {
                  selectCollaborators(collaborator)
                }}
                right={(props) =>
                  collaborator.selected ? (
                    <CommonIcon
                      {...props}
                      style={styles.collaboratorsDropdownItemIcon}
                      color='#4CAF50'
                      name='CHECKBOX-SELECTED'
                    />
                  ) : (
                    <CommonIcon
                      {...props}
                      style={styles.collaboratorsDropdownItemIconSelected}
                      color='rgba(0, 0, 0, .6)'
                      name='CHECKBOX-UNSELECTED'
                    />
                  )
                }
                style={styles.collaboratorsDropdownItemContainer}
              />
            ))}
          </List.Accordion>
        </List.Section>
      </View>
      <View style={styles.buttonContainer}>
        <ButtonCustom
          onPress={applyFilters}
          styles={!hasAnyFilterSelected ? styles.buttonDisabled : styles.button}
          disabled={!hasAnyFilterSelected}
        >
          {t('VIEWS').FILTERS.TEXT_5}
        </ButtonCustom>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
    marginBottom: 20,
    marginTop: 10,
  },
  mainContainer: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 20,
    flex: 1,
  },
  cropTypesContainer: {
    marginBottom: 20,
    marginLeft: 8,
  },
  cropTypesChipsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingLeft: 10,
  },
  cropTypesChipsContainerChip: {
    height: 30,
    marginRight: 10,
    marginBottom: 10,
    minWidth: 65,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .08)',
  },
  cropTypesChipsContainerChipSelected: {
    height: 30,
    marginRight: 10,
    marginBottom: 10,
    minWidth: 65,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#81C784',
  },
  cropTypesChipsContainerChipText: {
    color: '#000000',
    fontSize: 13,
  },
  cropTypesChipsContainerChipTextSelected: {
    color: '#FFFFFF',
    fontSize: 13,
  },
  companiesContainer: {
    marginBottom: 10,
    marginLeft: 8,
  },
  companiesChipsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingLeft: 10,
  },
  companiesChipsContainerChip: {
    height: 30,
    marginRight: 10,
    marginBottom: 10,
    minWidth: 65,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .08)',
  },
  companiesChipsContainerChipSelected: {
    height: 30,
    marginRight: 10,
    marginBottom: 10,
    minWidth: 65,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#81C784',
  },
  companiesChipsContainerChipText: {
    color: '#000000',
    fontSize: 13,
  },
  companiesChipsContainerChipTextSelected: {
    color: '#FFFFFF',
    fontSize: 13,
  },
  collaboratorsContainer: {},
  collaboratorsDropdownTitle: {
    fontSize: 20,
    fontWeight: '500',
  },
  collaboratorsDropdownContainer: {
    paddingLeft: 0,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .2)',
  },
  collaboratorsDropdownItemTitle: {
    fontSize: 16,
    fontWeight: '500',
  },

  collaboratorsDropdownItemIcon: {
    height: 20,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  collaboratorsDropdownItemIconSelected: {
    height: 20,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  collaboratorsDropdownItemContainer: {
    backgroundColor: 'rgba(0, 0, 0, .025)',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .2)',
  },
  cropVolumeContainer: {
    marginTop: 20,
    marginLeft: 8,
  },
  cropVolumeSlideContainer: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  cropVolumeSlide: {
    height: 10,
  },
  cropVolumeIndicatorsContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cropVolumeLeftIndicator: {
    fontSize: 16,
    marginLeft: 20,
    marginRight: 'auto',
    fontWeight: '400',
  },
  cropVolumeMiddleIndicator: {
    fontSize: 16,
    fontWeight: '400',
  },
  cropVolumeRightIndicator: {
    fontSize: 16,
    marginLeft: 'auto',
    marginRight: 20,
    fontWeight: '400',
  },
  buttonContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  button: {
    backgroundColor: '#4CAF50',
  },
  buttonDisabled: {
    backgroundColor: '#81C784',
  },
})

FiltersScreen.propTypes = {
  navigation: PropTypes.object,
}

export default FiltersScreen
