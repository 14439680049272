import React from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, white, grey300, grey500 } from '@styles/palette'

export const Button = ({
  title,
  onPress,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const buttonStyle = disabled ? styles.buttonDisabled : styles.buttoActive

  const labelStyle = disabled ? styles.textButtonDisabled : styles.textButton

  return (
    <ButtonCustom
      disabled={disabled}
      isLoading={isLoading}
      onPress={onPress}
      styles={[styles.button, buttonStyle]}
      labelStyle={labelStyle}
      {...props}
    >
      {title}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    textTransform: 'uppercase',
    marginVertical: 5,
  },
  buttoActive: {
    backgroundColor: primary500,
  },
  buttonDisabled: {
    backgroundColor: grey300,
  },
  textButton: {
    color: white,
  },
  textButtonDisabled: {
    color: grey500,
  },
})

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}
