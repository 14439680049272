import React from 'react'
import { Text } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import PropTypes from 'prop-types'

const ButtonPrimary = ({
  text,
  onPress,
  height,
  width,
  textAlign,
  fontSize,
  flex,
  icon,
  shadow,
  borderColor,
  loading,
  disabled,
  customTextStyle,
  marginTop,
  marginBottom,
  paddingLeft,
  fontWeight = 'bold',
  borderRadius = 10,
  backgroundColor = '#fff',
  colorText = '#4CAF50',
  justifyContent = 'center',
}) => {
  return (
    <TouchableRipple
      disabled={disabled || loading}
      style={{
        opacity: disabled || loading ? 0.5 : 1,
        backgroundColor,
        height: height || 53,
        flex,
        borderColor,
        marginTop,
        marginBottom,
        borderRadius,
        borderWidth: borderColor ? 1 : 0,
        justifyContent: icon ? 'flex-start' : justifyContent,
        paddingLeft: paddingLeft || (icon ? 15 : 0),
        flexDirection: 'row',
        alignItems: 'center',
        // ios
        shadowOpacity: shadow ? 0.3 : 0,
        shadowRadius: shadow ? 3 : 0,
        shadowOffset: {
          height: shadow ? 2 : 0,
          width: shadow ? 2 : 0,
        },
        // android
        elevation: shadow ? 2 : 0,
        width: width || '100%',
        cursor: onPress ? 'pointer' : 'auto',
      }}
      onPress={onPress}
    >
      <>
        <Text
          style={{
            color: colorText,
            textAlign: textAlign || 'center',
            fontSize: fontSize || 16,
            marginLeft: icon ? 10 : 0,
            fontWeight,
            ...customTextStyle,
          }}
        >
          {text}
        </Text>
      </>
    </TouchableRipple>
  )
}

ButtonPrimary.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
  flex: PropTypes.number,
  icon: PropTypes.element,
  shadow: PropTypes.bool,
  borderColor: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  customTextStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  fontWeight: PropTypes.string,
  borderRadius: PropTypes.number,
  backgroundColor: PropTypes.string,
  colorText: PropTypes.string,
}

export default ButtonPrimary
