import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { blackHighEmphasis, blackMediumEmphasis } from '@styles/palette'

export const EmptyItemFarm = ({ title }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.text}>
        0 {t('VIEWS.FARM_LIST.COMPONENTS.EMPTY_ITEM_FARM.TEXT_1')}
      </Text>
    </View>
  )
}

EmptyItemFarm.propTypes = {
  title: PropTypes.string.isRequired,
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 19,
    marginTop: 16,
  },
  title: {
    fontWeight: '400',
    fontSize: 16,
    color: blackMediumEmphasis,
    lineHeight: 22,
    marginBottom: 15,
  },
  text: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackHighEmphasis,
    marginVertical: 6,
  },
})
