import PropTypes from 'prop-types'
import moment from 'moment'
import React, { useState } from 'react'
import { View, StyleSheet, FlatList } from 'react-native'
import ItemDownload from '@components/common/v1/ItemDownload'
import { blackMediumEmphasis, primary500, white, gray12 } from '@styles/palette'
import TitleIcon from './TitleIcon'
import useActionSheetCustom from '@hooks/useActionSheetCustom'
import { Divider } from 'react-native-paper'
import { downloadFile } from '@utils/files'
import { ActivityIcon } from '@modules/common/components'

const AgreementsDetails = ({ t, activities }) => {
  const [indexSelected, setIndexSelected] = useState(null)
  const { ActionSheetComponent, openActionSheet } = useActionSheetCustom({
    t,
    title: t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_12,
  })

  const openAction = async (index) => {
    await setIndexSelected(index)
    openActionSheet()
  }

  const arrayEvidences =
    indexSelected !== null
      ? [
          ...activities[indexSelected].evidenceImages,
          ...activities[indexSelected].evidencesPdf,
        ]
      : []

  return (
    <View>
      <TitleIcon
        text={t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_12}
        Icon={() => <ActivityIcon name={'ACT_AGREEMENTS'} size={40} />}
      />
      <View style={styles.listContainer}>
        <FlatList
          data={activities}
          renderItem={({ index, item }) => {
            return (
              <ItemDownload
                title={item?.typeAgreement?.codeLabel}
                subtitle={`${t('COMPONENTS.BINNACLE.CARD_CROP_DETAIL.TEXT_13', {
                  lotsQuantity: item.lots.length,
                })} -  ${t('COMPONENTS.BINNACLE.CARD_CROP_DETAIL.TEXT_14', {
                  has: item.surface,
                })}`}
                description={`${
                  t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_15
                }: ${moment(item.dateOrder).format('DD/MM/YY')}`}
                Icon={() => (
                  <ActivityIcon
                    name={'ACT_AGREEMENTS'}
                    size={44}
                    color={white}
                    style={styles.icon}
                  />
                )}
                onPress={() => openAction(index)}
              />
            )
          }}
          keyExtractor={(item) => `${item._id}`}
          ItemSeparatorComponent={() => <Divider style={styles.divider} />}
        />
      </View>

      {indexSelected !== null && (
        <ActionSheetComponent>
          <FlatList
            data={arrayEvidences}
            renderItem={({ item }) => (
              <ItemDownload
                title={item.description}
                subtitle={item.nameFile}
                description={`${item.user.firstName} ${item.user.lastName} `}
                onPress={async () => {
                  downloadFile(item.path)
                }}
                titleStyle={styles.titleItem}
              />
            )}
            keyExtractor={(item) => `${item.id}`}
            ItemSeparatorComponent={() => <Divider style={styles.divider} />}
          />
        </ActionSheetComponent>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    paddingBottom: 16,
    marginHorizontal: 16,
  },
  icon: {
    padding: 10,
    backgroundColor: primary500,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },
  titleItem: {
    color: blackMediumEmphasis,
  },
  divider: {
    height: 0.5,
    backgroundColor: gray12,
    marginVertical: 8,
  },
})

AgreementsDetails.propTypes = {
  t: PropTypes.func.isRequired,
}

export default AgreementsDetails
