import { View, Text, StyleSheet } from 'react-native'
import React, { useContext, useState } from 'react'
import { primary500, green700, green300 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import RequestedReportModal from '../RequestedReportModal'
import { TouchableRipple } from 'react-native-paper'
import useNetwork from '@utils/network'

export const DownloadReport = ({
  isModalVisible,
  closeModal,
  toggleModal,
  farmId,
}) => {
  const { t } = useContext(LanguageContext)
  const [requestedReport, setRequestedReport] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')

  const { doRequest } = useNetwork()

  const handleRequestReport = async () => {
    setRequestedReport(true)
    try {
      await doRequest({
        method: 'POST',
        url: `landScapeSustainability/generatePaReport/${farmId}`,
        version: 'v2',
        params: {
          nextL5: 1,
          nextL8: 1,
        },
        displayAlert: false,
      })
      toggleModal()
    } catch (error) {
      if (error.status === 400) {
        setErrorResponse(error.message)
        toggleModal()
      }
      console.error(error)
    }
  }

  return (
    <>
      <View style={styles.downloadReportContainer}>
        <Text style={styles.downloadReportTitle}>
          {t('VIEWS.FARM_DETAILS.COMPONENTS.DONWLOAD_REPORT.TITLE')}
        </Text>
        <Text style={styles.downloadReportDescription}>
          {requestedReport
            ? t('VIEWS.FARM_DETAILS.COMPONENTS.REQUESTED_MODAL.DESCRIPTION')
            : t('VIEWS.FARM_DETAILS.COMPONENTS.DONWLOAD_REPORT.DESCRIPTION')}
        </Text>
        <TouchableRipple
          disabled={requestedReport}
          onPress={handleRequestReport}
        >
          <Text
            style={[
              styles.downloadReportButton,
              requestedReport && styles.disabledDownloadReportButton,
            ]}
          >
            {t('VIEWS.FARM_DETAILS.COMPONENTS.DONWLOAD_REPORT.REQUEST')}
          </Text>
        </TouchableRipple>
      </View>
      <RequestedReportModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        toggleModal={toggleModal}
        errorResponse={errorResponse}
      />
    </>
  )
}
const styles = StyleSheet.create({
  downloadReportContainer: {
    marginTop: 16,
  },
  downloadReportTitle: {
    color: green700,
    fontFamily: 'Roboto',
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: 24,
    fontWeight: '500',
  },
  downloadReportDescription: {
    color: green700,
    fontFamily: 'Roboto',
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: 16,
    fontWeight: '400',
  },
  downloadReportButton: {
    color: primary500,
    fontFamily: 'Roboto',
    cursor: 'pointer',
    fontSize: 14,
    letterSpacing: 0.75,
    lineHeight: 16,
    fontWeight: '700',
    alignSelf: 'center',
    textTransform: 'uppercase',
    marginTop: 16,
  },
  disabledDownloadReportButton: {
    color: green300,
    cursor: 'auto',
  },
})
