import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'

import InputCheckbox from '@components/inputs/InputCheckbox'
import { blackDisabled, blackHighEmphasis, gray12 } from '@styles/palette'
import { gray80 } from '@common/styles/palette'

export const ItemFilter = ({
  bigTitle,
  borderBottom,
  checked,
  disabled,
  onPress,
  title,
}) => {
  return (
    <TouchableWithoutFeedback onPress={disabled ? () => null : onPress}>
      <View
        style={[styles.containerItem, borderBottom ? styles.borderBottom : {}]}
      >
        <Text
          style={[
            bigTitle ? styles.bigTitle : styles.title,
            disabled ? styles.colorDisabled : {},
          ]}
        >
          {title}
        </Text>
        <InputCheckbox
          checked={checked}
          onPress={onPress}
          colorUnchecked={gray80}
          disabled={disabled}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  containerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 10,
  },
  bigTitle: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 21.78,
    color: blackHighEmphasis,
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 22,
    color: blackHighEmphasis,
  },
  borderBottom: {
    borderBottomColor: gray12,
    borderBottomWidth: 1,
  },
  colorDisabled: {
    color: blackDisabled,
  },
})

ItemFilter.propTypes = {
  bigTitle: PropTypes.bool,
  borderBottom: PropTypes.bool,
  checked: PropTypes.bool,
  disabledItems: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}
