import React from 'react'
import PropTypes from 'prop-types'

import ACTIVITY_TYPES from '@constants/activityTypes'
import { ActivityProgressBar, ActivityCardRealization } from '../'
import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

export const DirectApplicationActivity = ({
  activityType,
  percent,
  farms,
  subTypeActivity,
  status,
  signed,
  signedIf,
  supplies,
  envImpactIndex,
  pay,
  unitType,
  unitTypeKey,
  volume,
  areaUnitVolume,
  areaUnit,
  dateOrder,
  surface,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_APPLICATION.key) return null

  const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(farms)

  return (
    <>
      <ActivityProgressBar percent={percent} />

      <ActivityCardRealization
        activityType={activityType}
        supplies={supplies}
        envImpactIndex={envImpactIndex}
        farms={farmAndLotsWithSurfaceRealized}
        subTypeActivity={subTypeActivity}
        status={status}
        signed={signed}
        signedIf={signedIf}
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
        areaUnit={areaUnit}
        date={dateOrder}
        farmRealized={true}
        surface={surface}
      />
    </>
  )
}

DirectApplicationActivity.prototype = {
  activityType: PropTypes.oneOf([ACTIVITY_TYPES.ACT_TILLAGE.key]),
  percent: PropTypes.number.isRequired,
  farms: PropTypes.array.isRequired,
  subTypeActivity: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  dateOrder: PropTypes.string.isRequired,
  envImpactIndex: PropTypes.string,
  pay: PropTypes.string,
  unitType: PropTypes.string,
  unitTypeKey: PropTypes.string,
  volume: PropTypes.string,
  areaUnitVolume: PropTypes.string,
  areaUnit: PropTypes.string,
}
