import React, { useState, useContext, useCallback, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { View, StyleSheet } from 'react-native'
import { useFormik } from 'formik'
import { FormikProvider } from 'formik'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { selectFileBinaryAction } from '@store/actions'

import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  DEFAULT_FARM_IMAGE,
} from './utils'
import useNetwork from '@utils/network'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import useModal from '@hooks/useModal'
import {
  FarmForm,
  UploadArea,
  LotsAddButton,
  SaveDraftButton,
} from './components'
import { blackHighEmphasis, white } from '@styles/palette'
import { ActivityIndicator, CompanyDetails } from '@modules/common/components'

import { useShowSurface, useCreateDraft } from './hooks'

const FarmCreate = ({ navigation, route }) => {
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const { config, user } = useContext(AuthContext)
  const { name, identifier, _id } = config.companySelected
  const { params } = route
  const farmUuid = params?.farmUuid

  const dispatch = useDispatch()

  const [formikInitialValues] = useState(getFormikInitialValues(''))
  const [selectedFile, setSelectedFile] = useState([])
  const [surface, setSurface] = useState({
    image: '',
    surface: '',
    areaUnit: '',
  })
  const [removeFarmFile, setRemoveFarmFile] = useState(false)
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [statusFarm, setStatusFarm] = useState('DRAFT')

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: false,
  })

  const { isValid, validateForm, values, setFieldError, setFieldTouched } =
    formik

  const { visible } = useShowSurface(surface)
  const { validateName, handleSubmit } = useCreateDraft({
    farmUuid,
    farmName: values.farmName.trim(),
    company: _id,
    identifier,
    userId: user.id,
    File: selectedFile,
    navigation,
    setFieldTouched,
    setFieldError,
    setError,
    toggleModal,
  })

  useEffect(() => {
    findFarmByIdAndIdentifier()
  }, [])

  useEffect(() => {
    if (selectedFile && Object.entries(selectedFile).length) {
      uploadSurfaces()
    }

    return () => {}
  }, [selectedFile])

  useFocusEffect(
    useCallback(() => {
      validateForm()
    }, [])
  )

  const findFarmByIdAndIdentifier = async () => {
    if (!farmUuid) return

    setIsLoading(true)

    try {
      const url = `farms/${farmUuid}/companies/${identifier}`

      const response = await doRequest({
        method: 'GET',
        url,
        params,
        displayAlert: true,
      })

      const { name, surface, mapsURl, areaUnit, status } = response.data

      formik.setFieldValue('farmName', name)

      if (Number(surface)) {
        let image = DEFAULT_FARM_IMAGE

        if (mapsURl && mapsURl.length) {
          const [firstImage] = mapsURl

          image = firstImage
        }

        const farmSurface = {
          surface,
          image,
          areaUnit,
        }

        setSurface(farmSurface)
        setStatusFarm(status)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const uploadSurfaces = async () => {
    const formData = new FormData()

    formData.append('file', selectedFile)

    try {
      const response = await doRequest({
        method: 'POST',
        url: 'farms/surfaces',
        data: formData,
        displayAlert: false,
        params: { identifier },
      })

      if (response.data[0]?.surface <= 0) {
        setError({ message: t('VIEWS').FARM_CREATE.TEXT_3 })
        toggleModal()
      } else {
        setSurface(response.data[0])
        dispatch(selectFileBinaryAction.setFileBinary(selectedFile))
      }
    } catch (error) {
      setError({ message: error?.message })
      toggleModal()
    }
  }

  const onRemoveFarmFile = () => {
    dispatch(selectFileBinaryAction.clearFileBinary())

    setSelectedFile([])

    setSurface({
      image: '',
      surface: '',
      areaUnit: '',
    })

    if (farmUuid) {
      setRemoveFarmFile(true)
    }
  }

  const checkUniqueName = async () => {
    await validateForm()
    if (!farmUuid) return true
    if (!isValid) return false
    try {
      const data = {
        name: values.farmName.trim(),
        identifier: identifier,
      }

      await doRequest({
        method: 'POST',
        url: `farms/${farmUuid}/check-name`,
        displayAlert: false,
        data,
      })

      return true
    } catch (err) {
      setFieldTouched('farmName', true, false)
      setFieldError('farmName', err.message)
    }

    return false
  }

  /**
   * ON GO TO FARM LOTS ADD
   */
  const onGoFarmLotsAdd = async () => {
    const isUniqueName = await checkUniqueName()

    if (!isUniqueName) return

    navigation.navigate('FarmLotsAdd', {
      farmName: values.farmName.trim(),
      farmUuid,
      removeFarmFile,
      statusFarm: statusFarm,
      onReturn: () => {
        navigation.goBack()
      },
    })
  }

  const handleFile = ({ file }) => {
    setSelectedFile(file)
    setRemoveFarmFile(false)
  }

  if (isLoading) return <ActivityIndicator />

  return (
    <>
      <View style={styles.container}>
        <CompanyDetails
          companyName={name}
          companyIdentifier={identifier}
          style={styles.CompanyDetails}
        />

        <View style={styles.containerInput}>
          <FormikProvider value={formik}>
            <FarmForm
              formik={formik}
              validateName={validateName}
              onBlur={checkUniqueName}
            />
          </FormikProvider>
        </View>

        <UploadArea
          handleFile={handleFile}
          surfaces={surface}
          onRemoveFarmFile={onRemoveFarmFile}
          visible={visible}
        />

        <View style={styles.footer}>
          <LotsAddButton isValid={isValid} onGoFarmLotsAdd={onGoFarmLotsAdd} />

          <SaveDraftButton isValid={isValid} createFarmDraft={handleSubmit} />
        </View>
      </View>

      <ModalConfirm
        visible={isModalVisible}
        onClose={closeModal}
        onConfirm={closeModal}
        title={t('VIEWS').FARM_CREATE.TEXT_1}
        contentText={error?.message}
        titleTextStyle={styles.modalTitle}
        contentTextStyle={styles.modalContentTextStyle}
        confirmText={t('VIEWS').FARM_CREATE.TEXT_2}
        confirmButtonTextStyle={styles.confirmButtonTextStyle}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  containerInput: {
    marginTop: 20,
    marginBottom: 15,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 16,
    paddingBottom: 5,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  modalContentTextStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  confirmButtonTextStyle: {
    color: white,
    paddingRight: 8,
    paddingLeft: 8,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
  },
  CompanyDetails: {
    margin: 0,
  },
})

FarmCreate.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default FarmCreate
