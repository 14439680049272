import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  createRef,
} from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Platform,
  Text,
} from 'react-native'
import {
  ActivityIndicator,
  Colors,
  Subheading,
  Snackbar,
} from 'react-native-paper'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'

import { CommonIcon } from '@modules/common/components'
import CollaboratorCard from '@components/collaborators/CollaboratorCard'
import { AuthContext } from '@contextState/auth'
import { ROL, ROL_COLLABORATOR_DISABLED } from '@constants/roles'
import { cropEmpty } from '@constants/images'
import ButtonCustom from '@components/common/ButtonCustom'
import { useSelector } from 'react-redux'
import userTypes from '@constants/userTypes'
import VerifyPinModal from '@components/modals/VerifyPinModal'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'
import ConfirmUnlink from '@components/modals/collaborator/ConfirmUnlink'
import ModalCustom from '@components/common/ModalCustom'
import ActionSheetConfirmUnlink from '@components/modals/collaborator/ActionSheetConfirmUnlink'
import useNetwork from '@utils/network'
import { ModalDefault } from '@common/components'

function UnlinkCollaborator({ route, navigation }) {
  const { user } = useContext(AuthContext)
  const { doRequest } = useNetwork()
  const [currentCollaborator, setCurrentCollaborator] = useState({})
  const [unlinkCollaborator, setDelegateCollaborator] = useState({})
  const [selectedKey, setSelectedKey] = useState(null)
  const actionSheetConfirmUnlinkCollaborators = createRef()

  const { t } = useContext(LanguageContext)
  const [collaborators, setCollaborators] = useState([])
  const [isFetchingCrop, setIsFetchingCrop] = useState(false)

  const collaboratorsRedux = useSelector(
    (state) => state.collaborators.collaborators
  )
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const [visibleError, setVisibleError] = useState(false)
  const [selectCollaborator, setSelectCollaborator] = useState({})
  const [
    isActionSheetConfirmUnlinkCollaborators,
    setIsActionSheetConfirmUnlinkCollaborators,
  ] = useState(false)
  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    loadData()
  }, [])

  const loadData = useCallback(async () => {
    if (isFetchingCrop) {
      return
    }
    const { collaboratorsIds } = route.params
    setIsFetchingCrop(true)
    let currentUser = {}
    let toUnlink = {}
    let collaborators = collaboratorsRedux

    currentUser = collaborators.find(
      (member) => member.userId?._id === user._id
    )
    toUnlink = collaborators.find(
      (member) => member.userId?._id === collaboratorsIds
    )
    collaborators = collaborators.filter(
      (member) => member.userId?._id !== collaboratorsIds
    )

    setDelegateCollaborator(toUnlink)
    setCurrentCollaborator(currentUser)

    setCollaborators(collaborators)
    setIsFetchingCrop(false)
  }, [])

  const handlePressedItem = (collaborator) => {
    setSelectCollaborator(collaborator)
    setSelectedKey(collaborator.userId._id)
  }

  const setDisabled = (collaborator) =>
    currentCollaborator?.rol === collaborator?.rol
      ? false
      : ROL_COLLABORATOR_DISABLED[currentCollaborator?.rol][collaborator?.rol]

  const getTitleMenu = (title) =>
    title?.firstName ? `${title.firstName} ${title.lastName}` : `${title.email}`

  const setDisabledItem = (rol) => {
    if (
      unlinkCollaborator.rol === userTypes.KAM.key &&
      rol !== userTypes.KAM.key
    ) {
      return true
    }
    if (
      (unlinkCollaborator.rol === userTypes.MARKETER.key &&
        rol !== userTypes.MARKETER.key) ||
      (unlinkCollaborator.rol === userTypes.PROVIDER.key &&
        rol !== userTypes.PROVIDER.key)
    ) {
      return true
    }
    if (
      (unlinkCollaborator.rol === userTypes.PRODUCER.key ||
        unlinkCollaborator.rol === userTypes.PRODUCER_ADVISER.key ||
        unlinkCollaborator.rol === userTypes.PRODUCER_ADVISER_ENCOURAGED.key) &&
      (rol === userTypes.KAM.key ||
        rol === userTypes.MARKETER.key ||
        rol === userTypes.PROVIDER.key)
    ) {
      return true
    }

    return false
  }

  const RadioCheck = ({ id }) => (
    <View style={styles.containerRadioCheck}>
      {selectedKey === id ? (
        <CommonIcon name='SELECTED-FILLED' size={20} color='#2E7D32' />
      ) : (
        <CommonIcon name='CIRCLE' size={20} color='#DADADA' />
      )}
    </View>
  )

  if (isFetchingCrop) {
    return (
      <ActivityIndicator
        size='large'
        color={Colors.green500}
        style={styles.loader}
      />
    )
  }

  const confirmUnlink = () => {
    openConfirmUnlinkCollaborators()
  }

  const removeCollaboratorCrop = async () => {
    try {
      closeModal()

      const idCollaborator = unlinkCollaborator.userId._id
      const idCrop = {
        idCrop: route.params.cropId,
      }

      await doRequest({
        method: 'PUT',
        url: `collaborators/delegate-signs/current/${unlinkCollaborator.memberId}/new/${selectCollaborator.memberId}`,
      })

      await doRequest({
        method: 'PUT',
        url: `crops/unlink/${idCollaborator}`,
        data: idCrop,
      })

      if (idCollaborator === user._id) {
        navigation.navigate('CropList')
      } else {
        navigation.navigate('CropCollaborators', {
          id: route.params.cropId,
          status: 'delete',
        })
      }
    } catch (error) {
      closeModal()
      setVisibleError(true)
    }
  }

  const disableCollaborator = (collaborator) =>
    setDisabled(collaborator) || setDisabledItem(collaborator.rol)

  const titleCard = (collaborator) =>
    collaborator.userId._id === currentCollaborator.userId._id
      ? `${getTitleMenu(collaborator.userId)} ${
          t('VIEWS').CROP_COLLABORATOR.TEXT_21
        } `
      : `${getTitleMenu(collaborator.userId)}`

  const buttonLabelStyle = () => [
    styles.textButton,
    !selectedKey ? styles.textButtonDisabled : {},
  ]

  const openConfirmUnlinkCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetConfirmUnlinkCollaborators.current?.show()
      : setIsActionSheetConfirmUnlinkCollaborators(true)

  const closeConfirmUnlinkCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetConfirmUnlinkCollaborators.current?.hide()
      : setIsActionSheetConfirmUnlinkCollaborators(false)

  const removeUnlinkCollaborator = async () => {
    closeConfirmUnlinkCollaborators()

    if (verifyExpirationPin()) {
      setTimeout(toggleModal, 300)
    } else {
      const { validate } = await geTokenAuthPin()
      if (!validate) {
        setTimeout(toggleModal, 300)
        return
      }
      removeCollaboratorCrop()
    }
  }

  return (
    <View style={styles.flex}>
      {!collaborators.length ? (
        <View style={styles.container}>
          <Image source={cropEmpty} style={styles.emptyState} />
          <Subheading style={styles.emptyStateText}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_11}
          </Subheading>
        </View>
      ) : (
        <ScrollView>
          <View style={styles.scrollContainer}>
            <CollaboratorCard
              style={styles.unlinkCollaborator}
              collaborator={unlinkCollaborator}
              companyName={unlinkCollaborator.company.name}
              collaboratorType={unlinkCollaborator.rol}
              title={getTitleMenu(unlinkCollaborator.userId)}
              subtitle={ROL[unlinkCollaborator.rol](t) || ROL['DEFAULT'](t)}
              status={!!unlinkCollaborator.firstName}
              pendingValidation={unlinkCollaborator.pendingValidations.length}
            />
            {collaborators.map((collaborator, key) => {
              if (
                !ROL_COLLABORATOR_DISABLED[currentCollaborator.rol][
                  collaborator.rol
                ]
              ) {
                return (
                  <CollaboratorCard
                    key={key}
                    collaborator={collaborator}
                    onPress={() => handlePressedItem(collaborator)}
                    disabled={disableCollaborator(collaborator)}
                    companyName={collaborator.company.name}
                    collaboratorType={collaborator.rol}
                    title={titleCard(collaborator)}
                    subtitle={ROL[collaborator.rol](t) || ROL['DEFAULT'](t)}
                    status={!!collaborator.firstName}
                    pendingValidation={collaborator.pendingValidations.length}
                    right={<RadioCheck id={collaborator.userId._id} />}
                  />
                )
              }
            })}
          </View>
        </ScrollView>
      )}

      <View style={styles.containerButtonBottom}>
        <ButtonCustom
          disabled={!selectedKey}
          styles={
            !selectedKey ? styles.buttonDisabled : styles.confirmButtonStyle
          }
          labelStyle={buttonLabelStyle}
          onPress={confirmUnlink}
        >
          {t('VIEWS').CROP_COLLABORATOR.TEXT_27}
        </ButtonCustom>
      </View>

      <VerifyPinModal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={removeCollaboratorCrop}
      />

      <ModalDefault
        isModalVisible={isActionSheetConfirmUnlinkCollaborators}
        closeModal={() => setIsActionSheetConfirmUnlinkCollaborators(false)}
      >
        <ConfirmUnlink
          unlinkCollaborator={unlinkCollaborator}
          selectCollaborator={selectCollaborator}
          findCropCollaborator={collaborators}
          isFetchingCrop={isFetchingCrop}
          setDisabled={setDisabled}
          getTitleMenu={getTitleMenu}
          goToUnlinkCollaborators={removeUnlinkCollaborator}
          onClose={() => setIsActionSheetConfirmUnlinkCollaborators(false)}
        />
      </ModalDefault>

      <ActionSheetConfirmUnlink
        reference={actionSheetConfirmUnlinkCollaborators}
        unlinkCollaborator={unlinkCollaborator}
        findCropCollaborator={collaborators}
        selectCollaborator={selectCollaborator}
        isFetchingCrop={isFetchingCrop}
        setDisabled={setDisabled}
        getTitleMenu={getTitleMenu}
        goToUnlinkCollaborators={removeUnlinkCollaborator}
        onClose={() => actionSheetConfirmUnlinkCollaborators.current?.hide()}
      />

      <Snackbar
        visible={visibleError}
        duration={5000}
        onDismiss={() => {
          setVisibleError(false)
        }}
        style={styles.snackBarColorError}
      >
        <Text style={styles.snackText}>
          {t('VIEWS').CROP_COLLABORATOR.TEXT_31}
        </Text>
      </Snackbar>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerCard: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    alignItems: 'flex-start',
  },
  stylesCard: {
    alignItems: 'flex-start',
    paddingTop: 4,
    minHeight: 35,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    color: 'white',
    backgroundColor: '#4CAF50',
  },
  goToCrop: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    fontWeight: '700',
    fontStyle: 'normal',
    color: '#FFFFFF',
  },
  stylesSubTitleCard: {
    fontSize: 14,
    marginBottom: 8,
    marginRight: 8,
    color: '#9d9d9d',
    textAlign: 'justify',
  },
  scrollContainer: {
    padding: 16,
    flex: 1,
  },
  emptyStateText: {
    textAlign: 'center',
    marginTop: 16,
  },
  emptyState: {
    width: 203,
    height: 150,
    resizeMode: 'contain',
  },
  titleMenu: {
    fontSize: 14,
    fontWeight: '500',
  },
  actions: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: '-5%',
  },
  actionsWeb: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  unlinkMenu: {
    fontSize: 14,
    fontWeight: '500',
    color: '#FF7043',
  },
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  confirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  containerModal: {
    backgroundColor: 'white',
  },
  containerModalNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  titleModal: {
    color: '#333333',
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 18,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  containerButton: {
    paddingVertical: 15,
    marginTop: 15,
  },
  contentNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerActionSheet: {
    height: 370,
    marginHorizontal: 10,
  },
  button: {
    backgroundColor: '#FF7043',
  },
  buttonDisabled: {
    backgroundColor: '#FFAA90',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
  textButton: {
    color: 'white',
  },
  containerButtonBottom: {
    backgroundColor: 'white',
    padding: 16,
  },
  unlinkCollaborator: {
    backgroundColor: '#FBE9E7',
  },
  containerRadioCheck: {
    padding: 8,
  },
  snackBarColorError: {
    backgroundColor: '#FBE9E7',
    paddingHorizontal: 12,
  },
  snackText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'left',
    color: 'black',
  },
})

UnlinkCollaborator.propTypes = {
  route: PropTypes.object.isRequired,
}

export default UnlinkCollaborator
