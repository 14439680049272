import { useContext } from 'react'

import { AuthContext } from '@contextState/auth'

export const useUserAuth = () => {
  const { user } = useContext(AuthContext) as any

  return {
    ...user,
  }
}
