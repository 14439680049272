import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Animated,
  Platform,
} from 'react-native'
import { Input } from 'react-native-elements'
import { DatePickerModal } from 'react-native-paper-dates'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'
import momentUtils from '@date-io/moment'
import PropTypes from 'prop-types'

import { blackHighEmphasis, primary500, redError } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import useFadeAnimation from '@hooks/useFadeAnimation'
import useModal from '@hooks/useModal'
import { isEmptyValue } from '@utils/common'
import { formatDateMoment } from '@utils/date'

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4CAF50',
      contrastText: '#fff',
    },
  },
})

const InputDate = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  leftIcon = false,
  rightIcon = false,
  onChangeText,
  value,
  inputRef,
  editable = true,
  disabled = false,
  placeholder = '',
  label = '',
  disablePast = false,
  touched,
  error,
  minDate = undefined,
  maxDate = undefined,
  onBlur,
}) => {
  const { i18n, t } = useContext(LanguageContext)

  const [isFocused, setIsFocused] = useState(false)

  const fadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(fadeParams)

  const { isModalVisible, toggleModal, closeModal } = useModal()

  useEffect(() => {
    if (isEmptyValue(value.label)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [value.label])

  useEffect(() => {
    if (isModalVisible) {
      setIsFocused(true)
    } else {
      setIsFocused(false)
    }
  }, [isModalVisible])

  const onPress = () => {
    if (disabled || !editable) {
      return
    }

    toggleModal()
  }

  const selectDate = (date) => {
    closeModal()

    const valueDate = {
      value: date,
      label: formatDateMoment(date, 'DD/MMM/YYYY', i18n.locale, true),
    }

    onChangeText(valueDate)
  }

  const InputCustom = (params) => {
    return (
      <View style={containerStyle} {...params}>
        <Animated.View
          style={[
            styles.placeholderContainer,
            {
              opacity: placeholderOpacity,
            },
          ]}
        >
          <Text
            style={[
              styles.placeholder,
              touched && error ? styles.placeholderError : {},
              isFocused ? styles.focusedPlaceholder : {},
              disabled ? styles.placeholderDisabled : {},
            ]}
          >
            {label ?? placeholder}
          </Text>

          <View style={styles.placeholderBackground}></View>
        </Animated.View>

        {Platform.OS === 'web' ? (
          <Input
            id={id}
            name={name}
            inputContainerStyle={[
              styles.inputContainer,
              touched && error ? styles.borderError : {},
              isFocused ? styles.focusedInput : {},
            ]}
            inputStyle={[styles.input, inputStyle]}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            value={formatDateMoment(
              value.value,
              'DD/MMM/YYYY',
              i18n.locale,
              true
            )}
            ref={inputRef}
            editable={false}
            disabled={disabled}
            placeholder={placeholder}
            placeholderTextColor='rgba(51, 51, 51, 0.7)'
            errorStyle={styles.textError}
            errorMessage={touched && error}
          />
        ) : (
          <>
            <View
              style={[
                styles.textContainer,
                touched && error ? styles.borderError : {},
                isFocused ? styles.focusedInput : {},
              ]}
            >
              <Text
                style={[
                  inputStyle,
                  isEmptyValue(value.label)
                    ? styles.textPlaceholder
                    : styles.text,
                  disabled ? styles.placeholderDisabled : {},
                ]}
              >
                {isEmptyValue(value.label) ? label ?? placeholder : value.label}
              </Text>
            </View>

            {touched && error && (
              <Text style={styles.textErrorCustom}>{error}</Text>
            )}
          </>
        )}
      </View>
    )
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={momentUtils} locale={i18n.locale}>
            <DatePicker
              disablePast={disablePast}
              value={value.value}
              format='DD/MM/YYYY'
              onChange={(momentDate) => selectDate(momentDate.toDate())}
              readOnly={!editable}
              disabled={disabled}
              TextFieldComponent={InputCustom}
              minDate={minDate}
              maxDate={maxDate}
              onClose={onBlur}
              fullWidth
              autoOk
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ) : (
        <>
          <Pressable onPress={onPress}>
            <InputCustom />
          </Pressable>

          {isModalVisible && (
            <DatePickerModal
              locale={i18n.locale}
              mode='single'
              visible={isModalVisible}
              date={value.value}
              onConfirm={(value) => selectDate(value.date)}
              onDismiss={() => {
                closeModal()
                onBlur()
              }}
              validRange={{
                startDate: minDate,
                endDate: maxDate,
              }}
              saveLabel={t('COMPONENTS').INPUTS.INPUT_DATE.TEXT_1}
              label={t('COMPONENTS').INPUTS.INPUT_DATE.TEXT_2}
            />
          )}
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: 'rgba(0, 0, 0, .1)',
    borderRadius: 2,
    height: 62,
    paddingHorizontal: 16,
    marginHorizontal: -10,
    zIndex: 1,
  },
  borderError: {
    borderColor: redError,
  },
  textError: {
    color: redError,
    fontSize: 12,
  },
  textErrorCustom: {
    color: redError,
    fontSize: 12,
    marginTop: -14,
    marginBottom: 8,
    marginLeft: 16,
  },
  input: {
    fontSize: 15,
  },
  textContainer: {
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: 'rgba(0, 0, 0, .1)',
    borderRadius: 2,
    height: 62,
    marginBottom: 20,
    zIndex: 1,
  },
  text: {
    color: 'rgb(36, 36, 36)',
    paddingVertical: 20,
    paddingHorizontal: 16,
    fontSize: 15,
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  textPlaceholder: {
    color: 'rgba(51, 51, 51, 0.7)',
    fontWeight: '400',
    paddingVertical: 20,
    paddingHorizontal: 16,
    fontSize: 15,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: 'rgba(0, 0, 0, .4)',
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
  backScreen: {
    height: '100%',
    width: '100%',
  },
  modal: {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    width: '90%',
    maxHeight: 500,
    position: 'absolute',
  },
})

InputDate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    label: PropTypes.string,
  }),
  inputRef: PropTypes.object,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disablePast: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onBlur: PropTypes.func,
}

export default InputDate
