import { useEffect, useState } from 'react'

export const useShowSurface = (surface, hasSurface) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (hasSurface() || surface) {
      setVisible(true)
    }
    return () => {}
  }, [surface])

  return {
    visible,
    setVisible,
  }
}
