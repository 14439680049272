import React, { useContext } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { Title, Subheading, Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import UploadInput from '@components/common/UploadInput'
import ButtonCustom from '@components/common/ButtonCustom'
import { lotDefault } from '@constants/images'
import { KMZ_MIME } from '../../utils'
import {
  primary50,
  primary500,
  grey300,
  grey500,
  white,
  blackHighEmphasis,
} from '@styles/palette'
import { formatSurface } from '@modules/common/utils'

export const UploadArea = ({
  handleFile,
  surfaces,
  onRemoveFarmFile,
  visible,
}) => {
  const { t } = useContext(LanguageContext)
  const { image, surface, areaUnit } = surfaces

  return (
    <View style={styles.descriptionContainer}>
      <Title style={styles.descriptionTitle}>
        {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_1')}
      </Title>

      {!visible ? (
        <>
          <Subheading style={styles.description}>
            {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_2')}
          </Subheading>

          <View style={styles.containerUploadInput}>
            <ButtonCustom
              disabled={false}
              isLoading={false}
              styles={styles.uploadInputButton}
              labelStyle={styles.uploadInputButtonTitle}
            >
              {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_3')}
            </ButtonCustom>
            <UploadInput
              style={styles.uploadInput}
              handleFile={handleFile}
              accept={KMZ_MIME}
            ></UploadInput>
          </View>
        </>
      ) : (
        <>
          <Subheading style={styles.description}>
            {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_4')}
          </Subheading>

          <View style={styles.content}>
            <View style={styles.contentImage}>
              <Image
                source={{ uri: image || lotDefault }}
                style={styles.contentIcon}
              />
              <Text style={styles.contentText}>
                {formatSurface(surface, areaUnit)}
              </Text>
            </View>

            <Text style={styles.contentText} onPress={onRemoveFarmFile}>
              X
            </Text>
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  descriptionContainer: {
    borderRadius: 4,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 18,
    paddingRight: 18,
    backgroundColor: primary50,
  },
  descriptionTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  description: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: primary500,
    marginBottom: 20,
  },
  textButton: {
    color: white,
  },
  buttonDisabled: {
    textTransform: 'uppercase',
    backgroundColor: grey300,
    marginBottom: 20,
  },
  textButtonDisabled: {
    color: grey500,
  },
  containerUploadInput: {
    marginTop: 20,
    minHeight: 40,
  },
  uploadInputButton: {
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderWidth: 0,
    padding: 0,
    elevation: 0,
  },
  uploadInputButtonTitle: {
    color: primary500,
  },
  uploadInput: {
    height: 46,
    width: '100%',
    position: 'absolute',
  },
  content: {
    backgroundColor: white,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 10,
    paddingRight: 20,
  },
  contentImage: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentIcon: {
    width: 100,
    height: 56,
    marginRight: 20,
  },
  contentText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
})

UploadArea.propTypes = {
  handleFile: PropTypes.func.isRequired,
  surfaces: PropTypes.object.isRequired,
  onRemoveFarmFile: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}
