import React, { useContext, useState } from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import { primary500, white } from '@styles/palette'
import ItemCompany from './components/ItemCompany'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import useFetchPagination from '../../../../common/hooks/useFetchPagination'
import { TYPES_LIST_COMPANIES } from '@utils/constants'

const CompanyList = ({ route, navigation }) => {
  const { doRequest, loading } = useNetwork()
  const { config, handleUser, isCompanyUcropit, isVerifierUcropit, fetchUser } =
    useContext(AuthContext)
  const { isAdviser } = route?.params
  const companyRoute =
    !isAdviser && config.companyAdmin
      ? config.companyAdmin
      : config.companySelected
  const { t } = useContext(LanguageContext)
  const [companySelected, setCompanySelected] = useState(companyRoute)
  const responseCompanies = isAdviser
    ? TYPES_LIST_COMPANIES.ASSOCIATED
    : TYPES_LIST_COMPANIES.MANAGED

  const {
    response: { data },
    isLoading,
    nextPage,
    refreshPage,
  } = useFetchPagination({
    url: 'users/companies',
    version: 'v2',
    method: 'GET',
    params: {
      responseCompanies,
      created: Date.now(),
    },
  })

  const idCompanyAdmin = () =>
    config?.companyAdmin?._id || config?.companySelected?._id

  const onChangeCompany = async (company) => {
    const data = {
      companySelected: company._id,
      companyAdmin: isAdviser ? idCompanyAdmin() : company._id,
    }

    const configResponse = await doRequest({
      method: 'PUT',
      url: `configurations/${config._id}`,
      data,
    })
    await handleUser(configResponse.data)
    await fetchUser()

    navigation.navigate('CropList')
  }

  const handleChange = (company) => {
    setCompanySelected(company)
  }

  const goCompanyAdd = () => {
    navigation.navigate('CompanyAdd', {
      isAdviser,
    })
  }

  const FooterCompany = (
    <View style={styles.contentButton}>
      <ButtonPrimary
        text={t('VIEWS.COMPANY_LIST.TEXT_1')}
        backgroundColor={primary500}
        colorText={white}
        marginBottom={15}
        onPress={() => onChangeCompany(companySelected)}
        loading={loading}
      />
      <ButtonPrimary
        onPress={goCompanyAdd}
        text={
          isAdviser
            ? t('VIEWS.COMPANY_LIST.TEXT_2')
            : t('VIEWS.COMPANY_LIST.TEXT_3')
        }
        borderColor={primary500}
        disabled={(!isAdviser && isCompanyUcropit) || isVerifierUcropit}
      />
    </View>
  )

  return (
    <View style={styles.content}>
      <FlatList
        data={data}
        renderItem={({ item }) => (
          <ItemCompany
            name={item?.name}
            identifier={item?.identifier}
            isSelected={item._id === companySelected._id}
            onPress={() => {
              handleChange(item)
            }}
          />
        )}
        keyExtractor={(item) => {
          return item._id
        }}
        contentContainerStyle={styles.contentContainerStyle}
        style={styles.flatStyle}
        ListFooterComponentStyle={styles.listFooterComponent}
        onEndReachedThreshold={0.1}
        onEndReached={nextPage}
        onRefresh={refreshPage}
        refreshing={isLoading || loading}
      />
      {FooterCompany}
    </View>
  )
}
const styles = StyleSheet.create({
  content: {
    backgroundColor: white,
    flex: 1,
  },
  contentButton: {
    padding: 15,
  },
  flatStyle: {
    height: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  listFooterComponent: {
    flex: 1,
    justifyContent: 'flex-end',
    height: '100%',
  },
})

export default CompanyList
