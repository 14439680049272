export const tagsTypeAgreement = {
  SUSTAIN: 'SUSTAIN',
  EXPLO: 'EXPLO',
}

export const TYPE_UCROPIT = 'UCROPIT'
export const TYPE_VERIFIER = 'VERIFIERS'
export const TYPE_PRODUCER = 'PRODUCER'
export const ROLE_PRODUCER = 'PRODUCER'
export const ROLE_ADVISER = 'ADVISER'

export const VALID_FORMATS_FILES_IMAGES_PNG = 'png'
export const VALID_FORMATS_FILES_IMAGES_JPG = 'jpg'
export const VALID_FORMATS_FILES_DOCUMENTS = 'pdf'
export const VALID_DOCUMENTS = `text.*|image.*|application/pdf|zip.*|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document`
export const VALID_FORMATS_FILES = `application/vnd.google-earth.kmz`
export const typesActivities = [
  'ACT_SOWING',
  'ACT_FERTILIZATION',
  'ACT_APPLICATION',
  'ACT_HARVEST',
  'ACT_TILLAGE',
]
export const cropStages = [
  { label: 'FIRST', value: 'FIRST' },
  { label: 'SECOND', value: 'SECOND' },
]

export const typesActivitiesWithoutPlanning = [
  {
    name: 'Agreements',
    tag: 'agreement',
  },
  {
    name: 'Monitoring',
    tag: 'monitoring',
  },
  {
    name: 'Harvest',
    tag: 'harvest',
  },
]

export const typesSupplies = [
  {
    tag: 'ACT_SOWING',
    types: [
      '346564643430633836343130',
      '346564643430633836343230',
      '346564643430633836343231',
      '346564643430633836343232',
      '346564643430633836343233',
      '346564643430633836343330',
      '346564643430633836343331',
      '346564643430633836343332',
      '346564643430633836343333',
      '346564643430633836343334',
      '346564643430633836343335',
      '346564643430633836343336',
      '346564643430633836343337',
    ],
  },
  {
    tag: 'ACT_APPLICATION',
    types: [
      '346564643430633836343130',
      '346564643430633836343230',
      '346564643430633836343231',
      '346564643430633836343232',
      '346564643430633836343233',
    ],
  },
  {
    tag: 'ACT_FERTILIZATION',
    types: [
      '346564643430633836343130',
      '346564643430633836343230',
      '346564643430633836343231',
      '346564643430633836343232',
      '346564643430633836343233',
    ],
  },
  {
    tag: 'ACT_TILLAGE',
    types: [
      '346564643430633836343130',
      '346564643430633836343230',
      '346564643430633836343231',
      '346564643430633836343232',
      '346564643430633836343233',
    ],
  },
]

export const activitiesStatus = {
  pending: {
    es: 'COMPLETAR',
    en: 'COMPLETE',
    pt: 'COMPLETAR',
    key: 'pending',
  },
  toMake: {
    es: 'PLANIFICADA',
    en: 'PLANNED',
    pt: 'PLANEJADA',
    key: 'toMake',
  },
  done: {
    es: 'REALIZADA',
    en: 'CARRIED OUT',
    pt: 'REALIZADAS',
    key: 'done',
  },
  finished: {
    es: 'TERMINADA',
    en: 'FINISHED',
    pt: 'FINALIZADAS',
    key: 'finished',
  },
  inReview: {
    es: 'EN REVISION',
    en: 'IN REVIEW',
    pt: 'EM REVISÃO',
    key: 'inReview',
  },
}

export const errorMap = [
  {
    data: 'NOT_AUTHORIZATION_EXPORT',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_1,
  },
  {
    data: 'ERROR_KMZ_INVALID_FORMAT',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_2,
  },
  {
    data: 'ERROR_FILE_EXTENSION',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_3,
  },
  {
    data: 'ERROR_SERVICES_INTEGRATED',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_4,
  },
  {
    data: 'COMPANY_PREVIOUSLY_CREATED',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_5,
  },
  {
    data: 'COMPANY_NOT_FOUND',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_6,
  },
  {
    data: 'THE_LOT_HAS_NOT_BEEN_PREVIOUSLY_EXPORTED',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_7,
  },
  {
    data: 'ERROR_SENDING_DATA_TO_ERP_AGENT',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_8,
  },
  {
    data: 'INVALID_CREDENTIALS_OF_USER_AURAVANT',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_9,
  },
  {
    data: 'ERROR_KMZ_INVALID_FORMAT',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_10,
  },
  {
    data: 'NAME_LOT_DUPLICATED',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_11,
  },
  {
    data: 'REQUIRED_FIELDS',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_12,
  },
  {
    data: 'INVALID_DATE_CROP',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_13,
  },
  {
    data: 'INVALID_ARRAY_FILES',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_14,
  },
  {
    data: 'LOTS_NOT_AVAILABLE',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_15,
  },
  {
    data: 'INVALID_ARRAY_LOTS',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_16,
  },
  {
    data: 'INVALID_DATA_FIELD',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_17,
  },
  {
    data: 'DELETION_NOT_ALLOWED',
    message: (t) => t('ERRORS.GENERAL_ERRORS').TEXT_18,
  },
]

export const statusSubLicenses = {
  DISABLED: 'DISABLED',
  IS_APPLIED: 'IS_APPLIED',
}

export const unitTypesForModalStorage = ['tn', 'tn/ha', 't', 't/ac']

export const unitMeasureSystem = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
}

export const MODAL_ERRORS = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ERROR_UNEXPECTED: 'ERROR_UNEXPECTED',
  EXIST_VERIFICATION_DRAFT: 'EXIST_VERIFICATION_DRAFT',
  NO_LOTS_AVAILABLE: 'NO_LOTS_AVAILABLE',
}

export const TYPES_LIST_COMPANIES = {
  MANAGED: 'MANAGED',
  ASSOCIATED: 'ASSOCIATED',
}

export const COLLABORATORS_REQUEST_STATUS = {
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  REJECTED: 'rejected',
}

export const MIN_CONSIDERATION_LENGTH = 2
export const MAX_CONSIDERATION_LENGTH = 1200

export const WORK_ORDER_ANALYTICS_SHARE = 'SHARE'
export const WORK_ORDER_ANALYTICS_VIEW = 'VIEW'

export const errorsCognito = {
  USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
  USER_LAMBDA_VALIDATION_EXCEPTION: 'UserLambdaValidationException',
}

export const outlierLevels = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
}

export const badRequestCompanies = {
  INVALID_COMPANY: 'INVALID_COMPANY',
}

export const ABOUT_ACTIVITIES = [
  {
    KEY: 'DRAFT',
    TITLE: (t) =>
      t('ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.DRAFT.TITLE'),
    DESCRIPTION: (t) =>
      t('ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.DRAFT.DESCRIPTION'),
  },
  {
    KEY: 'PLANNED',
    TITLE: (t) =>
      t('ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.PLANNED.TITLE'),
    DESCRIPTION: (t) =>
      t(
        'ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.PLANNED.DESCRIPTION'
      ),
  },
  {
    KEY: 'DONE',
    TITLE: (t) =>
      t(
        'ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.PENDING_VALIDATION.TITLE'
      ),
    DESCRIPTION: (t) =>
      t(
        'ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.PENDING_VALIDATION.DESCRIPTION'
      ),
  },
  {
    KEY: 'FINISHED',
    TITLE: (t) =>
      t('ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.COMPLETED.TITLE'),
    DESCRIPTION: (t) =>
      t(
        'ACTIVITIES.EMPTY_STATE.COMPONENTS.ABOUT_ACTIVITIES.COMPLETED.DESCRIPTION'
      ),
  },
]
