import React, { useState, useEffect, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import useNetwork from '@utils/network'
import { ConnectionContext } from './connection'

export const EstablishmentContext = createContext({})

export const EstablishmentsProvider = ({ children }) => {
  const [establishments, setEstablishments] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [allEstablishmentSelected, setAllEstablishmentSelected] =
    useState(false)

  const { doRequest } = useNetwork()
  const { isConnected } = useContext(ConnectionContext)

  /**
   * LISTEN WHEN ESTABLISHMENTS CONTEXT CHANGE
   */
  useEffect(() => {
    if (!establishments.length) {
      return
    }

    const establishmentsQuantity = establishments.filter(
      (establishment) => !establishment.disabled
    ).length

    const establishmentsSelectedQuantity = establishments.filter(
      (establishment) => !establishment.disabled && establishment.selected
    ).length

    setAllEstablishmentSelected(
      establishmentsQuantity === establishmentsSelectedQuantity &&
        establishmentsQuantity !== 0
    )
  }, [establishments])

  /**
   * FETCH ESTABLISHMENTS
   *
   * @param {*} params
   */
  const fetchEstablishments = async (params) => {
    setIsloading(true)

    let data = []

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: 'lots/search-by-identifier',
        params,
        version: 'v2',
      })

      data = response.data
    }

    setEstablishments(data)

    setIsloading(false)
  }

  /**
   * DISPLAY/UNDISPLAY ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const displayEstablishmentToggle = async (establishment) => {
    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      return {
        ...element,
        display: !element.display,
      }
    })

    setEstablishments(newEstablishments)
  }

  /**
   * SELECT/UNSELECT ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const selectEstablishmentToggle = async (establishment) => {
    if (establishment.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? !element.selected
            : lotElement.selected,
        }
      })

      return {
        ...element,
        selected: !element.selected,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  /**
   * SELECT/UNSELECT ALL ESTABLISHMENTS
   */
  const selectAllEstablishmentsToggle = async () => {
    const establishmentsSelected = []

    const newEstablishments = establishments.map((element) => {
      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? !allEstablishmentSelected
            : lotElement.selected,
        }
      })

      if (element.disabled) {
        return element
      }

      establishmentsSelected.push(element)

      return {
        ...element,
        selected: !allEstablishmentSelected,
        lots: newLots,
      }
    })

    if (!establishmentsSelected.length) {
      return
    }

    setEstablishments(newEstablishments)
  }

  /**
   * SELECT/UNSELECT LOT
   *
   * @param {*} establishment
   * @param {*} lot
   */
  const selectLotToggle = async (establishment, lot) => {
    if (lot.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      const allLotSelected = []

      const newLots = element.lots.map((lotElement) => {
        if (lotElement._id !== lot._id) {
          if (lotElement.selected) {
            allLotSelected.push(lotElement)
          }

          return lotElement
        }

        if (!lotElement.selected) {
          allLotSelected.push(lotElement)
        }

        return {
          ...lotElement,
          selected: !lotElement.selected,
        }
      })

      return {
        ...element,
        selected:
          allLotSelected.length ===
          newLots.filter((lot) => !lot.disabled).length,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  /**
   * ADD KMZ ESTABLISHMENTS TO ESTABLISHMENTS CONTEXT
   *
   * @param {*} kmzEstablishments
   */
  const addKmzEstablishments = async (kmzEstablishments) => {
    setEstablishments([...kmzEstablishments, ...establishments])
  }

  /**
   * RESET DATA OF CONTEXT
   */
  const resetData = async () => {
    setEstablishments([])
    setAllEstablishmentSelected(false)
  }

  return (
    <EstablishmentContext.Provider
      value={{
        fetchEstablishments,
        establishments,
        isLoading,
        displayEstablishmentToggle,
        allEstablishmentSelected,
        selectEstablishmentToggle,
        selectAllEstablishmentsToggle,
        selectLotToggle,
        addKmzEstablishments,
        resetData,
      }}
    >
      {children}
    </EstablishmentContext.Provider>
  )
}

EstablishmentsProvider.propTypes = {
  children: PropTypes.object,
}
