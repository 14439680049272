import React, { useContext } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { FAB as Fab, Text, Colors, ActivityIndicator } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { activitiesStatus } from '@utils/constants'
import { CommonIcon } from '@modules/common/components'

import {
  blackHighEmphasis,
  blackMediumEmphasis,
  grayBlueLight,
  grayInput,
  LightSalmon,
  primary500,
  primary500Disabled,
  teal300,
  white,
  grayBackground,
} from '@styles/palette'
import { gray50 } from '@common/styles'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import AccordionItem from '@components/common/v2/AccordionItem'
import { getEIQRange } from '@utils/eiq'

import { FilterEmptyState, SnackbarLicensesPending } from './components'
import ActivityCardInfo from './components/ActivityCardInfo'
import { CropMenu } from '@modules/common/components'
import { EmptyState } from '@modules/activities/screens/activitiesList/v1/components'
import { CropDetails } from '@modules/common/components'

const Screen = ({
  activityTypes,
  agreementTypes,
  buildEstablishmentDraft,
  canWrite,
  closeModalNoLogerLots,
  closeModalValidate,
  companyAllow,
  crop,
  cropName,
  cropStatus,
  drafts,
  fabActions,
  goToDraft,
  handleCropMenu,
  handleFab,
  handleNav,
  hasFilter,
  isCropOffline,
  isModalNoLogerLotsVisible,
  isModalValidateVisible,
  isSnackbarLicensesPendingVisible,
  isVerifierUcropit,
  licensesPending,
  loading,
  onPressGoToLicenseDetails,
  open,
  snackbarText,
  subTypeActivities,
  unitTypes,
  userType,
  verificationTypes,
}) => {
  const { t } = useContext(LanguageContext)

  const showEmptyState = () =>
    !(
      drafts.length ||
      crop.toMake.length ||
      crop.done.length ||
      crop.finished.length
    )

  if (!crop && loading) {
    return (
      <View style={styles.indicatorContainer}>
        <ActivityIndicator animating color={Colors.green500} />
      </View>
    )
  }

  return (
    <View style={styles.containerScreen}>
      <SnackbarLicensesPending
        visible={isSnackbarLicensesPendingVisible}
        snackbarText={snackbarText}
        onPressGoToLicenseDetails={onPressGoToLicenseDetails}
        licensesPending={licensesPending}
      />

      <View style={styles.contentHeader}>
        <CropDetails
          cropTypeKey={crop?.cropType?.key}
          cropSurface={crop?.surface}
          areaUnit={crop?.areaUnit}
          cropName={cropName}
          companyName={crop?.company.name}
          RightComponent={() => (
            <CropMenu
              canWrite={canWrite}
              disabled={!companyAllow}
              isCropOffline={isCropOffline}
              status={cropStatus}
              onPress={handleCropMenu}
              showOptionDeleteCrop={false}
              showOptionEnableOrDisableOffline={false}
              hasLots={Boolean(
                crop?.establishments?.find((farm) =>
                  Boolean(farm?.lots?.length)
                )
              )}
              userType={userType}
            />
          )}
        />
      </View>

      {showEmptyState() ? (
        <EmptyState />
      ) : hasFilter ? (
        <FilterEmptyState />
      ) : (
        <ScrollView contentContainerStyle={styles.container}>
          <AccordionItem
            title={t('VIEWS').ACTIVITIES.TEXT_17}
            titleContainerStyle={styles.titleAccordionContainer}
            titleStyle={styles.titleAccordion}
            contentStyle={styles.contentAccordion}
            openByDefault
          >
            <View style={styles.section}>
              {drafts.map((element) => {
                element.establishments = element.lots
                  ? buildEstablishmentDraft(element.lots)
                  : []
                const eiqRange =
                  element.eiqRealized || element.eiqRealized === 0
                    ? getEIQRange(element.eiqRealized)
                    : undefined
                return (
                  <ActivityCardInfo
                    activity={element}
                    status='draft'
                    key={`activity-${element._id}`}
                    onPress={goToDraft}
                    agreementTypes={agreementTypes}
                    verificationTypes={verificationTypes}
                    activityTypes={activityTypes}
                    unitTypes={unitTypes}
                    subTypeActivities={subTypeActivities}
                    cropAreaUnit={crop?.areaUnit}
                    eiqRange={eiqRange}
                    hasEiqRealized
                  />
                )
              })}

              {!isVerifierUcropit && (
                <>
                  {crop.pending.map((item) => (
                    <ActivityCardInfo
                      activity={item}
                      status={activitiesStatus.pending.key}
                      key={`activity-${item._id}`}
                      onPress={handleNav}
                      agreementTypes={agreementTypes}
                      verificationTypes={verificationTypes}
                      activityTypes={activityTypes}
                      unitTypes={unitTypes}
                      subTypeActivities={subTypeActivities}
                      cropAreaUnit={crop?.areaUnit}
                    />
                  ))}

                  {!crop.pending.length && !drafts.length && (
                    <Text style={styles.noActivitiesFound}>{`0 ${
                      t('VIEWS').ACTIVITIES.TEXT_25
                    }`}</Text>
                  )}
                </>
              )}
            </View>
          </AccordionItem>

          <AccordionItem
            title={t('VIEWS').ACTIVITIES.TEXT_18}
            titleContainerStyle={styles.titleAccordionContainer}
            titleStyle={styles.titleAccordion}
            contentStyle={styles.contentAccordion}
            openByDefault
          >
            <View style={styles.section}>
              {crop.toMake.length ? (
                crop.toMake.map((item) => {
                  if (!item.establishments) {
                    item.establishments = item.lots
                      ? buildEstablishmentDraft(item.lots)
                      : []
                  }
                  const eiqRange = item.eiqPlanned
                    ? getEIQRange(item.eiqPlanned)
                    : undefined
                  return (
                    <ActivityCardInfo
                      activity={item}
                      status={activitiesStatus.toMake.key}
                      key={`toMake-${item._id}`}
                      eiqRange={eiqRange}
                      onPress={handleNav}
                      agreementTypes={agreementTypes}
                      verificationTypes={verificationTypes}
                      activityTypes={activityTypes}
                      unitTypes={unitTypes}
                      subTypeActivities={subTypeActivities}
                      cropAreaUnit={crop?.areaUnit}
                    />
                  )
                })
              ) : (
                <Text style={styles.noActivitiesFound}>{`0 ${
                  t('VIEWS').ACTIVITIES.TEXT_25
                }`}</Text>
              )}
            </View>
          </AccordionItem>

          <AccordionItem
            title={t('VIEWS').ACTIVITIES.TEXT_20}
            titleContainerStyle={styles.titleAccordionContainer}
            titleStyle={styles.titleAccordion}
            contentStyle={styles.contentAccordion}
            openByDefault
          >
            <View style={styles.section}>
              {crop.done.length ? (
                crop.done.map((item) => {
                  const eiqRange =
                    item.eiqRealized || item.eiqRealized === 0
                      ? getEIQRange(item.eiqRealized)
                      : undefined
                  return (
                    <ActivityCardInfo
                      activity={item}
                      status={activitiesStatus.done.key}
                      key={`done-${item._id}`}
                      onPress={handleNav}
                      eiqRange={eiqRange}
                      agreementTypes={agreementTypes}
                      verificationTypes={verificationTypes}
                      activityTypes={activityTypes}
                      unitTypes={unitTypes}
                      subTypeActivities={subTypeActivities}
                      cropAreaUnit={crop?.areaUnit}
                      hasEiqRealized
                    />
                  )
                })
              ) : (
                <Text style={styles.noActivitiesFound}>{`0 ${
                  t('VIEWS').ACTIVITIES.TEXT_25
                }`}</Text>
              )}
            </View>
          </AccordionItem>

          <AccordionItem
            title={t('VIEWS').ACTIVITIES.TEXT_23}
            titleContainerStyle={styles.titleAccordionContainer}
            titleStyle={styles.titleAccordion}
            contentStyle={styles.contentAccordion}
            openByDefault
          >
            <View style={styles.section}>
              {crop.finished.length ? (
                crop.finished.map((item) => {
                  const eiqRange =
                    item.eiqRealized || item.eiqRealized === 0
                      ? getEIQRange(item.eiqRealized)
                      : undefined
                  return (
                    <ActivityCardInfo
                      activity={item}
                      status={activitiesStatus.finished.key}
                      key={`finished-${item._id}`}
                      onPress={handleNav}
                      eiqRange={eiqRange}
                      agreementTypes={agreementTypes}
                      verificationTypes={verificationTypes}
                      activityTypes={activityTypes}
                      unitTypes={unitTypes}
                      subTypeActivities={subTypeActivities}
                      cropAreaUnit={crop?.areaUnit}
                      hasEiqRealized
                    />
                  )
                })
              ) : (
                <Text style={styles.noActivitiesFound}>{`0 ${
                  t('VIEWS').ACTIVITIES.TEXT_25
                }`}</Text>
              )}
            </View>
          </AccordionItem>
        </ScrollView>
      )}

      {!hasFilter && (
        <Fab.Group
          open={open}
          color='white'
          icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
          style={styles.textWhite}
          actions={fabActions}
          onStateChange={handleFab}
          fabStyle={styles.buttonFab}
          theme={{ colors: { text: blackHighEmphasis }, dark: true }}
        />
      )}

      <ModalConfirm
        visible={isModalValidateVisible}
        onConfirm={closeModalValidate}
        onClose={closeModalValidate}
        title={t('VIEWS').ACTIVITIES.MODAL_VALIDATE.TEXT_1}
        contentText={t('VIEWS').ACTIVITIES.MODAL_VALIDATE.TEXT_2}
        confirmText={t('VIEWS').ACTIVITIES.MODAL_VALIDATE.TEXT_3}
      />

      <ModalConfirm
        visible={isModalNoLogerLotsVisible}
        onConfirm={closeModalNoLogerLots}
        onClose={closeModalNoLogerLots}
        title={t('VIEWS').ACTIVITIES.MODAL_NO_LOGER_LOTS.TEXT_1}
        contentText={t('VIEWS').ACTIVITIES.MODAL_NO_LOGER_LOTS.TEXT_2}
        confirmText={t('VIEWS').ACTIVITIES.MODAL_NO_LOGER_LOTS.TEXT_3}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerScreen: {
    backgroundColor: gray50,
    flex: 1,
  },
  container: {
    paddingBottom: 10,
  },
  textWhite: {
    color: white,
  },
  buttonFab: {
    backgroundColor: primary500,
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    paddingHorizontal: 10,
  },
  title: {
    marginTop: 8,
    fontSize: 16,
  },
  cardContainer: {
    backgroundColor: white,
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 2,
    marginBottom: 10,
  },
  cardTitle: {
    fontSize: 16,
  },
  eiqContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 32,
    paddingHorizontal: 5,
    borderRadius: 100,
    marginTop: 10,
    marginBottom: 10,
    paddingRight: 20,
  },
  eiqText: {
    fontSize: 12,
    fontWeight: '700',
  },
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: LightSalmon,
    paddingVertical: 7.5,
    marginRight: 10,
    width: 160,
  },
  statusVerified: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: primary500Disabled,
    paddingVertical: 7.5,
    paddingHorizontal: 7.5,
    marginRight: 10,
    minWidth: 78,
  },
  statusNoCompliant: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: LightSalmon,
    paddingVertical: 7.5,
    paddingHorizontal: 7.5,
    marginRight: 10,
    minWidth: 91,
  },
  activityStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: grayBlueLight,
    paddingVertical: 7.5,
    marginRight: 10,
    width: 115,
  },
  extraStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    backgroundColor: grayBlueLight,
    paddingVertical: 7.5,
    marginRight: 10,
    width: 115,
  },
  statusText: {
    color: white,
    textTransform: 'capitalize',
  },
  noActivitiesFound: {
    fontSize: 16,
    fontWeight: '400',
    fontStyle: 'italic',
    color: blackHighEmphasis,
  },
  textActivityToMakeCard: {
    color: grayInput,
    marginBottom: 4,
  },
  subtextSurface: {
    color: grayInput,
    paddingRight: 5,
    marginBottom: 5,
  },
  draftContainer: {
    flexDirection: 'column',
    backgroundColor: white,
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 2,
    marginBottom: 10,
  },
  draftContainerVersioned: {
    backgroundColor: '#FBE9E7',
  },
  draftTitle: {
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  draftLastDateUpdated: {
    color: primary500,
    fontSize: 12,
    fontWeight: '400',
    marginBottom: 5,
  },
  draftTextLots: {
    color: '#919191',
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 5,
  },
  draftText: {
    color: blackHighEmphasis,
    fontSize: 14,
    fontWeight: '400',
  },
  draftChip: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#90A4AE',
    borderRadius: 100,
    paddingHorizontal: 7.5,
    paddingVertical: 7.5,
    marginTop: 10,
    width: 100,
  },
  draftChipVerification: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: teal300,
    borderRadius: 100,
    paddingHorizontal: 7.5,
    paddingVertical: 7.5,
    marginTop: 10,
    width: 110,
  },
  draftChipVersioned: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#90A4AE',
    borderRadius: 100,
    paddingHorizontal: 7.5,
    paddingVertical: 7.5,
    marginTop: 10,
    marginLeft: 'auto',
    minWidth: 135,
  },
  draftChipText: {
    color: white,
    fontSize: 12,
    fontWeight: '400',
  },
  titleAccordionContainer: {
    paddingHorizontal: 20,
    borderWidth: 0,
  },
  titleAccordion: {
    fontSize: 16,
    color: blackMediumEmphasis,
    lineHeight: 22,
  },
  contentAccordion: {
    backgroundColor: grayBackground,
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
    marginHorizontal: 16,
  },
})

Screen.propTypes = {
  crop: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  isSnackbarLicensesPendingVisible: PropTypes.bool.isRequired,
  snackbarText: PropTypes.string.isRequired,
  onPressGoToLicenseDetails: PropTypes.func.isRequired,
  licensesPending: PropTypes.array.isRequired,
  cropName: PropTypes.string,
  drafts: PropTypes.array.isRequired,
  goToDraft: PropTypes.func.isRequired,
  isVerifierUcropit: PropTypes.bool.isRequired,
  handleNav: PropTypes.func.isRequired,
  fabActions: PropTypes.array.isRequired,
  handleFab: PropTypes.func.isRequired,
  isModalValidateVisible: PropTypes.bool.isRequired,
  closeModalValidate: PropTypes.func.isRequired,
  isModalNoLogerLotsVisible: PropTypes.bool.isRequired,
  closeModalNoLogerLots: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
}

export default Screen
