import React, { useContext } from 'react'
import {
  View,
  Text,
  Modal as ModalMobile,
  StyleSheet,
  Platform,
  SafeAreaView,
} from 'react-native'
import PropTypes from 'prop-types'
import ModalWeb from 'modal-react-native-web'

import { CommonIcon } from '@modules/common/components'
import ButtonPrimary from '../buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { white, primary500Disabled, greenOpacity50 } from '@styles/palette'

const SuccessPinModal = ({
  isModalVisible,
  children,
  title = '¡Licencia Firmada!',
  toggleModal,
}) => {
  const Modal = Platform.OS === 'web' ? ModalWeb : ModalMobile
  const { t } = useContext(LanguageContext)

  return (
    <Modal
      animationType='slide'
      transparent={false}
      visible={isModalVisible}
      onBackButtonPress={toggleModal}
      ariaHideApp={false}
    >
      <SafeAreaView style={styles.contentPin}>
        <View style={styles.contentText}>
          <Text style={styles.textStyle}>{title}</Text>
        </View>
        <View style={styles.contentIcon}>
          <View style={styles.viewIcon}>
            <CommonIcon name={'CHECK'} size={50} color={white} />
          </View>
        </View>
        {children || (
          <View style={styles.contentInfo}>
            <View style={styles.contentTextInfo}>
              <Text style={styles.textInfo}>
                {t('COMPONENTS').MODAL.SUCCES_PIN.TEXT_1}
              </Text>
            </View>
            <View style={styles.contentButtons}>
              <ButtonPrimary text={t('COMPONENTS').MODAL.SUCCES_PIN.TEXT_2} />
              <ButtonPrimary
                marginTop={0}
                text={t('COMPONENTS').MODAL.SUCCES_PIN.TEXT_3}
                backgroundColor='transparent'
                colorText={white}
                fontSize={14}
              />
            </View>
          </View>
        )}
      </SafeAreaView>
    </Modal>
  )
}

const styles = StyleSheet.create({
  contentPin: {
    flex: 1,
    backgroundColor: primary500Disabled,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  contentText: {
    marginBottom: 61,
    alignItems: 'center',
  },
  textStyle: {
    color: white,
    fontSize: 34,
    fontWeight: '500',
  },
  contentIcon: {
    backgroundColor: greenOpacity50,
    width: 137,
    height: 137,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewIcon: {
    width: 97,
    height: 97,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: greenOpacity50,
    borderRadius: 100,
  },
  contentInfo: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: white,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: 0.15,
  },
  contentButtons: {
    marginTop: 61,
    width: 300,
  },
})

SuccessPinModal.propTypes = {
  title: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  children: PropTypes.element,
  toggleModal: PropTypes.func.isRequired,
}

export default SuccessPinModal
