import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

export const ModalSuccessChildren = ({ onGoFarmList }) => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.container}>
      <Text style={[styles.successText, styles.successTitleText]}>
        {t('VIEWS.FARM_LOTS_ADD.COMPONENTS.MODAL_SUCCESS.TEXT_1')}
      </Text>
      <Text style={[styles.successText, styles.successSubText]}>
        {t('VIEWS.FARM_LOTS_ADD.COMPONENTS.MODAL_SUCCESS.TEXT_2')}
      </Text>
      <View style={styles.footer}>
        <ButtonCustom
          styles={styles.button}
          labelStyle={styles.textButton}
          onPress={onGoFarmList}
        >
          {t('VIEWS.FARM_LOTS_ADD.COMPONENTS.MODAL_SUCCESS.TEXT_3')}
        </ButtonCustom>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  successTitleText: {
    fontSize: 34,
    fontWeight: '600',
    lineHeight: 40,
    letterSpacing: -0.5,
    marginBottom: 30,
    marginTop: 70,
  },
  successText: {
    color: white,
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  successSubText: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 21,
    letterSpacing: -0.5,
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: white,
    marginVertical: 10,
  },
  textButton: {
    fontSize: 14,
    fontWeight: '700',
    color: primary500,
    lineHeight: 16,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 32,
    paddingBottom: 5,
  },
})

ModalSuccessChildren.propTypes = {
  onGoFarmList: PropTypes.func.isRequired,
}
