import * as SQLite from 'expo-sqlite'
import { Platform } from 'react-native'
const getDatabase = (name) => {
  try {
    if (Platform.OS === 'web') {
      const databaseIsSupported = typeof window.openDatabase === 'function'
      if (!databaseIsSupported) {
        return null
      }
    }
    return SQLite.openDatabase(name)
  } catch (err) {
    console.warn('######## OPEN DATABASE ########')
    console.warn('Database is not supported')
    return null
  }
}

export default getDatabase
