import React from 'react'
import PropTypes from 'prop-types'
import { Image, StyleSheet, Text, View } from 'react-native'

import SiloImage from '@icon/silo.png'

export const Storage = ({ storages, areaUnitVolume, contentStyle = {} }) => {
  const renderStorage = () => {
    return storages.map((storage, key) => (
      <View style={styles.content} key={key}>
        <Image source={SiloImage} style={styles.contentIcon} />

        <Text style={styles.contentText}>
          {`${storage.tonsHarvest} ${areaUnitVolume} ${storage.storageTypeName}`}
        </Text>
      </View>
    ))
  }

  return <View style={contentStyle}>{renderStorage()}</View>
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  contentIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  contentText: {
    fontSize: 15,
  },
})

Storage.prototype = {
  storages: PropTypes.array.isRequired,
  areaUnitVolume: PropTypes.string,
  contentStyle: PropTypes.object,
}
