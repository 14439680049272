import { verificationTypes as type } from '../types'

const initialState = {
  verificationTypesForm: [],
  verificationTypesData: [],
  verificationSelected: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_VERIFICATION_TYPES: {
      const { verificationTypesData = [], draftsByCrop = [] } = action.payload
      const verificationTypesForm = verificationTypesData
        .filter((element) => !element?.isAllLotsVerified)
        .map((element) => {
          const draftInVerification = draftsByCrop?.find(
            (draft) => draft.verificationType._id === element._id
          )
          return {
            label: element.codeLabel,
            value: element._id,
            disabled: Boolean(draftInVerification),
          }
        })
      return {
        verificationTypesForm,
        verificationTypesData,
      }
    }
    case type.SET_VERIFICATION_SELECTED: {
      const verificationSelected = state.verificationTypesData.find(
        (element) => element._id === action.payload.value
      )
      return { ...state, verificationSelected }
    }
    case type.CLEAR_VERIFICATION_TYPES: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
