export const parseDataToSaveDraft = (values) => {
  const dataToReturn = {
    id: values.draftId ?? undefined,
    crop: values.cropId,
    activity: values.activityId ?? undefined,
    achievement: values.achievementId ?? undefined,
    company: values.company ?? undefined,
    tag: values.tag,
    lots: values.lots.value,
    surface: values.surface,
    dateHarvest: values.dateHarvest?.value,
    dateObservation: values.dateObservation?.value,
    dateEstimatedHarvest: values.dateEstimatedHarvest?.value,
    unitType: values.unitType?.value,
    typeAgreement: values.typeAgreement?.value,
    verificationType: values.verificationType?.value,
    volume: values?.volume,
    unitVolume: values?.unitVolume?.value,
    pay: values.pay,
    observation: values.observation,
    observations: values.observations?.length ? values.observations : [],
    storages: values.storages?.length ? values.storages : [],
    evidences: values.evidences?.length ? values.evidences : [],
    signers: values.signers?.length ? values.signers : [],
    companyIdentifier: values.companyIdentifier,
    draftGroupId: values.draftGroupId,
    isRejected: values.isRejected,
    areaUnit: values.areaUnit,
    subTypeActivity: values.subTypeActivity?.value,
    dateAchievement: values.dateAchievement?.value,
    supplies: values.supplies?.length
      ? values.supplies?.map((element) => ({
          ...element,
          typeId: element.typeId?._id ?? element.typeId,
          supply: element.supplyId || element.supply || element._id,
          typeSupply:
            typeof element.typeId === 'object'
              ? element?.typeId
              : element?.typeSupply,
        }))
      : [],
    type: values.type,
    isDirectAchievement: values.isDirectAchievement,
    eiqRealized: values.eiqRealized,
    canPlanning: values.canPlanning,
    farm: values.farm ?? undefined,
    seedDryContent: values.seedDryContent ?? undefined,
  }

  return dataToReturn
}
