import React, { useContext } from 'react'
import { StyleSheet, Animated, Text, View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { onPressedColor, blackHighEmphasis } from '@styles/palette'
import { roundSurface } from '@utils'

export const AccordionHeader = ({
  arrowAngle,
  onPress,
  otherParams: { name, surface, areaUnit, fieldsLength, realized },
  isOpened,
}) => {
  const { t } = useContext(LanguageContext)

  const wordLot = fieldsLength === 1 ? 'FIELD' : 'FIELDS'

  return (
    <TouchableRipple
      onPress={onPress}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
      style={styles.headerTextContainer}
    >
      <>
        <View style={{ flex: 1 }}>
          <Text style={styles.headerTitle} numberOfLines={1}>
            {`${name} ${roundSurface(surface)} ${areaUnit} ${
              realized
                ? t(
                    `VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.ACCORDION_HEADER.REALIZED`
                  )
                : ''
            }`}
          </Text>

          {!isOpened && (
            <Text style={styles.headerSubtitle} numberOfLines={1}>
              {fieldsLength}{' '}
              {t(`VIEWS.CROP_STORY_LOT_DETAIL.COMPONENTS.FARM_LIST.${wordLot}`)}
            </Text>
          )}
        </View>

        <Animated.View
          style={[
            styles.headerAccordionRightIcon,
            { transform: [{ rotateZ: arrowAngle }] },
          ]}
        >
          <CommonIcon name='CHEVRON-DOWN' size={25} color={blackHighEmphasis} />
        </Animated.View>
      </>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  headerTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingTop: 5,
  },
  headerTitle: {
    flex: 1,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    color: blackHighEmphasis,
  },
  headerSubtitle: {
    flex: 1,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: blackHighEmphasis,
  },
  headerAccordionRightIcon: {
    marginLeft: 10,
  },
  hide: {
    display: 'none',
  },
})

AccordionHeader.prototype = {
  arrowAngle: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  otherParams: PropTypes.object.isRequired,
}
