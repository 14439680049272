import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { blackHighEmphasis, primary50 } from '@styles/palette'

export const Alert = ({ title, message }) => {
  return (
    <View style={styles.container}>
      {Boolean(title) && <Text style={styles.title}>{title}</Text>}
      <Text style={styles.message}>{message}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: primary50,
    paddingVertical: 12,
    paddingHorizontal: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 13,
    fontWeight: '600',
    color: blackHighEmphasis,
    lineHeight: 24,
  },
  message: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 18,
  },
})

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
}
