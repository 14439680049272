import React, { useContext, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Divider, List } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import useOfflineQueue from '../offline/queries/queue'
import DialogCustom from '@components/common/DialogCustom'
import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'

function SetupScreen({ navigation }) {
  const { doRequest } = useNetwork()
  const { dropAllQueue } = useOfflineQueue()
  const [visible, setVisible] = useState(false)
  const { isConnected } = useContext(ConnectionContext)
  const { config } = useContext(AuthContext)
  const [loadingReset, setLoadingReset] = useState(false)
  const { t } = useContext(LanguageContext)

  function handleAlert() {
    if (isConnected) {
      setVisible(true)
    } else {
      alert(t('VIEWS').SETUP.TEXT_1)
    }
  }

  async function handleDone() {
    setLoadingReset(true)
    await dropAllQueue()

    await doRequest({
      method: 'POST',
      url: `offline/crops/reset?identifier=${config.companySelected?.identifier}`,
    })

    setLoadingReset(false)
    setVisible(false)
  }

  return (
    <View style={styles.container}>
      <DialogCustom
        visible={visible}
        title={t('VIEWS').SETUP.TEXT_2}
        content={t('VIEWS').SETUP.TEXT_3}
        labelCancel={t('VIEWS').SETUP.TEXT_4}
        loading={loadingReset}
        labelLoading={t('VIEWS').SETUP.TEXT_5}
        labelDone={t('VIEWS').SETUP.TEXT_6}
        onDone={handleDone}
        dismissable={false}
        toggleDialog={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      />
      <List.Item
        onPress={() => navigation.navigate('OfflineQueue')}
        description={t('VIEWS').SETUP.TEXT_7}
        left={() => (
          <List.Icon icon={() => <CommonIcon name='SYNC' size={20} />} />
        )}
        right={() => (
          <List.Icon
            icon={() => <CommonIcon name='CHEVRON-RIGHT' size={20} />}
          />
        )}
        title={t('VIEWS').SETUP.TEXT_8}
        subtitle={t('VIEWS').SETUP.TEXT_9}
      />
      <Divider />
      <List.Item
        onPress={handleAlert}
        left={() => (
          <List.Icon icon={() => <CommonIcon name='DELETE' size={20} />} />
        )}
        title={t('VIEWS').SETUP.TEXT_10}
        subtitle={t('VIEWS').SETUP.TEXT_11}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
})

export default SetupScreen
