import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  BackHandler,
  Platform,
} from 'react-native'
import { ActivityIndicator, Colors } from 'react-native-paper'
import { openURL } from 'expo-linking'
import PropTypes from 'prop-types'

import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import LotDetailCard from '@components/binnacle/LotDetailCard'
import ActivityDetailCard from '@components/binnacle/ActivityDetailCard'
import CardCropDetail from '@components/binnacle/CardCropDetail'
import CardCropBadgesDetail from '@components/binnacle/CardCropBadgesDetail'
import CardContact from '@components/common/CardContact'
import SectionLandscapeSustainability from '@components/binnacle/SectionLandscapeSustainability'
import { useFocusEffect } from '@react-navigation/native'
import ListLicenses from './components/ListLicenses'
import { LanguageContext } from '@contextState/language'

import VerifiedStatusText from './components/VerifiedStatusText'
import SectionVerified from './components/SectionVerified'

import AgreementsDetails from './components/AgreementsDetails'
import activityTypes from '@constants/activityTypes'

const FIELD_STORY_URL = process.env.FIELD_STORY_URL
const FIELD_STORY_URL_MOBILE = process.env.EXPO_HOST

const CropDetail = ({ navigation, route }) => {
  const { t, i18n } = useContext(LanguageContext)
  const { doRequest } = useNetwork({})
  const [crop, setCrop] = useState(null)
  const [establishments, setEstablishments] = useState([])
  const [cropActivities, setCropActivities] = useState([])
  const [histories, setHistories] = useState({})
  const { isConnected } = useContext(ConnectionContext)
  const {
    user,
    config: { companySelected },
  } = useContext(AuthContext)

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        navigation.popToTop()
        return true
      }

      BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () =>
        BackHandler.removeEventListener('hardwareBackPress', onBackPress)
    }, [])
  )

  useEffect(() => {
    const id = route.params.id
    fetchEstablishments(id)
    fetchActivities(id)
  }, [i18n.locale])

  const fetchEstablishments = useCallback(async (id) => {
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${id}/histories`,
      })
      setCrop(response.data)
      setHistories(response.data)
      setEstablishments(response.data.lotsGroupByTag)
    }
  }, [])

  const fetchActivities = useCallback(
    async (id) => {
      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          url: `crops/${id}/activities`,
        })

        if (!response.data) return

        setCropActivities(response.data)
      }
    },
    [i18n.locale]
  )

  const onGoToLandscapeSustainability = ({
    _id: lotId,
    landscapeSustainability,
  }) => {
    if (!landscapeSustainability) {
      return
    }

    const urlFieldStory =
      Platform.OS === 'web'
        ? FIELD_STORY_URL
        : `${FIELD_STORY_URL_MOBILE}/crop-story`

    const url = `${urlFieldStory}/field-story?lotId=${lotId}&cropId=${route.params.id}&taxID=${companySelected.identifier}`

    if (Platform.OS === 'web') {
      window.open(url, '_blank')
    } else {
      openURL(url)
    }
  }

  const onGoToLicenseDetails = ({ licenseId }) => {
    const paramsToSend = {
      cropId: route.params.id,
      licenseId: licenseId,
      isReadOnly: true,
    }

    navigation.navigate('LicenseDetails', paramsToSend)
  }

  if (!crop || !user || !establishments.length) {
    return (
      <View style={styles.indicatorContainer}>
        <ActivityIndicator animating color={Colors.green500} />
      </View>
    )
  }

  const landscapeSustainability = histories.landscapeSustainability
  const normativeGeneral = landscapeSustainability.find(
    (element) => element.generalCountryNormative === true
  )
  const otherNormative = landscapeSustainability.filter(
    (element) => element.generalCountryNormative !== true
  )

  const listAppliedLicenses = histories.listAppliedLicenses ?? []

  const verificationActivities =
    cropActivities.filter(
      (activity) => activity.tag === activityTypes.ACT_VERIFICATION.key
    ) ?? []
  const agreementsActivities =
    cropActivities?.filter(
      (activity) => activity.tag === activityTypes.ACT_AGREEMENTS.key
    ) ?? []

  const activitiesWithOutAgreementsAndVerifications =
    cropActivities?.filter(
      (activity) =>
        activity.tag !== activityTypes.ACT_AGREEMENTS.key &&
        activity.tag !== activityTypes.ACT_VERIFICATION.key
    ) ?? []

  return (
    <ScrollView style={styles.container}>
      <CardCropDetail crop={{ ...crop, verified: histories?.verified }} />

      {histories?.cropStatusVerified && <VerifiedStatusText t={t} />}

      <CardCropBadgesDetail
        badges={crop?.badges}
        areaUnitCrop={crop?.areaUnit ?? ''}
      />

      {landscapeSustainability &&
        histories?.isEnabledShowLandScapeSustainability && (
          <SectionLandscapeSustainability
            totals={normativeGeneral?.totals}
            datasets={normativeGeneral?.datasets}
            otherNormative={otherNormative}
            crop={crop}
            areaUnit={crop?.areaUnit ?? ''}
          />
        )}

      <CardContact crop={crop} />

      <LotDetailCard
        establishments={establishments}
        onGoToLandscapeSustainability={onGoToLandscapeSustainability}
        areaUnit={crop?.areaUnit ?? ''}
      />

      {!!listAppliedLicenses.length && (
        <ListLicenses
          licenses={listAppliedLicenses}
          onGoToLicenseDetails={onGoToLicenseDetails}
          t={t}
        />
      )}

      {Boolean(verificationActivities.length) && (
        <SectionVerified t={t} activities={verificationActivities} />
      )}

      <ActivityDetailCard
        areaUnitVolume={crop?.areaUnitVolume}
        cropActivities={activitiesWithOutAgreementsAndVerifications}
      />

      {Boolean(agreementsActivities.length) && (
        <AgreementsDetails t={t} activities={agreementsActivities} />
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  title: {
    marginTop: 8,
    fontSize: 16,
  },
  cardContent: {
    padding: 0,
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    padding: 0,
    margin: 16,
    marginTop: 0,
    marginBottom: 8,
  },
  container: {
    backgroundColor: 'white',
    flex: 1,
    paddingVertical: 8,
  },
})

CropDetail.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CropDetail
