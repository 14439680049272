import React, { useContext } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { Title, Subheading, Text } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import UploadInput from '@components/common/UploadInput'
import ButtonCustom from '@components/common/ButtonCustom'
import { lotDefault } from '@constants/images'
import { KMZ_MIME } from '@modules/farms/screens/FarmCreate/v1/utils'
import {
  primary100,
  primary500,
  grey300,
  grey500,
  white,
  blackHighEmphasis,
  carbon800,
} from '@styles/palette'
import { DownloadReport } from '../DonwloadReport'
import useModal from '@hooks/useModal'
import { AuthContext } from '@contextState/auth'

export const UploadArea = ({
  handleFile,
  surface,
  contentStyle = {},
  image = lotDefault,
  visible,
  farmId,
  onPress,
}) => {
  const { t } = useContext(LanguageContext)
  const { isModalVisible, closeModal, toggleModal } = useModal()
  const { config, isCompanyUcropit, isVerifierUcropit } =
    useContext(AuthContext)
  const isUcropit = isCompanyUcropit || isVerifierUcropit
  const verifyIsAdviser =
    isUcropit &&
    config?.companyAdmin &&
    config?.companyAdmin?._id !== config?.companySelected?._id

  return (
    <View style={[styles.descriptionContainer, contentStyle]}>
      <Title style={styles.descriptionTitle}>
        {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_1')}
      </Title>

      {!visible ? (
        <>
          <Subheading style={styles.description}>
            {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_2')}
          </Subheading>

          <View style={styles.containerUploadInput}>
            <ButtonCustom
              disabled={false}
              isLoading={false}
              styles={styles.uploadInputButton}
              labelStyle={styles.uploadInputButtonTitle}
            >
              {t('VIEWS.FARM_CREATE.COMPONENTS.UPLOAD_AREA.TEXT_3')}
            </ButtonCustom>
            <UploadInput
              style={styles.uploadInput}
              handleFile={handleFile}
              accept={KMZ_MIME}
            ></UploadInput>
          </View>
        </>
      ) : (
        <>
          <TouchableOpacity style={styles.surfaceInfo} onPress={onPress}>
            <View style={styles.contentImage}>
              <Image source={{ uri: image }} style={styles.contentIcon} />
              <Text style={styles.contentText}>{surface}</Text>
            </View>
          </TouchableOpacity>
          {verifyIsAdviser && (
            <DownloadReport
              isModalVisible={isModalVisible}
              closeModal={closeModal}
              toggleModal={toggleModal}
              farmId={farmId}
            />
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  descriptionContainer: {
    borderRadius: 4,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 18,
    paddingRight: 18,
    backgroundColor: primary100,
    marginHorizontal: 20.5,
  },
  descriptionTitle: {
    color: carbon800,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
    marginVertical: 0,
  },
  description: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: primary500,
    marginBottom: 20,
  },
  textButton: {
    color: white,
  },
  buttonDisabled: {
    textTransform: 'uppercase',
    backgroundColor: grey300,
    marginBottom: 20,
  },
  textButtonDisabled: {
    color: grey500,
  },
  containerUploadInput: {
    marginTop: 20,
    minHeight: 40,
  },
  uploadInputButton: {
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderWidth: 0,
    padding: 0,
    elevation: 0,
  },
  uploadInputButtonTitle: {
    color: primary500,
  },
  uploadInput: {
    height: 46,
    width: '100%',
    position: 'absolute',
  },
  surfaceInfo: {
    backgroundColor: white,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 8,
    padding: 10,
    paddingRight: 20,
    borderRadius: 4,
  },
  contentImage: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentIcon: {
    width: 100,
    height: 56,
    marginRight: 16,
  },
  contentText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    lineHeight: 24,
    letterSpacing: 0.15,
  },
})

UploadArea.propTypes = {
  handleFile: PropTypes.func.isRequired,
  surface: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentStyle: PropTypes.object,
  image: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}
