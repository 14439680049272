import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { StyleSheet, ScrollView, View, Platform } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { useFormik, FormikProvider } from 'formik'
import * as Yup from 'yup'

import { Layout } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { AuthContext } from '@contextState/auth'
import { EstablishmentContext } from '@contextState/establishment'
import { CropForm } from './components'
import ButtonCustom from '@components/common/ButtonCustom'
import DialogCustom from '@components/common/DialogCustom'
import SnackbarCustom from '@components/common/SnackbarCustom'
import { CompanyDetails } from '@common/components'
import useNetwork from '@utils/network'
import { draftCropActions } from '@store/actions'
import { primary500 } from '@styles/palette'
import { selectDraftCrop } from '@store/selectors/draftCrops'
import { formatDateMoment } from '@utils/date'
import { useStandardDeviation, useChangeOutliersHarvest } from '@common/hooks'

const CropCreate = ({ navigation, route }) => {
  const { isDraft } = useRef(route.params).current || { isDraft: null }

  const { t, i18n } = useContext(LanguageContext)
  const { cropTypes } = useContext(CommonContext)
  const { config, isCompanyUcropit, user } = useContext(AuthContext)
  const { fetchEstablishments, resetData } = useContext(EstablishmentContext)

  const currentUserIsAdminOfCompany = Boolean(
    user?.companies.find(
      (company) =>
        company?.company?._id === config?.companySelected?._id &&
        company?.isAdmin
    )
  )

  const initialFormData = {
    country: {
      value: currentUserIsAdminOfCompany
        ? config?.companySelected?.country?._id
        : '',
      label: currentUserIsAdminOfCompany
        ? config?.companySelected?.country?.name
        : '',
    },
    identifier: currentUserIsAdminOfCompany
      ? config?.companySelected?.identifier
      : '',
    cropType: {
      value: '',
      label: '',
    },
    stage: {
      value: '',
      label: '',
    },
    dateCrop: {
      value: '',
      label: '',
    },
    dateHarvest: {
      value: '',
      label: '',
    },
    unitType: {
      value: '',
      label: '',
    },
    pay: '',
    surface: '',
    name: '',
  }

  const [initialValues, setInitialValues] = useState(initialFormData)
  const [isViewReady, setIsViewReady] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(false)
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
  const [message, setMessage] = useState(t('VIEWS').CROP_CREATE.TEXT_4)

  const { doRequest } = useNetwork()
  const dispatch = useDispatch()
  const valuesForm = useSelector(
    selectDraftCrop(config.companySelected?.identifier)
  )
  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const validationSchema = Yup.object().shape({
    cropType: Yup.object()
      .shape({
        value: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    unitType: Yup.object()
      .shape({
        value: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required()
      .required(t('ERRORS').FORM_ERRORS.INVALID_FORMAT)
      .nullable(),
    pay: Yup.number()
      .typeError(t('ERRORS').FORM_ERRORS.INVALID_FORMAT)
      .required(t('ERRORS').FORM_ERRORS.REQUIRED),
    name: Yup.string(),
    dateCrop: Yup.object({
      value: Yup.date()
        .typeError(t('ERRORS').FORM_ERRORS.INVALID_FORMAT)
        .required(t('ERRORS').FORM_ERRORS.REQUIRED),
    }).required(),
    dateHarvest: Yup.object({
      value: Yup.date()
        .required(t('ERRORS').FORM_ERRORS.REQUIRED)
        .typeError(t('ERRORS').FORM_ERRORS.INVALID_FORMAT),
    }).required(),
    identifier: Yup.string()
      .required(t('ERRORS').FORM_ERRORS.REQUIRED)
      .typeError(t('ERRORS').FORM_ERRORS.INVALID_FORMAT),
    country: Yup.object()
      .shape({
        value: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })

  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleChange,
    setFieldTouched,
    isValid,
    dirty,
    validateForm,
    resetForm,
  } = formik

  useFocusEffect(
    useCallback(() => {
      validateForm()
    }, [])
  )

  useFocusEffect(
    useCallback(() => {
      const cropType = valuesForm?.cropType.key
      const unitType = valuesForm?.unitType.key
      const pay = valuesForm?.pay
      if (cropType && unitType && pay) {
        fetchOutlierRequest(cropType, pay, unitType)
      }
    }, [])
  )

  useFocusEffect(
    useCallback(() => {
      fetchInitData()
    }, [])
  )

  useEffect(() => {
    if (!isViewReady) {
      return
    }

    setIsSubmitButtonAvailable(isValid)
  }, [isValid])

  useEffect(() => {
    if (!dirty) {
      return
    }

    const dateCrop = values.dateCrop.value
      ? formatDateMoment(values.dateCrop.value, 'MMM/YYYY', i18n.locale, true)
      : ''
    const dateHarvest = values.dateHarvest.value
      ? formatDateMoment(
          values.dateHarvest.value,
          'MMM/YYYY',
          i18n.locale,
          true
        )
      : ''
    const unitSurface = values?.unitType?.key?.split('/')[1] ?? ''
    setFieldValue(
      'name',
      `${values?.cropType?.label || 'Tipo'} - ${values.surface || 0} ${
        values?.dateCrop?.value && values?.dateHarvest?.value
          ? `${unitSurface} - ${dateCrop} ${t(
              'VIEWS.ACTIVITIES.TEXT_62'
            )} ${dateHarvest}`
          : ''
      }`
    )

    setFieldValue('pay', String(values.pay))
  }, [dirty, values, cropTypes])

  useEffect(() => {
    if (!dirty) {
      return
    }
    dispatch(
      draftCropActions.setDraftCrop({
        ...values,
        idCompany: config.companySelected.identifier,
      })
    )
  }, [dirty, values])

  const fetchInitData = async () => {
    setIsViewReady(false)
    if (isDraft) {
      restoreDraft()
    } else if (valuesForm) {
      handleDialog()
    } else {
      await setDefaultData()
    }

    setIsViewReady(true)
  }

  const fetchOutlierRequest = async (cropType, pay, unit) => {
    try {
      await changeOutLierHarvest(pay, unit, cropType)
    } catch (error) {
      console.error(`Error request outlier: ${error}`)
    }
  }

  const setDefaultData = async () => {
    const userCountry = await fetchUserCountry()

    const newFormikInitialValues = {
      ...initialFormData,
      country: {
        label: userCountry.name,
        value: userCountry._id,
      },
      stage: {
        label: '',
        value: '',
      },
      identifier:
        config.companySelected?.identifier || initialFormData.identifier,
    }

    setInitialValues(newFormikInitialValues)

    resetForm({
      values: newFormikInitialValues,
    })

    setTimeout(validateForm)
  }

  const fetchUserCountry = async () => {
    const response = await doRequest({
      method: 'GET',
      url: `companies?identifier=${config.companySelected?.identifier}`,
    })

    return response.data[0].country
  }

  const doFetchEstablishments = async (
    dateCrop,
    identifier = config.companySelected?.identifier
  ) => {
    const params = {
      identifier,
      dateCrop,
    }

    fetchEstablishments(params)
  }

  const restoreDraft = () => {
    setInitialValues(valuesForm)

    resetForm({
      values: valuesForm,
    })

    setTimeout(validateForm)

    setShowDialog(false)
  }

  const clearDraft = async () => {
    dispatch(
      draftCropActions.clearDraftCrop(config.companySelected?.identifier)
    )

    setDefaultData()

    setShowDialog(false)

    setIsViewReady(false)

    const userCountry = await fetchUserCountry()

    const newFormikInitialValues = {
      ...initialFormData,
      country: {
        label: userCountry.name,
        value: userCountry._id,
      },
      identifier:
        config.companySelected?.identifier || initialFormData.identifier,
    }

    setInitialValues(newFormikInitialValues)

    resetForm({
      values: newFormikInitialValues,
    })

    setTimeout(validateForm)

    setIsViewReady(true)
  }

  const onChangePayHarvest = async (value) => {
    const unit = values.unitType.key
    const pay = value
    const cropType = values.cropType.key

    if (unit && cropType && pay) {
      await fetchOutlierRequest(cropType, pay, unit)
    }
  }

  const onChangeUnitType = async (value) => {
    const unit = value.key
    const pay = values.pay
    const cropType = values.cropType.key

    if (unit && cropType && pay) {
      await fetchOutlierRequest(cropType, pay, unit)
    }
  }

  const onSubmit = async () => {
    if (isSubmitButtonLoading) {
      return
    }
    setIsSubmitButtonLoading(true)

    let response, responseError, url

    values.companyExists = null
    try {
      if (isCompanyUcropit) {
        url = `companies/exists?identifier=${values.identifier}&countryId=${values.country.value}`
      } else {
        url = `companies?identifier=${config.companySelected?.identifier}`
      }

      response = await doRequest({
        method: 'GET',
        url,
        displayAlert: false,
      })
    } catch (err) {
      responseError = err
    }

    if (isCompanyUcropit) {
      if (response) {
        values.companyExists = response.data ? response.data._id : false
      }

      if (values.companyExists) {
        const collaborators = [
          {
            identifier: config.companyAdmin.identifier,
            type: 'KAM',
            email: user.email,
            owner: false,
          },
        ]
        doFetchEstablishments(values.dateCrop.value, values.identifier)

        dispatch(draftCropActions.setCompanyId(values.companyExists))
        dispatch(draftCropActions.setDraftCollaborators(collaborators))

        navigation.navigate('CropSelectResponsible', {
          identifier: values.identifier,
        })
      } else {
        if (responseError) {
          const { code, message: err } = responseError
          switch (code) {
            case 'NOT_FOUND':
              setMessage(err)
              setShowMessage(true)
              break
            case 'USER_NOT_ALLOW_HIGH_CROP':
            case 'COMPANY_NOT_ALLOW_HIGH_CROP':
              setMessage(err)
              setShowMessage(true)
              break
          }
        }
      }
    } else {
      if (response) {
        values.companyExists =
          response.data.length > 0 ? response.data[0]._id : false
      }

      if (!values.companyExists) {
        return setShowMessage(true)
      }

      resetData()

      doFetchEstablishments(values.dateCrop.value, values.identifier)

      navigation.navigate('LotsAdd', {
        identifier: values.identifier,
      })
    }

    setIsSubmitButtonLoading(false)
  }

  const handleDialog = () => {
    setShowDialog(!showDialog)
  }

  if (!isViewReady) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator animating size='large' color={primary500} />
      </View>
    )
  }

  return (
    <Layout>
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.formContentContainer}
          showsVerticalScrollIndicator={Platform.OS === 'web'}
        >
          <CompanyDetails
            companyName={config?.companySelected?.name}
            companyIdentifier={config?.companySelected?.identifier}
            style={styles.companyDetails}
          />

          <FormikProvider value={formik}>
            <CropForm
              currentUserIsAdminOfCompany={currentUserIsAdminOfCompany}
              isCompanyUcropit={isCompanyUcropit}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              valuesForm={valuesForm}
              formIsValid={isValid}
              canShowOutlier={canShowOutlierHarvest}
              isValueOutsideMean={isValueOutsideMeanHarvest}
              statusOutlier={statusOutlier}
              onChangePayHarvest={onChangePayHarvest}
              onChangeUnitType={onChangeUnitType}
              openBackdropStandardDeviation={openBackdropStandardDeviation}
            />
          </FormikProvider>
        </ScrollView>

        <ButtonCustom
          disabled={!isSubmitButtonAvailable}
          isLoading={isSubmitButtonLoading}
          onPress={onSubmit}
          styles={[
            styles.button,
            !isSubmitButtonAvailable ? styles.buttonDisabled : {},
          ]}
          labelStyle={[
            styles.textButton,
            !isSubmitButtonAvailable ? styles.textButtonDisabled : {},
          ]}
        >
          {t('VIEWS').CROP_CREATE.TEXT_3}
        </ButtonCustom>
      </View>

      <ComponentStandarDeviation />

      <DialogCustom
        visible={showDialog}
        toggleDialog={handleDialog}
        title={t('VIEWS.CROP_CREATE.MODAL_1.TEXT_1')}
        content={t('VIEWS.CROP_CREATE.MODAL_1.TEXT_2')}
        labelDone={t('VIEWS.CROP_CREATE.MODAL_1.TEXT_3')}
        labelCancel={t('VIEWS.CROP_CREATE.MODAL_1.TEXT_4')}
        onDone={restoreDraft}
        onCancel={clearDraft}
      />

      <SnackbarCustom
        onToggle={() => setShowMessage(!showMessage)}
        visible={showMessage}
      >
        {message}
      </SnackbarCustom>
    </Layout>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    flex: 1,
    color: '#F2F2F2',
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
  formContentContainer: {
    paddingBottom: 10,
  },
  companyDetails: {
    margin: 0,
    marginBottom: 16,
  },
  button: {
    backgroundColor: primary500,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
})

export default CropCreate
