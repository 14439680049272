import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { Text, Modal, Portal } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { red200, red300, red400, white } from '@styles/palette'

export const ModalError = ({
  title,
  description,
  visible,
  children = null,
}) => {
  return (
    <Portal>
      <Modal visible={visible} contentContainerStyle={styles.modal}>
        <View style={styles.container}>
          <Text style={[styles.errorText, styles.errorTitleText]}>{title}</Text>

          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <CommonIcon name={'X'} size={45} color={white} />
            </View>
          </View>
          {description && (
            <Text style={[styles.errorText, styles.errorSubText]}>
              {description}
            </Text>
          )}
          <View style={styles.errorAction}>{children}</View>
        </View>
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: red200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorTitleText: {
    fontSize: 30,
    lineHeight: 40,
    letterSpacing: -0.5,
    marginBottom: 10,
  },
  errorText: {
    color: white,
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  errorSubText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: -0.5,
    marginHorizontal: 50,
    marginTop: 20,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 171,
    height: 171,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 100,
    backgroundColor: red300,
  },
  errorAction: {
    marginTop: 20,
  },
  button: {
    width: 131,
    height: 131,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 100,
    backgroundColor: red400,
    marginVertical: 30,
  },
})

ModalError.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.object,
}
