import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { List, Divider } from 'react-native-paper'
import { Image } from 'react-native'
import integrations from '@constants/integrations'
import { LanguageContext } from '@contextState/language'

function IntegrationsList({ list, isIntegrated, onPress }) {
  const { t } = useContext(LanguageContext)

  function imageSelection(service) {
    let image
    switch (service) {
      case 'auravant':
        image = integrations.auravant
        break

      default:
        image = integrations.auravant
    }
    return image
  }

  function renderAvailableServices(list) {
    if (list.length > 0) {
      return list.map((el, key) => (
        <>
          <List.Item
            key={key}
            title={el.label ? el.label : el.service}
            right={() => (
              <Image
                style={{
                  width: 50,
                  height: 50,
                }}
                source={imageSelection(el.label ? el.label : el.service)}
              />
            )}
            onPress={() => onPress(isIntegrated, el.service || el.label)}
          />
          <React.Fragment>
            <Divider />
          </React.Fragment>
        </>
      ))
    } else {
      return (
        <>
          <List.Item title={t('COMPONENTS').INTEGRATION.TEXT_1} />
          <React.Fragment>
            <Divider />
          </React.Fragment>
        </>
      )
    }
  }

  if (!list) return null
  return renderAvailableServices(list)
}

IntegrationsList.propTypes = {
  list: PropTypes.array,
  isIntegrated: PropTypes.bool,
  onPress: PropTypes.func,
}

export default IntegrationsList
