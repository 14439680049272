import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import { activitiesActions } from '@store/actions'
import { LanguageContext } from '@contextState/language'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { gray600, gray900 } from '@common/styles'

export const FilterEmptyState = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const { t } = useContext(LanguageContext)

  const onGoToFiltersActivities = () => {
    navigation.navigate('FilterActivities')
  }

  const onReturnToActivities = () => {
    dispatch(activitiesActions.removeFilters())
  }

  return (
    <View style={styles.flex}>
      <View style={[styles.flex, styles.containerText]}>
        <Text style={styles.title}>
          {t(
            'ACTIVITIES.EMPTY_STATE.COMPONENTS.FILTER_EMPTY_STATE.THERE_ARE_NO_ACTIVITIES_MATCHING'
          )}
        </Text>
        <Text style={styles.text}>
          {t(
            'ACTIVITIES.EMPTY_STATE.COMPONENTS.FILTER_EMPTY_STATE.TRY_MODIFY_CURRENT_FILTERS'
          )}
        </Text>
      </View>

      <View style={styles.contentBottom}>
        <ButtonDefault
          typeButton={'primary'}
          text={t(
            'ACTIVITIES.EMPTY_STATE.COMPONENTS.FILTER_EMPTY_STATE.MODIFY_FILTERS'
          )}
          onPress={onGoToFiltersActivities}
        />

        <ButtonDefault
          typeButton={'outline_success'}
          text={t(
            'ACTIVITIES.EMPTY_STATE.COMPONENTS.FILTER_EMPTY_STATE.BACK_ACTIVITIES'
          )}
          onPress={onReturnToActivities}
          marginTop={8}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  contentBottom: {
    flexDirection: 'column',
    marginHorizontal: 8,
  },
  containerText: {
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: 20,
    lineHeight: 30,
    textAlign: 'center',
    color: gray900,
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: gray600,
  },
})
