import { useState, useCallback, useContext } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import useNetwork from '@utils/network'
import { getFieldDetailsDTO } from '../utils'

export const useFetchData = ({ cropId, fieldId }) => {
  const { t } = useContext(LanguageContext)

  const { doRequest } = useNetwork()

  const [fieldDetails, setFieldDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  const fetchData = async () => {
    setIsLoading(true)

    const requestData = {
      method: 'GET',
      url: `crops/${cropId}/fields/${fieldId}`,
      displayAlert: false,
      version: 'v2',
    }

    let response

    try {
      response = await doRequest(requestData)
    } catch (error) {
      console.error(error)

      return
    }

    const fieldDetailsDTO = getFieldDetailsDTO({
      t,
      ...response.data,
    })

    setFieldDetails(fieldDetailsDTO)

    setIsLoading(false)
  }

  return {
    isLoading,
    fieldDetails,
  }
}

useFetchData.propTypes = {
  cropId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
}
