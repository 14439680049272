import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { roundNumber } from '@utils'
import { blackMediumEmphasis } from '@styles/palette'
import { formatSurface } from '@common/utils'

export const InfoDisplayActivity = ({ activity, areaUnit, t, haveEiq }) => {
  const getQuantitySupply = (supply) =>
    `${roundNumber(supply.quantity, 3)} ${
      supply?.supply?.unitTypeSupply?.keyLabel ?? supply?.unitName ?? ''
    }/${activity?.areaUnit || areaUnit} ${supply.total} ${
      supply?.supply?.unitTypeSupply?.keyLabel ?? supply?.unitName ?? ''
    } ${haveEiq ? `- EIQ ${roundNumber(supply.eiqTotal || 0)}` : ''}`
  const renderSupply = (supply, key) => {
    return (
      <View key={`SUPPLY_RENDER_-${key}`}>
        <Text style={styles.textTitleSupply}>
          {supply.brand || supply.name}
        </Text>
        <Text style={styles.textSupply}>{getQuantitySupply(supply)}</Text>
      </View>
    )
  }
  const getTextLots = (lots) =>
    lots.map(
      (lot) =>
        `${lot.name} (${formatSurface(lot.surface, lot?.areaUnit ?? areaUnit)})`
    )

  const renderLots = (establishment) => {
    return (
      <View>
        <Text style={styles.textName}>
          {t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_1')}
        </Text>
        <Text>
          {`${establishment.tag} (${formatSurface(
            establishment.surface,
            establishment?.areaUnit ?? areaUnit
          )})`}
        </Text>
        <Text style={styles.textName}>
          {t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_4')}
        </Text>
        <Text>{getTextLots(establishment.lots).join(', ')}</Text>
      </View>
    )
  }
  return (
    <View style={styles.contentAdditionalInfo}>
      {Boolean(activity?.supplies?.length) && (
        <>
          <Text style={[styles.textSupply, styles.marginTitle]}>
            {t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_5')}
          </Text>
          {activity?.supplies.map((supply, key) => renderSupply(supply, key))}
        </>
      )}

      {activity?.establishments?.map((establishment) => (
        <View key={`establishment-${establishment._id}`}>
          {renderLots(establishment)}
        </View>
      ))}
    </View>
  )
}
const styles = StyleSheet.create({
  contentAdditionalInfo: {
    padding: 10,
  },
  textTitleSupply: {
    color: blackMediumEmphasis,
    fontWeight: 'bold',
  },
  textSupply: {
    color: blackMediumEmphasis,
  },
  marginTitle: {
    marginTop: 15,
    marginBottom: 25,
  },
  textName: {
    color: blackMediumEmphasis,
    marginVertical: 10,
  },
})

InfoDisplayActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  areaUnit: PropTypes.string,
  t: PropTypes.func.isRequired,
}
