import React, { useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { getFormikInitialValues, getFormikValidationSchema } from './utils'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { white, blackMediumEmphasis } from '@styles/palette'
import ButtonDefault from '@common/components/buttons/ButtonDefault'

import AsyncStorage from '@react-native-async-storage/async-storage'
import useNetwork from '@utils/network.js'
import useOfflineCrops from '@offline/queries/crops'
import useOfflineCommon from '@offline/queries/common'

import CodeForm from './components/CodeForm'
import { authActions } from '../../../../store/actions'

const EXTRA_HEIGHT = 50
const VerifyCode = ({ navigation }) => {
  const { t, T } = useContext(LanguageContext)
  const dispatch = useDispatch()

  const { setAuth, fetchUser } = useContext(AuthContext)

  const { insertCrop } = useOfflineCrops()
  const { storeAtIndividuals } = useOfflineCommon()

  const [initialValues, setInitialValues] = useState(
    getFormikInitialValues({
      code: '',
      email: '',
    })
  )

  const { doRequest } = useNetwork()

  useEffect(() => {
    getEmail()
  }, [])

  const getEmail = async () => {
    const email = await AsyncStorage.getItem('REGISTER_EMAIL')

    if (!email) {
      navigation.goBack()
    } else {
      setInitialValues({
        ...initialValues,
        email,
      })
    }
  }

  async function onSubmit({ code, email }, { setSubmitting }) {
    try {
      const response = await doRequest({
        needAuthorizationHeaders: false,
        method: 'POST',
        url: 'auth/validate',
        data: { code, email },
      })

      const { user, token } = response.data

      await AsyncStorage.setItem('USER_DATA', JSON.stringify(user))

      await AsyncStorage.setItem('USER_TOKEN', token)

      const currentUser = await fetchUser()

      setSubmitting(false)

      setAuth(true)

      AsyncStorage.removeItem('REGISTER_EMAIL')
      dispatch(authActions.setEmailWelcomeCompanyList({ email, hide: false }))

      if (Platform.OS !== 'web' && currentUser.config.companySelected) {
        const identifier = currentUser.config.companySelected.identifier
        const params = {
          identifier,
          createdAt: Date.now(),
        }

        let crops = []

        try {
          const { data } = await doRequest({
            method: 'GET',
            url: 'crops/offline',
            params,
            displayAlert: false,
          })

          crops = data
        } catch (error) {
          console.warn(error)
        }

        for (const crop of crops) {
          const [errorInsertCrop] = await insertCrop(crop, identifier)

          if (errorInsertCrop) {
            console.warn('errorInsertCrop')
            console.warn(errorInsertCrop)

            continue
          }

          try {
            await storeAtIndividuals('crops', crop._id, {
              companyId: currentUser.config.companySelected._id,
            })
          } catch (error) {
            console.warn(error)
          }
        }
      }
    } catch (error) {
      if (error.status === 401) {
        setFieldError('code', t('VIEWS.VERIFY_CODE_SCREEN.CODE_INCORRECT'))
        setSubmitting(false)
      }
      console.warn(error)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, { setSubmitting })
    },
  })

  const { dirty, isValid, isSubmitting, setFieldError, handleSubmit } = formik

  return (
    <View style={styles.main}>
      <KeyboardAwareScrollView
        extraHeight={EXTRA_HEIGHT}
        enableOnAndroid
        contentContainerStyle={styles.contentContainerStyle}
        behavior={'padding'}
      >
        <View style={styles.formContainer}>
          <View>
            <Text style={styles.title}>
              {t('VIEWS.VERIFY_CODE_SCREEN.TITLE')}
            </Text>
            <Text style={styles.description}>
              <T
                id='VIEWS.VERIFY_CODE_SCREEN.DESCRIPTION'
                values={{
                  email: initialValues.email,
                }}
                components={[
                  {
                    component: Text,
                    props: {
                      style: styles.parragraphBold,
                    },
                  },
                ]}
              />
            </Text>
            <View style={styles.inputContainer}>
              <CodeForm formik={formik} t={t} />
            </View>
            <Text style={styles.reminder}>
              {t('VIEWS.VERIFY_CODE_SCREEN.REMINDER')}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <ButtonDefault
              typeButton={
                dirty && isValid && !isSubmitting ? 'primary' : 'disabled'
              }
              text={t('VIEWS.VERIFY_CODE_SCREEN.ACCESS')}
              marginBottom={32}
              onPress={handleSubmit}
              disabled={!(dirty && isValid)}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 16,
    paddingTop: 30,
    backgroundColor: white,
    flex: 1,
  },
  contentContainerStyle: { flex: 1 },
  formContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 28,
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
    marginTop: 16,
  },
  inputContainer: {
    marginTop: 28,
    minHeight: 97,
  },
  reminder: {
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
  },
  textButton: {
    lineHeight: 16,
    letterSpacing: 0.75,
  },
  parragraphBold: {
    fontWeight: '700',
  },
})

export default VerifyCode
