import React, { useState, useEffect, useRef } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Animated,
  Platform,
} from 'react-native'
import { Input } from 'react-native-elements'
import PropTypes from 'prop-types'
import {
  blackHighEmphasis,
  primary500,
  redError,
  borderInput,
  white,
  placeholderDisabled,
  placeholderColor,
} from '@styles/palette'
import useFadeAnimation from '@hooks/useFadeAnimation'
import { isEmptyValue } from '@utils/common'
import { keyboardTypeParseNumberDecimal } from '@utils/number'

const InputText = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  leftIcon = false,
  rightIcon = false,
  onChange,
  onChangeText,
  value,
  onFocus,
  onBlur,
  inputRef,
  onSubmitEditing,
  editable = true,
  disabled = false,
  multiline = false,
  returnKeyType = 'next',
  keyboardType = undefined,
  placeholder = '',
  label = '',
  onPress,
  touched,
  error,
  setFieldValue,
  helpText,
  onEndEditing,
  autocomplete,
  maxLength,
  autofocus = false,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const fadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(fadeParams)

  useEffect(() => {
    if (isEmptyValue(value)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [value])

  const onPressCustom = () => {
    if (disabled) {
      return
    }
    if (onPress) {
      onPress()
    }
  }

  const onFocusCustom = () => {
    if (!editable || disabled) {
      return
    }

    setIsFocused(true)

    onFocus ? onFocus() : false
  }

  const onBlurCustom = () => {
    if (!editable || disabled) {
      return
    }

    setIsFocused(false)

    onBlur ? onBlur() : false
  }

  const handleChangeText = (text) => {
    const value = keyboardTypeParseNumberDecimal(text, keyboardType)
    if (onChangeText) {
      onChangeText(value)
    } else {
      setFieldValue(id, value)
    }
  }

  return (
    <Pressable onPress={onPressCustom} style={containerStyle}>
      <Animated.View
        style={[
          styles.placeholderContainer,
          {
            opacity: placeholderOpacity,
          },
        ]}
      >
        <Text
          style={[
            styles.placeholder,
            touched && error ? styles.placeholderError : {},
            isFocused ? styles.focusedPlaceholder : {},
            disabled ? styles.placeholderDisabled : {},
          ]}
        >
          {label ?? placeholder}
        </Text>

        <View style={styles.placeholderBackground}></View>
      </Animated.View>

      <Input
        id={id}
        name={name}
        inputContainerStyle={[
          styles.inputContainer,
          multiline ? styles.textAreaContainerStyle : {},
          touched && error ? styles.borderError : {},
          isFocused ? styles.focusedInput : {},
        ]}
        inputStyle={[
          styles.input,
          Platform.OS == 'web' ? { outlineWidth: 0 } : {},
          multiline ? styles.textAreaStyle : {},
          inputStyle,
        ]}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        onChange={onChange}
        onChangeText={handleChangeText}
        value={value}
        onFocus={onFocusCustom}
        onBlur={onBlurCustom}
        ref={inputRef}
        onSubmitEditing={onSubmitEditing}
        editable={editable}
        disabled={disabled}
        multiline={multiline}
        underlineColorAndroid='transparent'
        returnKeyType={returnKeyType}
        keyboardType={keyboardType}
        placeholder={placeholder}
        placeholderTextColor='rgba(51, 51, 51, 0.7)'
        errorStyle={styles.textError}
        errorMessage={touched && error}
        rightIconContainerStyle={styles.rightIconStyle}
        onEndEditing={onEndEditing}
        autoComplete={autocomplete}
        maxLength={maxLength}
        autoFocus={autofocus}
      />
      {(!disabled || !error) && helpText && (
        <Text style={styles.helpText}>{helpText}</Text>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: white,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: borderInput,
    borderRadius: 2,
    height: 62,
    marginHorizontal: -10,
    zIndex: 1,
    position: 'relative',
  },
  textAreaContainerStyle: {
    height: 100,
  },
  textError: {
    color: redError,
    fontSize: 12,
    marginLeft: 9,
    marginBottom: Platform.OS === 'web' ? 14 : 6,
  },
  borderError: {
    borderColor: redError,
  },
  input: {
    fontSize: 15,
    paddingHorizontal: 16,
  },
  textAreaStyle: {
    height: 90,
    paddingVertical: 14,
    textAlignVertical: 'top',
    justifyContent: 'flex-start',
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: placeholderDisabled,
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: white,
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
  helpText: {
    marginTop: Platform.OS === 'web' ? -12 : -24,
    marginBottom: 20,
    marginLeft: 20,
    fontSize: 11,
    color: placeholderColor,
  },
  rightIconStyle: {
    position: 'absolute',
    right: 15,
    backgroundColor: white,
  },
})

InputText.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onfocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.object,
  onSubmitEditing: PropTypes.func,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  returnKeyType: PropTypes.string,
  keyboardType: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onPress: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helpText: PropTypes.string,
  autofocus: PropTypes.bool,
}

export default InputText
