import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { white } from '@styles/palette'
import { useResponsive } from '../../hooks'

export const ResponsiveLayout = ({ children, fullScreen }) => {
  const { isScreenDesktop } = useResponsive()

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar backgroundColor={white} barStyle='dark-content' />
      <View
        style={[
          styles.content,
          isScreenDesktop && !fullScreen ? styles.fixedWidth : {},
        ]}
      >
        {children}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
  },
  content: {
    flex: 1,
    width: '100%',
  },
  fixedWidth: {
    width: 960,
  },
})
