import React, { useContext, useCallback } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import verificationStatus from '@constants/verificationStatus'
import { ActivityIcon } from '@modules/common/components'
import {
  grayBackground,
  blackHighEmphasis,
  blackMediumEmphasis,
} from '@styles/palette'

const ActivityVerificationValid = ({
  verificationName,
  fileName,
  uploadedBy,
  uploadedAt,
  right: RightComponent,
}) => {
  const { t } = useContext(LanguageContext)

  const VerificationIcon = useCallback(() => {
    return (
      <View style={styles.iconContainer}>
        <ActivityIcon
          name={'ACT_VERIFICATION'}
          size={35}
          color={blackHighEmphasis}
        />
      </View>
    )
  }, [])

  const VerificationDescription = useCallback(
    ({ verificationName, fileName, uploadedBy, uploadedAt }) => {
      return (
        <View style={styles.descriptionContainer}>
          {verificationName && (
            <Text style={styles.verificationNameText}>{verificationName}</Text>
          )}

          <Text style={styles.statusText}>
            {t('COMPONENTS.COMMON.ACTIVITY_VERIFICATION_VALID.TEXT_1', {
              status: verificationStatus.VALID.name(t),
            })}
          </Text>

          {fileName && <Text style={styles.fileNameText}>{fileName}</Text>}

          {uploadedBy && (
            <Text style={styles.uploadedByText}>{uploadedBy}</Text>
          )}

          {uploadedAt && (
            <Text style={styles.uploadedAtText}>{uploadedAt}</Text>
          )}
        </View>
      )
    },
    []
  )

  return (
    <View style={styles.verificationContainer}>
      <VerificationIcon />

      <VerificationDescription
        verificationName={verificationName}
        fileName={fileName}
        uploadedBy={uploadedBy}
        uploadedAt={uploadedAt}
      />

      {RightComponent && <RightComponent />}
    </View>
  )
}

const styles = StyleSheet.create({
  verificationContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    backgroundColor: grayBackground,
    borderRadius: 4,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 70,
  },
  icon: {
    color: blackHighEmphasis,
  },
  descriptionContainer: {
    flex: 1,
  },
  verificationNameText: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  statusText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  fileNameText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
  uploadedByText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
  uploadedAtText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
})

ActivityVerificationValid.propTypes = {
  verificationName: PropTypes.string,
  fileName: PropTypes.string,
  uploadedBy: PropTypes.string,
  uploadedAt: PropTypes.string,
  right: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

export default ActivityVerificationValid
