import React, { useContext } from 'react'
import { View, Text, Animated, StyleSheet } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { round, truncate } from 'lodash'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { ActivityIcon, SustainabilityIcon } from '@modules/common/components'
import ListCardEstablishment from './ListCardEstablishment'
import AccordionItem from '@components/common/v2/AccordionItem'
import ChipsComponent from '@components/common/v2/ChipsComponent'
import CardDefault from '@components/cards/CardDefault'
import verificationOptionStatus from '@constants/verificationStatus'
import activityTypesConstants from '@constants/activityTypes'
import statusEiq from '@constants/statusEiq'
import { activitiesStatus } from '@utils/constants'
import { formatDateMoment } from '@utils/date'
import { LanguageContext } from '@contextState/language'
import { isEmptyValue } from '@utils'
import {
  blackDisabled,
  lightGreen100,
  lightGreen900,
  blueGrey700,
  blueGrey50,
  secondary700,
  secondary600,
  orange100,
  blackMediumEmphasis,
  lime600,
  primary500,
  primary50,
  secondary300,
  secondary50,
  white,
  orange50,
} from '@styles/palette'
import { MAGIC_PERCENT_COMPLETE } from '@constants/magic'
import { roundSurface } from '@utils/common'

const SUB_TEXT_INFO_LENGTH = 38

const ActivityCardInfo = ({
  activity,
  status,
  eiqRange = '',
  onPress,
  agreementTypes,
  verificationTypes,
  activityTypes,
  subTypeActivities,
  unitTypes,
  cropAreaUnit,
  hasEiqRealized = false,
}) => {
  const { t, i18n } = useContext(LanguageContext)
  const {
    surface,
    type,
    subTypeActivity,
    typeAgreement,
    eiqPlanned,
    eiqRealized,
    isDraft,
    version,
    hasMoreVersion,
    achievementsPercentage,
    pendingValidations,
    verificationStatus,
    dateStart,
    dateEnd,
    dateObservation,
    dateHarvest,
    dateEstimatedHarvest,
    verificationType,
    establishments,
    lots,
    pay,
    unitType,
    createdAt,
    company,
    supplies = [],
    achievements = [],
    establishmentNumber = 0,
    isRejected,
    draftsRejected,
    areaUnit,
    isDirectAchievement,
    dateAchievement,
    canPlanning: canPlanningDraft,
  } = activity

  const { canPlanning } = type || {}
  const activityType = type

  const verificationTypeLabel =
    verificationType?.codeLabel ??
    verificationTypes.find((element) => element.value === verificationType)
      ?.label

  const activityTypeLabel =
    activityType?.codeLabel ??
    activityTypes.find(
      (element) =>
        element.value === activityType || element.tag === activity.tag
    )?.label

  const subTypeActivityLabel =
    subTypeActivity?.codeLabel ??
    subTypeActivities.find((element) => element.value === subTypeActivity)
      ?.label

  const agreementTypeLabel =
    typeAgreement?.codeLabel ??
    agreementTypes.find((element) => element.value === typeAgreement)?.label

  const tag = activity.tag
    ? activity.tag
    : activityType?.tag ??
      activityTypes.find((element) => element.value === activityType)?.label

  const isDraftRejected = isRejected ?? draftsRejected

  const getAdditionalTitleText = () => {
    let additionalText = ''

    if (
      canPlanning &&
      status === activitiesStatus.toMake.key &&
      dateStart &&
      dateEnd
    ) {
      additionalText = `\n${formatDateMoment(
        dateStart,
        'DD/MMM/YYYY',
        i18n.locale,
        true
      )} ${t('VIEWS.ACTIVITIES.TEXT_62')} ${formatDateMoment(
        dateEnd,
        'DD/MMM/YYYY',
        i18n.locale,
        true
      )}`
    } else if (
      canPlanning &&
      status !== activitiesStatus.toMake.key &&
      isDirectAchievement
    ) {
      additionalText = `\n${formatDateMoment(
        dateAchievement,
        'DD/MMM/YYYY',
        i18n.locale,
        true
      )}`
    } else if (
      tag === activityTypesConstants.ACT_MONITORING.key &&
      dateObservation
    ) {
      additionalText = `\n${formatDateMoment(
        dateObservation,
        'DD/MMM/YYYY',
        i18n.locale,
        true
      )}`
    } else if (tag === activityTypesConstants.ACT_HARVEST.key && dateHarvest) {
      additionalText = `\n${formatDateMoment(
        dateHarvest,
        'DD/MMM/YYYY',
        i18n.locale,
        true
      )}`
    } else if (
      tag === activityTypesConstants.ACT_VERIFICATION.key &&
      verificationType
    ) {
      return ` - ${verificationTypeLabel}`
    } else if (
      tag === activityTypesConstants.ACT_AGREEMENTS.key &&
      typeAgreement
    ) {
      return ` - ${agreementTypeLabel}`
    }
    return additionalText.split('.')
  }

  const getUnitType = (unit) => {
    if (typeof unit === 'string') {
      unit = unitTypes.find((item) => item.value.toString() === unit.toString())
    }
    return unit?.label ?? `${unit?.key} (${unit?.codeLabel})`
  }

  const getTextDraft = () => {
    if (Boolean(version) || isDraft > 0) {
      if (hasMoreVersion) {
        return `${t(
          tag === activityTypesConstants.ACT_VERIFICATION.key
            ? 'VIEWS.ACTIVITIES.TEXT_42'
            : 'VIEWS.ACTIVITIES.TEXT_26'
        )} : ${t('VIEWS.ACTIVITIES.TEXT_27')} ${version}`
      } else {
        if (isDraft > 1) {
          return `${isDraft} ${t('VIEWS.ACTIVITIES.TEXT_60')}`
        }
        return t(
          tag === activityTypesConstants.ACT_VERIFICATION.key
            ? 'VIEWS.ACTIVITIES.TEXT_42'
            : 'VIEWS.ACTIVITIES.TEXT_26'
        )
      }
    } else {
      return ''
    }
  }

  const getTextLots = `${t(
    establishments?.length > 1 || !establishments
      ? 'VIEWS.ACTIVITIES.TEXT_48'
      : 'VIEWS.ACTIVITIES.TEXT_49',
    {
      establishmentsQuantity: establishments?.length || establishmentNumber,
    }
  )} - ${t(
    lots?.length > 1 || !lots
      ? 'VIEWS.ACTIVITIES.TEXT_50'
      : 'VIEWS.ACTIVITIES.TEXT_51',
    {
      lotsQuantity: lots?.length || 0,
    }
  )}`

  const getSupplyNames = () => {
    let supplyNames = ''
    if (status === activitiesStatus.toMake.key || isDirectAchievement) {
      supplyNames = supplies.map((supply) => supply?.name).join(', ')
    } else {
      supplyNames = achievements
        .flatMap((achievement) => achievement.supplies)
        .map((supply) => supply?.name)
        .join(', ')
    }

    return truncate(supplyNames, {
      length: SUB_TEXT_INFO_LENGTH,
      separator: ', ',
    })
  }

  const displayHasMoreVersionDraft = () =>
    !canPlanningDraft || isDirectAchievement ? hasMoreVersion : false

  const extraStyleCard = displayHasMoreVersionDraft()
    ? { backgroundColor: orange50 }
    : { backgroundColor: white }

  const styleIconColorEiq = {
    color: eiqRange ? statusEiq[eiqRange]?.color : white,
  }

  const getTextPendingValidations = (pendingValidations) => {
    const sampleText = t('VIEWS.ACTIVITIES.TEXT_46')
    const pluralText = t('VIEWS.ACTIVITIES.TEXT_47', {
      value: pendingValidations,
    })

    switch (pendingValidations) {
      case undefined:
        return ''
      case 1:
        return sampleText
      default:
        return pluralText
    }
  }

  const displayAchievementsPercentage = () => {
    if (tag === activityTypesConstants.ACT_SOWING.key) {
      return status !== activitiesStatus.pending.key && !isDirectAchievement
    } else {
      return canPlanning && !isDirectAchievement
    }
  }

  const renderIsDraft = canPlanning
    ? version || isDraft > 0
    : (version || isDraft > 0) && !isDraftRejected

  const ContentHeader = ({ arrowAngle, onPress }) => {
    return (
      <TouchableRipple onPress={onPress}>
        <View style={styles.contentHeader}>
          <View style={styles.contentHeaderItem}>
            <View style={styles.infoHeader}>
              <View>
                <ActivityIcon name={tag} size={24} />
              </View>
              <View style={styles.contentTextInfo}>
                <Text style={styles.textBold}>
                  {activityTypeLabel}
                  {getAdditionalTitleText()}
                </Text>
                <Text style={styles.textHeaderInfo}>{getTextLots}</Text>
              </View>
            </View>
            <View>
              <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
                <CommonIcon
                  name='CHEVRON-DOWN'
                  size={25}
                  color={blackDisabled}
                />
              </Animated.View>
            </View>
          </View>
          <View style={styles.subContentHeader}>
            {displayHasMoreVersionDraft() && (
              <Text style={styles.textGreen}>
                {`${t('VIEWS.ACTIVITIES.TEXT_57')} ${String(
                  formatDateMoment(
                    createdAt,
                    'DD/MMM/YYYY - h:mm a',
                    i18n.locale,
                    true
                  )
                )}`}
              </Text>
            )}
            <Text style={styles.subTextInfo}>{`${t(
              'VIEWS.ACTIVITIES.TEXT_12'
            )}: ${round(surface, 2) || 0} ${areaUnit ?? cropAreaUnit}`}</Text>

            {company && tag === activityTypesConstants.ACT_VERIFICATION.key && (
              <Text style={styles.subTextInfo}>{`${t(
                'CONSTANTS.USER_TYPES.VERIFIER'
              )}: ${company?.name}`}</Text>
            )}
            {!isEmptyValue(pay) &&
              unitType !== undefined &&
              tag === activityTypesConstants.ACT_MONITORING.key && (
                <Text style={styles.subTextInfo} numberOfLines={1}>
                  {t('VIEWS.ACTIVITIES.TEXT_30', {
                    pay,
                    unitType: getUnitType(unitType),
                  })}
                </Text>
              )}
            {!isEmptyValue(pay) &&
              unitType !== undefined &&
              tag === activityTypesConstants.ACT_HARVEST.key && (
                <Text style={styles.subTextInfo} numberOfLines={1}>
                  {t('VIEWS.ACTIVITIES.TEXT_31', {
                    pay: roundSurface(pay),
                    unitType: getUnitType(unitType),
                  })}
                </Text>
              )}
            {Boolean(dateEstimatedHarvest) && (
              <Text style={styles.subTextInfo}>
                {t('VIEWS.ACTIVITIES.TEXT_32', {
                  dateEstimatedHarvest: `${formatDateMoment(
                    dateEstimatedHarvest,
                    'DD/MMM/YYYY',
                    i18n.locale,
                    true
                  )}`,
                })}
              </Text>
            )}
            {Boolean(subTypeActivity) && (
              <Text style={styles.subTextInfo}>
                {t('VIEWS.ACTIVITIES.TEXT_44', {
                  activityType: activityTypeLabel,
                  subTypeActivity: subTypeActivityLabel,
                })}
              </Text>
            )}
            {tag === activityTypesConstants.ACT_SOWING.key &&
              Boolean(supplies?.length) && (
                <Text style={styles.subTextInfo}>{`${t(
                  'VIEWS.ACTIVITIES.TEXT_15'
                )}: ${getSupplyNames()}`}</Text>
              )}
            <View style={styles.contentChips}>
              {tag === activityTypesConstants.ACT_APPLICATION.key &&
                eiqRange && (
                  <ChipsComponent
                    text={`${t(
                      hasEiqRealized
                        ? 'VIEWS.ACTIVITIES.TEXT_45'
                        : 'VIEWS.ACTIVITIES.TEXT_19'
                    )} ${round(hasEiqRealized ? eiqRealized : eiqPlanned, 2)}`}
                    widthChips={150}
                    heightChips={32}
                    borderRadius={50}
                    fontSize={14}
                    backgroundColor={statusEiq[eiqRange]?.background}
                    textColor={statusEiq[eiqRange]?.color}
                    icon={
                      <SustainabilityIcon
                        name={'EIQ'}
                        size={17}
                        style={[styles.chipEiq, styleIconColorEiq]}
                      />
                    }
                  />
                )}
            </View>
            <View style={styles.contentChips}>
              {(canPlanning ||
                version ||
                isDraft > 0 ||
                status === activitiesStatus.pending.key ||
                pendingValidations > 0) && (
                <>
                  {status === activitiesStatus.pending.key && (
                    <View style={styles.firstChip}>
                      <ChipsComponent
                        text={t('VIEWS.ACTIVITIES.TEXT_58')}
                        widthChips={72}
                        heightChips={20}
                        backgroundColor={primary50}
                        textColor={primary500}
                        textTransform='uppercase'
                      />
                    </View>
                  )}
                  {displayAchievementsPercentage() && (
                    <View style={styles.firstChip}>
                      <ChipsComponent
                        text={`${t('VIEWS.ACTIVITIES.TEXT_59')} ${
                          isDirectAchievement
                            ? MAGIC_PERCENT_COMPLETE
                            : achievementsPercentage || 0
                        }%`}
                        widthChips={113}
                        heightChips={20}
                        backgroundColor={lightGreen100}
                        textColor={lightGreen900}
                        textTransform='uppercase'
                      />
                    </View>
                  )}
                  {renderIsDraft && (
                    <View style={styles.commonChip}>
                      <ChipsComponent
                        text={getTextDraft()}
                        widthChips={70}
                        heightChips={20}
                        backgroundColor={blueGrey50}
                        textColor={blueGrey700}
                        textTransform='uppercase'
                      />
                    </View>
                  )}
                  {pendingValidations > 0 && !version && isDraft <= 0 && (
                    <View style={styles.commonChip}>
                      <ChipsComponent
                        text={getTextPendingValidations(pendingValidations)}
                        widthChips={70}
                        heightChips={20}
                        backgroundColor={orange100}
                        textColor={secondary700}
                        textTransform='uppercase'
                      />
                    </View>
                  )}
                  {Boolean(isDraftRejected) && !canPlanning && (
                    <View style={styles.lastChip}>
                      <ChipsComponent
                        text={t('VIEWS.ACTIVITIES.TEXT_61')}
                        widthChips={72}
                        heightChips={20}
                        backgroundColor={secondary600}
                        textColor={white}
                        textTransform='uppercase'
                      />
                    </View>
                  )}
                </>
              )}
              {pendingValidations > 0 && (version || isDraft > 0) && (
                <ChipsComponent
                  text={getTextPendingValidations(pendingValidations)}
                  widthChips={70}
                  heightChips={20}
                  backgroundColor={orange100}
                  textColor={secondary700}
                  textTransform='uppercase'
                />
              )}
              {Boolean(isDraftRejected) && canPlanning && (
                <View>
                  <ChipsComponent
                    text={t('VIEWS.ACTIVITIES.TEXT_61')}
                    widthChips={72}
                    heightChips={20}
                    marginLeft={10}
                    backgroundColor={secondary600}
                    textColor={white}
                    textTransform='uppercase'
                  />
                </View>
              )}

              {verificationStatus &&
                status === activitiesStatus.finished.key && (
                  <ChipsComponent
                    text={t(
                      verificationStatus === verificationOptionStatus.VALID.key
                        ? 'VIEWS.ACTIVITIES.TEXT_41'
                        : 'VIEWS.ACTIVITIES.TEXT_40'
                    )}
                    widthChips={75}
                    heightChips={20}
                    backgroundColor={
                      verificationStatus === verificationOptionStatus.VALID.key
                        ? primary500
                        : secondary300
                    }
                    textColor={
                      verificationStatus === verificationOptionStatus.VALID.key
                        ? primary50
                        : secondary50
                    }
                    textTransform='uppercase'
                  />
                )}
            </View>
          </View>
        </View>
      </TouchableRipple>
    )
  }

  return (
    <CardDefault extraStyle={extraStyleCard}>
      <AccordionItem
        ContentHeader={ContentHeader}
        extraBodyStyle={styles.bodyContainer}
      >
        <ListCardEstablishment
          establishments={establishments || []}
          status={isDraft ? 'draft' : status}
          t={t}
          onPress={onPress}
          activity={activity}
        />
      </AccordionItem>
    </CardDefault>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    paddingHorizontal: 15,
  },
  contentHeaderItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: 1,
    paddingVertical: 15,
  },
  infoHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '90%',
  },
  contentTextInfo: {
    marginLeft: 15,
  },
  textBold: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  textHeaderInfo: {
    color: blackDisabled,
    fontSize: 14,
  },
  subTextInfo: {
    color: blackMediumEmphasis,
    fontSize: 14,
    marginTop: 5,
  },
  subContentHeader: {
    paddingTop: 5,
    paddingBottom: 15,
  },
  contentChips: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: 10,
  },
  firstChip: {
    marginRight: 10,
    marginBottom: 5,
  },
  commonChip: {
    marginBottom: 5,
    marginRight: 10,
  },
  lastChip: {
    marginBottom: 5,
    justifyContent: 'flex-end',
  },
  chipEiq: {
    color: lime600,
    marginRight: 8,
  },
  bodyContainer: {
    backgroundColor: white,
  },
  textGreen: {
    color: primary500,
    fontSize: 14,
  },
})

ActivityCardInfo.propTypes = {
  activity: PropTypes.shape({
    _id: PropTypes.string,
    surface: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subTypeActivity: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    eiqPlanned: PropTypes.number,
    eiqRealized: PropTypes.number,
    version: PropTypes.number,
    hasMoreVersion: PropTypes.bool,
    achievementsPercentage: PropTypes.number,
    pendingValidations: PropTypes.number,
    verificationStatus: PropTypes.string,
    dateStart: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dateEnd: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dateObservation: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dateHarvest: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dateEstimatedHarvest: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    verificationType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    establishments: PropTypes.array,
    lots: PropTypes.array,
    supplies: PropTypes.array,
    pay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unitType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    company: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isRejected: PropTypes.bool,
  }),
  status: PropTypes.string,
  cropAreaUnit: PropTypes.string,
}

export default ActivityCardInfo
