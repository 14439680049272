import React, { useCallback, useContext, useState, useRef } from 'react'
import { useWindowDimensions, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useFormik } from 'formik'
import { debounce } from 'lodash'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PropTypes from 'prop-types'

import ListSupplies from './components/ListSupplies'
import useFetchPagination from '../../../hooks/useFetchPagination'
import { keysSupplies } from '@constants'
import activityTypes from '@constants/activityTypes'
import { ConnectionContext } from '@contextState/connection'
import { LanguageContext } from '@contextState/language'
import Screen from './Screen'

const SUPPLIES_SEARCH_HISTORY = 'SUPPLIES_SEARCH_HISTORY'

const SupplySelector = ({ route, navigation }) => {
  const { activityType, cropType, alphaCode, surface } = route.params
  const { isConnected } = useContext(ConnectionContext)
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [isModalHistoryVisible, setIsModalHistoryVisible] = useState(false)
  const [searchHistory, setSearchHistory] = useState([])
  const { t } = useContext(LanguageContext)
  const [routes] = useState([
    {
      key: keysSupplies.all,
      title: t('COMPONENTS').SUPPLIES.TABS.TEXT_1,
      name: 'insumos',
    },
    {
      key: keysSupplies.seeds,
      title: t('COMPONENTS').SUPPLIES.TABS.TEXT_2,
      name: 'semillas',
    },
    {
      key: keysSupplies.fertilizer,
      title: t('COMPONENTS').SUPPLIES.TABS.TEXT_3,
      name: 'fertilizantes',
    },
    {
      key: keysSupplies.phytotherapics,
      title: t('COMPONENTS').SUPPLIES.TABS.TEXT_4,
      name: 'fitoterapicos',
    },
  ])
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    tagType: 'ALL',
    cropType,
    activityType,
    alphaCode,
    orderBy: 'ASC',
  })

  const [refresh, setRefresh] = useState(false)
  const [isFirstRefresh, setIsFirstRefresh] = useState(false)

  const searchInputRef = useRef()

  useFocusEffect(
    useCallback(() => {
      if (isFirstRefresh) {
        setFilter({ ...filter, tagType: routes[index].key, page: 1 })
        setRefresh(!refresh)
      } else {
        setSearch('')
        setIsFirstRefresh(true)
      }

      syncSearchHistory()

      return () => true
    }, [index])
  )

  const {
    response: { data, hasMoreElements },
    firstPaginate,
    isLoading,
  } = useFetchPagination('supplies', 'get', [refresh, setRefresh], {
    filter,
  })

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      if (values.search.length && values.search.length < 3) {
        return
      }

      setFilter({
        ...filter,
        queryFiltering: values.search !== '' ? values.search : null,
        page: 1,
      })
      setRefresh(!refresh)
      setSearch(values.search)
      setIsModalHistoryVisible(false)
    },
    enableReinitialize: false,
  })

  const { setFieldValue, values, handleSubmit } = formik

  const handler = useCallback(debounce(handleSubmit, 500), [])

  const layout = useWindowDimensions()

  const onEndReached = () => {
    if (hasMoreElements) {
      setFilter({ ...filter, page: filter.page + 1 })
      setRefresh(!refresh)
    }
  }
  const handleOpenItem = (item) => {
    navigation.navigate('SupplyLoaderScreen', {
      ...route.params,
      item,
      surface,
    })
  }

  const onRefresh = () => {
    setFilter({ ...filter, skip: 1 })
    setRefresh(!refresh)
  }

  const disableTabConditions = (route) => {
    let disable = false
    if (
      route.key === keysSupplies.seeds &&
      activityType === activityTypes.ACT_FERTILIZATION.key &&
      Platform.OS !== 'web'
    ) {
      disable = true
    }
    if (
      route.key === keysSupplies.seeds &&
      activityType === activityTypes.ACT_APPLICATION.key &&
      Platform.OS !== 'web'
    ) {
      disable = true
    }

    return disable
  }

  const onTabPress = ({ route, preventDefault }) => {
    // verify if the tab is accesible
    const dontAllowTab = disableTabConditions(route)
    if (dontAllowTab) preventDefault()
  }

  const renderListSupply = () => (
    <ListSupplies
      data={data}
      search={search}
      setFilter={setFilter}
      filter={filter}
      setRefresh={setRefresh}
      refresh={refresh}
      isLoading={isLoading}
      onEndReached={onEndReached}
      firstPaginate={firstPaginate}
      type={routes[index]?.name}
      isConnected={isConnected}
      searchValue={values.search}
      onPress={handleOpenItem}
      onRefresh={onRefresh}
    />
  )

  const renderScene = ({ route }) => {
    switch (route.key) {
      case keysSupplies.all:
        return renderListSupply()
      case keysSupplies.seeds:
        if (
          activityType === activityTypes.ACT_FERTILIZATION.key ||
          activityType === activityTypes.ACT_APPLICATION.key
        ) {
          return false
        }
        return renderListSupply()
      case keysSupplies.fertilizer:
        return renderListSupply()
      case keysSupplies.phytotherapics:
        return renderListSupply()
    }
  }

  const setSearchCode = (mode) => {
    setFilter({ ...filter, page: 1, sortType: mode })
    setRefresh(!refresh)
  }

  const syncSearchHistory = async () => {
    const history = await onGetSearchHistory()

    setSearchHistory(history)
  }

  const onSelectHistoryElement = async (element) => {
    setIsModalHistoryVisible(false)

    setFilter({
      ...filter,
      queryFiltering: element.text,
      page: 1,
    })

    setRefresh(!refresh)

    setSearch(element.text)

    setFieldValue('search', element.text)

    onSaveSearch(element.text)
  }

  const onRemoveHistoryElement = async (element) => {
    let history = await onGetSearchHistory()

    history = history.filter(
      (historyElement) => historyElement.text !== element.text
    )

    AsyncStorage.setItem(SUPPLIES_SEARCH_HISTORY, JSON.stringify(history))

    setSearchHistory(history)

    return history
  }

  const onSearchInputFocus = () => {
    setIsModalHistoryVisible(true)
  }

  const onSearchInputBlur = () => {
    setTimeout(() => {
      setIsModalHistoryVisible(false)
    }, 600)
    if (!values.search) {
      return
    }

    onSaveSearch(values.search)
  }

  const onSaveSearch = async (newSearchValue) => {
    let history = await onGetSearchHistory()

    history = history.filter(
      (historyElement) => historyElement.text !== newSearchValue
    )

    if (history.length === 5) {
      history.pop()
    }

    history.unshift({
      text: newSearchValue,
    })

    AsyncStorage.setItem(SUPPLIES_SEARCH_HISTORY, JSON.stringify(history))

    setSearchHistory(history)

    return history
  }

  const onGetSearchHistory = async () => {
    const response = await AsyncStorage.getItem(SUPPLIES_SEARCH_HISTORY)

    return response ? JSON.parse(response) : []
  }

  const onSearchInputHandleKeypress = (searchValue) => {
    onSaveSearch(searchValue)

    setIsModalHistoryVisible(false)
  }

  const onPressRight = () => {
    setFieldValue('search', '')
    handleSubmit()
  }

  return (
    <Screen
      formik={formik}
      handler={handler}
      values={values}
      setFieldValue={setFieldValue}
      handleSubmit={handleSubmit}
      onTabPress={onTabPress}
      index={index}
      routes={routes}
      selectedTab={routes[index].name}
      renderScene={renderScene}
      setIndex={setIndex}
      layout={layout}
      isModalHistoryVisible={isModalHistoryVisible}
      searchHistory={searchHistory}
      onSelectHistoryElement={onSelectHistoryElement}
      onRemoveHistoryElement={onRemoveHistoryElement}
      onSearchInputFocus={onSearchInputFocus}
      onSearchInputBlur={onSearchInputBlur}
      searchInputRef={searchInputRef}
      setSearchCode={setSearchCode}
      onSearchInputHandleKeypress={onSearchInputHandleKeypress}
      onPressRight={onPressRight}
    />
  )
}

SupplySelector.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default SupplySelector
