import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import SuccessPinModal from '@components/modals/SuccessPinModal'
import ButtonPrimary from '@components/buttons/ButtonPrimary'

const SuccessApplySubLicensesModal = ({
  isModalVisible,
  toggleModal,
  goToLicenses,
  goToHome,
  t,
}) => {
  return (
    <SuccessPinModal
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
      title={t('VIEWS').LICENSE_LOTS_ADD.TEXT_10}
    >
      <View style={styles.contentInfo}>
        <View style={styles.contentTextInfo}>
          <Text style={styles.textInfo}>
            {t('VIEWS').LICENSE_LOTS_ADD.TEXT_11}
          </Text>

          <Text style={styles.textInfo}>
            {t('VIEWS').LICENSE_LOTS_ADD.TEXT_12}
          </Text>
        </View>

        <View style={styles.buttonsContainer}>
          <ButtonPrimary
            text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_13}
            onPress={goToLicenses}
          />
          <ButtonPrimary
            marginTop={40}
            text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_14}
            backgroundColor='transparent'
            colorText={'#fff'}
            fontSize={14}
            onPress={goToHome}
          />
        </View>
      </View>
    </SuccessPinModal>
  )
}

const styles = StyleSheet.create({
  contentInfo: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonsContainer: {
    marginTop: 50,
    width: 300,
  },
})

SuccessApplySubLicensesModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  goToLicenses: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default SuccessApplySubLicensesModal
