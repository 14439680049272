import React, { useState, useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { List, Divider } from 'react-native-paper'
import PropTypes from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'

function CompanyServiceIntegrationsSelectorScreen({ route, navigation }) {
  const [id, setId] = useState('')
  const { t } = useContext(LanguageContext)
  React.useEffect(() => {
    setId(route.params.id)
  }, [])

  return (
    <View style={styles.container}>
      <View>
        <React.Fragment>
          <List.Item
            description={t('VIEWS').COMPANY_SEVICE_INTEGRATION_SELECTOR.TEXT_1}
            title={t('VIEWS').COMPANY_SEVICE_INTEGRATION_SELECTOR.TEXT_2}
            titleStyle={styles.title}
            left={() => (
              <View style={styles.iconContainer}>
                <CommonIcon name='INTEGRATIONS' size={20} />
              </View>
            )}
            right={() => (
              <View style={styles.iconContainer}>
                <CommonIcon name='CHEVRON-RIGHT' size={20} />
              </View>
            )}
            onPress={() => {
              navigation.navigate('CompanyIntegrationAppList', {
                id: id,
              })
            }}
          />
          <Divider />
        </React.Fragment>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  title: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

CompanyServiceIntegrationsSelectorScreen.cropTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyServiceIntegrationsSelectorScreen
