export default {
  ACT_TILLAGE: 'Labrança',
  ACT_APPLICATION: 'Aplicação',
  ACT_FERTILIZATION: 'Fertilização',
  ACT_SOWING: 'Semeadura',
  ACT_HARVEST: 'Colheita',
  ACT_AGREEMENTS: 'Declaração',
  ACT_MONITORING: 'Monitoramento',
  ACT_VERIFICATION: 'Verifica',
}
