import * as React from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import { Text, Modal, Portal } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { white } from '@modules/common/styles'

const ModalSuccess = ({
  title,
  description,
  visible,
  onDismiss,
  children,
  style,
}) => {
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={onDismiss}
        contentContainerStyle={styles.modal}
      >
        <Pressable style={styles.container} onPress={onDismiss}>
          <Text style={[styles.successText, styles.successTitleText]}>
            {title}
          </Text>

          <View style={[styles.buttonContainer, style ?? style]}>
            <View style={styles.button}>
              <CommonIcon name={'CHECK'} size={45} color={white} />
            </View>
          </View>

          <Text style={[styles.successText, styles.successSubText]}>
            {description}
          </Text>

          {children}
        </Pressable>
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#81C784',
    justifyContent: 'center',
  },
  successTitleText: {
    fontSize: 30,
    lineHeight: 40,
    letterSpacing: -0.5,
  },
  successText: {
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  successSubText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: -0.5,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: 171,
    height: 171,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 100,
    backgroundColor: 'rgba(76, 175, 80, .5)',
  },
  button: {
    width: 131,
    height: 131,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 100,
    backgroundColor: '#4CAF50',
    marginVertical: 30,
  },
  icon: {
    color: '#FFFFFF',
  },
})

ModalSuccess.propTypes = {
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.object,
}

export default ModalSuccess
