import { truncateNumber } from './truncateNumber'

export const TWO_DECIMAL_PLACE = 2
export const FOUR_DECIMAL_PLACE = 4

/**
 *
 * @param {number | string} surface  The number to round.
 * @param {number} decimalPlace - Number of decimal places
 *
 * @return {number}
 */
export const truncateSurface = (surface, decimalPlace = FOUR_DECIMAL_PLACE) => {
  const number = truncateNumber(surface, decimalPlace)

  return number
}
