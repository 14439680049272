import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

const TitleIcon = ({ text, Icon }) => {
  return (
    <View style={styles.content}>
      {Icon && (
        <View style={styles.iconContainer}>
          <Icon />
        </View>
      )}
      <Text style={styles.textTitle}>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 15,
  },
  textTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})

TitleIcon.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.node,
}

export default TitleIcon
