import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { errorLight, black, primary50 } from '@styles/palette'
import toastTypes from '@constants/toastTypes'
const MessageToast = ({ type, message }) => {
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: toastTypes[type]?.color || primary50 },
      ]}
    >
      <Text style={styles.message}>{message}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: errorLight,
    padding: 16,
    borderRadius: 5,
    marginHorizontal: 8,
    marginTop: 16,
  },
  message: {
    fontSize: 14,
    color: black,
    opacity: 0.6,
    lineHeight: 20,
    fontWeight: 'bold',
  },
})

export default MessageToast
