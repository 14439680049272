import React, { useContext, useEffect } from 'react'
import { StyleSheet, Text, View, BackHandler } from 'react-native'
import { useDispatch } from 'react-redux'
import { ScrollView } from 'react-native-gesture-handler'

import { LanguageContext } from '@contextState/language'
import { primary500, white, transparent } from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { authActions } from '@store/actions'
import { AuthContext } from '@contextState/auth'

const WelcomeCompany = ({ navigation }) => {
  const { t } = useContext(LanguageContext)
  const { user } = useContext(AuthContext)
  const dispatch = useDispatch()

  useEffect(() => {
    const backAction = () => {
      return true
    }

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction
    )

    return () => backHandler.remove()
  }, [])

  const goJoinCompany = () => {
    navigation.navigate('ValidateTaxId')
  }
  const onHandlerSkipNow = () => {
    dispatch(
      authActions.setEmailWelcomeCompanyList({ email: user?.email, hide: true })
    )
  }
  return (
    <View style={styles.scaffold}>
      <ScrollView style={styles.containerContent}>
        <Text style={styles.title}>{t(`VIEWS.WELCOME_COMPANY.WELCOME`)}</Text>
        <Text style={styles.description}>
          {t(`VIEWS.WELCOME_COMPANY.MANAGE_OR_JOIN_COMPANY`)}
        </Text>
        <ButtonPrimary
          text={t(`VIEWS.WELCOME_COMPANY.MANAGE_NEW_COMPANY`)}
          backgroundColor={primary500}
          colorText={white}
          borderColor={primary500}
          borderRadius={8}
          fontSize={14}
          fontWeight={'700'}
          marginTop={28}
          onPress={goJoinCompany}
        />
        <ButtonPrimary
          text={t(`VIEWS.WELCOME_COMPANY.JOIN_COMPANY`)}
          backgroundColor={transparent}
          colorText={primary500}
          borderColor={primary500}
          borderRadius={8}
          fontSize={14}
          fontWeight={'700'}
          marginTop={15}
          onPress={goJoinCompany}
        />
      </ScrollView>
      <View style={styles.containerLink}>
        <Text style={styles.link} onPress={onHandlerSkipNow}>
          {t('VIEWS.WELCOME_COMPANY.SKIP_FOR_NOW')}{' '}
        </Text>
      </View>
    </View>
  )
}

export default WelcomeCompany

const styles = StyleSheet.create({
  scaffold: {
    flex: 1,
    paddingTop: 30,
    backgroundColor: white,
  },
  containerContent: {
    padding: 16,
    marginTop: 32,
  },
  title: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 28,
    marginBottom: 16,
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
  containerLink: {
    marginBottom: 48,
  },
  link: {
    textAlign: 'center',
    color: primary500,
    fontWeight: '700',
  },
})
