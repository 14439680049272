import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text } from 'react-native'

import { LanguageContext } from '@contextState/language'

import { formatDateMoment } from '@utils/date'
import activityTypes from '@constants/activityTypes'

import { blackHighEmphasis } from '@styles/palette'

export const ActivityCardDate = ({
  activityType,
  dateObservation,
  dateHarvest,
}) => {
  const { i18n } = useContext(LanguageContext)

  if (activityType === activityTypes.ACT_MONITORING.key) {
    return (
      <Text style={styles.date}>
        {formatDateMoment(dateObservation, 'DD/MMM/YYYY', i18n.locale, true)}
      </Text>
    )
  }

  if (activityType === activityTypes.ACT_HARVEST.key) {
    return (
      <Text style={styles.date}>
        {formatDateMoment(dateHarvest, 'DD/MMM/YYYY', i18n.locale, true)}
      </Text>
    )
  }

  return null
}

const styles = StyleSheet.create({
  date: {
    marginTop: 25,
    fontSize: 12,
    color: blackHighEmphasis,
  },
})

ActivityCardDate.prototype = {
  activityType: PropTypes.string.isRequired,
  dateHarvest: PropTypes.string,
  dateObservation: PropTypes.string,
}
