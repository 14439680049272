import React from 'react'
import PropTypes from 'prop-types'

import {
  GeneralInfoCard,
  ActivityCard,
  EvidenceCard,
  CollaboratorCard,
} from '..'

export const Agreement = ({
  cropName,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  agreementTypeName,
  evidences,
  collaboratorsQuantity,
}) => {
  return (
    <>
      <GeneralInfoCard cropName={cropName} companyName={companyName} />

      <ActivityCard
        fieldsQuantity={fieldsQuantity}
        surface={surface}
        unitArea={unitArea}
        agreementTypeName={agreementTypeName}
      />

      <EvidenceCard evidences={evidences} />

      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

Agreement.propTypes = {
  cropName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  agreementTypeName: PropTypes.string.isRequired,
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
  collaboratorsQuantity: PropTypes.number.isRequired,
}
