export default {
  TEXT_1: 'Add evidence',
  TEXT_2: 'Required field',
  TEXT_3: 'Concept',
  TEXT_4: 'Date of execution',
  TEXT_5: 'Picture inside the field',
  TEXT_6: 'Select file',
  TEXT_7: 'CANCEl',
  TEXT_8: 'Add',
}
