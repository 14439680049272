export default {
  TEXT_1: 'UCROP.IT | Log in',
  TEXT_2: 'UCROP.IT | Register',
  TEXT_3: 'UCROP.IT | Verify code',
  TEXT_4: 'UCROP.IT | Create PIN Code',
  TEXT_5: 'UCROP.IT | New company',
  TEXT_6: 'New company',
  TEXT_7: 'Select fields',
  TEXT_8: 'Select collaborators',
  TEXT_9: 'Search inputs',
  TEXT_10: 'Connection not available',
  TEXT_11: 'Limited connection',
  TEXT_12: 'CLOSE',
  TEXT_13: 'Synchronizing drafts',
}
