import { useContext } from 'react'

import { AuthContext } from '@contextState/auth'

export const useConfig = () => {
  const { config } = useContext(AuthContext) as any

  return {
    ...config,
  }
}
