import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { ItemCropType } from '../ItemCropType'
import { ContentBackDropCropTypes } from '../ContentBackDropCropTypes'

import ButtonRipple from '@modules/common/components/buttons/ButtonRipple'
import { CommonIcon } from '@modules/common/components'
import { green500, black } from '@modules/common/styles'

export const HeaderTypeLicenses = ({
  cropTypes,
  selectItem,
  displayBackDrop,
  ActionSheetComponent,
  afterClosing,
  t,
  itemSelected,
  clearFilter,
  openAll,
  numberColumns,
}) => {
  const { firstCropTypes, allCropTypes } = cropTypes
  return (
    <View style={styles.contentHeader}>
      <ItemCropType
        Icon={() => (
          <CommonIcon
            name={'LICENSES'}
            size={30}
            color={itemSelected === null ? green500 : black}
          />
        )}
        label={t('VIEWS.LICENSE_LIST.ALL')}
        onPress={clearFilter}
        isSelected={itemSelected === null}
      />
      {firstCropTypes.map((cropType) => (
        <ItemCropType
          item={cropType}
          key={`list_${cropType._id}`}
          onPress={selectItem}
          iconName={cropType.key}
          label={cropType.keyLabel}
          isSelected={itemSelected === cropType._id}
        />
      ))}
      {displayBackDrop && (
        <ActionSheetComponent afterClosing={afterClosing}>
          <ContentBackDropCropTypes
            cropTypes={allCropTypes}
            numberColumns={numberColumns}
            t={t}
            selectItem={selectItem}
            itemSelected={itemSelected}
          />
        </ActionSheetComponent>
      )}
      {numberColumns < allCropTypes.length && (
        <ButtonRipple style={styles.contentOpenBackDrop}>
          <CommonIcon onPress={openAll} name='CHEVRON-DOWN' size={20} />
        </ButtonRipple>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    paddingVertical: 25,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  contentItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentOpenBackDrop: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
  },
})

HeaderTypeLicenses.propTypes = {
  cropTypes: PropTypes.object.isRequired,
  selectItem: PropTypes.func,
  displayBackDrop: PropTypes.bool.isRequired,
  ActionSheetComponent: PropTypes.func,
  afterClosing: PropTypes.func.isRequired,
}
