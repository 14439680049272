import React from 'react'
import { FlatList, StyleSheet } from 'react-native'
import Constants from 'expo-constants'
import PropTypes from 'prop-types'

import { ActivityIndicator } from '@modules/common/components'
import { LotItem } from '../index'

const apiUrl = Constants.manifest.extra.EXPO_HOST

export const LotList = ({ lots, goToDetailsLotFarm, nextPage, isLoading }) => {
  const renderItem = ({ item }) => {
    const location = `${item.thirdLevelGeometry}, ${item.secondLevelGeometry}`

    let image

    if (item?.files?.length) {
      const [firstImage] = item.files
      const imageUrl = firstImage.url
      image =
        imageUrl.indexOf('/uploads/map-static') === -1
          ? imageUrl
          : `${apiUrl}${imageUrl}`
    }

    return (
      <LotItem
        key={item.uuid}
        id={item.uuid}
        name={item.name}
        surface={item.surface}
        areaUnit={item.areaUnit}
        location={location}
        image={image}
        goToDetailsLotFarm={goToDetailsLotFarm}
      />
    )
  }

  const renderFooter = () =>
    isLoading && (
      <ActivityIndicator
        size='large'
        containerStyle={styles.activityIndicator}
      />
    )

  return (
    <FlatList
      data={lots}
      renderItem={renderItem}
      keyExtractor={(item) => item.uuid}
      style={styles.container}
      onEndReached={nextPage}
      onEndReachedThreshold={0.1}
      ListFooterComponent={renderFooter}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  activityIndicator: {
    flex: 1,
    marginBottom: 20,
    marginTop: 10,
  },
})

LotList.propTypes = {
  lots: PropTypes.arrayOf(PropTypes.object).isRequired,
  goToDetailsLotFarm: PropTypes.func.isRequired,
}
