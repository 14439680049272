export default {
  TEXT_1: 'Nuevo cultivo',
  TEXT_2: 'De la empresa física o jurídica a cargo de la producción.',
  TEXT_3: 'Siguiente',
  TEXT_4:
    'El CUIT ya existe en la plataforma, solicite permisos al administrador de la empresa',
  TEXT_5: 'La fecha de siembra debe ser mayor o igual a la fecha actual',
  TEXT_6: 'La fecha de siembra no debe ser mayor a la fecha de cosecha',
  TEXT_7: 'La fecha de cocecha debe ser mayor a la fecha de siembra',
  TEXT_8: 'Campo Requerido',
  TEXT_9: 'Podrás editar el nombre del cultivo más adelante.',
  FIELD_1: {
    NAME: 'Tipo de cultivo',
    OPTION_1: 'Soja',
    OPTION_2: 'Girasol',
    OPTION_3: 'Maíz',
    OPTION_4: 'Algodón',
    OPTION_5: 'Trigo',
    OPTION_6: 'Carinata',
  },
  FIELD_2: {
    NAME: 'Siembra estimada',
  },
  FIELD_3: {
    NAME: 'Cosecha estimada',
  },
  FIELD_4: {
    NAME: 'Unidad de Rinde',
    OPTION_1: 'Kilogramos/ha',
    OPTION_2: 'Toneladas/ha',
    OPTION_3: 'Bolsas/ha',
    OPTION_4: 'Fardos/ha',
    OPTION_5: 'Quintales/ha',
  },
  FIELD_5: {
    NAME: 'Rinde Estimado',
  },
  FIELD_6: {
    NAME: 'CUIT',
  },
  FIELD_7: {
    NAME: 'Nombre del Cultivo',
  },
  FIELD_8: {
    NAME: 'Pais',
  },
  MODAL_1: {
    TEXT_1: '¿Deseas restaurar el borrador?',
    TEXT_2:
      'Tenes un borrador sin completar, podes iniciar un cultivo nuevo o bien continuar el anterior',
    TEXT_3: 'Restaurar',
    TEXT_4: 'Iniciar Nuevo',
  },
  STAGE_FIELD: {
    NAME: 'Estadío del cultivo',
    FIRST: 'Cultivo de primera',
    SECOND: 'Cultivo de segunda',
  },
}
