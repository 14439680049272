export const parseDataToValidateAchievementUnplanned = (values, userId) => {
  const dataToSend = {
    draftId: values?.draftId,
    name: values.name,
    surface: values.surface,
    type: values.type,
    cropId: values.cropId,
    subTypeActivity: values.subTypeActivity?.value,
    tag: values.tag,
    lots: values.lots.value.map((element) => element._id),
    isDirectAchievement: true,
    lotsWithSurface: values.lots.value.map((element) => ({
      lot: element._id,
      surfaceRealized: element.surface,
      tag: element.tag,
    })),
    signers: values.signers.map((element) => {
      if (element.userId !== userId) {
        element.signed = false
        element.dateToSign = new Date()
      }
      return element
    }),
    supplies: values.supplies.map((element) => ({
      ...element,
      typeId: element.typeId?._id ?? element.typeId,
      supply: element.supplyId || element.supply || element._id,
      typeSupply:
        typeof element.typeId === 'object'
          ? element?.typeId
          : element?.typeSupply,
    })),
    dateAchievement: values.dateAchievement?.value,
    evidences: values.evidences.map((element) => ({
      ...element,
      user: element.user?._id,
    })),
  }

  return dataToSend
}
