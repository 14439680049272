import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { TouchableRipple } from 'react-native-paper'
import { white, whiteTransparent, primary900 } from '@styles/palette'
import { ProgressBar } from 'react-native-paper'
import { MAGIC_PERCENT_COMPLETE } from '@constants/magic'

export const HeaderInfoAchievements = ({
  percent = 0,
  onPressCreateAchievement,
  isFinished,
  t,
}) => {
  return (
    <TouchableRipple onPress={onPressCreateAchievement}>
      <View style={styles.contentHeader}>
        <View>
          <CommonIcon name={'CALENDAR-REALIZED'} size={32} color={white} />
        </View>
        <View style={styles.contentPercent}>
          <View style={styles.percentTitleContainer}>
            <Text style={styles.title}>
              {t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_INFO_ACHIEVEMENT.TEXT_1',
                { percent }
              )}
            </Text>

            {isFinished && (
              <Text style={styles.finishedText}>
                {t(
                  'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_INFO_ACHIEVEMENT.FINISHED'
                )}
              </Text>
            )}
          </View>

          <ProgressBar
            progress={percent / MAGIC_PERCENT_COMPLETE}
            color={primary900}
            style={styles.progressBar}
          />

          <Text style={styles.colorWhite}>
            {t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_INFO_ACHIEVEMENT.TEXT_2'
            )}
          </Text>
        </View>
        {!isFinished && (
          <View>
            <CommonIcon
              name='CHEVRON-RIGHT'
              size={25}
              color={white}
              style={styles.iconArrow}
            />
          </View>
        )}
      </View>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 10,
  },
  colorWhite: {
    color: white,
  },
  iconArrow: {
    backgroundColor: whiteTransparent,
    borderRadius: 40,
  },
  contentPercent: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 15,
  },
  percentTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: white,
    fontSize: 16,
    fontWeight: '500',
  },
  finishedText: {
    color: white,
    fontSize: 16,
    fontWeight: '500',
    marginLeft: 15,
  },
  progressBar: {
    backgroundColor: whiteTransparent,
    borderRadius: 10,
    marginVertical: 10,
  },
})

HeaderInfoAchievements.propTypes = {
  percent: PropTypes.number,
  onPressCreateAchievement: PropTypes.func.isRequired,
  isFinished: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}
