import React, { useContext } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import CollaboratorCardDetails from '../../collaborators/CollaboratorCardDetails'
import { ROL } from '@constants/roles'
import ButtonCustom from '../../common/ButtonCustom'
import PropTypes from 'prop-types'

const ConfirmUnlink = ({
  onClose,
  notfound = false,
  modal = false,
  unlinkCollaborator,
  selectCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToUnlinkCollaborators,
}) => {
  const { t } = useContext(LanguageContext)
  const validation = unlinkCollaborator.pendingValidations.length ? true : false

  const confirmValidation = unlinkCollaborator.pendingValidations.length
    ? true
    : false

  const subTitle = ROL[unlinkCollaborator.rol](t) || ROL['DEFAULT'](t)

  const pendingValidation = unlinkCollaborator.pendingValidations.length

  const styleContent = notfound
    ? styles.containerModalNotFound
    : styles.containerModal

  const styleHeader = modal
    ? [styles.containerHeaderModalGeneral, styles.containerHeaderIsModal]
    : [styles.containerHeaderModalGeneral, styles.containerHeaderModal]

  const styleModal = modal ? styles.contentIsModal : styles.contentModal

  return (
    <View style={styleContent}>
      <View style={styleHeader}>
        <TouchableOpacity onPress={onClose}>
          <CommonIcon name={'X'} size={20} style={styles.buttonClose} />
        </TouchableOpacity>
        <Text style={styles.titleModal}>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_CONFIRM.TEXT_1}
        </Text>
      </View>
      <View style={styleModal}>
        {unlinkCollaborator != null && (
          <CollaboratorCardDetails
            notfound={notfound}
            collaborator={unlinkCollaborator}
            loading={isFetchingCrop}
            disabled={setDisabled(unlinkCollaborator)}
            companyName={unlinkCollaborator.company.name}
            collaboratorType={unlinkCollaborator.rol}
            title={getTitleMenu(unlinkCollaborator)}
            subtitle={subTitle}
            status={!!unlinkCollaborator.firstName}
            pendingValidation={pendingValidation}
            delegating
            style={styles.cardConfirm}
            titleStyle={styles.titleStyle}
            confirmValidation={confirmValidation}
          />
        )}
        {!notfound && !validation && (
          <View>
            <CommonIcon
              name={'SIGN'}
              size={40}
              color='#66BB6A'
              style={styles.iconNoValidation}
            />

            <Text style={styles.textNoValidation}>
              {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_5}
            </Text>
          </View>
        )}
        {validation && (
          <View style={styles.containerValidationTitle}>
            <Text style={styles.validationTitle}>
              {t('VIEWS').CROP_COLLABORATOR.MODAL_CONFIRM.TEXT_2}
            </Text>
          </View>
        )}

        <View style={styles.contentReplacement}>
          <CollaboratorCardDetails
            notfound={notfound}
            collaborator={selectCollaborator}
            loading={isFetchingCrop}
            disabled={setDisabled(selectCollaborator)}
            companyName={selectCollaborator.company.name}
            collaboratorType={selectCollaborator.rol}
            title={getTitleMenu(selectCollaborator)}
            subtitle={subTitle}
            status={!!selectCollaborator.firstName}
            pendingValidation={pendingValidation}
            delegating
            style={styles.cardConfirmSuccess}
            titleStyle={styles.titleStyle}
            confirmValidation={confirmValidation}
            cardSuccess={true}
          />
        </View>

        {!notfound && (
          <View style={styles.containerButton}>
            <ButtonCustom
              contentStyle={styles.buttonUnlinkStyle}
              onPress={goToUnlinkCollaborators}
              labelStyle={styles.buttonTitle}
            >
              {t('VIEWS').CROP_COLLABORATOR.MODAL_CONFIRM.TEXT_3}
            </ButtonCustom>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerModal: {
    backgroundColor: 'white',
  },
  containerModalNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  iconNoValidation: {
    alignSelf: 'center',
    padding: 8,
  },
  textNoValidation: {
    textAlign: 'center',
    alignSelf: 'center',
    color: '#66BB6A',
    fontSize: 24,
  },
  titleModal: {
    color: '#333333',
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 18,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  containerButton: {
    paddingVertical: 15,
    marginTop: 15,
  },
  buttonUnlinkStyle: {
    backgroundColor: '#FF7043',
  },
  containerValidationTitle: {
    marginTop: 20,
  },
  validationTitle: {
    fontWeight: '500',
    fontSize: 16,
    color: '#333',
  },
  card: {
    marginBottom: 8,
    padding: 12,
    backgroundColor: '#FBE9E7',
  },
  titleStyle: {
    fontSize: 16,
    color: '#333',
  },
  contentReplacement: {
    marginTop: 10,
  },
  cardConfirm: {
    marginBottom: 8,
    padding: 12,
    backgroundColor: '#FBE9E7',
  },
  cardConfirmSuccess: {
    marginBottom: 8,
    padding: 12,
    backgroundColor: '#E8F5E9',
  },
  buttonTitle: {
    fontSize: 12,
  },
})

ConfirmUnlink.propTypes = {
  onClose: PropTypes.func.isRequired,
  notfound: PropTypes.bool,
  modal: PropTypes.bool,
  unlinkCollaborator: PropTypes.object,
  selectCollaborator: PropTypes.object,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToUnlinkCollaborators: PropTypes.func.isRequired,
}

export default ConfirmUnlink
