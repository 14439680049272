import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import licenseStates from '@constants/licenseStates'
import useNetwork from '@utils/network'
import { ConnectionContext } from './connection'
import { LanguageContext } from './language'

export const LicenseContext = createContext({})

export const LicensesProvider = ({ children }) => {
  const [licenses, setLicenses] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [licenseSelected, setLicenseSelected] = useState()

  const { doRequest } = useNetwork()
  const { isConnected } = useContext(ConnectionContext)
  const { t } = useContext(LanguageContext)

  /**
   * FETCH LICENSES
   *
   * @param {*} params
   */
  const fetchLicenses = async (params) => {
    setIsloading(true)

    let data = []

    if (isConnected) {
      try {
        const response = await doRequest({
          method: 'GET',
          url: 'licenses/search-by-crop',
          params,
        })

        data = response.data

        setLicenses(sortLicenses(data))

        setIsloading(false)
      } catch (e) {
        setIsloading(false)
      }
    }
  }

  /**
   * FETCH APPLIED LICENSES BY CROP
   *
   * @param {*} params
   */
  const fetchAppliedLicensesByCrop = async ({ cropId, licenseId }) => {
    const params = {
      cropId,
    }

    if (isConnected) {
      try {
        const response = await doRequest({
          method: 'GET',
          url: `licenses/${licenseId}/applied-by-crop`,
          params,
        })

        return response.data
      } catch (e) {
        alert(t('CONSTANTS').LICENCES.TEXT4)
      }
    }
  }

  /**
   * SORT LICENSES
   *
   * @param {*} licenses
   */
  const sortLicenses = (licenses) => {
    const appliedLicenses = []
    const signedLicenses = []
    const shortHasLicenses = []
    const shortTimeLicenses = []
    const newLicenses = []
    const normalLicenses = []

    licenses.map((element) => {
      if (element.state === licenseStates.APPLIED.key) {
        appliedLicenses.push(element)
      } else if (element.state === licenseStates.SIGNED.key) {
        signedLicenses.push(element)
      } else if (element.state === licenseStates.SHORT_HAS.key) {
        shortHasLicenses.push(element)
      } else if (element.state === licenseStates.SHORT_TIME.key) {
        shortTimeLicenses.push(element)
      } else if (element.state === licenseStates.NEW.key) {
        newLicenses.push(element)
      } else {
        normalLicenses.push(element)
      }
    })

    return [
      ...appliedLicenses,
      ...signedLicenses,
      ...shortHasLicenses,
      ...shortTimeLicenses,
      ...newLicenses,
      ...normalLicenses,
    ]
  }

  /**
   * SELECT LICENSE
   *
   * @param {*} license
   */
  const selectLicense = (license) => {
    setLicenseSelected(license)
  }

  return (
    <LicenseContext.Provider
      value={{
        fetchLicenses,
        fetchAppliedLicensesByCrop,
        licenses,
        isLoading,
        licenseSelected,
        selectLicense,
      }}
    >
      {children}
    </LicenseContext.Provider>
  )
}

LicensesProvider.propTypes = {
  children: PropTypes.object,
}
