import activityTypes from '@constants/activityTypes'
import {
  harvestFormValues,
  monitoringFormValues,
  agreementFormValues,
  verificationFormValues,
  achievementUnPlannedFormValues,
} from './index'

export const getFormikInitialValues = (params) => {
  const { tag, isDirectAchievement } = params

  if (isDirectAchievement) {
    return achievementUnPlannedFormValues(params)
  }

  switch (tag) {
    case activityTypes.ACT_HARVEST.key:
      return harvestFormValues({ ...params, canShowOutlier: false })

    case activityTypes.ACT_MONITORING.key:
      return monitoringFormValues({ ...params, canShowOutlier: false })

    case activityTypes.ACT_AGREEMENTS.key:
      return agreementFormValues(params)

    case activityTypes.ACT_VERIFICATION.key:
      return verificationFormValues(params)
  }
}
