import { Field, Formik } from 'formik'
import React, { useCallback, useContext, useState } from 'react'
import {
  StyleSheet,
  View,
  KeyboardAvoidingView,
  Image,
  ScrollView,
} from 'react-native'
import { Text } from 'react-native-paper'
import { calculateDosage } from '@ucropit/eiq-calculator'
import Input from '@components/common/Input'
import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import { SUPPLY } from '@constants/iconGuide'
import { round } from 'lodash'
import { SelectorContext } from '@contextState/selectors'
import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, buttonDisabled, blackHighEmphasis } from '@styles/palette'
import { CommonContext } from '@contextState/common'
import { CropContext } from '@contextState/crop'
import { AuthContext } from '@contextState/auth'
import Outlier from '@modules/common/components/Outlier'
import {
  outlierType,
  outlierIcons,
  statusOutlier,
} from '@constants/outlierData'
import useNetwork from '@utils/network'
import { outlierOptionsRequest, urlsOutlierParser } from '@common/utils'

import { useFocusEffect } from '@react-navigation/native'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { useStandardDeviation } from '@common/hooks'
import { unitMeasureSystem as UnitMeasureSystem } from '@utils/constants'
import { truncateSurface } from '@common/utils/truncateSurface'

function SupplyLoaderScreen({ route, navigation }) {
  const { doRequest } = useNetwork()
  const { item, surface, suppliesSelected } = route.params

  const supplySelected = suppliesSelected?.find(
    (supply) => item._id === supply._id
  )
  const { supplySelector, setSupplySelector } = useContext(SelectorContext)
  const { unitTypesSupplies } = useContext(CommonContext)
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  const { currentCrop } = useContext(CropContext)
  const { config } = useContext(AuthContext)
  const [outlier, setOutlier] = useState()

  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })

  const [initialValues, setInitialValues] = useState({
    _id: item._id,
    supplyType: item.typeId || item.typeSupply || item.supplyType,
    unitTypeSupply: item.unitTypeSupply,
    name: item.name,
    unit: item.unit,
    quantity: item?.total / surface || 0,
    total: item?.total
      ? item.total.toString()
      : supplySelected?.total
      ? supplySelected?.total.toString()
      : '',
    eiqTotal: '',
    surface: surface ? surface.toString() : '0',
    areaUnit: currentCrop?.areaUnit
      ? currentCrop?.areaUnit
      : (config?.companySelected?.unitMeasureSystem ===
        UnitMeasureSystem.IMPERIAL
          ? 'ac'
          : 'ha') || '',
  })

  const {
    showDeleteModal,
    setShowDeleteModal,
    setShowDeleteSupplyIcon,
    setShowDeleteIcon,
  } = useContext(CommonContext)

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line no-prototype-builtins
      if (route.params.hasOwnProperty('readOnly')) {
        setShowDeleteSupplyIcon(!route.params.readOnly)
      } else {
        setShowDeleteSupplyIcon(Boolean(item?.total))
      }

      setShowDeleteIcon(false)

      if (item) {
        fetchOutlierRequest(item)
      }

      return () => {
        setShowDeleteSupplyIcon(false)

        return true
      }
    }, [item])
  )

  const unitName = unitTypesSupplies.find(
    (unit) => unit?.key?.toLowerCase() === item?.unit?.toLowerCase()
  )

  function validateAmount(value) {
    const number = value.replace(',', '.')
    let error
    if (!value) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_2
    } else if (!Number(number)) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_3
    } else if (Number(number) < 0) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_4
    }
    return error
  }

  async function onChangeTotal(value) {
    if (!value) {
      setInitialValues({
        ...initialValues,
        total: value,
        quantity: '0',
      })

      setOutlier()

      return t('VIEWS').SUPPLY_SELECTOR.TEXT_2
    }

    if (!validateAmount(value)) {
      const total = value.replace(',', '.')

      const newSupply = {
        ...initialValues,
        total,
        quantity: calculateDosage(
          Number(total),
          Number(initialValues.surface),
          6
        ).toString(),
      }

      item.total = Number(newSupply.total)
      item.quantity = Number(newSupply.quantity)

      setInitialValues(newSupply)

      await fetchOutlierRequest(newSupply)
    }

    return validateAmount(value)
  }

  const getOutlierRequest = async (url) => {
    if (!url) {
      return
    }

    let data

    try {
      const request = await doRequest({
        method: 'GET',
        url: url,
        displayAlert: false,
      })

      data = request.data
    } catch (error) {
      console.error(error)

      return
    }

    return data
  }

  const fetchOutlierRequest = async (newSupply) => {
    const { typeSupply, typeId } = item
    const tags = typeSupply?.tags || typeId?.tags

    const optionOutlier = outlierOptionsRequest(tags)

    if (!optionOutlier) {
      return
    }

    const { url } = urlsOutlierParser(newSupply, optionOutlier, surface)

    const response = await getOutlierRequest(url)

    if (!response) {
      return
    }

    if (tags.includes('SEEDS')) {
      setOutlier(statusOutlier[response.status])
    } else if (tags.includes('FERTILIZERS')) {
      setOutlier(statusOutlier[response.status])
    } else if (tags.includes('PHYTOTHERAPICS')) {
      let outlier

      response.forEach((element) => {
        if (!outlier && statusOutlier[element.status]) {
          outlier = statusOutlier[element.status]

          return
        }

        if (statusOutlier[element.status] === outlierType.DANGER) {
          outlier = statusOutlier[element.status]

          return
        }

        if (
          statusOutlier[element.status] === outlierType.WARNING &&
          outlier !== outlierType.DANGER
        ) {
          outlier = statusOutlier[element.status]

          return
        }
      })

      setOutlier(outlier)
    }
  }

  function handleSelect(values) {
    values.quantity = Number(values.quantity.replace(',', '.'))
    values.total = Number(values.total.replace(',', '.'))

    const supply = {
      ...values,
      code: item.code,
      typeId: item.typeId,
      icon: item.typeId.icon,
      supplyId: item._id,
      eiqTotal: item.eiqTotal,
      company: item.company,
      classToxic: item.classToxic,
      composition: item.composition ?? item.compositon,
      activeIngredients: item.activeIngredients?.length
        ? item.activeIngredients
        : [],
      brand: item?.brand,
      unitTypeSupply: item.unitTypeSupply,
      unitName: unitName ? unitName?.keyLabel : item?.unitTypeSupply?.key || '',
    }

    if (route.params.callBack) {
      route.params.callBack(supply)

      navigation.popToTop()

      return
    }

    let result = []

    if (supplySelector.find(({ _id }) => _id === supply._id)) {
      result = supplySelector.map((element) =>
        element._id !== supply._id ? element : supply
      )
    } else {
      result = [...supplySelector, supply]
    }

    setSupplySelector(result)

    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.supplies = result
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.popToTop()
    }
  }

  const closeModalDelete = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteSupply = (key) => {
    const result = supplySelector.filter((el, index) => key !== index)
    setSupplySelector(result)
    closeModalDelete()
    if (route.params.callBack) {
      route.params.callBack(key)
      navigation.goBack()
      return
    }
    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.supplies = result
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.goBack()
    }
  }

  if (item === undefined) return null

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={20}
      behavior='padding'
      style={styles.container}
    >
      <ScrollView>
        <View style={styles.supplyInfo}>
          <Image
            style={styles.image}
            source={SUPPLY[item.typeId.code] || SUPPLY['default']}
          />
          <View style={styles.supplyInfoTexts}>
            <Text style={styles.supplyName}>{item.brand ?? item.name}</Text>
            {item.brand && (
              <Text style={[styles.disabledText, styles.supplyProperty]}>
                {item.name}
              </Text>
            )}
            <Text style={[styles.disabledText, styles.supplyProperty]}>
              {item.typeId?.codeLabel ?? item.typeId.name}
            </Text>
            <Text style={[styles.disabledText, styles.supplyProperty]}>
              {item.company}
            </Text>
          </View>
        </View>

        {!!item?.activeIngredients.length && (
          <>
            <View style={styles.containerSegment}>
              <View style={styles.containerData}>
                <Text style={[styles.disabledText, styles.disabledTitles]}>
                  {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_1}
                </Text>
                <Text style={styles.activeIngredientPlacement}>
                  {isConnected && (
                    <View>
                      <Text style={[styles.disabledText, styles.supplyData]}>
                        {item.activeIngredientsLabel}
                      </Text>
                    </View>
                  )}
                  {!isConnected &&
                    item.activeIngredients.map((ingredient, key2) => (
                      <View key={key2}>
                        <Text
                          key={key2}
                          style={[styles.disabledText, styles.supplyData]}
                        >
                          {ingredient.activeIngredient.name[i18n.locale]}
                        </Text>
                        {key2 !== item.activeIngredients.length - 1 && (
                          <Text style={styles.disabledText} key={key2}>
                            ,{' '}
                          </Text>
                        )}
                      </View>
                    ))}
                </Text>
              </View>
              <View style={styles.containerData}>
                <Text style={[styles.disabledText, styles.disabledTitles]}>
                  {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_2}
                </Text>
                <Text style={[styles.disabledText, styles.supplyData]}>
                  {round(Number(item.eiqTotal), 2)}
                </Text>
              </View>

              <View style={styles.containerData}>
                <Text style={[styles.disabledText, styles.disabledTitles]}>
                  {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_3}
                </Text>
                {!!item.classToxic && (
                  <Text style={[styles.disabledText, styles.supplyData]}>
                    {`${item.classToxic}`}
                  </Text>
                )}
              </View>

              <View style={styles.separator} />
            </View>
          </>
        )}

        <View style={styles.containerSegment}>
          <View style={styles.containerData}>
            <Text style={[styles.disabledText, styles.disabledTitles]}>
              {t('VIEWS.SUPPLY_SELECTOR_LOADER.TEXT_4', {
                areaUnit: initialValues.areaUnit,
              })}
            </Text>
            <Text style={[styles.disabledText, styles.supplyData]}>
              {truncateSurface(Number(initialValues.surface))}
            </Text>
          </View>
          <View style={styles.containerData}>
            <Text style={[styles.disabledText, styles.disabledTitles]}>
              {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_5}
            </Text>
            <Text style={[styles.disabledText, styles.supplyData]}>
              {unitName?.keyLabel || item?.unitTypeSupply?.key || ''}
            </Text>
          </View>

          <View style={styles.containerData}>
            <Text style={[styles.disabledText, styles.disabledTitles]}>
              {t('VIEWS.SUPPLY_SELECTOR_LOADER.TEXT_6', {
                unit: unitName?.keyLabel || item?.unitTypeSupply?.key || '',
                areaUnit: initialValues.areaUnit,
              })}
            </Text>
            <Text style={[styles.disabledText, styles.supplyData]}>
              {`${round(Number(initialValues.quantity), 6)}`}
            </Text>
          </View>
        </View>
      </ScrollView>
      <View style={styles.bottomComponents}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSelect}
        >
          {({ handleSubmit, values }) => (
            <View style={styles.modalContainer}>
              <Field
                style={styles.input}
                name='total'
                component={Input}
                inputProps={{
                  label: t('VIEWS').SUPPLY_SELECTOR.FIELD_3.LABEL,
                  keyboardType: 'decimal-pad',
                  returnKeyType: 'done',
                  returnKeyLabel: t('VIEWS').SUPPLY_SELECTOR.FIELD_3.KEY,
                }}
                validate={onChangeTotal}
              />
              {outlier && (
                <View style={styles.outlierContainer}>
                  <Outlier
                    icon={outlierIcons.InfoFill}
                    title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
                    type={outlier}
                    onPress={openBackdropStandardDeviation}
                  />
                </View>
              )}
              <ButtonCustom
                onPress={handleSubmit}
                styles={[
                  styles.button,
                  !values.total ? styles.buttonDisabled : {},
                ]}
                labelStyle={[
                  styles.textButton,
                  !values.total ? styles.textButtonDisabled : {},
                ]}
              >
                {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_9}
              </ButtonCustom>
            </View>
          )}
        </Formik>
      </View>
      <ComponentStandarDeviation />
      <ModalConfirm
        visible={showDeleteModal}
        onClose={closeModalDelete}
        onConfirm={() => handleDeleteSupply(route.params.key)}
        title={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_1}
        contentText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_2}
        cancelText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_3}
        confirmText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 0,
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  containerSegment: {
    paddingHorizontal: 16,
    paddingVertical: 13,
  },
  disabledText: {
    color: '#333333B2',
  },
  supplyProperty: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    textAlign: 'left',
  },
  image: {
    width: 40,
    height: 40,
    marginTop: 8,
  },
  supplyInfoTexts: {
    flex: 1,
    paddingHorizontal: 16,
  },
  supplyName: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  disabledTitles: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.4,
    textAlign: 'left',
    flexWrap: 'wrap',
  },
  containerData: {
    paddingBottom: 4,
  },
  supplyData: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  supplyInfo: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  modalContainer: {
    padding: 4,
  },
  activeIngredientPlacement: {
    flexDirection: 'row',
  },
  separator: {
    borderBottomColor: '#0000001F',
    borderBottomWidth: 1,
    marginHorizontal: 32,
    paddingTop: 8,
  },
  bottomComponents: {
    marginTop: 'auto',
  },
  input: {
    backgroundColor: '#FFFFFF',
    margin: 20,
  },
  button: {
    backgroundColor: primary500,
    marginTop: 8,
    marginBottom: 10,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
  },
  buttonDisabled: {
    backgroundColor: buttonDisabled,
  },
  textButtonDisabled: {
    color: blackHighEmphasis,
  },
  textButton: {
    color: '#FFFFFF',
  },
  outlierContainer: {
    marginTop: 15,
    marginHorizontal: 0,
    marginBottom: 40,
  },
})

export default SupplyLoaderScreen
