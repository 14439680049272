import React, { useContext, useEffect, useRef, useState } from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { useDispatch } from 'react-redux'

import { LanguageContext } from '@contextState/language'
import { FabButton, HeaderOnlyBack, SwiperFlatList } from '@common/components'
import { blackHighEmphasis, white } from '@styles/palette'
import { authActions } from '@store/actions/auth'
import { CommonIcon } from '@modules/common/components'

import ItemSwiper from './ItemSwiper'
import { IMAGE_WIZZARD_1, IMAGE_WIZZARD_2, IMAGE_WIZZARD_3 } from './Images'
import WIZARD_WELCOME_1 from '@assets/animations/lottie/wizardWelcome1'
import WIZARD_WELCOME_2 from '@assets/animations/lottie/wizardWelcome2'
import WIZARD_WELCOME_3 from '@assets/animations/lottie/wizardWelcome3'

export const WizzardIntro = () => {
  const { t } = useContext(LanguageContext)
  const swiperRef = useRef(null)
  const [canShowClose, setCanShowClose] = useState(true)
  const dispatch = useDispatch()
  const data = [
    {
      _id: '1',
      title: t('VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_1.WELCOME'),
      description: t(
        'VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_1.PARAGRAPH'
      ),
      image: IMAGE_WIZZARD_1,
      animation: WIZARD_WELCOME_1,
      animationRef: useRef(null),
    },
    {
      _id: '2',
      title: t(
        'VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_2.TRACE_HISTORY_CROPS'
      ),
      description: t(
        'VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_2.PARAGRAPH'
      ),
      image: IMAGE_WIZZARD_2,
      animation: WIZARD_WELCOME_2,
      animationRef: useRef(null),
    },
    {
      _id: '3',
      title: t(
        'VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_3.DIGITAL_BACKUP'
      ),
      description: t('VIEWS.SIGN_IN.COMPONENTS.WIZZARD_INTRO.WIZARD_3.ITEMS'),
      image: IMAGE_WIZZARD_3,
      animation: WIZARD_WELCOME_3,
      animationRef: useRef(null),
    },
  ]

  const closeWizzard = () => {
    dispatch(authActions.setHideWizzardIntro(true))
  }

  const onResetAnimation = (index) => {
    if (Platform.OS === 'web') {
      data[index]?.animationRef.current?.stop()
    } else {
      data[index]?.animationRef.current?.reset()
    }
    data[index]?.animationRef.current?.play()
  }

  useEffect(() => {
    setTimeout(() => {
      if (Platform.OS !== 'web') {
        data[0]?.animationRef?.current?.play()
      }
    }, 300)
  })

  const onChangeIndex = (params) => {
    const validation = params?.index < data.length - 1
    setCanShowClose(validation)
    onResetAnimation(params?.index)
  }

  const goToNextItem = () => {
    const currentIndex = swiperRef?.current?.getCurrentIndex() || 0
    const validation = currentIndex < data.length - 1
    if (validation) {
      swiperRef?.current?.scrollToIndex({
        index: currentIndex + 1,
      })
    } else {
      closeWizzard()
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.containerHeaderBack}>
        {canShowClose && (
          <HeaderOnlyBack
            icon={'X'}
            onPress={closeWizzard}
            iconColor={blackHighEmphasis}
            style={styles.header}
          />
        )}
      </View>
      <SwiperFlatList
        swiperRef={swiperRef}
        items={data}
        ItemComponent={ItemSwiper}
        goToNextItem={goToNextItem}
        onChangeIndex={onChangeIndex}
      />
      <FabButton
        icon={(props) => <CommonIcon {...props} name={'ARROW-RIGHT'} />}
        onPress={goToNextItem}
        style={styles.fabButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerHeaderBack: {
    height: 88,
  },
  header: { paddingHorizontal: 20 },
  container: { flex: 1, backgroundColor: white },
  fabButton: { bottom: 17, marginRight: 10 },
})
