import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

import { SustainabilityIcon } from '@modules/common/components'

const TitleSection = ({ iconName, text }) => (
  <View style={styles.container}>
    <SustainabilityIcon name={iconName} size={30} style={styles.icon} />
    <Text style={styles.text}>{text}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 16,
  },
  text: {
    fontWeight: '500',
    color: '#333333',
    fontSize: 16,
  },
})

TitleSection.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default TitleSection
