import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'
import {
  View,
  ScrollView,
  StyleSheet,
  Platform,
  Linking,
  ActivityIndicator,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { CompanyDetails } from '@common/components'
import { useCompanyInfo } from '@modules/common/hooks'
import { COMPANY_TYPE_PERSON } from '@constants/companyTypePerson'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { userPermissions } from '@utils'
import useNetwork from '@utils/network'
import { primary500 } from '@styles/palette'
import ItemList from './components/ItemList'
import EvidencesList from '@components/common/v1/EvidencesList'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import ModalUploadFile from './components/ModalUploadFile'
import { CommonIcon } from '@modules/common/components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import useModal from '@hooks/useModal'
import ApproveModal from './components/ApproveCompanyModal/index'
import { canApproveCompany, canSeeApprovedCompany } from '@utils/common'
import RouterNames from '@constants/routerNames'

const pattern = new RegExp('^http')
const apiUrl = Constants.manifest.extra.EXPO_HOST || ''

const CompanyDetail = ({ navigation }) => {
  const { doRequest } = useNetwork()
  const { t } = useContext(LanguageContext)
  const { fetchUser, config } = useContext(AuthContext)
  const { roleSelected } = config

  const [files, setFiles] = useState([])
  const [visible, setVisible] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const { _id, companyName: name, allRest } = useCompanyInfo()
  const [companyData, setCompanyData] = useState({ name, ...allRest })
  const { isVetted = false } = companyData
  const [isViewReady, setIsViewReady] = useState(false)

  const { isModalVisible, closeModal, toggleModal } = useModal()

  const hasPermissionEdit = Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.EDIT_COMPANY
    )
  )
  const canSeeIsVerifiedCompany =
    canSeeApprovedCompany({ roleSelected }) && isVetted

  // const hasPermissionEdit = false

  useFocusEffect(
    useCallback(() => {
      if (!_id) {
        setIsViewReady(true)
        return null
      }
      fetchCompany()
      fetchUser()
    }, [_id])
  )

  const fetchCompany = async () => {
    setIsViewReady(false)
    const response = await doRequest({
      method: 'GET',
      url: `companies/${_id}`,
    })
    setCompanyData(response.data)
    setFiles(
      response.data.files.map((file) => ({
        ...file,
        persisted: true,
      }))
    )
    setIsViewReady(true)
  }

  const updateCompanyStatus = async () => {
    try {
      await doRequest({
        method: 'PUT',
        url: `companies/${_id}/change-status`,
        version: 'v2',
        data: {
          isVetted: true,
        },
      })
      closeModal()
      setCompanyData({ ...companyData, isVetted: true })
    } catch (error) {
      console.warn(error)
    }
  }

  const onPressEvidenceFile = (evidence) => {
    try {
      let path
      if (evidence.persisted) {
        path = evidence.isSatelliteImage
          ? evidence.imageSatellite
          : evidence.path
        if (!pattern.test(path)) {
          path = `${apiUrl}/${path}`
        }
      } else {
        path = getSourcePath(evidence)
      }
      if (Platform.OS === 'web') {
        window.open(path, '_blank')
      } else {
        Linking.openURL(path)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  const getSourcePath = useCallback((file) => {
    if (file.isSatelliteImage) {
      return file.imageSatellite
    }

    if (file.file?.uri) {
      return file.file.uri
    }

    if (typeof file.file === 'string' || file.file instanceof String) {
      return file.file
    }

    if (file.pathThumbnails || file.path) {
      const path = file.pathThumbnails ? file.pathThumbnails : file.path

      if (pattern.test(path)) {
        return path
      }

      return `${apiUrl}/${path}`
    }

    return URL.createObjectURL(file.file)
  }, [])

  function handleFile({ file }) {
    setSelectedFile(file)
  }

  function handleFileSubmit(values) {
    setFiles([...files, { ...values, file: selectedFile }])
    setVisible(false)
    setSelectedFile(null)
  }

  function handleFileDelete(index) {
    if (!files[index].persisted) {
      setFiles(files.filter((el, key) => key !== index))
    }
  }

  const goEditCompany = () => {
    navigation.navigate('EditCompany')
  }

  if (!isViewReady) {
    return <ActivityIndicator size='large' color={primary500} />
  }

  return (
    <>
      <View style={styles.container}>
        <ScrollView style={styles.scrollView}>
          <CompanyDetails
            companyName={companyData.name}
            companyIdentifier={companyData.identifier}
            verifiedCompany={canSeeIsVerifiedCompany}
          />
          {Boolean(companyData?.countryObject?.name) && (
            <ItemList
              title={t('VIEWS.COMPANY_DETAIL.TEXT_3')}
              description={companyData?.countryObject?.name}
            />
          )}

          {Boolean(companyData.typePerson) && (
            <ItemList
              title={t('VIEWS.COMPANY_DETAIL.TEXT_4')}
              description={COMPANY_TYPE_PERSON[companyData.typePerson].name(t)}
            />
          )}
          {Boolean(companyData.address) && (
            <ItemList
              title={t('VIEWS.COMPANY_DETAIL.TEXT_5')}
              description={companyData.address}
            />
          )}

          {Boolean(companyData.addressFloor) && (
            <ItemList
              title={t('VIEWS.COMPANY_DETAIL.TEXT_6')}
              description={companyData?.addressFloor}
            />
          )}

          <View style={styles.marginHorizontal}>
            <EvidencesList
              evidences={files}
              onPress={onPressEvidenceFile}
              evidenceNameTitle
              onDelete={handleFileDelete}
              canRemovePersisted={false}
            />

            <CollaboratorsButton
              title={t('COMPONENTS.COLLABORATORS_BUTTON.COMPANY_COLLABORATORS')}
              pendingAuthorization={Boolean(
                companyData?.hasPendingAuthorization
              )}
              companyDetails
              quantity={companyData?.countCollaborators || 0}
              onPress={() =>
                companyData?.countCollaborators
                  ? navigation.navigate(RouterNames.COLLABORATOR_LIST_COMPANY)
                  : null
              }
            />

            {canApproveCompany({ roleSelected }) && !isVetted && (
              <ButtonDefault
                text={t(`VIEWS.COMPANY_DETAIL.APPROVE`)}
                typeButton='outline_success'
                height={48}
                borderRadius={8}
                fontSize={16}
                fontWeight='700'
                disabled={!hasPermissionEdit}
                marginTop={44}
                onPress={toggleModal}
                icon={
                  <CommonIcon name={'VERIFIED'} size={20} color={primary500} />
                }
                iconStart
              />
            )}

            <ButtonDefault
              text={t(`VIEWS.COMPANY_DETAIL.TEXT_7`)}
              typeButton={hasPermissionEdit ? 'outline_success' : 'disabled'}
              height={48}
              borderRadius={8}
              fontSize={16}
              fontWeight='700'
              disabled={!hasPermissionEdit}
              marginTop={8}
              onPress={goEditCompany}
              icon={<CommonIcon name={'EDIT'} size={20} color={primary500} />}
              iconStart
            />

            <ButtonDefault
              text={t(`VIEWS.COMPANY_DETAIL.TEXT_9`)}
              height={48}
              borderRadius={8}
              fontSize={16}
              fontWeight='700'
              marginTop={25}
              onPress={() => navigation.goBack()}
            />
          </View>
        </ScrollView>
      </View>

      <ModalUploadFile
        visible={visible}
        setVisible={setVisible}
        handleFileSubmit={handleFileSubmit}
        handleFile={handleFile}
        selectedFile={selectedFile}
      />
      <ApproveModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        toggleModal={toggleModal}
        updateCompanyStatus={updateCompanyStatus}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginBottom: 16,
  },
  marginHorizontal: {
    marginHorizontal: 16,
  },
})

CompanyDetail.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default CompanyDetail
