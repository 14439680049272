import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { grayInput } from '@styles/palette'

import activityTypes from '@constants/activityTypes'
import statusEiq from '@constants/statusEiq'

import { LanguageContext } from '@contextState/language'
import { ConnectionContext } from '@contextState/connection'
import { achievementCard } from '../utils'
import { EiqIcon } from './EiqIcon'

const EiqAchievement = ({ item, crop, eiqRange, achievementsEiq }) => {
  const { t } = useContext(LanguageContext)
  const { isConnected } = useContext(ConnectionContext)
  return (
    <>
      <Text style={styles.textActivities}>
        {`${t('VIEWS').ACTIVITIES.TEXT_14} ${item.surface} ${
          t('VIEWS').ACTIVITIES.TEXT_13
        }`}
      </Text>

      <Text style={styles.textActivitiesSecond}>
        {achievementCard({ activity: item, crop })}
      </Text>

      {item?.type?.tag === activityTypes.ACT_APPLICATION.key && isConnected && (
        <View
          style={[
            styles.eiqContainer,
            {
              backgroundColor: statusEiq[eiqRange]?.background,
            },
          ]}
        >
          <EiqIcon
            name={activityTypes.ACT_APPLICATION.key}
            color={statusEiq[eiqRange]?.color}
          />
          <Text
            style={[
              styles.eiqText,
              {
                color: statusEiq[eiqRange]?.color,
              },
            ]}
          >
            {`${t('VIEWS').ACTIVITIES.TEXT_24} ${achievementsEiq}`}
          </Text>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  textActivities: {
    color: grayInput,
    paddingRight: 5,
    fontSize: 14,
  },
})

EiqAchievement.propTypes = {
  item: PropTypes.object.isRequired,
  crop: PropTypes.object.isRequired,
  eiqRange: PropTypes.string.isRequired,
  achievementsEiq: PropTypes.number.isRequired,
}

export default EiqAchievement
