import useNetwork from '@utils/network'

export const useUpdateSurface = ({
  farmUuid,
  farmName,
  company,
  identifier,
  File,
  setError,
  setVisible,
  toggleModal,
}) => {
  const { doRequest } = useNetwork()

  const updateSurfaceFarms = async () => {
    const formData = new FormData()

    formData.append('name', farmName)
    formData.append('companyId', company)
    formData.append('identifier', identifier)
    formData.append('lots', null)
    formData.append('lotsFiles', null)

    if (File && Object.entries(File).length) {
      formData.append('farmFile', File)
      formData.append('removeFarmFile', false)
    } else {
      formData.append('farmFile', null)
      formData.append('removeFarmFile', true)
    }

    try {
      await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'PUT',
        url: `farms/${farmUuid}`,
        data: formData,
        displayAlert: false,
      })
    } catch (error) {
      setVisible(false)
      setError({ message: error?.message || error?.code || error?.error })
      toggleModal()
    }
  }

  return {
    updateSurfaceFarms,
  }
}
