import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import InputText from '@components/inputs/InputText'
import InputSelectDropdown from '@components/inputs/InputSelectDropdown'
import InputMultiline from '@components/inputs/InputMultiline'
import { black } from '@styles/palette'

export const VerificationForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  readOnly = false,
  onPressLotsInput,
  openConfirmationChangeField,
  crop: { areaUnit },
}) => {
  const { user } = useContext(AuthContext)
  const { t } = useContext(LanguageContext)
  const { verificationTypesForm } = useSelector(
    (state) => state.verificationTypes
  )
  const [disabledVerificationType, setDisabledVerificationType] =
    useState(false)

  useEffect(() => {
    if (verificationTypesForm.length === 1) {
      setDisabledVerificationType(true)
      setFieldValue('verificationType', verificationTypesForm[0])
    } else if (values.verificationType?.value) {
      if (verificationTypesForm.find((element) => !element.disabled)) {
        setDisabledVerificationType(false)
      } else {
        setDisabledVerificationType(true)
      }
    } else {
      setDisabledVerificationType(false)
    }
  }, [verificationTypesForm])

  return (
    <View>
      <InputSelectDropdown
        id='verificationType'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-DOWN'}
            size={20}
            color={
              readOnly || disabledVerificationType ? 'rgba(0, 0, 0, .4)' : black
            }
          />
        }
        onChangeText={(value) => {
          if (
            values.verificationType?.value &&
            values.verificationType?.value !== '' &&
            value.value !== values.verificationType?.value
          ) {
            openConfirmationChangeField(value)
          } else {
            setFieldValue('verificationType', value)
          }
        }}
        onBlur={() => setTimeout(() => setFieldTouched('verificationType'))}
        value={values.verificationType}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_1.LABEL')}`}
        options={verificationTypesForm}
        editable={Boolean(!readOnly) || !disabledVerificationType}
        disabled={Boolean(readOnly) || disabledVerificationType}
        touched={touched.verificationType}
        error={errors.verificationType?.value}
      />

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            color={values.verificationType?.value ? black : 'rgba(0, 0, 0, .4)'}
            onPress={
              values.verificationType?.value ? onPressLotsInput : undefined
            }
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        disabled={!values.verificationType?.value}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_2.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_2.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputMultiline
        id='observation'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('observation', value)
        }}
        value={values.observation}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_4.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.VERIFICATION_FORM.FIELD_4.LABEL')}`}
        editable={Boolean(!readOnly) && Boolean(values.verificationType?.value)}
        disabled={Boolean(readOnly) || Boolean(!values.verificationType?.value)}
        onBlur={() => setFieldTouched('observation')}
        touched={touched.observation}
        error={errors.observation}
        pressIcon={() => {
          if (values.observation && values.observation !== '') {
            const valueObservation = {
              createdBy: {
                _id: user._id,
                firstName: user.firstName,
                lastName: user.lastName,
              },
              createdAt: new Date(),
              text: values.observation,
            }
            setFieldValue('observations', [
              ...values.observations,
              valueObservation,
            ])
            setTimeout(() => setFieldValue('observation', ''))
          }
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})

VerificationForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
}
