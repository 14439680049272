import React from 'react'
import { Platform } from 'react-native'
import Iframe from 'react-iframe'
import { WebView } from 'react-native-webview'
import PropTypes from 'prop-types'

export const WebViewResponsive = ({
  url,
  onNavigationStateChange,
  onMessage,
}) => {
  if (Platform.OS === 'web') {
    window.addEventListener('message', onMessage)
    return (
      <Iframe
        url={url}
        // position='absolute'
        width='100%'
        height='100%'
        frameBorder='0'
        loading='eager'
      />
    )
  }
  const runFirst = `
          window.isNativeApp = true;
          true; // note: this is required, or you'll sometimes get silent failures
        `
  return (
    <WebView
      originWhitelist={['*']}
      source={{ uri: url }}
      scrollEnabled={false}
      incognito={false}
      injectedJavaScriptBeforeContentLoaded={runFirst}
      onNavigationStateChange={onNavigationStateChange}
      onMessage={onMessage}
    />
  )
}

WebViewResponsive.propTypes = {
  url: PropTypes.string.isRequired,
  onNavigationStateChange: PropTypes.func,
  onMessage: PropTypes.func,
}
