import useNetwork from '@utils/network'
import getDatabase from './getDataBase'

const CREATE_QUERY = `CREATE TABLE IF NOT EXISTS subactivity_types (id integer primary key not null, _id text, data text);`
const DROP_QUERY = 'DROP TABLE IF EXISTS subactivity_types;'
const INSERT_QUERY = 'INSERT INTO subactivity_types ( _id, data) values (?, ?);'

function useOfflineSubTypeActivities() {
  const db = getDatabase('db.offlinedata')
  const { doRequest } = useNetwork()

  const dropSubTypeActivityTable = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return null
      }
      db.transaction((tx) => {
        tx.executeSql(
          DROP_QUERY,
          [],
          () => resolve(),
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  async function getTransaction() {
    const promise = new Promise((resolve, reject) => {
      if (!db) {
        return resolve(null)
      }
      db.transaction(
        (tx) => resolve(tx),
        (err) => reject(err)
      )
    })

    return promise
  }

  const initOfflineSubTypeActivities = async () => {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      if (!db) {
        return resolve()
      }

      db.transaction((tx) => {
        tx.executeSql(
          CREATE_QUERY,
          [],
          () => resolve(),
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  const syncSubTypeActivities = async () => {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve, reject) => {
      if (!db) {
        return resolve()
      }
      const response = await doRequest({
        method: 'GET',
        url: 'activities/subType',
        displayAlert: false,
      })

      const data = response.data

      const tx = await getTransaction()

      try {
        for (const element of data) {
          const dataToInsert = [element._id, JSON.stringify(element)]

          tx.executeSql(INSERT_QUERY, dataToInsert)
        }

        resolve()
      } catch (err) {
        console.warn(err)
        reject(err)
      }
    })

    return promise
  }

  async function getOfflineSubTypeActivities() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * from subactivity_types;`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            resolve(results.map((element) => JSON.parse(element.data)))
          },
          (_, err) => alert(err.message)
        )
      })
    })

    return promise
  }

  async function resetSubTypeActivities() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          'DELETE from subactivity_types;',
          [],
          () => {
            resolve()
          },
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  return {
    initOfflineSubTypeActivities,
    syncSubTypeActivities,
    dropSubTypeActivityTable,
    getOfflineSubTypeActivities,
    resetSubTypeActivities,
  }
}

export default useOfflineSubTypeActivities
