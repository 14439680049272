import FARM_FORM from '../../components/FarmForm/translate/en'
import LOST_ADD_BUTTON from '../../components/LotsAddButton/translate/en'
import SAVE_DRAFT_BUTTON from '../../components/SaveDraftButton/translate/en'
import UPLOAD_AREA from '../../components/UploadArea/translate/en'

export default {
  TEXT_1: 'Try to upload another file',
  TEXT_2: 'Close',
  TEXT_3: 'Area cannot be 0',
  COMPONENTS: {
    FARM_FORM,
    LOST_ADD_BUTTON,
    SAVE_DRAFT_BUTTON,
    UPLOAD_AREA,
  },
}
