import AsyncStorage from '@react-native-async-storage/async-storage'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import promiseMiddleware from 'redux-promise'
import createSagaMiddleware from 'redux-saga'

import reducers from './reducers'
import rootSaga from './sagas/index'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['draftCrop', 'lots', 'auth', 'companies', 'activities'],
  blacklist: ['collaborators'],
}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistReducer(persistConfig, reducers),
  composeWithDevTools(
    applyMiddleware(promiseMiddleware),
    applyMiddleware(sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga)

const persistor = persistStore(store)

export { store, persistor }
