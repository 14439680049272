export default {
  TEXT_1: 'Confirm Executed Area',
  TEXT_2: 'Field area',
  TEXT_3: 'Planned area',
  TEXT_4: 'Area',
  TEXT_5: 'Executed',
  TEXT_6: 'ha',
  TEXT_7: 'Total executed area',
  TEXT_8: 'Confirm Area',
  TEXT_9: 'planned',
  TEXT_10: 'Total planned area',
}
