import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import AccordionItem from '@components/common/v2/AccordionItem'
import {
  blackHighEmphasis,
  gray12,
  grayInput,
  primary500,
  grayBackground,
} from '@styles/palette'
import { Field, FieldArray } from 'formik'

export const AccordionList = ({
  fieldName,
  title,
  subtitle,
  data = [],
  showLoadMore,
  disableLoadMore,
  textLoadMore,
  seeMoreStyle = {},
  onLoadMore,
}) => {
  const renderItem = (item, index) => {
    return (
      <Field
        key={`${fieldName}_${index}`}
        name={`${fieldName}[${index}].selected`}
        component={({ form: { setFieldValue, values } }) => {
          return (
            <View style={styles.item}>
              <Text style={styles.textItem}>{item.name}</Text>

              <CommonIcon
                name={
                  item.selected ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'
                }
                size={24}
                style={
                  item.selected ? styles.checkBoxSelected : styles.checkBox
                }
                onPress={() =>
                  setFieldValue(
                    `${fieldName}[${index}].selected`,
                    !values?.[fieldName][index]?.selected
                  )
                }
              />
            </View>
          )
        }}
      />
    )
  }

  const renderLoadMore = () => {
    return (
      showLoadMore && (
        <TouchableOpacity
          onPress={onLoadMore}
          activeOpacity={0.8}
          disabled={disableLoadMore}
        >
          <Text style={[styles.seeMore, seeMoreStyle]}>{textLoadMore}</Text>
        </TouchableOpacity>
      )
    )
  }

  return (
    <View style={styles.container}>
      <FieldArray
        name={fieldName}
        render={() => (
          <AccordionItem
            openByDefault
            title={title}
            subtitle={subtitle}
            titleContainerStyle={styles.cardTitle}
            titleStyle={styles.title}
            contentStyle={styles.accordion}
          >
            {data?.map(renderItem)}

            {renderLoadMore()}
          </AccordionItem>
        )}
      ></FieldArray>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 6,
  },
  accordion: {
    backgroundColor: grayBackground,
    marginTop: 10,
  },
  title: {
    fontWeight: '500',
    fontSize: 18,
    color: blackHighEmphasis,
    lineHeight: 21,
    letterSpacing: 0.15,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    opacity: 1,
  },
  cardTitle: {
    borderColor: 'transparent',
    borderWidth: 0,
    margin: 0,
    padding: 10,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 20,
  },
  seeMore: {
    color: primary500,
    alignSelf: 'flex-start',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 0.75,
    marginTop: 16,
    marginLeft: 10,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 11,
    borderBottomWidth: 1.2,
    borderBottomColor: gray12,
  },
  textItem: {
    flex: 1,
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 22,
    marginLeft: 8,
  },
  checkBox: {
    fontSize: 25,
    color: grayInput,
    marginRight: 6,
  },
  checkBoxSelected: {
    fontSize: 25,
    marginRight: 6,
    color: primary500,
  },
})

AccordionList.prototype = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.array.isRequired,
  showLoadMore: PropTypes.bool,
  disableLoadMore: PropTypes.bool,
  textLoadMore: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func,
}
