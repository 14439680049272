import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Divider, List } from 'react-native-paper'

import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  secondary700,
  orange100,
} from '@styles/palette'

function CollaboratorsButton({
  title,
  onPress,
  quantity,
  pendingAuthorization = false,
}) {
  const { t } = useContext(LanguageContext)

  const PendingAuthorization = pendingAuthorization && (
    <Text>
      {`\n`}
      <Text style={styles.roleText}>
        {t('COMPONENTS.COLLABORATORS_BUTTON.TEXT_4')}
      </Text>
    </Text>
  )

  return (
    <>
      <List.Item
        onPress={onPress}
        left={() => (
          <View style={styles.collaboratorsIconContainer}>
            <CommonIcon
              name={'COLLABORATORS'}
              size={40}
              color={blackHighEmphasis}
            />
          </View>
        )}
        right={(props) => (
          <View style={styles.collaboratorsIconContainer}>
            <CommonIcon {...props} name={'CHEVRON-RIGHT'} size={20} />
          </View>
        )}
        title={title}
        titleStyle={styles.title}
        description={
          <Text style={styles.description}>
            {quantity
              ? `${quantity} ${t('COMPONENTS.COLLABORATORS_BUTTON.TEXT_1')} `
              : t('COMPONENTS.COLLABORATORS_BUTTON.TEXT_2')}
            {PendingAuthorization}
          </Text>
        }
      />
      <Divider />
    </>
  )
}

const styles = StyleSheet.create({
  collaboratorsIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 55,
  },
  roleText: {
    backgroundColor: orange100,
    color: secondary700,
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 16,
    letterSpacing: 1.5,
    textAlign: 'left',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    paddingHorizontal: 4,
    borderRadius: 4,
  },
  title: {
    fontWeight: '500',
  },
  description: {
    fontSize: 14,
    color: blackMediumEmphasis,
  },
})

CollaboratorsButton.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  quantity: PropTypes.number.isRequired,
  pendingAuthorization: PropTypes.bool,
}

export default CollaboratorsButton
