import { useContext } from 'react'
import { round } from 'lodash'
import PropTypes from 'prop-types'

import activityTypes from '@constants/activityTypes'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import { dateFormatedForMonitorings } from '..'

export const cardActivity = ({ activity, status, crop }) => {
  const { t } = useContext(LanguageContext)
  const { unitTypes, storageTypes } = useContext(CommonContext)

  const activityTag = activity?.type?.tag
  if (
    status === 'PENDING' &&
    activityTag !== activityTypes.ACT_VERIFICATION.key
  ) {
    return `${t('VIEWS.ACTIVITIES.TEXT_12')} ${activity.surface} ${t(
      'VIEWS.ACTIVITIES.TEXT_13'
    )}`
  }

  const unitPay = unitTypes.find((unit) => unit.value === activity.unitType)
  let lotsText = ''
  if (activity.lots) {
    lotsText =
      activity.lots.length > 1
        ? t('VIEWS.ACTIVITIES.TEXT_28', {
            lotsQuantity: activity.lots.length,
            surface: round(activity.surface, 2),
          })
        : t('VIEWS.ACTIVITIES.TEXT_29', {
            lotsQuantity: activity.lots.length,
            surface: round(activity.surface, 2),
          })
  }
  if (activityTag === activityTypes.ACT_MONITORING.key) {
    return `${lotsText}\n${t('VIEWS').ACTIVITIES.TEXT_8}: ${activity.pay} ${
      unitPay?.label
    } \n${t('VIEWS').ACTIVITIES.TEXT_9}: ${dateFormatedForMonitorings(
      activity,
      crop
    )}`
  } else if (activityTag === activityTypes.ACT_HARVEST.key) {
    return `${lotsText}\n${t('VIEWS.ACTIVITIES.TEXT_31', {
      pay: activity.pay,
      unitType: unitPay?.label,
    })} \n${t('VIEWS.ACTIVITIES.TEXT_33', {
      storage: activity.storages
        .map(
          (subElement) =>
            storageTypes?.find(
              (subSubElement) => subSubElement.value === subElement.storageType
            )?.label
        )
        .join(', '),
    })}`
  } else {
    return `${lotsText}`
  }
}

cardActivity.propTypes = {
  activity: PropTypes.object,
  status: PropTypes.string,
  crop: PropTypes.object,
}
