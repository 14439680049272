/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react'
import { ConnectionContext } from '@contextState/connection'
import useOfflineSupplies from '../offline/queries/supplies'
import useNetwork from '@utils/network'

const useFetchPagination = (url, method, refresh = [], options = {}) => {
  const { isConnected } = useContext(ConnectionContext)
  const { getSupplies } = useOfflineSupplies()
  const [response, setResponse] = useState({
    data: [],
    limit: 15,
    skip: 1,
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { doRequest } = useNetwork()
  const [firstPaginate, setFirstPaginate] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { filter } = options
        setIsLoading(true)
        if (filter.page === 1) {
          setFirstPaginate(true)
        }

        filter.skip = (filter.page - 1) * filter.limit
        filter.createAt = new Date().getTime()

        const res = isConnected
          ? await doRequest({
              method,
              url,
              params: filter,
            })
          : await getSupplies({
              ...filter,
              searchValue: filter.queryFiltering,
              activityType: filter.activityType,
              tag: filter.tagType === 'ALL' ? null : filter.tagType,
              orderByAscending: filter.sortType,
            })
        const dataResult = res.data || res
        const { results, hasMoreElements } = dataResult

        setResponse({
          data:
            options.filter.page === 1
              ? results
              : [...response.data, ...results],
          hasMoreElements,
        })

        setFirstPaginate(false)
        setIsLoading(false)
      } catch (e) {
        setError(e)
      }
      setIsLoading(false)
    }

    fetchData()
  }, refresh)
  return { response, error, isLoading, setError, setResponse, firstPaginate }
}

export default useFetchPagination
