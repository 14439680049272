export const DRAFT = 'DRAFT'
export const AVAILABLE = 'AVAILABLE'
export const NOT_AVAILABLE = 'NOT AVAILABLE'

/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const statusFarm = {
  AVAILABLE: AVAILABLE,
  NOT_AVAILABLE: NOT_AVAILABLE,
  DRAFT: DRAFT,
}
