import PropTypes from 'prop-types'
import React from 'react'
import { View, Text, Image, Platform, StyleSheet } from 'react-native'
import { SvgXml } from 'react-native-svg'
import { black, carbon600 } from '@styles/palette'

import { IMAGE_OUTLIERS_GRAPH } from './Images'

export const StandardDeviation = ({ t }) => {
  return (
    <View>
      <View style={[styles.containerImage, styles.space]}>
        {Platform.OS === 'web' ? (
          <Image source={IMAGE_OUTLIERS_GRAPH} style={styles.image} />
        ) : (
          <SvgXml xml={IMAGE_OUTLIERS_GRAPH} width='300px' height='300px' />
        )}
      </View>
      <Text style={[styles.title, styles.space]}>
        {t('COMMON.STANDARD_DEVIATION.STANDARD_DEVIATION')}
      </Text>
      <Text style={[styles.parragraph, styles.space]}>
        {t('COMMON.STANDARD_DEVIATION.PARRAGRAPH_1')}
      </Text>
      <Text style={[styles.parragraph, styles.space]}>
        {t('COMMON.STANDARD_DEVIATION.PARRAGRAPH_2')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  containerImage: {
    alignItems: 'center',
  },
  image: {
    width: 300,
    height: 300,
  },
  title: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 36,
    textAlign: 'center',
    color: black,
  },
  parragraph: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    color: carbon600,
  },
  space: {
    marginBottom: 20,
  },
})

StandardDeviation.propTypes = {
  t: PropTypes.func.isRequired,
}
