import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useNetwork from '@utils/network'

const INITIAL_PAGE = 1
const INITIAL_LOTS = []
const INITIAL_PAGINATION = null

export const useDetailsFarm = (identifier, farmId, isFocused, navigation) => {
  const { doRequest } = useNetwork()
  const [lots, setLots] = useState(INITIAL_LOTS)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)

  useEffect(() => {
    isFocused ? getDetailLotsFarm() : resetState()
  }, [isFocused, currentPage])

  const getDetailLotsFarm = async () => {
    try {
      setIsLoading(true)
      const params = { identifier, farmUuid: farmId, page: currentPage }
      const url = `fields`

      const response = await doRequest({
        method: 'GET',
        url,
        params,
        displayAlert: false,
      })

      const { meta, items } = response.data
      const newLots = currentPage === 1 ? items : [...lots, ...items]

      if (!newLots.length) {
        navigation.navigate('Home', {
          newFarm: true,
        })
      }
      setLots(() => newLots)

      setPagination(meta)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsLoading(false)
    }
  }

  const resetState = () => {
    setLots(INITIAL_LOTS)
    setPagination(INITIAL_PAGINATION)
    setCurrentPage(INITIAL_PAGE)
  }

  const nextPage = async () => {
    if (isLoading || currentPage >= pagination?.totalPages) return

    setCurrentPage(currentPage + 1)
  }

  return {
    lots,
    isLoading,
    nextPage,
  }
}

useDetailsFarm.propTypes = {
  identifier: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  navigation: PropTypes.object.isRequired,
}
