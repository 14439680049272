import React, { useState } from 'react'
import { View, StyleSheet, FlatList, Dimensions } from 'react-native'
import Markdown from 'react-native-markdown-display'
import Modal from 'react-native-modal'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis, white } from '@styles/palette'
import ButtonRipple from '@modules/common/components/buttons/ButtonRipple'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'

const { height } = Dimensions.get('window')
export const MAGIC_MARGIN = 32 //El total de los margenes verticales del modal

export const ModalTerms = ({
  terms,
  isModalVisible,
  acceptTerms,
  t,
  closeModalTerms,
  closeModalReject,
}) => {
  const [isFinished, setisFinished] = useState(false)
  const arrayTermsAux = [0] //este array es para usar el FlatList con la propiedad onEndReached para activar el boton de acepto, solo tiene como finalidad rendirizar los terminos

  const endFinished = () => {
    setisFinished(true)
  }

  const renderTerms = () =>
    terms ? (
      <View>
        <Markdown>{terms}</Markdown>
      </View>
    ) : (
      <LoaderCenterContainer />
    )

  return (
    <Modal isVisible={isModalVisible}>
      <View style={styles.styleModal}>
        <View style={styles.contentModal}>
          <View style={styles.close}>
            <ButtonRipple onPress={closeModalTerms}>
              <CommonIcon name={'X'} size={20} />
            </ButtonRipple>
          </View>
          <FlatList
            data={arrayTermsAux}
            renderItem={renderTerms}
            onEndReachedThreshold={0.1}
            onEndReached={endFinished}
          />
          {acceptTerms && closeModalTerms && (
            <View style={styles.contentButtons}>
              <ButtonDefault
                text={t('COMMON.COMPONENTS.MODAL_TERMS.ACCEPT')}
                marginBottom={8}
                typeButton={isFinished ? 'primary' : 'disabled'}
                disabled={!isFinished}
                onPress={acceptTerms}
              />
              <ButtonDefault
                text={t('COMMON.COMPONENTS.MODAL_TERMS.NOT_ACCEPT')}
                typeButton='inline'
                onPress={closeModalReject}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: blackHighEmphasis,
    opacity: 0.5,
  },
  styleModal: {
    flex: 1,
    backgroundColor: white,
    height: height - MAGIC_MARGIN,
    borderRadius: 16,
    paddingHorizontal: 32,
    paddingTop: 32,
    paddingBottom: 18,
  },
  contentModal: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  close: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: '700',
  },
  contentButtons: {
    marginTop: 11,
  },
})

ModalTerms.propTypes = {
  terms: PropTypes.string,
  isModalVisible: PropTypes.bool,
  acceptTerms: PropTypes.func,
  t: PropTypes.func,
  closeModalTerms: PropTypes.func,
  closeModalReject: PropTypes.func,
}
