import { ScrollView, View } from 'react-native'
import { Menu, TextInput, TouchableRipple, useTheme } from 'react-native-paper'
import React, { forwardRef, useEffect, useState } from 'react'

// eslint-disable-next-line react/display-name
const DropDown = forwardRef(
  (
    {
      visible,
      onDismiss,
      showDropDown,
      value,
      setValue,
      activeColor,
      mode,
      label,
      placeholder,
      inputProps,
      list,
      dropDownContainerMaxHeight,
      theme,
    },
    ref
  ) => {
    const activeTheme = useTheme()

    const [displayValue, setDisplayValue] = useState('')

    const [inputLayout, setInputLayout] = useState({
      height: 64,
      width: 0,
      x: 0,
      y: 0,
    })

    const onLayout = (event) => {
      setInputLayout(event.nativeEvent.layout)
    }

    useEffect(() => {
      const _label = list.find((_) => _.value === value)?.label

      if (_label) {
        setDisplayValue(_label)
      }
    }, [list, value])

    return (
      <Menu
        visible={visible}
        onDismiss={onDismiss}
        theme={theme}
        anchor={
          <TouchableRipple ref={ref} onPress={showDropDown} onLayout={onLayout}>
            <View pointerEvents={'none'}>
              <TextInput
                value={displayValue}
                mode={mode}
                label={label}
                placeholder={placeholder}
                pointerEvents={'none'}
                theme={theme}
                {...inputProps}
              />
            </View>
          </TouchableRipple>
        }
        style={{
          maxWidth: inputLayout?.width,
          width: inputLayout?.width,
          marginTop: inputLayout?.height,
        }}
      >
        <ScrollView
          style={{
            maxHeight: dropDownContainerMaxHeight || 200,
          }}
        >
          {list.map((_item, _index) => (
            <Menu.Item
              key={_index}
              theme={theme}
              titleStyle={{
                color:
                  value === _item.value
                    ? activeColor || (theme || activeTheme).colors.primary
                    : activeColor,
              }}
              onPress={() => {
                setValue(_item.value)
                if (onDismiss) {
                  onDismiss()
                }
              }}
              title={_item.custom || _item.label}
              style={{ width: inputLayout?.width }}
            />
          ))}
        </ScrollView>
      </Menu>
    )
  }
)
export default DropDown
