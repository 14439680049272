import { useState, useContext, useEffect } from 'react'
import Axios from 'axios'
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { errorMap } from '@utils/constants'
import { LanguageContext } from '@contextState/language'

const EXPO_API_HOST = Constants.manifest.extra.EXPO_API_HOST || ''
export const apiUrl = EXPO_API_HOST.replace('/v1', '')

let locale = null
const useNetwork = function () {
  const { t, i18n } = useContext(LanguageContext)

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (i18n) {
      locale = i18n.locale
    }
  }, [i18n?.locale])

  /**
   *
   * @param {object} params
   * @returns {import('axios').AxiosPromise}
   */
  const doRequest = function ({
    method,
    url,
    data = [],
    params = {},
    headers = {},
    version = 'v1',
    displayAlert = true,
    needAuthorizationHeaders = true,
  }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolver, reject) => {
      try {
        setLoading(true)

        headers.Accept = 'application/json'

        if (needAuthorizationHeaders) {
          const token = await AsyncStorage.getItem('USER_TOKEN')
          headers.Authorization = `Bearer ${token}`
        }

        if (locale) {
          headers['Accept-Language'] = locale
        }

        const options = {
          method,
          url: `${apiUrl}/${version}/${url}`,
          data,
          params,
          headers,
        }

        if (method === 'GET' || method === 'get') {
          delete options.data
        }

        const response = await Axios(options)

        setLoading(false)

        return resolver(response)
      } catch (err) {
        const { status = 500, data = {} } = err?.response ?? {}
        const { code, message = 'Bad Request' } = data
        let alertMessage, responseError
        setLoading(false)

        if (code) {
          alertMessage =
            errorMap
              .find((error) => error.data === err.response.data.code)
              ?.message(t) ?? message
          responseError = { status, ...data }
        }

        if (status === 400 && !responseError) {
          alertMessage =
            errorMap
              .find((error) => error.data === err?.response?.data)
              ?.message(t) ?? message
          responseError = { status, ...data }
        }

        if (status !== 401 && status !== 404 && !responseError) {
          if (t) {
            alertMessage = t('UTILS').NETWORK.TEXT_1
          }
        }

        if (displayAlert && alertMessage) {
          alert(alertMessage)
        }

        if (!responseError) {
          const error = data?.error || err.toString()
          responseError = { status, error }
        }
        console.warn(`ERROR AT ${apiUrl}/${version}/${url}`, err?.response)

        return reject(responseError)
      }
    })
  }

  return { doRequest, loading }
}

export default useNetwork
