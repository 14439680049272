import React, { useContext } from 'react'
import { View, Platform } from 'react-native'
import PropTypes from 'prop-types'
import ModalEvidence from '@components/common/ModalEvidence'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { primary500, blackDisabled } from '@styles/palette'

export const AddEvidence = ({
  isAddEvidenceVisible = true,
  isModalEvidenceVisible,
  toggleModalEvidences,
  closeModalEvidences,
  handleSelectEvidence,
  lots,
  tag,
  itemSelected,
}) => {
  if (!isAddEvidenceVisible) {
    return false
  }

  const { t } = useContext(LanguageContext)

  const allowedFormats = [
    '.jpg',
    '.jpeg',
    '.png',
    '.heic',
    '.pdf',
    '.doc',
    '.docx',
    '.txt',
    '.zip',
  ]

  return (
    <View>
      <ButtonPrimary
        text={t('VIEWS').ACTIVITY_CREATE.EVIDENCE.TEXT_1}
        onPress={toggleModalEvidences}
        backgroundColor={'transparent'}
        disabled={lots.value.length <= 0}
        colorText={lots.value.length <= 0 ? blackDisabled : primary500}
      />
      <ModalEvidence
        showCameraRollOption={Platform.OS !== 'web'}
        visible={isModalEvidenceVisible}
        onSelect={handleSelectEvidence}
        handleClose={closeModalEvidences}
        allowedFormats={allowedFormats}
        tag={tag}
        itemSelected={itemSelected}
      />
    </View>
  )
}

AddEvidence.propTypes = {
  isAddEvidenceVisible: PropTypes.bool.isRequired,
  isModalEvidenceVisible: PropTypes.bool.isRequired,
  toggleModalEvidences: PropTypes.func.isRequired,
  closeModalEvidences: PropTypes.func.isRequired,
  handleSelectEvidence: PropTypes.func.isRequired,
  lots: PropTypes.object.isRequired,
  tag: PropTypes.string,
  itemSelected: PropTypes.object,
}
