export * from './create'
export * from './activity'

export const isUserProvider = (user) => {
  if (user?.type === 'PROVIDER') {
    return true
  }
  return false
}

export const isUserMarketer = (user) => {
  if (user?.type === 'MARKETER') {
    return true
  }
  return false
}

export const agreementIncludeKey = (key) => {
  const keys = [
    'COMMER',
    'SUPPLIER',
    'FINAN',
    'EXPLO',
    'SUSTAIN',
    'COTTON_BETS',
    'SEED_USE',
    'RESPONSIBLE_USE',
  ]

  return keys.includes(key)
}

export const checkIsHaveAchievements = (item) => {
  if (item.achievements?.length > 0) {
    return true
  }
  return false
}

export const countSignAchievements = (item) => {
  const totalSingPerAchievement = item.achievements.map((achievement) => {
    return {
      countSigned: achievement.signers.filter((sign) => sign.signed).length,
    }
  })
  return totalSingPerAchievement.reduce(
    (a, b) => a + (b['countSigned'] || 0),
    0
  )
}

export const cantValidateAchievement = (item) => {
  const totalValidate = item.achievements.map((achievement) => {
    return { countSigners: achievement.signers.length }
  })
  return totalValidate.reduce((a, b) => a + (b['countSigners'] || 0), 0)
}

export const dateFormatedForMonitorings = (activity, crop) => {
  let date = ''
  if (activity?.dateEstimatedHarvest) {
    const dateHarvest = new Date(activity?.dateEstimatedHarvest)
    const year = dateHarvest.getFullYear()
    const month = ('0' + (dateHarvest.getMonth() + 1)).slice(-2)
    const day = ('0' + dateHarvest.getDate()).slice(-2)
    date = `${day}/${month}/${year}`
  } else {
    const dateHarvest = new Date(crop.dateHarvest)
    const year = dateHarvest.getFullYear()
    const month = ('0' + (dateHarvest.getMonth() + 1)).slice(-2)
    const day = ('0' + dateHarvest.getDate()).slice(-2)
    date = `${day}/${month}/${year}`
  }

  return date
}
