import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@common/components'
import { carbon100, carbon200, carbon600 } from '@styles/palette'

import { formatSurface, statusFarm } from '@modules/common/utils'

const getAssignedFields = (totalLots, availableLots) => {
  return totalLots - availableLots
}

export const FarmCard = ({
  name = '',
  surface = 0,
  areaUnit = '',
  address = '',
  status = '',
  totalLots = 0,
  availableLots = 0,
  onPress,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardShadow}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.8}>
          <View style={styles.cardContent}>
            <View>
              <CommonIcon
                name={'LOCATION'}
                size={18}
                style={[status === statusFarm.DRAFT && styles.iconDraftColor]}
              />
            </View>
            <View style={styles.cardInfo}>
              <Text
                style={[
                  styles.cardInfoName,
                  status === statusFarm.DRAFT && styles.farmDraftColor,
                ]}
              >
                {name}
              </Text>
              {Boolean(surface) && (
                <Text style={styles.cardInfoDescription}>
                  {formatSurface(surface, areaUnit)}
                </Text>
              )}
              {Boolean(surface) && (
                <Text style={styles.cardInfoDescription}>{address}</Text>
              )}

              {status === statusFarm.DRAFT && (
                <View style={styles.draftChip}>
                  <Text style={styles.draftChipText}>
                    {t('VIEWS.FARM_LIST_V2.COMPONENTS.FARM_CARD.DRAFT_STATUS')}
                  </Text>
                </View>
              )}
              {status !== statusFarm.DRAFT && (
                <View style={styles.lotsInfo}>
                  <Text style={styles.lotsTitle}>
                    {totalLots}{' '}
                    {t('VIEWS.FARM_LIST_V2.COMPONENTS.FARM_CARD.LOTS')}
                  </Text>
                  <Text style={styles.cardInfoDescription}>
                    {getAssignedFields(totalLots, availableLots) === 0
                      ? ''
                      : `${getAssignedFields(totalLots, availableLots)} ${t(
                          'VIEWS.FARM_LIST_V2.COMPONENTS.FARM_CARD.TOTALS'
                        )}`}

                    {getAssignedFields(totalLots, availableLots) !== 0 &&
                    availableLots > 0
                      ? ', '
                      : ''}
                    {availableLots > 0
                      ? `${availableLots} ${t(
                          'VIEWS.FARM_LIST_V2.COMPONENTS.FARM_CARD.AVAILABLE'
                        )}`
                      : ''}
                  </Text>
                </View>
              )}
            </View>
            <View>
              {status === statusFarm.DRAFT && (
                <CommonIcon name={'EDIT'} size={18} />
              )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: 8,
  },
  cardShadow: {
    backgroundColor: 'white',
    minHeight: 106,
    borderRadius: 8,
    shadowColor: 'rgba(16, 24, 40, 1)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  cardContent: {
    paddingVertical: 16,
    paddingStart: 20,
    paddingEnd: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardInfo: {
    paddingStart: 16,
    flex: 1,
  },
  cardInfoName: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 20,
  },
  farmDraftColor: {
    color: carbon600,
  },
  cardInfoDescription: {
    color: carbon600,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
  },
  draftChip: {
    width: 55,
    height: 20,
    marginTop: 16,
    backgroundColor: carbon100,
    borderWidth: 1,
    borderColor: carbon200,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  draftChipText: {
    fontSize: 10,
    lineHeight: 16,
    fontWeight: '600',
  },
  lotsInfo: {
    marginTop: 16,
  },
  lotsTitle: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 18,
  },
  iconDraftColor: {
    color: carbon600,
  },
})

FarmCard.propTypes = {
  name: PropTypes.string,
  surface: PropTypes.number,
  areaUnit: PropTypes.string,
  address: PropTypes.string,
  status: PropTypes.string,
  totalLots: PropTypes.number,
  availableLots: PropTypes.number,
  onPress: PropTypes.func,
}
