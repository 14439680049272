import React from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import ItemLot from '@components/common/v1/ItemLot'

const ListHorizontalLots = ({ lots, onGoToLandscapeSustainability }) => {
  return (
    <View style={styles.container}>
      <FlatList
        data={lots}
        horizontal
        keyExtractor={(_, idx) => `lot-${idx}`}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        renderItem={({ item, index }) => (
          <ItemLot
            item={item}
            onGoToLandscapeSustainability={onGoToLandscapeSustainability}
            index={index}
          />
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
  },
  separator: {
    marginHorizontal: 4,
  },
})

ListHorizontalLots.propTypes = {
  lots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      image: PropTypes.string,
      nameLot: PropTypes.string,
      surface: PropTypes.number,
      countAchievement: PropTypes.number,
      eiq: PropTypes.object,
    })
  ),
}

export default ListHorizontalLots
