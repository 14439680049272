import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import Modal from 'react-native-modal'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'

export const MAGIC_MARGIN = 32 //El total de los margenes verticales del modal

export const ModalDefault = ({
  isModalVisible,
  children,
  closeModal,
  hasIconCloseModal = true,
}) => {
  return (
    <Modal isVisible={isModalVisible}>
      <View style={styles.container}>
        <View style={styles.content}>
          {hasIconCloseModal && (
            <View style={styles.contentClose}>
              <CommonIcon name={'X'} size={20} onPress={closeModal} />
            </View>
          )}
          {children}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    minWidth: Platform.OS === 'web' ? 600 : 300,
  },
  contentClose: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
})

ModalDefault.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  hasIconCloseModal: PropTypes.bool,
}
