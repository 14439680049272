import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { blackHighEmphasis } from '@styles/palette'

export const CompanyDetails = ({
  companyName = '',
  companyIdentifier = '',
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.companyName} numberOfLines={1} ellipsizeMode='tail'>
        {companyName}
      </Text>

      <Text style={styles.companyIdentifier} numberOfLines={1}>
        {companyIdentifier}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
  },
  companyName: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginVertical: 1,
    lineHeight: 24,
    letterSpacing: 0.15,
    textTransform: 'uppercase',
  },
  companyIdentifier: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
})

CompanyDetails.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyIdentifier: PropTypes.string.isRequired,
}
