import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import { convertNumbertoString } from '@utils/number'
import { LanguageContext } from '@contextState/language'
import { round } from 'lodash'

const ItemOverlappingSurface = ({ name, value, color, areaUnit }) => {
  const { t } = useContext(LanguageContext)
  const colorOverlapping = value > 0 ? styles.overlapping : styles.nooverlapping
  return (
    <View style={styles.container}>
      {name && <Text style={[styles.name, { color }]}>{name}</Text>}
      <View style={styles.subcontainer}>
        <Text style={styles.nameOverlapping}>
          {
            t('COMPONENTS').LANDSCAPE_SUSTAINABILITY.ITEM_OVERLAPPING_SURFACE
              .TEXT_1
          }
        </Text>
        <Text style={[styles.value, colorOverlapping]}>
          {`${convertNumbertoString(round(value, 2))} ${areaUnit}`}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
    paddingVertical: 8,
    backgroundColor: '#F8F8F8',
  },
  name: {
    fontSize: 14,
    paddingBottom: 4,
  },
  subcontainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameOverlapping: {
    fontSize: 14,
    color: '#333333',
  },
  value: {
    fontSize: 16,
    fontWeight: '500',
  },
  overlapping: {
    color: '#BF360C',
  },
  nooverlapping: {
    color: '#1B5E20',
  },
})

ItemOverlappingSurface.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default ItemOverlappingSurface
