import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import { primary500 } from '@styles/palette'

export const CreateFarmButton = ({
  isUpdate = false,
  isButtonAvailable,
  isButtonLoading,
  statusFarm,
  onPress,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      disabled={!isButtonAvailable}
      isLoading={isButtonLoading}
      onPress={onPress}
      styles={[styles.button, !isButtonAvailable ? styles.buttonDisabled : {}]}
      labelStyle={[
        styles.textButton,
        !isButtonAvailable ? styles.textButtonDisabled : {},
      ]}
    >
      {!isUpdate || statusFarm === 'DRAFT'
        ? t('VIEWS.FARM_LOTS_ADD.COMPONENTS.CREATE_FARM_BUTTON.TEXT_1')
        : t('VIEWS.FARM_LOTS_ADD.COMPONENTS.CREATE_FARM_BUTTON.TEXT_2')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
    marginHorizontal: 16,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
})

CreateFarmButton.propTypes = {
  isUpdate: PropTypes.bool.isRequired,
  isButtonAvailable: PropTypes.bool.isRequired,
  isButtonLoading: PropTypes.bool.isRequired,
  statusFarm: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
}
