import { useContext } from 'react'
import { Platform } from 'react-native'

import { ConnectionContext } from '@contextState/connection'
import { AuthContext } from '@contextState/auth'
import { CommonContext } from '@contextState/common'

import useOfflineSupplies from '@offline/queries/supplies'
import offlineCropQueries from '@offline/queries/crops'
import useOfflineCommon from '@offline/queries/common'
import useNetwork from '@utils/network'

export const useOfflineCrops = () => {
  const { syncDrafts } = useContext(ConnectionContext)
  const { displayToggleModalNotification } = useContext(CommonContext)
  const { config, user } = useContext(AuthContext)

  const { insertCrop, deleteCrop } = offlineCropQueries()
  const { syncSupplies, findOneSupply, resetSupplies } = useOfflineSupplies()
  const {
    storeAtIndividuals,
    deleteIndividualCrop,
    findOneIndividual,
    updateFarmsInCrop,
  } = useOfflineCommon()
  const { doRequest } = useNetwork()

  /**
   *
   * @param {object} params
   * @param {object} params.crop
   * @param {Function=} params.onSuccessEnableCrop
   * @param {Function=} params.onErrorEnableCrop
   *
   * @return {Promise<void>}
   */
  const enableCropOffline = async ({
    crop,
    onSuccessEnableCrop,
    onErrorEnableCrop,
  }) => {
    try {
      const {
        _id: cropId,
        company: {
          country: { alpha3Code: alphaCodeInCrop },
        },
      } = crop

      const supply = await findOneSupply()

      const alphaCodeInSupply = supply ? supply.alphaCode : null

      if (!alphaCodeInSupply || alphaCodeInSupply === alphaCodeInCrop) {
        await doRequest({
          method: 'PUT',
          url: `crops/${cropId}/enableOffline`,
        })

        const [errorInsertCrop] = await insertCrop(
          crop,
          config?.companySelected?.identifier
        )

        if (errorInsertCrop) {
          console.warn('errorInsertCrop')
          console.warn(errorInsertCrop)

          return
        }

        const dataToInsertIndividual = {
          companyId: config.companySelected?._id,
        }

        await storeAtIndividuals('crops', cropId, dataToInsertIndividual)

        await syncSupplies(alphaCodeInCrop)

        syncDrafts(user, config, displayToggleModalNotification)

        if (onSuccessEnableCrop) onSuccessEnableCrop(crop)
      } else {
        if (onErrorEnableCrop) onErrorEnableCrop(supply.countryId?.name)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   *
   * @param {object} params
   * @param {object} params.crop
   * @param {Function=} params.onSuccessDisableCrop
   *
   * @return {Promise<void>}
   */
  const disableCropOffline = async ({ crop, onSuccessDisableCrop }) => {
    try {
      const { _id: cropId } = crop
      await doRequest({
        method: 'PUT',
        url: `crops/${cropId}/disableOffline`,
      })

      deleteCrop(cropId)

      await deleteIndividualCrop(crop)

      const existCrop = await findOneIndividual('crops')

      if (!existCrop) {
        resetSupplies()
      }

      syncDrafts(user, config, displayToggleModalNotification)

      if (onSuccessDisableCrop) onSuccessDisableCrop(crop)
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   *
   * @param {object} params
   * @param {object} params.cropId
   *
   * @return {Promise<boolean>}
   */
  const updateFarmsCropOffline = async ({ cropId }) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (Platform.OS === 'web') {
          return resolve({})
        }

        setTimeout(async () => {
          const { data: crop } = await doRequest({
            method: 'GET',
            url: `crops/${cropId}`,
            version: 'v2',
            params: {
              companyId: config.companySelected?._id,
            },
            displayAlert: false,
          })

          if (!crop) {
            return reject({
              error: true,
            })
          }

          const isOfflineEnabled = Boolean(
            crop.members?.find(
              ({ user: currentUser, isOfflineEnabled }) =>
                currentUser?._id === user._id && isOfflineEnabled
            )
          )

          if (!isOfflineEnabled) {
            return resolve({})
          }

          const { error: errorUpdateFarms } = await updateFarmsInCrop({
            cropId: crop._id,
            farms: crop.establishments,
          })

          if (errorUpdateFarms) {
            return reject({
              error: errorUpdateFarms,
            })
          }

          return resolve({})
        }, 3000)
      })

      return promise
    } catch (error) {
      console.error(error)

      return {
        error,
      }
    }
  }

  return {
    enableCropOffline,
    disableCropOffline,
    updateFarmsCropOffline,
  }
}
