import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import CardDefault from '@components/cards/CardDefault'
import AccordionItem from '@components/common/v2/AccordionItem'
import { white, backgroundDefault } from '@styles/palette'

export const PlanningDescription = ({ renderInfo, renderHeader }) => {
  return (
    <CardDefault>
      <AccordionItem
        ContentHeader={renderHeader}
        contentStyle={styles.contentStyle}
        extraBodyStyle={styles.extraBodyStyle}
      >
        <View style={styles.contentInfo}>{renderInfo()}</View>
      </AccordionItem>
    </CardDefault>
  )
}

const styles = StyleSheet.create({
  extraBodyStyle: {
    paddingTop: 0,
    paddingHorizontal: 7,
    paddingBottom: 10,
    backgroundColor: white,
  },
  contentInfo: {
    backgroundColor: backgroundDefault,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

PlanningDescription.propTypes = {
  renderInfo: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
}
