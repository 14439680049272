import React, { useContext } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { Button, Caption } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { MENU_COLLABORATOR_NEW } from '@constants/menuCollaborator'
import { ModalDefault } from '@common/components'

const MarkResponsibleModal = ({
  isModalMarkResponsible,
  isResonsableRol,
  hideModalMarkResponsible,
  addTagCollaborator,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <ModalDefault
      isModalVisible={isModalMarkResponsible}
      closeModal={hideModalMarkResponsible}
      hasIconCloseModal={false}
    >
      <View style={styles.containerHeaderModalResponsible}>
        <Text style={styles.modalTitle}>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_RESPONSIBLE.TEXT_1}
        </Text>
        <TouchableOpacity onPress={hideModalMarkResponsible}>
          <CommonIcon name={'X'} size={20} style={styles.buttonClose} />
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.modalParagraph}>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_RESPONSIBLE.TEXT_2}{' '}
          <Caption style={[styles.modalParagraph, styles.titleResponsible]}>
            {isResonsableRol && MENU_COLLABORATOR_NEW[isResonsableRol](t)}
          </Caption>{' '}
          {t('VIEWS').CROP_COLLABORATOR.MODAL_RESPONSIBLE.TEXT_3}
        </Text>
      </View>
      <View style={styles.actionsModalResponsible}>
        <Button
          onPress={hideModalMarkResponsible}
          mode='outlined'
          style={styles.cancelModalButton}
        >
          <Text>{t('VIEWS').CROP_COLLABORATOR.MODAL_RESPONSIBLE.TEXT_4}</Text>
        </Button>
        <Button onPress={addTagCollaborator} mode='contained'>
          <Text style={styles.titleModalResponsibleButton}>
            {t('VIEWS').CROP_COLLABORATOR.MODAL_RESPONSIBLE.TEXT_5}
          </Text>
        </Button>
      </View>
    </ModalDefault>
  )
}

const styles = StyleSheet.create({
  contentModalResponsible: {
    backgroundColor: '#FFF',
    height: 204,
    width: 312,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  actionsModalResponsible: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
  },
  titleModalResponsibleButton: {
    color: '#FFF',
  },
  cancelModalButton: {
    marginRight: 10,
    borderColor: '#4caf50',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 30,
  },
  modalParagraph: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 20,
  },
  containerHeaderModalResponsible: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleResponsible: {
    color: '#4caf50',
  },
  containerActionSheet: {
    height: 370,
    marginHorizontal: 10,
  },
})

MarkResponsibleModal.prototypes = {
  isModalMarkResponsible: PropTypes.bool.isRequired,
  isResonsableRol: PropTypes.string.isRequired,
  hideModalMarkResponsible: PropTypes.bool.isRequired,
  addTagCollaborator: PropTypes.func.isRequired,
}

export default MarkResponsibleModal
