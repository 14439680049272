import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { formatSurface } from '@modules/common/utils'
import { blackHighEmphasis } from '@styles/palette'

export const FieldItem = ({ name, surface, areaUnit }) => {
  return (
    <View style={styles.fieldContainer}>
      <Text style={styles.name} numberOfLines={1} ellipsizeMode={'tail'}>
        {name}
      </Text>

      <Text style={styles.title}>{formatSurface(surface, areaUnit)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  fieldContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    flex: 1,
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 20,
    marginRight: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 20,
    marginRight: 5,
  },
})

FieldItem.prototype = {
  name: PropTypes.string.isRequired,
  surface: PropTypes.number.isRequired,
  areaUnit: PropTypes.string.isRequired,
}
