import { useState } from 'react'
import useNetwork from '@utils/network'
import { useDispatch } from 'react-redux'
import { selectFileBinaryAction } from '@store/actions'

export const useCreateDraft = ({
  farmUuid,
  farmName,
  company,
  identifier,
  userId,
  File,
  navigation,
  setFieldTouched,
  setFieldError,
  setError,
  toggleModal,
}) => {
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()

  const [validateName, setValidateName] = useState({})

  const createFarmDraft = async () => {
    const formData = new FormData()
    formData.append('name', farmName.trim())
    formData.append('company', company)
    formData.append('identifier', identifier)
    formData.append('userId', userId)

    if (File && Object.entries(File).length) {
      formData.append('file', File)
    }

    try {
      await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: 'farms/drafts',
        data: formData,
        displayAlert: false,
      })
      dispatch(selectFileBinaryAction.clearFileBinary())
      navigation.navigate('FarmList', {
        newFarm: true,
      })
    } catch (error) {
      setValidateName(error)
    }
  }

  const updateFarm = async (deleted) => {
    const formData = new FormData()

    formData.append('name', farmName)
    formData.append('companyId', company)
    formData.append('identifier', identifier)
    formData.append('lots', null)
    formData.append('lotsFiles', null)

    if (File || Object.entries(File).length) {
      formData.append('farmFile', File)
      formData.append('removeFarmFile', false)
    } else {
      formData.append('farmFile', null)
      formData.append('removeFarmFile', true)
    }

    try {
      await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'PUT',
        url: `farms/${farmUuid}`,
        data: formData,
        displayAlert: false,
      })
      dispatch(selectFileBinaryAction.clearFileBinary())
      if (!deleted) {
        navigation.navigate('FarmList')
      }
    } catch (error) {
      setFieldTouched('farmName', true, false)
      setFieldError('farmName', error?.message)
      setError({ message: error?.message || error?.code || error?.error })
      toggleModal()
    }
  }

  const handleSubmit = async ({ deleted = false }) => {
    if (farmUuid) {
      updateFarm(deleted)
    } else {
      createFarmDraft()
    }
  }

  return {
    validateName,
    handleSubmit,
  }
}
