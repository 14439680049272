import { View, Platform } from 'react-native'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import InputText from '@components/inputs/InputText'
import { isNumber } from '@utils'

const CodeForm = ({ formik, t }) => {
  const ref = useRef(null)
  const { setFieldTouched, setFieldValue, values, touched, errors } = formik

  function checkLength(value, length) {
    return value.slice(0, length)
  }

  const onChangeText = (value) => {
    if (isNumber(value)) {
      const chekedLengthCode = checkLength(value, 6)
      setFieldValue('code', chekedLengthCode)
    }
  }

  useEffect(() => {
    ref?.current?.focus()
  }, [])

  return (
    <View>
      <InputText
        id='code'
        value={values.code}
        label={t('VIEWS.VERIFY_CODE_SCREEN.TITLE')}
        placeholder={t('VIEWS.VERIFY_CODE_SCREEN.TITLE')}
        onChangeText={onChangeText}
        onBlur={() => setFieldTouched('code')}
        touched={touched.code}
        error={errors.code}
        keyboardType='numeric'
        autofocus={!(Platform.OS === 'web')}
        inputRef={ref}
      />
    </View>
  )
}
CodeForm.propTypes = {
  formik: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default CodeForm
