import { View, Text, StyleSheet } from 'react-native'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import InputFileUpload from '@components/inputs/InputFileUpload'

import { primary50, primary500, blackHighEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { allowedFormatsFiles } from '@utils/files/'

const AttachDocumentation = ({ onPressAddEvidence }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {t(`COMPONENTS.COMPONENTS_COMPANY.ATTACH_DOCUMENTATION.AFFIDAVIT`)}
      </Text>
      <Text style={styles.description}>
        {t(
          `COMPONENTS.COMPONENTS_COMPANY.ATTACH_DOCUMENTATION.ATTACH_DESCRIPTION`
        )}
      </Text>
      <InputFileUpload
        accept={allowedFormatsFiles}
        onSelectFile={onPressAddEvidence}
      >
        <View style={styles.attachContainer}>
          <Text style={styles.attachText}>
            {t(
              `COMPONENTS.COMPONENTS_COMPANY.ATTACH_DOCUMENTATION.ATTACH_DOCUMENTATION`
            )}
          </Text>
        </View>
      </InputFileUpload>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 172,
    backgroundColor: primary50,
    paddingVertical: 24,
    paddingHorizontal: 18,
    borderRadius: 4,
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  description: {
    fontSize: 12,
    color: blackHighEmphasis,
  },
  attachContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 36,
  },
  attachText: {
    fontSize: 14,
    fontWeight: '700',
    color: primary500,
  },
})

AttachDocumentation.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
}

export default AttachDocumentation
