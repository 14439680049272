import React, { useState, useEffect, useRef } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Animated,
  Platform,
} from 'react-native'
import { Input } from 'react-native-elements'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import {
  blackHighEmphasis,
  primary500,
  redError,
  grayDA,
} from '@styles/palette'
import useFadeAnimation from '@hooks/useFadeAnimation'
import { isEmptyValue } from '@utils/common'
import { keyboardTypeParseNumberDecimal } from '@utils/number'

const InputMultiline = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  leftIcon = false,
  rightIcon = false,
  onChange,
  onChangeText,
  value,
  onFocus,
  onBlur,
  onSubmitEditing,
  editable = true,
  disabled = false,
  returnKeyType = 'next',
  keyboardType = undefined,
  placeholder = '',
  label = '',
  onPress,
  touched,
  error,
  setFieldValue,
  hasIcon = true,
  pressIcon,
}) => {
  const inputRef = useRef(null)
  const [height, setHeight] = useState(62)
  const [isFocused, setIsFocused] = useState(false)
  const styleHeight = { height: height }
  const styleCenter =
    Platform.OS !== 'android' && (!value || value === '')
      ? { paddingTop: 20 }
      : {}

  const fadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(fadeParams)

  useEffect(() => {
    if (isEmptyValue(value)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [value])

  const onPressCustom = () => {
    if (disabled) {
      return
    }

    if (onPress) {
      onPress()
    }
  }

  const onFocusCustom = () => {
    if (!editable || disabled) {
      return
    }

    setIsFocused(true)

    onFocus ? onFocus() : false
  }

  const onBlurCustom = () => {
    if (!editable || disabled) {
      return
    }

    setIsFocused(false)

    onBlur ? onBlur() : false
  }

  const handleChangeText = (text) => {
    const value = keyboardTypeParseNumberDecimal(text, keyboardType)
    if (value.trim().length <= 140) {
      if (onChangeText) {
        onChangeText(value)
      } else {
        setFieldValue(id, value)
      }
    }
  }

  const onContentSizeChange = ({
    nativeEvent: {
      contentSize: { height },
    },
  }) => {
    setHeight(height > 62 ? height : 62)
  }

  const rightIconDefault = (
    <CommonIcon
      name='SEND'
      size={25}
      onPress={() => {
        setIsFocused(false)
        inputRef.current.blur()
        pressIcon()
      }}
      color={grayDA}
    />
  )

  return (
    <Pressable onPress={onPressCustom} style={containerStyle}>
      <Animated.View
        style={[
          styles.placeholderContainer,
          {
            opacity: placeholderOpacity,
          },
        ]}
      >
        <Text
          style={[
            styles.placeholder,
            touched && error ? styles.placeholderError : {},
            isFocused ? styles.focusedPlaceholder : {},
            disabled ? styles.placeholderDisabled : {},
          ]}
        >
          {label ?? placeholder}
        </Text>

        <View style={styles.placeholderBackground}></View>
      </Animated.View>
      <Input
        ref={inputRef}
        id={id}
        name={name}
        multiline
        style={styleHeight}
        onChange={onChange}
        value={value}
        onContentSizeChange={onContentSizeChange}
        inputContainerStyle={[
          styles.inputContainer,
          touched && error ? styles.borderError : {},
          isFocused ? styles.focusedInput : {},
          styleHeight,
        ]}
        inputStyle={[
          styles.input,
          Platform.OS == 'web' ? { outlineWidth: 0 } : {},
          inputStyle,
          styleCenter,
          styleHeight,
        ]}
        rightIconContainerStyle={[styles.rightIconDefault, styleHeight]}
        leftIcon={leftIcon}
        rightIcon={hasIcon && (rightIcon || rightIconDefault)}
        onChangeText={handleChangeText}
        onFocus={onFocusCustom}
        onBlur={onBlurCustom}
        onSubmitEditing={onSubmitEditing}
        editable={editable}
        disabled={disabled}
        underlineColorAndroid='transparent'
        returnKeyType={returnKeyType}
        keyboardType={keyboardType}
        placeholder={placeholder}
        placeholderTextColor='rgba(51, 51, 51, 0.7)'
        errorStyle={styles.textError}
        errorMessage={touched && error}
      />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: 'rgba(0, 0, 0, .1)',
    borderRadius: 2,
    marginHorizontal: -10,
    zIndex: 1,
  },
  textError: {
    color: redError,
    fontSize: 12,
    marginLeft: 9,
    marginBottom: Platform.OS === 'web' ? 14 : 6,
  },
  borderError: {
    borderColor: redError,
  },
  input: {
    fontSize: 15,
    paddingLeft: 16,
    paddingRight: 8,
    paddingVertical: 10,
    textAlignVertical: 'center',
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: 'rgba(0, 0, 0, .4)',
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
  rightIconDefault: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 15,
  },
})

InputMultiline.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onfocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.object,
  onSubmitEditing: PropTypes.func,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  returnKeyType: PropTypes.string,
  keyboardType: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onPress: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default InputMultiline
