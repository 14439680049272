import React from 'react'
import PropTypes from 'prop-types'

import {
  GeneralInfoCard,
  ActivityCard,
  YieldCard,
  StorageCard,
  EvidenceCard,
  CollaboratorCard,
} from '..'

export const Harvest = ({
  collaboratorsQuantity,
  companyName,
  cropName,
  cropType,
  dateHarvest,
  evidences,
  fieldsQuantity,
  pay,
  storages,
  surface,
  unitArea,
  unitTypeKey,
  unitTypeName,
  unitVolume,
  volume,
}) => {
  return (
    <>
      <GeneralInfoCard cropName={cropName} companyName={companyName} />

      <ActivityCard
        fieldsQuantity={fieldsQuantity}
        surface={surface}
        unitArea={unitArea}
        dateHarvest={dateHarvest}
      />

      <YieldCard
        unitTypeName={unitTypeName}
        unitTypeKey={unitTypeKey}
        pay={pay}
        cropType={cropType}
        volume={volume}
        unitVolume={unitVolume}
      />

      <StorageCard storages={storages} />

      <EvidenceCard evidences={evidences} />

      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

Harvest.propTypes = {
  cropName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  dateHarvest: PropTypes.string.isRequired,
  unitTypeName: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  pay: PropTypes.number.isRequired,
  storages: PropTypes.arrayOf(
    PropTypes.shape({
      tons: PropTypes.number.isRequired,
      storageTypeName: PropTypes.string.isRequired,
    })
  ),
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
  collaboratorsQuantity: PropTypes.number.isRequired,
  isValueOutsideMeanHarvest: PropTypes.bool,
  canShowOutlierHarvest: PropTypes.bool,
  changeOutLierHarvest: PropTypes.func,
  statusOutlier: PropTypes.string,
}
