import React, { useState, useContext } from 'react'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native'
import { Divider, Checkbox, Button } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LicenseContext } from '@contextState/license'
import SuccessPinModal from '@components/modals/SuccessPinModal'
import ChildrenModalSuccessLicence from '@components/modals/ChildrenModalSuccessLicence'
import ModalError from '@components/common/v1/ModalError'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'
import { VerifyPin } from '@modules/common/components'
import { AuthContext } from '@contextState/auth'
import ModalConfirm from '@components/common/v1/ModalConfirm'

const LicenseSign = ({ navigation, route }) => {
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const { isModalVisible: isModalSuccess, toggleModal: toggleModalSuccess } =
    useModal()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { doRequest } = useNetwork()
  const {
    licenseSelected: { name, termsAndConditions, _id: licenseId, normative },
  } = useContext(LicenseContext)
  const { t } = useContext(LanguageContext)

  const { user } = useContext(AuthContext)
  const { config } = user

  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [visible, setVisible] = useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const [numberLicense, setNumberLicense] = useState('')

  /**
   * HANDLE FIRM BUTTON
   * WITH VERIFICATE EXPIRATION PIN
   */
  const signPress = async ({ updatedPin = false }) => {
    if (config.hasPin || updatedPin) {
      if (verifyExpirationPin()) {
        toggleModal(true)
      } else {
        const { validate, tokenPin } = await geTokenAuthPin()
        if (!validate) {
          toggleModal(true)
          return
        }
        onSignLicense(tokenPin)
      }
    } else {
      navigation.navigate('CreatePin', {
        callbackPin,
      })
    }
  }

  const callbackPin = async () => {
    const { tokenPin } = await geTokenAuthPin()
    onSignLicense(tokenPin)
  }

  /**
   * HIDE DIALOG
   */
  const hideDialog = () => {
    setVisible(false)
  }

  /**
   * HANDLE CHECK
   */
  const checkPress = () => {
    if (!checked) {
      setVisible(true)
    }
    denyTerm()
  }

  /**
   * DENY TERM DIALOG
   */
  const denyTerm = () => {
    setDisabled(true)
    setChecked(false)
  }

  /**
   * ACCEPT TERM DIALOG
   */
  const acceptTerm = () => {
    setDisabled(false)
    setChecked(true)
  }

  /**
   * SIGN LICENSE
   *
   * @param {*} tokenPin
   */
  const onSignLicense = async (tokenPin) => {
    setVisibleError(false)

    try {
      const headers = {
        'Validate-Token-Pin': `Bearer ${tokenPin}`,
      }

      const dataLicence = {
        cropId: route.params.cropId,
      }

      const response = await doRequest({
        method: 'POST',
        url: `licenses/${licenseId}/sign`,
        data: dataLicence,
        headers,
      })

      //Close Modal PIN
      closeModal()
      if (response) {
        setNumberLicense(String(response.data.timestamp))
        toggleModalSuccess()
      } else {
        setVisibleError(true)
      }
    } catch (error) {
      closeModal()
      setVisibleError(true)
    }
  }

  /**
   * GO TO HOME
   */
  const goToHome = () => {
    closeModal()
    toggleModalSuccess()

    navigation.navigate('CropList')
  }

  /**
   * GO TO SUMMARY LANDSCAPE SUSTAINABILITY
   */
  const goToSummaryLandscapeSustainability = () => {
    closeModal()
    toggleModalSuccess()
    let navigateScreen = 'SummaryLandscapeSustainability'
    if (!normative) {
      navigateScreen = 'LicenseLotsAdd'
    }

    navigation.navigate(navigateScreen, {
      cropId: route.params.cropId,
      licenseId: route.params.licenseId,
    })
  }

  return (
    <>
      <Divider />
      <View style={styles.container}>
        <Text style={styles.titleLicense}>{name}</Text>
        <Divider />
        <ScrollView style={styles.licenceView}>
          <View style={styles.term}>
            <Text style={styles.termText}>{termsAndConditions}</Text>
          </View>
        </ScrollView>
        <View style={styles.checked}>
          <Checkbox.Android
            status={checked ? 'checked' : 'unchecked'}
            color='#4CAF50'
            onPress={checkPress}
          />
          <Text style={styles.conditions}>
            {t('VIEWS').LICENSE_SIGN.TEXT_2}
          </Text>
        </View>
        <Button
          style={disabled ? styles.buttonDisabled : styles.button}
          mode='contained'
          onPress={signPress}
          disabled={disabled}
          contentStyle={styles.innerButtonStyle}
        >
          <Text style={styles.buttonText}>
            {t('VIEWS').LICENSE_SIGN.TEXT_3}
          </Text>
        </Button>
      </View>

      <ModalConfirm
        visible={visible}
        onConfirm={acceptTerm}
        onClose={hideDialog}
        onCancel={denyTerm}
        title={t('VIEWS.LICENSE_SIGN.TEXT_4')}
        contentText={t('VIEWS.LICENSE_SIGN.TEXT_5')}
        cancelText={t('VIEWS.LICENSE_SIGN.TEXT_6')}
        confirmText={t('VIEWS.LICENSE_SIGN.TEXT_7')}
      />

      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={onSignLicense}
      />
      <SuccessPinModal
        isModalVisible={isModalSuccess}
        toggleModal={toggleModalSuccess}
        title={t('VIEWS').LICENSE_SIGN.TEXT_8}
      >
        <ChildrenModalSuccessLicence
          onPressFirstButton={() => goToSummaryLandscapeSustainability()}
          onPressSecondButton={() => goToHome()}
          numberLicense={numberLicense}
        />
      </SuccessPinModal>

      {visibleError && (
        <ModalError
          visible={visibleError}
          title={t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_1}
          description={t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_2}
        >
          <TouchableOpacity onPress={() => goToHome()}>
            <Text style={styles.goToCrop}>
              {t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_3}
            </Text>
          </TouchableOpacity>
        </ModalError>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    flex: 1,
    paddingHorizontal: 20,
  },
  licenceView: {
    backgroundColor: '#FFF',
  },
  titleLicense: {
    fontSize: 16,
    paddingVertical: 16,
    fontWeight: '500',
  },
  checked: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
  },
  term: {
    marginHorizontal: '2.5%',
    marginTop: 10,
    marginBottom: 10,
  },
  termText: {
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'justify',
    lineHeight: 20,
  },
  button: {
    margin: 16,
    borderRadius: 8,
    marginBottom: 20,
  },
  buttonDisabled: {
    margin: 16,
    borderRadius: 8,
    marginBottom: 20,
    backgroundColor: '#81C784',
  },
  buttonText: {
    color: '#FFFFFF',
    fontWeight: '700',
  },
  innerButtonStyle: {
    padding: 10,
  },
  alertTitle: {
    fontSize: 18,
    color: '#333',
    fontWeight: '500',
  },
  alertParagraph: {
    fontSize: 18,
    color: '#333',
    fontWeight: '500',
  },
  conditions: {
    fontWeight: '500',
    textDecorationLine: 'underline',
  },
  goToCrop: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    fontWeight: '700',
    fontStyle: 'normal',
    color: '#FFFFFF',
  },
})

LicenseSign.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default LicenseSign
