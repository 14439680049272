import { WebViewRender } from "@modules/webView/components"

import { useStoriesWebView } from "@common/hooks"
import LoaderCenterContainer from "@components/loaders/LoaderCenterContainer"

const CropStoryDetail = ({ route }: { navigation: any, route: any }) => {
  const { params: { id: cropId, fieldStory, lotId } } = route
  const { accessToken, handleIFrameMessages, url } = useStoriesWebView(!fieldStory ? 'crop-story' : 'field-story', { cropId, lotId })


  if (!url || !accessToken) {
    return <LoaderCenterContainer />
  }

  return <>
    <WebViewRender
      url={url}
      onMessage={handleIFrameMessages}
    />
  </>
}

export default CropStoryDetail
