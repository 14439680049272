import React, { useState, useContext } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { Divider } from 'react-native-paper'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { SelectorContext } from '@contextState/selectors'
import { CropContext } from '@contextState/crop'
import LotsSurfaceDetails from '@components/lots/v1/LotsSurfaceDetails'
import { formatSurface, truncateSurface } from '@common/utils'

const ActivityLotsSurface = ({ route }) => {
  const { t } = useContext(LanguageContext)
  const { currentCrop } = useContext(CropContext)
  const { establishmentsSelector, lotSelectorTotalSurface } =
    useContext(SelectorContext)

  const [establishments] = useState(establishmentsSelector)

  const renderLotsInEstablishment = (establishments) =>
    establishments &&
    establishments.map((establishment, key) => (
      <View key={key}>
        <View style={styles.contentTitle}>
          <Text style={styles.title}>{establishment.tag}</Text>
        </View>
        {establishment.lots.map((lot, key) => (
          <View key={key}>
            <LotsSurfaceDetails
              key={lot._id}
              id={lot._id}
              tag={lot.tag}
              name={lot.name}
              provinceName={lot.provinceName}
              imageUrl={lot.imageUrl}
              surface={truncateSurface(lot.surface ?? 0)}
              areaUnit={currentCrop?.areaUnit ?? ''}
              readOnly={route.params.readOnly}
            />
            <Divider />
          </View>
        ))}
      </View>
    ))

  return (
    <>
      <ScrollView style={styles.scroll}>
        <View style={styles.container}>
          {renderLotsInEstablishment(establishments)}

          <View style={styles.contentSurface}>
            <Text style={styles.surfaceTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_10}
            </Text>
            <Text style={styles.surfaceSubTitle}>
              {formatSurface(
                lotSelectorTotalSurface ?? 0,
                currentCrop?.areaUnit ?? ''
              )}
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
  },
  contentSurface: {
    paddingVertical: 10,
    marginBottom: 20,
  },
  surfaceTitle: {
    fontSize: 20,
    fontWeight: '700',
    paddingVertical: 10,
  },
  surfaceSubTitle: {
    fontSize: 14,
    fontWeight: '400',
  },
  contentButton: {
    paddingVertical: 10,
  },
  titleSign: {
    color: '#FFFFFF',
  },
})

ActivityLotsSurface.propTypes = {
  route: PropTypes.object.isRequired,
}

export default ActivityLotsSurface
