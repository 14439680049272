import React, { useContext } from 'react'
import { Divider, List } from 'react-native-paper'
import { View, StyleSheet, Platform, Linking, Image } from 'react-native'
import Constants from 'expo-constants'
import mime from 'mime'

import { LanguageContext } from '@contextState/language'
const pattern = new RegExp('^http')
const apiUrl = Constants.manifest.extra.EXPO_HOST || ''
import { CommonIcon } from '@modules/common/components'

const FilesList = ({ data, onDelete, disabled, onPress }) => {
  const { t } = useContext(LanguageContext)

  const onPressCustom = (evidence) => {
    if (onPress) {
      onPress(evidence)

      return
    }

    try {
      if (evidence.persisted) {
        let path = evidence.isSatelliteImage
          ? evidence.imageSatellite
          : evidence.path
        if (!pattern.test(path)) {
          path = `${apiUrl}/${path}`
        }
        if (Platform.OS === 'web') {
          window.open(path, '_blank')
        } else {
          Linking.openURL(path)
        }
      }
    } catch (err) {
      console.warn(err)
    }
  }

  const getTitle = (evidence) => {
    const title =
      evidence.name ||
      evidence.file?.name ||
      evidence.nameFile ||
      t('COMPONENTS').COMMON.FILES_LIST.TEXT_4

    return title
  }

  const getDescription = (evidence) => {
    return `${
      evidence?.description || evidence?.file?.description
    } \n${getDescriptionSatelliteImage(evidence)} ${
      evidence?.description === t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_5 &&
      isImage(evidence) &&
      evidence?.settings?.isInLot === false &&
      evidence?.settings?.isValidateLot
        ? `\n⚠️ ${t('COMPONENTS').COMMON.FILES_LIST.TEXT_5}`
        : ''
    }`
  }

  const getLeftComponent = (props, evidence) => {
    return isImage(evidence) ? (
      <Image
        source={{
          uri: getSourcePath(evidence),
        }}
        style={styles.image}
      />
    ) : (
      <CommonIcon
        {...props}
        name={'FILETYPE-DOC'}
        size={35}
        style={styles.icon}
      />
    )
  }

  const getRightComponent = (props, key) => {
    return onDelete ? (
      <CommonIcon
        {...props}
        name={'X'}
        onPress={() => !disabled && onDelete(key)}
        disabled={disabled}
      />
    ) : null
  }

  const getSourcePath = (evidence) => {
    if (evidence.isSatelliteImage) {
      return evidence.imageSatellite
    }

    if (evidence.file?.uri) {
      return evidence.file.uri
    }

    if (typeof evidence.file === 'string' || evidence.file instanceof String) {
      return evidence.file
    }

    if (evidence.pathThumbnails || evidence.path) {
      const path = evidence.pathThumbnails
        ? evidence.pathThumbnails
        : evidence.path
      if (pattern.test(path)) {
        return path
      }
      return `${apiUrl}/${path}`
    }

    return URL.createObjectURL(evidence.file)
  }

  const getDescriptionSatelliteImage = (file) => {
    if (file.isSatelliteImage) {
      return `\n${t('COMPONENTS').COMMON.FILES_LIST.TEXT_1}: ${
        file.meta.typeImage
      } \n${t('COMPONENTS').COMMON.FILES_LIST.TEXT_2}: ${file.dateFormat} \n${
        t('COMPONENTS').COMMON.FILES_LIST.TEXT_3
      }: ${file.meta.nameLot}`
    }

    return ''
  }

  const isImage = ({ file, path }) => {
    if (path) {
      return (
        path.includes('.jpeg') || path.includes('.jpg') || path.includes('.png')
      )
    }

    if (file) {
      if (typeof file === 'string' || file instanceof String) {
        return (
          file.includes('.jpeg') ||
          file.includes('.jpg') ||
          file.includes('.png')
        )
      } else {
        return (
          mime
            .getType(file.uri || file.nameFile || file.path)
            .indexOf('image') !== -1
        )
      }
    }

    return false
  }

  return data.map((element, key) => (
    <View key={key}>
      <List.Item
        onPress={() => onPressCustom(element)}
        title={getTitle(element)}
        description={getDescription(element)}
        descriptionNumberOfLines={5}
        left={(props) => getLeftComponent(props, element)}
        right={(props) => getRightComponent(props, key)}
      />
      <Divider />
    </View>
  ))
}

const styles = StyleSheet.create({
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
  },
  icon: {
    marginTop: 5,
  },
})

export default FilesList
