export const identifierName = (alpha3Code, t) => {
  return (
    identifiersNames
      .find((identifier) => identifier.country === alpha3Code)
      ?.name(t) ?? t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_1
  )
}

/**
 * It returns true if the company's country's alpha3Code is equal to the alpha3Code passed in as an
 * argument.
 * @param company - {
 * @param alpha3Code - 'USA'
 * @returns Boolean.
 */
export const companySelectedHasCountry = (company, alpha3Code) => {
  const { country } = company
  return country.alpha3Code === alpha3Code
}

export const identifiersNames = [
  {
    country: 'ARG', //Argentina
    name: (t) => t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_1,
  },
  {
    country: 'BRA', //Brazil
    name: (t) => t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_2,
  },
  {
    country: 'USA', //"Estados Unidos de América"
    name: (t) => t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_2,
  },
  {
    country: 'PRY', //"Paraguay"
    name: (t) => t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_2,
  },
  {
    country: 'URU', //"Uruguay"
    name: (t) => t('COMPONENTS.COUNTRIES.IDENTIFIER').TEXT_2,
  },
]
