export default {
  TEXT_1: 'Siembra',
  TEXT_2: 'Cosecha',
  TEXT_3: 'Labranza',
  TEXT_4: 'Fertilización',
  TEXT_5: 'Aplicación',
  TEXT_6: 'Monitoreo',
  TEXT_7: 'Declaración',
  TEXT_8: 'Rinde esperado',
  TEXT_9: 'Fecha Est. Cosecha',
  TEXT_10: 'Realización',
  TEXT_11: 'Validaciones',
  TEXT_12: 'Superficie',
  TEXT_13: 'ha',
  TEXT_14: 'Sup',
  TEXT_15: 'Insumos',
  TEXT_16: 'Colaboradores',
  TEXT_17: 'En Borrador',
  TEXT_18: 'Planificadas a realizar',
  TEXT_19: 'EIQ planificado',
  TEXT_20: 'Realizadas pendientes de validación',
  TEXT_21: 'Observación',
  TEXT_22: 'Validación pendiente',
  TEXT_23: 'Terminadas',
  TEXT_24: 'EIQ',
  TEXT_25: 'Actividades',
  TEXT_26: 'Borrador',
  TEXT_27: 'Versión',
  TEXT_28: '%{lotsQuantity} lotes - %{surface} has',
  TEXT_29: '%{lotsQuantity} lote - %{surface} has',
  TEXT_30: 'Rinde esperado: %{pay} %{unitType}',
  TEXT_31: 'Rinde: %{pay} %{unitType}',
  TEXT_32: 'Fecha Est. Cosecha: %{dateEstimatedHarvest}',
  TEXT_33: 'Destino: %{storage}',
  TEXT_34: '¡Aún no asociaste ningún lote al ecosistema %{licenseName}!',
  TEXT_35:
    '¡Firmaste varias licencias dónde aun no tienes lotes asociados!. Ingresa a la sección de ecosistemas de tus cultivos para revisar que estén correctamente asociados.',
  TEXT_36:
    '¡La licencia %{licenseName} aún no tiene lotes asociados y empresas seleccionadas!',
  TEXT_37:
    '¡Hay varias licencias sin lotes y empresas asociados aún!, Requerimos tu ingreso para la operatividad de la misma.',
  TEXT_38: 'IR A LICENCIA',
  TEXT_39: 'IR A LICENCIAS',
  MODAL_VALIDATE: {
    TEXT_1: 'Actividad verificador',
    TEXT_2:
      'Hay una o más actividades de verificación en borrador. No se puede crear una nueva hasta no finalizar al menos una.',
    TEXT_3: 'OK',
  },
  MODAL_NO_LOGER_LOTS: {
    TEXT_1: 'Actividad verificador',
    TEXT_2: 'No hay lotes disponibles para generar una nueva verificación.',
    TEXT_3: 'OK',
  },
  TEXT_40: 'No conforme',
  TEXT_41: 'Verificado',
  TEXT_42: 'En verificación',
  TEXT_43: 'Verificación',
  TEXT_44: 'Tipo de %{activityType}: %{subTypeActivity}',
  TEXT_45: 'EIQ realizado',
  TEXT_46: 'PENDIENTE',
  TEXT_47: '%{value} PENDIENTES',
  TEXT_48: '%{establishmentsQuantity} Campos',
  TEXT_49: '%{establishmentsQuantity} Campo',
  TEXT_50: '%{lotsQuantity} lotes',
  TEXT_51: '%{lotsQuantity} lote',
  TEXT_52: 'CAMPO',
  TEXT_53: 'LOTES',
  TEXT_54: 'EMPEZAR',
  TEXT_55: 'VER DETALLE',
  TEXT_56: 'EDITAR',
  TEXT_57: 'Fecha y hora borrador:',
  TEXT_58: 'COMPLETAR',
  TEXT_59: 'REALIZACIÓN',
  TEXT_60: 'Borradores',
  TEXT_61: 'EN REVISIÓN',
  TEXT_62: 'a',
  NO_ACTIVITIES: 'Aún no tienes actividades',
  ADD_ACTIVITIES:
    'Agrega una nueva actividad para registrar las tareas a realizar o finalizadas en el campo.',
  ABOUT_ACTIVITIES: 'Conoce más sobre las actividades',
}
