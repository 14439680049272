import { Linking, Share } from 'react-native'
import { useAnalytics } from '@modules/common/hooks/useAnalytics'
import { mapWorkOrder, workOrderShareMessage } from '../utils'

const responseError = {
  shareWorkOrder: () => console.warn('does not have a work order number'),
  seeWorkOrder: () => console.warn('does not have a work order number'),
}

/**
 *
 * @param {Function} t
 * @param {Object} activity
 * @param {string} activity.name
 * @param {Object} activity.crop
 * @param {Object} activity.crop.cropType
 * @param {string} activity.crop.cropType.keyLabel
 * @param {number} activity.surface
 * @param {string} activity.areaUnit
 * @param {string} activity.dateStart
 * @param {string} activity.workOrderNumber
 * @param {string} activity.workOrderShortUrl
 * @param {string} activity.workOrderLongUrl
 * @param {string} locale
 *
 * @returns
 */
export const useWorkOrder = (t, activity, locale) => {
  if (!activity?.workOrderNumber) {
    return responseError
  }

  const workOrder = mapWorkOrder(activity, locale)
  const { shareWorkOrderAnalytics, viewWorkOrderAnalytics } = useAnalytics()
  const shareWorkOrder = async () => {
    if (!workOrder || !workOrder?.url) {
      return
    }

    try {
      const { title, message } = workOrderShareMessage(t, workOrder)

      shareWorkOrderAnalytics(activity.type.tag, activity.workOrderNumber)

      await Share.share({
        title,
        message,
      })
    } catch (err) {
      console.error(err.message)
    }
  }

  const seeWorkOrder = () => {
    viewWorkOrderAnalytics(activity.type.tag, activity.workOrderNumber)

    Linking.canOpenURL(workOrder?.longUrl) &&
      Linking.openURL(workOrder?.longUrl)
  }

  return {
    shareWorkOrder,
    seeWorkOrder,
  }
}
