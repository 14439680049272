import React, { useContext } from 'react'
import { StyleSheet, View, Platform, Image } from 'react-native'
import {
  Avatar,
  Subheading,
  Paragraph,
  Card,
  Divider,
  ActivityIndicator,
} from 'react-native-paper'
import { DrawerContentScrollView } from '@react-navigation/drawer'

import { AuthContext } from '@contextState/auth'
import { ConnectionContext } from '@contextState/connection'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import avatarDefault from '../../assets/images/avatar_default.png'
import useAuthCognito from '../modules/common/hooks/useAuthCognito'
import userTypes from '../constants/userTypes'
import DrawerItem from '../components/drawer/Item'
import {
  grayLight,
  white,
  blackHighEmphasis,
  blackMediumEmphasis,
  grayInput,
} from '@styles/palette'

function CustomDrawer(props) {
  const {
    user,
    config,
    isCompanyUcropit,
    isVerifierUcropit,
    isPendingAuthorization,
  } = useContext(AuthContext)
  const { isConnected } = useContext(ConnectionContext)

  const { t } = useContext(LanguageContext)
  const { redirectQuickSight, isLoading: isLoadingCognito } = useAuthCognito()

  const isUcropit = isCompanyUcropit || isVerifierUcropit

  function handleEdit() {
    props.navigation.navigate('Profile')
  }

  function goSettings() {
    props.navigation.navigate('Settings')
  }

  function trimStringPassLength(string, length) {
    if (length > 20) {
      return `${string.substring(0, 20)}...`
    }

    return string
  }

  const companyData = config?.companyAdmin ?? config.companySelected
  const rolSelectedDescription = config?.roleSelected?.keyLabel

  const navigateCompanyListIsAdviser = () => {
    props.navigation.navigate('CompanyListUcropit', {
      isAdviser: true,
    })
  }

  const navigateCompanyListOrAdd = () => {
    const companyNavigate = config.companySelected
      ? 'CompanyList'
      : 'CompanyAdd'
    props.navigation.navigate(
      companyNavigate,
      !config.companySelected
        ? {
            isAdviser: false,
          }
        : {}
    )
  }

  return (
    <>
      <DrawerContentScrollView {...props}>
        <Card style={styles.container}>
          {user.avatar ? (
            <Avatar.Image size={40} source={{ uri: user.avatarPath }} />
          ) : (
            <Image source={avatarDefault} style={styles.image} />
          )}
          <Subheading style={styles.subheading}>
            {user.firstName} {user.lastName}
          </Subheading>
          {Boolean(rolSelectedDescription) && (
            <Paragraph style={styles.rol}>{rolSelectedDescription}</Paragraph>
          )}
          <Paragraph style={styles.email}>{user.email}</Paragraph>
        </Card>
        <View>
          <DrawerItem
            onPress={handleEdit}
            disable={!isConnected}
            title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_11')}
            left={() => (
              <CommonIcon name={'PROFILE'} size={24} style={styles.iconItem} />
            )}
            right={() => (
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={styles.titleItem}
            style={styles.containerItem}
          />
          <Divider />
          <DrawerItem
            onPress={goSettings}
            disable={!isConnected}
            title={t('NAVIGATION.CUSTOM_DRAWER.SETTINGS')}
            left={() => (
              <CommonIcon name={'SETTINGS'} size={24} style={styles.iconItem} />
            )}
            right={() => (
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={styles.titleItem}
            style={styles.containerItem}
          />
          <Divider />
          <DrawerItem
            title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_6')}
            left={() => (
              <CommonIcon name={'COMPANY'} size={24} style={styles.iconItem} />
            )}
            right={() => (
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={styles.titleItem}
            style={[styles.containerItem, styles.cursorAuto]}
          />

          <DrawerItem
            onPress={navigateCompanyListOrAdd}
            disable={!isConnected}
            title={
              config.companySelected
                ? trimStringPassLength(
                    companyData?.name,
                    companyData?.name.length
                  )
                : t('NAVIGATION.CUSTOM_DRAWER.ADD_COMPANY')
            }
            description={config.companySelected ? companyData?.identifier : ''}
            right={() => (
              <CommonIcon
                name={'COMPANY-SWITCH'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={[styles.titleItem, { marginLeft: 0 }]}
            style={[styles.containerItem, styles.childItem]}
          />
          <Divider style={styles.childItem} />
          {isUcropit && (
            <View>
              {config?.companyAdmin &&
              config?.companyAdmin?._id !== config?.companySelected?._id ? (
                <DrawerItem
                  onPress={navigateCompanyListIsAdviser}
                  disable={!isConnected}
                  title={trimStringPassLength(
                    config.companySelected?.name,
                    config.companySelected?.name.length
                  )}
                  description={config.companySelected?.identifier}
                  right={() => (
                    <CommonIcon
                      name={'CHEVRON-RIGHT'}
                      size={20}
                      style={styles.iconRightItem}
                    />
                  )}
                  titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                  style={[styles.containerItem, styles.childItem]}
                />
              ) : (
                <DrawerItem
                  title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_16')}
                  onPress={navigateCompanyListIsAdviser}
                  disable={!isConnected}
                  right={() => (
                    <CommonIcon
                      name={'CHEVRON-RIGHT'}
                      size={20}
                      style={styles.iconRightItem}
                    />
                  )}
                  titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                  style={[styles.containerItem, styles.childItem]}
                />
              )}
              <Divider style={styles.childItem} />
            </View>
          )}

          <DrawerItem
            onPress={() => props.navigation.navigate('CompanyDetail')}
            disable={
              !isConnected || isPendingAuthorization || !config.companySelected
            }
            title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_13')}
            right={() => (
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={[styles.titleItem, { marginLeft: 0 }]}
            style={[styles.containerItem, styles.childItem]}
          />
          <Divider style={styles.childItem} />
          {Platform.OS === 'web' &&
            config?.roleSelected?.value === userTypes.PRODUCER.key &&
            config?.isAdmin && (
              <View>
                <DrawerItem
                  onPress={redirectQuickSight}
                  title={t('NAVIGATION.CUSTOM_DRAWER.PRODUCTION_BOARD')}
                  right={() =>
                    isLoadingCognito ? (
                      <ActivityIndicator size={20} color='green' />
                    ) : (
                      <CommonIcon
                        name={'ARROW-UP-RIGHT'}
                        size={20}
                        style={styles.iconRightItem}
                      />
                    )
                  }
                  titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                  style={[styles.containerItem, styles.childItem]}
                />
                <Divider style={styles.childItem} />
              </View>
            )}
          {Platform.OS !== 'web' && (
            <View>
              <DrawerItem
                onPress={() => props.navigation.navigate('Setup')}
                disable={isPendingAuthorization || !config.companySelected}
                title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_14')}
                right={() => (
                  <CommonIcon
                    name={'CHEVRON-RIGHT'}
                    size={20}
                    style={styles.iconRightItem}
                  />
                )}
                titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                style={[styles.containerItem, styles.childItem]}
              />
              <Divider style={styles.childItem} />
            </View>
          )}
          <DrawerItem
            onPress={() =>
              props.navigation.navigate('CompanyServiceIntegrationsSelector', {
                id: config.companySelected?._id,
              })
            }
            disable={
              !isConnected || isPendingAuthorization || !config.companySelected
            }
            title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_15')}
            right={() => (
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={20}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={[styles.titleItem, { marginLeft: 0 }]}
            style={[styles.containerItem, styles.childItem]}
          />
          <Divider style={styles.childItem} />
        </View>
      </DrawerContentScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  menu: { width: '64%' },
  icon: {
    marginHorizontal: 0,
    height: 22,
    width: 22,
  },
  paragraph: {
    color: grayInput,
    marginBottom: 8,
  },
  subheading: {
    fontWeight: '500',
    marginTop: 16,
    marginBottom: 0,
    textTransform: 'capitalize',
    color: blackHighEmphasis,
  },
  email: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
  },
  company: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheadingCompany: {
    fontWeight: '500',
    fontSize: 14,
    marginTop: 4,
    marginBottom: 0,
  },
  button: {
    width: '60%',
  },
  container: {
    paddingHorizontal: 16,
    paddingBottom: 32,
    paddingTop: 8,
    elevation: 0,
    shadowOpacity: 0,
  },
  label: {
    color: white,
  },
  avatar: {
    backgroundColor: grayLight,
    color: '#B8B8B8',
  },
  containerItem: { paddingHorizontal: 16, cursor: 'pointer' },
  cursorAuto: { cursor: 'auto' },
  titleItem: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginLeft: 16,
    justifyContent: 'center',
    marginTop: -3,
  },
  iconItem: { color: blackHighEmphasis },
  iconRightItem: { color: grayInput },
  childItem: {
    marginLeft: 50,
  },
  image: {
    width: 40,
    height: 40,
  },
  rol: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackHighEmphasis,
  },
})

export default CustomDrawer
