import React from 'react'
import { StyleSheet } from 'react-native'
import { FAB as Fab } from 'react-native-paper'
import PropTypes from 'prop-types'

import { primary500, white } from '@styles/palette'

export const FabButton = ({
  backgroundColor,
  color,
  icon,
  small,
  style,
  onPress,
  visible,
}) => {
  return (
    <Fab
      style={[styles.fab, { backgroundColor }, style]}
      color={color}
      icon={icon}
      onPress={onPress}
      small={small}
      visible={visible}
    />
  )
}

const FAB_MARGIN_BOTTOM = 40

const styles = StyleSheet.create({
  fab: {
    backgroundColor: primary500,
    position: 'absolute',
    color: white,
    right: 15,
    bottom: FAB_MARGIN_BOTTOM,
  },
})

FabButton.defaultProps = {
  backgroundColor: primary500,
  icon: '',
  color: white,
  small: false,
  style: {},
  visible: true,
}

FabButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.bool,
  style: PropTypes.object,
  visible: PropTypes.bool,
}
