import { authTypes as type } from '../types'

const initialState = {
  pin: null,
  hideWizzardIntro: false,
  welcomeCompanyList: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_PIN: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case type.SET_SHOW_INTRO_WIZZARD: {
      return {
        ...state,
        hideWizzardIntro: action.payload,
      }
    }
    case type.REMOVE_PIN: {
      return {
        ...state,
        pin: null,
      }
    }
    case type.SET_EMAIL_WELCOME_COMPANY: {
      const welcomeCompanyList = state?.welcomeCompanyList ?? []
      const index = welcomeCompanyList.findIndex(
        (item) => item.email === action.payload.email
      )
      if (index !== -1) {
        welcomeCompanyList[index] = action.payload
      } else {
        welcomeCompanyList.push(action.payload)
      }
      return {
        ...state,
        welcomeCompanyList,
      }
    }
    case type.REMOVE_EMAIL_WELCOME_COMPANY: {
      const welcomeCompanyList = state?.welcomeCompanyList ?? []
      return {
        ...state,
        welcomeCompanyList: welcomeCompanyList.filter(
          (element) => element.email !== action.payload
        ),
      }
    }
    default:
      return state
  }
}

export default reducer
