import React from 'react'
import { FlatList } from 'react-native'
import ItemSupply from './ItemSupply'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import SuppliesEmpty from '@components/supplies/SuppliesEmpty'

const ListSupplies = ({
  data,
  search,
  isLoading,
  onEndReached,
  firstPaginate,
  onPress,
  type,
  isConnected,
  searchValue,
  onRefresh,
}) => {
  return !firstPaginate ? (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={data}
      renderItem={({ item }) => (
        <ItemSupply
          search={search}
          item={item}
          onPress={() => onPress(item)}
          type={type}
        />
      )}
      keyExtractor={(item, index) => index.toString()}
      onRefresh={onRefresh}
      refreshing={isLoading}
      onEndReachedThreshold={0.2}
      onEndReached={onEndReached}
      ListEmptyComponent={
        <SuppliesEmpty
          type={type}
          searchValue={searchValue}
          isConnected={isConnected}
        />
      }
    />
  ) : (
    <LoaderCenterContainer />
  )
}

export default ListSupplies
