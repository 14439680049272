import { useContext } from 'react'
import { useNavigation } from '@react-navigation/native'

import { CropContext } from '@contextState/crop'

import { CROP_MENU_OPTIONS } from '@modules/common/utils'
import { useOfflineCrops } from '@modules/common/hooks'

export const useCropMenu = ({
  onDeleteDraft,
  onSuccessEnableCrop,
  onErrorEnableCrop,
  onSuccessDisableCrop,
  onDeleteCrop,
} = {}) => {
  const { setCurrentCrop } = useContext(CropContext)
  const { disableCropOffline, enableCropOffline } = useOfflineCrops()
  const navigation = useNavigation()

  const onPressCropMenu = async (crop, option, canWrite = false) => {
    switch (option) {
      case CROP_MENU_OPTIONS.DELETE_DRAFT:
        onDeleteDraft()
        break
      case CROP_MENU_OPTIONS.CROP_STORY:
        setCurrentCrop(crop)
        navigation.navigate('CropDetail', {
          id: crop._id,
        })
        break
      case CROP_MENU_OPTIONS.LICENSES:
        navigation.navigate('Licenses', {
          cropId: crop._id,
        })
        break
      case CROP_MENU_OPTIONS.COLLABORATORS:
        navigation.navigate('CropCollaborators', {
          id: crop._id,
          status: !canWrite,
        })
        break
      case CROP_MENU_OPTIONS.INTEGRATIONS_WITH_THIRD_PARTIES:
        navigation.navigate('ServiceIntegrations', {
          id: crop._id,
        })
        break
      case CROP_MENU_OPTIONS.ENABLE_OFFLINE:
        await enableCropOffline({
          crop,
          onSuccessEnableCrop,
          onErrorEnableCrop,
        })
        break
      case CROP_MENU_OPTIONS.DISABLE_OFFLINE:
        await disableCropOffline({ crop, onSuccessDisableCrop })
        break
      case CROP_MENU_OPTIONS.FARM_AND_FIELD_MANAGEMENT:
        navigation.navigate('CropFarmList', {
          cropId: crop._id,
        })
        break
      case CROP_MENU_OPTIONS.DELETE_CROP:
        if (onDeleteCrop) onDeleteCrop(crop)
        break
      default:
        break
    }
  }

  return {
    onPressCropMenu,
  }
}
