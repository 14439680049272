import React from 'react'
import { View, Text, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'
import { blackMediumEmphasis } from '@styles/palette'

const BackDropErrorContain = ({ error = {} }) => {
  const { typeError, description, image } = error
  return (
    <View style={styles.container}>
      <View style={styles.contentText}>
        <Text style={styles.subTitle}>{typeError}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
      <View style={styles.contentImage}>
        <Image
          source={{
            uri: image,
          }}
          style={styles.errorImage}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 10,
  },
  contentText: {
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 40,
  },
  subTitle: {
    fontSize: 16,
    color: blackMediumEmphasis,
    fontWeight: '500',
  },
  description: {
    fontSize: 12,
    color: blackMediumEmphasis,
    fontWeight: '400',
  },
  errorImage: {
    width: 115,
    height: 120,
  },
  contentImage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

BackDropErrorContain.propTypes = {
  error: PropTypes.shape({
    typeError: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
}

export default BackDropErrorContain
