import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Chip } from 'react-native-paper'
import { round } from 'lodash'

import { SustainabilityIcon } from '@modules/common/components'
import { CommonContext } from '@contextState/common'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  primary500,
  grayBackground,
  orange100,
  secondary700,
} from '@styles/palette'
import { FarmList, ApplicationType } from '..'

import { LanguageContext } from '@contextState/language'

import ACTIVITY_TYPES from '@constants/activityTypes'
import { formatDateMoment } from '@utils/date'
import ActivitySowingImage from '@icon/activity-sowing.png'

export const ActivityCardRealization = ({
  farms,
  date,
  subTypeActivity,
  activityType,
  signed,
  signedIf,
  supplies,
  suppliesList,
  envImpactIndex,
  areaUnit,
  contentStyle = {},
  farmRealized = false,
  showTextDone = true,
  isDirectAchievement = true,
  surface,
}) => {
  const { t, i18n } = useContext(LanguageContext)
  const { unitTypesSupplies } = useContext(CommonContext)

  const renderTotalEiq = () => {
    return (
      <View style={styles.contentTotalEiq}>
        <SustainabilityIcon name={'EIQ'} size={14} style={styles.iconEiq} />

        <Text style={styles.textTotalEiq}>
          EIQ{' '}
          {!isDirectAchievement
            ? envImpactIndex?.eiq?.value
            : envImpactIndex?.eiq?.planned}
        </Text>
      </View>
    )
  }

  return (
    <View style={[styles.done, contentStyle]}>
      {showTextDone && (
        <Text style={styles.textDone}>
          {
            t('VIEWS').CROP_STORY_LOT_DETAIL.COMPONENTS
              .ACTIVITY_CARD_REALIZATION.DONE
          }
        </Text>
      )}

      {date && (
        <Text style={styles.applicationDate}>
          {formatDateMoment(date, 'DD/MMM/YYYY', i18n.locale, true)}
        </Text>
      )}

      <FarmList farms={farms} realized={farmRealized} />

      {subTypeActivity && (
        <Text style={styles.titleTypeAplication}>
          {
            t('VIEWS').CROP_STORY_LOT_DETAIL.COMPONENTS
              .ACTIVITY_CARD_REALIZATION.APPLICATION_TYPE
          }
          : {subTypeActivity}
        </Text>
      )}

      {activityType === ACTIVITY_TYPES.ACT_APPLICATION.key && (
        <>
          {supplies.map((supply, index) => {
            const quantitySurface = surface
              ? supply.total / surface
              : supply.quantity
            const unitName = unitTypesSupplies.find(
              (unit) =>
                unit?.key?.toLowerCase() ===
                supply.supply?.unitTypeSupply?.key.toLowerCase()
            )

            const subTitle = `${round(Number(quantitySurface), 6)} ${
              unitName?.keyLabel ?? supply.unit
            }/${areaUnit} - ${supply.total} ${
              unitName?.keyLabel ?? supply.unit
            }`

            return (
              <ApplicationType
                key={index}
                title={supply.name}
                subTitle={subTitle}
                eiq={supply.eiq}
              />
            )
          })}

          {renderTotalEiq()}
        </>
      )}

      {activityType === ACTIVITY_TYPES.ACT_SOWING.key &&
        isDirectAchievement &&
        suppliesList?.map((item, key) => (
          <View key={key} style={styles.content}>
            <Image source={ActivitySowingImage} style={styles.contentIcon} />
            <Text style={styles.contentText}>
              {item?.supply?.brand ?? item?.supply?.name ?? ''}
            </Text>
          </View>
        ))}

      {activityType === ACTIVITY_TYPES.ACT_SOWING.key &&
        !isDirectAchievement &&
        supplies.map((supply, key) => {
          const quantitySurface = surface
            ? supply.total / surface
            : supply.quantity
          return (
            <View key={key} style={styles.contentSupplySowing}>
              <Text style={styles.contentSowingTitle}>
                {`${supply.name} ${supply?.type?.name ?? ''}`}
              </Text>
              <Text style={styles.contentSowingSubTitle}>
                {`${round(Number(quantitySurface), 6)} ${
                  supply.unitTypeSupplyName
                }/${areaUnit} - ${supply.total} ${supply.unitTypeSupplyName}`}
              </Text>
            </View>
          )
        })}

      {activityType === ACTIVITY_TYPES.ACT_FERTILIZATION.key &&
        isDirectAchievement &&
        supplies.map((supply, index) => {
          const quantitySurface = surface
            ? supply.total / surface
            : supply.quantity
          const quantity = round(Number(quantitySurface), 6)
          const subTitle = `${quantity} ${supply.unit}/${areaUnit} - ${supply.total} ${supply.unitTypeSupplyName}`

          return (
            <ApplicationType
              key={index}
              title={supply.name}
              subTitle={subTitle}
            />
          )
        })}

      {activityType === ACTIVITY_TYPES.ACT_FERTILIZATION.key &&
        !isDirectAchievement &&
        supplies.map((supply, index) => {
          return (
            <Text key={index} style={styles.textFertilization}>
              {supply.name}
            </Text>
          )
        })}

      {Boolean(signedIf) && Boolean(signed) && Boolean(signed - signedIf) && (
        <Chip textStyle={styles.chipText} style={styles.chipStyle}>
          {`${signedIf}/${signed} ${
            t('VIEWS').CROP_STORY_LOT_DETAIL.COMPONENTS
              .ACTIVITY_CARD_REALIZATION.PENDING_VALIDATIONS
          }`}
        </Chip>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  done: {
    paddingLeft: 16,
    paddingRight: 22,
    paddingTop: 16,
    paddingBottom: 12,
    backgroundColor: grayBackground,
    marginTop: 30,
  },
  textDone: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    letterSpacing: 0.4,
    color: blackHighEmphasis,
  },
  titleTypeAplication: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackHighEmphasis,
    marginVertical: 12,
  },
  applicationDate: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    letterSpacing: 0.4,
    color: primary500,
    marginTop: 5,
  },
  chipStyle: {
    backgroundColor: orange100,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 14,
    height: 22,
    paddingVertical: 6,
  },
  chipText: {
    color: secondary700,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '500',
    letterSpacing: 1.5,
    alignSelf: 'center',
  },
  contentTotalEiq: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 14,
    alignItems: 'center',
  },
  textTotalEiq: {
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
    color: blackMediumEmphasis,
  },
  iconEiq: {
    marginRight: 10,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  contentIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  contentText: {
    fontSize: 15,
  },
  contentSupplySowing: {
    marginBottom: 5,
  },
  contentSowingTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '700',
    letterSpacing: 0.25,
  },
  contentSowingSubTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    letterSpacing: 0.25,
  },
  textFertilization: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    letterSpacing: 0.25,
  },
})

ActivityCardRealization.prototype = {
  farms: PropTypes.array.isRequired,
  date: PropTypes.string,
  subTypeActivity: PropTypes.string,
  activityType: PropTypes.string,
  status: PropTypes.string,
  signed: PropTypes.number,
  signedIf: PropTypes.number,
  supplies: PropTypes.array,
  contentStyle: PropTypes.object,
  farmRealized: PropTypes.bool,
  showTextDone: PropTypes.bool,
  isDirectAchievement: PropTypes.bool,
}
