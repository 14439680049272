import MODAL_UPLOAD_FILE from '../../components/ModalUploadFile/translate/en'
import APPROVE_MODAL from '../../components/ApproveCompanyModal/translate/en'

export default {
  TEXT_1: 'Physical',
  TEXT_2: 'Legal',
  TEXT_3: 'Country',
  TEXT_4: 'Legal Identity',
  TEXT_5: 'Address',
  TEXT_6: 'Floor/Apartment/Office',
  TEXT_7: 'Edit company',
  TEXT_8: 'Attach Documentation',
  TEXT_9: 'Go back',
  TEXT_10: 'Company detail',
  APPROVE: 'Approve company',
  COMPONENTS: {
    MODAL_UPLOAD_FILE,
    APPROVE_MODAL,
  },
}
