import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import ButtonRipple from '@modules/common/components/buttons/ButtonRipple'
import {
  primary800,
  grayDA,
  gray12,
  blackHighEmphasis,
  gray999,
} from '@styles/palette'

const ItemCompany = ({ name, isSelected, identifier, onPress }) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentIcon}>
        <ButtonRipple onPress={onPress}>
          <CommonIcon
            name={isSelected ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'}
            size={25}
            color={isSelected ? primary800 : grayDA}
          />
        </ButtonRipple>
      </View>
      <View style={styles.contentInfo}>
        <Text style={styles.textName}>{name}</Text>
        <Text style={styles.textIdentifier}>{identifier}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentIcon: {
    paddingHorizontal: 20,
  },
  contentInfo: {
    padding: 10,
    paddingTop: 20,
    borderBottomColor: gray12,
    borderBottomWidth: 1,
    flex: 1,
  },
  textName: {
    color: blackHighEmphasis,
    fontWeight: '400',
  },
  textIdentifier: {
    color: gray999,
    fontSize: 12,
  },
})

export default ItemCompany
