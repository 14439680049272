import React from 'react'
import PropTypes from 'prop-types'

import { FabButton } from '@common/components'
import { CommonIcon } from '@modules/common/components'

export const FarmCreateButton = ({ goCreateFarm }) => {
  return (
    <FabButton
      icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
      onPress={goCreateFarm}
    />
  )
}

FarmCreateButton.propTypes = {
  goCreateFarm: PropTypes.func.isRequired,
}
