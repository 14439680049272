import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

const PercentageOverlap = ({ percentage, text, overlap = false }) => (
  <View style={styles.container}>
    <Text
      style={[styles.percentage, overlap ? styles.overlap : styles.nooverlap]}
    >
      {percentage}
    </Text>
    <Text style={styles.text}>{text}</Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 16,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  percentage: {
    paddingHorizontal: 4,
    paddingVertical: 2,
    fontSize: 20,
    fontWeight: '500',
  },
  overlap: {
    backgroundColor: '#E8F5E9',
    color: '#1B5E20',
  },
  nooverlap: {
    backgroundColor: '#FBE9E7',
    color: '#BF360C',
  },
  text: {
    fontSize: 16,
    color: '#333333',
    paddingLeft: 4,
  },
})

PercentageOverlap.propTypes = {
  percentage: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  overlap: PropTypes.bool,
}

export default PercentageOverlap
