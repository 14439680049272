import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import ActionSheet from 'react-native-actions-sheet'
import Unlink from './Unlink'
import PropTypes from 'prop-types'

const ActionSheetUnlink = ({
  reference,
  currentKey,
  findCropCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToUnlinkCollaborators,
  onClose,
}) => {
  return (
    Platform.OS !== 'web' && (
      <ActionSheet ref={reference} containerStyle={styles.actionStyles}>
        <View style={styles.containerActionSheet}>
          <Unlink
            currentKey={currentKey}
            findCropCollaborator={findCropCollaborator}
            isFetchingCrop={isFetchingCrop}
            setDisabled={setDisabled}
            getTitleMenu={getTitleMenu}
            goToUnlinkCollaborators={() => goToUnlinkCollaborators()}
            onClose={onClose}
          />
        </View>
      </ActionSheet>
    )
  )
}

const styles = StyleSheet.create({
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerActionSheet: {
    height: 500,
    marginHorizontal: 10,
  },
})

ActionSheetUnlink.propTypes = {
  reference: PropTypes.object.isRequired,
  currentKey: PropTypes.number,
  findCropCollaborator: PropTypes.array.isRequired,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToUnlinkCollaborators: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ActionSheetUnlink
