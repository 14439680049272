export default {
  TEXT_1: 'EIQ',
  TEXT_2: 'Campo requerido',
  TEXT_3: 'Numero invalido',
  TEXT_4: 'Número Negativo',
  TEXT_5: 'La superficie debe ser mayor a 0',
  FIELD_1: {
    LABEL: 'Insumo',
  },
  FIELD_2: {
    LABEL: 'Unidad de medida',
  },
  FIELD_3: {
    LABEL: 'Total',
    KEY: 'Aceptar',
  },
  FIELD_4: {
    LABEL: 'Superficie has',
  },
  FIELD_5: {
    LABEL: 'Cantidad de',
  },
  TEXT_6: 'ha',
  TEXT_7: 'Cancelar',
  TEXT_8: 'Seleccionar',
  TEXT_9: 'Buscar el insumo requerido',
  TEXT_10: 'insumos',
}
