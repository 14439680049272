import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import activityTypes from '@constants/activityTypes'
import CommonList from '@components/common/CommonList'
import { unitMeasureSystem } from '@utils/constants'

export const DestinationsList = ({
  tag,
  storages,
  handleDeleteDestination,
  readOnly = false,
  company,
}) => {
  if (tag !== activityTypes.ACT_HARVEST.key) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <View>
      {storages.map((el, key) => (
        <CommonList
          key={key}
          index={key}
          iconRender={() => <CommonIcon name={'STORAGE'} size={45} />}
          onDelete={() => handleDeleteDestination(key)}
          data={{
            name: t('VIEWS').ACTIVITY_CREATE.DESTINATION_LIST.TEXT_1,
            description: `${el.tonsHarvest} ${
              company?.unitMeasureSystem === unitMeasureSystem.IMPERIAL
                ? t('VIEWS.HARVEST.TEXT_9')
                : t('VIEWS.HARVEST.TEXT_4')
            }`,
          }}
          disabled={readOnly}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
})

DestinationsList.propTypes = {
  tag: PropTypes.string.isRequired,
  storages: PropTypes.array,
  handleDeleteDestination: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
}
