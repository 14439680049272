import React, {
  useContext,
  useState,
  useCallback,
  createRef,
  useEffect,
} from 'react'
import { View, StyleSheet, ScrollView, Image, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import {
  FAB as Fab,
  Menu,
  ActivityIndicator,
  Colors,
  Subheading,
  Text,
  Snackbar,
} from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import CollaboratorCard from '@components/collaborators/CollaboratorCard'
import useNetwork from '@utils/network'
import { AuthContext } from '@contextState/auth'
import { ConnectionContext } from '@contextState/connection'
import { cropEmpty } from '@constants/images'
import CardContactActions from '@components/common/CardContactActions'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { collaboratorsActions } from '@store/actions'
import MarkResponsibleModal from '@components/modals/collaborator/markResponsibleModal'

import {
  ROL,
  DISABLED_MENU,
  ITS_CAM,
  DISABLED_MENU_VERIFIER,
} from '@constants/roles'
import {
  MENU_COLLABORATOR,
  MENU_COLLABORATOR_NEW,
} from '@constants/menuCollaborator'
import userTypes from '@constants/userTypes'
import { COLLABORATOR_TYPES } from '@constants/collaboratorsTypes'
import DelegateValidations from '@components/modals/collaborator/DelegateValidations'
import ActionSheetDelegateValidations from '@components/modals/collaborator/ActionSheetDelegateValidations'
import Unlink from '@components/modals/collaborator/Unlink'
import ActionSheetUnlink from '@components/modals/collaborator/ActionSheetUnlink'
import { ModalDefault, VerifyPin } from '@modules/common/components'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'
import { CommonContext } from '@contextState/common'
import PropTypes from 'prop-types'
import { CommonIcon } from '@modules/common/components'

function CropCollaboratorsListScreen({ route, navigation }) {
  const actionSheetDelegateCollaborators = createRef()
  const actionSheetUnlinkCollaborators = createRef()
  const actionSheetNotCollaborators = createRef()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const {
    isModalVisible: isModalVerifier,
    toggleModal: toggleModalVerifier,
    closeModal: closeModalVerifier,
  } = useModal()
  const [isModalNotCollaborators, setIsModalNotCollaborators] = useState(false)
  const [isModalDelegateCollaborators, setIsModalDelegateCollaborators] =
    useState(false)
  const [isModalUnlinkCollaborators, setIsModalUnlinkCollaborators] =
    useState(false)
  const [
    isActionSheetUnlinkCollaborators,
    setIsActionSheetUnlinkCollaborators,
  ] = useState(false)
  const { user, isVerifierUcropit } = useContext(AuthContext)
  const [currentCollaborator, setCurrentCollaborator] = useState({})
  const [openMenu, setOpenMenu] = useState({})
  const [currentKey, setCurrentKey] = useState(null)

  const { doRequest } = useNetwork()
  const { isConnected } = useContext(ConnectionContext)
  const { t } = useContext(LanguageContext)
  const [pressedItem, setPressedItem] = useState({})
  const { roleTypes } = useContext(CommonContext)

  const [findCropCollaborator, setFindCropCollaborator] = useState([])
  const [isFetchingCrop, setIsFetchingCrop] = useState(false)
  const phoneCountry = 'AR'
  const [isModalMarkResponsible, setIsModalMarkResponsible] = useState(false)
  const [isResponsibleRol, setIsResponsibleRol] = useState('')
  const [isMarkResponsible, setIsMarkResponsible] = useState([])
  const [selectedCollaborator, setSelectedCollaborator] = useState({})
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const companySelected = user.companies.find(
    (company) => company.company?._id === user.config.companySelected._id
  )
  const [unLinkCollaboratorSelected, setUnLinkCollaboratorSelected] = useState(
    {}
  )
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      fetchCropCollaborator()
      return () => true
    }, [])
  )

  useEffect(() => {
    success()
    return () => true
  }, [route.params.status])

  const success = () => {
    if (route.params.status === 'delete') {
      setVisibleSnackbar(true)
    }
  }

  const fetchCropCollaborator = async () => {
    if (isFetchingCrop) {
      return
    }

    setIsFetchingCrop(true)

    if (isConnected) {
      const { id } = route.params

      const response = await doRequest({
        method: 'GET',
        url: `crops/${id}/collaborators`,
      })

      const currentUser = response.data.find(
        (member) => member.userId._id === user._id
      )

      setFindCropCollaborator(response.data)

      setCurrentCollaborator(currentUser)

      dispatch(collaboratorsActions.setCollaborators(response.data))
    }

    setIsFetchingCrop(false)
  }

  const openDelegateCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetDelegateCollaborators.current?.show()
      : setIsModalDelegateCollaborators(true)

  const openNotCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetNotCollaborators.current?.show()
      : setIsModalNotCollaborators(true)

  const handlePressedItem = (idCard) =>
    setPressedItem({ [idCard]: !pressedItem[idCard] })

  const collaboratorAdd = () => {
    navigation.navigate('CropCollaboratorsAdd', {
      id: route.params.id,
    })
  }

  const setDisabled = (collaboratorRol) => {
    const typeRol = roleTypes.find(
      (rol) => rol.value === currentCollaborator?.rol
    )
    if (!typeRol) {
      return false
    }
    const assignable = typeRol.assignable.map((item) => item.value)
    return (
      currentCollaborator?.rol === collaboratorRol &&
      assignable.includes[collaboratorRol]
    )
  }

  const getStyleItem = (style, disabled) =>
    style
      ? styles.titleMenu
      : disabled
      ? styles.unlinkMenuDisabled
      : styles.unlinkMenu

  const getTitleMenu = (collaborator) =>
    collaborator?.firstName
      ? `${collaborator.firstName} ${collaborator.lastName}`
      : `${collaborator.email}`

  const setDisabledMenu = (key, collaborator, pendingValidation) => {
    switch (key) {
      case COLLABORATOR_TYPES.DELEGATE.key:
        if (!pendingValidation) {
          return true
        }
        if (
          DISABLED_MENU[currentCollaborator?.rol] &&
          ITS_CAM[collaborator.rol]
        ) {
          return true
        }
        break
      case COLLABORATOR_TYPES.UNLINK.key:
        if (
          ((collaborator.rol === userTypes.MARKETER.key &&
            currentCollaborator?.rol === userTypes.MARKETER.key) ||
            (collaborator.rol === userTypes.PROVIDER.key &&
              currentCollaborator?.rol === userTypes.PROVIDER.key)) &&
          companySelected &&
          !companySelected.isAdmin
        ) {
          return true
        }

        break
      case COLLABORATOR_TYPES.UCROPIT_ADVISER.key:
        if (collaborator.tag === COLLABORATOR_TYPES.UCROPIT_ADVISER.key)
          return true
        break

      case COLLABORATOR_TYPES.CROP_RESPONSIBLE.key:
        if (collaborator.tag === COLLABORATOR_TYPES.CROP_RESPONSIBLE.key)
          return true
        break

      case COLLABORATOR_TYPES.CROP_MARKETER.key:
        if (collaborator.tag === COLLABORATOR_TYPES.CROP_MARKETER.key)
          return true
        break
      case COLLABORATOR_TYPES.VERIFIER_RESPONSIBLE.key:
        if (collaborator.tag === COLLABORATOR_TYPES.VERIFIER_RESPONSIBLE.key)
          return true
        break
      default:
        return false
    }
  }

  const handleOpenMenu = (key) => setOpenMenu({ [key]: !openMenu[key] })

  const addMarkResponsible = async (memberId, flagKey) => {
    const response = await doRequest({
      method: 'POST',
      url: `collaborators/flags`,
      data: { memberId, flagKey },
    })
    if (response) {
      fetchCropCollaborator()
    }
  }

  const editMarkResponsible = (userId, tag) => {
    const editMark = isMarkResponsible.map((collaborator) =>
      collaborator.id === userId
        ? { ...collaborator, id: userId, tag: tag }
        : collaborator
    )
    setIsMarkResponsible(editMark)
  }

  const markResponsible = (menu, collaborator) => {
    setIsResponsibleRol(menu)
    setSelectedCollaborator({
      tag: menu,
      userId: collaborator.userId._id,
      memberId: collaborator.memberId,
    })
    setIsModalMarkResponsible(true)
  }

  const handleItemMenu = (key2, menu, collaborator) => {
    switch (menu) {
      case COLLABORATOR_TYPES.DELEGATE.key:
        setCurrentKey(key2)
        if (
          findCropCollaborator.filter(
            (collaborator) => collaborator.firstName != ''
          ).length > 1
        ) {
          if (
            ITS_CAM[currentCollaborator?.rol] &&
            findCropCollaborator.filter(
              (collaborator) => ITS_CAM[collaborator.rol]
            ).length > 1
          ) {
            openDelegateCollaborators()
          } else if (
            !ITS_CAM[currentCollaborator?.rol] &&
            findCropCollaborator.filter(
              (collaborator) => !ITS_CAM[collaborator.rol]
            ).length > 1
          ) {
            openDelegateCollaborators()
          } else {
            openNotCollaborators()
          }
        } else {
          openNotCollaborators()
        }
        break
      case COLLABORATOR_TYPES.UNLINK.key:
        setCurrentKey(key2)
        setUnLinkCollaboratorSelected(collaborator)
        setShowModal(true)

        if (collaborator?.rol === userTypes.VERIFIER.key) {
          toggleModalVerifier()
        } else {
          if (collaborator.pendingValidations.length) {
            setIsModalUnlinkCollaborators(true)
          } else {
            openUnlinkCollaborators()
          }
        }

        break
      case COLLABORATOR_TYPES.UCROPIT_ADVISER.key:
        markResponsible(menu, collaborator)
        break

      case COLLABORATOR_TYPES.CROP_RESPONSIBLE.key:
        markResponsible(menu, collaborator)

        break

      case COLLABORATOR_TYPES.CROP_MARKETER.key:
        markResponsible(menu, collaborator)
        break
      case COLLABORATOR_TYPES.VERIFIER_RESPONSIBLE.key:
        markResponsible(menu, collaborator)
        break

      default:
        break
    }
  }

  const closeDelegateCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetDelegateCollaborators.current?.hide()
      : setIsModalDelegateCollaborators(false)

  const openUnlinkCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetUnlinkCollaborators.current?.show()
      : setIsActionSheetUnlinkCollaborators(true)

  const closeUnlinkCollaborators = () =>
    Platform.OS !== 'web'
      ? actionSheetUnlinkCollaborators.current?.hide()
      : setIsActionSheetUnlinkCollaborators(false)

  const pressCardContact = (key, collaborator) =>
    pressedItem[key] && (
      <CardContactActions
        item={collaborator}
        country={phoneCountry}
        stylesContact={
          Platform.OS !== 'web' ? styles.actions : styles.actionsWeb
        }
      />
    )

  const hideModalMarkResponsible = () => {
    setIsModalMarkResponsible(false)
  }

  const menuItem = (collaboratorRol, collaborator, key) => {
    if (
      currentCollaborator?.rol === userTypes.VERIFIER.key &&
      collaboratorRol !== userTypes.VERIFIER.key
    ) {
      return
    }

    if (
      collaboratorRol === userTypes.VERIFIER.key &&
      currentCollaborator?.rol === userTypes.VERIFIER.key &&
      !companySelected?.isAdmin
    ) {
      return
    }

    if (
      collaboratorRol === userTypes.VERIFIER.key &&
      DISABLED_MENU_VERIFIER[currentCollaborator?.rol]
    ) {
      return
    }

    if (ITS_CAM[collaboratorRol] && DISABLED_MENU[currentCollaborator.rol]) {
      return
    }

    return (
      <>
        {collaborator &&
          collaborator?.canMarkFlags &&
          collaborator?.canMarkFlags.map((val, keys) => (
            <Menu.Item
              key={keys}
              onPress={() => {
                handleOpenMenu(key)
                handleItemMenu(key, val.flag, collaborator)
              }}
              disabled={setDisabledMenu(
                val.flag,
                collaborator,
                collaborator.pendingValidations.length
              )}
              title={MENU_COLLABORATOR_NEW[val.flag](t)}
              titleStyle={getStyleItem(true)}
            />
          ))}
        {MENU_COLLABORATOR[collaboratorRol]?.map((val, keys) => {
          const disabled = setDisabledMenu(
            val.menu,
            collaborator,
            collaborator.pendingValidations.length
          )
          return (
            <Menu.Item
              key={keys}
              onPress={() => {
                handleOpenMenu(key)
                handleItemMenu(key, val.menu, collaborator)
              }}
              disabled={disabled}
              title={val.title(t)}
              titleStyle={getStyleItem(val.style, disabled)}
            />
          )
        })}
      </>
    )
  }

  const UnlinkModal = ({ doneAction }) => {
    if (!currentKey && Object.keys(unLinkCollaboratorSelected).length)
      return null

    const text = (
      <>
        <Text style={styles.modalText}>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_6}
          <Text style={styles.modalRole}>
            {ROL[unLinkCollaboratorSelected?.rol](t)}
          </Text>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_7}
        </Text>
      </>
    )
    return (
      <ModalConfirm
        visible={isModalUnlinkCollaborators}
        onConfirm={doneAction}
        title={t('VIEWS').CROP_COLLABORATOR.TEXT_19}
        contentChildren={text}
        confirmText={t('VIEWS').CROP_COLLABORATOR.TEXT_22}
        onClose={() => {
          setIsModalUnlinkCollaborators(!isModalUnlinkCollaborators)
        }}
        confirmButtonStyle={styles.buttonDialog}
      />
    )
  }

  const UnlinkSwipe = () => {
    setIsModalUnlinkCollaborators(!isModalUnlinkCollaborators)
    openUnlinkCollaborators()
  }

  const goToCollaboratorsDelegateSignatures = () => {
    closeDelegateCollaborators()
    navigation.navigate('CollaboratorsDelegateSignatures', {
      cropId: route.params.id,
      collaboratorsIds: findCropCollaborator[currentKey].userId._id,
    })
  }

  const goToUnlinkCollaborators = () => {
    closeUnlinkCollaborators()

    if (findCropCollaborator[currentKey].pendingValidations.length) {
      navigation.navigate('UnlinkCollaborator', {
        cropId: route.params.id,
        collaboratorsIds: findCropCollaborator[currentKey].userId._id,
      })
    } else {
      verifyPin()
    }
  }

  const verifyPin = async () => {
    if (verifyExpirationPin()) {
      setTimeout(toggleModal, 300)
    } else {
      const { validate } = await geTokenAuthPin()
      if (!validate) {
        setTimeout(toggleModal, 300)
        return
      }
      removeCollaboratorCrop()
    }
  }

  const addTagCollaborator = () => {
    !isMarkResponsible.find(
      (responsible) => responsible.id === selectedCollaborator.userId
    )
      ? addMarkResponsible(
          selectedCollaborator.memberId,
          selectedCollaborator.tag
        )
      : editMarkResponsible(
          selectedCollaborator.userId,
          selectedCollaborator.tag
        )

    setIsModalMarkResponsible(false)
  }

  const getTitleCurrent = (collaborator) =>
    collaborator.userId._id === user._id
      ? t('VIEWS').CROP_COLLABORATOR.TEXT_29
      : ''

  const removeCollaboratorCrop = async () => {
    try {
      closeModal()

      const idCollaborator = unLinkCollaboratorSelected.userId._id
      const idCrop = {
        idCrop: route.params.id,
      }

      const response = await doRequest({
        method: 'PUT',
        url: `crops/unlink/${idCollaborator}`,
        data: idCrop,
      })

      if (!response) {
        closeModal()
        setVisibleError(true)
        return
      }

      if (idCollaborator === user._id) {
        setTimeout(() => navigation.goBack(), 3000)
      } else {
        const newCollaborator = findCropCollaborator.filter(
          (collaborator) => collaborator.userId._id !== idCollaborator
        )
        setFindCropCollaborator(newCollaborator)
      }

      setVisibleSnackbar(true)
    } catch (error) {
      closeModal()
      setVisibleError(true)
    }
  }

  if (isFetchingCrop) {
    return (
      <ActivityIndicator
        size='large'
        color={Colors.green500}
        style={styles.loader}
      />
    )
  }

  const TextUnlinkVerifier = (
    <Text style={styles.textVerifier}>
      {t('VIEWS').CROP_COLLABORATOR.MODAL_UNLINK_VERIFIER.TEXT_1}
      <Text style={styles.textVerifierOrange}>
        {t('VIEWS').CROP_COLLABORATOR.MODAL_UNLINK_VERIFIER.TEXT_2}
      </Text>
      {t('VIEWS').CROP_COLLABORATOR.MODAL_UNLINK_VERIFIER.TEXT_3}
    </Text>
  )

  return (
    <View style={styles.flex}>
      {!findCropCollaborator.length ? (
        <View style={styles.container}>
          <Image source={cropEmpty} style={styles.emptyState} />

          <Subheading style={styles.emptyStateText}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_11}
          </Subheading>
        </View>
      ) : (
        <ScrollView>
          <View style={styles.scrollContainer}>
            {findCropCollaborator.map((collaborator, key) => (
              <CollaboratorCard
                key={key}
                onPress={() => handlePressedItem(key)}
                disabled={setDisabled(collaborator.rol)}
                companyName={collaborator.company.name}
                collaboratorType={collaborator.rol}
                title={getTitleMenu(collaborator)}
                subtitle={ROL[collaborator.rol](t) || ROL['DEFAULT'](t)}
                open={Boolean(openMenu[key])}
                handlePressedItem={() => handlePressedItem(key)}
                handleOpenMenu={() => handleOpenMenu(key)}
                status={!!collaborator.firstName}
                pendingValidation={collaborator.pendingValidations.length}
                tag={collaborator.tag}
                actionCard={pressCardContact(key, collaborator)}
                menuItems={menuItem(collaborator.rol, collaborator, key)}
                currentTitle={getTitleCurrent(collaborator)}
              />
            ))}
          </View>
        </ScrollView>
      )}
      {!isVerifierUcropit && (
        <Fab
          style={styles.fab}
          icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
          onPress={collaboratorAdd}
        />
      )}
      <ActionSheetDelegateValidations
        reference={actionSheetDelegateCollaborators}
        currentKey={currentKey}
        findCropCollaborator={findCropCollaborator}
        isFetchingCrop={isFetchingCrop}
        setDisabled={setDisabled}
        getTitleMenu={getTitleMenu}
        goToCollaboratorsDelegateSignatures={() =>
          goToCollaboratorsDelegateSignatures()
        }
        onClose={() => actionSheetDelegateCollaborators.current?.hide()}
      />
      <ModalConfirm
        visible={isModalVerifier}
        onConfirm={() => {
          closeModalVerifier()
          verifyPin()
        }}
        title={t('VIEWS').CROP_COLLABORATOR.TEXT_19}
        contentChildren={TextUnlinkVerifier}
        confirmText={t('VIEWS').CROP_COLLABORATOR.TEXT_22}
        onClose={() => {
          closeModalVerifier()
        }}
        cancelText={t('VIEWS').CROP_COLLABORATOR.TEXT_9}
        confirmButtonStyle={styles.buttonDialog}
        cancelButtonStyle={styles.cancelButtonStyle}
        cancelButtonTextStyle={styles.unlinkMenu}
      />
      <ModalDefault
        isModalVisible={isModalDelegateCollaborators}
        closeModal={() => setIsModalDelegateCollaborators(false)}
      >
        <DelegateValidations
          currentKey={currentKey}
          findCropCollaborator={findCropCollaborator}
          isFetchingCrop={isFetchingCrop}
          setDisabled={setDisabled}
          getTitleMenu={getTitleMenu}
          goToCollaboratorsDelegateSignatures={() =>
            goToCollaboratorsDelegateSignatures()
          }
          onClose={() => setIsModalDelegateCollaborators(false)}
        />
      </ModalDefault>
      {currentKey !== null && showModal && (
        <UnlinkModal doneAction={UnlinkSwipe} />
      )}
      <ActionSheetUnlink
        reference={actionSheetUnlinkCollaborators}
        currentKey={currentKey}
        findCropCollaborator={findCropCollaborator}
        isFetchingCrop={isFetchingCrop}
        setDisabled={setDisabled}
        getTitleMenu={getTitleMenu}
        goToUnlinkCollaborators={() => goToUnlinkCollaborators()}
        onClose={() => actionSheetUnlinkCollaborators.current?.hide()}
      />
      <ModalDefault
        isModalVisible={isActionSheetUnlinkCollaborators}
        closeModal={() => setIsActionSheetUnlinkCollaborators(false)}
      >
        <Unlink
          currentKey={currentKey}
          findCropCollaborator={findCropCollaborator}
          isFetchingCrop={isFetchingCrop}
          setDisabled={setDisabled}
          getTitleMenu={getTitleMenu}
          goToUnlinkCollaborators={() => goToUnlinkCollaborators()}
          onClose={() => setIsActionSheetUnlinkCollaborators(false)}
        />
      </ModalDefault>
      <ActionSheetDelegateValidations
        reference={actionSheetNotCollaborators}
        notfound
        currentKey={currentKey}
        findCropCollaborator={findCropCollaborator}
        isFetchingCrop={isFetchingCrop}
        setDisabled={setDisabled}
        getTitleMenu={getTitleMenu}
        goToCollaboratorsDelegateSignatures={() =>
          goToCollaboratorsDelegateSignatures()
        }
        onClose={() => actionSheetNotCollaborators.current?.hide()}
      />
      <ModalDefault
        isModalVisible={isModalNotCollaborators}
        closeModal={() => setIsModalNotCollaborators(false)}
        style={styles.contentNotFound}
      >
        <DelegateValidations
          modal
          notfound
          onClose={() => setIsModalNotCollaborators(false)}
          currentKey={currentKey}
          findCropCollaborator={findCropCollaborator}
          isFetchingCrop={isFetchingCrop}
          setDisabled={setDisabled}
          getTitleMenu={getTitleMenu}
          goToCollaboratorsDelegateSignatures={() =>
            goToCollaboratorsDelegateSignatures()
          }
        />
      </ModalDefault>
      <MarkResponsibleModal
        isModalMarkResponsible={isModalMarkResponsible}
        isResonsableRol={isResponsibleRol}
        hideModalMarkResponsible={hideModalMarkResponsible}
        addTagCollaborator={addTagCollaborator}
      />
      <Snackbar
        visible={visibleSnackbar}
        duration={5000}
        onDismiss={() => {
          setVisibleSnackbar(false)
        }}
        style={styles.snackBarColorSuccess}
      >
        <Text style={styles.snackText}>
          {unLinkCollaboratorSelected
            ? t('VIEWS').CROP_COLLABORATOR.TEXT_32
            : t('VIEWS').CROP_COLLABORATOR.TEXT_33}
        </Text>
      </Snackbar>
      <Snackbar
        visible={visibleError}
        duration={5000}
        onDismiss={() => {
          setVisibleError(false)
        }}
        style={styles.snackBarColorError}
      >
        <Text style={styles.snackText}>
          {t('VIEWS').CROP_COLLABORATOR.TEXT_31}
        </Text>
      </Snackbar>
      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={removeCollaboratorCrop}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    color: '#FFFFFF',
    backgroundColor: '#4CA050',
  },
  scrollContainer: {
    padding: 16,
    flex: 1,
  },
  emptyStateText: {
    textAlign: 'center',
    marginTop: 16,
  },
  emptyState: {
    width: 203,
    height: 150,
    resizeMode: 'contain',
  },
  titleMenu: {
    fontSize: 14,
    fontWeight: '500',
  },
  actions: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: '-5%',
  },
  actionsWeb: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  unlinkMenu: {
    fontSize: 14,
    fontWeight: '500',
    color: '#FF7043',
  },
  unlinkMenuDisabled: {
    fontSize: 14,
    fontWeight: '500',
    color: '#FFAA90',
  },
  contentNotFound: {
    backgroundColor: '#FBE9E7',
  },
  buttonDialog: {
    backgroundColor: '#FF7043',
  },
  cancelButtonStyle: {
    borderColor: '#FF7043',
  },
  modalText: {
    fontSize: 16,
    fontWeight: '500',
  },
  modalRole: {
    color: '#FF7043',
  },
  snackText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'left',
    color: 'black',
  },
  snackBarColorSuccess: {
    backgroundColor: '#E8F5E9',
    paddingHorizontal: 12,
  },
  snackBarColorError: {
    backgroundColor: '#FBE9E7',
    paddingHorizontal: 12,
  },
  textVerifier: {
    fontSize: 16,
    fontWeight: '500',
  },
  textVerifierOrange: {
    fontSize: 16,
    color: '#FF7043',
    fontWeight: '500',
  },
})

CropCollaboratorsListScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CropCollaboratorsListScreen
