import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  Platform,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { Divider, Text, Colors } from 'react-native-paper'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { Layout } from '@modules/common/components'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { getDecimalsQuantity } from '@modules/common/utils'
import { CompanyDetails } from '@common/components'
import { AuthContext } from '@contextState/auth'
import { EstablishmentContext } from '@contextState/establishment'
import { CommonContext } from '@contextState/common'
import ModalSuccess from '@components/common/v1/ModalSuccess'
import useNetwork from '@utils/network'
import { round } from 'lodash'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import { CropContext } from '@contextState/crop'
import InputText from '@components/inputs/InputText'
import InputSelectDropdown from '@components/inputs/InputSelectDropdown'
import { buttonDisabled } from '@styles/palette'
import { useDispatch, useSelector } from 'react-redux'
import { draftCropActions } from '@store/actions'
import { selectDraftCrop } from '@store/selectors/draftCrops'
import { formatDateMoment } from '@utils/date'
import { primary500, white } from '@styles/palette'
import { useChangeOutliersHarvest } from '@common/hooks'
import { enterToCropStory } from '@services/analytics'
import { truncateSurface } from '@common/utils/truncateSurface'

const CropCreateConfirm = ({ navigation }) => {
  const { t, i18n } = useContext(LanguageContext)
  const { establishments, resetData } = useContext(EstablishmentContext)
  const { stages, cropTypes, unitTypes, optionsCountries } =
    useContext(CommonContext)
  const { currentCrop, setCurrentCrop } = useContext(CropContext)
  const { config, handleUser, isCompanyUcropit, user } = useContext(AuthContext)

  const [establishmentsSelected, setEstablishmentsSelected] = useState([])
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
  const [goToViewOnFinish, setGoToViewOnFinish] = useState({
    name: 'CropList',
    params: {},
  })
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(false)
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)

  const { doRequest } = useNetwork()

  const { collaborators } = useSelector((state) => state.draftCrop)

  const valuesForm = useSelector(
    selectDraftCrop(config.companySelected?.identifier)
  )

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    pay: Yup.number()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .positive(t('ERRORS.FORM_ERRORS.IS_POSITIVE')),
    surface: Yup.number()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .positive(t('ERRORS.FORM_ERRORS.IS_POSITIVE')),
  })

  const formik = useFormik({
    initialValues: valuesForm
      ? {
          ...valuesForm,
          surface: round(
            establishmentsSelected.reduce(
              (accumulator, { lots }) =>
                accumulator +
                lots.reduce(
                  (subAccumulator, { surface }) => subAccumulator + surface,
                  0
                ),
              0
            ),
            2
          ),
        }
      : {},
    enableReinitialize: true,
    validationSchema,
  })

  const {
    setFieldValue,
    values,
    touched,
    errors,
    setFieldTouched,
    isValid,
    validateForm,
  } = formik

  const currentUserIsAdminOfCompany = Boolean(
    user?.companies.find(
      (company) =>
        company?.company?._id === config?.companySelected?._id &&
        company?.isAdmin
    )
  )

  useFocusEffect(
    useCallback(() => {
      setTimeout(validateForm)

      buildEstablishmentsSelected()
    }, [])
  )

  useEffect(() => {
    if (!isValid) {
      setIsSubmitButtonAvailable(false)

      return
    }

    setIsSubmitButtonAvailable(true)
  }, [isValid])

  /*
  LISTEN CHANGES OF CROP DRAFT
  */
  useEffect(() => {
    changeState(valuesForm)
  }, [valuesForm])

  useFocusEffect(
    useCallback(() => {
      const cropType = valuesForm?.cropType.key
      const unitType = valuesForm?.unitType.key
      const pay = valuesForm?.pay
      if (cropType && unitType && pay) {
        changeOutLierHarvest(pay, unitType, cropType)
      }
    }, [])
  )

  const onChangePayHarvest = async (value) => {
    try {
      const unit = values.unitType.key
      const pay = value
      const cropType = values.cropType.key

      if (unit && cropType && pay) {
        await changeOutLierHarvest(pay, unit, cropType)
      }
    } catch (error) {
      console.error(`Error:${error}`)
    }
  }

  /*
  LISTEN SURFACE FIELD
  */
  useEffect(() => {
    if (!valuesForm) {
      return
    }
    const substringForUnit = values?.unitType?.key.substring(
      values?.unitType?.key.indexOf('/') + 1
    )

    setFieldValue(
      'name',
      `${values?.cropType?.label || 'Tipo'} - ${
        values.surface || 0
      } ${substringForUnit} - ${formatDateMoment(
        valuesForm.dateCrop.value ?? valuesForm.dateCrop,
        'MMM/YYYY',
        i18n.locale,
        true
      )} ${t('VIEWS.ACTIVITIES.TEXT_62')} ${formatDateMoment(
        valuesForm.dateHarvest.value ?? valuesForm.dateHarvest,
        'MMM/YYYY',
        i18n.locale,
        true
      )}`
    )
  }, [values?.surface, valuesForm])

  /*
  BUILD ESTABLISHMENTS SELECTED
  */
  const buildEstablishmentsSelected = async () => {
    const establishmentsSelected = []
    establishments.map((element) => {
      if (element.disabled) return
      const newLots = element.lots.filter(
        (subElement) => !subElement.disabled && subElement.selected
      )

      if (!newLots.length) return

      let totalSurface1 = 0
      newLots.map((element) => (totalSurface1 += Number(element.surface)))
      establishmentsSelected.push({
        ...element,
        lots: newLots,
        totalSurface: truncateSurface(totalSurface1),
      })
    })
    setEstablishmentsSelected(establishmentsSelected)
  }

  /*
  CHANGE DATA OF CROP
  */
  const changeState = () => {
    if (!valuesForm) return null
    setFieldValue('name', valuesForm.name)
    setFieldValue('surface', valuesForm.surface)
  }

  /*
  CREATE NEW CROP
  */
  const createNewCrop = async () => {
    if (isSubmitButtonLoading) {
      return
    }

    setIsSubmitButtonLoading(true)

    const lots = []
    const reusableLots = []

    establishmentsSelected.map((element) => {
      if (element.file) {
        lots.push({
          tag: element.tag,
          names: element.lots.map(
            (subElement) =>
              `${subElement.name}${
                subElement.signature ? `_${subElement.signature}` : ''
              }`
          ),
        })
      } else {
        reusableLots.push({
          tag: element.tag,
          farmId: element?.farmId,
          lotIds: element.lots.map((subElement) => subElement._id),
        })
      }
    })

    const dataToCreate = {
      identifier: valuesForm.identifier,
      name: values.name,
      pay: values.pay,
      surface: values.surface,
      stage: valuesForm?.stage?.value || undefined,
      cropType: valuesForm.cropType.value,
      unitType: valuesForm.unitType.value,
      dateHarvest: valuesForm.dateHarvest.value,
      dateCrop: valuesForm.dateCrop.value,
      lots: lots.length ? lots : undefined,
      reusableLots: reusableLots.length ? reusableLots : undefined,
    }

    if (isCompanyUcropit && collaborators.length) {
      dataToCreate.collaborators = collaborators
    }

    let crop

    try {
      const { data } = await doRequest({
        method: 'POST',
        url: 'crops/create',
        data: dataToCreate,
        version: 'v2',
      })

      crop = data
    } catch (error) {
      setIsSubmitButtonLoading(false)

      alert('BAD REQUEST')

      return
    }

    if (crop) {
      setCurrentCrop(crop)

      if (!isCompanyUcropit) {
        const configResponse = await doRequest({
          method: 'PUT',
          url: `configurations/${config._id}`,
          data: {
            companySelected: valuesForm.companyExists,
          },
        })

        handleUser(configResponse.data)
      }

      setGoToViewOnFinish({
        name: 'CropList',
        params: {},
      })

      setDisplaySuccessModal(true)

      dispatch(
        draftCropActions.clearDraftCrop(config.companySelected?.identifier)
      )
    }

    setIsSubmitButtonLoading(false)
  }

  /*
  CLOSE SUCCESS MODAL AND GO TO HOME VIEW
  */
  const closeSuccessModal = () => {
    resetData()

    setDisplaySuccessModal(false)

    navigation.popToTop()

    navigation.navigate(goToViewOnFinish.name, goToViewOnFinish.params)
  }

  /*
  CLOSE SUCCESS MODAL AND GO TO CROP HISTORY VIEW
  */
  const goToViewHistoryCrop = () => {
    enterToCropStory({
      date: new Date(),
      fromView: 'CREATE_CROP',
      cropId: currentCrop._id,
      fieldId: '',
      userId: user._id,
      userRol: config?.roleSelected?.equivalentRole,
      userIsAdmin: config?.isAdmin,
      userIsResponsible: config?.isResponsible,
      os: Platform.OS,
    })

    resetData()

    setDisplaySuccessModal(false)

    navigation.popToTop()

    navigation.navigate('CropDetail', {
      id: currentCrop._id,
    })
  }

  const ExtraContent = () => (
    <View style={styles.containerExtra}>
      <Text style={[styles.successText, styles.successTitleText]}>
        {t('VIEWS').CROP_CREATE_CONFIRM.TEXT_14}
      </Text>
      <Text style={[styles.successText, styles.successSubText]}>
        {t('VIEWS').CROP_CREATE_CONFIRM.TEXT_15}
      </Text>

      <View style={styles.footer}>
        {/* <ButtonCustom
          onPress={goToViewHistoryCrop}
          styles={styles.buttonPrimary}
          labelStyle={styles.labelButtonPrimaryStyle}
        >
          {t('VIEWS').CROP_CREATE_CONFIRM.TEXT_11}
        </ButtonCustom> */}
        <ButtonCustom
          mode='outlined'
          onPress={closeSuccessModal}
          styles={styles.buttonOutlined}
          labelStyle={styles.labelButtonOutlined}
        >
          {t('VIEWS').CROP_CREATE_CONFIRM.TEXT_12}
        </ButtonCustom>
      </View>
    </View>
  )

  if (!valuesForm && !displaySuccessModal) {
    return (
      <ActivityIndicator
        size='large'
        color={Colors.green500}
        style={styles.loader}
      />
    )
  }

  if (displaySuccessModal) {
    return (
      <ModalSuccess
        visible={displaySuccessModal}
        title={''}
        description={''}
        style={styles.buttonContainer}
      >
        <ExtraContent />
      </ModalSuccess>
    )
  }

  return (
    <Layout>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        <CompanyDetails
          companyName={config?.companySelected?.name}
          companyIdentifier={config?.companySelected?.identifier}
          style={styles.companyDetails}
        />

        <FormikProvider value={formik}>
          {!currentUserIsAdminOfCompany && (
            <>
              <InputSelectDropdown
                id='country'
                containerStyle={styles.containerInput}
                rightIcon={
                  <CommonIcon
                    name={'CHEVRON-DOWN'}
                    size={20}
                    color={buttonDisabled}
                  />
                }
                onChangeText={(value) => {
                  setFieldValue('country', value)
                }}
                onBlur={() => setTimeout(() => setFieldTouched('country'))}
                value={values.country}
                label={t('VIEWS').CROP_CREATE.FIELD_8.NAME}
                placeholder={t('VIEWS').CROP_CREATE.FIELD_8.NAME}
                options={optionsCountries}
                touched={touched.country}
                error={errors?.country?.value}
                disabled={true}
                editable={false}
              />

              <InputText
                id='identifier'
                value={values.identifier}
                label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_5.NAME}
                placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_5.NAME}
                editable={false}
                disabled={true}
                helpText={t('VIEWS').CROP_CREATE_CONFIRM.TEXT_2}
              />
            </>
          )}

          <InputSelectDropdown
            id='cropType'
            containerStyle={styles.containerInput}
            rightIcon={
              <CommonIcon
                name={'CHEVRON-DOWN'}
                size={20}
                color={buttonDisabled}
              />
            }
            onChangeText={(value) => {
              setFieldValue('cropType', value)
            }}
            value={values.cropType}
            label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_1.NAME}
            placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_1.NAME}
            options={cropTypes}
            touched={touched.cropType}
            error={errors.cropType}
            disabled
            editable={false}
          />

          <InputSelectDropdown
            id='stage'
            containerStyle={styles.containerInput}
            rightIcon={
              <CommonIcon
                name={'CHEVRON-DOWN'}
                size={20}
                color={buttonDisabled}
              />
            }
            onChangeText={(value) => {
              setFieldValue('stage', value)
            }}
            value={values.stage}
            label={t('VIEWS').CROP_CREATE.STAGE_FIELD.NAME}
            placeholder={t('VIEWS').CROP_CREATE.STAGE_FIELD.NAME}
            options={stages}
            touched={touched.stage}
            error={errors.stage}
            disabled
            editable={false}
          />

          <View style={styles.date}>
            <View style={styles.dateItem}>
              <InputText
                id='dateCrop'
                value={formatDateMoment(
                  valuesForm.dateCrop.value ?? valuesForm.dateCrop,
                  'DD/MMM/YYYY',
                  i18n.locale,
                  true
                )}
                label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_2.NAME}
                placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_2.NAME}
                editable={false}
                disabled={true}
                rightIcon={
                  <CommonIcon
                    name={'CALENDAR-DATE'}
                    size={20}
                    color={buttonDisabled}
                  />
                }
              />
            </View>

            <View style={styles.dateItem}>
              <InputText
                id='dateHarvest'
                value={formatDateMoment(
                  valuesForm.dateHarvest.value ?? valuesForm.dateHarvest,
                  'DD/MMM/YYYY',
                  i18n.locale,
                  true
                )}
                label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_3.NAME}
                placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_3.NAME}
                editable={false}
                disabled={true}
                rightIcon={
                  <CommonIcon
                    name={'CALENDAR-DATE'}
                    size={20}
                    color={buttonDisabled}
                  />
                }
              />
            </View>
          </View>

          <View style={styles.date}>
            <View style={styles.dateItem}>
              <InputSelectDropdown
                id='unitType'
                rightIcon={
                  <CommonIcon
                    name={'CHEVRON-DOWN'}
                    size={20}
                    color={buttonDisabled}
                  />
                }
                onChangeText={(value) => {
                  setFieldValue('unitType', value)
                }}
                value={values.unitType}
                label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_8.NAME}
                placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_8.NAME}
                options={unitTypes}
                touched={touched.unitType}
                error={errors.unitType}
                disabled
                editable={false}
              />
            </View>

            <View style={styles.dateItem}>
              <InputText
                id='pay'
                keyboardType='decimal-pad'
                value={values?.pay?.toString()}
                label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_4.NAME}
                placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_4.NAME}
                touched={touched.pay}
                error={errors.pay}
                onChangeText={(value) => {
                  setFieldValue('pay', value)
                  onChangePayHarvest(value)
                }}
                onBlur={() => setFieldTouched('pay')}
              />
            </View>
          </View>

          {canShowOutlierHarvest && isValueOutsideMeanHarvest && (
            <View style={styles.outlierContainer}>
              <Outlier
                title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
                icon={outlierIcons.InfoFill}
                description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
                type={statusOutlier}
                style={styles.outlier}
              />
            </View>
          )}

          <InputText
            id='surface'
            keyboardType='decimal-pad'
            value={values?.surface?.toString()}
            label={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_7.NAME}
            placeholder={t('VIEWS').CROP_CREATE_CONFIRM.FIELD_7.NAME}
            touched={touched.surface}
            error={errors.surface}
            onChangeText={(value) =>
              getDecimalsQuantity(value) <= 4
                ? setFieldValue('surface', value)
                : false
            }
            onBlur={() => setFieldTouched('surface')}
          />

          <InputText
            id='name'
            value={values.name}
            label={t('VIEWS.CROP_CREATE_CONFIRM.FIELD_6.NAME')}
            placeholder={t('VIEWS.CROP_CREATE_CONFIRM.FIELD_6.NAME')}
            touched={touched.name}
            error={errors.name}
            onChangeText={(value) => setFieldValue('name', value)}
            onBlur={() => setFieldTouched('name')}
          />

          <View>
            {establishmentsSelected.map((item, key) => (
              <View style={styles.contextEstablishment} key={key}>
                <Text style={styles.titleEstablishment}>
                  {item.tag} - {item.lots.length}{' '}
                  {t('VIEWS').CROP_CREATE_CONFIRM.TEXT_5} - {item.totalSurface}{' '}
                  {values?.unitType?.key.substring(
                    values?.unitType?.key.indexOf('/') + 1
                  )}
                </Text>
                <Divider />
              </View>
            ))}
          </View>
        </FormikProvider>
      </ScrollView>

      <ButtonCustom
        disabled={!isSubmitButtonAvailable}
        isLoading={isSubmitButtonLoading}
        onPress={createNewCrop}
        styles={[
          styles.button,
          !isSubmitButtonAvailable ? styles.buttonDisabled : {},
        ]}
        labelStyle={[
          styles.textButton,
          !isSubmitButtonAvailable ? styles.textButtonDisabled : {},
        ]}
      >
        {t('VIEWS.CROP_CREATE_CONFIRM.TEXT_7')}
      </ButtonCustom>
    </Layout>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    padding: 16,
  },
  companyDetails: {
    margin: 0,
    marginBottom: 16,
  },
  date: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  dateItem: {
    flexBasis: '49%',
  },
  contentButton: {
    marginTop: 14,
    paddingVertical: 6,
  },
  contentButtonDisabled: {
    backgroundColor: '#81C784',
    marginTop: 14,
    paddingVertical: 6,
  },
  textCaption: {
    marginLeft: 10,
    marginBottom: 4,
  },
  textCaptionCompany: {
    fontSize: 12,
    color: 'gray',
    marginBottom: 10,
  },
  marginInput: {
    marginBottom: 6,
  },
  contextEstablishment: {
    marginVertical: 4,
    paddingVertical: 6,
    paddingHorizontal: 10,
  },
  titleEstablishment: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
    paddingVertical: 12,
  },
  input: {
    backgroundColor: '#FFF',
  },
  inputWithMargin: {
    backgroundColor: '#FFF',
    marginBottom: 23,
  },
  inputCropName: {
    backgroundColor: '#FFF',
    marginBottom: 23,
  },
  containerExtra: {
    flex: 1,
  },
  textTitleExtra: {
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: -0.5,
    paddingVertical: 20,
  },
  buttonPrimary: {
    backgroundColor: 'white',
    marginBottom: 20,
  },
  labelButtonPrimaryStyle: {
    color: '#4CAF50',
  },
  buttonOutlined: {
    borderColor: 'white',
    width: '100%',
  },
  labelButtonOutlined: {
    color: 'white',
  },
  successTitleText: {
    fontSize: 34,
    fontWeight: '600',
    lineHeight: 40,
    letterSpacing: -0.5,
    marginBottom: 30,
    marginTop: 70,
  },
  successText: {
    color: white,
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  successSubText: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 21,
    letterSpacing: -0.5,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 32,
    paddingBottom: 5,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: 171,
    height: 171,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 100,
    backgroundColor: 'rgba(76, 175, 80, .5)',
  },
  button: {
    backgroundColor: primary500,
    margin: 16,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
  outlier: {},
  outlierContainer: {
    marginHorizontal: 33,
    marginBottom: 45,
  },
})

CropCreateConfirm.propTypes = {
  navigation: PropTypes.object,
}

export default CropCreateConfirm
