import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import { Title } from 'react-native-paper'

import { ActivityIcon } from '@modules/common/components'
import { activityIcons } from '../../utils'

export const ActivityCardHeader = ({ name, activityType }) => {
  return (
    <View style={styles.header}>
      <ActivityIcon
        name={activityIcons[activityType]}
        size={28}
        style={styles.icon}
      />

      <Title>{name}</Title>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 20,
  },
  icon: {
    marginRight: 20,
  },
})

ActivityCardHeader.prototype = {
  name: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
}
