import React, { useCallback, useContext, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Subheading, Button, List, Divider } from 'react-native-paper'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { AuthContext } from '@contextState/auth'
import { ConnectionContext } from '@contextState/connection'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'

function IntegrationQueueScreen({ route, navigation }) {
  const { isConnected } = useContext(ConnectionContext)
  const { doRequest, loading } = useNetwork()
  const { user, config } = useContext(AuthContext)
  const [crop, setCrop] = useState(null)
  const [isSyc, setIsSync] = useState(false)
  const [serviceName, setServicesName] = useState('')
  const [updating, setUpdating] = useState(false)
  const [logs, setLogs] = useState({})
  const [msg, setMsg] = useState(false)
  const { t } = useContext(LanguageContext)

  const fetchData = useCallback(async (id) => {
    if (isConnected) {
      const response = await doRequest({ method: 'GET', url: `crops/${id}` })
      const responseCompany = await doRequest({
        method: 'GET',
        url: `companies/${config.companySelected._id}`,
      })
      responseCompany.data?.servicesIntegrations.map((item) => {
        const service = response.data.synchronizedList
          ? response.data.synchronizedList.find(
              (el) => el.service === item.service
            )
          : null
        if (service) {
          service.isSynchronized
            ? (setIsSync(true), setUpdating(true))
            : (setIsSync(false), setUpdating(false))

          return {
            service: service.service,
            isSynchronized: service.isSynchronized,
          }
        }
        if (!service) {
          setUpdating(false)
        }

        return {
          service: item.service,
          isSynchronized: false,
        }
      })

      setCrop(response.data)

      response.data.members.find((el) => el.user._id === user._id)
    } else {
      /*
      const cropOffline = await getOne('OFFLINE_CROPS', id)
      me = cropOffline.members.find((el) => el.user._id === user._id)
      */
    }
  }, [])

  function syncIconChange(sync, logs) {
    if (sync && logs && logs?.log?.fullyProcessed) {
      return <CommonIcon name={'SYNC'} color='green' size={20} />
    } else if (
      (sync && logs && !logs?.log?.fullyProcessed) ||
      logs?.log === ''
    ) {
      return <CommonIcon name={'SYNC-ALERT'} color='red' size={20} />
    } else {
      return <CommonIcon name={'SYNC-OFF'} size={20} />
    }
  }

  function syncMessageLog(isSyc, logSync, msg) {
    if (logSync?.log && logSync?.log !== '') {
      return (
        <Text
          style={styles.textLog}
        >{`${logSync?.log.message}: ${logs?.dateFormat}`}</Text>
      )
    } else if (msg && !isSyc) {
      return (
        <Text style={styles.textLog}>
          {t('VIEWS').INTEGRATION_QUEUE.TEXT_1}
        </Text>
      )
    } else if (!isSyc) {
      return (
        <Text style={styles.textLog}>
          {t('VIEWS').INTEGRATION_QUEUE.TEXT_2}
        </Text>
      )
    }
  }

  async function synchronizedService(service) {
    const data = {
      erpAgent: service,
      crops: [
        {
          id: crop._id,
        },
      ],
    }

    await doRequest({
      method: 'POST',
      url: `exporters/crops`,
      data: data,
    })

    await fetchData(crop._id)
    await fetchLogs(crop._id, service)

    setMsg(true)
  }

  const fetchLogs = useCallback(async (id, service) => {
    const response = await doRequest({
      method: 'GET',
      url: `exporters/logs/${id}/${service}`,
    })
    setLogs(response.data[0])
  }, [])

  React.useEffect(() => {
    const { id } = route.params
    setServicesName(route.params.service)
    const unsubscribe = navigation.addListener('focus', async () => {
      await fetchData(id)
      await fetchLogs(route.params.id, route.params.service)
    })

    return unsubscribe
  }, [navigation])

  return (
    <View style={styles.container}>
      <Subheading style={styles.title}>
        {t('VIEWS').INTEGRATION_QUEUE.TEXT_3}
      </Subheading>
      <Divider />
      <View style={{ flex: 1 }}>
        <React.Fragment>
          <List.Item
            description={() => syncMessageLog(isSyc, logs, msg)}
            title={serviceName}
            right={() => <List.Icon icon={() => syncIconChange(isSyc, logs)} />}
            subtitle={t('VIEWS').INTEGRATION_QUEUE.TEXT_4}
          />
          <Divider />
        </React.Fragment>
      </View>
      <View>
        <Button
          loading={loading}
          onPress={() => {
            setUpdating(true)
            synchronizedService(serviceName)
          }}
          disabled={updating}
          style={styles.button}
        >
          {!isSyc
            ? t('VIEWS').INTEGRATION_QUEUE.TEXT_5
            : t('VIEWS').INTEGRATION_QUEUE.TEXT_6}
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  title: {
    marginLeft: 10,
  },
  button: {
    position: 'relative',
    bottom: 0,
    margin: 16,
  },
})

IntegrationQueueScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default IntegrationQueueScreen
