import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { Chip } from 'react-native-elements'

import { CommonIcon } from '@modules/common/components'
import InputText from '@components/inputs/InputText'
import {
  primary500,
  grayDA,
  gray7,
  blackMediumEmphasis,
  primary50,
  grayBackground,
  primary700,
} from '@styles/palette'

export const InputMultiSelect = ({
  id,
  name,
  label,
  placeholder,
  value,
  data = [],
  dataSelected = [],
  touched,
  disabled,
  multiline,
  keyboardType,
  inputStyle,
  onChangeText,
  onBlur,
  onfocus,
  onSelected,
  onRemoved,
  contentStyle = {},
  contentDataStyle = {},
  itemStyle = {},
  emptyStateComponent,
  showEmptyState,
}) => {
  return (
    <View style={[styles.container, contentStyle]}>
      <InputText
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChangeText={onChangeText}
        onBlur={onBlur}
        onfocus={onfocus}
        disabled={disabled}
        touched={touched}
        multiline={multiline}
        keyboardType={keyboardType}
        inputStyle={inputStyle}
        inputContainerStyle={styles.inputContainerStyle}
        leftIcon={
          <CommonIcon name='SEARCH' size={25} style={styles.iconInputSearch} />
        }
      />

      <View style={{ marginTop: dataSelected.length ? -25 : -22 }}>
        {Boolean(dataSelected?.length) && (
          <View style={[styles.contentChip]}>
            {dataSelected.map((item, key) => {
              return (
                <Chip
                  key={`SELECTED_FARM_${key}`}
                  title={`${item.name}`}
                  containerStyle={styles.chipContainerStyle}
                  buttonStyle={styles.chipStyle}
                  titleStyle={styles.chipText}
                  titleProps={{ numberOfLines: 1, ellipsizeMode: 'tail' }}
                  icon={
                    <CommonIcon
                      name='X-CIRCLE'
                      size={20}
                      color={primary500}
                      style={styles.chipIcon}
                      onPress={() => onRemoved(item)}
                    />
                  }
                  iconRight
                />
              )
            })}
          </View>
        )}

        {Boolean(data && data.length) && (
          <ScrollView style={[styles.contentData, contentDataStyle]}>
            {data.map((item, key) => {
              return (
                <View
                  key={`INPUT_MULTISELECT_DATA_${key}`}
                  style={[styles.item, itemStyle]}
                >
                  <Text
                    style={styles.itemText}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    {item.name}
                  </Text>

                  <CommonIcon
                    name={
                      item.selected
                        ? 'CHECKBOX-SELECTED'
                        : 'CHECKBOX-UNSELECTED'
                    }
                    size={24}
                    style={
                      item.selected ? styles.checkBoxSelected : styles.checkBox
                    }
                    onPress={() =>
                      item.selected ? onRemoved(item) : onSelected(item)
                    }
                  />
                </View>
              )
            })}
          </ScrollView>
        )}

        {showEmptyState && emptyStateComponent()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: grayBackground,
  },
  iconInputSearch: {
    marginRight: -10,
    marginLeft: 19,
  },
  contentChip: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 10,
    paddingLeft: 5,
    borderLeftWidth: 0.3,
    borderRightWidth: 0.3,
    borderColor: '#AAA',
  },
  chipContainerStyle: {
    maxWidth: '100%',
  },
  chipStyle: {
    backgroundColor: primary50,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginHorizontal: 2,
    marginBottom: 5,
  },
  chipText: {
    color: primary700,
    fontSize: 14,
    letterSpacing: 1.5,
    alignSelf: 'center',
    fontWeight: '400',
    paddingLeft: 10,
    maxWidth: '96%',
  },
  chipIcon: {
    marginLeft: 5,
    paddingVertical: 0,
  },
  contentData: {
    borderWidth: 1,
    borderColor: blackMediumEmphasis,
    maxHeight: 65 * 5,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 18,
    marginHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: grayDA,
  },
  itemText: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackMediumEmphasis,
  },
  checkBox: {
    fontSize: 25,
    color: gray7,
  },
  checkBoxSelected: {
    fontSize: 25,
    color: primary500,
  },
})

InputMultiSelect.prototype = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array,
  dataSelected: PropTypes.array,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onfocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelected: PropTypes.func,
  onRemoved: PropTypes.func,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  touched: PropTypes.bool,
  keyboardType: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  contentStyle: PropTypes.object,
  contentDataStyle: PropTypes.object,
  itemStyle: PropTypes.object,
  emptyState: PropTypes.node,
}
