import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import { View, Text, StyleSheet, FlatList } from 'react-native'
import { Divider } from 'react-native-paper'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  grayBackground,
} from '@styles/palette'
import TitleIcon from './TitleIcon'
import ItemDownload from '@components/common/v1/ItemDownload'
import useActionSheetCustom from '@hooks/useActionSheetCustom'
import { gray12 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { downloadFile } from '@utils/files'
import { ActivityIcon } from '@common/components'

const SectionVerified = ({ t, activities }) => {
  const { i18n } = useContext(LanguageContext)
  const [indexSelected, setIndexSelected] = useState(null)
  const { ActionSheetComponent, openActionSheet } = useActionSheetCustom({
    t,
    title: t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_8,
  })

  const openAction = async (index) => {
    await setIndexSelected(index)
    openActionSheet()
  }

  const arrayEvidences =
    indexSelected !== null
      ? [
          {
            ...activities[indexSelected]?.verificationFile,
            verificationFile: true,
          },
          ...activities[indexSelected].evidenceImages,
          ...activities[indexSelected].evidencesPdf,
        ]
      : []
  if (
    Boolean(arrayEvidences.length) &&
    !activities[indexSelected]?.verificationFile
  ) {
    arrayEvidences.splice(0, 1)
  }

  const getVerifierCompanyIcon = (activity) => {
    if (
      !activity?.verificationType ||
      !activity?.verificationType?.comapnies?.length
    ) {
      return ''
    }

    return (
      activity.verificationType.companies.find(
        (company) => company.identifier === activity.company.identifier
      )?.image ??
      activity.company[0]?.image ??
      ''
    )
  }

  return (
    <View>
      <TitleIcon
        text={t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_8}
        Icon={() => <ActivityIcon name={'ACT_VERIFICATION'} size={24} />}
      />
      {Boolean(activities.length) && (
        <View style={styles.content}>
          <FlatList
            data={activities}
            renderItem={({ index, item }) => (
              <>
                <Text style={styles.title}>{`${
                  t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_9
                }: ${_.startCase(_.toLower(item.company.name))}`}</Text>
                <Text style={styles.title}>{`${
                  t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_10
                }: ${t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_16}`}</Text>
                <ItemDownload
                  title={item.verificationType.name[i18n.locale]}
                  subtitle={`${
                    t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_11
                  }: ${moment(item.verificationType.createdAt).format(
                    'DD/MM/YY'
                  )}`}
                  icon={{
                    uri: getVerifierCompanyIcon(item),
                  }}
                  iconProps={{ size: 50 }}
                  iconStyle={styles.icon}
                  onPress={() => openAction(index)}
                />
              </>
            )}
            keyExtractor={(item) => `${item._id}`}
            ItemSeparatorComponent={() => <Divider style={styles.divider} />}
          />
        </View>
      )}
      <ActionSheetComponent>
        <FlatList
          data={arrayEvidences}
          renderItem={({ index, item }) => (
            <ItemDownload
              title={
                item.verificationFile
                  ? `${t('COMPONENTS').BINNACLE.CARD_CROP_DETAIL.TEXT_17}`
                  : `${t('COMPONENTS.BINNACLE.CARD_CROP_DETAIL.TEXT_18', {
                      evidenceNumber: arrayEvidences.find(
                        (evidence) => evidence.verificationFile
                      )
                        ? index
                        : index + 1,
                    })}`
              }
              subtitle={
                item.verificationFile
                  ? activities[indexSelected].verificationType.name[i18n.locale]
                  : !item?.evidenceConcept
                  ? item?.description
                  : item?.evidenceConcept?.name
              }
              description={item?.nameFile}
              titleStyle={styles.titleItem}
              onPress={async () => {
                downloadFile(item.path)
              }}
            />
          )}
          keyExtractor={(item, index) => `${index}_${item.nameFile}`}
          ItemSeparatorComponent={() => <Divider style={styles.divider} />}
        />
      </ActionSheetComponent>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: grayBackground,
    paddingHorizontal: 16,
    paddingTop: 5,
  },
  title: { fontSize: 16, color: blackHighEmphasis },
  dataCompany: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconCompany: { width: 80, height: 80 },
  nameActivity: {
    fontSize: 16,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  date: {
    fontSize: 14,
    color: blackMediumEmphasis,
  },
  divider: {
    height: 0.5,
    backgroundColor: gray12,
    marginBottom: 16,
  },
  icon: { marginRight: 8 },
})

SectionVerified.propTypes = {
  t: PropTypes.func.isRequired,
  cropActivities: PropTypes.array,
}

export default SectionVerified
