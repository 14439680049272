import React, { useContext } from 'react'
import { StyleSheet, FlatList, ImageBackground, View } from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'
import { roundSurface } from '@utils'
import { LanguageContext } from '@contextState/language'

function CardCropBadgesDetail({ badges, areaUnitCrop = '' }) {
  const { i18n } = useContext(LanguageContext)
  function renderItem({ item }) {
    return (
      <View style={styles.badgesContainer}>
        {item.badge.imagePath ? (
          <ImageBackground
            source={{
              uri: item.badge.imagePath,
            }}
            style={styles.imageContainer}
          />
        ) : null}

        <View style={styles.textsContainer}>
          <Text style={styles.title}>
            {item?.badge?.translatedName ?? item?.badge?.name[i18n.locale]}
          </Text>

          {item.surfaceTotal ? (
            <Text style={styles.subtitle}>
              {`${roundSurface(item.surfaceTotal ?? 0)} ${areaUnitCrop}`}
            </Text>
          ) : null}
        </View>
      </View>
    )
  }

  return (
    <FlatList
      data={badges}
      renderItem={renderItem}
      keyExtractor={(item) => item._id}
      horizontal={true}
    />
  )
}

const styles = StyleSheet.create({
  badgesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 220,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  imageContainer: {
    height: 70,
    width: 70,
    resizeMode: 'contain',
  },
  textsContainer: {
    width: 120,
    marginLeft: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: '500',
    color: '#4CAF50',
  },
  subtitle: {
    fontSize: 12,
    color: '#4CAF50',
  },
})

CardCropBadgesDetail.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      badge: PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.shape({
          en: PropTypes.string,
          es: PropTypes.string,
          pt: PropTypes.string,
        }),
      }),
      surfaceTotal: PropTypes.number,
    })
  ),
}

export default CardCropBadgesDetail
