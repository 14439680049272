const { Platform } = require('react-native')

switch (Platform.OS) {
  case 'web':
    require('react-native').YellowBox.ignoreWarnings([
      'Setting a timer',
      'EventEmitter.removeListener',
    ])
    break
  case 'android':
    require('react-native').LogBox.ignoreLogs([
      'Setting a timer',
      'EventEmitter.removeListener',
    ])
    break
  case 'ios':
    // eslint-disable-next-line no-console
    console.log(Platform.OS, 'YellowBox and LogBox not supported')
}
