import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import InputCheckbox from '@components/inputs/InputCheckbox'
import { blackDisabled } from '@styles/palette'

const SelectAll = ({ selectedAll, selectAndUnselectAll, disabledAll, t }) => {
  const isDisabled = disabledAll ? styles.colorDisabled : styles.colorText
  return (
    <View style={styles.containerAll}>
      <InputCheckbox
        checked={selectedAll}
        onPress={selectAndUnselectAll}
        disabled={disabledAll}
      />
      <Text style={[styles.textAll, isDisabled]}>
        {t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_8')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  containerAll: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 21,
    marginVertical: 5,
  },
  textAll: {
    fontWeight: 'bold',
    fontSize: 16,
    marginLeft: 5,
  },
  colorDisabled: {
    color: blackDisabled,
  },
  colorText: {
    color: '#000',
  },
})

SelectAll.propTypes = {
  selectedAll: PropTypes.bool.isRequired,
  selectAndUnselectAll: PropTypes.func,
}

export default SelectAll
