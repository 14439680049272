import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import CardDefault from '@components/cards/CardDefault'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  secondary700,
  orange100,
} from '@styles/palette'
import ChipCommon from '@common/components/chips/ChipCommon'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'

const CardCollaborator = ({
  collaborator,
  t,
  hasPermissionAuthorizeCollaborator,
  isCompanyProducer,
  companyName,
  roleName,
}) => {
  const isPendingAuthorization =
    collaborator.collaboratorRequest?.status ===
    COLLABORATORS_REQUEST_STATUS.PENDING
  const name = collaborator?.firstName
    ? `${collaborator?.firstName} ${collaborator?.lastName}`
    : collaborator.email
  return (
    <CardDefault>
      <View style={styles.contentCard}>
        {isCompanyProducer && isPendingAuthorization && (
          <View style={styles.contentOptions}>
            <ChipCommon
              text={t(
                'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.CARD_COLLABORATOR.PENDING_AUTHORIZATION'
              )}
              backgroundColor={orange100}
              textColor={secondary700}
            />
            {hasPermissionAuthorizeCollaborator && (
              <CommonIcon
                name='DOTS-VERTICAL'
                color={blackMediumEmphasis}
                size={24}
              />
            )}
          </View>
        )}
        <View style={styles.contentTitle}>
          <Text style={styles.textTitle}>{name}</Text>
        </View>
        <Text style={styles.textContent}>{roleName}</Text>
        <Text style={styles.textContent}>
          {collaborator?.companyName || companyName}
        </Text>
      </View>
    </CardDefault>
  )
}

CardCollaborator.propTypes = {
  collaborator: PropTypes.object,
  t: PropTypes.func,
  hasPermissionAuthorizeCollaborator: PropTypes.bool,
}

const styles = StyleSheet.create({
  contentCard: {
    padding: 10,
    paddingHorizontal: 15,
  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  textTitle: {
    fontSize: 16,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  textContent: {
    color: blackMediumEmphasis,
  },
  contentOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collaboratorContact: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  space: {
    width: 30,
  },
})

export default CardCollaborator
