/////////////////// WHITE ///////////////////
export const white = '#FFFFFF'

/////////////////// BLACK ///////////////////
export const black = '#000000'

/////////////////// GRAY ///////////////////
export const gray25 = '#FCFDFD'
export const gray50 = '#FAFAFB'
export const gray80 = '#C9C5CA'
export const gray100 = '#F3F4F7'
export const gray200 = '#EAECF0'
export const gray300 = '#D1D5DD'
export const gray400 = '#9AA1B2'
export const gray500 = '#687083'
export const gray600 = '#495365'
export const gray700 = '#373F52'
export const gray800 = '#1F2938'
export const gray900 = '#111827'

/////////////////// GREEN ///////////////////
export const green25 = '#F6FBF6'
export const green50 = '#EDF7ED'
export const green100 = '#DBEFDC'
export const green200 = '#B7DFB9'
export const green300 = '#94CF96'
export const green400 = '#70BF73'
export const green500 = '#4CAF50'
export const green600 = '#3D8C40'
export const green700 = '#2E6930'
export const green800 = '#1E4620'
export const green900 = '#0F2310'

/////////////////// RED ///////////////////
export const red50 = '#FEF3F2'
export const red100 = '#FEE4E2'
export const red200 = '#FECDCA'
export const red300 = '#FDA29B'
export const red400 = '#F97066'
export const red500 = '#F04438'
export const red600 = '#D92D20'
export const red700 = '#B42318'
export const red800 = '#93370D'
export const red900 = '#7A2E0E'

/////////////////// YELLOW ///////////////////
export const yellow100 = '#FEFAEC'
export const yellow200 = '#FCF1CB'
export const yellow300 = '#F9E094'
export const yellow400 = '#F6CA62'
export const yellow500 = '#FDB022'
export const yellow600 = '#E99537'
export const yellow700 = '#CD6F2A'
export const yellow800 = '#A84E20'
export const yellow900 = '#883D1C'
