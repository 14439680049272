import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'
import { Card, Text } from 'react-native-paper'

import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  white,
  yellow500,
} from '@styles/palette'

export const AuthorizationCard = ({ companyName, username }) => {
  const { t } = useContext(LanguageContext)

  return (
    <Card style={styles.container}>
      <Card.Title
        style={styles.stylesCard}
        leftStyle={styles.leftStyle}
        title={t(
          'VIEWS.CROP_LIST.COMPONENTS.AUTHORIZATION_CARD.PENDING_AUTHORIZATION'
        )}
        titleStyle={styles.title}
        left={() => <CommonIcon name={'INFO'} size={24} color={yellow500} />}
      />

      <Card.Content style={styles.contentMessage}>
        <Text style={styles.stylesSubTitleCard}>
          {t('VIEWS.CROP_LIST.COMPONENTS.AUTHORIZATION_CARD.PARRAGRAPH', {
            name: username,
            company: companyName,
          })}
        </Text>
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    alignItems: 'flex-start',
    backgroundColor: white,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  stylesCard: {
    alignItems: 'flex-start',
    minHeight: 35,
  },
  leftStyle: {
    marginRight: 0,
  },
  title: {
    fontWeight: '700',
    fontSize: 16,
    letterSpacing: 0.15,
    color: blackHighEmphasis,
  },
  orange: {
    color: yellow500,
  },
  contentMessage: {
    paddingLeft: 55,
  },
  stylesSubTitleCard: {
    marginTop: -5,

    fontWeight: '400',
    fontSize: 12,
    letterSpacing: 0.15,
    color: blackMediumEmphasis,
    textAlign: 'justify',
  },
})

AuthorizationCard.propTypes = {
  companyName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}
