import { FC, ReactElement } from 'react'
import { StyleSheet } from 'react-native'
import { FAB as Fab } from 'react-native-paper'

import { blackHighEmphasis, primary500 } from '@styles/palette'
import { IFabButton } from './interfaces'
import { CommonIcon } from '@modules/common/components'

const FAB = {
  COLOR: 'white',
  THEME: {
    colors: {
      text: blackHighEmphasis,
    },
    dark: true,
  },
}

export const FabButton: FC<IFabButton> = ({
  isFabOptionsOpened,
  onPressFabButton,
  fabActions,
}): ReactElement => {
  return (
    <Fab.Group
      visible={true}
      open={isFabOptionsOpened}
      color={FAB.COLOR}
      icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
      actions={fabActions}
      onStateChange={onPressFabButton}
      fabStyle={styles.buttonFab}
      theme={FAB.THEME}
    />
  )
}

const styles = StyleSheet.create({
  buttonFab: {
    backgroundColor: primary500,
  },
})
