import React, { useState, useEffect, useContext, useCallback } from 'react'
import { StyleSheet, ScrollView, View, Image } from 'react-native'
import { Button, List, TextInput, Text } from 'react-native-paper'
import { uniqueId } from 'lodash'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import useNetwork from '@utils/network'
import { EstablishmentContext } from '@contextState/establishment'
import UploadInput from '@components/common/UploadInput'
import { LanguageContext } from '@contextState/language'
import MessageToast from '@components/common/v1/MessageToast'
import toastTypes from '@constants/toastTypes'
import {
  black,
  blackHighEmphasis,
  grayBackground,
  onPressedColor,
  primary500,
  primary500Disabled,
  secondary500,
  white,
} from '@styles/palette'
import { roundSurface } from '@utils'

const KMZ_MIME = '.kmz'

const KmzAdd = ({ route, navigation }) => {
  const { addKmzEstablishments } = useContext(EstablishmentContext)
  const [establishments, setEstablishments] = useState([])
  const { doRequest } = useNetwork()

  const { t } = useContext(LanguageContext)

  /*
  INIT FUNCTIONS
  */
  useEffect(() => {
    addInitialEstablishment()
  }, [route.params])

  /*
  ADD INITIAL DATA TO ESTABLISHMENTS
  */
  const addInitialEstablishment = () => {
    const establishmentInRoute = {
      ...route.params.establishment,
      display: true,
      selected: !route.params.establishment.isAllDisabled,
      lots: route.params.establishment.lots.map((lotElement) =>
        mapLotMarkSelected(lotElement, !lotElement.disabled)
      ),
    }
    setEstablishments([...establishments, establishmentInRoute])
  }

  /*
  SELECT/UNSELECT ESTABLISHMENT
  */
  const selectEstablishmentToggle = async (establishment) => {
    const allEstablishmentSelected = []

    const newEstablishments = establishments.map((element) => {
      if (element._id != establishment._id) {
        element.selected ? allEstablishmentSelected.push(element) : null

        return element
      }

      !element.selected ? allEstablishmentSelected.push(element) : null

      const newLots = element.lots.map((lotElement) =>
        mapLotMarkSelected(lotElement, !element.selected)
      )

      return {
        ...element,
        selected: !element.selected,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  /*
   SELECT/UNSELECT LOT
  */
  const mapLotMarkSelected = (lotElement, selected) => {
    return {
      ...lotElement,
      selected,
    }
  }

  /*
  SELECT/UNSELECT LOT
  */
  const selectLotToggle = async (establishment, lot) => {
    const newEstablishments = establishments.map((element) => {
      if (element._id != establishment._id) return element

      const allLotSelected = []

      const newLots = element.lots.map((lotElement) => {
        if (lotElement._id != lot._id) {
          lotElement.selected ? allLotSelected.push(lotElement) : null

          return lotElement
        }

        !lotElement.selected ? allLotSelected.push(lotElement) : null

        return {
          ...lotElement,
          selected: !lotElement.selected,
        }
      })

      return {
        ...element,
        selected: allLotSelected.length == newLots.length,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  /*
  DISPLAY/UNDISPLAY ESTABLISHMENT
  */
  const displayEstablishmentToggle = async (establishment) => {
    const newEstablishments = establishments.map((element) => {
      if (element._id != establishment._id) return element
      return {
        ...element,
        display: !element.display,
      }
    })

    setEstablishments(newEstablishments)
  }

  /*
  REMOVE ESTABLISHMENT
  */
  const deleteEstablishment = (indexSelected) => {
    const newEstablishments = [...establishments]

    newEstablishments.splice(indexSelected, 1)

    setEstablishments(newEstablishments)
  }

  /*
  UPDATE TEXT VALUE OF TAG INPUT
  */
  const setTag = useCallback(
    (establishment, value) => {
      const newEstablishments = establishments.map((element) => {
        if (element._id != establishment._id) return element
        return {
          ...element,
          tag: value,
        }
      })

      setEstablishments(newEstablishments)
    },
    [establishments]
  )

  /*
  CUSTOM HANDLEFILE FOR ADDING MORE KMZ TO ESTABLISHMENTS
  */
  const handleFile = async ({ file }) => {
    const formData = new FormData()

    formData.append('files', file)

    const response = await doRequest({
      method: 'POST',
      url: 'establishments/parse-files',
      data: formData,
    })

    if (response) {
      try {
        setEstablishments([
          {
            ...response.data,
            _id: uniqueId('ESTABLISHMENT_'),
            tag: '',
            display: true,
            selected: !response.data.isAllDisabled,
            file,
            lots: response.data.lots.map((lot) => {
              return {
                ...lot,
                _id: uniqueId('LOT_'),
                selected: !lot.disabled,
              }
            }),
          },
          ...establishments,
        ])
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }

  /*
  ADD NEWS KMZ ESTABLISHMENTS SELECTED TO CONTEXT
  */
  const uploadSelectedLots = () => {
    if (!validateEstablishments()) {
      return
    }

    const establishmentsToSend = []

    establishments.map((element) => {
      const lotsSelected = element.lots.filter(
        (lotElement) => lotElement.selected
      )

      if (!lotsSelected.length) return

      establishmentsToSend.push({
        ...element,
        lots: lotsSelected,
        display: false,
      })
    })

    if (!establishmentsToSend.length) return

    addKmzEstablishments(establishmentsToSend)

    navigation.navigate('LotsAdd', {
      isAddLot: true,
      identifier: route?.params?.identifier,
    })
  }

  /*
  VALIDATE IF ANY ESTABLISHMENT IS VALID
  */
  const validateEstablishments = () => {
    const establishmentsToSend = establishments.filter((element) => {
      return (
        element.tag &&
        element.lots.some((item) => {
          return item.selected
        })
      )
    })

    return establishmentsToSend.length
  }

  return (
    <>
      <ScrollView style={styles.container}>
        {establishments.map((establishment, key) => (
          <View key={key}>
            <View style={styles.containerAccordion}>
              <CommonIcon
                name={'X'}
                size={26}
                style={styles.accordionIcon}
                onPress={() => deleteEstablishment(key)}
              />

              <View style={styles.subContainerAccordion}>
                <List.Accordion
                  title={establishment.file.name}
                  style={styles.accordion}
                  titleStyle={
                    !establishment.display
                      ? styles.accordionTitle
                      : styles.accordionTitleSelected
                  }
                  expanded={establishment.display}
                  onPress={() => displayEstablishmentToggle(establishment)}
                ></List.Accordion>
              </View>
            </View>

            {establishment.display && (
              <>
                <TextInput
                  style={styles.establishmentName}
                  placeholder={t('VIEWS').KMZ_ADD.FIELD_1.NAME}
                  label={t('VIEWS').KMZ_ADD.FIELD_1.NAME}
                  placeholderTextColor='rgba(0, 0, 0, .6)'
                  value={establishment.tag}
                  onChangeText={(text) => {
                    setTag(establishment, text)
                  }}
                  autoCorrect={false}
                  mode={'outlined'}
                />

                {Boolean(establishment.message) && (
                  <MessageToast
                    type={toastTypes.ERROR.key}
                    message={establishment.message}
                  />
                )}

                <View
                  style={[
                    styles.lotsHeader,
                    establishment.isAllDisabled ? styles.containerDisabled : {},
                  ]}
                >
                  <CommonIcon
                    name={
                      establishment.selected
                        ? 'CHECKBOX-SELECTED'
                        : 'CHECKBOX-UNSELECTED'
                    }
                    size={24}
                    color={
                      establishment.selected ? primary500 : blackHighEmphasis
                    }
                    style={styles.lotsHeaderIcon}
                    onPress={() => {
                      !establishment.isAllDisabled &&
                        selectEstablishmentToggle(establishment)
                    }}
                  />

                  <View style={styles.lotsHeaderTextContainer}>
                    <Text style={styles.lotsHeaderText}>
                      {t('VIEWS').KMZ_ADD.TEXT_4}
                    </Text>
                  </View>
                </View>

                {establishment.lots.map((lot, lotKey) => (
                  <View key={lotKey} style={styles.containerLot}>
                    <View
                      style={[
                        styles.subContainerLot,
                        lot.disabled ? styles.containerDisabled : {},
                      ]}
                    >
                      <CommonIcon
                        name={
                          lot.selected
                            ? 'CHECKBOX-SELECTED'
                            : 'CHECKBOX-UNSELECTED'
                        }
                        size={24}
                        color={lot.selected ? primary500 : blackHighEmphasis}
                        style={styles.lotIcon}
                        onPress={() =>
                          !lot.disabled && selectLotToggle(establishment, lot)
                        }
                      />

                      <View style={styles.lotTexts}>
                        <Text style={styles.lotName}>{lot.name}</Text>

                        <Text style={styles.lotSurface}>
                          {`${roundSurface(lot.surface)} ${
                            lot?.areaUnit ?? ''
                          }`}
                        </Text>
                      </View>

                      <View style={styles.lotImageContainer}>
                        <Image
                          source={{
                            uri: 'https://ucropsatelliteimagery.s3.amazonaws.com/stage/10.png',
                          }}
                          style={styles.lotImage}
                        />
                      </View>
                    </View>
                  </View>
                ))}
              </>
            )}
          </View>
        ))}
      </ScrollView>

      <View style={styles.containerButtons}>
        <View style={styles.containerFileButton}>
          <Button style={styles.button} mode='outlined'>
            {t('VIEWS').KMZ_ADD.TEXT_2}
          </Button>

          <UploadInput
            handleFile={handleFile}
            style={styles.uploadInput}
            accept={KMZ_MIME}
          ></UploadInput>
        </View>

        <Button
          labelStyle={styles.uploadLotsText}
          style={
            validateEstablishments() ? styles.button : styles.buttonDisabled
          }
          mode='contained'
          onPress={uploadSelectedLots}
        >
          {t('VIEWS').KMZ_ADD.TEXT_3}
        </Button>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: white,
  },
  containerAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  accordionIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: secondary500,
  },
  subContainerAccordion: {
    flex: 1,
  },
  accordion: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
    paddingRight: 16,
    backgroundColor: white,
  },
  accordionTitle: {
    color: black,
    fontWeight: '500',
    marginLeft: -8,
  },
  accordionTitleSelected: {
    color: primary500,
    fontWeight: '500',
    marginLeft: -8,
  },
  establishmentName: {
    width: '100%',
    height: 48,
    backgroundColor: white,
    color: black,
    borderRadius: 2,
    paddingTop: 2,
    paddingRight: 16,
    paddingBottom: 2,
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: '500',
  },
  lotsHeader: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    backgroundColor: grayBackground,
    marginTop: 15,
  },
  lotsHeaderIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  lotsHeaderTextContainer: {
    flex: 1,
    paddingTop: 18,
    paddingBottom: 18,
    borderBottomWidth: 1,
    borderBottomColor: onPressedColor,
  },
  lotsHeaderText: {
    fontSize: 16,
    fontWeight: '500',
  },
  containerLot: {
    backgroundColor: grayBackground,
    paddingLeft: 5,
    paddingRight: 5,
  },
  subContainerLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: onPressedColor,
  },
  containerDisabled: {
    opacity: 0.38,
  },
  lotIcon: {
    marginLeft: 30,
    marginRight: 30,
  },
  lotTexts: {
    flex: 1,
  },
  lotName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: blackHighEmphasis,
    marginBottom: 5,
  },
  lotImageContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  lotImage: {
    height: 60,
    width: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  containerButtons: {
    backgroundColor: white,
  },
  containerFileButton: {
    position: 'relative',
  },
  uploadInput: {
    height: 45,
    width: '100%',
    position: 'absolute',
  },
  button: {
    padding: 5,
    marginHorizontal: 20,
    marginVertical: 5,
  },
  buttonDisabled: {
    padding: 5,
    marginHorizontal: 20,
    marginVertical: 5,
    backgroundColor: primary500Disabled,
  },
  uploadLotsText: {
    color: white,
  },
})

KmzAdd.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default KmzAdd
