import * as Analytics from 'expo-firebase-analytics'

export const logLogin = async ({ os, email }) => {
  try {
    await Analytics.logEvent(`login_${os}`, {
      email,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE LOG LAST ENTRY OF USER
 *
 * @param {*} data
 */
export const logLastEntry = async ({ os, email, date }) => {
  try {
    await Analytics.logEvent('last_entry', {
      os,
      email,
      date,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE ACTIVITY NOT VALIDATE
 *
 * @param {*} data
 */
export const activityNotValidate = async ({
  activityType,
  date,
  userId,
  email,
}) => {
  try {
    await Analytics.logEvent('activity_not_validate', {
      activityType,
      date,
      userId,
      email,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE MOST USED FILTERS NOTIFICATIONS
 *
 * @param {*} data
 */
export const mostUsedFiltersNotifications = async ({
  activityTypes,
  cropTypes,
  byFarmFilter,
  byCropFilter,
  date,
  userId,
  email,
}) => {
  try {
    await Analytics.logEvent('most_used_filters_notifications', {
      activityTypes,
      cropTypes,
      byFarmFilter,
      byCropFilter,
      date,
      userId,
      email,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE MOST USED FILTERS ACTIVITIES
 *
 * @param {*} data
 */
export const mostUsedFiltersActivities = async ({
  activityStatus,
  activityType,
}) => {
  try {
    await Analytics.logEvent('most_used_filters_activities', {
      activityStatus,
      activityType,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE SHARE OR VIEW WORK ORDER
 *
 * @param {*} data
 */
export const shareViewWorkOrder = async ({
  date,
  userType,
  activityType,
  workOrderNumber,
  email,
  action,
}) => {
  try {
    await Analytics.logEvent('share_view_work_order', {
      date,
      userType,
      activityType,
      workOrderNumber,
      email,
      action,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * SAVE ENTER TO CROP STORY
 *
 * @param {*} data
 */
export const enterToCropStory = async ({
  date,
  fromView,
  cropId,
  fieldId,
  userId,
  userRol,
  userIsAdmin,
  userIsResponsible,
  os,
}) => {
  try {
    await Analytics.logEvent('enter_to_crop_story', {
      date,
      fromView,
      cropId,
      fieldId,
      userId,
      userRol,
      userIsAdmin,
      userIsResponsible,
      os,
    })
  } catch (error) {
    console.error(error)
  }
}
