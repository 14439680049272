import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'

import {
  ActivityCardDate,
  ActivityProgressBar,
  FarmList,
  Info,
  Storage,
} from '../'
import ACTIVITY_TYPES from '@constants/activityTypes'
import { mapRealizedSurfaceFarmsAndLots } from '../../utils'

export const ActivityHarvest = ({
  activityType,
  farms,
  storages,
  areaUnitVolume,
  dateHarvest,
  dateObservation,
  percent,
  pay,
  unitType,
  unitTypeKey,
  volume,
}) => {
  if (activityType !== ACTIVITY_TYPES.ACT_HARVEST.key) return null

  const farmAndLotsWithSurfaceRealized = mapRealizedSurfaceFarmsAndLots(farms)

  return (
    <>
      <ActivityCardDate
        activityType={activityType}
        dateHarvest={dateHarvest}
        dateObservation={dateObservation}
      />

      <ActivityProgressBar percent={percent} />

      <Info
        pay={pay}
        unitType={unitType}
        unitTypeKey={unitTypeKey}
        volume={volume}
        areaUnitVolume={areaUnitVolume}
      />

      <FarmList farms={farmAndLotsWithSurfaceRealized} realized={true} />

      <Storage
        storages={storages}
        areaUnitVolume={areaUnitVolume}
        contentStyle={styles.storage}
      />
    </>
  )
}

const styles = StyleSheet.create({
  storage: {
    marginTop: 12,
  },
})

ActivityHarvest.prototype = {
  activityType: PropTypes.string.isRequired,
  farms: PropTypes.array.isRequired,
  storages: PropTypes.array.isRequired,
  areaUnitVolume: PropTypes.string.isRequired,
  dateHarvest: PropTypes.string.isRequired,
  dateObservation: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  pay: PropTypes.number.isRequired,
  unitType: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
}
