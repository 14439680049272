import PropTypes from 'prop-types'
import React, { createRef, useEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import ActionSheet from 'react-native-actions-sheet'
import ActionSheetAndModal from '@components/common/v1/ActionSheetAndModal'
import { ModalDefault } from '@common/components'

const useActionSheetCustom = ({ t, title, afterClosing }) => {
  const actionSheetRef = createRef()
  const [isOpenModal, setOpenModal] = useState(false)
  const [displayActionSeet, setIsDisplayActionSheet] = useState(false)

  useEffect(() => {
    if (actionSheetRef.current) {
      if (displayActionSeet) {
        actionSheetRef.current?.show()
      } else {
        actionSheetRef.current?.hide()
      }
    }
  }, [displayActionSeet])

  const openActionSheet = () => {
    if (Platform.OS !== 'web') {
      setIsDisplayActionSheet(true)
    } else {
      setOpenModal(true)
    }
  }
  const closeActionSheet = () => {
    if (Platform.OS !== 'web') {
      setIsDisplayActionSheet(false)
    } else {
      setOpenModal(false)
    }
    if (afterClosing) {
      afterClosing()
    }
  }

  const ActionSheetComponent = ({ children }) => {
    return (
      <>
        {Platform.OS !== 'web' ? (
          <ActionSheet
            ref={actionSheetRef}
            containerStyle={styles.actionStyles}
            onClose={closeActionSheet}
          >
            <View style={styles.containerActionSheet}>
              <ActionSheetAndModal
                onClose={() => actionSheetRef.current?.hide()}
                title={title}
                t={t}
              >
                {children}
              </ActionSheetAndModal>
            </View>
          </ActionSheet>
        ) : (
          <ModalDefault
            isModalVisible={isOpenModal}
            closeModal={closeActionSheet}
          >
            <ActionSheetAndModal
              onClose={closeActionSheet}
              t={t}
              modal
              title={title}
            >
              {children}
            </ActionSheetAndModal>
          </ModalDefault>
        )}
      </>
    )
  }

  return {
    ActionSheetComponent,
    openActionSheet,
    closeActionSheet,
  }
}

const styles = StyleSheet.create({
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerActionSheet: {
    marginHorizontal: 10,
  },
})

useActionSheetCustom.propTypes = {
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default useActionSheetCustom
