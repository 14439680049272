import { EActiveAgreementTypes } from '@modules/common/utils'

/**
 *
 * @param {Array.<{key: EActiveAgreementTypes, label: String, value: String}>} agreementTypes
 * @returns
 */
export const getActiveAgreementTypes = (agreementTypes, keyExclude = null) => {
  return agreementTypes.filter(
    (agreement) =>
      Object.values(EActiveAgreementTypes).includes(agreement.key) &&
      agreement.key !== keyExclude
  )
}
