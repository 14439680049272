import { EActiveAgreementTypes, EquivalentSeeds } from '@common/utils'
import { CommonContext } from '@contextState/common'
import useNetwork from '@utils/network'
import { useContext, useEffect, useState } from 'react'
import { getActiveAgreementTypes } from '../utils'

const useAgreementsTypes = ({ cropType }) => {
  const { agreementTypes: agreementTypesContext } = useContext(CommonContext)
  const [agreementTypes, setAgreementTypes] = useState([])
  const { doRequest } = useNetwork()
  useEffect(() => {
    fetchAgreements()
  }, [])
  const fetchAgreements = async () => {
    const cropTypeKey = String(cropType.key).toUpperCase()
    const cropTypeLabel = EquivalentSeeds[cropTypeKey] ?? cropTypeKey
    let agreementsResult = agreementTypesContext
    const constants2BSVS = await doRequest({
      method: 'GET',
      url: 'emissions/constants/2bsvs',
      displayAlert: false,
    })
    if (constants2BSVS?.data) {
      const isCrop2BSVS = constants2BSVS?.data.find(
        (constant2BSVS) =>
          String(constant2BSVS.key).toUpperCase() ===
          String(cropTypeLabel).toUpperCase()
      )
      agreementsResult = getActiveAgreementTypes(
        agreementTypesContext,
        !isCrop2BSVS && EActiveAgreementTypes.CALCULATION_2BSVS
      )
    }
    setAgreementTypes(agreementsResult)
  }
  return { agreementTypes }
}

export default useAgreementsTypes
