import React, { useCallback, useContext, useState } from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import {
  FAB as Fab,
  Subheading,
  Card,
  ActivityIndicator,
} from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { cropEmpty } from '@constants/images'
import { AuthContext } from '@contextState/auth'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'

function CompanyServiceIntegrationScreen({ navigation }) {
  const { doRequest, loading } = useNetwork()
  const { selectedProfile } = useContext(AuthContext)
  const [integrations, setIntegration] = useState([])
  const [company, setCompany] = useState(null)
  const { t } = useContext(LanguageContext)

  const fetchData = useCallback(
    async (id) => {
      const response = await doRequest({
        method: 'GET',
        url: `companies/integrations/${id}`,
      })
      setIntegration(response.data)
    },
    [doRequest]
  )

  React.useEffect(() => {
    setCompany(selectedProfile.company)
    const unsubscribe = navigation.addListener('focus', async () => {
      await fetchData(selectedProfile.company._id)
    })

    return unsubscribe
  }, [navigation])

  if (loading) {
    return (
      <View style={styles.emptyContainer}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {integrations.length > 0 ? (
        <ScrollView style={{ flex: 1 }}>
          {integrations.map((integration, key) => (
            <Card key={key} style={styles.card}>
              <Card.Title
                right={({ props }) =>
                  !integration.integrate ? (
                    <CommonIcon
                      {...props}
                      style={{ marginRight: 16 }}
                      size={20}
                      name='CLOCK'
                      color='#ccc'
                    />
                  ) : (
                    <CommonIcon
                      {...props}
                      style={{ marginRight: 16 }}
                      size={20}
                      name='CHECK-CIRCLE'
                      color='green'
                    />
                  )
                }
                titleStyle={{ fontSize: 16 }}
                subtitleStyle={{ fontSize: 14 }}
                title={integration.service}
              />
            </Card>
          ))}
        </ScrollView>
      ) : (
        <View style={styles.emptyContainer}>
          <Image source={cropEmpty} style={styles.emptyState} />
          <Subheading style={styles.emptyStateText}>
            {t('VIEWS').COMPANY_SERVICE_INTEGRATION.TEXT_1}
          </Subheading>
        </View>
      )}

      <Fab
        style={styles.fab}
        color='white'
        icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
        onPress={() =>
          navigation.navigate('CompanyServiceIntegrationAdd', {
            id: company?._id,
          })
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    marginBottom: 8,
  },
  emptyStateText: {
    textAlign: 'center',
    marginTop: 16,
  },
  emptyState: {
    width: 203,
    height: 150,
    resizeMode: 'contain',
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    padding: 16,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    color: 'white',
  },
  fabContainer: {
    marginBottom: 25,
    marginRight: 10,
    alignItems: 'center',
  },
})

CompanyServiceIntegrationScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyServiceIntegrationScreen
