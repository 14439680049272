import React, { useContext, useState, useCallback } from 'react'
import { Platform } from 'react-native'
import PropTypes from 'prop-types'
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from 'react-native'
import { Chip } from 'react-native-paper'

import { LanguageContext } from '@contextState/language'
import { AuthContext } from '@contextState/auth'
import { CommonIcon } from '@modules/common/components'
import {
  blackHighEmphasis,
  white,
  blackMediumEmphasis,
  blackDisabled,
  red,
  lightGreen900,
  LightSalmon,
} from '@styles/palette'
import { CROP_MENU_OPTIONS } from '@modules/common/utils'
import { CropMenu } from '@modules/common/components'
import { enterToCropStory } from '@services/analytics'
import { green500 } from '@modules/common/styles'

export const CropItem = ({
  canWrite,
  crop,
  disabled,
  downloaded,
  isCropOffline,
  name,
  onPress,
  onPressCropMenu,
  status,
  subtitle,
}) => {
  const { t } = useContext(LanguageContext)
  const { user, config } = useContext(AuthContext)

  const [isLoadingDownload, setIsloadingDonwload] = useState(false)

  const handleCropMenuOptions = async (option) => {
    if (option === CROP_MENU_OPTIONS.CROP_STORY) {
      enterToCropStory({
        date: new Date(),
        fromView: 'CROP_LIST',
        cropId: crop._id,
        fieldId: '',
        userId: user._id,
        userRol: config?.roleSelected?.equivalentRole,
        userIsAdmin: config?.isAdmin,
        userIsResponsible: config?.isResponsible,
        os: Platform.OS,
      })
    }

    if (
      option === CROP_MENU_OPTIONS.ENABLE_OFFLINE ||
      option === CROP_MENU_OPTIONS.DISABLE_OFFLINE
    ) {
      setIsloadingDonwload(true)
      await onPressCropMenu(crop, option)
      setIsloadingDonwload(false)

      return
    }

    onPressCropMenu(crop, option)
  }

  const hasLots = useCallback(
    Boolean(crop.lots?.find((farm) => Boolean(farm.data?.length))),
    [crop.lots]
  )

  const userType = useCallback(
    String(
      crop.members?.find((member) => member?.user?._id === user._id)?.type
    ),
    [crop.members]
  )

  return (
    <TouchableWithoutFeedback onPress={disabled ? null : onPress ?? null}>
      <View style={styles.container}>
        <View style={styles.contentTitle}>
          <Text style={styles.title} numberOfLines={1} ellipsizeMode='tail'>
            {name}
          </Text>

          <CropMenu
            disabled={disabled}
            canWrite={canWrite}
            isCropOffline={isCropOffline}
            status={status}
            onPress={handleCropMenuOptions}
            hasLots={hasLots}
            userType={userType}
            userCanDeleteCrop={crop.userCanDeleteCrop}
            isDeleteCropAvailable={crop.isDeleteCropAvailable}
          />
        </View>

        <Text style={styles.subtitle}>{subtitle}</Text>

        <View style={styles.contentChips}>
          {status === 'DRAFT' && (
            <Chip textStyle={styles.chipText} style={styles.chipStyle}>
              {t('COMPONENTS.COMMON.LIST_ITEM.TEXT_1')}
            </Chip>
          )}

          {hasLots && (
            <>
              {status && (
                <Chip textStyle={styles.chipText} style={styles.chipStyle}>
                  {status}
                </Chip>
              )}

              {downloaded && !isLoadingDownload && (
                <View style={styles.syncStatus}>
                  <CommonIcon
                    name={'FILE-DOWNLOAD'}
                    size={16}
                    color={green500}
                  />
                </View>
              )}

              {isLoadingDownload && <ActivityIndicator color='green' />}
            </>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 17.5,
    paddingBottom: 12,
    paddingLeft: 16,
    backgroundColor: white,
    marginBottom: 4,
    borderRadius: 4,
  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
    fontStyle: 'normal',
  },
  subtitle: {
    color: blackMediumEmphasis,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    fontStyle: 'normal',
    marginTop: -5,
  },
  optionMenu: {
    color: blackHighEmphasis,
    fontSize: 14,
  },
  optionMenuDisabled: {
    color: blackDisabled,
    fontSize: 14,
  },
  optionMenuRemove: {
    color: red,
    fontSize: 14,
  },
  contentChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    paddingRight: 16,
  },
  syncStatus: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1.5,
    borderRadius: 100,
    borderColor: green500,
  },
  chipText: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 14,
    margin: 0,
  },
  chipStyle: {
    backgroundColor: LightSalmon,
    margin: 0,
    color: 'white',
    fontSize: 14,
  },
})

CropItem.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  crop: PropTypes.object.isRequired,
  onPressCropMenu: PropTypes.func.isRequired,
  canWrite: PropTypes.bool,
  isCropOffline: PropTypes.bool,
  downloaded: PropTypes.bool,
}
