import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { LanguageContext } from '@contextState/language'
import InputFileUpload from '@components/inputs/InputFileUpload'
import { allowedFormats } from '../../utils'

export const KmzAddButton = ({ buttonRef, onPress }) => {
  const { t } = useContext(LanguageContext)

  return (
    <InputFileUpload
      accept={allowedFormats.join(',')}
      onSelectFile={onPress}
      inputRef={buttonRef}
      isDocumentOnly
      autoOpen
      showAlert={false}
      cancelOnPress={() => {}}
    >
      <View style={[styles.button]}>
        <Text style={[styles.textButton]}>
          {t('VIEWS.FARM_LOTS_ADD.COMPONENTS.KMZ_ADD_BUTTON.TEXT_1')}
        </Text>
      </View>
    </InputFileUpload>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderColor: '#4CAF50',
    borderWidth: 1,
    marginTop: 5,
    borderRadius: 4,
    height: 50,
    marginHorizontal: 16,
  },
  textButton: {
    color: '#4CAF50',
    fontWeight: '500',
  },
})

KmzAddButton.propTypes = {
  onPress: PropTypes.func.isRequired,
}
