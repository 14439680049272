import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'

const ItemLandscapeSustainability = ({
  name,
  value,
  horizontal = false,
  children,
}) => (
  <View style={styles.containerMaster}>
    <View style={[styles.container, horizontal && styles.horizontal]}>
      <Text style={styles.name}>{name}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
    {children}
  </View>
)

const styles = StyleSheet.create({
  containerMaster: {
    marginBottom: 4,
  },
  container: {
    marginLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    paddingVertical: 12,
    marginBottom: 4,
  },
  name: {
    fontWeight: '500',
    fontSize: 16,
    color: '#333333',
    marginRight: 8,
  },
  value: {
    color: '#707070',
    fontSize: 14,
  },
  horizontal: {
    flexDirection: 'row',
  },
})

ItemLandscapeSustainability.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default ItemLandscapeSustainability
