import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import AutocompleteInput from 'react-native-autocomplete-input'
import PropTypes from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import InputSearch from '@components/inputs/InputSearch'

const InputSearchSupply = ({
  data,
  handler,
  setFieldValue,
  values,
  onSearchInputFocus,
  onSearchInputBlur,
  searchInputRef,
  visible,
  onSelectElement,
  onRemoveElement,
  onSearchInputHandleKeypress,
  onPressRight,
}) => {
  const renderItem = ({ item }) => {
    return (
      <View style={styles.elementContainer}>
        <TouchableOpacity onPress={() => onSelectElement(item)}>
          <View style={styles.selectElementContainer}>
            <CommonIcon name='CLOCK' size={18} color='#DADADA' />
            <Text style={styles.elementText}>{`  ${item.text}`}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onRemoveElement(item)}>
          <View style={styles.removeElementContainer}>
            <CommonIcon name='X' size={20} color='black' />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  const inputContainerStyle = {
    borderWidth: 0,
  }

  return (
    <View style={styles.autocompleteContainer}>
      <AutocompleteInput
        data={visible ? data : []}
        inputContainerStyle={inputContainerStyle}
        renderTextInput={() => (
          <InputSearch
            onChange={handler}
            onChangeText={(text) => setFieldValue('search', text)}
            value={values.search}
            onPressRight={onPressRight}
            onFocus={onSearchInputFocus}
            onBlur={onSearchInputBlur}
            inputRef={searchInputRef}
            onSubmitEditing={() => onSearchInputHandleKeypress(values.search)}
          />
        )}
        flatListProps={{
          keyboardShouldPersistTaps: 'always',
          keyExtractor: (_, idx) => `item-search-${idx}`,
          style: styles.flatListStyle,
          contentContainerStyle: styles.contentContainerStyle,
          renderItem,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  autocompleteContainer: {
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: 'transparent',
    paddingTop: 15,
  },
  elementContainer: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 14,
    height: 40,
  },
  selectElementContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatListStyle: {
    paddingHorizontal: 10,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: 'transparent',
  },
  contentContainerStyle: {
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
})

InputSearchSupply.propTypes = {
  data: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onSearchInputFocus: PropTypes.func.isRequired,
  onSearchInputBlur: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onSelectElement: PropTypes.func.isRequired,
  onRemoveElement: PropTypes.func.isRequired,
}

export default InputSearchSupply
