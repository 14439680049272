import React, { useContext, useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useDispatch } from 'react-redux'

import { LanguageContext } from '../contextState/language'
import activityTypes from '@constants/activityTypes'

/******************************
 *           HEADER           *
 *****************************/
import Header from '../components/common/Header'
import { HeaderOnlyBack } from '@common/components'

/******************************
 *         COMPANIES          *
 *****************************/
import CropCompanyScreen from '../screens/CropCompanyScreen'
import AddCompanyScreen from '@modules/companies/screens/AddCompanyScreen/v1'
import EditCompany from '@modules/companies/screens/EditCompany/v1'
import CompanyProfileScreen from '../screens/CompanyProfileScreen'
import ValidateTaxId from '@modules/companies/screens/ValidateTaxId/v1'

/******************************
 *           FARMS            *
 *****************************/
import FarmList from '@modules/farms/screens/FarmList/v2'
import FarmCreate from '@modules/farms/screens/FarmCreate/v1'
import FarmLotsAdd from '@modules/farms/screens/FarmLotsAdd/v1'
import FarmDetails from '@modules/farms/screens/FarmDetails/v1'
import FarmLotList from '@modules/farms/screens/FarmLotList/v1'
import FarmLotDetails from '@modules/farms/screens/FarmLotDetails/v1'
import FarmEditName from '@modules/farms/screens/FarmEditName/v1'
import FarmLotEditName from '@modules/farms/screens/FarmLotEditName/v1'

/******************************
 *           CROPS            *
 *****************************/
import CropList from '@modules/crops/screens/CropList/v1'
import CropCreate from '@modules/crops/screens/CropCreate/v1'
import CropSelectResponsible from '../screens/v1/cropSelectResponsible'
import CollaboratorCreate from '../screens/v1/collaboratorCreate'
import LotsAddScreen from '../screens/v1/LotsAdd'
import KmzAddScreen from '../screens/v1/KmzAdd'
import CropCreateConfirm from '@modules/crops/screens/CropCreateConfirm/v1'
import CropDetail from '../screens/v1/CropStory/CropDetail'
import CropCollaboratorsListScreen from '../screens/v1/CropCollaboratorsListScreen'
import CropCollaboratorsAddScreen from '../screens/CropCollaboratorsAddScreen'
import CropFarmList from '@modules/crops/screens/CropFarmList/v1'
import CropFarmFieldDetails from '@modules/crops/screens/CropFarmFieldDetails/v1'
import FiltersScreen from '../screens/FiltersScreen'
import CompanyList from '../modules/companies/screens/CompanyList/v1'
import CompanyDetail from '../modules/companies/screens/CompanyDetail/v1'

/******************************
 *           CROPS            *
 *****************************/
import CropStoryDetail from '@modules/cropStory/screens/CropStoryDetail/v2'

/******************************
 *          LICENSES          *
 *****************************/
import LicensesScreen from '../screens/v1/Licenses'
import LicenseDetailsScreen from '../screens/v1/LicenseDetails'
import LicenseSignScreen from '../screens/v1/LicenseSign'
import LicenseLotsAddScreen from '../screens/v1/LicenseLotsAdd'
import SummaryLicenseLots from '../screens/v1/SummaryLicenseLots'
import subLicenseSelectCompanies from '../screens/v1/subLicenseSelectCompanies'
import LicenseList from '@modules/licenses/screens/LicenseList/v1'
import LicenseDetail from '@modules/licenses/screens/LicenseDetail/v1'
import LicenseSign from '@modules/licenses/screens/LicenseSign/v2'

/******************************
 *         ACTIVITIES         *
 *****************************/
import ActivitiesScreen from '@modules/activities/screens/activitiesList/v1'
import ActivityList from '@modules/activities/screens/activitiesList/v2'
import FilterActivitiesScreen from '@modules/activities/screens/Filter/v1'
import ActivityCreate from '@modules/activities/screens/activityCreate/v1'
import ActivityApplicationDetails from '@modules/activities/screens/applicationAchievementList/v1/ActivityApplicationDetails'
import ActivityValidation from '../screens/v1/ActivityValidation'
import CommonActivitiesScreen from '../screens/CommonActivitiesScreen'
import AchievementsActivityList from '@modules/activities/screens/achievementsActivityList/v1'
import AchievementValidation from '../screens/v1/AchievementValidation'
import AchievementsScreen from '@modules/activities/screens/Achievement/v1/AchievementsScreen'
import CommonActivitiesDetail from '../screens/CommonActivitiesDetail'
import ActivityLotsAdd from '../screens/v1/ActivityLotsAdd'
import ActivityMinimumUnitLot from '../screens/v1/ActivityMinimumUnitLot'
import ActivityLotsSurface from '../screens/v1/ActivityLotsSurface'
import OptionalPlanned from '@modules/activities/screens/optionalPlanned/v1'
import CollaboratorsDelegateSignaturesList from '../screens/v1/CollaboratorsDelegateSignaturesList'
import UnlinkCollaborator from '../screens/v1/UnlinkCollaborator'
import SupplyDetail from '../screens/v1/supplyDetail/index'
import ActivityCreateConfirm from '@modules/activities/screens/ActivityCreateConfirm/v1'

/******************************
 *  LANDSCAPE SUSTAINABILITY  *
 *****************************/
import LandscapeSustainability from '../screens/v1/landscapeSustainability'
import SummaryLandscapeSustainability from '../screens/v1/SummaryLandscapeSustainability'

/******************************
 *            USER            *
 *****************************/
import ProfileScreen from '../screens/ProfileScreen'

/******************************
 *     SERVICE INTEGRATION    *
 *****************************/
import IntegrationDetailScreen from '../screens/IntegrationDetailScreen'
import CropServiceIntegrationsScreen from '../screens/CropServiceIntegrationsScreen'
import CompanyServiceIntegrationScreen from '../screens/CompanyServiceIntegrationScreen'
import CompanyServiceIntegrationAddScreen from '../screens/CompanyServiceIntegrationAddScreen'
import IntegrationQueueScreen from '../screens/IntegrationQueueScreen'
import CompanyServiceIntegrationsSelectorScreen from '../screens/CompanyServiceIntegrationsSelectorScreen'
import CompanyIntegrationAppListScreen from '../screens/CompanyIntegrationAppListScreen'
import CompanyIntegrationAgreementScreen from '../screens/CompanyIntegrationAgreementScreen'
import CompanyIntegrationLinkScreen from '../screens/CompanyIntegrationLinkScreen'
import CompanyServiceIntegrationAccountScreen from '../screens/CompanyServiceIntegrationsAccountScreen'
import CompanyUnlinkServiceIntegrationScreen from '../screens/CompanyUnlinkServiceIntegrationScreen'

/******************************
 *       SYNCHRONIZATION      *
 *****************************/
import OfflineQueueScreen from '../screens/OfflineQueueScreen'

/******************************
 *          DEEP LINK         *
 *****************************/
import ModalErrorsDeepLinks from '@modules/common/ModalErrorsDeepLinks'

/******************************
 *           OTHERS           *
 *****************************/
import SetupScreen from '../screens/SetupScreen'

/******************************
 *       COLLABORATORS        *
 *****************************/
import CollaboratorListCompany from '@modules/collaborators/screens/CollaboratorListCompany/v1'
import AddCollaborator from '@modules/collaborators/screens/AddCollaborator/v1'
import CollaboratorsListNewCompany from '../modules/collaborators/screens/CollaboratorsListNewCompany/v1'

/******************************
 *       SETTINGS        *
 *****************************/
import SettingsScreen from '@modules/settings/screens/SettingsScreen/v1'
import DeleteAccount from '@modules/settings/screens/DeleteAccount/v1'
import Languages from '@modules/settings/screens/Languages/v1'
import CognitoCode from '@modules/authentication/screens/CognitoCode/v1'

import HomeWithoutCompany from '@modules/home/screens/HomeWithoutCompany'
import { authActions } from '@store/actions'
import { AuthContext } from '@contextState/auth'
import RouterNames from '@constants/routerNames'
import WebViewResponsivePage from '@modules/webView/screens/WebViewResponsivePage/index'
import { Platform } from 'react-native'
import FarmsListSelectByCrop from '@modules/farms/screens/FarmsListSelectByCrop'

const Stack = createStackNavigator()

const ProfileStack = () => {
  const { t } = useContext(LanguageContext)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='Profile'
        component={ProfileScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_1}
              goBack
            />
          ),
        }}
      />
    </Stack.Navigator>
  )
}

const SetupStack = () => {
  const { t } = useContext(LanguageContext)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='Setup'
        component={SetupScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_2,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_2}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='OfflineQueue'
        component={OfflineQueueScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_3,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_4}
              goBack
            />
          ),
        }}
      />
    </Stack.Navigator>
  )
}

const Main = createStackNavigator()

const MainStackNavigator = ({ initialRouteName = 'FarmList' }) => {
  const { t } = useContext(LanguageContext)
  const { user, config } = useContext(AuthContext)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      authActions.setEmailWelcomeCompanyList({ email: user?.email, hide: true })
    )
  }, [])
  return (
    <Main.Navigator headerMode='float' initialRouteName={initialRouteName}>
      <Stack.Screen
        name='FarmList'
        component={FarmList}
        initialParams={{ newFarm: false }}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_50'),
          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_50')}
              drawer
              showNotificationIcon={config?.companySelected}
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropList'
        component={CropList}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_5,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_5}
              drawer
              showFilters
            />
          ),
        }}
      />
      <Stack.Screen
        name='LicenseList'
        component={LicenseList}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.LICENCES,
          // eslint-disable-next-line react/display-name
          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.LICENCES}
              drawer
            />
          ),
        }}
      />

      <Stack.Screen
        name='LicenseDetail'
        component={LicenseDetail}
        options={{
          title: t('VIEWS').LICENSE_DETAILS.TEXT_1,
          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').LICENSE_DETAILS.TEXT_1}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='LicenseSignV2'
        component={LicenseSign}
        options={{
          title: t('VIEWS.LICENSE_SIGN.TEXT_1'),
          header: (props) => (
            <Header {...props} title={t('VIEWS.LICENSE_SIGN.TEXT_1')} goBack />
          ),
        }}
      />

      <Stack.Screen
        name='CropCreate'
        component={CropCreate}
        options={{
          title: t('VIEWS').CROP_CREATE.TEXT_1,

          header: (props) => (
            <Header {...props} title={t('VIEWS').CROP_CREATE.TEXT_1} goBack />
          ),
        }}
      />
      <Stack.Screen
        name='Filters'
        component={FiltersScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_6,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_6}
              close
              showResetFilters
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropDetail'
        component={Platform.OS === 'web' ? CropStoryDetail : CropDetail}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_7,

          header: (props) => (
            <Header
              {...props}
              goBack
              title={t('VIEWS').CROP_DETAILS.TEXT_1}
              download={Platform.OS === 'web' ? false : true}
            />
          ),
        }}
      />
      <Stack.Screen
        name='FieldStory'
        component={CropStoryDetail}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.FIELD_STORY'),
          header: (props) => (
            <Header
              {...props}
              goBack
              title={t('NAVIGATION.STACK_NAVIGATOR.FIELD_STORY')}
              download={Platform.OS === 'web' ? false : true}
            />
          ),
        }}
      />
      <Stack.Screen
        name='LandscapeSustainability'
        component={LandscapeSustainability}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_62,

          header: (props) => (
            <Header
              {...props}
              close
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_62}
            />
          ),
        }}
      />
      <Stack.Screen
        name='SyncDetail'
        component={IntegrationDetailScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_8,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_8}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropCompany'
        component={CropCompanyScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_9,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_9}
              goBack='CropList'
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropCollaborators'
        component={CropCollaboratorsListScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_10,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_10}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CollaboratorsDelegateSignatures'
        component={CollaboratorsDelegateSignaturesList}
        options={{
          title: t('VIEWS').CROP_COLLABORATOR.TEXT_18,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').CROP_COLLABORATOR.TEXT_18}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='UnlinkCollaborator'
        component={UnlinkCollaborator}
        options={{
          title: t('VIEWS').CROP_COLLABORATOR.TEXT_19,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').CROP_COLLABORATOR.TEXT_19}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropCollaboratorsAdd'
        component={CropCollaboratorsAddScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_11,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_11}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropFarmList'
        component={CropFarmList}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_63'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_63')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropFarmFieldDetails'
        component={CropFarmFieldDetails}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_64'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_64')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='ServiceIntegrations'
        component={CropServiceIntegrationsScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_12,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_13}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegration'
        component={CompanyServiceIntegrationScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_14,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_14}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegrationsSelector'
        component={CompanyServiceIntegrationsSelectorScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_14,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_14}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyIntegrationAppList'
        component={CompanyIntegrationAppListScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_14,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_14}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegrationAccountSelector'
        component={CompanyServiceIntegrationAccountScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_15,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_15}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyIntegrationAgreement'
        component={CompanyIntegrationAgreementScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_13,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_13}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegrationUnlink'
        component={CompanyUnlinkServiceIntegrationScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_13,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_13}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegrationLink'
        component={CompanyIntegrationLinkScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_13,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_13}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='IntegrationQueue'
        component={IntegrationQueueScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_16,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_16}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyServiceIntegrationAdd'
        component={CompanyServiceIntegrationAddScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_17,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_17}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CompanyProfile'
        component={CompanyProfileScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_18,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_18}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='CompanyAdd'
        component={AddCompanyScreen}
        initialParams={{}}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_9,

          header: (props) => {
            const { isAdviser } = props?.scene?.route?.params
            const title = isAdviser
              ? t('NAVIGATION.STACK_NAVIGATOR.ADVISE_COMPANY')
              : t('NAVIGATION.STACK_NAVIGATOR.MANAGE_COMPANY')
            return <Header {...props} title={title} goBack />
          },
        }}
      />
      <Stack.Screen
        name='EditCompany'
        component={EditCompany}
        initialParams={{}}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.EDIT_COMPANY'),

          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.EDIT_COMPANY')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='LotsAdd'
        component={LotsAddScreen}
        options={{
          title: t('VIEWS').LOTS_ADD.ADD_FIELDS_TO_CROP,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').LOTS_ADD.ADD_FIELDS_TO_CROP}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='ActivityMinimumUnitLot'
        component={ActivityMinimumUnitLot}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_42,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_42}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='DetailsRealization'
        component={ActivityMinimumUnitLot}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_41,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_41}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='DetailsPlanning'
        component={ActivityLotsSurface}
        options={{
          title: t('VIEWS').ACTIVITY_VALIDATION.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').ACTIVITY_VALIDATION.TEXT_1}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='ActivityLotsAdd'
        component={ActivityLotsAdd}
        options={{
          title: t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_1,

          header: (props) => {
            const { previous } = props
            const textTitle =
              previous?.route?.params?.tag ===
              activityTypes.ACT_VERIFICATION.key
                ? t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_7
                : t('VIEWS').ACHIEVEMENT_LOTS_ADD.TEXT_1
            return <Header {...props} title={textTitle} goBack />
          },
        }}
      />
      <Stack.Screen
        name='FarmsListSelectByCrop'
        component={FarmsListSelectByCrop}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='KmzAdd'
        component={KmzAddScreen}
        options={{
          title: t('VIEWS').KMZ_ADD.TEXT_1,

          header: (props) => (
            <Header {...props} title={t('VIEWS').KMZ_ADD.TEXT_1} goBack />
          ),
        }}
      />
      <Stack.Screen
        name='Licenses'
        component={LicensesScreen}
        options={{
          title: t('VIEWS').LICENSES.TEXT_1,

          header: (props) => (
            <Header {...props} title={t('VIEWS').LICENSES.TEXT_1} goBack />
          ),
        }}
      />
      <Stack.Screen
        name='ActivityApplicationDetails'
        component={ActivityApplicationDetails}
        options={{
          title: t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').ACTIVITY_APPLICATION_DETAILS.TEXT_1}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='ActivityValidation'
        component={ActivityValidation}
        options={{
          title: t('VIEWS').ACTIVITY_VALIDATION.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').ACTIVITY_VALIDATION.TEXT_1}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='AchievementValidation'
        component={AchievementValidation}
        options={{
          title: t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').ACHIEVEMENT_VALIDATION.TEXT_1}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='LicenseDetails'
        component={LicenseDetailsScreen}
        options={{
          title: t('VIEWS').LICENSE_DETAILS.TEXT_1,

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS').LICENSE_DETAILS.TEXT_1}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='LicenseSign'
        component={LicenseSignScreen}
        options={{
          title: t('VIEWS.LICENSE_SIGN.TEXT_1'),

          header: (props) => (
            <Header {...props} title={t('VIEWS.LICENSE_SIGN.TEXT_1')} goBack />
          ),
        }}
      />
      <Stack.Screen
        name='LicenseLotsAdd'
        component={LicenseLotsAddScreen}
        options={{
          title: t('VIEWS.LICENSE_LOTS_ADD.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.LICENSE_LOTS_ADD.TEXT_1')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='SummaryLicenseLots'
        component={SummaryLicenseLots}
        options={{
          title: t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_1')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='ActivityCreate'
        component={ActivityCreate}
        options={({ route }) => ({
          title: t(
            route.params.isDirectAchievement
              ? 'NAVIGATION.STACK_NAVIGATOR.TEXT_61'
              : 'NAVIGATION.STACK_NAVIGATOR.TEXT_46',
            {
              activityType: activityTypes[route.params.tag].name(t),
              Realize: activityTypes[route.params.tag].name(t),
            }
          ),

          header: (props) => (
            <Header
              {...props}
              title={t(
                route.params.isDirectAchievement
                  ? 'NAVIGATION.STACK_NAVIGATOR.TEXT_61'
                  : 'NAVIGATION.STACK_NAVIGATOR.TEXT_46',
                {
                  activityType: activityTypes[route.params.tag].name(t),
                  Realize: activityTypes[route.params.tag].name(t),
                }
              )}
              goBack
            />
          ),
        })}
      />
      <Stack.Screen
        name='ActivityUpdate'
        component={ActivityCreate}
        options={({ route }) => ({
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
            activityType: activityTypes[route.params.tag].name(t),
          }),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
                activityType: activityTypes[route.params.tag].name(t),
              })}
              goBack
            />
          ),
        })}
      />
      <Stack.Screen
        name='ActivityFinished'
        component={ActivityCreate}
        options={({ route }) => ({
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_48', {
            activityType: activityTypes[route.params.tag].name(t),
          }),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_48', {
                activityType: activityTypes[route.params.tag].name(t),
              })}
              goBack
            />
          ),
        })}
      />
      <Stack.Screen
        name='CollaboratorCreate'
        component={CollaboratorCreate}
        options={{
          title: t('VIEWS.COLLABORATOR_CREATE.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.COLLABORATOR_CREATE.TEXT_1')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropSelectResponsible'
        component={CropSelectResponsible}
        options={{
          title: t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_1')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='SummaryLandscapeSustainability'
        component={SummaryLandscapeSustainability}
        options={{
          title: t('VIEWS.LICENSE_LOTS_ADD.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.LICENSE_LOTS_ADD.TEXT_1')}
              goBack='LicenseDetails'
            />
          ),
        }}
      />
      <Stack.Screen
        name='CropCreateConfirm'
        component={CropCreateConfirm}
        options={{
          title: t('VIEWS.CROP_CREATE_CONFIRM.TEXT_1'),

          header: (props) => (
            <Header
              {...props}
              title={t('VIEWS.CROP_CREATE_CONFIRM.TEXT_1')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='Activities'
        component={ActivitiesScreen}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_19'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_19')}
              goBack
              showFiltersActivities
            />
          ),
        }}
      />

      <Stack.Screen
        name='ActivityList'
        component={ActivityList}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_19'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_19')}
              goBack
              showFiltersActivities
            />
          ),
        }}
      />

      <Stack.Screen
        name='FilterActivities'
        component={FilterActivitiesScreen}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.FILTERS'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.FILTERS')}
              close
              showClearOutFiltersActivities
            />
          ),
        }}
      />

      <Stack.Screen
        name='CommonActivities'
        component={CommonActivitiesScreen}
        options={{
          header: (props) => {
            let mode = ''
            const names = {
              sowing: t('NAVIGATION.STACK_NAVIGATOR.TEXT_26'),
              tillage: t('NAVIGATION.STACK_NAVIGATOR.TEXT_27'),
              fertilization: t('NAVIGATION.STACK_NAVIGATOR.TEXT_28'),
              application: t('NAVIGATION.STACK_NAVIGATOR.TEXT_29'),
              harvest: t('NAVIGATION.STACK_NAVIGATOR.TEXT_30'),
            }

            mode = names[props.scene.route.params.mode]

            return <Header {...props} title={mode} goBack />
          },
        }}
      />
      <Stack.Screen
        name='CommonOptionalPlanned'
        component={OptionalPlanned}
        options={{
          header: (props) => {
            let mode = ''
            const names = {
              sowing: t('NAVIGATION.STACK_NAVIGATOR.TEXT_56'),
              tillage: t('NAVIGATION.STACK_NAVIGATOR.TEXT_57'),
              fertilization: t('NAVIGATION.STACK_NAVIGATOR.TEXT_58'),
              application: t('NAVIGATION.STACK_NAVIGATOR.TEXT_59'),
            }
            mode = names[props.scene.route.params.mode]
            return <Header {...props} title={mode} goBack />
          },
        }}
      />
      <Stack.Screen
        name='SupplyDetail'
        component={SupplyDetail}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_44')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='ActivityCreateConfirm'
        component={ActivityCreateConfirm}
        options={{
          title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.NAVIGATION.TITLE'),
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('VIEWS.ACTIVITY_CREATE_CONFIRM.NAVIGATION.TITLE')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='AchievementsActivityList'
        component={AchievementsActivityList}
        options={{
          header: (props) => {
            let mode = ''

            const names = {
              sowing: t('NAVIGATION.STACK_NAVIGATOR.TEXT_31'),
              tillage: t('NAVIGATION.STACK_NAVIGATOR.TEXT_32'),
              fertilization: t('NAVIGATION.STACK_NAVIGATOR.TEXT_33'),
              application: t('NAVIGATION.STACK_NAVIGATOR.TEXT_34'),
              harvest: t('NAVIGATION.STACK_NAVIGATOR.TEXT_35'),
            }

            mode = names[props.scene.route.params.mode]

            return <Header {...props} title={mode} goBack />
          },
        }}
      />
      <Stack.Screen
        name='AchievementsScreen'
        component={AchievementsScreen}
        options={{
          header: (props) => {
            let mode = ''

            const names = {
              ACT_SOWING: t('NAVIGATION.STACK_NAVIGATOR.TEXT_36'),
              ACT_TILLAGE: t('NAVIGATION.STACK_NAVIGATOR.TEXT_37'),
              ACT_FERTILIZATION: t('NAVIGATION.STACK_NAVIGATOR.TEXT_38'),
              ACT_APPLICATION: t('NAVIGATION.STACK_NAVIGATOR.TEXT_39'),
              ACT_HARVEST: t('NAVIGATION.STACK_NAVIGATOR.TEXT_40'),
            }

            mode = names[props.scene.route.params.tag]

            return <Header {...props} title={mode} goBack />
          },
        }}
      />
      <Stack.Screen
        name='CommonActivitiesDetail'
        component={CommonActivitiesDetail}
        options={{
          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_41')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='subLicenseSelectCompanies'
        component={subLicenseSelectCompanies}
        options={{
          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_49')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='FarmCreate'
        component={FarmCreate}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_52'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_52')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='FarmLotsAdd'
        component={FarmLotsAdd}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_51'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_51')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='FarmDetails'
        component={FarmDetails}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_53'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_53')}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='FarmLotList'
        component={FarmLotList}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_54'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_54')}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='FarmLotDetails'
        component={FarmLotDetails}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_55'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_55')}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='FarmEditName'
        component={FarmEditName}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_60'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_60')}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='FarmLotEditName'
        component={FarmLotEditName}
        options={{
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_60'),

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_60')}
              goBack
            />
          ),
        }}
      />

      <Stack.Screen
        name='ModalErrorDeepLinks'
        component={ModalErrorsDeepLinks}
        options={{
          header: (props) => <Header {...props} title={''} />,
        }}
      />

      <Stack.Screen
        name='CompanyList'
        component={CompanyList}
        initialParams={{}}
        options={{
          header: (props) => {
            const { isAdviser } = props?.scene?.route?.params
            const title = isAdviser
              ? t('NAVIGATION.STACK_NAVIGATOR.TEXT_65')
              : t('NAVIGATION.STACK_NAVIGATOR.TEXT_66')
            return <Header {...props} title={title} goBack />
          },
        }}
      />

      <Stack.Screen
        name='CompanyListUcropit'
        component={CompanyList}
        initialParams={{}}
        options={{
          header: (props) => {
            const { isAdviser } = props?.scene?.route?.params
            const title = isAdviser
              ? t('NAVIGATION.STACK_NAVIGATOR.TEXT_65')
              : t('NAVIGATION.STACK_NAVIGATOR.TEXT_66')
            return <Header {...props} title={title} goBack />
          },
        }}
      />

      <Stack.Screen
        name={RouterNames.COLLABORATOR_LIST_COMPANY}
        component={CollaboratorListCompany}
        initialParams={{}}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='AddCollaborator'
        component={AddCollaborator}
        initialParams={{}}
        options={({ route }) => ({
          title: route.params.isNewCompany
            ? t('NAVIGATION.STACK_NAVIGATOR.ADD_PRODUCER_ADMIN')
            : t('NAVIGATION.STACK_NAVIGATOR.ADD_COLLABORATORS'),

          header: (props) => (
            <Header
              {...props}
              title={
                route.params.isNewCompany
                  ? t('NAVIGATION.STACK_NAVIGATOR.ADD_PRODUCER_ADMIN')
                  : t('NAVIGATION.STACK_NAVIGATOR.ADD_COLLABORATORS')
              }
              goBack
            />
          ),
        })}
      />
      <Stack.Screen
        name='AddAdviser'
        component={AddCollaborator}
        initialParams={{}}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.ADD_ADVISER_RESPONSIBLE')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='CompanyDetail'
        component={CompanyDetail}
        initialParams={{}}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('VIEWS.COMPANY_DETAIL.TEXT_10')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='CollaboratorsListNewCompany'
        component={CollaboratorsListNewCompany}
        initialParams={{}}
        options={{
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
                goBack
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='Settings'
        component={SettingsScreen}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.SETTINGS,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.SETTINGS}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='DeleteAccount'
        component={DeleteAccount}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.DELETE_ACCOUNT,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.DELETE_ACCOUNT}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='Languages'
        component={Languages}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.LANGUAGES,

          header: (props) => (
            <Header
              {...props}
              title={t('NAVIGATION').STACK_NAVIGATOR.LANGUAGES}
              goBack
            />
          ),
        }}
      />
      <Stack.Screen
        name='CognitoCode'
        component={CognitoCode}
        options={{
          title: '',

          header: (props) => <HeaderOnlyBack {...props} title={''} />,
        }}
      />

      <Stack.Screen
        name='ValidateTaxId'
        component={ValidateTaxId}
        options={{
          title: '',

          header: (props) => <HeaderOnlyBack {...props} title={''} />,
        }}
      />

      <Stack.Screen
        name='Home'
        initialParams={{}}
        component={HomeWithoutCompany}
        options={{
          title: t('NAVIGATION').STACK_NAVIGATOR.TEXT_5,
          // eslint-disable-next-line react/display-name
          header: (props) => {
            return (
              <Header
                {...props}
                title={t('NAVIGATION').STACK_NAVIGATOR.TEXT_5}
                drawer
                showFilters
              />
            )
          },
        }}
      />
      <Stack.Screen
        name='WebViewResponsePage'
        component={WebViewResponsivePage}
        options={{
          title: '',
          header: (props) => {
            return (
              <Header
                {...props}
                title={props?.scene?.route?.params?.title}
                goBack
              />
            )
          },
        }}
      />
    </Main.Navigator>
  )
}

export { MainStackNavigator, ProfileStack, SetupStack }
