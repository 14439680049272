import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import ModalError from '@components/common/v1/ModalError'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { blackHighEmphasis, white } from '@styles/palette'

const ErrorApplySubLicensesModal = ({
  isModalErrorVisible,
  goToLicenses,
  t,
  goToHome,
}) => {
  return (
    <ModalError
      visible={isModalErrorVisible}
      title={t('VIEWS').LICENSE_LOTS_ADD.TEXT_15}
      description={t('VIEWS').LICENSE_LOTS_ADD.TEXT_16}
    >
      <View style={styles.buttonsContainerError}>
        <ButtonPrimary
          text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_13}
          onPress={goToLicenses}
          colorText={blackHighEmphasis}
        />

        <ButtonPrimary
          marginTop={20}
          text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_14}
          backgroundColor='transparent'
          colorText={white}
          fontSize={14}
          onPress={goToHome}
        />
      </View>
    </ModalError>
  )
}

const styles = StyleSheet.create({
  buttonsContainerError: {
    marginTop: 10,
    width: 300,
  },
})

ErrorApplySubLicensesModal.propTypes = {
  isModalErrorVisible: PropTypes.bool.isRequired,
  goToLicenses: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default ErrorApplySubLicensesModal
