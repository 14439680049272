import React, { useCallback, useContext } from 'react'
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'
import { Title, Subheading, Text } from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'
import { round } from 'lodash'

import { CommonIcon } from '@modules/common/components'
import { CropContext } from '@contextState/crop'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import EiqCardSimple from '@components/common/v1/EiqCardSimple'
import { formatDateMoment } from '@utils/date'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import { SelectorContext } from '@contextState/selectors'
import { ConnectionContext } from '@contextState/connection'
import { LanguageContext } from '@contextState/language'
import { SUPPLY } from '@constants/iconGuide'
import { getDescriptionSupplySelect } from '@utils/supply'
import CommonList from '@components/common/CommonList'
import { getCropNameFormatted } from '@utils/crop'
import { CommonContext } from '@contextState/common'
import { truncateSurface } from '@common/utils'

const ActivityValidation = ({ route, navigation }) => {
  const { activity } = route.params
  const { currentCrop } = useContext(CropContext)
  const {
    setEstablishmentsSelector,
    setLotSelector,
    setSelectorTotalSurface,
    collaboratorSelector,
    setCollaboratorSelector,
    setSupplySelector,
  } = useContext(SelectorContext)
  const { isConnected } = useContext(ConnectionContext)
  const { t, i18n } = useContext(LanguageContext)
  const { unitTypesSupplies } = useContext(CommonContext)

  useFocusEffect(
    useCallback(() => {
      if (currentCrop) {
        getAchievementData()
        setCollaboratorSelector(
          activity.signers.map((el) => ({
            userId: el.userId,
            fullName: el.fullName,
            email: el.email,
            type: el.type || 'Colaborador',
            signed: el.signed,
          }))
        )
        return () => true
      }
    }, [currentCrop])
  )

  const getAchievementData = () => {
    setLotSelector(activity.lots.map((el) => el._id))

    const newEstablishments = []

    activity.lots.forEach((element) => {
      let establishmentName = ''

      const establishments = currentCrop?.establishments ?? currentCrop?.lots

      const lotsInCrop = establishments.find((subElement) => {
        const lots = subElement.lots ?? subElement.data

        return lots.find((subSubElement) => subSubElement._id === element._id)
      })

      if (lotsInCrop) {
        establishmentName = lotsInCrop.tag
      }

      if (!newEstablishments.length) {
        newEstablishments.push({
          tag: establishmentName,
          lots: [
            {
              ...element,
              surfaceRealized: element.surfaceAchievement,
            },
          ],
        })
      } else {
        let exist = false

        newEstablishments.map((subElement) => {
          if (subElement.tag === establishmentName) {
            subElement.lots.push({
              ...element,
              ...element.lot,
              surfaceRealized: element.surfaceAchievement,
            })

            exist = true
          }
        })

        if (!exist) {
          newEstablishments.push({
            tag: establishmentName,
            lots: [
              {
                ...element,
                ...element.lot,
                surfaceRealized: element.surfaceAchievement,
              },
            ],
          })
        }
      }
    })

    setSupplySelector(activity.supplies)
    setEstablishmentsSelector(newEstablishments)
    setSelectorTotalSurface(truncateSurface(activity.surface))
  }

  const handleCollaboratorsNav = () => {
    navigation.navigate('CollaboratorsSelectorModal', {
      id: currentCrop._id,
      status: 'readOnly',
    })
  }

  const goToDetailsPlanning = () => {
    navigation.navigate('DetailsPlanning', {
      cropId: currentCrop._id,
      activityId: route.params.activity,
      routeRedirect: 'ActivityValidation',
      readOnly: true,
    })
  }

  function navigateToSupplyDetail(supplyId, key) {
    navigation.navigate('SupplyDetail', {
      id: supplyId,
      key: key,
      surface: route.params.activity.surface,
      readOnly: true,
    })
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.containerTitle}>
          <View style={styles.containerHeadTitle}>
            <Title style={styles.title}>
              {getCropNameFormatted(currentCrop, i18n.locale)}
            </Title>
            <Subheading style={styles.subTitle}>
              {round(currentCrop?.surface, 2)} {currentCrop?.areaUnit} -{' '}
              {currentCrop?.company.name}
            </Subheading>
          </View>

          <View>
            <Text style={styles.subTitles}>
              {`${t('VIEWS').ACTIVITY_VALIDATION.TEXT_7} ${formatDateMoment(
                activity.dateStart,
                'MM/YYYY'
              )} ${t('VIEWS').ACTIVITY_VALIDATION.TEXT_8} ${formatDateMoment(
                activity.dateEnd,
                'MM/YYYY'
              )}`}
            </Text>
            <Text style={styles.subTitles}></Text>
            <TouchableOpacity
              onPress={goToDetailsPlanning}
              style={styles.containerDetailsLots}
            >
              <Text style={styles.subTitles}>{`${activity.lots.length} ${
                t('VIEWS').ACTIVITY_VALIDATION.TEXT_5
              } - ${round(activity.surface, 2)} ${activity.areaUnit} `}</Text>
              <CommonIcon name='CHEVRON-RIGHT' size={20} />
            </TouchableOpacity>
          </View>
        </View>

        {isConnected && (
          <EiqCardSimple
            title={`${t('VIEWS').ACTIVITY_VALIDATION.TEXT_4} ${round(
              calculateEiq(activity.supplies, activity.surface),
              2
            )}`}
            range={getEIQRange(
              calculateEiq(activity.supplies, activity.surface)
            )}
            tag='ACT_APPLICATION'
          />
        )}

        {activity.supplies.map((element, key) => {
          const unitName = unitTypesSupplies.find(
            (unit) => unit.key === element.unit
          )
          element.unitName = unitName?.keyLabel
          return (
            <CommonList
              key={key}
              index={key}
              img={
                SUPPLY[
                  element?.typeId?.code ? element?.typeId?.code : 'default'
                ]
              }
              onPress={() => navigateToSupplyDetail(element._id, key)}
              data={getDescriptionSupplySelect(
                element,
                currentCrop?.areaUnit,
                element.surface
              )}
            />
          )
        })}

        <View style={styles.containerCollaborator}>
          <CollaboratorsButton
            title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
            quantity={collaboratorSelector.length}
            onPress={handleCollaboratorsNav}
          />
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  containerTitle: {
    flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
    lineHeight: 24,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333',
    lineHeight: 20,
  },
  containerHeadTitle: {
    marginBottom: 8,
  },
  iconSupply: {
    color: 'white',
  },
  subTitles: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  card: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  cardItem: {
    flexBasis: '20%',
  },
  supplies: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16,
    marginBottom: 16,
  },
  iconContainerSupplies: {
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9E9E9E',
    width: 40,
    height: 40,
    flexDirection: 'row',
    marginHorizontal: 20,
  },
  leftContainerSupplies: {
    alignItems: 'center',
    width: 40,
    height: '100%',
    marginHorizontal: 20,
  },
  rightContainerSupplies: {
    width: '70%',
  },
  spaceCard: {
    marginBottom: 10,
  },
  containerCollaborator: {
    marginTop: 16,
    marginBottom: 16,
  },
  containerDetailsLots: {
    flex: 1,
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    width: 50,
    height: 50,
  },
})

export default ActivityValidation
