import _ from 'lodash'
import moment from 'moment'

/**
 * @description get crop name formated with his data (cropType datecrop(MMM-YYYY) - (cropType dateharvest(MMM-YYYY))
 * @param  crop
 * @param  locale
 * @returns string
 */
export const getCropNameFormatted = (crop, locale = 'es') => {
  moment.locale(locale)
  return crop?.cropType && crop?.dateCrop && crop?.dateHarvest
    ? `${crop.cropType.codeLabel} ${_.capitalize(
        moment(crop.dateCrop).format('MMM/YYYY').replace('.', '')
      )} - ${_.capitalize(
        moment(crop.dateHarvest).format('MMM/YYYY').replace('.', '')
      )}`
    : ''
}
