import { useState, useContext } from 'react'

import useNetwork from '@utils/network'
import { statusOutlier as statusOutlierEnum } from '@constants/outlierData'
import { AuthContext } from '@contextState/auth'

export const useChangeOutliersHarvest = () => {
  const { config } = useContext(AuthContext)

  const [canShowOutlierHarvest, setCanShowOutlierHarvest] = useState(false)
  const [isValueOutsideMeanHarvest, setIsValueOutsideMeanHarvest] =
    useState(false)
  const [statusOutlier, setStatusOutlier] = useState(null)
  const { doRequest } = useNetwork()
  const LEVEL_UMBRAL_MEAN = 2

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  /**
   *  Change State outlier harvest.
   *
   * @param {*} pay
   * @param {*} unit
   * @param {*} cropType
   */
  const changeOutLierHarvest = async (pay, unit, cropType) => {
    try {
      if (alpha3Code !== 'USA') {
        const request = await doRequest({
          method: 'GET',
          url: `outliers/harvest?cropType=${cropType}&pay=${pay}&unit=${unit}`,
          displayAlert: false,
        })
        const { data } = request

        if (data?.level > LEVEL_UMBRAL_MEAN) {
          setIsValueOutsideMeanHarvest(true)
          setCanShowOutlierHarvest(true)
          setStatusOutlier(statusOutlierEnum[data.status || 'atypical'])
        } else {
          setIsValueOutsideMeanHarvest(false)
          setCanShowOutlierHarvest(false)
          setStatusOutlier(null)
        }
      }
    } catch (error) {
      console.error(`Error request outlier: ${error}`)
    }
  }

  return {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    statusOutlier,
    changeOutLierHarvest,
  }
}
