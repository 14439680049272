import { useContext } from 'react'
import { AuthContext } from '@contextState/auth'
import {
  WORK_ORDER_ANALYTICS_SHARE,
  WORK_ORDER_ANALYTICS_VIEW,
} from '@utils/constants'
import { shareViewWorkOrder } from '@services/analytics'

export const useAnalytics = () => {
  const { user } = useContext(AuthContext)
  const { email, config } = user
  const userType = config?.roleSelected?.value

  const shareWorkOrderAnalytics = (activityType, workOrderNumber) => {
    workOrderAnalytics(
      activityType,
      workOrderNumber,
      WORK_ORDER_ANALYTICS_SHARE
    )
  }

  const viewWorkOrderAnalytics = (activityType, workOrderNumber) => {
    workOrderAnalytics(activityType, workOrderNumber, WORK_ORDER_ANALYTICS_VIEW)
  }

  const workOrderAnalytics = (activityType, workOrderNumber, action) => {
    shareViewWorkOrder({
      date: new Date(),
      userType: userType,
      activityType,
      workOrderNumber,
      email: email,
      action: action,
    })
  }

  return { shareWorkOrderAnalytics, viewWorkOrderAnalytics }
}
