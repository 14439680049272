import { useState, useCallback, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { useCompanyInfo } from '@common/hooks'
import useNetwork from '@utils/network'
import { useActivityWrapper } from '../useActivityWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { activitiesActions } from '@store/actions'
import { IParams, IParamsDraft } from './interfaces'
import { ACTIVITY_CONDITIONS } from '@common/utils'

const INIT_PAGE = 1
const LIMIT = 15
const INIT_PAGINATION = {
  page: INIT_PAGE,
  limit: LIMIT,
  totalPages: null,
}

export const useFetchActivities = ({ cropId }: { cropId: string }) => {
  const dispatch = useDispatch()

  const { activityStatus, activityType } = useSelector(
    (state: any) => state.activities
  )

  const thereIsFilterDraft = activityStatus.some(
    (condition: string) => condition === ACTIVITY_CONDITIONS.DRAFT.key
  )
  const activityStatusWithoutDraft = activityStatus.filter(
    (status: string) => status !== ACTIVITY_CONDITIONS.DRAFT.key
  )

  const [isLoading, setIsLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [paginationData, setPaginationData]: any = useState(INIT_PAGINATION)
  const [hasMoreData, setHasMoreData] = useState(false)

  const { doRequest } = useNetwork()
  const { identifier } = useCompanyInfo()
  const { wrapData } = useActivityWrapper()

  useEffect(() => {
    return () => {
      dispatch(activitiesActions.removeFilters())
    }
  }, [])

  useFocusEffect(
    useCallback(() => {
      fetchDraftData()
    }, [activityStatus, activityType])
  )

  const fetchDraftData = async () => {
    setIsLoading(true)

    const params: IParamsDraft = {
      cropId,
      limit: 250,
    }

    const requestData = {
      method: 'GET',
      url: 'activities-drafts',
      params,
      displayAlert: false,
    }

    let response
    if (
      (activityStatus.length && thereIsFilterDraft) ||
      !activityStatus.length
    ) {
      try {
        const { data } = await doRequest(requestData)
        response = data
      } catch (error) {
        await fetchData()
        return
      }
    } else {
      response = { cantElements: 0, data: [] }
    }

    const { data: parsedActivities } = wrapData({
      currentData: [],
      newData: response.data,
    })
    await fetchData({ activitiesDraft: parsedActivities })
  }

  const fetchData = async ({
    page = INIT_PAGE,
    limit = LIMIT,
    activitiesDraft = [],
  } = {}) => {
    setIsLoading(true)

    const params: IParams = { identifier, cropId, page, limit }

    if (activityStatusWithoutDraft.length) {
      params.condition = activityStatusWithoutDraft.toString()
    }
    if (activityType.length) {
      params.tag = activityType.toString()
    }

    const requestData = {
      method: 'GET',
      url: 'activities',
      version: 'v2',
      params,
      displayAlert: false,
    }

    let response
    if (
      !activityStatus.length ||
      (activityStatus.length && activityStatusWithoutDraft.length)
    ) {
      try {
        const { data } = await doRequest(requestData)
        response = data
      } catch (error) {
        setIsLoading(false)
        return
      }
    } else {
      response = { cantElements: 0, data: [] }
    }

    const { data: parsedActivities } = wrapData({
      currentData: page === INIT_PAGE ? activitiesDraft : activities,
      newData: response.data,
    })

    dispatch(
      activitiesActions.setIsActivities(Boolean(parsedActivities.length))
    )

    setActivities(parsedActivities)

    if (!paginationData.totalPages) {
      const newPaginationData = {
        ...paginationData,
        totalPages: response.totalPages,
      }

      setPaginationData(newPaginationData)
    }

    if (response.page === response.totalPages) {
      setHasMoreData(false)
    } else {
      setHasMoreData(true)
    }

    setIsLoading(false)
  }

  const nextPage = async () => {
    if (isLoading || !hasMoreData) {
      return
    }

    const newPaginationData = {
      ...paginationData,
      page: paginationData.page + 1,
    }

    setPaginationData(newPaginationData)

    await fetchData(newPaginationData)
  }

  return {
    isLoading,
    activities,
    nextPage,
  }
}
